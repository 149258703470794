import {memo} from 'react';
import {Box, makeStyles} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
    width: '100%',
    paddingBottom: '100%',
    overflow: 'hidden',
  },
  imageBox: {
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
  image: {
    width: '100%',
    height: '100%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'auto',
    objectFit: 'cover',
  },
}));

type Props = {
  image?: string;
  title?: string;
};

export const ImageSquare = memo(({image, title}: Props) => {
  const styles = useStyles();

  return (
    <Box className={styles.root}>
      <Box className={styles.imageBox}>
        <img className={styles.image} src={image} alt={title} />
      </Box>
    </Box>
  );
});
