import {Button} from '@cere/rxb-template-ui-kit';
import {makeStyles} from '@material-ui/core';
import colors from '../../../styles/colors';

const useStyles = makeStyles(() => ({
  text: {
    color: colors.error,
    fontWeight: 600,
  },
}));

export const DisconnectButton = ({onClick}: {onClick: Function}) => {
  const classes = useStyles();

  return (
    <Button variant="text" onClick={onClick} classes={classes}>
      Disconnect wallet
    </Button>
  );
};
