import {memo, ReactElement} from 'react';
import {Box, Typography, Divider} from '@material-ui/core';
import {makeStyles, Theme} from '@material-ui/core';
import clsx from 'clsx';

import {useLocalization} from 'shared/hooks/use-locale.hook';

import {Help} from '@material-ui/icons';
import {ReactComponent as Logo} from '../../../assets/svg/logo.svg';
import {ReactComponent as FacebookIcon} from './assets/facebook.svg';
import {ReactComponent as InstagramIcon} from './assets/instagram.svg';
import {ReactComponent as TwitterIcon} from './assets/facebook.svg';
import {ReactComponent as DiscordIcon} from './assets/discord.svg';

import {StyledLink} from '../../primitives/StyledLink';
import {PageContainer} from '../PageContainer';
import {
  FAQ_URL,
  INSTAGRAM_ONDAVINCI_URL,
  FACEBOOK_ONDAVINCI_URL,
  TWITTER_ONDAVINCI_URL,
  DISCORD_ONDAVINCI_URL,
} from '../../../constants/common';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.grey[100],
    padding: '40px 0',
    [theme.breakpoints.up('md')]: {
      padding: '49px 0',
    },
    [theme.breakpoints.up('lg')]: {
      padding: '65px 0 48px',
    },
  },
  container: {
    position: 'relative',
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  contentTop: {
    paddingBottom: '24px',
    [theme.breakpoints.up('md')]: {
      paddingBottom: '32px',
    },
  },
  contentBottom: {
    paddingTop: '24px',
    [theme.breakpoints.up('md')]: {
      paddingTop: '32px',
    },
  },
  firstLine: {
    [theme.breakpoints.down('md')]: {
      marginBottom: '16px',
    },
    [theme.breakpoints.up('md')]: {
      paddingRight: '40px',
    },
  },
  logo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  socialIcons: {
    display: 'flex',
    alignItems: 'center',
    gap: '24px',
  },
  logoImg: {
    height: '30px',
  },
  helpFeedback: {
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',
    paddingLeft: '9px',
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.primary.main,
    margin: 'auto 0',
  },
  helpBlock: {
    margin: '24px 0 8px 0',
    [theme.breakpoints.up('md')]: {
      margin: 'unset',
      paddingRight: '40px',
    },
  },
  languageSelect: {
    margin: '24px 0 8px 0',
    [theme.breakpoints.up('md')]: {
      margin: 'unset',
      paddingRight: '40px',
    },
  },
  experiencePlatform: {
    color: theme.palette.grey[700],
    order: 2,
  },
  footerInfo: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      alignItems: 'center',
    },
  },
}));

type Props = {
  renderFooterSelect?: () => ReactElement;
};

export const PageFooter = memo(({renderFooterSelect}: Props) => {
  const classes = useStyles();
  const {t, locale} = useLocalization();

  return (
    <Box className={classes.root}>
      <PageContainer className={clsx(classes.container, classes.contentTop)}>
        <StyledLink to={`/${locale}/home`} className={classes.firstLine}>
          <Box className={classes.logo}>
            <Logo className={classes.logoImg} />
          </Box>
        </StyledLink>
        <Box className={classes.footerInfo}>
          <Typography variant="h4" className={classes.experiencePlatform}>
            {t('The NFT Experience Platform')}
          </Typography>
          <Box className={classes.languageSelect}>{renderFooterSelect?.()}</Box>
          <Box className={classes.helpBlock}>
            <a className={classes.link} href={FAQ_URL} target="_blank" rel="noreferrer">
              <Help />
              <span className={classes.helpFeedback}>{t('Help & Feedback')}</span>
            </a>
          </Box>
        </Box>
      </PageContainer>
      <Divider />
      <PageContainer className={clsx(classes.container, classes.contentBottom)}>
        <Box className={classes.firstLine}>
          <Typography>{`©${new Date().getFullYear()} ${t('DaVinci')}. ${t('All rights reserved')}`}</Typography>
        </Box>

        <Box className={classes.socialIcons}>
          <StyledLink to={INSTAGRAM_ONDAVINCI_URL} target="_blank">
            <InstagramIcon />
          </StyledLink>
          <StyledLink to={FACEBOOK_ONDAVINCI_URL} target="_blank">
            <FacebookIcon />
          </StyledLink>
          <StyledLink to={TWITTER_ONDAVINCI_URL} target="_blank">
            <TwitterIcon />
          </StyledLink>
          <StyledLink to={DISCORD_ONDAVINCI_URL} target="_blank">
            <DiscordIcon />
          </StyledLink>
        </Box>
      </PageContainer>
    </Box>
  );
});
