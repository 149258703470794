import {useState} from 'react';
import {Box, makeStyles} from '@material-ui/core';
import {Button, Typography, Collapse} from '@cere/rxb-template-ui-kit';
import clsx from 'clsx';
import {ReactComponent as Arrow} from '../../../../assets/arrow.svg';
import {ReactComponent as BidIcon} from '../../../../assets/icons/success.svg';
import {ReactComponent as NoBids} from '../../../../assets/icons/no-bids.svg';
import colors from '../../../../styles/colors';
import analyticService, {AnalyticEventsEnum} from '../../../services/analytic.service';
import BidHistory from 'pages/NftPage/nft-bid-history';
import {CreatorAuctionBidWithStatus} from 'shared/types/graphql';
import {useLocalization} from '../../../hooks/use-locale.hook';

interface AuctionMoreInfoProps {
  bids: CreatorAuctionBidWithStatus[];
  isOpen: boolean;
  isHighestBid?: boolean;
}
export interface ColumnConfig {
  title: string;
  width: string;
}

const useStyles = makeStyles(() => ({
  grey: {
    color: '#717178',
  },
  moreInfo: {
    color: colors.green,
    padding: '0',
    whiteSpace: 'nowrap',
    flex: '0 0 auto',
    fontSize: '14px',

    '& *': {
      fontSize: 'inherit',
    },
  },
  moreInfoArrow: {
    transition: 'transform 100ms ease-out',
    '& path': {
      stroke: colors.green,
    },
  },
  moreInfoArrowRotate: {
    transform: 'rotate(180deg)',
  },
  moreInfoText: {
    '& p': {
      color: '#E0E0E7',
      opacity: '0.7',
    },
  },
  badge: {
    background: 'rgba(26, 204, 151, 0.12)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '8px 5px',
    borderRadius: '12px',
    whiteSpace: 'nowrap',
    color: '#fff',
  },
  noBidsIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  noBidsText: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '14px',
    color: '#E0E0E7',
    opacity: '0.7',
    marginTop: '8px',
  },
  badgeIcon: {
    marginRight: '8px',
    marginLeft: '5px',
    width: '14px',
    height: '14px',

    fill: colors.green,
    stroke: colors.green,
    background: '#FFFFFF',
    borderRadius: '50%',
  },
  collapseHeader: {
    minHeight: '35px !important',
  },
}));

export const AuctionMoreInfo = ({bids, isOpen = false, isHighestBid = false}: AuctionMoreInfoProps) => {
  const {t} = useLocalization();
  const classes = useStyles();
  const [showInfo, setShowInfo] = useState(isOpen);

  const moreInfoChanged = () => {
    analyticService.track(AnalyticEventsEnum.AUCTION_MORE_INFO_CLICKED);

    setShowInfo(!showInfo);
  };

  return (
    <Box mt="10px">
      <Collapse
        onChange={moreInfoChanged}
        headerClassName={classes.collapseHeader}
        headerContent={
          <>
            <Button
              variant="text"
              className={clsx(classes.moreInfo)}
              endIcon={<Arrow className={clsx(classes.moreInfoArrow, showInfo && classes.moreInfoArrowRotate)} />}
            >
              {showInfo ? t('Less info') : t('More info')}
            </Button>

            {isHighestBid && (
              <Box className={classes.badge} minWidth="200px" ml="20px" width="calc(100% - 196px)">
                <BidIcon className={classes.badgeIcon} />
                <Typography variant="caption1" display="block">
                  {t('You have the highest bid!')}
                </Typography>
              </Box>
            )}
          </>
        }
      >
        <Box my="16px">
          <Typography variant="caption2" className={classes.moreInfoText}>
            <p>
              {' '}
              {t(
                'Your bid will be placed for the duration of the auction or until someone else bids higher than your bid. The minimal bid is an increment of 10% compared to previous bid.',
              )}{' '}
            </p>
          </Typography>
        </Box>

        <Box mb="12px" color="#FAF9F5">
          <Typography variant="h3">{t('Bid history')}</Typography>
        </Box>
        {bids?.length ? (
          <BidHistory bids={bids} isLightTheme={false} />
        ) : (
          <Box>
            <Box className={classes.noBidsIcon}>
              <NoBids />
            </Box>
            <Typography variant="caption1" className={classes.noBidsText}>
              {t('Be the first to place a bid')}
            </Typography>
          </Box>
        )}
      </Collapse>
    </Box>
  );
};
