import React from 'react';
import {Typography, NumberBadge, Icon} from '@cere/rxb-template-ui-kit';
import {Dialog as MaterialDialog, DialogContent, DialogTitle, Box, makeStyles} from '@material-ui/core';
import colors from '../../../styles/colors';
import clsx from 'clsx';
import {ReactComponent as CloseIcon} from '../../../assets/close.svg';
import {ReactComponent as TicketInfoIcon} from '../../../assets/ticket-info-illustration.svg';
import {Markdown} from '../Markdown';
import {useLocalization} from '../../hooks/use-locale.hook';

interface TicketInfoModalProps {
  onClick: (email: string) => void;
  onClose: () => void;
  title: string;
  body: string;
  onFocusAnalyticsEvent: string;
  onSubmitAnalyticsEvent: string;
  ctaButton: React.ReactNode;
}

const useStyles = makeStyles((theme) => ({
  label: {
    color: colors.lightGrey,
  },
  modalContent: {
    padding: '15px',
  },
  root: {
    margin: '0px',
  },
  paper: {
    width: '100%',
    borderRadius: '13px',
    margin: '0 16px',
  },
  paperWidthXs: {
    maxWidth: '340px',
  },
  button: {
    background: theme.palette.secondary.main,
  },
}));

const useHeaderStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 1rem',
    flexDirection: 'column',
    paddingTop: '0.5rem',
  },
}));

const useContentStyles = makeStyles(() => ({
  root: {
    padding: '16px',
  },
  body: {
    color: colors.lightGrey,
  },
}));

export const TicketInfoModal = ({onClose, title, body, ctaButton}: TicketInfoModalProps) => {
  const {t} = useLocalization();
  const styles = useStyles();
  const headerStyles = useHeaderStyles();
  const contentStyles = useContentStyles();
  const defaultBody = t(
    `- Valuable photo, video or audio asset\n- Access ticket to live exhibit & auction\n- Unlocks unseen video footage made by the creator\n- Private option to purchase exclusive auction or limited edition NFTs\n- Resell value on marketplace`,
  );

  return (
    <MaterialDialog classes={styles} scroll="paper" fullWidth open onClose={onClose} maxWidth={body ? 'sm' : 'xs'}>
      <DialogTitle disableTypography classes={headerStyles}>
        <Box width="100%" display="flex" flexDirection="row-reverse" onClick={onClose}>
          <NumberBadge variant="primary" text={<Icon size="md" icon={<CloseIcon />} />} />
        </Box>
        <TicketInfoIcon />
        <Typography variant="h3">{title || t('What is an NFT ticket?')}</Typography>
      </DialogTitle>
      <DialogContent classes={contentStyles} className={clsx(styles.modalContent)}>
        <Markdown>{body || defaultBody}</Markdown>
        <Box m="24px auto 0" width="100%" maxWidth="140px">
          {ctaButton}
        </Box>
      </DialogContent>
    </MaterialDialog>
  );
};
