import {AnyAction, MiddlewareAPI} from 'redux';
import {WalletsConnectionRestoredEvent} from '../wallets';
import {
  LoadPurchasedNFTsCommand,
  PurchasedNFTsDocument,
  PurchaseHistoryLoadingFailedEvent,
  ReloadPurchasedNFTsCommand,
  ReplacedPurchasedNFTsDocument,
} from './actions';
import {IPurchaseHistoryApi} from '../../../api/purchase-history/IPurchaseHistoryApi';
import {WalletConnectionService} from '../../../models/wallet/WalletConnectionService';
import {selectCurrentLocale} from '../localization/selectors';

export const initPurchaseHistoryLoadingMiddleware =
  ({dispatch}: MiddlewareAPI) =>
  (next: Function) =>
  (action: AnyAction) => {
    next(action);
    if (action.type === WalletsConnectionRestoredEvent.type) {
      dispatch(LoadPurchasedNFTsCommand.create());
    }
  };

export const loadPurchasedNftsMiddleware =
  (purchaseHistoryApi: IPurchaseHistoryApi, walletConnectionService: WalletConnectionService) =>
  ({dispatch, getState}: MiddlewareAPI) =>
  (next: Function) =>
  async (action: AnyAction) => {
    next(action);
    if (action.type === LoadPurchasedNFTsCommand.type) {
      const userWalletsAddresses = walletConnectionService.getConnectedWalletsAddresses();
      try {
        const locale = selectCurrentLocale(getState());
        const purchasedNfts = await purchaseHistoryApi.getPurchasedNftsWithWallets(userWalletsAddresses, locale);
        dispatch(PurchasedNFTsDocument.create(purchasedNfts));
      } catch (e) {
        console.error(e);
        dispatch(PurchaseHistoryLoadingFailedEvent.create(e.message));
      }
    }
  };

export const reloadPurchasedNftsMiddleware =
  (purchaseHistoryApi: IPurchaseHistoryApi, walletConnectionService: WalletConnectionService) =>
  ({dispatch, getState}: MiddlewareAPI) =>
  (next: Function) =>
  async (action: AnyAction) => {
    next(action);
    if (action.type === ReloadPurchasedNFTsCommand.type) {
      const userWalletsAddresses = walletConnectionService.getConnectedWalletsAddresses();
      try {
        const locale = selectCurrentLocale(getState());
        const purchasedNfts = await purchaseHistoryApi.getPurchasedNftsWithWallets(userWalletsAddresses, locale);
        dispatch(ReplacedPurchasedNFTsDocument.create(purchasedNfts));
      } catch (e) {
        console.error(e);
        dispatch(PurchaseHistoryLoadingFailedEvent.create(e.message));
      }
    }
  };
