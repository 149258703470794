import {Box, Button, makeStyles} from '@material-ui/core';
import {Snackbar, Typography} from '@cere/rxb-template-ui-kit';
import colors from '../../../styles/colors';
import clsx from 'clsx';
import {ReactComponent as CopyIcon} from '../../../assets/icons/copy.svg';
import {ReactComponent as CheckIcon} from '../../../assets/check.svg';
import {copyToClipboard} from '../../../shared/lib/utils';
import {useContext, useMemo, useRef, useState} from 'react';
import {UserContext} from '../../../context/user-context/user-context';
import {ReactComponent as CloseIcon} from '../../../assets/icons/close.svg';
import {POPUP_TIMEOUT_BEFORE_CLOSE} from '../../../config/common';
import {SupportedWallet} from '../../../shared/types/supported-wallet';
import {isNonCustodyWalletType} from '../../../shared/types/non-custody-wallet';
import {useLocalization} from 'shared/hooks/use-locale.hook';

const useStyles = makeStyles((theme) => ({
  page: {
    backgroundColor: colors.lighter,
    borderRadius: '12px',
    padding: '24px 16px 24px 16px',
  },
  wallet: {
    color: colors.grey,
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '5px',

    [theme.breakpoints.up('lg')]: {
      flexDirection: 'column',
    },
  },
  cutText: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    width: 'calc(100% - 40px)',
    height: '1.2em',
    whiteSpace: 'nowrap',
  },
  copyIcon: {
    cursor: 'pointer',
  },
  copyIconDesktop: {
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  walletPage: {
    height: '86px',
    [theme.breakpoints.up('lg')]: {
      height: '175px',
    },
  },
  copyButton: {
    height: '48px',
    width: '193px',
    backgroundColor: theme.palette.grey[100],
    margin: '32px auto 0 auto',

    [theme.breakpoints.down('lg')]: {
      display: 'none',
    },
  },
  copyText: {
    color: theme.palette.info.main,
    marginLeft: '10px',
    fontWeight: 600,
  },
}));

type UserWalletDetailsProps = {
  wallet?: SupportedWallet;
  isWalletPage?: boolean;
};

export const UserWalletDetails = ({wallet, isWalletPage}: UserWalletDetailsProps) => {
  const {t} = useLocalization();
  const classes = useStyles();
  const timeout = useRef<number>();
  const {userData, nonCustodyWallets} = useContext(UserContext);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const walletAddress = useMemo(
    () =>
      wallet && isNonCustodyWalletType(wallet)
        ? nonCustodyWallets.find(({type}) => wallet === type)?.publicKey
        : userData.userPublicKey,
    [nonCustodyWallets, userData.userPublicKey, wallet],
  );

  const handleCopyClick = (e: React.MouseEvent<SVGSVGElement> | React.MouseEvent<HTMLButtonElement>) => {
    walletAddress && copyToClipboard(e, walletAddress, onSnackBarShow);
  };

  const onSnackBarShow = () => {
    setShowSnackbar(true);

    timeout.current = window.setTimeout(() => {
      setShowSnackbar(false);
    }, POPUP_TIMEOUT_BEFORE_CLOSE);
  };

  const onSnackBarClose = () => {
    setShowSnackbar(false);
    clearTimeout(timeout.current);
    timeout.current = undefined;
  };

  if (!walletAddress) {
    return null;
  }

  return (
    <Box className={clsx(classes.page, 'user-wallet-balance-component', isWalletPage && classes.walletPage)} mt="10px">
      {showSnackbar && (
        <Snackbar
          open
          title=""
          text=""
          style={{
            background: '#161616',
            color: '#FAF9F5',
            padding: '18px',
          }}
        >
          <Box display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center" width="100%">
            <Box alignSelf="flex-start" marginRight="14px">
              <CheckIcon />
            </Box>
            <Box>
              <Typography variant="h3">Link copied</Typography>
              <Typography variant="caption1">{`The wallet address been copied to your clipboard`}</Typography>
            </Box>
            <Box alignSelf="flex-start" marginLeft="20px">
              <CloseIcon onClick={onSnackBarClose} />
            </Box>
          </Box>
        </Snackbar>
      )}

      <Box>
        <div>
          <Typography variant="h4">{t('Wallet Address')}</Typography>
        </div>
      </Box>
      <Box className={classes.wallet}>
        <Typography className={classes.cutText}>{walletAddress}</Typography>
        {showSnackbar ? (
          !isWalletPage ? (
            <CheckIcon />
          ) : (
            <>
              <CheckIcon className={classes.copyIconDesktop} />
              <Button className={classes.copyButton}>
                <CheckIcon />
              </Button>
            </>
          )
        ) : !isWalletPage ? (
          <CopyIcon className={classes.copyIcon} onClick={handleCopyClick} />
        ) : (
          <>
            <CopyIcon className={clsx(classes.copyIcon, classes.copyIconDesktop)} onClick={handleCopyClick} />
            <Button className={classes.copyButton} onClick={handleCopyClick}>
              <CopyIcon className={classes.copyIcon} />
              <Typography variant="h4" className={classes.copyText}>
                {t('Copy')}
              </Typography>
            </Button>
          </>
        )}
      </Box>
    </Box>
  );
};
