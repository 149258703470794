import {memo} from 'react';
import {Grid} from '@material-ui/core';

import {ConnectedCard} from '../../../../components/connected/ConnectedCard';

type Props = {
  ids: string[];
};

export const NftCollectibles = memo(({ids}: Props) => {
  return (
    <Grid container spacing={2}>
      {ids.map((id) => (
        <Grid item key={`key=${id}`} xs={12} md={6} lg={4}>
          <ConnectedCard nftId={id} />
        </Grid>
      ))}
    </Grid>
  );
});
