import {Button} from '@material-ui/core';
import clsx from 'clsx';
import {useBidButtonStyles, useDefaultButtonStyles} from './styles';
import {useTranslation} from 'react-i18next';
import {useStartAuctionFlow} from '../../../context/auction.context';
import {Auction} from '../../../model/auction/Auction';
import {withAuctionContext, withCheckBeforePurchase} from './hocs';
import {DisabledButton} from './DisabledButton';

export interface BidButtonProps {
  type?: 'default' | 'green';
  auction: Auction;
  className?: string;
  text?: string;
  nftId: string;
  nftCreatorName: string;
  nftTitle: string;
  nftAddress: string;
  nftImage: string;
  nftEventSlug?: string;
  disabled?: boolean;
  checkBeforePurchase?: () => void;
}

const PlainBidButton = ({
  type = 'default',
  className,
  text,
  auction,
  nftId,
  nftAddress,
  nftTitle,
  nftImage,
  nftEventSlug,
  nftCreatorName,
  disabled,
  checkBeforePurchase,
}: BidButtonProps) => {
  const buttonStyles = useDefaultButtonStyles().default;
  const bidButtonStyles = useBidButtonStyles()[type];
  const {t} = useTranslation();
  const startBidFlow = useStartAuctionFlow();

  const bid = async () => {
    checkBeforePurchase?.();
    startBidFlow(auction, {
      nftId,
      address: nftAddress,
      title: nftTitle,
      image: nftImage,
      creatorName: nftCreatorName,
      eventSlug: nftEventSlug,
    });
  };

  if (disabled) {
    return <DisabledButton text={text || t('Bid')} className={className} />;
  }

  return (
    <Button
      className={clsx(className, buttonStyles, bidButtonStyles)}
      onClick={bid}
      variant="contained"
      color="primary"
    >
      {text || t('Bid')}
    </Button>
  );
};

export const BidButton = withAuctionContext(withCheckBeforePurchase(PlainBidButton));
