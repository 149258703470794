/* eslint-disable react-hooks/rules-of-hooks */
import {ReactElement} from 'react';
import {Badge} from '@cere/rxb-template-ui-kit';
import {css} from '@linaria/core';
import {NftAvailability} from '../../services/nft.service';
import {EventTimelineStatus} from '../../types/event';
import {ReactComponent as Success} from '../../../assets/icons/success.svg';
import {ReactComponent as Lock} from '../../../assets/lock.svg';
import {CmsExhibitionNft, CmsExhibitionNftRelType, CreatorAuction} from '../../types/graphql';
import {NftCardBigFinished} from './NftCardBigFinished';
import {isAuctionedNft} from '../../helpers/auction';
import {NftCardBigNotStarted} from './NftCardBigNotStarted';
import {NftAllocationPendingBadge} from './NftCardBigBadge';
import {GoogleAnalyticsId} from '../../../analytics-ids';
import {enableMarketplaceView} from '../../../config/common';
import {useNftIsPending} from '../../hooks/use-nft-is-pending';
import {getNFTTotalOfferedQty} from '../../helpers/nfts';
import i18n from '../../../i18n';
import {AvailabilityStatus, NftType} from '@cere/services-types';
import {useLocalization} from '../../hooks/use-locale.hook';

const lockClassName = css`
  width: 14px;
  height: 14px;
  fill: #f8f8fa;
`;

export const checkNftAvailabilityByTimelines = (
  eventStatus: EventTimelineStatus,
  nftType: CmsExhibitionNftRelType,
  nftAvailability: NftAvailability | undefined,
): boolean => {
  if (nftAvailability !== NftAvailability.ONGOING) {
    return false;
  }

  return nftType === CmsExhibitionNftRelType.ACCESS
    ? eventStatus !== EventTimelineStatus.FINISHED
    : eventStatus === EventTimelineStatus.STARTED;
};

export const checkNftAvailabilityByTimelinesV2 = (
  eventStatus: EventTimelineStatus,
  nftType: NftType,
  nftAvailability: AvailabilityStatus | undefined,
): boolean => {
  if (nftAvailability !== AvailabilityStatus.ONGOING) {
    return false;
  }

  return nftType === NftType.ACCESS
    ? eventStatus !== EventTimelineStatus.FINISHED
    : eventStatus === EventTimelineStatus.STARTED;
};

export const checkNftMayBePurchased = (
  eventStatus: EventTimelineStatus,
  nftType: CmsExhibitionNftRelType | undefined,
  nftAvailability: NftAvailability | undefined,
  balance: number,
  isSettled?: boolean,
): boolean => {
  if (!balance || !nftType) {
    return false;
  }
  if (isSettled && nftType === CmsExhibitionNftRelType.AUCTIONED) {
    return balance > 0;
  }
  return checkNftAvailabilityByTimelines(eventStatus, nftType, nftAvailability);
};
export const checkNftMayBePurchasedV2 = (
  eventStatus: EventTimelineStatus,
  nftType: NftType | undefined,
  nftAvailability: AvailabilityStatus | undefined,
  balance: number,
  isSettled?: boolean,
): boolean => {
  if (!balance || !nftType) {
    return false;
  }
  if (isSettled && nftType === NftType.AUCTIONED) {
    return balance > 0;
  }
  return checkNftAvailabilityByTimelinesV2(eventStatus, nftType, nftAvailability);
};

/**
 * @deprecated
 * @param nft
 * @param auction
 */
export const getNftsLeft = (nft: CmsExhibitionNft, auction: CreatorAuction | undefined) => {
  if (auction) {
    return auction.is_settled ? 0 : 1;
  }

  return getNFTTotalOfferedQty(nft.cmsNft);
};

export const isNftCardBlured = (
  isPurchased = false,
  mayBePurchased: boolean = false,
  nftAvailability: AvailabilityStatus | undefined,
): boolean => {
  if (isPurchased) {
    return false;
  }

  if (!mayBePurchased && nftAvailability !== AvailabilityStatus.COMING_SOON) {
    return false;
  }

  if (mayBePurchased && nftAvailability === AvailabilityStatus.COMING_SOON) {
    return true;
  }

  return !mayBePurchased;
};

export const getCardBadge = (text: string, icon: unknown = null, variant: 'inverted' | 'error' = 'inverted') => (
  <Badge variant={variant} type="rounded" text={text} icon={icon} />
);

export const getNftCardBadge = (
  isPurchased: boolean,
  withUnlockableContent: boolean,
  hasPendingTransaction?: boolean,
): ReactElement | null => {
  if (hasPendingTransaction) {
    return NftAllocationPendingBadge();
  }
  if (isPurchased) {
    return getCardBadge(i18n.t('Your NFT'), <Success width="14px" height="14px" />);
  }
  if (withUnlockableContent) {
    return getCardBadge(i18n.t('Unlockable content'), <Lock className={lockClassName} />);
  }
  return null;
};

export const isNftCardSharable = (
  isPurchased = false,
  eventTimelineStatus: EventTimelineStatus | undefined,
  isAllSold: boolean = false,
  availability: NftAvailability = NftAvailability.ONGOING,
): boolean => {
  if (isPurchased) {
    return true;
  }

  if (availability === NftAvailability.ONGOING && !isAllSold) {
    return true;
  }

  return eventTimelineStatus === EventTimelineStatus.STARTED && !isAllSold;
};

export const isNftCardWithButtons = (
  isPurchased: boolean = false,
  isOngoing: boolean,
  isAccessNft: boolean,
  isAllSold: boolean,
): boolean => {
  if (isPurchased) {
    return false;
  }

  return !isAllSold && (isOngoing || isAccessNft);
};

export const getNftCardByTimeline = (nft: CmsExhibitionNft, eventStatus: EventTimelineStatus) => {
  const isPending = useNftIsPending(nft.cmsNft?.creatorNft?.nft_id);
  const isAuction = isAuctionedNft(nft);
  const {t} = useLocalization();

  if (eventStatus === EventTimelineStatus.FINISHED) {
    return <NftCardBigFinished />;
  }

  return (
    <NftCardBigNotStarted
      buttonText={isAuction ? t('Bid') : t('Buy')}
      withUnlockableContent={false}
      badge={isPending ? <NftAllocationPendingBadge /> : null}
    />
  );
};

export const getNftCardButtonTextFinished = (purchased: boolean, balance: number) => {
  if (purchased) {
    return i18n.t('View NFT');
  }
  if (!balance) {
    return i18n.t('Sold out');
  }
  return i18n.t('Offer ended');
};

export const getNftCardButtonText = (
  purchaseText: string,
  purchased: boolean,
  balance: number,
  pending: boolean,
  eventStatus: EventTimelineStatus,
  nftAvailability: NftAvailability,
  nftType: CmsExhibitionNftRelType | undefined,
  isSettled?: boolean,
  isAvailableOnlyOnMarketplace?: boolean,
) => {
  if (purchased || pending) {
    return enableMarketplaceView() ? i18n.t('Sell') : i18n.t('View NFT');
  }
  if (
    (eventStatus === EventTimelineStatus.NOT_STARTED && nftType !== CmsExhibitionNftRelType.ACCESS) ||
    nftAvailability === NftAvailability.COMING_SOON
  ) {
    return i18n.t('Coming soon');
  }
  if (isAvailableOnlyOnMarketplace) {
    return i18n.t('Buy on Marketplace');
  }
  if (nftAvailability === NftAvailability.PASSED) {
    return i18n.t('Offer ended');
  }
  if (!balance) {
    return i18n.t('Sold out');
  }
  if (isSettled && nftType === CmsExhibitionNftRelType.AUCTIONED) {
    if (balance > 0) {
      return i18n.t('Buy');
    }
    return i18n.t('Sold out');
  }
  return purchaseText;
};

export const getSegmentClassname = (defaultClassname?: string, purchased?: boolean, pending?: boolean) => {
  if (purchased || pending) {
    return GoogleAnalyticsId.ViewNftBtn;
  }

  return defaultClassname;
};
