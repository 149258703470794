import {useCallback, useContext} from 'react';
import * as Sentry from '@sentry/react';
import {UserContext} from './user-context/user-context';
import {getWalletSigner} from '../shared/services/get-signer-and-provider';
import {Signer} from '../shared/types/signer';
import {useWallet} from './use-wallet';

export const useGetSigner = (publicKey: string): (() => Promise<Signer>) => {
  const wallet = useWallet(publicKey);
  const {userData} = useContext(UserContext);

  return useCallback(() => {
    if (wallet) {
      return getWalletSigner(wallet, userData.token);
    }
    const message = `Can't get signer for unknown wallet with public key ${publicKey}`;
    Sentry.captureMessage(message);
    throw Error(message);
  }, [publicKey, userData.token, wallet]);
};
