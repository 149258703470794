import {PendingTransactionTypes, usePendingTransactionsContext} from '../../context/pending-transaction-context';
import {useQuery} from '@apollo/client';
import {GET_NFT_OWNER_OFFER} from '../queries/offers';
import {CMSNFTAuction, CMSNFTOffer} from './use-nft-listing';
import {useUserWalletsPubKeys} from '../../context/use-user-wallets-pub-keys';
import {useEffect} from 'react';

const POLLING_INTERVAL = 5000; // 5 sec

export const useOwnerOffer = (nftAddress: string | undefined) => {
  const {getPendingTransactionForNft, clearPendingTransactionForNft} = usePendingTransactionsContext();
  const wallets = useUserWalletsPubKeys();
  const {data, loading, stopPolling, startPolling} = useQuery<{
    creatorMakeOffers: CMSNFTOffer[];
    creatorAuctions: CMSNFTAuction[];
  }>(GET_NFT_OWNER_OFFER, {
    variables: {nftAddress, wallets},
    skip: !nftAddress || !wallets.length,
  });
  const pendingTransaction = getPendingTransactionForNft(nftAddress);
  useEffect(() => {
    if (!pendingTransaction) {
      stopPolling();
      return;
    }
    if (
      pendingTransaction.type === PendingTransactionTypes.SELL_NFT ||
      pendingTransaction.type === PendingTransactionTypes.CANCEL_NFT_LISTING
    ) {
      startPolling(POLLING_INTERVAL);
    }
    return stopPolling;
  }, [pendingTransaction, startPolling, stopPolling]);

  useEffect(() => {
    if (!pendingTransaction || !data || loading) {
      return;
    }
    const [ownerOffer] = data?.creatorMakeOffers;
    const [ownerAuction] = data?.creatorAuctions;

    if (pendingTransaction?.type === PendingTransactionTypes.CANCEL_NFT_LISTING && !ownerOffer) {
      clearPendingTransactionForNft(nftAddress);
    }
    if (pendingTransaction?.type === PendingTransactionTypes.SELL_NFT && ownerOffer) {
      clearPendingTransactionForNft(nftAddress);
    }
    if (pendingTransaction?.type === PendingTransactionTypes.SELL_NFT && ownerAuction) {
      clearPendingTransactionForNft(nftAddress);
    }
  }, [nftAddress, data, loading, pendingTransaction, clearPendingTransactionForNft]);

  return {
    data,
    loading,
  };
};
