import {useContext, useMemo, useState} from 'react';
import {Form} from 'react-final-form';
import {Button} from '@cere/rxb-template-ui-kit';
import {NftCardInterface} from '@cere/services-types';
import clsx from 'clsx';
import mime from 'mime-types';
import {Typography, makeStyles} from '@material-ui/core';

import {ReactComponent as DownloadIcon} from 'assets/download.svg';
import {UserPaymentHistoryContext} from 'context/payments-history/user-payment-history.context';
import {useGetRequestContentDownloadLink} from 'shared/helpers/requestContentDownloadLink ';
import {useLocalization} from 'shared/hooks/use-locale.hook';

type Response = [string, Blob];

const createDownloadURL = ([contentType, blob]: Response): string =>
  contentType ? URL.createObjectURL(new Blob([blob], {type: contentType})) : URL.createObjectURL(blob);

export const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down('lg')]: {
      minHeight: '30px',
      minWidth: '30px',
      padding: '0 0 4px 0',
    },
  },
  buttonHeight: {
    background: 'rgba(22, 22, 22, 0.7)',
    backdropFilter: 'blur(22px)',
    '&:hover': {
      background: 'rgba(22, 22, 22, 0.7)',
      backdropFilter: 'blur(22px)',
    },

    [theme.breakpoints.up('lg')]: {
      height: '48px',
      width: '184px',
      backgroundColor: theme.palette.background.paper,
      '&:hover': {
        backgroundColor: theme.palette.background.paper,
      },
    },
  },
  downloadIcon: {
    [theme.breakpoints.down('lg')]: {
      '& path': {
        stroke: theme.palette.background.paper,
      },
    },
  },
  downloadButtonText: {
    display: 'inline',
    marginLeft: '12px',
    marginTop: '12px',
    [theme.breakpoints.down('lg')]: {
      display: 'none',
    },
  },
  downloadLink: {
    display: 'none',
  },
}));

interface FormButtonProps {
  nft?: NftCardInterface;
}

export const DownloadContentButton = ({nft}: FormButtonProps) => {
  const {t} = useLocalization();
  const [assetContent, setAssetContent] = useState<Response | undefined>();
  const {getWalletWithNftPublicKey} = useContext(UserPaymentHistoryContext);
  const initialValues = useMemo(() => ({content: assetContent}), [assetContent]);
  const classes = useStyles();
  const nftOwnerPublicKey = getWalletWithNftPublicKey(nft?.address);
  const requestContentDownloadLink = useGetRequestContentDownloadLink(nft, nftOwnerPublicKey, setAssetContent);

  return (
    <Form initialValues={initialValues} onSubmit={requestContentDownloadLink}>
      {({handleSubmit, submitting, values}) => {
        const extension = mime.extension(values.content?.[0]) || '';

        return (
          <>
            <Button
              component={values.content ? 'a' : 'button'}
              download={values.content ? `${nft?.title}.${extension}` : undefined}
              href={values.content ? createDownloadURL(values.content) : undefined}
              onClick={values.content ? undefined : handleSubmit}
              disabled={submitting}
              variant="contained"
              className={clsx(classes.buttonHeight, classes.root)}
            >
              <DownloadIcon className={classes.downloadIcon} />
              <Typography variant="h4" className={classes.downloadButtonText}>
                {t('Download NFT')}
              </Typography>
            </Button>
            <Button
              component={'a'}
              href={values.content ? createDownloadURL(values.content) : undefined}
              className={classes.downloadLink}
              download={values.content ? `${nft?.title}.${extension}` : undefined}
              id="linkId"
            />
          </>
        );
      }}
    </Form>
  );
};
