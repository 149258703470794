import {createContext, FC, useCallback, useContext, useMemo, useState, useEffect} from 'react';
import {PaymentStatusEnum} from '@cere/services-types';

import {BuyNFTLimitedOrTicketModal} from '../shared/components/BuyNFTLimitedModal';
import {NFTPurchaseConfirmedModal} from '../shared/components/NFTPurchaseConfirmedModal';
import {NFTPurchaseDeniedModal} from '../shared/components/NFTPurchaseDeniedModal';
import {BuyNFTLimitedOrTicketModalContinue} from '../shared/components/BuyNFTLimitedModalContinue';
import {useSingletonPollNftStatus} from '../shared/hooks/nft-status-poll.hook';
import {usePurchaseWithNonCustody} from '../shared/hooks/purchase.hook';
import {useLocalization} from '../shared/hooks/use-locale.hook';
import {TOKEN_DECIMALS} from '../shared/lib/formatPrice';
import {humanReadableError} from '../shared/lib/error-handler';
import analyticService, {AnalyticEventsEnum} from '../shared/services/analytic.service';
import {getPopupUrlByEmailAndNftId} from '../shared/services/payment.service';
import {getIsPaymentSuccess} from '../shared/helpers/paymentStatus';

import {PendingTransactionTypes, usePendingTransactionsContext} from './pending-transaction-context';
import {UserContext} from './user-context/user-context';
import {NftContext} from '../shared/components/Nft/nft-context-v2';
import {useSelectedWallet} from './use-selected-wallet';
import {UserPaymentHistoryContext} from './payments-history/user-payment-history.context';
import {secondaryOfferPrice} from './purchase-nft-context';

enum PurchaseCallbackTypes {
  BUY_FROM_MINTER_BY_CARD = 'buyFromMinterByCard',
  BUY_FROM_MINTER = 'buyFromMinter',
}

export type PrimaryPurchaseData = {
  nftId: string;
  nftAddress: string;
  nftMinterAddress: string;
  nftTitle: string;
  nftImage?: string;
  nftCreatorName: string;
  nftUsdPrice: number;
  nftAmount: number;
  nftEventSlug: string;
  nftCollectionAddress?: string;
  quantity?: number;
};

export interface IPrimaryPurchaseContext {
  startPrimaryPurchase: (purchaseData: PrimaryPurchaseData, isContinue?: boolean) => void;
}

export const PrimaryPurchaseContext = createContext<IPrimaryPurchaseContext>({
  startPrimaryPurchase: () => {},
});

export const useStartPrimaryPurchase = () => {
  const {startPrimaryPurchase} = useContext<IPrimaryPurchaseContext>(PrimaryPurchaseContext);
  return startPrimaryPurchase;
};

export enum PrimaryPurchaseNFTModalTypes {
  LIMITED_TICKET_PURCHASE_MODAL = 'LIMITED_TICKET_PURCHASE_MODAL',
  LIMITED_TICKET_PURCHASE_MODAL_CONTINUE = 'LIMITED_TICKET_PURCHASE_MODAL_CONTINUE',
  PURCHASE_CONFIRMED_MODAL = 'PURCHASE_CONFIRMED_MODAL',
  PURCHASE_FAIL_MODAL = 'PURCHASE_FAIL_MODAL',
}

export const PrimaryPurchaseContextProvider: FC = ({children}) => {
  const {t, locale} = useLocalization();
  const {setPendingTransactionForNft, getPendingTransactionForNft} = usePendingTransactionsContext();
  const {userData} = useContext(UserContext);
  const {quantity} = useContext(NftContext);
  const selectedWallet = useSelectedWallet();
  const {setPollingEnabled, statusesByNftId, sessionParamsById} = useSingletonPollNftStatus();
  const {checkAndShowSwitchNetworkDialog, handleNonCustodyPurchase, purchaseError} = usePurchaseWithNonCustody();
  const {getNftIsPurchased, getPaymentStatuses} = useContext(UserPaymentHistoryContext);

  const [purchaseData, setPurchaseData] = useState<PrimaryPurchaseData | null>(null);
  const [activeModalType, setActiveModalType] = useState<PrimaryPurchaseNFTModalTypes | null>(null);
  const [prevModalType, setPrevModalType] = useState<PrimaryPurchaseNFTModalTypes | null>(null);
  const [purchaseCallback, setPurchaseCallback] = useState<PurchaseCallbackTypes | null>(null);
  const [isProcessing, setProcessing] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const showPurchaseModal = (isContinue: boolean) =>
    setActiveModalType(
      isContinue
        ? PrimaryPurchaseNFTModalTypes.LIMITED_TICKET_PURCHASE_MODAL_CONTINUE
        : PrimaryPurchaseNFTModalTypes.LIMITED_TICKET_PURCHASE_MODAL,
    );
  const showPurchaseSuccessModal = () => setActiveModalType(PrimaryPurchaseNFTModalTypes.PURCHASE_CONFIRMED_MODAL);
  const showPurchaseFailedModal = () => setActiveModalType(PrimaryPurchaseNFTModalTypes.PURCHASE_FAIL_MODAL);
  const closeModals = () => {
    analyticService.track(AnalyticEventsEnum.EXHIBIT_WALLET_CLOSE_BUTTON_CLICKED);
    setActiveModalType(null);
  };

  const startPrimaryPurchase = useCallback(
    (purchaseData: PrimaryPurchaseData, isContinue: boolean = false) => {
      setPurchaseData({quantity: purchaseData.quantity || 1, ...purchaseData});
      showPurchaseModal(isContinue);
    },
    [setPurchaseData],
  );

  const finishFlow = useCallback(() => {
    closeModals();
    setPurchaseData(null);
  }, []);

  const buyFromMinter = useCallback(async () => {
    if (!purchaseData) {
      return;
    }
    setPurchaseCallback(PurchaseCallbackTypes.BUY_FROM_MINTER);
    try {
      setProcessing(true);
      setPollingEnabled(true);
      const priceInTokens = purchaseData.nftUsdPrice * TOKEN_DECIMALS;

      const isCorrectNetworkSelected = await checkAndShowSwitchNetworkDialog();
      if (!isCorrectNetworkSelected) {
        setErrorMessage(t('Wrong network selected!'));
        closeModals();
        return;
      }

      await handleNonCustodyPurchase(
        purchaseData.nftMinterAddress,
        purchaseData.nftAddress,
        priceInTokens,
        purchaseData?.quantity ?? 1,
        purchaseData.nftCollectionAddress,
      );
      await setPendingTransactionForNft({
        wallet: selectedWallet.publicKey || '',
        type: PendingTransactionTypes.BUY_NFT,
        nftId: purchaseData!.nftAddress ?? '',
      });
      showPurchaseSuccessModal();
    } catch (error) {
      setErrorMessage(humanReadableError(error));
      console.error(error);
      setPrevModalType(activeModalType);
      showPurchaseFailedModal();
    } finally {
      setProcessing(false);
      setPollingEnabled(false);
    }
  }, [
    checkAndShowSwitchNetworkDialog,
    handleNonCustodyPurchase,
    purchaseData,
    selectedWallet.publicKey,
    setPendingTransactionForNft,
    setPollingEnabled,
    activeModalType,
    t,
  ]);

  const buyFromMinterByCard = useCallback(async () => {
    if (!purchaseData) {
      return;
    }
    setPurchaseCallback(PurchaseCallbackTypes.BUY_FROM_MINTER_BY_CARD);
    let win;
    try {
      setProcessing(true);
      setPollingEnabled(true);
      const nftTitle = purchaseData.nftTitle;
      const artistName = purchaseData.nftCreatorName;
      const nftId = purchaseData.nftAddress;
      win = window.open('', t('Pay by card'), 'left=100,top=100,width=800,height=600');

      let url;
      const status = statusesByNftId[nftId];
      if (status === PaymentStatusEnum.FIAT_PAYMENT_PENDING) {
        url = sessionParamsById[nftId].paymentUrl;
      } else {
        url = await getPopupUrlByEmailAndNftId({
          buyerEmail: userData.userEmail ?? '',
          nftId: purchaseData!.nftAddress,
          quantity: purchaseData?.quantity ?? 1,
          locale,
        });
      }

      if (url) {
        analyticService.track(AnalyticEventsEnum.CHECKOUT_STARTED, {
          nftId,
          nftTitle,
          artistName,
        });
        await win?.location.replace(url);

        await setPendingTransactionForNft({
          wallet: selectedWallet.publicKey || '',
          type: PendingTransactionTypes.BUY_NFT,
          nftId: purchaseData!.nftAddress ?? '',
        });
      }
    } catch (error) {
      win?.close();
      setErrorMessage(error.message);
      setPrevModalType(activeModalType);
      showPurchaseFailedModal();
      setProcessing(false);
      console.error(error);
    }
  }, [
    purchaseData,
    selectedWallet.publicKey,
    sessionParamsById,
    setPendingTransactionForNft,
    setPollingEnabled,
    statusesByNftId,
    t,
    locale,
    userData.userEmail,
    activeModalType,
  ]);

  const CALLBACKS = useMemo(
    () => ({
      [PurchaseCallbackTypes.BUY_FROM_MINTER]: buyFromMinter,
      [PurchaseCallbackTypes.BUY_FROM_MINTER_BY_CARD]: buyFromMinterByCard,
    }),
    [buyFromMinter, buyFromMinterByCard],
  );

  const onTryAgainClick = useCallback(() => {
    if (purchaseCallback) {
      setActiveModalType(prevModalType);
      CALLBACKS[purchaseCallback]();
    }
  }, [purchaseCallback, prevModalType, CALLBACKS]);

  const updatePurchaseNftQuantity = useCallback(
    (quantity: number) => {
      setPurchaseData({...purchaseData!, quantity});
    },
    [purchaseData],
  );

  useEffect(() => {
    const isPending = getPendingTransactionForNft(purchaseData?.nftAddress);
    const nftIsPurchased =
      getNftIsPurchased(purchaseData?.nftAddress) ||
      getIsPaymentSuccess(...getPaymentStatuses(purchaseData?.nftAddress));

    if (isPending && nftIsPurchased) {
      showPurchaseSuccessModal();
    }
  }, [getNftIsPurchased, getPaymentStatuses, getPendingTransactionForNft, purchaseData?.nftAddress]);

  const value = useMemo(
    () => ({
      startPrimaryPurchase,
    }),
    [startPrimaryPurchase],
  );

  return (
    <PrimaryPurchaseContext.Provider value={value}>
      {children}
      {purchaseData && (
        <>
          <BuyNFTLimitedOrTicketModal
            nftId={purchaseData.nftId}
            nftTitle={purchaseData.nftTitle}
            nftAddress={purchaseData.nftAddress}
            nftImage={purchaseData.nftImage}
            author={purchaseData.nftCreatorName}
            updateNftQuantity={updatePurchaseNftQuantity}
            price={purchaseData.nftUsdPrice}
            open={activeModalType === PrimaryPurchaseNFTModalTypes.LIMITED_TICKET_PURCHASE_MODAL}
            isProcessing={isProcessing}
            onCancel={finishFlow}
            buyNft={buyFromMinter}
            buyNftByCard={buyFromMinterByCard}
            maxCount={purchaseData.nftAmount}
            quantity={quantity}
          />
          <BuyNFTLimitedOrTicketModalContinue
            nftId={purchaseData.nftId}
            nftTitle={purchaseData.nftTitle}
            nftImage={purchaseData.nftImage}
            price={Number(secondaryOfferPrice) || purchaseData.nftUsdPrice}
            open={activeModalType === PrimaryPurchaseNFTModalTypes.LIMITED_TICKET_PURCHASE_MODAL_CONTINUE}
            isProcessing={isProcessing}
            onCancel={finishFlow}
            buyNft={buyFromMinter}
            buyNftByCard={buyFromMinterByCard}
          />
          <NFTPurchaseConfirmedModal
            nftId={purchaseData.nftId}
            author={purchaseData.nftCreatorName}
            nftTitle={purchaseData.nftTitle}
            nftAddress={purchaseData.nftAddress}
            nftImage={purchaseData.nftImage}
            quantity={purchaseData?.quantity ?? 1}
            price={purchaseData.nftUsdPrice}
            open={activeModalType === PrimaryPurchaseNFTModalTypes.PURCHASE_CONFIRMED_MODAL}
            onClose={finishFlow}
          />
          <NFTPurchaseDeniedModal
            nftId={purchaseData.nftId}
            author={purchaseData.nftCreatorName}
            nftTitle={purchaseData.nftTitle}
            nftAddress={purchaseData.nftAddress}
            nftImage={purchaseData.nftImage}
            quantity={purchaseData?.quantity ?? 1}
            eventSlug={purchaseData.nftEventSlug}
            price={purchaseData.nftUsdPrice}
            error={{message: errorMessage || purchaseError}}
            open={activeModalType === PrimaryPurchaseNFTModalTypes.PURCHASE_FAIL_MODAL}
            onClose={finishFlow}
            onTryAgainClick={onTryAgainClick}
            isProcessing={isProcessing}
          />
        </>
      )}
    </PrimaryPurchaseContext.Provider>
  );
};
