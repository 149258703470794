import {providers} from 'ethers';

import {NetworkNotSupportedError} from './errors/NetworkNotSupportedError';
import {WalletConnectionStorage} from './WalletConnectionStorage';
import {IWalletConnector} from './connectors/IWalletConnector';
import {ConnectWalletOptions, SupportedWalletTypes} from './types';
import {WalletNotConnectedError} from './errors/WalletNotConnectedError';

export class WalletConnectionManager {
  private provider: providers.Web3Provider | null = null;
  private availableConnectors: Map<SupportedWalletTypes, IWalletConnector> = new Map<
    SupportedWalletTypes,
    IWalletConnector
  >();
  private availableProviders: Map<SupportedWalletTypes, providers.Web3Provider> = new Map<
    SupportedWalletTypes,
    providers.Web3Provider
  >();

  constructor(private readonly walletConnectionStorage: WalletConnectionStorage, private readonly networkId: number) {}

  async connect(connector: IWalletConnector, options?: ConnectWalletOptions): Promise<string> {
    this.availableConnectors.set(connector.type, connector);
    this.provider = await connector.connectToWallet(options);
    this.availableProviders.set(connector.type, this.provider);
    const connectedNetwork = await this.provider.getNetwork();
    if (connectedNetwork.chainId !== this.networkId) {
      await this.disconnect(connector.type);
      throw new NetworkNotSupportedError(this.networkId);
    }

    const address = await this.provider.getSigner().getAddress();
    this.walletConnectionStorage.setConnectedWalletsTypes(Array.from(this.availableConnectors.keys()));
    return address.toLowerCase();
  }

  async disconnect(type: SupportedWalletTypes): Promise<void> {
    const connector = this.availableConnectors.get(type);
    if (connector?.disconnect) {
      await connector.disconnect();
    }
    this.availableConnectors.delete(type);
    this.provider = null;
    this.walletConnectionStorage.setConnectedWalletsTypes(Array.from(this.availableConnectors.keys()));
  }

  async getSigner(): Promise<providers.JsonRpcSigner | null> {
    return this.provider?.getSigner() ?? null;
  }

  setActiveWallet(type: SupportedWalletTypes): void {
    const provider = this.availableProviders.get(type);
    if (!provider) {
      throw new WalletNotConnectedError(type);
    }
    this.walletConnectionStorage.setLastActiveWallet(type);
    this.provider = provider;
  }
}
