import {FC} from 'react';
import {makeStyles, Theme} from '@material-ui/core';
import clsx from 'clsx';
import colors from '../../../styles/colors';

const useStyles = makeStyles<Theme, {isLoading?: boolean}>((theme) => ({
  tabItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '40px',

    [theme.breakpoints.up('md')]: {
      height: '34px',
    },
    [theme.breakpoints.up('lg')]: {
      height: '44px',
      cursor: ({isLoading}) => (isLoading ? 'default' : 'inherit'),
    },
  },
  active: {
    cursor: 'default',
    color: colors.white,
    borderRadius: '2rem',
    background: theme.palette.info.main,
    fontWeight: 600,
  },
}));

export interface TabProps {
  selected: boolean;
  onClick: () => void;
  isLoading: boolean;
}

export const Tab: FC<TabProps> = ({children, selected, onClick, isLoading = false}) => {
  const styles = useStyles({isLoading});
  return (
    <div className={clsx('tab', styles.tabItem, {[styles.active]: selected})} onClick={onClick}>
      {children}
    </div>
  );
};
