import {useContext, useMemo} from 'react';
import {UserContext} from './user-context/user-context';

export const useUserWalletsPubKeys = (): string[] => {
  const {userData, nonCustodyWallets} = useContext(UserContext);
  const wallets = [userData.userPublicKey ?? '', ...nonCustodyWallets.map((wallet) => wallet.publicKey)]
    .filter((publicKey) => publicKey !== '')
    .filter(Boolean)
    .map((key) => key.toLowerCase());
  wallets.sort();
  const walletsHash = wallets.join('.');
  return useMemo(() => walletsHash.split('.'), [walletsHash]);
};
