import {EventTimelineStatus} from '../../../shared/types/event';
import {AvailabilityStatus, ExhibitCardInterface, NftCardInterface} from '@cere/services-types';

export const getTimelineStatus = (exhibition?: {
  startsAt: string;
  endsAt: string;
  isComingSoon?: boolean;
}): EventTimelineStatus => {
  if (!exhibition || exhibition?.isComingSoon) {
    return EventTimelineStatus.NOT_STARTED;
  }

  const eventStartDate = new Date(exhibition.startsAt);
  const eventEndDate = new Date(exhibition.endsAt);
  const currentDate = new Date();

  if (currentDate < eventStartDate) return EventTimelineStatus.NOT_STARTED;
  if (!exhibition.startsAt) return EventTimelineStatus.NOT_STARTED;
  else if (currentDate > eventEndDate) return EventTimelineStatus.FINISHED;
  else return EventTimelineStatus.STARTED;
};

export const getTimelineStatusV2 = (nft: NftCardInterface, exhibition?: ExhibitCardInterface): EventTimelineStatus => {
  if (!exhibition || nft.availability === AvailabilityStatus.COMING_SOON) {
    return EventTimelineStatus.NOT_STARTED;
  }

  const eventStartDate = new Date(exhibition.startsAt ?? '');
  const eventEndDate = new Date(exhibition.endsAt ?? '');
  const currentDate = new Date();

  if (currentDate < eventStartDate) return EventTimelineStatus.NOT_STARTED;
  if (!exhibition.startsAt) return EventTimelineStatus.NOT_STARTED;
  else if (currentDate > eventEndDate) return EventTimelineStatus.FINISHED;
  else return EventTimelineStatus.STARTED;
};
