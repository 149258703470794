import {useMemo, useContext} from 'react';
import {getIsPaymentSuccess} from '../../shared/helpers/paymentStatus';
import {UserPaymentHistoryContext} from '../../context/payments-history/user-payment-history.context';
import {ExhibitContext} from '../../context/exhibition/exhibit-context';

type Options = {
  fromAllNFTs?: boolean;
};

export const useAccessTicket = (options?: Options) => {
  const {getPaymentStatuses, getNftIsPurchased} = useContext(UserPaymentHistoryContext);
  const {accessNfts, event} = useContext(ExhibitContext);

  return useMemo(() => {
    if (options?.fromAllNFTs) {
      return (event?.nfts ?? []).find(
        (nft) =>
          getNftIsPurchased(nft.cmsNft?.creatorNft?.nft_id) ||
          getIsPaymentSuccess(...getPaymentStatuses(nft.cmsNft?.creatorNft?.nft_id)),
      );
    }

    return accessNfts.find(
      (nft) =>
        getNftIsPurchased(nft.cmsNft?.creatorNft?.nft_id) ||
        getIsPaymentSuccess(...getPaymentStatuses(nft.cmsNft?.creatorNft?.nft_id)),
    );
  }, [accessNfts, getNftIsPurchased, getPaymentStatuses, options?.fromAllNFTs, event?.nfts]);
};
