import {FC, useCallback, useContext, useEffect, useState} from 'react';
import {getBrightcoveJwtToken} from '../../services/video.service';
import {UserContext} from '../../../context/user-context/user-context';

import {MediaPlayer} from './MediaPlayer';
import {MediaPlayerWithRestrictedAccessProps} from './types';

export const MediaPlayerWithRestrictedAccess: FC<MediaPlayerWithRestrictedAccessProps> = ({
  videoId,
  onJwtLoaded,
  ...props
}) => {
  const [videoToken, setVideoToken] = useState<string | undefined>();
  const {userData} = useContext(UserContext);

  const loadJwtToken = useCallback(async () => {
    if (!videoId) {
      return;
    }

    const {userPublicKey, token} = userData;

    try {
      const jwt = await getBrightcoveJwtToken(videoId, {userPublicKey}, token);

      onJwtLoaded?.(true);
      setVideoToken(jwt);
    } catch (error) {
      console.log(error);
      onJwtLoaded?.(false);
    }
  }, [videoId, userData, onJwtLoaded]);

  useEffect(() => {
    loadJwtToken();
  }, [loadJwtToken]);

  return <MediaPlayer videoId={videoId} videoToken={videoToken} {...props} />;
};
