import {useMediaQuery, useTheme, Box, Grid, GridSize, makeStyles} from '@material-ui/core';

import {useLocalization} from 'shared/hooks/use-locale.hook';

import {Title, SubTitle} from '../Title';
import {NftCardSkeleton} from './NftCardSkeleton';
import {Skeleton} from './Skeleton';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: '60px',
    marginBottom: '28px',
    marginLeft: 'auto',
    marginRight: 'auto',

    width: '342px',

    [theme.breakpoints.up('md')]: {
      width: '688px',
    },
    [theme.breakpoints.up('lg')]: {
      width: '1264px',
    },
  },
  header: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'space-between',
    },
  },
  filterBox: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridColumnGap: '10px',
    width: '343px',
    paddingTop: '10px',

    [theme.breakpoints.up('lg')]: {
      width: '397px',
    },
  },
  filterButton: {
    width: '166px',
    height: '40px',
    borderRadius: '20px',

    [theme.breakpoints.up('lg')]: {
      width: '190px',
      height: '44px',
      borderRadius: '22px',
    },
  },
  gridBox: {
    paddingTop: '24px',
  },
}));

export const NftCollectiblesSceleton = () => {
  const {t} = useLocalization();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isMobileOrTable = useMediaQuery(theme.breakpoints.down('lg'));
  const sceletonsCount = isMobileOrTable ? 2 : 4;

  const styles = useStyles();

  return (
    <Box className={styles.root}>
      <Box className={styles.header}>
        <Box>
          <Title>{t('NFT collectibles')}</Title>
          <SubTitle>
            {t('Exclusive and verified digital collectibles which unlock unseen experiences of creators')}
          </SubTitle>
        </Box>
        <Box className={styles.filterBox}>
          <Skeleton variant="rect" className={styles.filterButton} />
          <Skeleton variant="rect" className={styles.filterButton} />
        </Box>
      </Box>
      <Grid container spacing={isMobile ? 1 : 2} className={styles.gridBox}>
        {Array(sceletonsCount)
          .fill(0)
          .map((_, index) => (
            <Grid item xs={(12 / sceletonsCount) as GridSize}>
              <NftCardSkeleton key={index} isNftCollectiblesCard />
            </Grid>
          ))}
      </Grid>
    </Box>
  );
};
