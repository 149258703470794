import {MouseEvent, useState, useMemo} from 'react';
import {useHistory, generatePath} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {Box, Button, Menu, IconButton, makeStyles} from '@material-ui/core';
import {AccountCircle, ArrowDropDown, Menu as MenuIcon} from '@material-ui/icons';

import {ROUTES} from '../../../constants/routes';
import {useThemeBreakpoints} from '../../../styles/useThemeBreakpoints';
import {UserDropdownBox} from '../UserDropdownBox';
import type {WalletType} from '../Wallet/types';
import type {HeaderNavLinkType} from './types';

const useStyles = makeStyles((theme) => ({
  icon: {
    width: '36px',
    height: '36px',
    backgroundColor: theme.palette.grey[300],
  },
  accountIconBox: {
    cursor: 'pointer',
  },
  button: {
    textTransform: 'none',
  },
  menuPaper: {
    [theme.breakpoints.down('md')]: {
      top: '0 !important',
      left: '0 !important',
      right: 0,
      maxWidth: '100vw',
      minWidth: '100vw',
      maxHeight: '100%',
      minHeight: 'unset',
    },
  },
  menuList: {
    paddingTop: 0,
    paddingBottom: 0,
  },
}));

type Props = {
  navLinks: HeaderNavLinkType[];
  isUserAuth: boolean;
  userName?: string;
  userLogo?: string;
  usersNftsCount?: number;
  isHaveUnreadedNotifications: boolean;
  wallets: WalletType[];
  activeWallet: string;
  setActiveWallet: (wallet: string) => void;
  onLogOut: () => void;
  hideBackButton?: boolean;
};

export const HeaderControl = ({
  isUserAuth,
  userName,
  userLogo,
  usersNftsCount,
  isHaveUnreadedNotifications,
  onLogOut,
  navLinks,
  activeWallet,
  setActiveWallet,
  wallets,
}: Props) => {
  const {t, i18n} = useTranslation();
  const history = useHistory();
  const styles = useStyles();
  const {isDesktop} = useThemeBreakpoints();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const SIGN_IN_LINK = useMemo(() => generatePath(ROUTES.SIGN_IN, {locale: i18n.language}), [i18n.language]);

  if (!isUserAuth) {
    return (
      <Button className={styles.button} variant="text" color="secondary" onClick={() => history.push(SIGN_IN_LINK)}>
        {t('Sign in')}
      </Button>
    );
  }

  return (
    <>
      {!isDesktop ? (
        <IconButton size="small" color="primary" onClick={handleClick}>
          <MenuIcon />
        </IconButton>
      ) : (
        <Box className={styles.accountIconBox} onClick={handleClick}>
          <IconButton className={styles.icon}>
            <AccountCircle />
          </IconButton>
          <ArrowDropDown />
        </Box>
      )}

      <Menu
        anchorEl={anchorEl}
        keepMounted
        variant="menu"
        classes={{paper: styles.menuPaper, list: styles.menuList}}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <UserDropdownBox
          isUserAuth={isUserAuth}
          userName={userName}
          userLogo={userLogo}
          usersNftsCount={usersNftsCount}
          isHaveUnreadedNotifications={isHaveUnreadedNotifications}
          onClose={handleClose}
          onExitClick={onLogOut}
          navLinks={navLinks}
          activeWallet={activeWallet}
          setActiveWallet={setActiveWallet}
          wallets={wallets}
        />
      </Menu>
    </>
  );
};
