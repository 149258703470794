import {ReactElement, useCallback, useEffect, useState} from 'react';
import {Typography} from '@cere/rxb-template-ui-kit';

import {PaymentTypeEnum} from '@cere/services-types';
import Countdown from 'react-countdown';
import {CountdownRenderProps} from 'react-countdown/dist/Countdown';
import {getAveragePaymentProcessingTime} from '../../services/payment.service';
import i18n from '../../../i18n';

const MIN_COUNTDOWN_TIME = 3000;

const countdownRenderer = ({hours, minutes, seconds}: CountdownRenderProps) => (
  <span>
    {hours ? `${hours} ${i18n.t('hours')}` : ''} {minutes ? `${minutes} ${i18n.t('min')}` : ''}{' '}
    {seconds ? `${seconds} ${i18n.t('sec')}` : ''}
  </span>
);

const AverageNftPaymentCountdown = ({
  type,
  text,
  className,
  onComplete,
  averagePaymentTime,
}: {
  type: PaymentTypeEnum;
  text: string;
  averagePaymentTime?: number;
  className?: string;
  onComplete?: Function;
}): ReactElement | null => {
  const [paymentFinishedIn, setPaymentFinishedIn] = useState<number>();
  const [inProgress, setInProgress] = useState(true);
  const getAveragePaymentTime = useCallback(async () => {
    const diff: number = Math.max(
      await getAveragePaymentProcessingTime(type),
      averagePaymentTime || MIN_COUNTDOWN_TIME,
    );
    const date: number = Date.now() + diff;
    setPaymentFinishedIn(date);
  }, [type, averagePaymentTime]);

  useEffect(() => {
    void getAveragePaymentTime();
  }, [getAveragePaymentTime]);

  const complete = () => {
    setInProgress(false);
    onComplete?.();
  };

  if (!inProgress) {
    return null;
  }

  return (
    <Typography variant="h5" className={className}>
      {text}{' '}
      {paymentFinishedIn && <Countdown date={paymentFinishedIn} renderer={countdownRenderer} onComplete={complete} />}
    </Typography>
  );
};

export default AverageNftPaymentCountdown;
