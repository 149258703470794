import {useContext, useMemo} from 'react';
import {useParams} from 'react-router-dom';
import {AvailabilityStatus, NftType} from '@cere/services-types';

import {enableMarketplaceView} from 'config/common';
import {NftCollapseDetails} from 'shared/components/NftDetailsInfo';
import {NFTDetailsCard} from 'shared/components/NFTDetailsCard/NftCard';
import {NFTListingsCard} from 'shared/components/NFTListingsCard';
import {NFTBidHistory} from 'shared/components/NFTBidHistory';
import {SellingExplainer} from 'shared/components/NFTSellingExplainer';
import {NftContext} from 'shared/components/Nft/nft-context-v2';
import {getBidHistoryStatus, removeProcessingBid} from 'shared/services/auction.service';
import {Box, makeStyles} from '@material-ui/core';

interface NftPageRouteParams {
  cmsNftIncrementId: string;
}

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.up('lg')]: {
      '& > *': {
        marginBottom: '16px',
      },
    },
  },
}));

export const NftPage = () => {
  const {nft, event, market, button} = useContext(NftContext);
  const isComingSoon = nft.availability === AvailabilityStatus.COMING_SOON || !event;
  const classes = useStyles();
  const {cmsNftIncrementId} = useParams<NftPageRouteParams>();

  const bids = useMemo(() => {
    nft.auction?.bids?.forEach((bid) => {
      removeProcessingBid(bid.txHash, nft.address);
    });

    return nft.auction?.bids || [];
  }, [nft]);
  const bidsWithStatuses = getBidHistoryStatus(nft.address, bids);

  return (
    <Box className={classes.container}>
      {market.owned > 0 && !button.disabled && <SellingExplainer />}
      <NFTDetailsCard />
      {!isComingSoon && enableMarketplaceView() && <NFTListingsCard />}
      {!isComingSoon && <NftCollapseDetails cmsNftIncrementId={cmsNftIncrementId} />}
      {!isComingSoon && nft.nftType === NftType.AUCTIONED && <NFTBidHistory bids={bidsWithStatuses!} />}
    </Box>
  );
};
