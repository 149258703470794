import {useCallback, useRef} from 'react';
import {Box, Button, Divider, Typography, CircularProgress, makeStyles} from '@material-ui/core';
import ErrorCircleIcon from '@material-ui/icons/Error';
import clsx from 'clsx';
import {PurchaseModal} from '../PurchaseModal';
import colors from '../../../styles/colors';
import {CopyButton} from '../CopyButton';
import {TOKEN_TITLE} from '../../lib/formatPrice';
import {useHistory, useRouteMatch} from 'react-router-dom';
import NftModalHighlightCard from '../NftModalHighlightCard';
import {mobileLandscapeMediaQuery} from '../../../styles/mediaQueries';
import {useLocalization} from '../../hooks/use-locale.hook';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: '22px',
    [theme.breakpoints.up('md')]: {
      paddingTop: '32px',
    },
    [mobileLandscapeMediaQuery(theme)]: {
      display: 'flex',
      gap: '20px',
    },
  },
  subBox: {
    [mobileLandscapeMediaQuery(theme)]: {
      width: '50%',
    },
  },
  description: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    margin: '8px 0 24px 0',
    fontFamily: 'inherit',
    letterSpacing: 'inherit',
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '16px',
    [theme.breakpoints.up('md')]: {
      marginTop: '24px',
    },
    [mobileLandscapeMediaQuery(theme)]: {
      flexDirection: 'row-reverse',
      justifyContent: 'flex-start',
    },
  },
  button: {
    width: '100%',
    height: '40px',
    borderRadius: '50px',
    textTransform: 'none',

    '&:not(:last-child)': {
      marginBottom: '8px',
      [mobileLandscapeMediaQuery(theme)]: {
        marginBottom: 0,
        marginLeft: '8px',
      },
    },

    [mobileLandscapeMediaQuery(theme)]: {
      width: '180px',
    },

    [theme.breakpoints.up('lg')]: {
      height: '48px',
    },
  },
  link: {
    textDecoration: 'none',
  },
  icon: {
    '--size': '18px',
    height: 'var(--size)',
    width: 'var(--size)',
    color: theme.palette.secondary.main,
  },
  dataLabel: {
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '22px',
    color: colors.grey,
    marginBottom: '4px',
    fontFamily: 'inherit',
    letterSpacing: 'inherit',
  },
  dark: {
    color: colors.primaryDark,
  },
  dataText: {
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '22px',
    color: colors.primaryDark,
    marginBottom: '4px',
    maxWidth: '325px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontFamily: 'inherit',
    letterSpacing: 'inherit',
  },
  dataContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  copyButtonContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& button, & button svg': {
      width: '20px',
      height: '20px',
    },
  },
  dataLine: {
    marginBottom: '18px',
  },
  totalAmount: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    '& p': {
      margin: 0,
    },
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  arrowIcon: {
    marginTop: '-3px',
  },
}));

export interface NFTPurchaseDeniedModalProps {
  nftId: string;
  author: string;
  nftTitle: string;
  nftAddress: string;
  nftImage?: string;
  price: number;
  quantity: number;
  open: boolean;
  error: {message: string};
  eventSlug: string;
  onClose: () => void;
  onTryAgainClick?: () => void;
  isProcessing?: boolean;
}

export const NFTPurchaseDeniedModal = ({
  nftId,
  author,
  nftTitle,
  nftAddress,
  nftImage,
  price,
  quantity,
  open,
  eventSlug,
  error,
  onClose,
  onTryAgainClick,
  isProcessing,
}: NFTPurchaseDeniedModalProps) => {
  const {t, locale} = useLocalization();
  const styles = useStyles();
  const nftIdRef = useRef<HTMLElement | null>(null);
  const history = useHistory();
  const isNftPage = useRouteMatch(`/${locale}/home/nft/${nftId}`);

  const onBackClick = useCallback(() => {
    if (isNftPage) {
      onClose && onClose();
      return;
    }
    eventSlug && history.push(`/${locale}/home/exhibit/${eventSlug}`);
    onClose && onClose();
  }, [isNftPage, eventSlug, history, onClose, locale]);

  return (
    <PurchaseModal
      open={open}
      icon={<ErrorCircleIcon className={styles.icon} />}
      title={t('Payment failed')}
      onClose={onClose}
    >
      <Typography className={styles.description}>{error?.message}</Typography>
      <Box className={styles.root}>
        <Box className={styles.subBox}>
          <Box className={clsx(styles.dataContainer, styles.dataLine)}>
            <Box className={clsx(styles.column)}>
              <NftModalHighlightCard
                nftId={nftId}
                title={author}
                nftTitle={nftTitle}
                nftImage={nftImage}
                onClick={onClose}
              />
            </Box>
          </Box>
          <Box className={clsx(styles.dataContainer, styles.dataLine)}>
            <Box className={styles.column}>
              <Typography className={styles.dataLabel}>{t('NFT ID')}</Typography>
              <Box className={styles.copyButtonContainer}>
                <Typography className={styles.dataText} ref={nftIdRef}>
                  {nftAddress}
                </Typography>
                <CopyButton node={nftIdRef} />
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className={styles.subBox}>
          <Box className={clsx(styles.column, styles.dataLine)}>
            <Box className={styles.dataContainer}>
              <Typography className={styles.dataLabel}>{t('Quantity')}</Typography>
              <Typography className={styles.dataText}>{quantity}</Typography>
            </Box>
            <Divider />
          </Box>
          <Box className={clsx(styles.column, styles.dataLine)}>
            <Box className={styles.dataContainer}>
              <Typography className={styles.dataLabel}>{t('Price')}</Typography>
              <Typography className={styles.dataText}>{`${price} ${TOKEN_TITLE}`}</Typography>
            </Box>
            <Divider />
          </Box>
          <Box className={styles.column}>
            <Box className={styles.dataContainer}>
              <Typography className={clsx(styles.dataLabel, styles.dark)}>{t('Total amount')}</Typography>
              <Typography className={clsx(styles.dataText, styles.totalAmount)}>
                {`${price * quantity} ${TOKEN_TITLE}`}{' '}
                <Typography className={styles.dataLabel}>
                  (~{price * quantity}
                  {' USD'})
                </Typography>
              </Typography>
            </Box>
            <Divider />
          </Box>
        </Box>
      </Box>
      <Box className={styles.buttonContainer}>
        {onTryAgainClick && (
          <>
            <Button
              color="primary"
              variant="contained"
              className={styles.button}
              onClick={onTryAgainClick}
              disabled={isProcessing}
            >
              {isProcessing ? <CircularProgress /> : t('Try again')}
            </Button>
          </>
        )}
        <Button className={styles.button} onClick={onBackClick}>
          {isNftPage?.isExact ? t('Back to NFT') : t('Back to exhibit')}
        </Button>
      </Box>
    </PurchaseModal>
  );
};
