import {MarketplacePageStore} from './types';
import {MARKETPLACE_PAGE_MODULE_NAME} from './constants';
import {NoSuchModuleError} from '../../base/NoSuchModuleError';

export const selectMarketplacePageData = (store: MarketplacePageStore) => {
  if (!store[MARKETPLACE_PAGE_MODULE_NAME]) {
    throw new NoSuchModuleError(MARKETPLACE_PAGE_MODULE_NAME);
  }
  return store[MARKETPLACE_PAGE_MODULE_NAME];
};

export const selectMarketplacePageFeaturedNftsIds = (store: MarketplacePageStore) =>
  selectMarketplacePageData(store).featuredNfts;

export const selectMarketplacePageCollectableNftsIds = (store: MarketplacePageStore) =>
  selectMarketplacePageData(store).nftCollectables;

export const selectMarketplacePageFeaturedCreators = (store: MarketplacePageStore) =>
  selectMarketplacePageData(store).featuredCreators;

export const selectMarketplacePageLoadingState = (store: MarketplacePageStore) =>
  selectMarketplacePageData(store).loadingState;
