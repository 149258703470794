import {Box} from '@material-ui/core';
import {Counter, Typography} from '@cere/rxb-template-ui-kit';
import clsx from 'clsx';

import {ReactComponent as LockHourglass} from 'assets/lock.svg';

import {CmsExhibition} from 'shared/types/graphql';
import {isEventStarted} from 'shared/lib/CompareDates';
import {ExhibitionTeaserType} from 'shared/types/exhibition-teaser';
import {useLocalization} from 'shared/hooks/use-locale.hook';

import {useStyles} from '../Exhibition-preview-container/live-styles';
import {ExhibitTeaser} from '../Exhibition-preview-container/exhibit-teaser';

type Props = {
  event: CmsExhibition;
};

export const NoAccess = ({event}: Props) => {
  const shouldShowTeaser = Boolean(event?.teaser?.content);
  const styles = useStyles({});
  const {t} = useLocalization();

  return (
    <Box className={styles.noAccess}>
      <Box className={styles.exhibitionInfo}>
        <Box className={styles.exhibitionInfoLeft}>
          <Box className={styles.exhibitionCounter}>
            <Typography variant="caption1">
              {isEventStarted(event.endsAt) ? t('Exhibit ends in') : t('Exhibit starts in')}
            </Typography>
            <Counter
              withSec
              startsAt={isEventStarted(event.endsAt) ? new Date(event.endsAt) : new Date(event.startsAt)}
              withCellParameters={true}
              cellParametersClassName={styles.cellParamClassName}
              cellClassName={styles.cellClassName}
              delimiterClassName={styles.cellClassName}
              containerClassName={styles.counterContainer}
            />
          </Box>
          <Box className={styles.exhibitionTitles}>
            <Typography className={styles.eventSubtitle} variant="h5">
              {event?.subtitle}
            </Typography>
            <Box className={styles.exhibitionTitleWithoutTheme}>{event?.title}</Box>
          </Box>

          <Box className={styles.lockContentInfo}>
            <Box className={styles.iconStyle}>
              <LockHourglass />
            </Box>
            <Typography variant="body2" className={styles.lockedExhibitDescription}>
              {t('Purchase NFT tickets below to gain access to the exhibit')}
            </Typography>
          </Box>
        </Box>
        <Box className={styles.lockExhibitionImageBlock}>
          <Box className={styles.exhibitImagePreview}>
            <Box className={clsx(styles.enterLockExhibitButtonBlock, styles.enterButtonBlock)}>
              {shouldShowTeaser && (
                <Box className={styles.exhibitionTeaserContainer}>
                  <ExhibitTeaser type={ExhibitionTeaserType.REGULAR} />
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
