import {useContext} from 'react';
import {UserContext} from './user-context/user-context';
import {AppWallet, SupportedWallet} from '../shared/types/supported-wallet';
import {isNonCustodyWalletType} from '../shared/types/non-custody-wallet';

export const useSelectedWallet = (): {wallet: SupportedWallet; publicKey?: string} => {
  const {selectedWallet, nonCustodyWallets, userData} = useContext(UserContext);

  if (isNonCustodyWalletType(selectedWallet)) {
    const wallet = nonCustodyWallets.find(({type}) => type === selectedWallet);
    return wallet ? {publicKey: wallet.publicKey, wallet: selectedWallet} : {wallet: selectedWallet};
  }
  return {publicKey: userData.userPublicKey, wallet: AppWallet.DAVINCI};
};
