import {useCallback} from 'react';
import {useHistory} from 'react-router-dom';

import {useLocalization} from '../../shared/hooks/use-locale.hook';

export const useGoBack = (): (() => void) => {
  const history = useHistory();
  const {locale} = useLocalization();

  return useCallback(() => {
    try {
      const appOrigin = new URL(window.location.href).origin;
      const referrerOrigin = document.referrer ? new URL(document.referrer).origin : '';
      if (appOrigin === referrerOrigin || referrerOrigin === '') {
        history.goBack();
        return;
      }
    } catch (e) {
      history.push(`/${locale}/home`);
    }
    history.push(`/${locale}/home`);
  }, [history, locale]);
};
