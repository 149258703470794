import {Box, makeStyles} from '@material-ui/core';
import clsx from 'clsx';

import {useStyles as useBannerStyles} from 'pages/InterstitialEventPage/routes/InterstitialEventPage/styled';
import {Skeleton} from './Skeleton';
import {InterstitialNftTicketSkeleton} from './InterstitialNftTicketSkeleton';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.grey[200],
  },
  leftBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: '343px',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: '24px',
    [theme.breakpoints.up('md')]: {
      maxWidth: '447px',
    },
    [theme.breakpoints.up('lg')]: {
      alignItems: 'flex-start',
      maxWidth: '658px',
      marginLeft: 0,
      marginBottom: 0,
      marginRight: '90px',
    },
  },
  calendar: {
    width: '100%',
    height: '105px',
    borderRadius: '12px',
    marginBottom: '24px',
    [theme.breakpoints.up('md')]: {
      marginBottom: '32px',
    },
    [theme.breakpoints.up('lg')]: {
      width: '235px',
    },
  },
  title: {
    width: '170px',
    height: '88px',
    borderRadius: '12px',
    marginBottom: '24px',
    [theme.breakpoints.up('md')]: {
      width: '208px',
      height: '103px',
      marginBottom: '32px',
    },
    [theme.breakpoints.up('lg')]: {
      width: '616px',
      height: '150px',
    },
  },
  controlsBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row',
    },
  },
  buttonsBox: {
    display: 'flex',
    justifyContent: 'center',
    gap: '12px',
    marginBottom: '24px',
    [theme.breakpoints.up('md')]: {
      marginBottom: '32px',
    },
    [theme.breakpoints.up('lg')]: {
      gap: '32px',
      marginBottom: '0',
      marginRight: '32px',
    },
  },
  button: {
    width: '164px',
    height: '48px',
    borderRadius: '24px',
    [theme.breakpoints.up('md')]: {
      width: '171px',
    },
    [theme.breakpoints.up('lg')]: {
      width: '184px',
    },
  },
  desc: {
    width: '250px',
    height: '46px',
    borderRadius: '12px',
    [theme.breakpoints.up('lg')]: {
      width: '226px',
    },
  },
}));

export const InterstitialEventPageBannerSkeleton = () => {
  const bannerStyle = useBannerStyles();
  const styles = useStyles();

  return (
    <Box className={clsx(bannerStyle.bannerBox, styles.root)}>
      <Box className={styles.leftBox}>
        <Skeleton variant="rect" className={styles.calendar} />
        <Skeleton variant="rect" className={styles.title} />
        <Box className={styles.controlsBox}>
          <Box className={styles.buttonsBox}>
            <Skeleton variant="rect" className={styles.button} />
            <Skeleton variant="rect" className={styles.button} />
          </Box>
          <Skeleton variant="rect" className={styles.desc} />
        </Box>
      </Box>
      <InterstitialNftTicketSkeleton />
    </Box>
  );
};
