import {FullCreatorInterface, NftCardInterface} from '@cere/services-types';
import {BaseAction} from '../../base/BaseAction';

const MARKETPLACE_PAGE = '[MARKETPLACE PAGE]';

export class LoadMarketplaceDataCommand extends BaseAction {
  static type = `${MARKETPLACE_PAGE} Load data`;
}

export class MarketplaceFeaturedNftsDocument {
  static type = `${MARKETPLACE_PAGE} Featured NFTs`;
  static create(nfts: NftCardInterface[]) {
    return {
      type: this.type,
      payload: nfts,
    };
  }
}

export class MarketplaceCollectableNftsDocument {
  static type = `${MARKETPLACE_PAGE} Collectable NFTs`;
  static create(nfts: NftCardInterface[]) {
    return {
      type: this.type,
      payload: nfts,
    };
  }
}

export class MarketplaceFeaturedCreatorsDocument {
  static type = `${MARKETPLACE_PAGE} Featured creators`;
  static create(creators: FullCreatorInterface[]) {
    return {
      type: this.type,
      payload: creators,
    };
  }
}

export class MarketplaceFeaturedNftsLoadingFailedEvent {
  static type = `${MARKETPLACE_PAGE} Featured NFTs loading failed`;
  static create(message: string) {
    return {
      type: this.type,
      payload: message,
    };
  }
}

export class MarketplaceCollectablesNftsLoadingFailedEvent {
  static type = `${MARKETPLACE_PAGE} Collectables NFTs loading failed`;
  static create(message: string) {
    return {
      type: this.type,
      payload: message,
    };
  }
}

export class MarketplaceFeaturedCreatorsLoadingFailedEvent {
  static type = `${MARKETPLACE_PAGE} Featured creators loading failed`;
  static create(message: string) {
    return {
      type: this.type,
      payload: message,
    };
  }
}
