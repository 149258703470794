import {CmsAppConfig} from '@cere/services-types';
import {AnyAction} from 'redux';
import {AppConfigDocument} from './actions';

const defaultAppConfig: CmsAppConfig = {
  appTitle: '',
  logoFull: {url: '', alternativeText: '', caption: ''},
  logoShort: {url: '', alternativeText: '', caption: ''},
  facebookUrl: '',
  instagramUrl: '',
  twitterUrl: '',
  discordUrl: '',
  faqUrl: '',
  privacyPolicyUrl: '',
  termsOfServicesUrl: '',
  cereWalletTitle: '',
  signUpCta: '',
  isFiatPaymentDisabled: false,
  primaryMainColor: '',
  secondaryMainColor: '',
  secondaryDarkColor: '',
  buttonContainedPrimaryFirstColor: '',
  buttonContainedPrimarySecondColor: '',
  buttonContainedSecondaryColor: '',
  infoMainColor: '',
  backgroundPaperColor: '',
  backgroundDefaultColor: '',
  grey100Color: '',
  grey700Color: '',
};

export const appConfigReducer = (state: CmsAppConfig = defaultAppConfig, action: AnyAction): CmsAppConfig => {
  if (action.type === AppConfigDocument.type) {
    return action.payload;
  }
  return state;
};
