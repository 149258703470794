import {MouseEventHandler, ReactElement} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Box} from '@material-ui/core';
import {Typography} from '@cere/rxb-template-ui-kit';

import {ReactComponent as CloseIcon} from '../../../../../assets/close.svg';
import {ReactComponent as HourglassIcon} from '../../../../../assets/hourglass.svg';
import colors from '../../../../../styles/colors';
import AverageNftPaymentCountdown from '../../../../../shared/components/average-payment-countdown/average-payment-countdown.component';
import {PaymentTypeEnum} from '@cere/services-types';
import {useLocalization} from '../../../../../shared/hooks/use-locale.hook';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    borderRadius: '12px',
    background: colors.light,
    padding: '20px 70px 16px 20px',
    maxWidth: '360px',
    bottom: '8px',
    position: 'fixed',
    margin: '0 auto',
    left: 0,
    right: 0,
    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.12)',
    lineHeight: '16px',
    zIndex: 1500,
    [theme.breakpoints.up('md')]: {
      width: '687px',
      maxWidth: '687px',
    },
    [theme.breakpoints.up('lg')]: {
      width: '832px',
      maxWidth: '832px',
    },
  },
  icon: {
    flex: '0 0 auto',
    fill: theme.palette.info.main,
    marginRight: '16px',

    '& svg': {
      width: '28px',
      height: '28px',
    },
  },
  closeContainerIconStyle: {
    position: 'absolute',
    top: '18px',
    right: '16px',
    width: '30px',
    height: '100%',
    display: 'flex',

    '& svg': {
      background: colors.lighter,
      width: '30px',
      height: '30px',
      borderRadius: '20px',
      padding: '6px 8px',
      cursor: 'pointer',
      '& path': {
        fill: colors.lightGrey,
      },
    },
  },
  countdown: {
    color: colors.primaryDark,
    '& span': {
      color: colors['navbar-text'],
    },
  },
  textColor: {
    color: colors.primaryDark,
  },
  hourglassIconStyle: {
    marginTop: '-3px',
    marginRight: '8px',
    '& svg': {
      width: '28px',
      height: '28px',
      '& path': {
        fill: theme.palette.info.main,
      },
    },
  },
}));

interface NftAllocationCardProps {
  open: boolean;
  onClose: MouseEventHandler<unknown>;
}

const NftAllocationModal = ({open, onClose}: NftAllocationCardProps): ReactElement | null => {
  const classes = useStyles();
  const {t} = useLocalization();
  if (!open) return null;

  return (
    <Box className={classes.root}>
      <Box className={classes.hourglassIconStyle}>
        <HourglassIcon />
      </Box>
      <Box className={classes.closeContainerIconStyle}>
        <CloseIcon onClick={onClose} />
      </Box>
      <Box>
        <Box mb="11px">
          <Typography variant="h4" className={classes.textColor}>
            {t('NFT transaction processing')}
          </Typography>
        </Box>
        <Typography mb="16px" variant="caption1" className={classes.textColor}>
          {t(
            'Thank you for your purchase. Your transaction is currently being processed via our blockchain service and will soon be available in your wallet.',
          )}
        </Typography>
        <Box mt="16px">
          <AverageNftPaymentCountdown
            text={'Approximate time for NFT delivery'}
            className={classes.countdown}
            type={PaymentTypeEnum.USD_TO_NFT}
            onComplete={onClose}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default NftAllocationModal;
