import {useCallback, useEffect, useState} from 'react';
import {NftCardInterface} from '@cere/services-types';
import {gql, useQuery} from '@apollo/client';

import {useLocalization} from '../../shared/hooks/use-locale.hook';
import {nftApi} from '../../api';

const featuredMarketplaceNftsQuery = gql`
  query featuredMarketplaceNfts($locale: String) {
    cmsMarketplaces(locale: $locale) {
      marketplace_featured {
        featuredNfts {
          id
        }
      }
    }
  }
`;

type FeaturedMarketplaceNftsResponse = Array<{
  marketplace_featured: {
    featuredNfts: Array<{
      id: string;
    }>;
  };
}>;

export const useFeaturedMarketplaceNfts = () => {
  const {locale} = useLocalization();
  const [nfts, setNfts] = useState<NftCardInterface[] | null>(null);
  const {data, loading} = useQuery<{cmsMarketplaces: FeaturedMarketplaceNftsResponse}, {locale: string}>(
    featuredMarketplaceNftsQuery,
    {variables: {locale}},
  );
  const loadNfts = useCallback(
    async (ids: string[]) => {
      const nftsData = await nftApi.getNftsByIdsList(ids, locale);
      setNfts(nftsData || []);
    },
    [locale],
  );

  useEffect(() => {
    const ids = data?.cmsMarketplaces?.[0]?.marketplace_featured?.featuredNfts?.map((nft) => nft.id);
    if (!ids) {
      return;
    }
    void loadNfts(ids);
  }, [data?.cmsMarketplaces, loadNfts]);

  return {
    featuredNfts: nfts,
    loading: loading || !nfts,
  };
};
