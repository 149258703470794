import clsx from 'clsx';
import {Dispatch} from 'redux';
import {connect} from 'react-redux';
import {Button} from '../../primitives/Button';
import {useLocalization} from '../../../utils/hooks/use-localization';
import {StartNFTSellingCommand} from '../../../redux/modules/selling/actions';
import {makeStyles} from '@material-ui/core';
import colors from '../../../../styles/colors';

const useStyles = makeStyles((theme) => ({
  default: {
    background: theme.palette.background.paper,
  },
  disabled: {
    background: `${colors.disable} !important `,
    color: `${colors.light} !important`,
  },
}));

export interface SellButtonProps {
  className?: string;
  text?: string;
  disabled?: boolean;
  onClick: () => void;
}

export const PlainSellButton = ({className, text, disabled, onClick}: SellButtonProps) => {
  const {t} = useLocalization();
  const styles = useStyles();
  return (
    <Button
      className={clsx(className, styles.default, {[styles.disabled]: disabled})}
      secondary
      disabled={disabled}
      onClick={onClick}
    >
      {text || t('Sell')}
    </Button>
  );
};

const mapDispatchToProps = (dispatch: Dispatch, {nftId}: {nftId: string}) => ({
  onClick: () => dispatch(StartNFTSellingCommand.create(nftId)),
});

export const SellButton = connect(() => ({}), mapDispatchToProps)(PlainSellButton);
