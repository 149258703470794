import {memo, ReactNode} from 'react';
import {Box, Typography, makeStyles} from '@material-ui/core';
import {Link} from 'react-router-dom';

import {ImageSquare} from '../ImageSquare';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  link: {
    textDecoration: 'unset',
  },
  backdrop: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: theme.palette.grey[700],
    opacity: 0.9,
  },
  backdropInner: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translateX(-50%)',
    color: theme.palette.common.white,
  },
}));

type Props = {
  title: string;
  image: string;
  cardLink: string;
  children?: NonNullable<ReactNode>;
  imageBackdrop?: NonNullable<ReactNode>;
};

export const CardImage = memo(({title, image, cardLink, children, imageBackdrop}: Props) => {
  const styles = useStyles();

  return (
    <Box className={styles.root}>
      <Link to={cardLink} className={styles.link}>
        <ImageSquare image={image} title={title} />
      </Link>

      {imageBackdrop ? (
        <>
          <Box className={styles.backdrop} />
          <Typography className={styles.backdropInner}>{imageBackdrop}</Typography>
        </>
      ) : (
        children
      )}
    </Box>
  );
});
