import {useCallback, useContext, useMemo} from 'react';
import {useHistory} from 'react-router-dom';
import {NftType} from '@cere/services-types';
import {Exhibition, ExhibitionNft, isCmsNft} from './types';
import {CmsExhibitionNftRelType} from '../../types/graphql';
import {getIsPaymentPending} from '../../helpers/paymentStatus';
import {UserPaymentHistoryContext} from '../../../context/payments-history/user-payment-history.context';
import {getTimelineStatus} from '../../../pages/Сms/Exhibit/utils';
import {getNftAvailabilityByCmsNft, NftAvailability} from '../../services/nft.service';
import {findActualAuction} from './utils';
import {useNftListing} from '../../hooks/use-nft-listing';
import {usePendingTransactionsContext} from '../../../context/pending-transaction-context';
import {enableMarketplaceView} from '../../../config/common';
import {EventTimelineStatus} from '../../types/event';
import {ExhibitContext, ExhibitionEmptyContext} from '../../../context/exhibition/exhibit-context';
import analyticService, {AnalyticEventsEnum} from '../../services/analytic.service';
import {useUserHasEventAccess} from '../../../pages/ExhibitPage/use-user-has-event-access';
import {UserContext} from 'context/user-context/user-context';
import {useLocalization} from '../../hooks/use-locale.hook';

type Params = {
  balance: number;
  buy: () => Promise<unknown>;
  event?: Exhibition;
  nft?: ExhibitionNft;
  owned: number;
  share: () => unknown;
  sell: () => unknown;
  attributes: {
    offerEnded: boolean;
    soldOut: boolean;
    availableOnlyOnMarketplace: boolean;
  };
  isNftDetailsPage?: boolean;
};

export function useNftCtaButtonProps({
  nft,
  event,
  balance,
  buy,
  sell,
  owned,
  attributes,
  share,
  isNftDetailsPage,
}: Params): {
  disabled: boolean;
  label: string;
  loading: boolean;
  isLightButton: boolean;
  onClick: () => unknown;
  onShare: () => unknown;
} {
  const {t, locale} = useLocalization();
  const nftId = nft?.cmsNft?.creatorNft?.nft_id;
  const relType = nft?.relType;
  const isAuction = relType === (NftType.AUCTIONED as string);
  const isAuctionedOrLimited = relType !== (NftType.ACCESS as string);
  const {getNftIsPurchased, getPaymentStatuses} = useContext(UserPaymentHistoryContext);
  const purchased = getNftIsPurchased(nftId);
  const pending = getIsPaymentPending(...getPaymentStatuses(nftId));
  const history = useHistory();

  const userHasEventAccess = useUserHasEventAccess();

  const eventStatus = getTimelineStatus(event);
  const nftAvailability = getNftAvailabilityByCmsNft(nft).availability;

  const {
    offers,
    ownerOffer,
    loading: nftListingLoading,
  } = useNftListing({
    cmsNftIncrementId: nft?.cmsNft.id,
    start: 0,
    limit: 10,
  });

  const actualAuction = findActualAuction(nft?.cmsNft);
  const isSettled = actualAuction?.is_settled;
  const isComingSoon = Boolean(nft?.cmsNft.isComingSoon);

  const buttonIsLoading = !isComingSoon && (nft?.cmsNft == null || !isCmsNft(nft?.cmsNft) || nftListingLoading);

  const {symbol} = useContext(ExhibitContext);
  const isExhibitionPage = symbol !== ExhibitionEmptyContext;

  const buttonLabel = useMemo((): string => {
    if (buttonIsLoading) {
      return t('Loading...');
    }

    if (!nft?.cmsNft?.creatorNft || nft?.cmsNft.isComingSoon) {
      return t('Coming soon');
    }

    if (purchased || pending) {
      return enableMarketplaceView() ? t('Sell') : t('View NFT');
    }
    if (
      (eventStatus === EventTimelineStatus.NOT_STARTED && isAuctionedOrLimited) ||
      nftAvailability === NftAvailability.COMING_SOON
    ) {
      return t('Coming soon');
    }
    if (!isSettled && relType === CmsExhibitionNftRelType.AUCTIONED) {
      return t('Bid');
    }
    if (attributes.soldOut) {
      return t('Sold out');
    }
    if (attributes.offerEnded || nftAvailability === NftAvailability.PASSED) {
      return t('Offer ended');
    }
    if (attributes.availableOnlyOnMarketplace) {
      return isExhibitionPage || isNftDetailsPage ? t('Buy on Marketplace') : t('Buy');
    }
    if (isSettled && isAuction) {
      if (balance > 0) {
        return t('Buy');
      }
      return t('Sold out');
    }
    return t('Buy');
  }, [
    relType,
    attributes.availableOnlyOnMarketplace,
    attributes.offerEnded,
    attributes.soldOut,
    balance,
    buttonIsLoading,
    eventStatus,
    isExhibitionPage,
    isNftDetailsPage,
    isSettled,
    nft?.cmsNft?.creatorNft,
    nft?.cmsNft.isComingSoon,
    nftAvailability,
    pending,
    purchased,
    isAuctionedOrLimited,
    isAuction,
    t,
  ]);

  const {getPendingTransactionForNft} = usePendingTransactionsContext();
  const qtyOfferedByUser = ownerOffer?.qty || 0;
  const {userData} = useContext(UserContext);

  const disabled = useMemo(() => {
    const pendingTransaction = getPendingTransactionForNft(nftId);
    if (nft?.cmsNft.isComingSoon) {
      return true;
    }

    if (buttonIsLoading) {
      return true;
    }
    if (pendingTransaction && userData.userPublicKey) {
      return true;
    }
    if (purchased) {
      return qtyOfferedByUser > 0;
    }
    if (pending && userData.userPublicKey) {
      return true;
    }
    if (offers.length > 0) {
      return false;
    }
    if (attributes.availableOnlyOnMarketplace) {
      return false;
    }
    if (!isSettled && relType === CmsExhibitionNftRelType.AUCTIONED) {
      return false;
    }
    if (attributes.offerEnded) {
      return true;
    }
    if (nftAvailability === NftAvailability.COMING_SOON || nftAvailability === NftAvailability.PASSED) {
      return true;
    }
    if (!event) {
      return true;
    }
    if (isSettled && isAuction) {
      return balance < 1;
    }
    if (isAuctionedOrLimited && event.allowFreeAccess) {
      return false;
    }
    if (isAuctionedOrLimited && !event.allowFreeAccess && !buttonIsLoading && !pendingTransaction && purchased) {
      return !userHasEventAccess;
    }
    return !balance;
  }, [
    relType,
    getPendingTransactionForNft,
    nftId,
    nft?.cmsNft.isComingSoon,
    buttonIsLoading,
    purchased,
    pending,
    offers.length,
    attributes.availableOnlyOnMarketplace,
    attributes.offerEnded,
    nftAvailability,
    event,
    isSettled,
    isAuction,
    isAuctionedOrLimited,
    balance,
    qtyOfferedByUser,
    userHasEventAccess,
    userData.userPublicKey,
  ]);

  const isLightButton = useMemo(() => {
    if (nft?.cmsNft.isComingSoon) {
      return true;
    }

    return attributes.offerEnded ? !attributes.availableOnlyOnMarketplace : attributes.soldOut;
  }, [attributes.availableOnlyOnMarketplace, attributes.offerEnded, attributes.soldOut, nft?.cmsNft.isComingSoon]);

  const viewNft = useCallback(
    (nft: ExhibitionNft | undefined) => {
      if (nft && nft.relType) {
        analyticService.track(AnalyticEventsEnum.EXHIBIT_NFT_VIEW_CLICKED, {
          nftId: nft.cmsNft?.creatorNft?.nft_id ?? '',
          nftTitle: nft.cmsNft?.title ?? '',
          artistName: nft.cmsNft.cmsCreator?.name,
          eventName: event?.title ?? '',
          nftType: nft.relType,
        });

        history.push(`/${locale}/home/nft/${nft.cmsNft.id}`, {
          event,
          referrer: history.location.pathname,
          nft,
        });
      }
    },
    [event, history, locale],
  );

  const onClick = useCallback(() => {
    if (attributes.soldOut || attributes.offerEnded || nftAvailability === NftAvailability.PASSED) {
      return undefined;
    }
    if (isExhibitionPage && purchased) {
      return viewNft(nft);
    }

    if (enableMarketplaceView() && owned > 0) {
      return sell();
    }

    return nft && relType ? buy() : undefined;
  }, [buy, isExhibitionPage, nft, owned, purchased, relType, sell, viewNft, attributes, nftAvailability]);

  return useMemo(
    () => ({
      label: buttonLabel,
      loading: buttonIsLoading,
      disabled: Boolean(disabled),
      onClick,
      onShare: share,
      isLightButton,
    }),
    [buttonIsLoading, buttonLabel, disabled, isLightButton, onClick, share],
  );
}
