import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  makeStyles,
  Theme,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import {Link} from 'react-router-dom';
import {Trans} from 'react-i18next';
import clsx from 'clsx';

import {PageContainer} from 'shared/components/PageContainer';
import {PageLayout} from 'shared/components/PageLayout';
import {usePageLoaded} from 'shared/services/loading.service';
import {useLocalization} from 'shared/hooks/use-locale.hook';
import {useOtpVerification} from 'shared/hooks/use-otp-verification.hook';
import {Condition, ConditionsList} from 'shared/components/Conditions';
import {ExhibitsRowSkeleton, NftCollectiblesSceleton, VipCardSkeleton} from 'shared/components/Skeletons';
import {EventCardV2} from 'shared/components/EventCardBig/EventCardV2';
import {NftProvider2} from 'shared/components/Nft/nft-provider-v2';
import {NftCardV3} from 'pages/HomePage/NftCard/nft-card-v3';
import {ExhibitionBFF} from 'pages/HomePage/types';
import {useHomePageNfts} from 'api/hooks/use-home-page-nfts';
import {useMemo, useCallback, useState} from 'react';
import {Numberish} from 'shared/types/numberish';
import analyticService, {AnalyticEventsEnum} from 'shared/services/analytic.service';
import {GoogleAnalyticsId} from 'analytics-ids';
import {NftInfoCollection} from 'pages/UserPages/components/nftInfoCollection';
import {useWalletNfts} from 'pages/UserPages/use-wallet-nfts';
import {ALL_WALLET} from 'pages/UserPages/types';
import {ReactComponent as EmptyCart} from '../../assets/empty-cart.svg';
import {CuratedRow} from 'shared/components/CuratedRow';
import {useThemeBreakpoints} from 'app-v2/styles/useThemeBreakpoints';
import {useNotifications} from 'pages/NotificationsPage/notifications.hook';
import {NotificationsListItem} from 'shared/components/Notifications/notifications-list-item';
import {noop} from 'lodash';
import {useAllExhibits} from 'api/hooks/use-all-exhibit';
import {sortAllEvents} from 'shared/services/event.service';
import {AvailabilityStatus, CheckUserHasNftEnum} from '@cere/services-types';
import {WelcomeBackPopup} from './welcomeBack';
import {VipCard} from 'shared/components/VipRealEvent/VipCard';
import {useRealLifeEvents} from 'api/hooks/use-real-life-exhibits';
import {VipEventType} from 'shared/types/vipEvent';
import {getNFTCardImage} from 'shared/helpers/nfts';

const useStyles = makeStyles<Theme>((theme) => ({
  title: {
    marginBottom: '32px',
    marginTop: '24px',
    [theme.breakpoints.up('md')]: {
      marginTop: '40px',
      fontWeight: 800,
      fontSize: '40px',
      lineHeight: '48px',
    },
    [theme.breakpoints.up('lg')]: {
      marginBottom: '24px',
    },
  },
  selectorNftRoot: {
    position: 'relative',
    overflow: 'unset',
    borderRadius: '12px',
    boxShadow: 'none',

    width: '164px',
    [theme.breakpoints.up('md')]: {
      width: '208px',
    },
    [theme.breakpoints.up('lg')]: {
      width: '184px',
    },
  },
  selectorNftContent: {
    padding: '8px',
  },
  emptyNftList: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '8px 0',
  },
  emptyNftText: {
    textAlign: 'center',
    fontSize: '16px',
    color: theme.palette.grey[700],
  },
  loadingBox: {
    display: 'flex',
    justifyContent: 'center',
  },
  cardMedia: {
    '--size': '164px',
    borderRadius: '12px 12px 0 0',
    width: 'var(--size)',
    height: 'var(--size)',

    [theme.breakpoints.up('sm')]: {
      '--size': '208px',
    },
    [theme.breakpoints.up('lg')]: {
      '--size': '184px',
    },
  },
  newNftsPadding: {
    marginRight: '8px !important',
    [theme.breakpoints.up('md')]: {
      marginRight: '24px !important',
    },
  },
  myNftsPadding: {
    marginRight: '8px !important',
    [theme.breakpoints.up('md')]: {
      marginRight: '32px !important',
    },
  },
  newEventsPadding: {
    marginRight: '12px !important',
    [theme.breakpoints.up('md')]: {
      marginRight: '32px !important',
    },
  },
  titleStyles: {
    fontWeight: 700,
    fontSize: '20px',
    lineHeight: '26px',
    [theme.breakpoints.up('md')]: {
      fontWeight: 800,
      fontSize: '28px',
      lineHeight: '26px',
    },
    '& p': {
      fontWeight: 700,
      fontSize: '20px',
      lineHeight: '26px',
      [theme.breakpoints.up('md')]: {
        fontWeight: 800,
        fontSize: '28px',
        lineHeight: '26px',
      },
    },
  },
  notificationTitleStyles: {
    [theme.breakpoints.up('md')]: {
      marginTop: '32px',
    },
  },
  notificationsBox: {
    display: 'flex',
    flexDirection: 'column',
  },
  showMoreButton: {
    padding: '8px 16px',
    borderRadius: '100px',
    background: theme.palette.grey[300],
    margin: '16px auto 0 auto',
    textTransform: 'none',
  },
}));

const FeedPage = () => {
  usePageLoaded();
  useOtpVerification();
  const {t, locale} = useLocalization();

  const data = useAllExhibits();
  const exhibits = sortAllEvents(data.exhibits || []);
  const {vipEvents} = useRealLifeEvents();
  const {nfts} = useHomePageNfts();
  const filteredNfts = useMemo(
    () =>
      nfts
        ? nfts.filter(
            (nft) =>
              (nft?.availability === AvailabilityStatus.ONGOING && !!nft?.balance && nft?.balance > 0) ||
              nft.purchaseStatus === CheckUserHasNftEnum.USER_HAS_NFT,
          )
        : null,
    [nfts],
  );
  const trackNFTCollectableClick = useCallback(
    (nftId: Numberish) => analyticService.track(AnalyticEventsEnum.NFT_COLLECTABLE_CLICKED, {nftId}),
    [],
  );

  const {nfts: myNfts, isLoading} = useWalletNfts(ALL_WALLET);
  const {isDesktop} = useThemeBreakpoints();
  const {notifications} = useNotifications();

  const [showMoreNotifications, setShowMoreNotifications] = useState<boolean>(false);

  const notificationsToDisplay = useMemo(
    () => notifications.slice(0, !showMoreNotifications && notifications.length > 5 ? 5 : notifications.length),
    [notifications, showMoreNotifications],
  );
  const classes = useStyles();

  return (
    <PageLayout hideBackButton>
      <PageContainer>
        <Typography variant="h2" className={classes.title}>
          {t('Personal feed')}
        </Typography>
        <Box>
          <ConditionsList>
            <Condition condition={!exhibits}>
              <Box mb={3}>
                <ExhibitsRowSkeleton />
              </Box>
            </Condition>
            <Condition condition={!!exhibits}>
              <CuratedRow
                title={t(`Events you can't miss`)}
                isOverlay={!isDesktop}
                paddingStyles={classes.newEventsPadding}
                titleStyles={classes.titleStyles}
              >
                {exhibits &&
                  exhibits.map((event) => (
                    <EventCardV2 key={event.id} event={event as ExhibitionBFF} isCuttedCard={true} />
                  ))}
              </CuratedRow>
            </Condition>
          </ConditionsList>

          <ConditionsList>
            <Condition condition={!vipEvents}>
              <VipCardSkeleton />
            </Condition>
            <Condition condition={!!vipEvents}>
              <CuratedRow
                title={t(`Get ready for your VIP event`)}
                isOverlay={!isDesktop}
                paddingStyles={classes.newEventsPadding}
                titleStyles={classes.titleStyles}
                slidesPerView={isDesktop ? 1 : undefined}
              >
                {(vipEvents ?? []).map((event: VipEventType) => (
                  <VipCard key={event.id} event={event} isCuttedCard={true} />
                ))}
              </CuratedRow>
            </Condition>
          </ConditionsList>

          <ConditionsList>
            <Condition condition={!filteredNfts}>
              <NftCollectiblesSceleton />
            </Condition>
            <Condition condition={!!filteredNfts}>
              <CuratedRow
                title={t(`What's new for you`)}
                isOverlay={!isDesktop}
                paddingStyles={classes.newNftsPadding}
                titleStyles={classes.titleStyles}
              >
                {(filteredNfts ?? []).map((nft) => (
                  <NftProvider2 key={nft.id} nft={nft}>
                    <NftCardV3
                      nftId={nft.id}
                      imageUrl={nft.image}
                      nftType={nft.nftType}
                      availableFrom={nft.availableFrom?.toString()}
                      availableTo={nft.availableTo?.toString()}
                      availability={nft.availability}
                      nftAddress={nft.address}
                      nftTitle={nft.title}
                      nftDescription={nft.description}
                      balance={nft.balance}
                      supply={nft.supply}
                      usdPrice={nft.priceUsd}
                      onClick={trackNFTCollectableClick}
                      buttonClassName={GoogleAnalyticsId.BuyCollectiblesBtn}
                      isNftCollectiblesCard
                      isCutTitle
                      withOutShadow
                    />
                  </NftProvider2>
                ))}
              </CuratedRow>
            </Condition>
          </ConditionsList>

          <ConditionsList>
            <Condition condition={isLoading}>
              <Box className={classes.loadingBox}>
                <CircularProgress />
              </Box>
            </Condition>
            <Condition condition={myNfts.length > 0}>
              <CuratedRow
                title={t(`Your collectibles`)}
                isOverlay={!isDesktop}
                paddingStyles={classes.myNftsPadding}
                titleStyles={classes.titleStyles}
              >
                {myNfts.map((nft) => (
                  <Card key={nft.nft_id.nft_id} classes={{root: classes.selectorNftRoot}}>
                    <Link to={`/${locale}/home/nft/${nft.nft_id.nft_id}`}>
                      <CardMedia className={classes.cardMedia} image={getNFTCardImage(nft.nft_id.cmsNft)} />
                    </Link>

                    <CardContent classes={{root: classes.selectorNftContent}}>
                      <NftInfoCollection
                        title={nft.nft_id.cmsNft.title}
                        id={nft.nft_id.id}
                        cmsNft={nft.nft_id.cmsNft}
                        limit={nft.nft_id.cmsNft?.creatorNft?.supply ?? 0}
                        qtyOwned={nft.quantity}
                      />
                    </CardContent>
                  </Card>
                ))}
              </CuratedRow>
            </Condition>
            <Condition condition={myNfts.length === 0}>
              <Box className={classes.emptyNftList}>
                <EmptyCart />
                <Typography className={classes.emptyNftText}>
                  <Trans i18nKey="You do not yet own any NFTs.<br /> Discover the ongoing exhibits and buy an access NFT to gain access to more exclusive NFTs." />
                </Typography>
              </Box>
            </Condition>
          </ConditionsList>

          <Box>
            <Typography className={clsx(classes.titleStyles, classes.notificationTitleStyles)}>
              {t('Stay updated')}
            </Typography>

            <Box className={classes.notificationsBox}>
              {notificationsToDisplay.map((notification) => (
                <NotificationsListItem notification={notification} key={notification.id} onView={noop} onRead={noop} />
              ))}
              {!showMoreNotifications && (
                <Button className={classes.showMoreButton} onClick={() => setShowMoreNotifications(true)}>
                  {t('Show more notifications')}
                </Button>
              )}
            </Box>
          </Box>
        </Box>
        <WelcomeBackPopup />
      </PageContainer>
    </PageLayout>
  );
};

export default FeedPage;
