import {MouseEventHandler, SyntheticEvent, useEffect, useMemo, useRef, useState} from 'react';
import {alpha, Box, Card, CardMedia, Grid, makeStyles, Theme, useMediaQuery} from '@material-ui/core';
import useResizeObserver from 'use-resize-observer';
import clsx from 'clsx';
import {useQuery} from '@apollo/client';

import {GoogleAnalyticsId} from 'analytics-ids';
import {ReactComponent as InfoIcon} from 'assets/icons/infoIcon.svg';
import {useIsUserOwnsSomeAccessNft} from 'context/payments-history/use-is-user-owns-some-access-nft';
import {FIND_EXHIBITIONS_BY_IDS} from 'pages/HomePage/queries';
import {Button, Typography} from 'shared/components/UI';
import {TYPOGRAPHY_VARIANTS} from 'shared/components/UI/Typography/types';
import {BUTTON_VARIANTS} from 'shared/components/UI/Button/types';
import {WithPurchaseNftResult} from 'shared/components/WithPurchaseNft/v2';
import {getEventCardBadge, getEventCardStatus} from 'shared/components/EventCardBig/helpers';
import {link, StyledLink} from 'shared/components/StyledLink';
import {Avatar} from 'shared/components/Avatar';
import {NFTModalSimple} from 'shared/components/NFTModalSimple';
import {Exhibition} from 'shared/components/Nft/types';
import BootstrapLoader from 'shared/components/bootstrap-loader';
import {useLocalization} from 'shared/hooks/use-locale.hook';
import {BannerTitleHeight, useHover} from 'shared/hooks/use-hover.hook copy';
import {omitUndefined} from 'shared/lib/omit-undefined';
import {arrayToHash} from 'shared/lib/hash-utils';
import {getDottedDate} from 'shared/lib/utils';
import {CmsCreator, CmsExhibition, CmsExhibitionNft, CmsExhibitionNftRelType} from 'shared/types/graphql';
import {Optional} from 'shared/types/optional';
import {EventCardBadgeStatus} from 'shared/types/event';
import colors from 'styles/colors';

import {BannerImagesBlock} from '../imagesBlock';
import {NftProvider2} from 'shared/components/Nft/nft-provider-v2';
import {NFTAdditionsCard} from 'shared/components/NftAdditions/NftAdditionsCard';
import {AuctionStatus, AvailabilityStatus, NftType} from '@cere/services-types';
import {useExhibitNftsBySlug} from 'api/hooks/use-exhibit-nfts-by-slug';

type CmsExhibitionNftWithOptionalRelType = Optional<CmsExhibitionNft, 'relType'>;

export const isCmsExhibitionNft = (nft: CmsExhibitionNftWithOptionalRelType): nft is CmsExhibitionNft =>
  nft.relType != null;

interface ExhibitsBannerProps extends WithPurchaseNftResult {
  exhibit: Omit<CmsExhibition, 'nfts' | 'cmsCreator'> & {
    nfts: CmsExhibitionNftWithOptionalRelType[];
    cmsCreator: Pick<CmsCreator, 'id' | 'name' | 'avatar'>;
  };
  image: string;
  onClick: MouseEventHandler;
}

type ExhibitVars = {
  ids: number[];
  locale: string;
};

const useStyles = makeStyles<Theme, {isHover?: boolean}>(() => ({
  wrapper: {
    background: ({isHover}) =>
      isHover
        ? `linear-gradient(to top, ${colors.primaryDark} 0%, ${alpha(colors.primaryDark, 0.7)} 100%)`
        : `linear-gradient(to top, ${colors.primaryDark} 0%, ${alpha(colors.primaryDark, 0)} 100%)`,
    position: 'relative',
    height: '100%',
    width: '100%',
  },
  title: {
    color: colors.light,
    fontWeight: 'bold',
  },
  period: {
    color: colors.light,
    fontWeight: 600,
  },
}));

const useBaseStyles = makeStyles<Theme, {numberOfDescriptionLines: number; isHover: boolean}>((theme) => ({
  card: {
    height: '440px',
    position: 'relative',
    borderRadius: 0,

    [theme.breakpoints.down('md')]: {
      height: '375px',
    },
  },
  cardItem: {
    margin: '12px 0',
    '& div': {
      '& div': {
        '& button': {
          maxWidth: '90px',
          width: '90px',
          minWidth: '90px',
        },
      },
    },
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    paddingBottom: '64px',

    paddingLeft: '40px',
    paddingRight: '40px',

    [theme.breakpoints.down('md')]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'flex-end',

      paddingLeft: '16px',
      paddingRight: '16px',
      paddingBottom: '40px',
    },
  },
  period: {
    fontSize: '16px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
    },
  },
  periodOpenDescription: {
    marginTop: '8px',
  },
  title: {
    color: colors.light,
    fontWeight: 'bold',
    fontSize: '48px',
    lineHeight: ({isHover}) => (isHover ? '56px' : 'unset'),
    margin: '0 !important',

    [theme.breakpoints.down('md')]: {
      fontSize: '20px',
    },
  },
  exhibitControl: {
    minWidth: '102px',
    width: '168px',
    [theme.breakpoints.up('sm')]: {
      marginTop: '24px',
      width: '125px',
    },
  },
  badge: {
    position: 'absolute',
    zIndex: 2,
    top: '20px',
    left: '24px',
    '& h5': {
      fontWeight: 600,
      fontSize: '14px',
      lineHeight: '22px',
    },

    [theme.breakpoints.up('sm')]: {
      top: '42px',
      left: '40px',
    },
  },
  infoIcon: {
    position: 'absolute',
    zIndex: 2,
    top: '22px',
    right: '22px',

    [theme.breakpoints.up('md')]: {
      top: '38px',
      right: '38px',
    },
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  button: {
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',
    color: colors.light,
  },
  whiteButton: {
    backgroundColor: `${colors.light} !important`,
    color: colors.primaryDark,
  },
  descriptionText: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '21px',
    color: colors.white,
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: ({numberOfDescriptionLines}) => numberOfDescriptionLines,
    WebkitBoxOrient: 'vertical',
  },
  authorWrapper: {
    marginTop: '12px',
    display: ({isHover}) => (!isHover ? 'none' : 'flex'),
    '& p': {
      color: colors.light,
      lineHeight: '21px',
    },
    [theme.breakpoints.down('lg')]: {
      display: 'none',
    },
  },
  descriptionContainer: {
    marginTop: '24px',
    marginBottom: '-24px',
    display: ({isHover}) => (!isHover ? 'none' : 'block'),
    [theme.breakpoints.down('lg')]: {
      display: 'none',
    },
  },
  subtitles: {
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '22px',
    marginTop: '24px',

    [theme.breakpoints.up('md')]: {
      fontWeight: 700,
      fontSize: '20px',
      lineHeight: '26px',
    },
  },
}));

const useMediaStyles = makeStyles({
  root: {
    display: 'flex',
    height: '100%',
    width: '100%',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
});

const useModalStyles = makeStyles((theme) => ({
  status: {
    margin: '16px 0',
    '& h5': {
      fontWeight: 600,
      fontSize: '14px',
      lineHeight: '22px',
    },
  },
  title: {
    fontWeight: 700,
    fontSize: '20px',
    lineHeight: '26px',
  },
  period: {
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '22px',
    color: theme.palette.secondary.main,
    marginBottom: '16px',
  },
  authorWrapper: {
    '& p': {
      fontWeight: 600,
      fontSize: '14px',
      lineHeight: '22px',
    },
  },
  descriptionText: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '21px',
    margin: '20px 0',
  },
  buttonsBlock: {
    display: 'flex',
    justifyContent: 'center',
  },
  button: {
    width: '150px',
    minWidth: '150px',
    height: '44px',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',
  },
  viewExhibitButton: {
    backgroundColor: colors.primaryDark,
    color: colors.snowWhite,
  },
  modalContainer: {
    [theme.breakpoints.up('md')]: {
      '& .MuiDialog-paper': {
        padding: '24px',
        background: colors.light,
        borderRadius: '12px',
        minWidth: '686px',
        maxWidth: '686px',
      },
    },
  },
}));

export const ExhibitsBanner = ({exhibit, image, onClick}: ExhibitsBannerProps) => {
  const {t, locale} = useLocalization();
  const {ref, width} = useResizeObserver();
  const [numberOfDescriptionLines, setNumberOfDescriptionLines] = useState(1);
  const mediaClasses = useMediaStyles({width});
  const modalClasses = useModalStyles();

  const isDesktop = useMediaQuery<Theme>((theme) => `${theme.breakpoints.up('lg')}`);
  const [hoverRef, isHovered] = useHover();
  const [isHover, setIsHover] = useState(false);

  useEffect(() => {
    if (isDesktop) {
      setIsHover(isHovered);
    }
  }, [isHovered, isDesktop]);

  const classes = useStyles({isHover});
  const baseClasses = useBaseStyles({numberOfDescriptionLines, isHover});
  const status = getEventCardStatus({...exhibit, nfts: exhibit.nfts.filter(isCmsExhibitionNft)});
  const badge = getEventCardBadge(status);

  const refComponent = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (refComponent.current !== null) {
      const height = refComponent.current.getBoundingClientRect().height;
      if (height < BannerTitleHeight.ONE_LINE) {
        setNumberOfDescriptionLines(5);
      }
      if (height > BannerTitleHeight.TWO_LINES) {
        setNumberOfDescriptionLines(1);
      } else {
        setNumberOfDescriptionLines(3);
      }
    }
  }, [refComponent, numberOfDescriptionLines]);

  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = (event: SyntheticEvent) => {
    event.stopPropagation();
    setOpenModal(!openModal);
  };

  const {data} = useQuery<{exhibitions: Exhibition[]}, ExhibitVars>(FIND_EXHIBITIONS_BY_IDS, {
    variables: {ids: [Number(exhibit.id)], locale},
  });

  const {nfts, loading: nftsLoading} = useExhibitNftsBySlug(exhibit.slug);

  const accessNftIds: string[] = useMemo(() => {
    return data?.exhibitions[0]
      ? data.exhibitions[0]?.nfts.map((nft) => nft.cmsNft?.creatorNft?.nft_id).filter(omitUndefined)
      : [];
  }, [data]);
  const userHasAccess = useIsUserOwnsSomeAccessNft(arrayToHash(accessNftIds));

  let exhibitionNfts = useMemo(() => data?.exhibitions[0]?.nfts, [data]);

  if (status === EventCardBadgeStatus.TICKET_ON_SALE) {
    exhibitionNfts = data?.exhibitions[0]?.nfts.filter((nft) => nft.relType === CmsExhibitionNftRelType.ACCESS);
  }

  return (
    <div ref={hoverRef}>
      <Card ref={ref} className={clsx(baseClasses.card)} onClick={onClick}>
        <CardMedia classes={mediaClasses} image={image}>
          <Grid item className={clsx(baseClasses.wrapper, classes.wrapper)}>
            <Box>
              <p ref={refComponent} className={baseClasses.title}>
                {exhibit.title}
              </p>
              <Typography
                variant={TYPOGRAPHY_VARIANTS.button1}
                className={clsx(baseClasses.period, classes.period, isHover && baseClasses.periodOpenDescription)}
              >
                {`${getDottedDate(exhibit.startsAt)} - ${getDottedDate(exhibit.endsAt)}`}
              </Typography>
            </Box>
            <StyledLink
              to={`/${locale}/home/creator/${exhibit?.cmsCreator?.id}`}
              onClick={(event) => event.stopPropagation()}
              className={clsx(link, baseClasses.authorWrapper, GoogleAnalyticsId.ViewCreatorBtn)}
            >
              <Avatar
                name={exhibit?.cmsCreator?.name}
                imageUrl={exhibit?.cmsCreator?.avatar?.url}
                creatorId={exhibit.cmsCreator.id}
              />
            </StyledLink>

            <Box className={baseClasses.exhibitControl}>
              <Button
                className={clsx(baseClasses.button, baseClasses.whiteButton, GoogleAnalyticsId.ExhibitBannerBtn)}
                variant={BUTTON_VARIANTS.contained}
                fullWidth
                onClick={onClick}
              >
                {t('View exhibit')}
              </Button>
            </Box>

            <Box className={baseClasses.descriptionContainer}>
              <Typography className={baseClasses.descriptionText}>{exhibit.description}</Typography>
            </Box>
          </Grid>
          {isHover && !!exhibitionNfts && (
            <BannerImagesBlock
              isHovered={isHovered}
              url1={exhibitionNfts[0]?.cmsNft.assets[0]?.content?.url}
              url2={exhibitionNfts[1]?.cmsNft.assets[0]?.content?.url}
              url3={exhibitionNfts[2]?.cmsNft.assets[0]?.content?.url}
            />
          )}
        </CardMedia>

        <Box className={baseClasses.badge}>{badge}</Box>
        <InfoIcon className={baseClasses.infoIcon} onClick={handleOpenModal} />
      </Card>

      <NFTModalSimple
        open={openModal}
        title={t('The story behind this exhibit')}
        onClose={handleOpenModal}
        classes={modalClasses}
      >
        <Box className={modalClasses.status}>{badge}</Box>
        <Typography className={modalClasses.title}>{exhibit.title}</Typography>
        <Typography className={modalClasses.period}>
          {`${getDottedDate(exhibit.startsAt)} - ${getDottedDate(exhibit.endsAt)}`}
        </Typography>
        <StyledLink
          to={`/${locale}/home/creator/${exhibit?.cmsCreator?.id}`}
          onClick={(event) => event.stopPropagation()}
          className={clsx(link, modalClasses.authorWrapper, GoogleAnalyticsId.ViewCreatorBtn)}
        >
          <Avatar
            name={exhibit?.cmsCreator?.name}
            imageUrl={exhibit?.cmsCreator?.avatar?.url}
            creatorId={exhibit.cmsCreator.id}
          />
        </StyledLink>
        <Typography className={modalClasses.descriptionText}>{exhibit.description}</Typography>

        <Box className={modalClasses.buttonsBlock}>
          <Button
            className={clsx(modalClasses.button, modalClasses.viewExhibitButton, GoogleAnalyticsId.ExhibitBannerBtn)}
            variant={BUTTON_VARIANTS.contained}
            fullWidth
            onClick={onClick}
          >
            {t('View exhibit')}
          </Button>
        </Box>
        <Box>
          <Typography className={baseClasses.subtitles}>{t('Exhibit tickets')}</Typography>
          {!nftsLoading ? (
            nfts
              ?.filter((nft) =>
                (userHasAccess || data?.exhibitions[0].allowFreeAccess) &&
                nft.availability === AvailabilityStatus.ONGOING
                  ? nft.auctionStatus === AuctionStatus.ACTIVE || (!!nft.balance && nft.balance > 0)
                  : nft.nftType === NftType.ACCESS,
              )
              ?.map((nft) => (
                <Box className={baseClasses.cardItem}>
                  <NftProvider2 nft={nft}>
                    <NFTAdditionsCard />
                  </NftProvider2>
                </Box>
              ))
          ) : (
            <BootstrapLoader />
          )}
        </Box>
      </NFTModalSimple>
    </div>
  );
};
