import {Box, makeStyles} from '@material-ui/core';

import {Skeleton} from './Skeleton';

const useStyles = makeStyles((theme) => ({
  ticket: {
    width: '343px',
    marginLeft: 'auto',
    marginRight: 'auto',
    borderRadius: '12px',
    overflow: 'hidden',
    [theme.breakpoints.up('lg')]: {
      width: '400px',
    },
  },
  ticketImg: {
    width: '343px',
    height: '343px',
    [theme.breakpoints.up('lg')]: {
      width: '400px',
      height: '400px',
    },
  },
  ticketInfo: {
    backgroundColor: theme.palette.background.paper,
    padding: '12px',
  },
  ticketTitle: {
    width: '100%',
    height: '22px',
    borderRadius: '4px',
    marginBottom: '4px',
    [theme.breakpoints.up('md')]: {
      marginBottom: '6px',
    },
  },
  ticketDate: {
    width: '100%',
    height: '42px',
    borderRadius: '4px',
    marginBottom: '16px',
  },
  ticketPrice: {
    width: '100%',
    height: '22px',
    borderRadius: '4px',
    marginBottom: '12px',
    [theme.breakpoints.up('lg')]: {
      marginBottom: '12px',
    },
  },
  ticketButton: {
    width: '100%',
    height: '48px',
    borderRadius: '24px',
  },
}));

export const InterstitialNftTicketSkeleton = () => {
  const styles = useStyles();

  return (
    <Box className={styles.ticket}>
      <Skeleton variant="rect" className={styles.ticketImg} />
      <Box className={styles.ticketInfo}>
        <Skeleton variant="rect" className={styles.ticketTitle} />
        <Skeleton variant="rect" className={styles.ticketDate} />
        <Skeleton variant="rect" className={styles.ticketPrice} />
        <Skeleton variant="rect" className={styles.ticketButton} />
      </Box>
    </Box>
  );
};
