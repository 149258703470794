import {Box, CircularProgress, makeStyles, Typography} from '@material-ui/core';
import clsx from 'clsx';
import {usePollUserWalletBalance} from '../../../../shared/hooks/user-balance.hook';
import {formatPrice} from '../../../../shared/lib/formatPrice';
import {SupportedWallet} from '../../../../shared/types/supported-wallet';
import colors from '../../../../styles/colors';

const useStyles = makeStyles(() => ({
  page: {
    backgroundColor: colors.lighter,
    borderRadius: '12px',
    padding: '16px',
  },
  loader: {
    color: 'white',
    margin: 'auto',
  },
  balanceCere: {
    fontSize: '14px',
    fontWeight: 500,
    display: 'flex',
    alignItems: 'center',
    color: colors.disable,
    padding: '0 12px 0 36px',
  },
  active: {
    color: colors.lightGrey,
    marginLeft: '4px',
  },
}));

type Props = {
  walletType: SupportedWallet;
  isActive?: boolean;
};

export const WalletBalanceComponent = ({walletType, isActive}: Props) => {
  const classes = useStyles();
  const {walletBalance, isLoading} = usePollUserWalletBalance(walletType);
  if (isLoading) {
    return (
      <Box className={classes.page} display="flex">
        <CircularProgress className={classes.loader} />
      </Box>
    );
  }

  if (!walletBalance.usdc) {
    return null;
  }

  return (
    <Typography className={clsx(classes.balanceCere, isActive && classes.active)}>
      {formatPrice(walletBalance.usdc || 0)}
    </Typography>
  );
};
