import {ReactElement, useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import {Field, Form, FormSpy} from 'react-final-form';
import {BigNumber} from 'ethers';
import {
  alpha,
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  makeStyles,
  MenuItem,
  OutlinedInput,
  Popover,
  Select,
  Theme,
  useMediaQuery,
} from '@material-ui/core';
import {Typography} from '@cere/rxb-template-ui-kit';
import {AddCircle, Cancel} from '@material-ui/icons';
import clsx from 'clsx';
import {isAddress} from 'web3-utils';
import {FormApi} from 'final-form';

import {PageLayout} from '../../shared/components/PageLayout';
import {useStyles} from './styles';
import {useLoadWalletNfts} from '../UserPages/use-load-wallet-nfts';
import {UserContext} from '../../context/user-context/user-context';
import {NftSelector} from './nft-selector';
import {CreatorWalletNft} from '../../shared/types/graphql/creator-wallet-nfts';
import {ConfirmTransfer} from './confirm-transfer';
import {useConfirmProcess} from './use-confirm-process';
import {SubmitStepFn, TransferFormValues} from './types';
import {useNotifications} from '../../shared/hooks/use-notifications';
import {safeTransferFrom} from '../../shared/services/token-transfer.service';
import {useSelectedWallet} from '../../context/use-selected-wallet';
import {AppWallet, isAppWallet, SupportedWallet} from '../../shared/types/supported-wallet';
import {useGetFreeport} from '../../context/use-get-freeport';
import {ReactComponent as NoAvailableCoinTransferIcon} from '../../assets/icons/coinTransferNotAvailable.svg';
import {ReactComponent as InfoIcon} from '../../assets/info.svg';
import {NonCustodyWalletTitles} from '../../shared/types/non-custody-wallet';
import {useAuthorized} from 'shared/hooks/auth.hook';
import BootstrapLoader from '../../shared/components/bootstrap-loader';
import {useLocation} from 'react-router-dom';
import {AppContext} from '../../context/app-context';
import {useLocalization} from '../../shared/hooks/use-locale.hook';
import {useGetCollection} from '../../context/use-get-collection';
import {getCreatorNft} from '../../shared/services/nft.service';
import {humanReadableError} from '../../shared/lib/error-handler';
import {getNFTCardImage} from 'shared/helpers/nfts';
import {useNftRoyalty} from 'context/use-nft-royalties';
import {useLoadNftsByIds} from 'api/hooks/use-load-nft-by-id';
import {NftCardInterface} from '@cere/services-types/dist/types';
import {transformTokensToPrice} from 'app-v2/utils/helpers/price';

type FormValues = Omit<TransferFormValues, 'nfts'> & {
  selectedNfts: Set<string>;
};

const usePopoverStyles = makeStyles((theme) => ({
  paper: {
    borderRadius: '12px',
    boxShadow: `0px 4px 20px ${alpha(theme.palette.common.black, 0.12)}`,
    padding: '16px',
    width: '390px',

    [theme.breakpoints.down('lg')]: {
      width: 'calc(100% - 120px)',
      left: '61px !important',
    },

    [theme.breakpoints.down('md')]: {
      width: 'calc(100% - 70px)',
      left: '35px !important',
    },
  },
}));

const isCreatorWalletNft = (nft: CreatorWalletNft | undefined): nft is CreatorWalletNft => nft !== undefined;

const formSubscriptionTrue = {
  values: true,
};

export function TransferNftsPage(): ReactElement {
  const {t} = useLocalization();
  useAuthorized();
  const classes = useStyles();
  const {appConfig} = useContext(AppContext);
  const [openNftSelector, setOpenNftSelector] = useState(false);
  const [activeTab, setActiveTab] = useState<'nfts' | 'coins'>('nfts');
  const [isScLoading, setScLoading] = useState<boolean>(false);
  const {
    userData,
    nonCustodyWallets,
    selectedWallet: activeWallet,
    setSelectedWallet: setActiveWallet,
  } = useContext(UserContext);
  const {success, warning, info, error} = useNotifications();
  const {publicKey} = useSelectedWallet();
  const location = useLocation<{wallet: SupportedWallet}>();
  const walletFromLocation = useMemo((): string | undefined => {
    const walletType = location?.state?.wallet;
    if (isAppWallet(walletType)) {
      return userData.userPublicKey;
    }
    return nonCustodyWallets.find((wallet) => wallet.type === walletType)?.publicKey;
  }, [location?.state?.wallet, nonCustodyWallets, userData.userPublicKey]);
  const selectedWallet = useRef<string | undefined>(walletFromLocation || publicKey || userData.userPublicKey);
  const prevActiveWallet = useRef<SupportedWallet>(activeWallet);
  const getFreeport = useGetFreeport();
  const getCollection = useGetCollection();

  const {nfts, refetch, isLoading} = useLoadWalletNfts(selectedWallet.current ?? '');
  const [checkedNftIds, setCheckedNftIds] = useState<string[]>([]);
  const nftsMap = useMemo(() => new Map<string, CreatorWalletNft>(nfts.map((nft) => [nft.nft_id.nft_id, nft])), [nfts]);

  const {nfts: nfts2} = useLoadNftsByIds(nfts.map((nft) => nft.nft_id.cmsNft.id.toString()));

  const nftsWithFullDataMap = useMemo(
    () => new Map<string, NftCardInterface>(nfts2?.map((nft) => [nft.id, nft])),
    [nfts2],
  );

  console.log('nfts', nfts);
  console.log('nfts2', nfts2);
  console.log('nftsMap', nftsMap);
  console.log('nftWithFullData', nftsWithFullDataMap);

  const [nftHash, setHash] = useState(() => {
    const ids = nfts.map((nft) => nft.nft_id.nft_id);
    ids.sort();
    return ids.join('|');
  });

  const checkedNfts = useMemo(() => {
    return nfts.filter((nft) => checkedNftIds.includes(nft.nft_id.nft_id) || true);
  }, [checkedNftIds, nfts]);

  const inputLabelStyle = {
    root: classes.label,
  };

  const submitBlockTotalStyle = {root: classes.submitBlockTotal};

  const submitBlockButtonStyle = {contained: classes.submitBlockButton};

  const formChangeListener = useCallback(
    ({values}) => {
      selectedWallet.current = values.sender;
      void refetch({wallet: values.sender});
    },
    [refetch],
  );

  const availableWallets = useMemo((): {value?: string; label: SupportedWallet}[] => {
    return [...nonCustodyWallets.map(({type, publicKey}) => ({value: publicKey, label: type}))];
  }, [nonCustodyWallets]);

  const checkNftsBalance = useCallback(
    async (hash: string) => {
      const selectedWalletType = availableWallets.find(({value}) => value === selectedWallet.current)?.label;
      const nftIds = hash.split('|').filter(Boolean);
      if (selectedWalletType && nftIds.length > 0) {
        setScLoading(true);

        const selectedNfts = await Promise.all(
          nftIds.map(async (nftId): Promise<string> => {
            const nftData = await getCreatorNft(nftId);
            const contract = nftData.collectionAddress
              ? await getCollection(selectedWalletType, nftData.collectionAddress!)
              : await getFreeport(selectedWalletType);

            const balance = await contract
              .balanceOf(selectedWallet.current!, BigNumber.from(nftId))
              .catch(() => BigNumber.from(0))
              .finally(() => setScLoading(false));
            return balance.toNumber() > 0 ? nftId : '';
          }),
        );
        setCheckedNftIds(selectedNfts.filter(Boolean));
      }
    },
    [availableWallets, getCollection, getFreeport],
  );

  // This hook should be invoked only once after component's first render
  useEffect(() => {
    const newActiveWallet = nonCustodyWallets.find((wallet) => wallet.publicKey === selectedWallet.current);
    if (newActiveWallet) {
      setActiveWallet(newActiveWallet.type);
    }

    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      setActiveWallet(prevActiveWallet.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    void checkNftsBalance(nftHash);
  }, [checkNftsBalance, nftHash]);

  useEffect(() => {
    setHash(() => {
      const ids = nfts.map((nft) => nft.nft_id.nft_id);
      ids.sort();
      return ids.join('|');
    });
  }, [nfts]);

  const submitStep: SubmitStepFn = useCallback(
    async ({sender, address, amount, nft}) => {
      info(t('The transfer has started. Please, wait'));
      const selectedWalletType = availableWallets.find(({value}) => value === sender)?.label;
      if (!selectedWalletType) {
        error(t('An unexpected error occurred. Please, try again later.'));
        return;
      }
      try {
        const collectionAddress = nft?.nft_id?.cmsNft?.creatorNft?.collection_address;
        const contract = collectionAddress
          ? await getCollection(selectedWalletType, collectionAddress!)
          : await getFreeport(selectedWalletType);
        await safeTransferFrom(contract, {
          from: sender,
          toAccount: address,
          tokenId: nft.nft_id.nft_id,
          amount,
        });
        success(t('NFT {{nftName}} has been transferred', {nftName: nft.nft_id.cmsNft.title}));
      } catch (e) {
        console.error(e);

        warning(
          t(`NFT transfer for {{nftName}} has been failed. {{errorDetails}}`, {
            nftName: nft.nft_id.cmsNft.title,
            errorDetails: humanReadableError(e),
          }),
        );
      }
    },
    [error, getFreeport, getCollection, info, availableWallets, success, warning, t],
  );
  const {step, open: confirm, submit: submitWithConfirm} = useConfirmProcess(submitStep);

  const openSelector = useCallback(() => {
    setOpenNftSelector(true);
  }, []);

  const closeSelector = useCallback(() => {
    setOpenNftSelector(false);
  }, []);

  const submit = useCallback(
    async (values: FormValues, form: FormApi<FormValues, FormValues>) => {
      const {selectedNfts, ...restValues} = values;
      const nfts: CreatorWalletNft[] = Array.from(selectedNfts.values())
        .map((nftId) => nftsMap.get(nftId))
        .filter(isCreatorWalletNft);
      await submitWithConfirm({nfts, ...restValues});
      form.restart();
      form.change('sender', selectedWallet.current);
      setCheckedNftIds([]);
      await refetch({wallet: selectedWallet.current ?? ''});
      setHash((hash) => `${hash}|`);
    },
    [nftsMap, submitWithConfirm, refetch],
  );

  const initialValues: FormValues = useMemo(
    () => ({
      sender: selectedWallet.current ?? '',
      network: 'Polygon',
      amount: '1',
      address: '',
      selectedNfts: new Set<string>(),
    }),
    [],
  );

  const isMobile = useMediaQuery<Theme>((theme) => `${theme.breakpoints.down('sm')}`);
  const isDesktop = useMediaQuery<Theme>((theme) => `${theme.breakpoints.up('lg')}`);
  const nftRoyalty = useNftRoyalty(nfts[0]?.id);

  interface removeSelectedNFTProps {
    selectedNftId?: string | undefined;
    value?: Set<string>;
    onChangeRemove?: (selection: Set<string>) => unknown;
  }

  const removeSelectedNFT = useCallback(({selectedNftId, value, onChangeRemove}: removeSelectedNFTProps) => {
    const newSelection = new Set(value);
    newSelection.delete(selectedNftId ?? '');
    if (onChangeRemove) {
      onChangeRemove(newSelection);
    }
  }, []);

  const removeSelectedNFTHandler = ({selectedNftId, value, onChangeRemove}: removeSelectedNFTProps) => {
    removeSelectedNFT({selectedNftId, value, onChangeRemove});
  };

  const validateAddress = useCallback((address) => (isAddress(address) ? null : t('Empty or invalid address')), [t]);

  const validateSelectionSize = useCallback(
    (selection: Set<string>) => (selection.size > 0 ? null : t('Please select NFT to transfer')),
    [t],
  );

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const infoPopupHandler = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const popoverClasses = usePopoverStyles();

  return (
    <PageLayout hideFooter={activeTab === 'coins'}>
      <Box className={classes.page}>
        <div className={classes.tokensBlock}>
          <div>
            <Typography className={classes.header} variant="h1">
              {t('Transfer')}
            </Typography>
            <Typography variant="body1">
              {t('Transfer NFTs or coins to a connected wallet address or Polygon network compatible address.')}
            </Typography>
            <div className={classes.tabs}>
              <div onClick={() => setActiveTab('nfts')} className={clsx(activeTab === 'nfts' && classes.activeTab)}>
                {t('NFTs')}
              </div>
              <div onClick={() => setActiveTab('coins')} className={clsx(activeTab === 'coins' && classes.activeTab)}>
                {t('COINS')}
              </div>
            </div>
          </div>
        </div>
        {activeTab === 'nfts' ? (
          <Form onSubmit={submit} initialValues={initialValues}>
            {({handleSubmit, values, pristine, hasValidationErrors, submitting}) => (
              <form onSubmit={handleSubmit}>
                <FormSpy subscription={formSubscriptionTrue} onChange={formChangeListener} />
                <div className={classes.tokensBlock}>
                  <div>
                    <div className={classes.sedingAndReceivingBlock}>
                      <div className={classes.formLine}>
                        <InputLabel classes={inputLabelStyle} id="select-wallet">
                          {t('Sending address')}
                        </InputLabel>
                        <Field name="sender">
                          {({input}) => (
                            <Select
                              fullWidth
                              margin="dense"
                              variant="outlined"
                              onChange={input.onChange}
                              labelId="select-wallet"
                              value={input.value}
                              MenuProps={{
                                disableScrollLock: true,
                              }}
                            >
                              {availableWallets.map(({label, value}) => (
                                <MenuItem value={value}>
                                  {t('My {{name}} wallet', {
                                    name:
                                      label === AppWallet.DAVINCI ? appConfig.appTitle : NonCustodyWalletTitles[label],
                                  })}
                                </MenuItem>
                              ))}
                            </Select>
                          )}
                        </Field>
                      </div>
                      <div className={classes.formLine}>
                        <InputLabel classes={inputLabelStyle} htmlFor="receiving-address">
                          {t('Receiving address')}
                        </InputLabel>
                        <Field name="address" validate={validateAddress}>
                          {({input: {value, onChange, onFocus, onBlur}, meta}) => (
                            <FormControl fullWidth>
                              <OutlinedInput
                                value={value}
                                onChange={onChange}
                                onFocus={onFocus}
                                onBlur={onBlur}
                                placeholder="Enter or select address"
                                fullWidth
                                id="receiving-address"
                                margin="dense"
                              />
                              {meta.touched && meta.error && <FormHelperText error>{meta.error}</FormHelperText>}
                            </FormControl>
                          )}
                        </Field>
                      </div>
                    </div>
                    <div className={classes.formLine}>
                      <InputLabel classes={inputLabelStyle}>{t('Network')}</InputLabel>
                      <Field name="network">
                        {({input}) => (
                          <Button
                            classes={{root: classes.networkSelectRoot, outlined: classes.networkSelectOutline}}
                            variant="outlined"
                          >
                            {input.value}
                          </Button>
                        )}
                      </Field>
                    </div>
                    <div className={classes.formLine}>
                      <InputLabel classes={inputLabelStyle}>{t('Transfer NFTs')}</InputLabel>
                      <Field validate={validateSelectionSize} name="selectedNfts">
                        {({input: {value, onChange}}) => {
                          return (
                            <>
                              <NftSelector
                                nfts={checkedNfts}
                                onClose={closeSelector}
                                open={openNftSelector}
                                value={value}
                                onChange={onChange}
                              />
                              <div className={classes.selectedNfts}>
                                {Array.from(value.values()).map((nftId) => {
                                  const nft = nftsMap.get(nftId as string);
                                  return (
                                    <div className={classes.selectedNftBlock}>
                                      <img alt={nft?.nft_id.cmsNft.title} src={getNFTCardImage(nft?.nft_id?.cmsNft)} />
                                      <Typography variant="h4">{nft?.nft_id.cmsNft.title}</Typography>
                                      <IconButton
                                        onClick={removeSelectedNFTHandler.bind(null, {
                                          selectedNftId: nft?.nft_id.nft_id,
                                          value: value,
                                          onChangeRemove: onChange,
                                        })}
                                        className="remove"
                                      >
                                        <Cancel />
                                      </IconButton>
                                    </div>
                                  );
                                })}
                              </div>
                            </>
                          );
                        }}
                      </Field>
                      <button
                        disabled={checkedNfts.length === 0}
                        onClick={openSelector}
                        type="button"
                        className={classes.nftSelector}
                      >
                        <span>{isLoading || isScLoading ? <BootstrapLoader color="text-light" /> : <AddCircle />}</span>
                        {checkedNfts.length === 0 ? t('No available NFTs') : t('Select NFTs')}
                      </button>
                    </div>
                  </div>
                  <div
                    className={clsx(
                      classes.submitBlock,
                      (pristine || hasValidationErrors || submitting) && classes.dissableButton,
                    )}
                  >
                    <div className={classes.submitBlockContent}>
                      <Typography variant="h3">{t('Total')}</Typography>
                      <Typography classes={submitBlockTotalStyle} variant="body1">
                        {t('Number of NFTs for transfer')}
                        <span className="total">{values.selectedNfts.size}</span>
                      </Typography>
                      <Typography classes={submitBlockTotalStyle} variant="body1">
                        {t('Royalties {{royaltiesPercent}}%', {royaltiesPercent: nftRoyalty || 1})}
                        <InfoIcon className={classes.infoIcon} onClick={infoPopupHandler} />
                        <span className="total">
                          {transformTokensToPrice(
                            Array.from(values.selectedNfts)
                              .map((nftId) => {
                                const nft = nftsMap.get(nftId as string);
                                return nft?.nft_id.cmsNft.creatorNft?.creator_make_offer[0].price || 0;
                              })
                              .reduce((partialPrice, nft) => partialPrice + nft, 0),
                          ) * (nftRoyalty || 0.01)}{' '}
                          {values.selectedNfts.size > 0 && t('USDC')}
                        </span>
                      </Typography>

                      <Divider className={classes.divider} />

                      <Button
                        disableElevation
                        classes={submitBlockButtonStyle}
                        type="submit"
                        disabled={pristine || hasValidationErrors || submitting}
                        variant="contained"
                      >
                        {submitting ? t('Transferring...') : t('Transfer')}
                      </Button>

                      <Popover
                        id={Boolean(anchorEl) ? 'simple-popover' : undefined}
                        open={Boolean(anchorEl)}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: isMobile ? -107 : isDesktop ? -80 : -72,
                          horizontal: isDesktop ? -100 : 0,
                        }}
                        disableScrollLock={true}
                        classes={popoverClasses}
                      >
                        <span className={classes.popoverText}>
                          {t(
                            'Secondary royalties must be paid for any transaction with NFTs. Royalties are split between the creator, Davinci and Cere DDC. ',
                          )}
                        </span>
                        <a href="https://ondavinci.com/faq#q14" className={clsx(classes.link, classes.popoverText)}>
                          {t('Learn more here')}
                        </a>
                      </Popover>
                    </div>
                  </div>
                </div>
                {step && (
                  <ConfirmTransfer
                    nft={step.values.nft}
                    address={step.values.address}
                    network={step.values.network}
                    open={confirm}
                    onCancel={step.cancel}
                    onConfirm={step.confirm}
                  />
                )}
              </form>
            )}
          </Form>
        ) : (
          <Box className={classes.noAvailablecoinTransferBlock}>
            <NoAvailableCoinTransferIcon />
            <Typography
              variant="body1"
              className={isMobile ? classes.noAvailableTransferTextMobile : classes.noAvailableTransferTextDesktop}
            >
              {t('Coin transfer is not yet available')}
            </Typography>
          </Box>
        )}
      </Box>
    </PageLayout>
  );
}
