import {useState} from 'react';
import useSWR from 'swr';
import {creatorApi} from '../index';
import {useParams} from 'react-router-dom';
import {FullCreatorInterface} from '@cere/services-types';
import {useLocalization} from '../../shared/hooks/use-locale.hook';

interface ArtistPageRouteParams {
  artistId: string;
}

const INTERVAL_TIME_MS = 5000;

export const useCreatorPageCreator = (): {
  loading: boolean;
  creator: FullCreatorInterface | undefined;
} => {
  const {artistId} = useParams<ArtistPageRouteParams>();
  const {locale} = useLocalization();

  const [loading, setLoading] = useState<boolean>(true);
  const {data: creator} = useSWR(
    ['creator-page-creator'],
    async () => {
      const creator = await creatorApi.getCreatorById(artistId, locale);
      setLoading(false);
      return creator;
    },
    {
      errorRetryInterval: INTERVAL_TIME_MS,
    },
  );

  return {
    loading,
    creator,
  };
};
