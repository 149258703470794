import {useCallback, useContext, useEffect, useState} from 'react';
import {useLoadNftByAddress} from '../../../api/hooks/use-load-nft-by-address';
import {getEmailByStripeSessionId} from '../../services/payment.service';
import {Verify} from './Verify';
import {useAuth} from '../../hooks/auth.hook';
import {IKeys} from '../../services/auth.service';
import {unwrap} from '../../lib/unwrap';
import {useNotifications} from '../../hooks/use-notifications';
import {UserContext} from '../../../context/user-context/user-context';

export const VerifyEmailGuard = () => {
  const [nftAddress, setNftAddress] = useState<string | null>(null);
  const [email, setEmail] = useState<string | null>(null);
  const {error: showError} = useNotifications();
  const [isModalOpened, setModalOpened] = useState<boolean>(false);
  const {setAuthData} = useAuth();
  const {userData} = useContext(UserContext);
  const nft = useLoadNftByAddress(nftAddress);

  const getUrlSearchParams = useCallback(
    () => Object.fromEntries(new URLSearchParams(window.location.search).entries()),
    [],
  );

  const getUserEmailBySessionId = useCallback((sessionId: string) => getEmailByStripeSessionId(sessionId), []);

  const closeModal = useCallback(() => setModalOpened(false), [setModalOpened]);

  const dropUrlQuery = useCallback(() => {
    const url = new URL(window.location.href);
    const previewKey = url.searchParams.get('preview_key');
    url.search = '';
    if (previewKey) {
      url.searchParams.append('preview_key', previewKey);
    }
    window.history.replaceState({}, '', url.href);
  }, []);

  const onVerificationDone = useCallback(
    (keys: IKeys) => {
      setAuthData(unwrap(email), keys.publicKey, keys.token);
      closeModal();
      setNftAddress(null);
      setEmail(null);
      dropUrlQuery();
    },
    [email, setAuthData, closeModal, setNftAddress, dropUrlQuery],
  );

  const loadDataAndShowModal = useCallback(
    async (sessionId: string, nftId: string) => {
      setNftAddress(nftId);
      let userEmail: string = '';
      try {
        userEmail = await getUserEmailBySessionId(sessionId);
        setAuthData(userEmail, '', '');
        setEmail(userEmail);
      } catch (e) {
        showError(e.message);
      }
      setModalOpened(true);
    },
    [getUserEmailBySessionId, showError, setEmail, setModalOpened, setAuthData],
  );

  useEffect(() => {
    if (userData.userPublicKey) {
      return;
    }
    const {session_id, nftId: nft_id} = getUrlSearchParams();
    if (!session_id || !nft_id) {
      return;
    }
    void loadDataAndShowModal(session_id, nft_id);
  }, [userData.userPublicKey, getUrlSearchParams, loadDataAndShowModal]);

  return (
    <Verify
      open={isModalOpened && Boolean(nft)}
      onClose={closeModal}
      onVerify={onVerificationDone}
      title={nft?.title || ''}
      imgSrc={nft?.image || ''}
    />
  );
};
