import {useCallback, useContext, useEffect, useMemo, useState, useRef} from 'react';
import {useHistory} from 'react-router-dom';
import {Box, Divider, Grid, Typography} from '@material-ui/core';
import {FullCreatorInterface} from '@cere/services-types';

import {UserContext} from 'context/user-context/user-context';
import {UserPaymentHistoryContext} from 'context/payments-history/user-payment-history.context';
import {PAYMENT_SUCCESS_VALIDITY_INTERVAL} from 'context/payments-history/constants';
import {ExhibitContext} from 'context/exhibition/exhibit-context';

import {getShareUrl} from 'shared/components/Share/utils/get-share-url';
import {PageLayout} from 'shared/components/PageLayout';
import {PageContainer} from 'shared/components/PageContainer';
import {Title} from 'shared/components/Title';
import {Condition, ConditionsList} from 'shared/components/Conditions';
import {AboutUpcomingAuctionInfo} from 'shared/components/auction/AboutAuctionTickets';
import {AppMetaTags} from 'shared/components/SeoHeaders/seo-headers.component';
import {WithPurchaseNftResult} from 'shared/components/WithPurchaseNft/v2';
import Share from 'shared/components/Share';
import {
  ExhibitBannerSkeleton,
  ExhibitAboutInfo,
  ExhibitionTicketSkeleton,
  ExhibitsRowSkeleton,
} from 'shared/components/Skeletons';
import {getBrightcoveJwtToken} from 'shared/services/video.service';
import {getFirstInAssetsImage} from 'shared/services/asset.service';
import analyticService, {AnalyticEventsEnum} from 'shared/services/analytic.service';
import {isAccessNft} from 'shared/helpers/auction';
import {getIsPaymentPending, getIsPaymentPrePending} from 'shared/helpers/paymentStatus';
import {useLocalization} from 'shared/hooks/use-locale.hook';
import {useLocaleUpdated} from 'shared/hooks/use-locale-updated.hook';
import {useSingletonPollNftStatus} from 'shared/hooks/nft-status-poll.hook';
import {useScrollToElement} from 'shared/hooks/scroll-to-element.hook';
import {getMediaUrl, parseMediaAlternativeText} from 'shared/lib/media';
import {arrayToHash, hashToArray} from 'shared/lib/hash-utils';
import {CmsExhibitionNft, isCmsExhibitionNftReady} from 'shared/types/graphql';
import {EventTimelineStatus} from 'shared/types/event';
import {Numberish} from 'shared/types/numberish';

import {useUserHasEventAccess} from '../../use-user-has-event-access';
import {useExhibitAutoPlay} from '../../use-exhibit-auto-play';
import {useIsLiveThemeApplied} from './use-is-live-theme-applied';
import {ExhibitInfo} from './ExhibitInfo';
import {ExhibitPageTopCards} from './ExhibitPageTopCards';
import {ExhibitionStayUpdated} from './ExhibitionTeaser/exhibition-stay-updated';
import {ExhibitionPreviewContainer} from './Exhibition-preview-container/exhibition-preview-container';
import {ExhibitionInfoAndAccess} from './Live-theme-components/exhibition-info-and-access';
import {ExhibitionTicket} from './Live-theme-components/exhibition-ticket';
import {useStyles} from './mui-styles';
import {ExhibitionsList} from 'pages/ExhibitsPage/exhibitions-list';
import {useRandomExhibits} from 'api/hooks/use-random-exhibit';

const DELAY_BETWEEN_REQUESTS = 5 * 1000;
const MAX_JWT_REQUESTS = 30;

interface InterstitialHomePageProps {
  onPlayVideoClick: () => void;
}

let jwtRequestCounter: number = 0;
let jwtIntervalId: number;

export const WebInterstitialHomePage = ({
  onPlayVideoClick,
  setEvent,
  setVerifyCallback,
  setShowTokenAllocation,
}: InterstitialHomePageProps & WithPurchaseNftResult) => {
  const {locale, t} = useLocalization();
  const {
    event,
    loading: isLoading,
    refetch: refetchEventWithNfts,
    eventTimelineStatus: timelineStatus,
  } = useContext(ExhibitContext);
  const history = useHistory();

  const eventNfts = useMemo(() => (event?.nfts ?? []).filter(isCmsExhibitionNftReady), [event?.nfts]);
  const styles = useStyles();
  const accessNftsHash = useMemo(() => {
    return arrayToHash(eventNfts.filter((nft) => isAccessNft(nft)).map((nft) => nft.cmsNft?.creatorNft?.nft_id));
  }, [eventNfts]);

  const userHasEventAccess = useUserHasEventAccess();
  const {getPaymentStatuses} = useContext(UserPaymentHistoryContext);

  const [shareNftId, setShareNftId] = useState<Numberish>();
  const toggleShareNft = (nft?: CmsExhibitionNft) => {
    setShowShareNftModal((val) => !val);
    setShareNftId(nft?.cmsNft?.id);
  };

  const [userHasJwtAccess, setUserHasJwtAccess] = useState<boolean>(false);
  const [accessPending, setAccessPending] = useState<boolean>(false);

  const [showShareExhibitModal, setShowShareExhibitModal] = useState(false);
  const [showShareNftModal, setShowShareNftModal] = useState(false);

  const [localIsLoading, setLocalIsLoading] = useState<boolean>(false);
  const [mounted, setMounted] = useState(false);

  const isLiveThemeApplied = useIsLiveThemeApplied();

  const {userData} = useContext(UserContext);

  const {setPollingEnabled, setNftIds, newTokenPurchased, setNewTokenPurchased} = useSingletonPollNftStatus();

  const handleTokenAllocation = useCallback(() => {
    setShowTokenAllocation(true);
  }, [setShowTokenAllocation]);

  const loadBrightcoveJwtToken = useCallback(async (): Promise<string | undefined> => {
    if (!userData.userPublicKey) {
      setLocalIsLoading(false);
      return;
    }

    jwtRequestCounter = jwtRequestCounter + 1;
    const {brightcoveId: brightcoveVideoId} = parseMediaAlternativeText(
      (event?.assets?.length && event.assets[0]?.content?.alternativeText) || '',
    );
    let jwt;

    const {userPublicKey} = userData;

    if (brightcoveVideoId) {
      try {
        jwt = await getBrightcoveJwtToken(
          brightcoveVideoId,
          {
            userPublicKey,
          },
          userData.token,
        );
        setUserHasJwtAccess(true);
      } catch (err) {
        console.error(err);
      }
    }

    return jwt;
  }, [event?.assets, userData]);

  const getStatuses = useCallback(async () => {
    setLocalIsLoading(!mounted);

    await loadBrightcoveJwtToken();
    setMounted(true);
  }, [loadBrightcoveJwtToken, mounted]);

  const registerLandedEvent = useCallback(() => {
    const currentUrl = new URLSearchParams(window.location.search);
    const sessionId = currentUrl.get('session_id') as string;

    if (!sessionId) {
      return;
    }

    analyticService.track(AnalyticEventsEnum.EXHIBIT_LANDED, {
      eventId: event?.id ?? '',
      sessionId,
    });
  }, [event?.id]);

  const updateEventAccess = useCallback(() => {
    const accessNftIds = hashToArray(accessNftsHash);
    const hasSomePendingAccessNft = accessNftIds.some((nftId) => getIsPaymentPending(...getPaymentStatuses(nftId)));

    setAccessPending(hasSomePendingAccessNft);
  }, [accessNftsHash, getPaymentStatuses]);

  useEffect(() => {
    updateEventAccess();
    const timeout = setTimeout(updateEventAccess, PAYMENT_SUCCESS_VALIDITY_INTERVAL + 100);
    return () => clearTimeout(timeout);
  }, [updateEventAccess]);

  useEffect(() => {
    if (!userData || !userData.userPublicKey || !userData.token) {
      setUserHasJwtAccess(false);
    }
  }, [userData]);

  useEffect(() => {
    if (event?.id) {
      registerLandedEvent();
    }
    // FIXME needs to reduce dependencies and flow
  }, [event?.id, getStatuses, registerLandedEvent]);

  useEffect(() => {
    getStatuses();
  }, [getStatuses]);

  useEffect(() => {
    clearInterval(jwtIntervalId);

    const isPrePending = event?.nfts.some((nft: CmsExhibitionNft) =>
      getIsPaymentPrePending(...getPaymentStatuses(nft.cmsNft?.creatorNft?.nft_id)),
    );

    if (!isPrePending) {
      setPollingEnabled(false);
    }

    const isPending = event?.nfts.some((nft: CmsExhibitionNft) =>
      getIsPaymentPending(...getPaymentStatuses(nft.cmsNft?.creatorNft?.nft_id)),
    );

    if (userData.userEmail && isPending) {
      setPollingEnabled(true);
      handleTokenAllocation();
    }

    const brightcoveVideoId =
      event?.assets?.length && event.assets.find((asset) => asset?.content?.alternativeText)?.content?.alternativeText;

    if (!brightcoveVideoId) {
      // TODO what to show in this case on the screen?
      console.error("The first asset in event doesn't have brightcoveVideoId");
      setUserHasJwtAccess(true); // we don't have to check JWT in this case, because we don't have any video in assets
      return;
    }

    if (userHasEventAccess && brightcoveVideoId && !userHasJwtAccess && jwtRequestCounter < MAX_JWT_REQUESTS) {
      jwtIntervalId = window.setInterval(loadBrightcoveJwtToken, DELAY_BETWEEN_REQUESTS);
    }
  }, [
    userHasEventAccess,
    userHasJwtAccess,
    userData.userEmail,
    event?.assets,
    loadBrightcoveJwtToken,
    handleTokenAllocation,
    setPollingEnabled,
    event?.nfts,
    getPaymentStatuses,
  ]);

  const onToggleShareModal = () => {
    analyticService.track(AnalyticEventsEnum.EXHIBIT_SHARE_CLICKED);

    setShowShareExhibitModal((val) => !val);
  };

  const {refElement, scrollToElement} = useScrollToElement();

  const verifyCallback = useCallback(
    () => () => {
      if (timelineStatus === EventTimelineStatus.NOT_STARTED) {
        return;
      }

      scrollToElement();
    },
    [timelineStatus, scrollToElement],
  );

  useEffect(() => {
    setVerifyCallback(verifyCallback);
    scrollToElement();
  }, [setVerifyCallback, verifyCallback, scrollToElement]);

  useEffect(() => {
    if (event) {
      setEvent(event);
    }
  }, [event, setEvent]);

  useEffect(() => {
    if (userData.userPublicKey && accessPending && !isLoading) {
      handleTokenAllocation();
    }
  }, [isLoading, accessPending, handleTokenAllocation, userData.userPublicKey]);

  useEffect(() => {
    setNftIds(eventNfts.map((nft) => nft.cmsNft?.creatorNft.nft_id));
  }, [eventNfts, setNftIds]);

  useEffect(() => {
    if (newTokenPurchased) {
      setNewTokenPurchased(false);
      void refetchEventWithNfts();
    }
  }, [newTokenPurchased, refetchEventWithNfts, setNewTokenPurchased]);

  useEffect(() => {
    return () => {
      setNftIds([]);
      setPollingEnabled(false);
    };
  }, [setNftIds, setPollingEnabled]);

  const shareNft = event?.nfts.find((nft) => nft.cmsNft.id === shareNftId);

  useLocaleUpdated(
    useCallback(
      (newLocale: string) => {
        const localization = event?.localizations?.find((localizedEvent) => localizedEvent.locale === newLocale);
        const location = localization
          ? `/${localization.locale}/home/exhibit/${localization.slug}`
          : `/${newLocale}/home`;

        history.push(location);
      },
      [history, event],
    ),
  );

  useExhibitAutoPlay({goToExhibitPage: onPlayVideoClick, userHasJwtAccess});
  const topCardsContainerRef = useRef<HTMLDivElement | null>(null);

  const localizations = !event
    ? []
    : [
        {
          locale: event.locale,
          link: `/${event.locale}/home/exhibit/${event.slug}`,
        },
        ...event.localizations?.map((localizedEvent) => ({
          locale: localizedEvent.locale,
          link: `/${localizedEvent.locale}/home/exhibit/${event.slug}`,
        })),
      ];

  const {exhibits, loading} = useRandomExhibits();

  return (
    <Box className={styles.mainBox} {...{ref: refElement}}>
      {event && (
        <AppMetaTags
          title={event?.seoTitle || event.title}
          description={event?.seoDescription || event?.description}
          canonical={event.seoCanonical}
          image={event?.seoImage?.url || event?.image?.url}
          localizations={localizations}
        />
      )}
      <PageLayout>
        {event && (
          <Share
            isOpen={showShareExhibitModal}
            onClose={onToggleShareModal}
            title={t('Share exhibit')}
            description={event.title ?? ''}
            imgSrc={getMediaUrl(event.image)}
            url={getShareUrl(
              `/${locale}/home/exhibit/${event.slug}`,
              event.title,
              event.description,
              getMediaUrl(event.image),
            )}
            entity="exhibit"
          />
        )}
        <Share
          isOpen={showShareNftModal}
          onClose={toggleShareNft}
          title={t('Share NFT')}
          description={shareNft?.cmsNft.title || ''}
          imgSrc={getFirstInAssetsImage(shareNft?.cmsNft.assets || [])}
          url={getShareUrl(
            `/${locale}/home/nft/${shareNftId}`,
            shareNft?.cmsNft.title || '',
            shareNft?.cmsNft.title || '',
            getFirstInAssetsImage(shareNft?.cmsNft.assets || []) || '',
          )}
        />

        <ConditionsList>
          <Condition condition={isLoading}>
            <ExhibitBannerSkeleton />
          </Condition>
          <Condition condition={Boolean(event)}>
            <ExhibitionPreviewContainer
              onToggleShareModal={onToggleShareModal}
              localIsLoading={localIsLoading}
              userHasEventAccess={userHasEventAccess || Boolean(event?.allowFreeAccess)}
              userHasJwtAccess={userHasJwtAccess}
              accessPending={accessPending}
              onPlayVideoClick={onPlayVideoClick}
              topCardsContainerRef={topCardsContainerRef.current}
            />
          </Condition>
        </ConditionsList>

        <PageContainer>
          <ConditionsList>
            <Condition condition={isLoading}>
              <Box pt={4} pb={2}>
                <Title>{t('About the drop')}</Title>
              </Box>
              <ExhibitAboutInfo />
            </Condition>
            <Condition condition={Boolean(event) && isLiveThemeApplied}>
              <Box>
                <ExhibitionInfoAndAccess />
                <Box my="2rem">
                  <Divider />
                </Box>
              </Box>
            </Condition>
            <Condition condition={Boolean(event)}>
              <ExhibitionStayUpdated />
              <ExhibitInfo artist={event?.cmsCreator as FullCreatorInterface} event={event!} />
              <Box my="2rem">
                <Divider />
              </Box>
            </Condition>
          </ConditionsList>

          <Box className={styles.topCardsContainer}>
            <div ref={topCardsContainerRef}>
              <ExhibitPageTopCards isLoading={isLoading || localIsLoading} />
            </div>
          </Box>

          <ConditionsList>
            <Condition condition={isLoading}>
              <Title>{t('NFT tickets')}</Title>
              <Box pb={4} pt={2}>
                <ExhibitionTicketSkeleton />
              </Box>

              <Title>{t('NFT tickets from Frank Woth')}</Title>
              <Box pb={2}>
                <ExhibitsRowSkeleton />
              </Box>
            </Condition>
            <Condition condition={Boolean(event)}>
              <Box className={styles.exhibitPageBottomCards}>
                <ExhibitionTicket />
              </Box>
              {timelineStatus === EventTimelineStatus.NOT_STARTED && event?.nftVisualExhibit && (
                <AboutUpcomingAuctionInfo previewImageUrl={event.NFT_visual_exhibitupload?.url} />
              )}
            </Condition>
          </ConditionsList>

          <Typography className={styles.title}>{t('Discover more popular exhibits')}</Typography>
          <Grid container spacing={4}>
            <ExhibitionsList exhibitions={exhibits} loading={loading} />
          </Grid>
        </PageContainer>
      </PageLayout>
    </Box>
  );
};
