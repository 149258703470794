import {ReactElement} from 'react';
import {Card, CardActionArea, CardContent, CardMedia} from '@material-ui/core';
import {CheckCircle} from '@material-ui/icons';
import {useStyles} from './styles';
import clsx from 'clsx';
import {CreatorWalletNft} from '../../shared/types/graphql/creator-wallet-nfts';
import {NftInfoCollection} from 'pages/UserPages/components/nftInfoCollection';
import {getNFTCardImage} from 'shared/helpers/nfts';

type Props = {
  nft: CreatorWalletNft;
  selected?: boolean;
  onChange: (nftId: string) => unknown;
};

export function NftItem({nft, selected = false, onChange}: Props): ReactElement {
  const classes = useStyles();
  const cmsNft = nft.nft_id.cmsNft;

  return (
    <Card elevation={0} onClick={() => onChange(nft.nft_id.nft_id)} classes={{root: classes.selectorNftRoot}}>
      <div
        className={clsx(
          classes.selectorNftCheckbox,
          selected && classes.selectorNftCheckboxChecked,
          !selected && classes.selectorNftCheckboxUnChecked,
        )}
      >
        <CheckCircle />
      </div>
      <CardActionArea>
        <CardMedia classes={{root: classes.selectorNftMedia}} title={cmsNft.title} image={getNFTCardImage(cmsNft)} />
        <CardContent classes={{root: classes.selectorNftContent}}>
          <NftInfoCollection
            title={nft.nft_id.cmsNft.title}
            id={nft.nft_id.id}
            cmsNft={nft.nft_id.cmsNft}
            limit={nft.nft_id.cmsNft?.creatorNft?.supply ?? 0}
            qtyOwned={nft.quantity}
          />
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
