import {useHistory} from 'react-router-dom';
import {Button, Typography} from '@cere/rxb-template-ui-kit';
import {Box, makeStyles} from '@material-ui/core';

import {ReactComponent as LockIcon} from '../../../../../assets/lock.svg';
import colors from '../../../../../styles/colors';
import {useLocalization} from '../../../../../shared/hooks/use-locale.hook';
import {useContext} from 'react';
import {ExhibitContext} from 'context/exhibition/exhibit-context';

interface SoldOutProps {
  text?: string;
}

const useStyles = makeStyles((theme) => ({
  soldOut: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row',
    },
  },
  button: {
    margin: '0 auto 0',
    display: 'block',
    padding: '13px 19px',
    zIndex: 999,
    [theme.breakpoints.up('md')]: {
      padding: '13px 37px',
    },
    [theme.breakpoints.up('lg')]: {
      margin: 'unset',
    },
  },
  buttonText: {
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '22px',
  },
  text: {
    color: colors.lighter,
    margin: '16px 0 24px 0',
    [theme.breakpoints.up('md')]: {
      margin: '4px 0 32px 0',
    },
    [theme.breakpoints.up('lg')]: {
      margin: 'auto 16px',
    },
  },
  eventSubtitle: {
    color: colors.light,
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',
    marginBottom: '4px',
    [theme.breakpoints.up('md')]: {
      fontSize: '16px',
      lineHeight: '22px',
    },
    [theme.breakpoints.up('lg')]: {
      lineHeight: '26px',
    },
  },
  exhibitionTitles: {
    textAlign: 'center',
    order: 1,
    marginBottom: '24px',
    marginTop: '53px',
    color: colors.light,

    [theme.breakpoints.up('md')]: {
      marginBottom: '32px',
      marginTop: 'unset',
    },

    [theme.breakpoints.up('lg')]: {
      marginBottom: '20px',
      order: 'unset',
      textAlign: 'unset',
    },
  },
  lockWrapper: {
    width: '40px',
    height: '40px',
    background: 'rgba(140, 140, 148, 0.5)',
    backdropFilter: 'blur(8px)',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    [theme.breakpoints.down('lg')]: {
      margin: '0 auto',
    },

    '& svg': {
      width: '11px',
      height: '13px',

      '& path': {
        fill: colors.light,
      },
    },
    [theme.breakpoints.up('sm')]: {
      width: '48px',
      height: '48px',
      '& svg': {
        width: '13px',
        height: '16px',

        '& path': {
          fill: colors.light,
        },
      },
    },
  },
}));

export const SoldOut = ({text}: SoldOutProps) => {
  const {locale, t} = useLocalization();
  const history = useHistory();
  const classes = useStyles();
  const {event} = useContext(ExhibitContext);

  return (
    <Box order="3">
      <Box className={classes.soldOut}>
        <Box className={classes.lockWrapper}>
          <LockIcon />
        </Box>
        <Typography variant="body2" className={classes.text}>
          {text || t('All NFTs are sold out')}
        </Typography>
        <Button
          variant="contained"
          className={classes.button}
          color="primary"
          onClick={() => history.push(`/${locale}/marketplace?creatorId=${event?.cmsCreator.id}`)}
        >
          <Typography className={classes.buttonText}>{t('Buy on marketplace')}</Typography>
        </Button>
      </Box>
    </Box>
  );
};
