import {arrayToHash} from '../helpers/array-to-hash';
import {PaymentHistoryItem} from '../types/payment-history-item';
import {getPaymentHistoryByPublicKey} from './payment.service';
import {
  CmsExhibitionNft,
  CmsExhibitionNftRelType,
  CmsExhibitionNftWithUserPaymentInfo,
  CreatorAuction,
  CreatorNftWithBalance,
  isCreatorNftWithBalance,
} from '../types/graphql';
import {get, post} from '../lib/request';
import {PaymentStatusEnum} from '@cere/services-types';
import {getIsPaymentSuccess} from '../helpers/paymentStatus';
import {isRecord} from '../types/is-record';
import {GET_WALLETS_NFTS} from '../queries/wallet';
import {apolloClient} from '../../apollo-client';
import {CreatorWalletNft, CreatorWalletNftQueryResult} from '../types/graphql/creator-wallet-nfts';
import {hashFromObjectHelper} from '../helpers/hash-from-object.helper';
import {getNFTTotalOfferedQty} from '../helpers/nfts';
import {NftCardInterface, AvailabilityStatus, NftType} from '@cere/services-types';
import {FREEPORT_API_URL} from '../../config/common';

export const getPurchasedNftsByWallet = async (
  wallets: string[],
  locale: string,
): Promise<{
  creatorWalletNfts: CreatorWalletNft[];
}> => {
  const response = await apolloClient.query<{creatorWalletNfts: CreatorWalletNftQueryResult[]}>({
    query: GET_WALLETS_NFTS,
    variables: {
      wallet: wallets,
      locale,
    },
  });

  return (
    response.data?.creatorWalletNfts && {
      creatorWalletNfts: response.data?.creatorWalletNfts
        .filter((creatorWalletNft) => creatorWalletNft.nft_id?.cmsNfts?.length > 0)
        .map((creatorWalletNft) => ({
          id: creatorWalletNft.id,
          wallet: creatorWalletNft.wallet,
          quantity: creatorWalletNft.quantity,
          nft_id: {
            id: creatorWalletNft.nft_id?.id,
            nft_id: creatorWalletNft.nft_id?.nft_id,
            cmsNft: creatorWalletNft.nft_id?.cmsNfts?.[0],
          },
        })),
    }
  );
};

export const getPaymentStatusesByNftIds = async (
  nftIds: string[],
  userPublicKey: string,
  nonCustodyWallets: string[] = [],
  userPayments: PaymentHistoryItem[] = [],
  locale: string,
): Promise<{[key: string]: PaymentStatusEnum}> => {
  const purchasedNfts = await getPurchasedNftsByWallet([userPublicKey, ...nonCustodyWallets], locale);

  const transferredNftIds = purchasedNfts.creatorWalletNfts.map((nft) => nft.nft_id?.nft_id);

  const userPaymentsHash = arrayToHash(userPayments, 'nftId');

  return nftIds.reduce(
    (acc, nftId) => ({
      ...acc,
      [nftId]: transferredNftIds.includes(nftId)
        ? PaymentStatusEnum.TOKEN_TRANSFER_SUCCESS
        : userPaymentsHash[nftId]?.status,
    }),
    {},
  );
};

export const enrichNftByPaymentStatuses = async (
  nfts: CmsExhibitionNft[],
  userPublicKey: string,
  nonCustodyWallets: string[] = [],
  locale: string,
): Promise<CmsExhibitionNftWithUserPaymentInfo[]> => {
  const userPayments = await getPaymentHistoryByPublicKey({ethAddress: userPublicKey});
  const nftPaymentStatuses = await getPaymentStatusesByNftIds(
    nfts.map((nft) => nft.cmsNft?.creatorNft?.nft_id ?? ''),
    userPublicKey,
    nonCustodyWallets,
    userPayments,
    locale,
  );
  return nfts.map((nft) => ({
    ...nft,
    paymentStatus: nftPaymentStatuses[nft.cmsNft?.creatorNft?.nft_id ?? ''] ?? PaymentStatusEnum.INITIAL,
  }));
};

export const filterAuctionedNfts = <T extends CmsExhibitionNft>(nfts: T[]): T[] =>
  nfts.filter((nftItem) => nftItem.relType === CmsExhibitionNftRelType.AUCTIONED);

export const filterAccessNfts = <T extends CmsExhibitionNft>(nfts: T[]): T[] =>
  nfts.filter((nftItem) => nftItem.relType === CmsExhibitionNftRelType.ACCESS);

export const filterLimitedNfts = <T extends CmsExhibitionNft>(nfts: T[]): T[] =>
  nfts.filter((nftItem) => nftItem.relType === CmsExhibitionNftRelType.LIMITED);

export enum NftAvailability {
  ONGOING = 'ONGOING',
  COMING_SOON = 'COMING_SOON',
  PASSED = 'PASSED',
  SOLDOUT = 'SOLDOUT',
}

export enum NftSectionType {
  ONGOING = 'ONGOING',
  COMING_SOON = 'COMING_SOON',
  PASSED = 'PASSED',
  SOLDOUT = 'SOLDOUT',
}

export const getNftAvailabilityByCmsNft = (
  eventNft: Pick<CmsExhibitionNft, 'availableFrom' | 'availableTo'> | undefined,
): {availability: NftAvailability; from: Date | null; to: Date | null} => {
  const now = new Date();

  if (!eventNft) {
    return {availability: NftAvailability.ONGOING, from: null, to: null};
  }

  if (!eventNft.availableFrom || !eventNft.availableTo) {
    return {availability: NftAvailability.ONGOING, from: null, to: null};
  }

  const availableFrom = new Date(eventNft.availableFrom);
  const availableTo = new Date(eventNft.availableTo);

  if (now < availableFrom) {
    return {availability: NftAvailability.COMING_SOON, from: availableFrom, to: availableTo};
  }

  if (now > availableTo) {
    return {availability: NftAvailability.PASSED, from: availableFrom, to: availableTo};
  }

  return {availability: NftAvailability.ONGOING, from: availableFrom, to: availableTo};
};

export const getNftAvailabilityWithDates = (
  nftId: string,
  eventNfts: Pick<CmsExhibitionNft, 'availableFrom' | 'availableTo' | 'cmsNft'>[],
): {availability: NftAvailability; from: Date | null; to: Date | null} => {
  const eventNft = eventNfts.find((eventNft) => nftId === eventNft.cmsNft?.creatorNft?.nft_id);

  return getNftAvailabilityByCmsNft(eventNft);
};

export const isExclusive = <T extends CmsExhibitionNft>(nfts: T[]): T[] =>
  nfts.filter((nft) => nft.relType !== CmsExhibitionNftRelType.ACCESS);

const getAuctionedNftQuantityByActionStatus = (auction: CreatorAuction | undefined): number => {
  if (auction) {
    return auction.is_settled ? 0 : 1;
  }

  return 0;
};

export const isNftAvailabilityPassed = (availability: NftAvailability, balance: number) =>
  availability === NftAvailability.PASSED || balance === 0;

export const isNftAvailabilityComing = (availability: NftAvailability) => availability === NftAvailability.COMING_SOON;

/**
 * Sort NFTs by statuses in following order:
 *  1. ongoing NFTs
 *  2. coming soon NFTs
 *  3. sold out NFTs
 *  4. expired  NFTs
 */
export const sortByAvailability = <T extends CmsExhibitionNft>(
  nfts: T[],
  nftItems: {[key: string]: {balance: number; availability: NftSectionType}},
) => {
  const nftsSeparatedByType = separateNftByAvailabilityAndStatus(nfts, nftItems);

  return [
    ...nftsSeparatedByType[NftSectionType.ONGOING],
    ...nftsSeparatedByType[NftSectionType.COMING_SOON],
    ...nftsSeparatedByType[NftSectionType.SOLDOUT],
    ...nftsSeparatedByType[NftSectionType.PASSED],
  ];
};

export const sortNftsByAvailability = (nfts: NftCardInterface[]) => {
  const nftsSeparatedByType = groupNftByAvailabilityAndStatus(nfts);

  return [
    ...nftsSeparatedByType[AvailabilityStatus.ONGOING],
    ...nftsSeparatedByType[AvailabilityStatus.COMING_SOON],
    ...nftsSeparatedByType[AvailabilityStatus.SOLD_OUT],
    ...nftsSeparatedByType[AvailabilityStatus.OFFER_ENDED],
  ];
};

export const sortNftsByNftType = (nfts: NftCardInterface[]) =>
  nfts.sort((prev, next) => {
    if (prev.nftType === next.nftType) {
      return 0;
    }

    if (next.nftType === NftType.AUCTIONED) {
      return 1;
    }

    if (next.nftType === NftType.LIMITED) {
      return -1;
    }

    return -1;
  });

export const separateNftByAvailabilityAndStatus = <T extends CmsExhibitionNft>(
  nfts: T[],
  nftItems: {[key: string]: {balance: number; availability: NftSectionType}},
): Record<NftSectionType, T[]> => {
  return nfts.reduce(
    (acc, nft) => {
      const nftItem = nftItems[hashFromObjectHelper(nft)];

      if (nftItem.availability === NftSectionType.SOLDOUT) {
        acc[NftSectionType.SOLDOUT].push(nft);
      } else if (nftItem.availability === NftSectionType.COMING_SOON) {
        acc[NftSectionType.COMING_SOON].push(nft);
      } else if (nftItem.availability === NftSectionType.PASSED) {
        acc[NftSectionType.PASSED].push(nft);
      } else if (nftItem.availability === NftSectionType.ONGOING) {
        acc[NftSectionType.ONGOING].push(nft);
      } else {
        throw new Error(`nft doesn't have availability field`);
      }

      return acc;
    },
    {
      [NftSectionType.ONGOING]: [] as T[],
      [NftSectionType.COMING_SOON]: [] as T[],
      [NftSectionType.SOLDOUT]: [] as T[],
      [NftSectionType.PASSED]: [] as T[],
    },
  );
};

export const groupNftByAvailabilityAndStatus = (
  nfts: NftCardInterface[],
): Record<AvailabilityStatus, NftCardInterface[]> => {
  return nfts.reduce(
    (acc, nft) => {
      if (nft.availability === AvailabilityStatus.SOLD_OUT) {
        acc[AvailabilityStatus.SOLD_OUT].push(nft);
      } else if (nft.availability === AvailabilityStatus.COMING_SOON) {
        acc[AvailabilityStatus.COMING_SOON].push(nft);
      } else if (nft.availability === AvailabilityStatus.OFFER_ENDED) {
        acc[AvailabilityStatus.OFFER_ENDED].push(nft);
      } else if (nft.availability === AvailabilityStatus.ONGOING) {
        acc[AvailabilityStatus.ONGOING].push(nft);
      } else {
        throw new Error(`nft doesn't have availability field`);
      }

      return acc;
    },
    {
      [AvailabilityStatus.ONGOING]: [] as NftCardInterface[],
      [AvailabilityStatus.COMING_SOON]: [] as NftCardInterface[],
      [AvailabilityStatus.SOLD_OUT]: [] as NftCardInterface[],
      [AvailabilityStatus.OFFER_ENDED]: [] as NftCardInterface[],
    },
  );
};

const prepareNftBalanceAndAvailability = <T extends CmsExhibitionNft>(
  nfts: T[],
  getBalance: (nft: T) => number,
): {[key: string]: {balance: number; availability: NftSectionType}} => {
  return nfts.reduce((acc, nft) => {
    const availability: string = getNftAvailabilityByCmsNft(nft).availability;
    const balance = getBalance(nft);
    return {
      ...acc,
      [hashFromObjectHelper(nft)]: {
        availability: balance === 0 && availability !== NftSectionType.PASSED ? NftSectionType.SOLDOUT : availability,
        balance,
      },
    };
  }, {});
};

export const getAccessOrLimitedNftsByStatus = <T extends CmsExhibitionNft>(nfts: T[]): T[] => {
  const nftItems = prepareNftBalanceAndAvailability(nfts, (nft) => getNFTTotalOfferedQty(nft.cmsNft));
  return sortByAvailability(nfts, nftItems);
};

export const getSeparatedAccessOrLimitedNftsByAvailability = (
  nfts: CmsExhibitionNft[],
): Record<NftSectionType, CmsExhibitionNft[]> => {
  const nftItems = prepareNftBalanceAndAvailability(nfts, (nft) => getNFTTotalOfferedQty(nft.cmsNft));
  return separateNftByAvailabilityAndStatus(nfts, nftItems);
};

export const getAuctionedNftsByStatus = <T extends CmsExhibitionNft>(
  nfts: T[],
  auctions: {[key: string]: CreatorAuction | undefined} = {},
): T[] => {
  const nftItems = prepareNftBalanceAndAvailability(nfts, (nft) =>
    getAuctionedNftQuantityByActionStatus(auctions[hashFromObjectHelper(nft)]),
  );

  return sortByAvailability(nfts, nftItems);
};

export const separateNftsByPurchaseStatus = (
  nfts: CmsExhibitionNftWithUserPaymentInfo[],
): Map<boolean, CmsExhibitionNftWithUserPaymentInfo[]> => {
  return nfts.reduce(
    (acc, nft) => {
      if (getIsPaymentSuccess(nft.paymentStatus)) {
        acc.get(true)?.push(nft);
      } else {
        acc.get(false)?.push(nft);
      }
      nfts.filter((nft) => getIsPaymentSuccess(nft.paymentStatus));

      return acc;
    },
    new Map<boolean, CmsExhibitionNftWithUserPaymentInfo[]>([
      [true, []],
      [false, []],
    ]),
  );
};

export const getCreatorNft = async (nftId: string): Promise<CreatorNftWithBalance> => {
  return (await get(`/nft/${nftId}`, {base: FREEPORT_API_URL()})) as CreatorNftWithBalance;
};

export const getCreatorNftBalance = async (nftId: string): Promise<number> => {
  const nft = await getCreatorNft(nftId);

  return isRecord(nft) && nft?.balance && typeof nft?.balance === 'number' ? nft?.balance : 0;
};

export const getNftsOwnedByWallet = async (wallet: string): Promise<CreatorNftWithBalance[]> => {
  const nfts = (await get(`/wallet/${wallet}/nfts/owned`, {base: FREEPORT_API_URL()})) as unknown[];

  return nfts.filter(isCreatorNftWithBalance).filter((nft) => nft.quantity > 0);
};

export const getNftsBatch = async (ids: string[]): Promise<CreatorNftWithBalance[]> =>
  post('/nft:batchGet', {ids}, {base: FREEPORT_API_URL()}) as Promise<CreatorNftWithBalance[]>;
