import {ReactElement} from 'react';
import {Typography} from '@cere/rxb-template-ui-kit';
import {makeStyles, Theme} from '@material-ui/core';
import goldBackground from './gold-background.webp';

type Props = {
  children?: string;
};

const useStyles = makeStyles<Theme, {title?: string}>((theme) => ({
  title: {
    '--color': '#e9b87c',
    color: 'var(--color)',
    fontSize: '2.5rem',
    fontWeight: 800,
    zIndex: 2,
    position: 'relative',

    [theme.breakpoints.up('md')]: {
      fontSize: '3rem',
    },

    '@supports (-moz-background-clip: text) or (-webkit-background-clip: text)': {
      '-moz-background-clip': 'text',
      '-webkit-background-clip': 'text',
      backgroundImage: `url(${goldBackground})`,
      backgroundSize: 'cover',
      color: 'transparent',

      '&:after': {
        content: 'attr(data-text)',
        position: 'absolute',
        width: '100%',
        left: 0,
        top: 0,
        zIndex: 3,
        color: 'var(--color)',
        opacity: '0.5',
      },
    },
  },
}));

export function ExhibitionLiveTitle({children}: Props): ReactElement {
  const styles = useStyles({title: children});
  return (
    <Typography data-text={children} className={styles.title} variant="h3">
      {children}
    </Typography>
  );
}
