import useSWR from 'swr';
import {gql, useQuery} from '@apollo/client';
import {findExhibitionByAttachedNft} from '../../services/exhibition.service';
import {EXHIBITION_BASE_PROPS} from '../../queries/exhibitions-fragments';
import {CmsExhibitionNftRelType} from '../../types/graphql';
import {Exhibition} from '../../../pages/HomePage/types';
import {GetPromiseResult} from '../../types/type-utils';
import {useContext, useMemo} from 'react';
import {ExhibitContext, ExhibitionEmptyContext} from '../../../context/exhibition/exhibit-context';
import {NFT_FRAGMENT} from '../../queries/nfts';

export const EXHIBITION_BY_ID = gql`
  ${NFT_FRAGMENT}
  ${EXHIBITION_BASE_PROPS}
  query findExhibitionsById($id: ID!) {
    exhibition: cmsExhibition(id: $id) {
      ...ExhibitionBaseProps
      nfts {
        id
        availableFrom
        availableTo
        relType
        cmsNft {
          ...nftFragment
        }
      }
    }
  }
`;

type FindExhibitionResult = GetPromiseResult<ReturnType<typeof findExhibitionByAttachedNft>>;
type FindRelatedExhibitionResult = {
  data: FindExhibitionResult | undefined;
  error: Error | undefined;
};

function useFindRelatedExhibitionData(nftId: string | undefined): FindRelatedExhibitionResult {
  const {event, symbol} = useContext(ExhibitContext);
  const isInsideExhibitionContext = symbol !== ExhibitionEmptyContext;
  const fetchKey = useMemo(() => {
    if (isInsideExhibitionContext || !nftId) {
      return null;
    }
    return [nftId, 'findExhibitionByAttachedNft'];
  }, [isInsideExhibitionContext, nftId]);
  const {data, error} = useSWR<FindExhibitionResult, Error>(fetchKey, findExhibitionByAttachedNft);
  return {
    data: data || event,
    error,
  };
}

export function useFindRelatedExhibitionId(nftId: string | undefined): number | undefined {
  return useFindRelatedExhibitionData(nftId)?.data?.id;
}

export function useFindRelatedExhibitionSlug(nftId: string | undefined): string | undefined {
  return useFindRelatedExhibitionData(nftId)?.data?.slug;
}

export function useFindRelatedExhibition(nftId: string | undefined): Exhibition | undefined {
  const {symbol, event} = useContext(ExhibitContext);
  const isInsideExhibitionContext = symbol !== ExhibitionEmptyContext;
  const {data} = useFindRelatedExhibitionData(isInsideExhibitionContext ? undefined : nftId);
  const {data: response} = useQuery<{exhibition: Exhibition}>(EXHIBITION_BY_ID, {
    variables: {id: data?.id},
    skip: isInsideExhibitionContext || data?.id == null,
  });

  return isInsideExhibitionContext ? event : response?.exhibition;
}

export function useFindNftRelType(nftId: string | undefined): CmsExhibitionNftRelType | undefined {
  const event = useFindRelatedExhibition(nftId);
  return event?.nfts.find((nft) => nft.cmsNft?.creatorNft?.nft_id === nftId)?.relType;
}

export function useRelatedExhibitionStatus(nftId: string | undefined): 'loading' | 'failed' | 'ready' {
  const {data, error} = useFindRelatedExhibitionData(nftId);
  if (data) {
    return 'ready';
  }
  return error ? 'failed' : 'loading';
}
