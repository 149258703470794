import {alpha, makeStyles, Theme} from '@material-ui/core';
import colors from '../../../styles/colors';

export const useCardMediaStyles = makeStyles<
  Theme,
  {
    comingSoon: boolean;
    image?: string;
    isNftCollectiblesCard?: boolean;
  }
>((theme) => ({
  root: {
    width: '100%',
    height: ({isNftCollectiblesCard}) => (!isNftCollectiblesCard ? '320px' : '167px'),
    position: 'relative',
    overflow: 'hidden',
    backgroundImage: 'unset!important',

    '&:after': {
      content: '""',
      width: '100%',
      height: '100%',
      position: 'absolute',
      backgroundImage: ({image}) => `url(${image})`,
      backgroundSize: 'cover',
      filter: ({comingSoon}) => (comingSoon ? 'blur(1.3rem)' : 'unset'),
      zIndex: 10,
    },

    [theme.breakpoints.up('md')]: {
      height: ({isNftCollectiblesCard}) => (!isNftCollectiblesCard ? '320px' : '328px'),
    },

    [theme.breakpoints.up('lg')]: {
      height: ({isNftCollectiblesCard}) => (!isNftCollectiblesCard ? '400px' : '298px'),
    },
  },
}));

export const useCardStyles = makeStyles<
  Theme,
  {
    comingSoon: boolean;
    order?: number;
    isFeaturedListingsCard?: boolean;
    isNftCollectiblesCard?: boolean;
    withOutShadow?: boolean;
  }
>((theme) => ({
  container: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    borderRadius: '12px',
    boxShadow: ({withOutShadow}) => (withOutShadow ? 'none' : `0px 4px 20px ${alpha(colors.black, 0.12)}`),
    order: ({order}) => order,
    height: '100%',

    [theme.breakpoints.down('sm')]: {
      width: ({isFeaturedListingsCard, isNftCollectiblesCard}) =>
        isFeaturedListingsCard ? '320px' : isNftCollectiblesCard ? '167px' : '343px',
    },

    [theme.breakpoints.down('lg')]: {
      width: '328px',
    },

    [theme.breakpoints.up('lg')]: {
      width: ({isNftCollectiblesCard}) => (isNftCollectiblesCard ? '298px' : '400px'),
      boxShadow: 'none',
    },
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
  },
  comingSoon: {
    overflow: 'hidden',
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#fff',
    fontWeight: 700,
    zIndex: 100,

    '& span': {
      fontSize: '1.2rem',
    },
  },
  cardTitle: {
    fontWeight: 'bold',
    marginBottom: '6px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',

    [theme.breakpoints.down('sm')]: {
      marginBottom: '8px',
      fontSize: ({isNftCollectiblesCard}) => (!isNftCollectiblesCard ? 'inherit' : '12px'),
    },
  },
  cardAmount: {
    display: 'flex',
  },
  amountCommon: {
    fontWeight: 'bold',
    color: colors.lightGrey,

    [theme.breakpoints.down('sm')]: {
      fontSize: ({isNftCollectiblesCard}) => (!isNftCollectiblesCard ? 'inherit' : '10px'),
      lineHeight: ({isNftCollectiblesCard}) => (!isNftCollectiblesCard ? 'inherit' : '14px'),
    },
  },
  amountAuction: {
    paddingLeft: '1ch',
  },
  amountLimited: {
    fontWeight: 'normal',
  },
  cardService: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '12px 12px 16px 12px',
    flexGrow: 1,
  },
  share: {
    position: 'absolute',
    top: '12px',
    right: '12px',
    zIndex: 10,
  },
  serviceTexts: {
    display: 'flex',
    flexDirection: 'column',
  },
  serviceInfo: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  serviceInfoText: {
    display: 'flex',
    color: colors.primaryDark,
    fontWeight: 'bold',
  },
  serviceInfoSubText: {
    fontWeight: 'normal',
    paddingRight: '1ch',
  },
  serviceInfoButton: {
    minWidth: '122px',
  },
  buttonContainer: {
    display: 'flex',
    maxHeight: '44px',
    minWidth: '122px',
    marginLeft: 'auto',

    [theme.breakpoints.down('md')]: {
      display: ({isNftCollectiblesCard}) => (isNftCollectiblesCard ? 'none' : 'flex'),
    },

    '& button': {
      width: '100%',
      '&:hover': {
        backgroundColor: theme.palette.common.white,
      },
    },
  },
  buttonLight: {
    background: `${colors.lighter} !important`,
    color: `${colors.disable} !important`,
    cursor: 'default',
  },
  link: {
    textDecoration: 'none',
    color: 'currentColor',
  },
  inactiveLink: {
    cursor: 'default',
  },
}));
