import {memo, useCallback, useState} from 'react';
import {Box, Button, Divider, CircularProgress, makeStyles} from '@material-ui/core';

import {NftModalHighlightCard} from '../../../primitives/NftModalHighlightCard';
import {NftModalValueBlock} from '../../../primitives/NftModalValueBlock';
import {WalletBalanceCard} from '../../../primitives/WalletBalanceCard';
import {CopyBox} from '../../../primitives/CopyBox';
import {ErrorMsg} from '../../../primitives/ErrorMsg';
import {StyledLink} from '../../../primitives/StyledLink';

import {ReactComponent as BuyIcon} from '../assets/buy.svg';
import {PurchaseModalBox} from '../PurchaseModalBox';
import {PurchaseModalBoxInner} from '../PurchaseModalBoxInner';
import {useLocalization} from '../../../../utils/hooks/use-localization';
import {transformTokensToPrice} from '../../../../utils/helpers/price';

const useStyles = makeStyles((theme) => ({
  button: {
    width: '200px',
    textTransform: 'none',
    [theme.breakpoints.up('md')]: {
      height: '44px',
    },
  },
}));

type Props = {
  image: string;
  creatorName: string;
  title: string;
  nftLink: string;
  walletName: string;
  walletBalance: number;
  nftAddress: string;
  priceUSDC: number;
  quantity: number;
  onClose: () => void;
  purchase: () => void;
  fundWallet: () => void;
};

export const BuyLimitedNftModal = memo(
  ({
    image,
    creatorName,
    title,
    nftLink,
    walletName,
    walletBalance,
    nftAddress,
    priceUSDC,
    quantity,
    onClose,
    purchase,
    fundWallet,
  }: Props) => {
    const {t} = useLocalization();
    const styles = useStyles();

    const [isProcessing, setProcessing] = useState<boolean>(false);

    const totalAmount = priceUSDC * quantity;
    const insufficientFunds = totalAmount > walletBalance;

    const handlePurchase = useCallback(() => {
      setProcessing(true);
      purchase();
    }, [purchase]);

    const renderNftCardAndUserWallet = useCallback(
      () => (
        <>
          <StyledLink to={nftLink}>
            <NftModalHighlightCard image={image} creator={creatorName} title={title} />
          </StyledLink>
          <Box pt="16px">
            <WalletBalanceCard balance={transformTokensToPrice(walletBalance)} walletName={walletName} />
          </Box>
        </>
      ),
      [creatorName, image, nftLink, title, walletBalance, walletName],
    );

    const renderNftPriceInfo = useCallback(
      () => (
        <>
          <Box pb="8px">
            <CopyBox title={t('NFT ID')} copiedText={nftAddress} />
          </Box>
          <Divider />
          <Box pb="8px" pt="16px">
            <NftModalValueBlock title={t('Quantity')} value="1" />
          </Box>
          <Divider />
          <Box pb="8px" pt="16px">
            <NftModalValueBlock title={t('Price')} value={`${priceUSDC.toFixed(2)} USDC `} />
          </Box>
          <Divider />
          <Box pb="8px" pt="16px">
            <NftModalValueBlock
              title={t('Total amount')}
              value={`${transformTokensToPrice(totalAmount).toFixed(2)} USDC `}
              subValue={`(~${transformTokensToPrice(totalAmount).toFixed(2)} USD)`}
            />
          </Box>
          <Divider />
        </>
      ),
      [nftAddress, priceUSDC, totalAmount, t],
    );

    const renderAdditionalInfoBlock = useCallback(
      () => (insufficientFunds ? <ErrorMsg variant="error" text="Insufficient balance, fund your wallet" /> : <></>),
      [insufficientFunds],
    );

    const renderButtons = useCallback(
      () => (
        <>
          {insufficientFunds && (
            <Button
              variant="contained"
              color="primary"
              className={styles.button}
              disabled={isProcessing}
              onClick={fundWallet}
            >
              {t('Fund wallet')}
            </Button>
          )}
          <Button
            variant="contained"
            color="primary"
            className={styles.button}
            disabled={insufficientFunds || isProcessing}
            onClick={handlePurchase}
          >
            {isProcessing ? <CircularProgress /> : t('Buy')}
          </Button>
        </>
      ),
      [fundWallet, handlePurchase, insufficientFunds, isProcessing, styles.button, t],
    );

    return (
      <PurchaseModalBox icon={<BuyIcon />} title={t('Buy this NFT')} onClose={onClose}>
        <PurchaseModalBoxInner
          renderNftCardAndUserWallet={renderNftCardAndUserWallet}
          renderNftPriceInfo={renderNftPriceInfo}
          renderAdditionalInfoBlock={renderAdditionalInfoBlock}
          renderButtons={renderButtons}
        />
      </PurchaseModalBox>
    );
  },
);
