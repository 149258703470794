import 'swiper/swiper-bundle.css';
import {FullCreatorInterface} from '@cere/services-types';

import {CuratedRow} from 'shared/components/CuratedRow';
import {CreatorCard} from 'pages/CreatorsPage/CreatorCard';

interface HomePageCreatorsRowProps {
  featuredCreators: FullCreatorInterface[] | undefined;
}

export const HomePageCreatorsRow = ({featuredCreators}: HomePageCreatorsRowProps) => {
  return (
    <CuratedRow>
      {featuredCreators &&
        featuredCreators.map((creator) => <CreatorCard key={creator.id} artist={creator} isHomePageCard />)}
    </CuratedRow>
  );
};
