import {gql, useQuery} from '@apollo/client';
import {useCallback} from 'react';
import {useGetSigner} from '../../context/use-get-signer';
import {getNftContent} from '../services/ddc.services';
import {useNotifications} from '../hooks/use-notifications';
import {useLocalization} from '../hooks/use-locale.hook';
import {NftCardInterface} from '@cere/services-types';

const getNftCids = gql`
  query creatorNftWithCids($nftId: String!) {
    nfts: creatorNfts(where: {nft_id: $nftId}) {
      creator_nft_cids(sort: "published_at:asc") {
        cid
      }
    }
  }
`;

type NftWithCids = {
  creator_nft_cids: Array<{cid: string}>;
};

export const useGetRequestContentDownloadLink = (
  nft: NftCardInterface | undefined,
  nftOwnerPublicKey: string | undefined,
  setAssetContent: Function,
) => {
  const getSigner = useGetSigner(nftOwnerPublicKey ?? '');
  const {warning, success} = useNotifications();
  const {t} = useLocalization();

  const {data: creatorNftsQuery} = useQuery<{nfts: NftWithCids[]}>(getNftCids, {
    variables: {nftId: nft?.address},
    skip: nft?.address == null,
  });

  const nftWithCids = creatorNftsQuery?.nfts?.[0];
  const firstNftCid = nftWithCids?.creator_nft_cids?.[0]?.cid ?? '';

  return useCallback(async () => {
    try {
      const signer = await getSigner();
      const minter = nft?.minter || '';
      const content = await getNftContent({
        minter,
        cid: firstNftCid,
        signer,
        owner: nftOwnerPublicKey ?? '',
      });
      setAssetContent(content);
      const link = document.getElementById('linkId') as HTMLAnchorElement;
      link.click();
      success(t('NFT was successfully downloaded'));
    } catch (e) {
      console.error(e);
      warning(t("Couldn't load content at the moment. Please, try later"));
    }
  }, [firstNftCid, getSigner, nft?.minter, nftOwnerPublicKey, success, warning, setAssetContent, t]);
};
