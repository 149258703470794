import {useContext, useState} from 'react';
import {Dialog, Typography, Button} from '@cere/rxb-template-ui-kit';
import {makeStyles, Box} from '@material-ui/core';
import {SwitchNetworkContext} from '../../../context/switch-network-context';
import BootstrapLoader from '../../../shared/components/bootstrap-loader';
import {switchNetwork} from '../../../shared/services/non-custody-wallet';
import {useGetSelectedNonCustodialWallet} from '../../../shared/hooks/non-custody-wallet.hook';
import {useLocalization} from '../../../shared/hooks/use-locale.hook';

const useStyles = makeStyles(() => ({
  header: {
    display: 'flex',
    justifyContent: 'center',
  },
  text: {
    textAlign: 'center',
  },
  button: {
    minWidth: '130px',
  },
}));

export const SwitchNetworkDialog = () => {
  const classes = useStyles();
  const {t} = useLocalization();
  const {isSwitchNetworkDialogVisible, hideNetworkDialog, switchNetworkWalletType} = useContext(SwitchNetworkContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const {getSelectedWallet} = useGetSelectedNonCustodialWallet();

  const handleCancelClick = () => {
    hideNetworkDialog();
  };

  const handleSwitchClick = async () => {
    try {
      setIsLoading(true);
      // there is a case when need to switch network for not connected wallet
      const selectedWalletType = switchNetworkWalletType || getSelectedWallet().type;
      await switchNetwork(selectedWalletType);
      hideNetworkDialog();
    } catch (e) {
      alert(t('Something unexpected happened. Please try again later.'));
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog
      open={isSwitchNetworkDialogVisible}
      onClose={handleCancelClick}
      headerClassName={classes.header}
      headerContent={<Typography variant="h3">{t('Wrong network')}</Typography>}
      disableCloseIcon
    >
      <Box m="15px">
        <Typography className={classes.text}>
          {t(
            'Currently, we only support Polygon network for transactions. Make sure your wallet is connected to Polygon network in order to sign transactions.',
          )}
        </Typography>
        <Box display="flex" justifyContent="space-between" mt="10px">
          <Button color="primary" variant="outlined" className={classes.button} onClick={handleCancelClick}>
            {t('Cancel')}
          </Button>
          <Button
            color="primary"
            variant="contained"
            className={classes.button}
            onClick={handleSwitchClick}
            disabled={isLoading}
          >
            {isLoading ? <BootstrapLoader /> : t('Switch network')}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};
