// @ts-ignore // TODO: Add declaration types
import {cereWebSDK} from '@cere/sdk-js/dist/web';
import {CereSDK} from '@cere/sdk-js/dist/src';

import {ProviderInterface} from './provider-interface';
import {APP_ID, ENV} from '../../../config/common';

const userAgent = navigator.userAgent;

export class CereProvider implements ProviderInterface {
  private readonly cereSdk: CereSDK | null = null;

  constructor(public readonly sessionId: string) {
    this.cereSdk = cereWebSDK(APP_ID(), this.sessionId, {deployment: ENV});
  }

  init(): void {}

  track(eventName: string, metadata: any): void {
    return this.cereSdk?.sendEvent(eventName, {
      ...metadata,
      ...{
        // TODO: Consider moving to SDK.
        metadata: {
          userAgent,
        },
      },
    });
  }

  identify() {}
}
