import {AnyAction, MiddlewareAPI} from 'redux';
import {INftsApi} from '../../../api/nfts/INftsApi';
import {
  CancelNFTSellingCommand,
  CancelNFTSellingFailedEvent,
  CancelNFTSellingSuccessEvent,
  NFTSellFailedEvent,
  NFTSellSuccessEvent,
  SellNFTCommand,
  ShowNFTSellFailedModalCommand,
  ShowNFTSellingCancelFailedModalCommand,
  ShowNFTSellingCancelSuccessModalCommand,
  ShowNFTSellSuccessModalCommand,
  ShowSellNFTModalCommand,
  StartNFTSellingCommand,
} from './actions';
import {selectNftById} from '../nfts/selectors';
import {NftDocument} from '../nfts/actions';
import {ISellNftApi} from '../../../models/selling/ISellNftApi';
import {WalletConnectionService} from '../../../models/wallet/WalletConnectionService';
import {selectPurchasedNft} from '../purchase-history/selectors';
import {SelectWalletTypeCommand} from '../wallets';
import {selectCurrentLocale} from '../localization/selectors';
import {SetPendingTransactionForNftCommand} from '../pending-transactions/actions';
import {PendingTransactionType} from '../../../models/pending-transactions/types';

export const startNftSellingMiddleware =
  (nftApi: INftsApi) =>
  ({dispatch, getState}: MiddlewareAPI) =>
  (next: Function) =>
  async (action: AnyAction) => {
    next(action);
    if (action.type === StartNFTSellingCommand.type) {
      const nftId = action.payload;
      const state = getState();
      const nft = selectNftById(state, nftId);
      const locale = selectCurrentLocale(state);
      if (!nft) {
        try {
          const loadedNft = await nftApi.getNftById(nftId, locale);
          dispatch(NftDocument.create(loadedNft));
        } catch (e) {
          console.error(e);
          dispatch(NFTSellFailedEvent.create(nftId, e.message));
          return;
        }
      }
      dispatch(ShowSellNFTModalCommand.create(nftId));
    }
  };

export const sellNftMiddleware =
  (sellApi: ISellNftApi, walletConnectionService: WalletConnectionService) =>
  ({dispatch, getState}: MiddlewareAPI) =>
  (next: Function) =>
  async (action: AnyAction) => {
    next(action);
    if (action.type === SellNFTCommand.type) {
      const {nftId, price} = action.payload;
      const nft = selectNftById(getState(), nftId);
      if (!nft) {
        dispatch(NFTSellFailedEvent.create(nftId, `Cannot find nft data`));
        return;
      }
      const purchasedNft = selectPurchasedNft(getState(), nftId);
      if (!purchasedNft) {
        dispatch(NFTSellFailedEvent.create(nftId, `Cannot find purchased nft data`));
        return;
      }
      const {userWalletAddress, qty} = purchasedNft;
      const selectedWalletAddress = walletConnectionService.getSelectedWalletAddress();
      if (userWalletAddress !== selectedWalletAddress) {
        dispatch(SelectWalletTypeCommand.create(walletConnectionService.getWalletTypeByAddress(userWalletAddress)));
      }
      try {
        const timestamp = await sellApi.sellNft(nft.address, price, nft.collectionAddress);
        dispatch(NFTSellSuccessEvent.create());
        dispatch(ShowNFTSellSuccessModalCommand.create(nftId, price, qty));
        dispatch(
          SetPendingTransactionForNftCommand.create(
            userWalletAddress,
            nftId,
            PendingTransactionType.SellTransaction,
            price,
            timestamp,
          ),
        );
      } catch (e) {
        dispatch(NFTSellFailedEvent.create(nftId, e.message));
        dispatch(ShowNFTSellFailedModalCommand.create(nftId, price, qty));
      }
    }
  };

export const cancelNftSellingMiddleware =
  (sellApi: ISellNftApi, walletConnectionService: WalletConnectionService) =>
  ({dispatch, getState}: MiddlewareAPI) =>
  (next: Function) =>
  async (action: AnyAction) => {
    next(action);
    if (action.type === CancelNFTSellingCommand.type) {
      const nftId = action.payload;
      const nft = selectNftById(getState(), nftId);
      if (!nft) {
        dispatch(CancelNFTSellingFailedEvent.create(nftId, 'Cannot find nft data'));
        return;
      }
      const purchasedNft = selectPurchasedNft(getState(), nftId);
      if (!purchasedNft) {
        dispatch(CancelNFTSellingFailedEvent.create(nftId, 'Cannot find purchased nft data'));
        return;
      }
      const {userWalletAddress} = purchasedNft;
      const selectedWalletAddress = walletConnectionService.getSelectedWalletAddress();
      if (userWalletAddress !== selectedWalletAddress) {
        dispatch(SelectWalletTypeCommand.create(walletConnectionService.getWalletTypeByAddress(userWalletAddress)));
      }
      try {
        await sellApi.cancelNftSelling(nft.address, nft.collectionAddress);
        dispatch(CancelNFTSellingSuccessEvent.create());
        dispatch(ShowNFTSellingCancelSuccessModalCommand.create());
      } catch (e) {
        dispatch(CancelNFTSellingFailedEvent.create(nftId, e.message));
        dispatch(ShowNFTSellingCancelFailedModalCommand.create(e.message));
      }
    }
  };
