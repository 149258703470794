import {FC, useEffect} from 'react';
import {Box} from '@material-ui/core';
import {makeStyles} from '@material-ui/core';

import PageHeader from '../PageHeader';
import {Footer} from '../Footer';
import {Condition} from '../Conditions';
import {useScrollToElement} from 'shared/hooks/scroll-to-element.hook';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    webkitBoxOrient: 'vertical',
    backgroundColor: theme.palette.background.default,

    [theme.breakpoints.down('md')]: {
      width: '100%',
      minWidth: '100%',
      maxWidth: '100%',
    },
  },
}));

interface IPageLayoutProps {
  hideBackButton?: boolean;
  textHeader?: string;
  hideFooter?: boolean;
  hideHeader?: boolean;
}

export const PageLayout: FC<IPageLayoutProps> = ({children, hideBackButton, textHeader, hideFooter, hideHeader}) => {
  const classes = useStyles();

  const {refElement, scrollToElement} = useScrollToElement();
  useEffect(() => {
    scrollToElement();
  }, [scrollToElement]);

  return (
    <Box className={classes.root} {...{ref: refElement}}>
      <Condition condition={!hideHeader}>
        <PageHeader hideBackButton={hideBackButton} textHeader={textHeader} />
      </Condition>
      {children}
      {!hideFooter && (
        <Box marginTop="auto">
          <Footer />
        </Box>
      )}
    </Box>
  );
};
