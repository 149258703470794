import {FullCreatorInterface} from '@cere/services-types';
import {CreatorsDocument, CreatorsFetchingFinishedEvent, CreatorsFetchingStartedEvent} from './actions';
import {AnyAction, combineReducers} from 'redux';

export const allCreatorsReducer = (state: FullCreatorInterface[] = [], action: AnyAction): FullCreatorInterface[] => {
  switch (action.type) {
    case CreatorsDocument.type:
      return action.payload as FullCreatorInterface[];
    default:
      return state;
  }
};

export const creatorsLoadingReducer = (state: boolean = false, action: AnyAction): boolean => {
  switch (action.type) {
    case CreatorsFetchingStartedEvent.type:
      return true;
    case CreatorsFetchingFinishedEvent.type:
      return false;
    default:
      return state;
  }
};

export const creatorsModuleReducer = combineReducers({
  allCreators: allCreatorsReducer,
  loading: creatorsLoadingReducer,
});
