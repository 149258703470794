import {createContext} from 'react';
import {CmsExhibitionNftRelType} from '../../types/graphql';
import {Exhibition, ExhibitionNft, PartialExhibitionNft} from './types';
import {useNftCtaButtonProps} from './use-nft-cta-button-props';
import {useNftAttributes} from './use-nft-attributes';
import {NFTOffer} from '../../hooks/use-nft-listing';
import {makePartialExhibitionNft} from './utils';
import {Numberish} from '../../types/numberish';
import {useRelatedExhibitionStatus} from './use-find-related-exhibition';
import {NftPurchaseParams} from '../WithPurchaseNft/v2';
import {FullCreatorInterface, NftCardInterface} from '@cere/services-types';

export type NftContextType = {
  eventUrl: string;
  cmsNftId: Numberish;
  event: Exhibition | undefined;
  eventNftStatus: ReturnType<typeof useRelatedExhibitionStatus>;
  partialNft: PartialExhibitionNft;
  eventNft: ExhibitionNft | undefined;
  bffNft?: NftCardInterface | undefined;
  bffCreator?: FullCreatorInterface | undefined;
  relType: CmsExhibitionNftRelType | undefined;
  attributes: ReturnType<typeof useNftAttributes>;
  supply: number;
  balance: number;
  listing: {
    offers: NFTOffer[];
    ownerOffer: NFTOffer | null;
  };
  price: {
    units: number;
    usd: number;
    cents: number;
  };
  handlers: {
    sell: () => unknown;
    buy: () => Promise<unknown>;
    handlePurchase: (nftParams: NftPurchaseParams) => Promise<unknown>;
  };
  market: {
    owned: number;
  };
  auction: {
    priceText: string;
  };
  button: ReturnType<typeof useNftCtaButtonProps>;
  isNftDetailsPage?: boolean;
};

export const NftContext = createContext<NftContextType>({
  relType: undefined,
  eventNftStatus: 'loading',
  partialNft: makePartialExhibitionNft(-1),
  cmsNftId: -100,
  eventNft: undefined,
  event: undefined,
  bffNft: undefined,
  bffCreator: undefined,
  eventUrl: '',
  balance: 0,
  supply: 0,
  listing: {
    offers: [],
    ownerOffer: null,
  },
  attributes: {
    auctionSettled: false,
    availability: undefined,
    availableOnlyOnMarketplace: false,
    hasPendingTransaction: false,
    counter: '',
    failed: false,
    link: '',
    offerEnded: false,
    order: 0,
    pending: false,
    purchased: false,
    soldOut: false,
  },
  price: {
    units: 0,
    usd: 0,
    cents: 0,
  },
  handlers: {
    sell: () => null,
    buy: () => Promise.resolve(),
    handlePurchase: () => Promise.resolve(),
  },
  market: {
    owned: 0,
  },
  auction: {
    priceText: 'Starting price',
  },
  button: {
    label: '',
    disabled: false,
    loading: false,
    isLightButton: false,
    onClick: () => null,
    onShare: () => null,
  },
  isNftDetailsPage: false,
});
