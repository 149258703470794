import {UserAuction, UserWalletNft} from './types';

export const isUserWalletNft = (data: any): data is UserWalletNft =>
  typeof data?.wallet === 'string' && typeof data?.quantity === 'number' && data?.nft_id?.cmsNfts?.length > 0;

export const isUserAuction = (data: any): data is UserAuction =>
  typeof data?.seller === 'string' && data?.nft_id?.cmsNfts?.length > 0;

export const isPurchasedNft = (data: any): data is {quantity: number} => typeof data?.quantity === 'number';

export const isUserNftOffer = (data: any): data is {seller: string; price: number} =>
  typeof data?.seller === 'string' && typeof data?.price === 'number';
