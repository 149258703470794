import {makeStyles, Theme} from '@material-ui/core';

import colors from 'styles/colors';

export const useContainerStyles = makeStyles<Theme, {previewImage?: string; isLiveMode?: boolean}>((theme) => ({
  main: {
    backgroundImage: ({previewImage, isLiveMode}) =>
      previewImage &&
      `${
        isLiveMode
          ? 'linear-gradient(to top,rgb(0, 0, 0, 0.72),rgb(0, 0, 0, 0.72))'
          : 'linear-gradient(180deg, rgba(22, 22, 22, 0) 0%, #000 94.27%)'
      },url("${previewImage}")`,
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    position: 'relative',
    borderBottom: '1px solid #484848',
    minHeight: '500px',
    padding: '48px 0 16px 0',

    [theme.breakpoints.up('md')]: {
      padding: '48px 0 140px 0',
    },

    [theme.breakpoints.up('lg')]: {
      padding: '148px 0 137px 0',
    },
  },
  shareIconBox: {
    position: 'absolute',

    [theme.breakpoints.down('md')]: {
      right: '16px',
      top: '16px',
    },

    [`${theme.breakpoints.up('md')} and (max-width:${theme.breakpoints.values.lg}px)`]: {
      bottom: '30px',
      left: '50%',
      transform: 'translateX(-50%)',
    },

    [theme.breakpoints.up('lg')]: {
      right: `calc(+50vw - 620px)`,
      top: '48px',
    },
  },
}));

export const useStyles = makeStyles<Theme, {exhibitionPreview?: string}>((theme) => ({
  exhibitionInfo: {
    color: colors.white,
    display: 'flex',
    flexDirection: 'column',

    [theme.breakpoints.up('md')]: {
      padding: '0 16px',
      alignItems: 'center',
    },

    [theme.breakpoints.up('lg')]: {
      padding: '0',
      flexDirection: 'row',
      gridTemplateColumns: '1fr 1fr',
      margin: '0 -16px',
      display: 'grid',
    },
  },
  exhibitionInfoLiveTheme: {
    [theme.breakpoints.down('md')]: {
      paddingBottom: '40px',
    },
    [theme.breakpoints.down('lg')]: {
      paddingTop: '136px',
    },
  },
  exhibitionInfoNotStarded: {
    [theme.breakpoints.down('md')]: {
      paddingBottom: '40px',
    },
  },
  finishedExhibitionInfo: {
    [theme.breakpoints.up('md')]: {
      margin: '0 0 140px 0',
    },

    [theme.breakpoints.up('lg')]: {
      margin: '148px 0 137px 0',
    },
  },

  exhibitionInfoLeft: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '1rem',

    [theme.breakpoints.down('lg')]: {
      paddingBottom: '1rem',
    },

    [theme.breakpoints.up('lg')]: {
      alignItems: 'start',
      justifyContent: 'center',
      maxWidth: '575px',
      minWidth: '575px',
    },
  },
  exhibitionCounter: {
    display: 'inline-flex',
    alignItems: 'center',
    flexDirection: 'column',
    marginBottom: 'var(--gap)',
    order: 2,
    width: '180px',
  },

  exhibitionCalendar: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    marginBottom: 'var(--gap)',
    order: 2,
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row',
      marginBottom: 'unset',
    },
  },

  exhibitionCalendarNotStarted: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1),
    height: '60px',
    borderRadius: '30px',
    background: 'rgba(22, 22, 22, 0.5)',
    width: 'fit-content',

    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      height: 'auto',
      padding: theme.spacing(1.5, 1),
    },
  },

  exhibitionCalendarNotStartedLockedView: {
    [theme.breakpoints.down('lg')]: {
      order: -1,
    },
    [theme.breakpoints.up('lg')]: {
      position: 'absolute',
      left: `calc(+50vw - 640px)`,
      top: '48px',
    },
  },

  exhibitionCalendarLiveTheme: {
    position: 'absolute',
    [theme.breakpoints.down('lg')]: {
      top: '48px',
      left: '50%',
      transform: 'translateX(-50%)',
    },
    [theme.breakpoints.up('lg')]: {
      left: `calc(+50vw - 640px)`,
      top: '48px',
    },
  },

  exhibitionTitlesSoldOut: {
    textAlign: 'center',
    order: 1,
    marginBottom: '24px',
    marginTop: '53px',
    color: colors.light,

    [theme.breakpoints.up('md')]: {
      marginBottom: '32px',
      marginTop: '90px',
      order: 1,
    },

    [theme.breakpoints.up('lg')]: {
      marginTop: 'unset',
      marginBottom: '20px',
      order: 2,
      textAlign: 'unset',
    },
  },

  exhibitionTitles: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    textAlign: 'center',
    order: 1,
    color: colors.light,

    [theme.breakpoints.up('sm')]: {
      gap: '1.5rem',
    },
    [theme.breakpoints.down('lg')]: {
      alignItems: 'center',
    },
    [theme.breakpoints.up('lg')]: {
      order: 'unset',
      textAlign: 'unset',
    },
  },
  exhibitionTitleWithoutTheme: {
    fontWeight: 800,
    fontSize: '32px',
    lineHeight: '40px',
    maxWidth: '344px',
    textAlign: 'center',
    margin: '0 auto',
    [theme.breakpoints.up('md')]: {
      maxWidth: 'unset',
    },
    [theme.breakpoints.up('lg')]: {
      textAlign: 'left',
      fontSize: '48px',
      lineHeight: '56px',
      maxWidth: '575px',
      minWidth: '575px',
    },
  },
  cellParamClassName: {
    fontSize: '8px !important',
    lineHeight: '14px !important',
  },
  cellClassName: {
    fontSize: '16px !important',
    lineHeight: '26px !important',
  },
  liveExhibit: {
    padding: '2px 14px 2px 6px',
    background: colors.red,
    fontSize: '10px',
    lineHeight: 2,
    color: colors.light,
    position: 'relative',
    width: 'max-content',
    borderRadius: '6px',
    margin: '12px auto 0',

    '&::after': {
      content: '""',
      width: '4px',
      height: '4px',
      borderRadius: '50%',
      background: colors.light,
      position: 'absolute',
      right: '6px',
      top: '10px',
    },
  },
  eventSubtitle: {
    fontSize: '16px',
  },
  exhibitionTitle: {
    marginBottom: '0',
    maxWidth: '343px',
    [theme.breakpoints.up('md')]: {
      maxWidth: '575px',
      minWidth: '575px',
    },
  },
  exhibitionImageBlock: {
    margin: '1rem auto 0 auto',
    [theme.breakpoints.up('md')]: {
      margin: '0 auto',
    },
  },
  finishedExhibitionImageBlock: {
    margin: '1rem auto 0 auto',
    [theme.breakpoints.up('md')]: {
      margin: '0 auto',
    },
    [theme.breakpoints.up('lg')]: {
      margin: '0 4.5rem 0 auto',
    },
  },
  soldOutExhibitionContainer: {
    marginTop: '1.5rem',
    marginBottom: '2.5rem',
    [theme.breakpoints.up('lg')]: {
      display: 'flex',
      height: '685px',
      alignItems: 'center',
    },
  },
  soldOutExhibitionCounter: {
    display: 'none',
    order: 2,
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginBottom: '24px',
      order: 2,
    },
    [theme.breakpoints.up('lg')]: {
      alignItems: 'center',
      width: 'min-content',
      order: 1,
    },
  },
  soldOutTitle: {
    [theme.breakpoints.down('lg')]: {
      display: 'none',
    },
  },
  exhibitionTeaserContainer: {
    width: '90vw',
    height: '55vw',
    position: 'relative',
    maxWidth: '686px',
    maxHeight: '400px',
    [theme.breakpoints.up('lg')]: {
      width: '686px',
      height: '400px',
    },
  },
  exhibitionImagePreview: {
    width: '90vw',
    height: '55vw',
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      maxWidth: '487px',
      width: '487px',
      height: '332px',
    },

    '--radius': '12px',
    '--stroke': '2px',
    backgroundImage: ({exhibitionPreview}) => `url(${exhibitionPreview})`,
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    borderRadius: 'var(--radius)',

    '@supports not (-webkit-mask-composite: xor)': {
      border: 'var(--stroke) solid rgba(243, 102, 130, 0.8)',
    },

    '@supports (-webkit-mask-composite: xor)': {
      '&::before': {
        content: "''",
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        padding: 'var(--stroke)',
        borderRadius: 'var(--radius)',
        background:
          'linear-gradient(226.34deg, rgba(244, 104, 129, 0.8) 15.52%, rgba(243, 102, 130, 0.8) 16.27%, rgba(231, 69, 150, 0.8) 29.76%, rgba(224, 49, 162, 0.8) 41.02%, rgba(221, 42, 166, 0.8) 48.62%, rgba(82, 16, 226, 0.8) 77.78%)',
        mask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
        '-webkit-mask-composite': 'xor',
      },
    },
  },
  closedExhibitionImagePreview: {
    '--radius': '12px',
    borderRadius: 'var(--radius)',
    width: '343px',
    height: '200px',

    [theme.breakpoints.up('md')]: {
      width: '487px',
      height: '332px',
    },
    [theme.breakpoints.up('lg')]: {
      width: '480px',
      height: '330px',
    },
  },
  enterExhibitButtonBlock: {
    margin: '1.5rem auto 0.5rem auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '174px',
    position: 'absolute',
    top: '-50px',
    left: 0,
    right: 0,

    [theme.breakpoints.up('md')]: {
      bottom: '-35px',
      top: 'unset',
      width: '245px',
    },
  },
  enterExhibitButton: {
    padding: '8px 16px !important',
    zIndex: 1,
  },
  enterExhibitIcon: {
    backgroundColor: '#cb1451',
    minWidth: '2.5rem',
    minHeight: '2.5rem',
    borderRadius: '50%',
  },
  exhibitImagePreview: {
    width: '343px',
    height: '235px',
    position: 'relative',

    [theme.breakpoints.up('md')]: {
      width: '480px',
      height: '330px',
    },
  },

  enterExhibitionButton: {
    zIndex: 1,
    position: 'absolute',
    top: '-50px',
    left: 0,
    right: 0,

    [theme.breakpoints.up('md')]: {
      padding: '8px 16px !important',
      position: 'absolute',
      bottom: '0px',
      top: 'unset',
      left: 'unset',
      right: 'unset',
    },
  },

  enterButtonBlock: {
    top: '0',

    [theme.breakpoints.up('md')]: {
      bottom: '-35px',
    },
  },

  calendarIcon: {
    '& path ': {
      stroke: colors.primaryDark,
    },
  },
  grey: {
    color: colors.lighter,
  },
  addToCalendarBtn: {
    color: colors.primaryDark,
  },

  addToCalendarText: {
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '20px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '16px',
      lineHeight: '22px',
    },
  },
  description: {
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '21px',
    textAlign: 'center',
    color: '#E0E0E7',
    marginTop: '4px',
    order: 1,
    [theme.breakpoints.down('sm')]: {
      width: '222px',
    },
  },
  descriptionNotStarted: {
    marginTop: '-16px',
    marginBottom: '24px',
  },
  descriptionHide: {
    display: 'none',
  },
  counterContainer: {
    maxWidth: 'unset',
    minWidth: '180px',
  },
  notStartedTitles: {
    [theme.breakpoints.down('md')]: {
      marginTop: '38px',
    },
  },

  iconStyle: {
    width: '40px',
    height: '40px',
    background: 'rgba(140, 140, 148, 0.5)',
    backdropFilter: 'blur(8px)',
    borderRadius: '50%',
    margin: '0 auto 16px auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    '& svg': {
      width: '11px',
      height: '13px',

      '& path': {
        fill: colors.light,
      },
    },
    [theme.breakpoints.up('sm')]: {
      width: '48px',
      height: '48px',
      marginBottom: '4px',

      '& svg': {
        width: '13px',
        height: '16px',

        '& path': {
          fill: colors.light,
        },
      },
    },
    [theme.breakpoints.up('lg')]: {
      margin: '0 16px 0 0',
      display: 'inline-flex',
    },
  },

  lockHourglassStyle: {
    width: '40px',
    height: '40px',
    background: 'rgba(140, 140, 148, 0.5)',
    backdropFilter: 'blur(8px)',
    borderRadius: '50%',
    margin: '0 auto 12px auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    '& svg': {
      '& path': {
        fill: colors.light,
      },
    },
    [theme.breakpoints.up('md')]: {
      width: '48px',
      height: '48px',
    },
    [theme.breakpoints.up('lg')]: {
      margin: '0 16px 0 0',
      display: 'inline-flex',
    },
  },
  noAccess: {
    marginTop: '24px',
    [theme.breakpoints.up('sm')]: {
      marginTop: '32px',
      marginBottom: '40px',
    },
  },
  pendingAllocationPage: {
    marginTop: '84px',
    [theme.breakpoints.up('sm')]: {
      marginTop: '32px',
      marginBottom: '40px',
    },
  },
  countdown: {
    display: 'flex',
    color: colors.lighter,
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '16px',
    margin: 'auto 0',

    '& span': {
      color: colors['navbar-text'],
      display: 'block',
      width: '92px',
      paddingLeft: '0',

      [theme.breakpoints.up('lg')]: {
        width: '193px',
        textAlign: 'right',
      },
    },
  },
  countdownContainer: {
    width: '262px',
    height: '44px',
    padding: '6px 16px',
    background: 'rgba(22, 22, 22, 0.5)',
    backdropFilter: 'blur(20px)',
    borderRadius: '6px',

    [theme.breakpoints.up('md')]: {
      marginBottom: '40px',
    },

    [theme.breakpoints.up('lg')]: {
      width: '487px',
      height: '60px',
      display: 'flex',
      margin: 'unset',
    },
  },
  lockedExhibitDescription: {
    color: colors.lighter,
    textAlign: 'center',
    padding: '0 2rem',
    minHeight: '3.5rem',
    [theme.breakpoints.down('lg')]: {
      marginBottom: '1rem',
    },
    [theme.breakpoints.up('lg')]: {
      padding: 'unset',
      display: 'inline',
    },
  },
  teaserBlock: {
    margin: '0 auto',

    [theme.breakpoints.up('lg')]: {
      margin: 'unset',
    },
  },
  lockExhibitionImageBlock: {
    margin: '1rem auto 0 auto',

    [theme.breakpoints.up('md')]: {
      margin: '0 auto',
    },
    [theme.breakpoints.up('lg')]: {
      paddingRight: '50px',
    },
  },
  enterLockExhibitButtonBlock: {
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'absolute',
    left: 0,
    right: 0,
    top: '-52px',
    width: '245px',
  },

  stayUpdatedButton: {
    border: `1px solid rgba(255, 255, 255, 0.4) !important`,
    color: `${colors.lighter} !important`,
    paddingLeft: '40px',
    paddingRight: '40px',
  },

  lockContentInfo: {
    order: 4,
  },

  lockExhibitionInfo: {
    padding: '0 16px',
    color: colors.white,
    gap: '2rem 2rem',
    display: 'flex',
    flexDirection: 'column',
    margin: '55px 0 0',

    [theme.breakpoints.up('md')]: {
      padding: '0 140px',
      alignItems: 'center',
      margin: '140px 0',
    },

    [theme.breakpoints.up('lg')]: {
      padding: '0',
      margin: '148px 0 137px 0',
      gap: '2rem 5.5rem',
      display: '-webkit-box',
      gridTemplateColumns: '1fr max-content',
    },
  },
}));

export const useConfirmationStyles = makeStyles((theme) => ({
  confirmationContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  checkIcon: {
    width: '19px',
    height: '19px',
    marginTop: '23px',
    marginBottom: '13px',

    [theme.breakpoints.up('md')]: {
      width: '22px',
      height: '22px',
      marginTop: '35px',
      marginBottom: '15px',
    },
  },
  title: {
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '22px',

    [theme.breakpoints.up('md')]: {
      fontWeight: 700,
      fontSize: '20px',
      lineHeight: '26px',
    },
  },
  message: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '21px',
    marginTop: '4px',
    textAlign: 'center',

    [theme.breakpoints.up('md')]: {
      fontSize: '16px',
      lineHeight: '24px',
    },
  },
  button: {
    width: '200px',
    height: '36px',
    border: '1px solid #161616',
    borderRadius: '50px',
    margin: '24px auto 0 auto',

    '&:hover': {
      backgroundColor: colors.light,
    },

    [theme.breakpoints.up('md')]: {
      width: '282px',
      height: '48px',
      margin: '32px auto 0 auto',
    },
  },
  buttonText: {
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '22px',
    textTransform: 'none',
  },
}));

export const useLiveThemeCalendarStyles = makeStyles<Theme>((theme) => ({
  container: {
    backgroundColor: 'rgba(22, 22, 22, 0.5)',
    padding: '8px 12px',
    borderColor: '#FA0C5833',

    [theme.breakpoints.down('md')]: {
      borderRadius: '16px',
      minHeight: 'unset',
    },
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      minHeight: '42px',
      minWidth: 'unset',
      width: 'fit-content',
      whiteSpace: 'nowrap',
      borderRadius: '21px',
      alignItems: 'center',
    },
  },
  liveExhibit: {
    display: 'flex',
    alignItems: 'center',
    padding: '2px 20px 2px 8px',
    backgroundColor: 'rgba(250, 12, 88, 0.1)',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '16px',
    color: theme.palette.secondary.main,
    height: '24px',
    borderRadius: '12px',

    [theme.breakpoints.up('md')]: {
      marginBottom: 0,
      marginLeft: '16px',
      order: 2,
    },

    '&::after': {
      content: '""',
      width: '6px',
      height: '6px',
      borderRadius: '50%',
      background: '#FA0C58',
      position: 'absolute',
      right: '9px',
      top: '9px',
    },
  },
}));
