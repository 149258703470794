import {Typography, Counter} from '@cere/rxb-template-ui-kit';
import {Box} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import {useLocalization} from '../../../hooks/use-locale.hook';

const useStyles = makeStyles({
  counterCell: {
    fontSize: '10px',
    width: '20px',
    height: '22px',
  },
  delimiter: {
    color: 'rgba(224, 224, 231, 0.5)',
  },
});

const EventCounter = ({date}: {date: Date}) => {
  const {t} = useLocalization();
  const classes = useStyles();

  return (
    <Box
      display="flex"
      height="100%"
      flexDirection="column"
      justifyContent="flex-end"
      padding="12px"
      position="relative"
      zIndex="2"
    >
      <Typography variant="body2">{t('Auction ends')}</Typography>
      <Counter startsAt={date} cellClassName={classes.counterCell} delimiterClassName={classes.delimiter} withSec />
    </Box>
  );
};
export {EventCounter};
