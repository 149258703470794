import {useState, useContext, useMemo} from 'react';
import {UserContext} from '../../context/user-context/user-context';
import {
  deleteNonCustodyWallet,
  postNonCustodyWallet,
  fetchNonCustodyWallets,
} from '../services/non-custody-wallet.service';
import {NonCustodyWallet, NonCustodyWalletTypeEnum} from '../types/non-custody-wallet';
import {connectAndSignIn as connectAndSignInTorus, disconnect as disconnectTorus} from '../services/torus';
import {
  connectAndSignIn as connectAndSignInWaletConnect,
  disconnect as disconnectWaletConnect,
} from '../services/wallet-connect';
import {connectAndSignIn as connectAndSignInMetamask, disconnect as disconnectMetamask} from '../services/metamask';
import {AppWallet} from '../types/supported-wallet';
import {dispatchError} from '../lib/error-handler';
import {SwitchNetworkContext} from '../../context/switch-network-context';
import {INCORRECT_NETWORK_TYPE_ERROR_CODE} from '../constants/errors';
import analyticService, {AnalyticEventsEnum} from '../services/analytic.service';
import {useLocalization} from './use-locale.hook';
import {AppContext} from '../../context/app-context';

type ConnectTorusOptions = {
  reconnect?: boolean;
  completeUrl?: string;
};

export const useGetSelectedNonCustodialWallet = () => {
  const {t} = useLocalization();
  const {nonCustodyWallets, selectedWallet} = useContext(UserContext);

  const getSelectedWallet = (): NonCustodyWallet => {
    const wallet = nonCustodyWallets.find((walletItem) => walletItem.type === selectedWallet);
    if (!wallet) {
      throw new Error(t("Can't retrieve the wallet"));
    }

    return wallet;
  };

  return {getSelectedWallet};
};

export const useConnectNonCustodyWallets = () => {
  const {t} = useLocalization();
  const {appConfig} = useContext(AppContext);
  const [isTorusLoading, setIsTorusLoading] = useState<boolean>(false);
  const [isMetamaskLoading, setIsMetamaskLoading] = useState<boolean>(false);
  const [isWalletConnectLoading, setIsWalletConnectLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [success, setSuccess] = useState<boolean>(false);
  const {userData, setSelectedWallet, selectedWallet, nonCustodyWallets, getNonCustodyWallets} =
    useContext(UserContext);
  const {showNetworkDialog, setSwitchNetworkWalletType} = useContext(SwitchNetworkContext);
  const analyticsEventPayload = useMemo(() => ({userEmail: userData.userEmail ?? null}), [userData]);

  const addNonCustodyForUser = async (type: NonCustodyWalletTypeEnum, account: string) => {
    const accountAddress = account.toLocaleLowerCase();
    const connectedWallets = await fetchNonCustodyWallets(userData.token);
    let existingWallet = connectedWallets.find((wallet) => wallet.type === type);
    let shouldDeleteWallet = existingWallet && existingWallet.publicKey.toLocaleLowerCase() !== accountAddress;

    if (shouldDeleteWallet) {
      await deleteNonCustodyWallet(userData.token, type);
    }

    if (!existingWallet || shouldDeleteWallet) {
      await postNonCustodyWallet(userData.token, type, accountAddress);
      await getNonCustodyWallets();
    }

    //  We need to switch to current wallet if it wasn't connected before
    if (!existingWallet) {
      setSelectedWallet(type);
    }
  };

  const connectTorus = async ({reconnect = false, completeUrl}: ConnectTorusOptions = {}) => {
    setIsTorusLoading(true);
    setSuccess(false);
    setError('');

    try {
      const account = await connectAndSignInTorus({
        replaceInstance: true,
        completeUrl,
        appName: appConfig.appTitle,
        logoUrl: appConfig.logoShort.url,
      });

      await addNonCustodyForUser(NonCustodyWalletTypeEnum.TORUS, account);
      setSuccess(true);
    } catch (e) {
      console.error('Something went wrong', e);
      setError(e.message);
    } finally {
      setIsTorusLoading(false);
    }

    if (!reconnect) {
      analyticService.track(AnalyticEventsEnum.WALLET_CONNECTED, {type: NonCustodyWalletTypeEnum.TORUS});
      analyticService.track(AnalyticEventsEnum.TORUS_WALLET_COMPLETED, analyticsEventPayload);
      analyticService.gtmTrack(AnalyticEventsEnum.TORUS_WALLET_COMPLETED, analyticsEventPayload);
    }
  };

  const connectMetamask = async () => {
    setIsMetamaskLoading(true);
    setSuccess(false);
    setError('');

    try {
      const account = await connectAndSignInMetamask();
      await addNonCustodyForUser(NonCustodyWalletTypeEnum.METAMASK, account);
      setSuccess(true);
    } catch (e) {
      if (e?.code === INCORRECT_NETWORK_TYPE_ERROR_CODE) {
        showNetworkDialog();
        setSwitchNetworkWalletType(NonCustodyWalletTypeEnum.METAMASK);
      } else {
        console.error('Something went wrong', e);
        setError(e.message);
        return;
      }
    } finally {
      setIsMetamaskLoading(false);
    }

    analyticService.track(AnalyticEventsEnum.WALLET_CONNECTED, {type: NonCustodyWalletTypeEnum.METAMASK});
    analyticService.track(AnalyticEventsEnum.METAMASK_WALLET_COMPLETED, analyticsEventPayload);
    analyticService.gtmTrack(AnalyticEventsEnum.METAMASK_WALLET_COMPLETED, analyticsEventPayload);
  };

  const connectWalletConnect = async (setIsWalletConnectLoading: Function) => {
    setIsWalletConnectLoading(true);
    setSuccess(false);
    setError('');

    try {
      const account = await connectAndSignInWaletConnect(setIsWalletConnectLoading);
      await addNonCustodyForUser(NonCustodyWalletTypeEnum.WALLET_CONNECT, account);
      setSuccess(true);
    } catch (e) {
      // is it the same error code as with metamask?
      if (e?.code === INCORRECT_NETWORK_TYPE_ERROR_CODE) {
        showNetworkDialog();
        setSwitchNetworkWalletType(NonCustodyWalletTypeEnum.WALLET_CONNECT);
      } else {
        console.error('Something went wrong', e);
        setError(e.message);
        return;
      }
    } finally {
      setIsWalletConnectLoading(false);
    }

    analyticService.track(AnalyticEventsEnum.WALLET_CONNECTED, {type: NonCustodyWalletTypeEnum.WALLET_CONNECT});
    analyticService.track(AnalyticEventsEnum.WALLETCONNECT_WALLET_COMPLETED, analyticsEventPayload);
    analyticService.gtmTrack(AnalyticEventsEnum.WALLETCONNECT_WALLET_COMPLETED, analyticsEventPayload);
  };

  const disconnectWalletByType = async (wallet: NonCustodyWalletTypeEnum) => {
    switch (wallet) {
      case NonCustodyWalletTypeEnum.TORUS:
        return disconnectTorus();
      case NonCustodyWalletTypeEnum.METAMASK:
        return disconnectMetamask();
      case NonCustodyWalletTypeEnum.WALLET_CONNECT:
        return disconnectWaletConnect();
      default:
        break;
    }
  };

  const disconnectNonCustodyWallet = async (wallet: NonCustodyWalletTypeEnum) => {
    try {
      const isDisconnected = await disconnectWalletByType(wallet);

      if (!isDisconnected) {
        return false;
      }

      await deleteNonCustodyWallet(userData.token, wallet);
      if (selectedWallet === wallet) {
        setSelectedWallet(nonCustodyWallets.filter(({type}) => type !== wallet)?.[0]?.type || AppWallet.DAVINCI);
      }

      await getNonCustodyWallets();
      return true;
    } catch (e) {
      console.error(e);
      dispatchError(t('Failed to disconnect wallet!'));
      return false;
    }
  };

  return {
    isTorusLoading,
    isMetamaskLoading,
    isWalletConnectLoading,
    connectTorus,
    connectMetamask,
    connectWalletConnect,
    error,
    success,
    setIsTorusLoading,
    setIsWalletConnectLoading,
    setIsMetamaskLoading,
    disconnectNonCustodyWallet,
  };
};
