import {useEffect, useState} from 'react';
import {Box, makeStyles, Theme, Typography} from '@material-ui/core';
import clsx from 'clsx';
import {useLocalization} from 'shared/hooks/use-locale.hook';

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    position: 'absolute',
    top: 75,
    left: 'calc(50% - 124px)',
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'center',
    width: '253px',
    padding: '12px',
    backgroundColor: theme.palette.common.white,
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.12)',
    borderRadius: '12px',
    zIndex: 50,

    [theme.breakpoints.up('lg')]: {
      top: 80,
      width: '294px',
      left: 'calc(50% - 147px)',
      padding: '16px',
    },
  },
  hidden: {
    display: 'none',
  },
}));

export const WelcomeBackPopup = () => {
  const classes = useStyles();
  const {t} = useLocalization();
  const [open, setOpen] = useState<boolean>(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setOpen(false);
    }, 5000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Box className={clsx(classes.root, !open && classes.hidden)}>
      <Typography>👋 {t('Welcome back!')}</Typography>
    </Box>
  );
};
