import {useCallback} from 'react';
import {
  AuctionInterface,
  AuctionStatus,
  AvailabilityStatus,
  NftType,
  CheckUserHasNftEnum,
  NftSellingType,
} from '@cere/services-types';
import {Button} from '../../primitives/Button';
import {SellButton} from './SellButton';
import {useLocalization} from '../../../utils/hooks/use-localization';
import {BidButton} from './BidButton';
import {BuyButton} from './BuyButton';

type NftCardCompositeButtonProps = {
  className?: string;
  excludeSellButton?: boolean;
  nftId: string;
  nftPrice: number;
  nftPurchaseStatus?: CheckUserHasNftEnum;
  nftAuction?: AuctionInterface;
  nftAuctionStatus: AuctionStatus;
  nftAvailability: AvailabilityStatus;
  nftSellingType?: NftSellingType;
  nftType: NftType;
  nftBalance?: number;
  sellerWalletAddress?: string;
  disabled?: boolean;
};

export const NftCardCompositeButton = ({
  className,
  excludeSellButton,
  nftId,
  nftPrice,
  nftAuction,
  nftAuctionStatus,
  nftAvailability,
  nftPurchaseStatus,
  nftSellingType,
  nftType,
  nftBalance,
  disabled,
  sellerWalletAddress,
}: NftCardCompositeButtonProps) => {
  const {t} = useLocalization();

  const renderDisabledButton = useCallback(
    (text: string) => (
      <Button className={className} disabled>
        {text}
      </Button>
    ),
    [className],
  );

  const renderNftButton = useCallback(() => {
    if (!excludeSellButton && nftPurchaseStatus === CheckUserHasNftEnum.USER_HAS_NFT) {
      return <SellButton className={className} nftId={nftId} disabled={disabled} />;
    }
    if (
      nftAuction &&
      nftAuction?.bids?.length > 0 &&
      nftAuctionStatus === AuctionStatus.ACTIVE &&
      nftAvailability === AvailabilityStatus.OFFER_ENDED
    ) {
      return renderDisabledButton(t('Sold out'));
    }
    if (nftAuction && nftAuctionStatus === AuctionStatus.ACTIVE && nftAvailability === AvailabilityStatus.OFFER_ENDED) {
      return renderDisabledButton(t('Offer ended'));
    }
    if (nftAuction && nftAuctionStatus === AuctionStatus.ACTIVE) {
      return (
        <BidButton className={className} nftId={nftId} sellerWalletAddress={sellerWalletAddress!} disabled={disabled} />
      );
    }
    if (nftAvailability === AvailabilityStatus.COMING_SOON) {
      return renderDisabledButton(t('Coming soon'));
    }
    if (nftAuctionStatus === AuctionStatus.NOT_STARTED && nftType !== NftType.ACCESS) {
      return renderDisabledButton(t('Coming soon'));
    }
    if (nftAvailability === AvailabilityStatus.SOLD_OUT || !nftBalance) {
      return renderDisabledButton(t('Sold out'));
    }
    if (nftAvailability === AvailabilityStatus.OFFER_ENDED) {
      return renderDisabledButton(t('Offer ended'));
    }
    if (nftAuctionStatus === AuctionStatus.SETTLED && nftType === NftType.AUCTIONED) {
      if (nftBalance) {
        return (
          <BuyButton
            className={className}
            nftId={nftId}
            sellingType={nftSellingType!}
            price={nftPrice}
            sellerWalletAddress={sellerWalletAddress!}
            disabled={disabled}
          />
        );
      }
      return renderDisabledButton(t('Sold out'));
    }
    return (
      <BuyButton
        className={className}
        nftId={nftId}
        sellingType={nftSellingType!}
        price={nftPrice}
        sellerWalletAddress={sellerWalletAddress!}
        disabled={disabled}
      />
    );
  }, [
    className,
    disabled,
    excludeSellButton,
    nftAuction,
    nftAuctionStatus,
    nftAvailability,
    nftBalance,
    nftId,
    nftPrice,
    nftPurchaseStatus,
    nftSellingType,
    nftType,
    renderDisabledButton,
    sellerWalletAddress,
    t,
  ]);

  return renderNftButton();
};
