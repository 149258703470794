import {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {singletonHook} from 'react-singleton-hook';
import {getERC20balance, getSelectedWalletPublicKey} from '../services/wallet.service';
import {UserContext} from '../../context/user-context/user-context';
import {BALANCE_UPDATE_DELAY_TIMEOUT} from '../../config/common';
import {SupportedWallet} from '../types/supported-wallet';

export interface UserWalletBalance {
  usdc: number;
}

const init = {walletBalance: {} as UserWalletBalance, isLoading: true};

export const usePollUserWalletBalance = (
  wallet?: SupportedWallet,
): {walletBalance: UserWalletBalance; isLoading: boolean} => {
  const [walletBalance, setWalletBalance] = useState<UserWalletBalance>({} as UserWalletBalance);
  const [updateTimer, setUpdateTimer] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const {selectedWalletPublicKey, userData, nonCustodyWallets} = useContext(UserContext);

  const walletToPoll = useMemo(() => {
    if (wallet) {
      return getSelectedWalletPublicKey(wallet, userData.userPublicKey, nonCustodyWallets);
    }

    return selectedWalletPublicKey;
  }, [selectedWalletPublicKey, wallet, userData.userPublicKey, nonCustodyWallets]);

  const getBalance = useCallback(async () => {
    if (!walletToPoll) {
      return;
    }

    try {
      if (!updateTimer) {
        setIsLoading(true);
      }
      const ercBalance = await getERC20balance(walletToPoll, userData.token);

      setWalletBalance({
        usdc: ercBalance,
      });
    } catch (e) {
      console.error('Balance fetching error: ', e);
    } finally {
      setIsLoading(false);
    }
  }, [updateTimer, walletToPoll, userData.token]);

  useEffect(() => {
    setInterval(() => {
      setUpdateTimer(+new Date());
    }, BALANCE_UPDATE_DELAY_TIMEOUT);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getBalance();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateTimer, walletToPoll]);

  return {walletBalance, isLoading};
};

export const useSingletonFetchUserWalletBalance = singletonHook(init, usePollUserWalletBalance);
