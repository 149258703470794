import {Dispatch} from 'redux';
import {connect} from 'react-redux';
import {generatePath} from 'react-router-dom';
import {SellSuccessModal as PlainSellSuccessModal} from '../../shared/Modals/Sell/SellSuccessModal';
import {selectNftById} from '../../../redux/modules/nfts/selectors';
import {selectCurrentLocale} from '../../../redux/modules/localization/selectors';
import {ROUTES} from '../../../constants/routes';
import {CloseActiveModalCommand} from '../../../redux/modules/modals/actions';

type SellSuccessModalProps = {
  nftId: string;
  price: number;
};

const mapStateToProps = (state: any, {nftId, price}: SellSuccessModalProps) => {
  const nft = selectNftById(state, nftId);
  const locale = selectCurrentLocale(state);
  return {
    image: nft.image!,
    title: nft.title,
    nftLink: generatePath(ROUTES.NFT_PAGE, {nftId, locale}),
    royalties: 0, // FixMe
    price,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onClose: () => dispatch(CloseActiveModalCommand.create()),
});

export const SellSuccessModal = connect(mapStateToProps, mapDispatchToProps)(PlainSellSuccessModal);
