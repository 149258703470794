import {useCallback, useState} from 'react';
import {Box} from '@material-ui/core';

import {enableMarketplaceView} from '../../config/common';
import {useAuthorized} from '../../shared/hooks/auth.hook';
import {PageLayout} from '../../shared/components/PageLayout';
import {SellNftContextProvider} from '../../context/sell-nft-context';

import {ALL_WALLET, AllWallets} from './types';
import {useSharedStyles} from './shared-styles';
import {WalletSelector} from './wallet-selector';
import {useWalletNfts} from './use-wallet-nfts';
import {PageHeader} from './components/page-header.component';
import {UserNftCollectionComponent} from './components/user-nft-collection.component';
import {WalletNftsSellButton} from './components/wallet-nfts-sell-button.component';
import {useLocaleUpdated} from 'shared/hooks/use-locale-updated.hook';
import {useHistory} from 'react-router-dom';
import {useLocalization} from '../../shared/hooks/use-locale.hook';

export const UserNftsPage = () => {
  const {t} = useLocalization();
  const history = useHistory();
  const sharedStyles = useSharedStyles();
  const [activeWallet, setActiveWallet] = useState<AllWallets>(ALL_WALLET);
  const {nfts, isLoading} = useWalletNfts(activeWallet);

  useAuthorized();

  useLocaleUpdated(
    useCallback(
      (locale, prevLocale) => {
        history.push(history.location.pathname.replace(`/${prevLocale}/`, `/${locale}/`));
      },
      [history],
    ),
  );

  return (
    <SellNftContextProvider>
      <PageLayout>
        <Box className={sharedStyles.page}>
          <PageHeader
            title={t('My collection')}
            rightElement={enableMarketplaceView() && <WalletNftsSellButton nfts={nfts} />}
          />

          <WalletSelector setActiveWallet={setActiveWallet} />
          <UserNftCollectionComponent nfts={nfts} isLoading={isLoading} />
        </Box>
      </PageLayout>
    </SellNftContextProvider>
  );
};
