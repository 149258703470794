import {Freeport, Marketplace} from '@cere/freeport-sdk';
import {ISellNftApi} from './ISellNftApi';
import {ContractsProvider} from '../blockchain/ContractsProvider';

export class FreeportSellNftApi implements ISellNftApi {
  constructor(
    private readonly contractsProvider: ContractsProvider,
    private readonly gasLimit: number,
    private readonly gasPrice: number,
  ) {}

  async sellNft(nftAddress: string, price: number, collectionAddress?: string): Promise<number> {
    const contract = await this.getContract(Boolean(collectionAddress));
    return await this.makeNftOffer(contract, nftAddress, price);
  }

  async cancelNftSelling(nftAddress: string, collectionAddress?: string): Promise<void> {
    const contract = await this.getContract(Boolean(collectionAddress));
    await this.makeNftOffer(contract, nftAddress, 0);
  }

  private async makeNftOffer(contract: Marketplace | Freeport, nftAddress: string, price: number): Promise<number> {
    const tx = await contract.makeOffer(nftAddress, price, {
      gasLimit: this.gasLimit,
      gasPrice: this.gasPrice,
    });
    await tx.wait();
    return tx.timestamp || Date.now();
  }

  private async getContract(hasCollectionAddress: boolean) {
    if (hasCollectionAddress) {
      return await this.contractsProvider.getMarketplaceContract();
    }
    return await this.contractsProvider.getFreeportContract();
  }
}
