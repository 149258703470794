import React, {useCallback, useEffect, useState} from 'react';

import {LocalizationContext} from './localization-context';

import {LOCAL_STORAGE_USER_LOCALE} from '../../const/storage-keys';
import {AVAILABLE_LANGUAGES, DEFAULT_LANGUAGE} from '../../config/common';
import i18n from '../../i18n';
import {translationsApi} from '../../api';

const getInitialLocaleFromPath = (userLang: string): string => {
  const pathArray = window.location.pathname.split('/').filter(Boolean);
  const pathLocale = pathArray[0];

  if (pathLocale && AVAILABLE_LANGUAGES().includes(pathLocale)) {
    return pathLocale;
  }

  return userLang;
};

export const LocalizationContextProvider: React.FC = ({children}) => {
  const [userLocale, setUserLocale] = useState(localStorage.getItem(LOCAL_STORAGE_USER_LOCALE) || DEFAULT_LANGUAGE);
  const [locale, setLocale] = useState(getInitialLocaleFromPath(userLocale));

  const updateLocale = (newLocale: string) => {
    setLocale(newLocale);
    setUserLocale(locale);
  };

  useEffect(() => {
    i18n.changeLanguage(locale);
    localStorage.setItem(LOCAL_STORAGE_USER_LOCALE, locale);
  }, [locale]);

  const fetchTranslations = useCallback(async () => {
    const translations = await translationsApi.getTranslationsByLocale(locale);
    i18n.addResourceBundle(locale, 'translation', translations, true, true);
  }, [locale]);

  useEffect(() => {
    fetchTranslations();
  }, [fetchTranslations]);

  return (
    <LocalizationContext.Provider
      value={{
        userLocale,
        locale,
        updateLocale,
      }}
    >
      {children}
    </LocalizationContext.Provider>
  );
};
