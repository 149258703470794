import {createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import {Box, makeStyles} from '@material-ui/core';
import {gql, useQuery} from '@apollo/client';
import {Modal, Typography} from '@cere/rxb-template-ui-kit';
import {NftCardMiniature} from '../NftCardMiniature';
import {AuthForm} from '../../../pages/SignInPage/auth-form';
import {Stripe} from '../Stripe';
import {CmsExhibitionCmsNftRelTypeOnly, CmsExhibitionIdSlugOnly, CmsExhibitionNftRelType} from '../../types/graphql';
import {formatPrice} from '../../lib/formatPrice';
import {UserContext} from '../../../context/user-context/user-context';
import {AuthAnalyticsTrackingContext} from '../../../pages/SignInPage/auth-analytics-tracking.context';
import analyticService from '../../services/analytic.service';
import {checkIfUserHasNft} from '../../services/payment.service';
import {unwrap} from '../../lib/unwrap';
import {IKeys} from '../../services/auth.service';
import {useAuth} from '../../hooks/auth.hook';
import {CheckUserHasNftEnum} from '@cere/services-types';
import {useNotifications} from '../../hooks/use-notifications';
import {useHistory} from 'react-router-dom';
import {useIsPreviewMode} from '../../hooks/use-preview-mode-hooks';
import {PublicationState} from '../../types/cms-exhibit';
import {ReactComponent as CardIcon} from '../../../assets/icons/card.svg';
import colors from '../../../styles/colors';
import {usePurchaseNftContext} from '../../../context/purchase-nft-context';
import {AuthType, AuthPageState} from '../../../pages/SignInPage/types';
import {NFT_FRAGMENT} from '../../queries/nfts';
import {useFindRelatedExhibitionId} from '../Nft/use-find-related-exhibition';
import {ReactComponent as CheckIcon} from '../../../assets/check.svg';
import {Verify} from '../VerifyEmailGuard/Verify';
import {useNftListing} from '../../hooks/use-nft-listing';
import {useLocalization} from '../../hooks/use-locale.hook';

interface BuyNftGuardProps {
  button?: string;
  nftId: string;
  eventId?: number;
  relType: string;
  nftTitle: string;
  nftAddress: string;
  nftImage?: string;
  nftPrice: number;
  noVerify?: boolean;
  onClose: () => unknown;
  onSuccess: (email: string, social?: boolean) => void;
  sellerWalletAddress?: string;
  open: boolean;
}

const query = gql`
  ${NFT_FRAGMENT}
  query Exhibition($id: ID!, $publicationState: PublicationState) {
    cmsExhibition(id: $id, publicationState: $publicationState) {
      id
      slug
      cmsCreator {
        name
      }
      nfts {
        id
        relType
        cmsNft {
          ...nftFragment
        }
      }
    }
  }
`;

const useStyles = makeStyles(() => ({
  modalTitle: {
    '& h3': {
      fontWeight: 600,
      fontSize: '16px',
      lineHeight: '22px',
    },
  },
  cardIcon: {
    width: '17px',
    height: '13px',
    margin: '0 10px 4px 0',

    '& path': {
      stroke: colors.snowWhite,
    },
  },
  description: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '21px',
  },
  paymentSuccessful: {
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '22px',
    marginBottom: '16px',
  },
  checkIcon: {
    width: '19px',
    height: '19px',
    marginBottom: '11px',
  },
  grey: {
    color: colors.lightGrey,
  },
}));

type Exhibition = CmsExhibitionIdSlugOnly & {
  cmsCreator: {
    name: string;
  };
  nfts: CmsExhibitionCmsNftRelTypeOnly[];
};

const dropUrlQuery = () => {
  const url = new URL(window.location.href);
  const previewKey = url.searchParams.get('preview_key');
  url.search = '';
  if (previewKey) {
    url.searchParams.append('preview_key', previewKey);
  }
  window.history.replaceState({}, '', url.href);
};

export type AuthContextType = {
  variant: AuthPageState;
  setVariant: Function;
};

export const AuthContext = createContext<AuthContextType>({
  variant: AuthPageState.AUTH,
  setVariant: () => {},
});

export const BuyNftGuard = ({
  nftId,
  eventId,
  relType,
  nftAddress,
  nftImage,
  nftTitle,
  nftPrice,
  open,
  onClose,
  onSuccess,
  button,
  sellerWalletAddress,
}: BuyNftGuardProps) => {
  const {t, locale} = useLocalization();
  const [variant, setVariant] = useState(AuthPageState.AUTH);
  const value = {variant, setVariant};

  const resolve = useRef<(data?: unknown) => unknown>(() => null);
  const reject = useRef<(data?: unknown) => unknown>(() => null);
  const [showVerifyModal, setShowVerifyModal] = useState(false);
  const {userData} = useContext(UserContext);
  const [email, setEmail] = useState<string | undefined>();
  const {setAuthData} = useAuth();
  const {purchase} = usePurchaseNftContext();
  const {buySendClicked} = useContext(AuthAnalyticsTrackingContext);
  const isPreviewMode = useIsPreviewMode();
  const relatedEventId = useFindRelatedExhibitionId(eventId?.toString() || nftAddress);
  const {primaryOffer} = useNftListing({cmsNftIncrementId: nftId, start: 0});
  const {data: event, error: exhibitionError} = useQuery<
    {cmsExhibition: Exhibition},
    {id?: number; publicationState: PublicationState}
  >(query, {
    variables: {
      id: eventId || relatedEventId,
      publicationState: isPreviewMode ? PublicationState.PREVIEW : PublicationState.LIVE,
    },
    skip: !eventId && !relatedEventId,
  });
  const isAuthenticated = useMemo(
    () => Boolean(userData.userPublicKey) && Boolean(userData.userEmail),
    [userData.userEmail, userData.userPublicKey],
  );
  const isAccessNft = useMemo(() => relType === CmsExhibitionNftRelType.ACCESS, [relType]);
  const [showStripe, setShowStripe] = useState(false);
  const styles = useStyles();

  const price: ReactNode | null = useMemo(() => {
    if (!relType) {
      return null;
    }
    if (relType === CmsExhibitionNftRelType.AUCTIONED) {
      return <>{t('Latest bid: {{price}}', {price: formatPrice(nftPrice)})}</>;
    } else {
      return purchase?.price ? <>{formatPrice(purchase?.price)}</> : <>{formatPrice(nftPrice)}</>;
    }
  }, [relType, nftPrice, purchase?.price, t]);

  const history = useHistory();
  const {warning, info} = useNotifications();

  const buttonText = (authType: AuthType) => {
    if (relType === CmsExhibitionNftRelType.ACCESS || relType === CmsExhibitionNftRelType.LIMITED) {
      return (
        <>
          <CardIcon className={styles.cardIcon} />
          {authType === 'Sign up' ? t('Create account to buy') : t('Sign in to buy')}
        </>
      );
    } else if (relType === CmsExhibitionNftRelType.AUCTIONED) {
      return (
        <>
          <CardIcon className={styles.cardIcon} />
          {authType === 'Sign up' ? t('Create account to bid') : t('Sign in to bid')}
        </>
      );
    }
    if (button) {
      return button;
    }
    return authType === 'Sign up' ? t('Create account to buy') : t('Sign in to buy');
  };

  const verifyOtp = useCallback(
    () =>
      new Promise((resolveCallback, rejectCallback) => {
        resolve.current = resolveCallback;
        reject.current = rejectCallback;
        setShowVerifyModal(true);
      }),
    [],
  );

  useEffect(() => {
    if (userData.userEmail) {
      setEmail(userData.userEmail);
    }
  }, [userData.userEmail]);

  const onAuthSuccess = useCallback(
    async (email: string) => {
      setEmail(email);
      if (relType === CmsExhibitionNftRelType.ACCESS && primaryOffer) {
        analyticService.track(buySendClicked);
        checkIfUserHasNft(unwrap(email), nftAddress)
          .then(async (userNftStatus) => {
            if (userNftStatus === CheckUserHasNftEnum.USER_DOES_NOT_HAVE_NFT) {
              if (sellerWalletAddress && primaryOffer.seller !== sellerWalletAddress) {
                await verifyOtp();
                onSuccess(email);
                return;
              }
              if (!userData.userEmail) {
                setShowStripe(true);
                return;
              }
              onSuccess(email);
              return;
            }
            info(t('This email is already registered. Enter the exhibit directly with your purchased NFT ticket.'));
            onSuccess(email);
            setAuthData(email, '', '');
            setEmail(email);
            await verifyOtp();
            dropUrlQuery();
            history.push(`/${locale}/home/exhibit/${unwrap(event?.cmsExhibition.slug)}`);
          })
          .catch(() => {
            warning(t("Can't purchase the access NFT at the moment. Please try later"));
          });
      } else {
        onSuccess(email);
      }
    },
    [
      relType,
      primaryOffer,
      buySendClicked,
      nftAddress,
      sellerWalletAddress,
      userData.userEmail,
      verifyOtp,
      onSuccess,
      info,
      t,
      setAuthData,
      history,
      locale,
      event?.cmsExhibition.slug,
      warning,
    ],
  );

  const closeHandler = useCallback(() => {
    onClose();
    setVariant(AuthPageState.AUTH);
  }, [onClose]);

  useEffect(() => {
    if (exhibitionError) {
      onClose();
    }
  }, [exhibitionError, onClose]);

  const handleCloseVerifyModal = useCallback(() => {
    reject.current();
    setShowVerifyModal(false);
  }, []);

  const handleStripeClose = useCallback(
    (_event, reason) => {
      if (reason !== 'backdropClick') {
        setShowStripe(false);
        onClose();
      }
    },
    [onClose],
  );

  const handleVerify = useCallback(
    (keys: IKeys) => {
      try {
        setAuthData(unwrap(email), keys.publicKey, keys.token);
        resolve.current();
      } catch (e) {
        console.error(e);
      } finally {
        handleCloseVerifyModal();
      }
    },
    [email, handleCloseVerifyModal, setAuthData],
  );

  useEffect(() => {
    if (!open) {
      return;
    }
    if (userData.userEmail) {
      void onAuthSuccess(userData.userEmail);
      return;
    }
  }, [onAuthSuccess, open, userData.userEmail]);

  if (!event) {
    return <></>;
  }

  return (
    <>
      <Modal
        open={open && !isAuthenticated}
        onClose={closeHandler}
        title={
          variant === AuthPageState.VERIFY
            ? t('Success, verify it’s you')
            : t('You’re one step away from your NFT purchase')
        }
        maxWidth="xs"
        headerClassName={styles.modalTitle}
      >
        {variant === AuthPageState.VERIFY ? (
          <Box display="flex" alignItems="center" flexDirection="column">
            <CheckIcon className={styles.checkIcon} />
            <Typography className={styles.paymentSuccessful}>{t('Payment successful')}</Typography>
          </Box>
        ) : (
          <Typography variant="h4" className={styles.description}>
            {t('Leave your email to purchase your NFT and create an account to access the marketplace.')}
          </Typography>
        )}
        {nftImage && (
          <Box m="8px 0 15px">
            <NftCardMiniature
              imgSrc={nftImage}
              title={nftTitle}
              content={<Typography variant="h4">{price}</Typography>}
            />
          </Box>
        )}

        {variant === AuthPageState.VERIFY && (
          <Box mt="8px" mb="24px">
            <Typography variant="body2" className={styles.grey}>
              {t('Please enter the verification code sent to your email to complete verification for your NFTs.')}
            </Typography>
          </Box>
        )}

        <Box mt="1rem">
          <AuthContext.Provider value={value}>
            <AuthForm withHeader={false} onComplete={onAuthSuccess} noVerify={isAccessNft} buttonText={buttonText} />
          </AuthContext.Provider>
        </Box>
      </Modal>
      <Modal
        disableEscapeKeyDown
        open={showStripe && email}
        onClose={handleStripeClose}
        title={t('Access the exhibit')}
        maxWidth="xs"
      >
        <Stripe
          event={event.cmsExhibition}
          email={email}
          nftAddress={nftAddress}
          nftTitle={nftTitle}
          relType={relType}
          quantity={1}
        />
      </Modal>
      <Verify
        email={email}
        open={showVerifyModal}
        onVerify={handleVerify}
        onClose={handleCloseVerifyModal}
        title={nftTitle}
        imgSrc={nftImage ?? ''}
      />
    </>
  );
};
