import {Dispatch} from 'redux';
import {connect} from 'react-redux';
import {generatePath} from 'react-router-dom';
import {selectNftById} from '../../../redux/modules/nfts/selectors';
import {selectCurrentLocale} from '../../../redux/modules/localization/selectors';
import {ROUTES} from '../../../constants/routes';
import {CloseActiveModalCommand} from '../../../redux/modules/modals/actions';
import {PurchaseSuccessModal} from '../../shared/Modals/Purchase/PurchaseSuccessModal';

type ModalProps = {
  nftId: string;
  price: number;
  qty: number;
};

const mapStateToProps = (state: any, {nftId, price, qty}: ModalProps) => {
  const nft = selectNftById(state, nftId);
  const locale = selectCurrentLocale(state);
  return {
    image: nft.image!,
    creatorName: nft.creatorName,
    title: nft.title,
    nftLink: generatePath(ROUTES.NFT_PAGE, {nftId: nftId, locale}),
    nftAddress: nft.address,
    quantity: qty,
    priceUSDC: price,
    transitionDetailsLink: '', // FixMe
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onClose: () => dispatch(CloseActiveModalCommand.create()),
});

export const PurchaseSuccessfulModal = connect(mapStateToProps, mapDispatchToProps)(PurchaseSuccessModal);
