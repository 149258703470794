import {FC, useCallback, useContext} from 'react';
import {Box, CircularProgress, makeStyles, Theme} from '@material-ui/core';
import {Button} from '@cere/rxb-template-ui-kit';
import clsx from 'clsx';
import colors from '../../../../styles/colors';
import {getSegmentClassname} from '../../NftCardBig/helpers';
import {UserContext} from '../../../../context/user-context/user-context';
import {useHistory} from 'react-router-dom';
import {enableMarketplaceView} from '../../../../config/common';
import {GoogleAnalyticsId} from '../../../../analytics-ids';
import {ExhibitContext, ExhibitionEmptyContext} from '../../../../context/exhibition/exhibit-context';
import {NftContext} from '../nft-context';
import {CmsExhibitionNftRelType} from '../../../types/graphql';
import {useLocalization} from '../../../hooks/use-locale.hook';

const useStyles = makeStyles<Theme, {isDarkTheme?: boolean}>((theme) => ({
  buttonLight: {
    background: ({isDarkTheme}) => (isDarkTheme ? `${colors.disable}` : `${colors.lighter} !important`),
    color: ({isDarkTheme}) => (isDarkTheme ? `${colors.disable}` : `${colors.disable} !important`),
    cursor: 'default',
  },
  buttonDarkTheme: {
    background: `${theme.palette.info.main} !important`,
    color: `${theme.palette.primary.main}`,
  },
}));

type Props = {
  buttonText?: string;
  id?: string;
  classname?: string | undefined;
  hideIfPurchased?: boolean;
  isDarkTheme?: boolean;
};

export const NftCtaButton: FC<Props> = ({id, classname, buttonText, hideIfPurchased = false, isDarkTheme = false}) => {
  const classes = useStyles({isDarkTheme});
  const {locale} = useLocalization();
  const {setIsExhibitFirstOpen} = useContext(UserContext);
  const {eventNft, button, attributes, relType} = useContext(NftContext);
  const {symbol} = useContext(ExhibitContext);

  const defaultText = relType === CmsExhibitionNftRelType.AUCTIONED ? 'Bid' : 'Buy';

  const isExhibitionPage = symbol !== ExhibitionEmptyContext;

  const segmentClass = getSegmentClassname(classname, attributes.purchased, attributes.pending);
  const history = useHistory();

  const gotoNftPage = useCallback(() => {
    if (eventNft) {
      history.push(`/${locale}/home/nft/${eventNft?.cmsNft.id}`);
    }
  }, [eventNft, history, locale]);

  const handleClick = useCallback(
    (event: MouseEvent) => {
      event.stopPropagation();
      setIsExhibitFirstOpen(false);

      if (
        (attributes.offerEnded || attributes.availableOnlyOnMarketplace) &&
        isExhibitionPage &&
        attributes.auctionSettled
      ) {
        return gotoNftPage();
      }

      if (attributes.purchased && isExhibitionPage) {
        return button.onClick();
      }

      return isExhibitionPage && attributes.purchased && enableMarketplaceView() ? gotoNftPage() : button.onClick();
    },
    [
      attributes.availableOnlyOnMarketplace,
      attributes.offerEnded,
      attributes.purchased,
      attributes.auctionSettled,
      button,
      gotoNftPage,
      isExhibitionPage,
      setIsExhibitFirstOpen,
    ],
  );

  const checkIfPurchasedAndMarketplaceDisabled = () => {
    return hideIfPurchased && attributes.purchased && !enableMarketplaceView();
  };

  const checkIfOfferEndedAndMarketplaceDisabled = () => {
    return hideIfPurchased && !enableMarketplaceView() && attributes.offerEnded;
  };

  if (checkIfPurchasedAndMarketplaceDisabled() || checkIfOfferEndedAndMarketplaceDisabled()) {
    return null;
  }

  return (
    <Button
      fullWidth
      variant={isExhibitionPage && attributes.purchased ? 'outlined' : 'contained'}
      size="large"
      onClick={handleClick}
      className={clsx(
        isDarkTheme && !button.disabled && classes.buttonDarkTheme,
        button.isLightButton && classes.buttonLight,
        segmentClass,
        attributes.purchased && GoogleAnalyticsId.ViewNftBtn,
      )}
      disabled={button.disabled}
      disableRipple={button.isLightButton}
      id={id}
    >
      {button.loading ? (
        <Box display="flex">
          <CircularProgress size={22} thickness={2} color="inherit" />
        </Box>
      ) : (
        button.label || buttonText || defaultText
      )}
    </Button>
  );
};
