import {alpha, Box, makeStyles} from '@material-ui/core';
import {SyntheticEvent, useMemo} from 'react';
import dayjs from 'dayjs';
import {Typography} from '@cere/rxb-template-ui-kit';
import clsx from 'clsx';
import {EventCounter} from '../EventCounter';
import {AuctionMoreInfo} from '../AuctionMoreInfo';
import {BidInfo} from './BidInfo';
import {GoogleAnalyticsId} from '../../../../analytics-ids';
import CardDetailNavigation from 'shared/components/CardDetailNavigation';
import {OverlayDetailCardProps} from './types';
import {AuctionCardLargeBase} from 'shared/components/AuctionCardLarge/auctionCardLargeBase';
import {getNftRelTypeTitle} from '../../../helpers/get-nft-rel-type-title.helper';
import {NftActionButton} from 'shared/components/ActionButtons';

const useStyles = makeStyles((theme) => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  bid: {
    color: '#717178',
    marginLeft: '5px',
  },
  button: {
    background: theme.palette.info.main,
    maxWidth: '140px',
    minWidth: '140px',
    maxHeight: '36px',
    color: theme.palette.common.white,
    border: 'none',

    [theme.breakpoints.up('md')]: {
      maxWidth: 'unset',
      '& span': {
        padding: '0 8px',
      },
    },

    '&:hover': {
      background: alpha(theme.palette.info.main, 0.6),
    },
    '&.Mui-disabled': {
      background: alpha(theme.palette.info.main, 0.3),
    },
  },
  grey: {
    color: '#717178',
  },
  root: {
    overflow: 'auto',
    position: 'relative',
    flex: '0 0 auto',
  },
  rotateLeft: {
    transform: 'rotate(90deg)',
  },
  rotateRight: {
    transform: 'rotate(270deg)',
  },
  arrow: {
    background: 'rgba(224, 224, 231, 0.5)',
    cursor: 'pointer',
  },
  inactiveArrow: {
    opacity: '50%',
    cursor: 'not-allowed',
  },
  cardNavWrapper: {
    position: 'absolute',
    right: '16px',
    top: '18px',
  },
  subtitle: {
    display: 'block',
    marginTop: '5px',
  },
  nftType: {
    marginRight: 'auto',
    backgroundColor: '#7F38F3',
    borderRadius: '4px',
    padding: '1px 7px',
    fontWeight: 700,
  },
}));

export const OverlayDetailCardLandscape = ({
  title,
  subtitle,
  description,
  description1,
  price,
  imgSrc,
  disabled = false,
  isBadge,
  selectedIndex,
  totalLength,
  bids = [],
  isAuction,
  isOpen,
  endsAt,
  handlePrevClick,
  handleNextClick,
  onVisualClick,
  hideNavigation,
  relType,
  hidePrice,
}: OverlayDetailCardProps) => {
  const classes = useStyles();

  const isCounterVisible = useMemo(
    () => !disabled && isAuction && dayjs().isBefore(dayjs(endsAt)),
    [disabled, endsAt, isAuction],
  );

  return (
    <Box onClick={(event: SyntheticEvent) => event.stopPropagation()}>
      <AuctionCardLargeBase
        onVisualClick={onVisualClick}
        title={title}
        imgSrc={imgSrc}
        className={classes.root}
        contentClassName={classes.content}
        contentBottom={isAuction ? <AuctionMoreInfo bids={bids} isOpen={isOpen} isHighestBid={isBadge} /> : null}
        mediaContent={isCounterVisible ? <EventCounter date={endsAt} /> : null}
      >
        <Typography variant="caption1" className={classes.nftType}>
          {relType && getNftRelTypeTitle(relType)}
        </Typography>
        <Typography variant="caption1" className={classes.subtitle}>
          {subtitle}
        </Typography>
        {!hideNavigation && (
          <CardDetailNavigation
            selectedIndex={selectedIndex}
            totalLength={totalLength}
            handleNextClick={handleNextClick}
            handlePrevClick={handlePrevClick}
            wrapperClassName={classes.cardNavWrapper}
          />
        )}
        <Box>
          <Box mt="5px" mb="11px">
            <Typography variant="body2" className={classes.grey}>
              {description}
            </Typography>
          </Box>

          <Box mb="35px">
            <Typography variant="body2">{description1}</Typography>
          </Box>

          <Box display="flex" justifyContent="space-between">
            <BidInfo
              hidePrice={hidePrice}
              isAuction={isAuction}
              price={price}
              bids={bids}
              containerProps={{maxWidth: '130px', width: '100%', flex: '0 1 130px'}}
            />

            <Box ml="20px">
              <NftActionButton
                className={clsx(classes.button, isAuction ? GoogleAnalyticsId.BidBtn : GoogleAnalyticsId.BuyDropBtn)}
              />
            </Box>
          </Box>
        </Box>
      </AuctionCardLargeBase>
    </Box>
  );
};
