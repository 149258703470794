import {SyntheticEvent, useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {useQuery} from '@apollo/client';
import {isEmpty} from 'lodash';
import {Box, Theme, useMediaQuery} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import {Dialog, Typography} from '@cere/rxb-template-ui-kit';
import clsx from 'clsx';
import {Carousel} from '../../../../shared/components/auction/Carousel';
import BootstrapLoader from '../../../../shared/components/bootstrap-loader';
import AboutExhibitDialog from '../../../../shared/components/auction/AboutExhibitDialog';
import {AuctionContext} from './context/auction-context';
import {OverlayNav} from '../../../../shared/components/auction/OverlayNav';
import {AuctionOverlay} from './Overlay/auction-overlay';
import {CmsExhibitionNft, ExchangeRate, isCmsExhibitionNftReady} from '../../../../shared/types/graphql';
import {CERE_EXCHANGE_RATE} from '../../../../shared/queries';
import analyticService, {AnalyticEventsEnum} from '../../../../shared/services/analytic.service';
import {AuctionsContext} from '../../../../context/auctions-context';
import {useViewportHeight} from '../../../../shared/hooks/inner-height.hook';
import {MarkersAndPurchase} from './markers-and-purchase';
import {PlayerContextProvider} from '../../../../shared/components/Player/player.context';
import {useSingletonPollNftStatus} from '../../../../shared/hooks/nft-status-poll.hook';
import {getIsPaymentPending} from '../../../../shared/helpers/paymentStatus';
import {UserContext} from 'context/user-context/user-context';
import {Condition, ConditionsList} from '../../../../shared/components/Conditions';
import {isAuctionedNft, isLimitedNft} from '../../../../shared/helpers/auction';
import {ExhibitContext} from '../../../../context/exhibition/exhibit-context';
import {Numberish} from '../../../../shared/types/numberish';
import {usePurchaseById} from '../../../../context/purchase-nft-context';
import {useLocalization} from '../../../../shared/hooks/use-locale.hook';
import {NextExhibitOverlay} from './Overlay/nextExhibits-overlay';

interface InterstitialExhibitionPageProps {
  goBack: () => void;
  setShowTokenAllocation: (show: boolean) => void;
}

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: '0 0 auto',
    background: '#f8f8fa',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    alignItems: 'center',
  },
  videoWrapper: {
    '& .video-js': {
      width: '100% !important',
      height: (props: {height: number}) => `${props.height}px !important`,

      '& .vjs-dock-text': {
        display: 'none',
      },
    },
  },
  videoHidden: {
    '& .vjs-control-bar': {
      display: 'none',
    },
  },
  progressWrapper: {
    position: 'absolute',
    top: '60px',
    width: '100%',
    padding: '0 14px',
    display: 'flex',
    zIndex: 10,
  },
});

export const WebInterstitialExhibitionPage = ({goBack}: InterstitialExhibitionPageProps) => {
  const {t} = useLocalization();
  const height = useViewportHeight();
  const {event, exclusiveNfts} = useContext(ExhibitContext);
  const classes = useStyles({height});
  const {data: exchangeRate} = useQuery<{creatorExchangeRate: ExchangeRate}>(CERE_EXCHANGE_RATE);

  const {auctionNfts: auctionPerNfts} = useContext(AuctionsContext);
  const {connectNonCustodialWallet} = useContext(UserContext);
  const {startNFTPurchaseById} = usePurchaseById();

  const {setSelectedNftIndex, setNfts, nfts} = useContext(AuctionContext);

  const [showAbout, setShowAbout] = useState(false);
  const [showWallet, setShowWallet] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);
  const [showNextExhibits, setShowNextExhibits] = useState(false);
  const [showLimitedWallet, setShowLimitedWallet] = useState(false);

  const [videoProgress, setVideoProgress] = useState<number>(0);
  const [videoCurrentTime, setVideoCurrentTime] = useState<number>(0);
  const [selectedVideo, setSelectedVideo] = useState<number>(0);
  const [showMore, setShowMore] = useState(false);

  const onToggleLimitedWallet = useCallback(() => {
    analyticService.track(AnalyticEventsEnum.LIMITED_WALLET_CLOSE_BUTTON_CLICKED);

    setShowLimitedWallet((show) => !show);
  }, []);

  const onToggleAbout = () => {
    analyticService.track(AnalyticEventsEnum.EXHIBIT_PLAYER_ABOUT_CLOSED);
    setShowAbout((show) => !show);
  };

  const onToggleWallet = () => {
    analyticService.track(AnalyticEventsEnum.AUCTION_WALLET_CLOSE_BUTTON_CLICKED);

    setShowWallet((show) => !show);
  };

  const onToggleShowMore = () => {
    setShowMore((show) => !show);
  };

  const onClickBack = useCallback(() => {
    if (showWallet) {
      return onToggleWallet();
    } else if (showLimitedWallet) {
      return onToggleLimitedWallet();
    } else if (showMore) {
      return onToggleShowMore();
    } else if (showOverlay) {
      return setShowOverlay(false);
    } else {
      goBack();
    }
  }, [showWallet, showLimitedWallet, showMore, showOverlay, onToggleLimitedWallet, goBack]);

  const onShopExhibitClick = () => {
    analyticService.track(AnalyticEventsEnum.EXHIBIT_PLAYER_ABOUT_BROWSE_NFTS_CLICKED);

    setShowOverlay(() => true);
    setShowAbout(() => false);
  };

  const onBidExhibitClick = (nft: CmsExhibitionNft, index: number, isAuction: boolean) => {
    setShowAbout(() => false);
    if (isAuction) {
      setSelectedNftIndex(index);
      onPlaceBid(nft.cmsNft.id);
    } else {
      handleBuyNft(nft);
    }
  };

  const onPlaceBid = useCallback(
    async (nftId: Numberish) => {
      analyticService.track(AnalyticEventsEnum.AUCTION_NFT_CARD_BID_CLICKED, {
        nftId,
      });
      await connectNonCustodialWallet();

      setShowWallet(() => true);
    },
    [connectNonCustodialWallet],
  );

  const handleBuyNft = useCallback(
    async (nft: CmsExhibitionNft) => {
      analyticService.track(AnalyticEventsEnum.AUCTION_NFT_CARD_BUY_CLICKED);
      await connectNonCustodialWallet();
      const {cmsNft} = nft;

      return startNFTPurchaseById(cmsNft.id.toString(), 1);
    },
    [connectNonCustodialWallet, startNFTPurchaseById],
  );

  const setNextAsset = () => {
    let currentVideoIndex = 0;
    setSelectedVideo((i) => {
      currentVideoIndex = i;
      return i;
    });

    if (currentVideoIndex + 1 < (event?.assets?.length ?? 0)) {
      setVideoProgress(0);
      setSelectedVideo((i) => i + 1);
    } else {
      setShowOverlay(true);
      setShowNextExhibits(true);
    }
  };

  const onBrowseNftsClick = useCallback(() => {
    analyticService.track(AnalyticEventsEnum.EXHIBIT_PLAYER_BROWSE_NFTS_CLICKED);

    setShowOverlay((state) => !state);
  }, []);

  const onPlayerPause = () => {
    analyticService.track(AnalyticEventsEnum.EXHIBIT_PLAYER_PAUSE_CLICKED);
  };

  const onVolumeChange = () => {
    analyticService.track(AnalyticEventsEnum.EXHIBIT_PLAYER_VOLUME_CHANGED);
  };

  const onFullscreenChange = () => {
    analyticService.track(AnalyticEventsEnum.EXHIBIT_PLAYER_FULLSCREEN_CHANGED);
  };

  const showMarker = useCallback(() => {
    setShowOverlay(true);
    setShowMore(true);
  }, []);

  const onPlayerTimeUpdate = useCallback((currentTime: number) => {
    setVideoCurrentTime(currentTime);
  }, []);

  useEffect(() => {
    setNfts([...exclusiveNfts.filter(isAuctionedNft), ...exclusiveNfts.filter(isLimitedNft)]);
  }, [exclusiveNfts, setNfts]);

  const {setPollingEnabled, setNftIds, statusesByNftId, newTokenPurchased, setNewTokenPurchased} =
    useSingletonPollNftStatus();
  const {refetch: refetchEventWithNfts} = useContext(ExhibitContext);

  useEffect(() => {
    setNftIds(nfts.filter(isCmsExhibitionNftReady).map((nft) => nft.cmsNft?.creatorNft.nft_id));
  }, [nfts, setNftIds]);

  useEffect(() => {
    if (Object.values(statusesByNftId).some((paymentStatus) => getIsPaymentPending(paymentStatus))) {
      setPollingEnabled(true);
    } else {
      setPollingEnabled(false);
    }
  }, [statusesByNftId, setPollingEnabled]);

  useEffect(() => {
    if (newTokenPurchased) {
      void refetchEventWithNfts();
      setNewTokenPurchased(false);
    }
  }, [newTokenPurchased, refetchEventWithNfts, setNewTokenPurchased]);

  useEffect(() => {
    return () => {
      setNftIds([]);
      setPollingEnabled(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isMobile = useMediaQuery<Theme>((theme) => `${theme.breakpoints.down('sm')}`);
  const {isConnectWalletVisible} = useContext(UserContext);

  const onCloseOverlay = useCallback(() => {
    setShowOverlay(false);
  }, []);

  const play = useMemo(
    () =>
      !showOverlay && !showAbout && !showWallet && !showLimitedWallet && !isConnectWalletVisible && !showNextExhibits,
    [showOverlay, showAbout, showWallet, showLimitedWallet, isConnectWalletVisible, showNextExhibits],
  );

  if (!event?.cmsCreator || !event || !exchangeRate)
    return (
      <Box style={{overflowY: 'auto', padding: '0', height: `${height}px`, display: 'flex', justifyContent: 'center'}}>
        <BootstrapLoader color="black" />
      </Box>
    );

  const handleOverlayClick = (e: SyntheticEvent) => {
    e.stopPropagation();

    if (showMore) {
      setShowMore(false);
    } else if (showAbout) {
      setShowAbout(false);
    } else if (showOverlay) {
      onCloseOverlay();
    }
  };

  return (
    <PlayerContextProvider>
      <Box style={{overflowY: 'auto', padding: '0'}}>
        <Box className={classes.container}>
          <Box
            onClick={handleOverlayClick}
            display="flex"
            flexDirection="column"
            justifyContent="flex-start"
            width="100%"
            height={`${height}px`}
            overflow="hidden"
            className={clsx(classes.videoWrapper, showOverlay && classes.videoHidden)}
          >
            {!isEmpty(event.assets) && (
              <Carousel
                asset={event.assets[selectedVideo]}
                progress={videoProgress}
                onPlayerTimeUpdate={onPlayerTimeUpdate}
                onPlayback={setVideoProgress}
                play={play}
                setNextAsset={setNextAsset}
                onPlayerPause={onPlayerPause}
                onVolumeChange={onVolumeChange}
                onFullscreenChange={onFullscreenChange}
              />
            )}

            <OverlayNav
              about={showAbout}
              setAbout={setShowAbout}
              onClickBack={onClickBack}
              showOverlay={showOverlay}
              selectedVideo={selectedVideo}
              videoProgress={videoProgress}
            />

            <ConditionsList>
              <Condition condition={!showOverlay}>
                <MarkersAndPurchase
                  showMarker={showMarker}
                  currentTime={videoCurrentTime}
                  onClick={onBrowseNftsClick}
                />
              </Condition>

              <Condition condition={showNextExhibits}>
                <NextExhibitOverlay
                  showMore={showMore}
                  setShowMore={setShowMore}
                  setShowOverlay={() => setShowOverlay(false)}
                  onClick={() => setShowNextExhibits(false)}
                />
              </Condition>

              <Condition condition={showOverlay && !showNextExhibits}>
                <AuctionOverlay
                  onPlaceBid={onPlaceBid}
                  onBuy={handleBuyNft}
                  showMore={showMore}
                  setShowMore={setShowMore}
                />
              </Condition>
            </ConditionsList>
          </Box>
        </Box>

        <Dialog
          open={showAbout}
          onClose={onToggleAbout}
          headerContent={<Typography variant="h4">{t('About exhibit')}</Typography>}
          dark
          desktop={!isMobile}
          aboutExhibit
        >
          <AboutExhibitDialog
            artist={event.cmsCreator}
            event={event}
            onButtonClick={onBidExhibitClick}
            onOverlayClick={onShopExhibitClick}
            auctionPerNfts={auctionPerNfts}
          />
        </Dialog>
      </Box>
    </PlayerContextProvider>
  );
};
