import {FC, ReactElement} from 'react';
import {Box, makeStyles, Typography} from '@material-ui/core';
import {useLocalization} from '../../shared/hooks/use-locale.hook';
import {ReactComponent as CereWalletIcon} from '../../assets/icons/cere-wallet.svg';
import colors from '../../styles/colors';
import {webAppUrl} from '../../config/common';

export const useStyles = makeStyles((theme) => ({
  base: {
    border: `1px solid ${colors.lighter}`,
    borderRadius: '20px',
    padding: '12px 18px',
    marginBottom: '14px',
    textAlign: 'center',
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.grey[200],
    '& svg': {
      minWidth: '48px',
      minHeight: '48px',
    },
    '& p': {
      fontSize: '12px',
      fontWeight: '600',
      color: colors.primaryDark,
    },
    [theme.breakpoints.up('md')]: {
      padding: '12px',
    },
  },
  link: {
    color: colors.lightGrey,
    margin: '8px',
  },
  box: {
    backgroundColor: theme.palette.background.paper,
    paddingTop: '12px',
    marginBottom: '12px',
    [theme.breakpoints.up('md')]: {
      margin: '0 42px 12px 42px',
    },
    [theme.breakpoints.up('lg')]: {
      margin: '0 20px 12px 20px',
    },
  },
  text: {
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '20px',
  },
  br: {
    [theme.breakpoints.down('lg')]: {
      display: 'none',
    },
  },
}));

export const SignInKeyConstructInfo: FC<{}> = (): ReactElement => {
  const {t} = useLocalization();
  const classes = useStyles();

  return (
    <Box className={classes.base}>
      <Box className={classes.box}>
        <CereWalletIcon />
        <Typography className={classes.link}>
          {t('Constructing your key on ')}
          <a href={webAppUrl()}>
            <u>{webAppUrl()?.replace('https://', '')}</u>
          </a>
        </Typography>
      </Box>
      <Typography className={classes.text}>
        {t('After sign up we secure your account and ')}
        <br className={classes.br} />
        {t('you will be redirected to the app within seconds')}
      </Typography>
    </Box>
  );
};
