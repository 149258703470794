import {CollapsibleCard} from '../CollapsibleCard';
import {CreatorAuctionBidWithStatus} from 'shared/types/graphql';
import BidHistory from 'pages/NftPage/nft-bid-history';
import {useLocalization} from '../../hooks/use-locale.hook';

export interface NFTBidsHistoryProps {
  bids: CreatorAuctionBidWithStatus[];
}

export const NFTBidHistory = ({bids}: NFTBidsHistoryProps) => {
  const {t} = useLocalization();

  return (
    <CollapsibleCard title={t('Bid history')}>
      {bids?.length ? <BidHistory bids={bids} isLightTheme /> : <p>{t('Be the first to place a bid')}</p>}
    </CollapsibleCard>
  );
};
