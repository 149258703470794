import {Button} from '@material-ui/core';
import clsx from 'clsx';

import BootstrapLoader from '../bootstrap-loader';

export interface NFTCTAButtonProps {
  id?: string;
  text: string;
  loading?: boolean;
  type?: 'button' | 'reset' | 'submit';
  disabled?: boolean;
  className?: string;
  onClick?: () => void;
}

export const NFTCTAButton = ({id, className, text, loading, onClick, disabled, type = 'button'}: NFTCTAButtonProps) => {
  return (
    <Button
      id={id}
      color="primary"
      variant="contained"
      className={clsx(className)}
      onClick={onClick}
      type={type}
      disabled={loading || disabled}
    >
      {loading ? <BootstrapLoader /> : text}
    </Button>
  );
};
