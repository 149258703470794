import {Box, makeStyles, Typography} from '@material-ui/core';
import clsx from 'clsx';

import colors from '../../../../styles/colors';
import UpcomingTicketsImage from '../../../../assets/auction/aboutUpcomingAuction.png';
import {ReactComponent as Hammer} from '../../../../assets/icons/hammer.svg';
import {ReactComponent as ShoppingCart} from '../../../../assets/icons/shoppingCart.svg';
import {ReactComponent as View} from '../../../../assets/icons/view.svg';
import {useLocalization} from '../../../hooks/use-locale.hook';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: '-38px',
    marginBottom: '58px',

    [theme.breakpoints.up('md')]: {
      display: 'flex',
      alignItems: 'center',
      marginTop: '-81px',
      marginBottom: '70px',
    },

    [theme.breakpoints.up('lg')]: {
      marginTop: '-96px',
    },
  },

  interstitialContainer: {
    marginTop: '30px',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      alignItems: 'center',
    },
    [theme.breakpoints.up('lg')]: {
      marginTop: 'unset',
    },
  },
  title: {
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '20px',
    lineHeight: '26px',
    color: colors.primaryDark,
    marginBottom: '8px',

    [theme.breakpoints.up('md')]: {
      fontWeight: 800,
      fontSize: '28px',
      lineHeight: '36px',
      marginBottom: '4px',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '40px',
      lineHeight: '48px',
      marginBottom: '24px',
    },
  },

  subTitle: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '22px',
    color: colors.primaryDark,
    marginBottom: '4px',

    [theme.breakpoints.up('md')]: {
      marginBottom: '8px',
    },

    [theme.breakpoints.up('lg')]: {
      fontWeight: 700,
      fontSize: '20px',
      lineHeight: '26px',
      marginBottom: '10px',
    },
  },
  text: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '21px',
    color: colors.lightGrey,
  },
  item: {
    display: 'flex',
    marginTop: '20px',
  },
  content: {
    display: 'inline',
    marginLeft: '20px',

    [theme.breakpoints.up('md')]: {
      marginLeft: '16px',
    },

    [theme.breakpoints.up('lg')]: {
      marginLeft: '32px',
    },
  },
  iconContainer: {
    width: '36px',
    height: '36px',
    minWidth: '36px',
    background: 'rgba(127, 56, 243, 0.07)',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.up('lg')]: {
      width: '60px',
      height: '60px',
    },
  },
  viewIcon: {
    '& svg': {
      width: '19px',
      height: '12px',
    },
    [theme.breakpoints.up('lg')]: {
      '& svg': {
        width: '31px',
        height: '20px',
      },
    },
  },
  hammerIcon: {
    '& svg': {
      width: '17px',
      height: '17px',
    },
    [theme.breakpoints.up('lg')]: {
      '& svg': {
        width: '29px',
        height: '29px',
      },
    },
  },
  cartIcon: {
    '& svg': {
      width: '14px',
      height: '13px',

      '& path': {
        fill: theme.palette.secondary.main,
      },
    },
    [theme.breakpoints.up('lg')]: {
      '& svg': {
        width: '23px',
        height: '21px',
      },
    },
  },
  infoContent: {
    order: 2,
    [theme.breakpoints.up('md')]: {
      order: 1,
    },
  },
  imageContent: {
    order: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 'calc(100vw - 40px)',
    height: 'calc((100vw - 40px)*1.26)',
    marginBottom: '24px',

    [theme.breakpoints.up('md')]: {
      order: 2,
      width: '245px',
      height: '340px',
      marginLeft: '48px',
      backgroundSize: '110%',
      marginBottom: 'unset',
    },

    [theme.breakpoints.up('lg')]: {
      order: 2,
      width: '40vw',
      height: '52vw',
    },
  },
  image: {
    width: '100%',
  },
}));

type Props = {
  isInterstitialPage?: boolean;
  previewImageUrl?: string;
};

export const AboutUpcomingAuctionInfo = ({isInterstitialPage, previewImageUrl}: Props) => {
  const {t} = useLocalization();
  const classes = useStyles();
  return (
    <Box className={isInterstitialPage ? classes.interstitialContainer : classes.container}>
      <Box className={classes.imageContent}>
        <img className={classes.image} src={previewImageUrl || UpcomingTicketsImage} alt="" />
      </Box>

      <Box className={classes.infoContent}>
        <Typography className={classes.title}>{t('About the upcoming live auction')}</Typography>
        <Box className={classes.item}>
          <Box className={clsx(classes.iconContainer, classes.viewIcon)}>
            <View />
          </Box>
          <Box className={classes.content}>
            <Typography className={classes.subTitle}>{t('View unseen video')}</Typography>
            <Typography className={classes.text}>
              {t('Сontent from the Creator, only visible to NFT ticket holders.')}
            </Typography>
          </Box>
        </Box>

        <Box className={classes.item}>
          <Box className={clsx(classes.iconContainer, classes.hammerIcon)}>
            <Hammer />
          </Box>
          <Box className={classes.content}>
            <Typography className={classes.subTitle}>{t('Auction NFTs to bid')}</Typography>
            <Typography className={classes.text}>
              {t('Unique NFTs that can be purchased with the winning auction bid')}
            </Typography>
          </Box>
        </Box>

        <Box className={classes.item}>
          <Box className={clsx(classes.iconContainer, classes.cartIcon)}>
            <ShoppingCart />
          </Box>
          <Box className={classes.content}>
            <Typography className={classes.subTitle}>{t('Limited NFTs to buy')}</Typography>
            <Typography className={classes.text}>{t('Limited items available for direct purchase')}</Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
