import {useContext, useEffect} from 'react';
import {Box, makeStyles} from '@material-ui/core';
import {Typography, Modal} from '@cere/rxb-template-ui-kit';
import {ReactComponent as Success} from '../../../assets/icons/success.svg';
import colors from '../../../styles/colors';
import {IKeys} from '../../services/auth.service';
import {UserContext} from '../../../context/user-context/user-context';
import {NftCardMiniature} from '../NftCardMiniature';
import {VerifyOTP} from '../auth/VerifyOTP';
import {useLocalization} from '../../hooks/use-locale.hook';

const useStyles = makeStyles(() => ({
  badgeText: {
    color: colors.lightGrey,
    marginLeft: '7px',
  },
  badgeIcon: {
    height: '13px',
    width: '13px',
  },
  grey: {
    color: colors.lightGrey,
  },
}));

interface VerifyParams {
  open: boolean;
  onVerify: (value: IKeys) => void;
  onClose: Function;
  imgSrc: string;
  title: string;
  email?: string;
}

export const Verify = ({open, onVerify, onClose, imgSrc, title, email}: VerifyParams) => {
  const classes = useStyles();
  const {t} = useLocalization();
  const {userData, socialUserData} = useContext(UserContext);

  useEffect(() => {
    return () => localStorage.removeItem('socialAuthData');
  }, []);

  return (
    <Modal open={open} onClose={onClose} maxWidth="xs" title={t('Success, verify it’s you')}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        height="55px"
        justifyContent="space-between"
        mb="16px"
      >
        <Success />
        <Typography variant="h4">{t('Payment successful')}</Typography>
      </Box>

      <NftCardMiniature
        imgSrc={imgSrc}
        title={title}
        content={
          <Box display="flex" alignItems="center" padding="3px 0">
            <Success className={classes.badgeIcon} />
            <Typography variant="h5" className={classes.badgeText}>
              {t('Your NFT')}
            </Typography>
          </Box>
        }
      />

      <Box mt="8px" mb="24px">
        <Typography variant="body2" className={classes.grey}>
          {t('Please enter the verification code sent to your email to complete verification for your NFTs.')}
        </Typography>
      </Box>
      <VerifyOTP onVerify={onVerify} email={socialUserData?.email || userData.userEmail || email || ''} />
    </Modal>
  );
};
