import {useState} from 'react';
import {Box} from '@material-ui/core';
import {Modal} from '@cere/rxb-template-ui-kit';
import {GoogleAnalyticsId} from '../../../analytics-ids';
import {NftTicketCardSelect} from './NftTicketSelect';
import {AccessNftInfoModal} from '../AccessNftInfoModal';
import {NftTicketCardBase} from './NftTicketCardBase';
import {NftCtaButton} from '../Nft/components/NftCtaButton';
import {useGetTopCardsSorted} from '../../../pages/ExhibitPage/routes/WebInterstitialHomePage/ExhibitPageTopCards/use-get-top-cards-sorted';
import {isInterstitialPageList} from '../../../config/common';
import {NftProvider} from '../Nft/nft-provider';
import {useLocalization} from '../../hooks/use-locale.hook';

export const NftTicketCardEvent = () => {
  const {t} = useLocalization();
  const nfts = useGetTopCardsSorted(isInterstitialPageList());
  const [selectedNft, setSelectedNft] = useState(nfts[0]);
  const [showModal, setShowModal] = useState(false);

  return (
    <NftProvider nft={selectedNft}>
      <NftTicketCardBase
        handleClickLearn={() => setShowModal(true)}
        buttonContent={
          <Box mt="12px">
            <NftCtaButton buttonText={t('Buy')} classname={GoogleAnalyticsId.BuyAccessBtn} />
          </Box>
        }
        selectContent={
          <Box mt="4px">
            <NftTicketCardSelect nfts={nfts} onSelect={setSelectedNft} />
          </Box>
        }
        justifyContentInfo="flex-end"
      />

      <Modal open={showModal} onClose={() => setShowModal(false)} title={t('NFT tickets')} maxWidth="xs">
        <AccessNftInfoModal handleButtonClick={() => setShowModal(false)} />
      </Modal>
    </NftProvider>
  );
};
