import {Field} from 'react-final-form';
import {Box, FormControl, Paper, TextField, Typography} from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import {AnyObject} from 'final-form';
import {useCallback} from 'react';
import {useListingPriceStyles} from './styles';

const INPUT_LABEL_PROPS = {
  shrink: true,
};

export interface ListingBlockProps {
  styles: ReturnType<typeof useListingPriceStyles>;
  isProcessing: boolean;
  hasValidationErrors: boolean;
  errors?: AnyObject;
}

export const FixedPriceBlock = ({styles, isProcessing, hasValidationErrors, errors}: ListingBlockProps) => {
  const valueValidator = useCallback((value: number) => {
    if (!value || value < 1) {
      return 'Min price has to be more than 1';
    }

    return null;
  }, []);

  return (
    <>
      <Box className={styles.fieldsContainer}>
        <Field name="quantity">
          {({input}) => (
            <FormControl variant="outlined" disabled={isProcessing}>
              <TextField
                className={styles.input}
                type="number"
                variant="outlined"
                label="Quantity"
                {...input}
                disabled
                InputLabelProps={INPUT_LABEL_PROPS}
              />
            </FormControl>
          )}
        </Field>
        <Field name="currency">
          {({input}) => (
            <TextField
              className={styles.input}
              variant="outlined"
              label="Currency"
              {...input}
              disabled
              InputLabelProps={INPUT_LABEL_PROPS}
            />
          )}
        </Field>
        <Field name="price" validate={valueValidator}>
          {({input}) => (
            <FormControl variant="outlined" disabled={isProcessing}>
              <TextField
                className={styles.input}
                type="number"
                variant="outlined"
                label="Price"
                {...input}
                disabled={isProcessing}
                InputLabelProps={INPUT_LABEL_PROPS}
              />
            </FormControl>
          )}
        </Field>
      </Box>
      {hasValidationErrors && (
        <Paper className={styles.errorMessage} elevation={0}>
          <ErrorIcon fontSize="small" />
          <Typography className={styles.errorText}>{errors?.price}</Typography>
        </Paper>
      )}
    </>
  );
};
