import {PurchasedNftsStore} from './types';
import {PURCHASE_HISTORY_MODULE_NAME} from './constants';
import {NoSuchModuleError} from '../../base/NoSuchModuleError';
import {PurchasedNft} from '../../../api/purchase-history/types';

export const selectPurchasedNft = (store: PurchasedNftsStore, nftId: string): PurchasedNft | undefined => {
  if (!store[PURCHASE_HISTORY_MODULE_NAME]) {
    throw new NoSuchModuleError(PURCHASE_HISTORY_MODULE_NAME);
  }
  return store[PURCHASE_HISTORY_MODULE_NAME][nftId];
};
