import {AppWallet, SupportedWallet} from '../shared/types/supported-wallet';
import {useContext} from 'react';
import {UserContext} from './user-context/user-context';
import {compareKeys} from '../shared/lib/compare-keys';

export const useWallet = (publicKey?: string): SupportedWallet | undefined => {
  const {userData, nonCustodyWallets} = useContext(UserContext);
  if (!publicKey) {
    return undefined;
  }
  if (compareKeys(userData.userPublicKey ?? '', publicKey)) {
    return AppWallet.DAVINCI;
  }
  return nonCustodyWallets.find((wallet) => compareKeys(wallet.publicKey, publicKey))?.type;
};
