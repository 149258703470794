import {SocialUserData} from './types';

export const getInitialSocialData = () => {
  try {
    return JSON.parse(localStorage.getItem('socialAuthData') || '');
  } catch {
    return undefined;
  }
};

export const storeSocialData = (socialAuthData: SocialUserData): void => {
  if (!socialAuthData) {
    localStorage.removeItem('socialAuthData');
  } else {
    try {
      localStorage.setItem('socialAuthData', JSON.stringify(socialAuthData));
    } catch (e) {}
  }
};
