import {useMediaQuery, useTheme} from '@material-ui/core';
import {BID_STATUSES} from '../../types/graphql';
import {Typography} from '@cere/rxb-template-ui-kit';
import {ReactComponent as SuccessBid} from '../../../assets/icons/success.svg';
import {ReactComponent as PendingBid} from '../../../assets/hourglass.svg';
import {Condition, ConditionsList} from '../Conditions';
import {makeStyles} from '@material-ui/core';
import colors from '../../../styles/colors';
import {useLocalization} from '../../hooks/use-locale.hook';

interface BidHistoryStatusProps {
  bidStatus: BID_STATUSES;
}

const useStyles = makeStyles((theme) => ({
  pending: {
    height: '20px',
    width: '24px',

    '& path': {
      fill: colors.blue,
    },
  },
  success: {
    height: '14px',

    '& path': {
      fill: theme.palette.info.main,
    },
  },
}));

export const BidHistoryStatus = ({bidStatus}: BidHistoryStatusProps) => {
  const {t} = useLocalization();
  const theme = useTheme();
  const classes = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const bidStatusText = bidStatus === BID_STATUSES.PENDING ? t('Bid transaction processing') : t('Bid placed');

  return (
    <>
      {bidStatus === BID_STATUSES.PENDING ? (
        <PendingBid className={classes.pending} />
      ) : (
        <SuccessBid className={classes.success} />
      )}
      <ConditionsList multiply>
        <Condition condition={!isMobile}>
          <Typography>{bidStatusText}</Typography>
        </Condition>
      </ConditionsList>
    </>
  );
};
