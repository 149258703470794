import {ReactElement, ReactNode, useContext} from 'react';
import {Grid} from '@material-ui/core';
import {makeStyles, Theme} from '@material-ui/core/styles';
import {NftContext} from '../../../../../shared/components/Nft/nft-context';

type Props = {
  children: ReactNode;
};

const useStyles = makeStyles<Theme, {order: number}>(() => ({
  root: {
    order: ({order}) => order,
  },
}));

export function NftOrderCard({children}: Props): ReactElement {
  const {attributes} = useContext(NftContext);
  const styles = useStyles({order: attributes.order});
  return (
    <Grid className={styles.root} item xs={12} sm={6} lg={4}>
      {children}
    </Grid>
  );
}
