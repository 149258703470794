import {ReactElement, useCallback, useContext, useEffect, useRef, useState} from 'react';
import {Button, Typography} from '@cere/rxb-template-ui-kit';
import {Box} from '@material-ui/core';
import {useQuery} from '@apollo/client';
import clsx from 'clsx';
import {AuctionContext} from './context/auction-context';
import {isAuctionedNft} from '../../../../shared/helpers/auction';
import {AuctionsContext} from '../../../../context/auctions-context';
import {getNextBidAmount} from '../../../../shared/services/auction.service';
import {cereToUSD, formatPrice} from '../../../../shared/lib/formatPrice';
import {ExchangeRate} from '../../../../shared/types/graphql';
import {CERE_EXCHANGE_RATE} from '../../../../shared/queries';
import {getFirstInAssetsImage} from '../../../../shared/services/asset.service';
import {usePlayer} from '../../../../shared/components/Player/player.context';
import {useStyles} from './styles';
import {GoogleAnalyticsId} from '../../../../analytics-ids';
import {ExhibitContext} from '../../../../context/exhibition/exhibit-context';
import {getNFTTotalOfferedQty} from '../../../../shared/helpers/nfts';
import {Numberish} from '../../../../shared/types/numberish';
import {useLocalization} from '../../../../shared/hooks/use-locale.hook';

type Props = {
  onClick: () => void;
  currentTime: number;
  showMarker: () => void;
};

type MarkerNft = {
  index: number;
  cmsNftIncrementId: Numberish;
  title: string;
  description: string;
  price: string;
  image?: string;
};

export function MarkersAndPurchase({onClick, currentTime, showMarker}: Props): ReactElement {
  const classes = useStyles();
  const {t} = useLocalization();
  const {setSelectedNftIndex} = useContext(AuctionContext);
  const {exclusiveNfts} = useContext(ExhibitContext);
  const [intervals, setIntervals] = useState<Set<[number, number]>>(new Set());
  const [currentMarker, setCurrentMarker] = useState<MarkerNft | undefined>();
  const markerNfts = useRef<WeakMap<[number, number], MarkerNft>>(new WeakMap());
  const {auctionNfts: auctionPerNfts} = useContext(AuctionsContext);
  const {data: exchangeRate} = useQuery<{creatorExchangeRate: ExchangeRate}>(CERE_EXCHANGE_RATE);
  const {player} = usePlayer();

  const markerClickHandler = useCallback(
    (event) => {
      const index = event.currentTarget.getAttribute('data-index');
      setSelectedNftIndex(Number(index) ?? 0);
      showMarker();
      player?.pause();
    },
    [player, setSelectedNftIndex, showMarker],
  );

  useEffect(() => {
    if (!exchangeRate) {
      return;
    }
    exclusiveNfts.forEach((nft, index) => {
      if (!nft.cmsNft?.creatorNft) {
        return;
      }
      const offersCount = auctionPerNfts[nft.cmsNft?.creatorNft?.nft_id]?.creator_auction_bids?.length ?? 0;
      const description = (() => {
        if (isAuctionedNft(nft)) {
          return offersCount > 0 ? t('Highest bid') : t('Starting price');
        }
        return t('{{leftAmount}}/{{totalAmount}} left', {
          leftAmount: getNFTTotalOfferedQty(nft.cmsNft),
          totalAmount: nft.cmsNft?.creatorNft.supply,
        });
      })();
      const amount = isAuctionedNft(nft)
        ? getNextBidAmount(
            auctionPerNfts[nft.cmsNft?.creatorNft.nft_id],
            exchangeRate.creatorExchangeRate.cere_units_per_penny,
          )
        : cereToUSD(
            nft.cmsNft?.creatorNft?.creator_make_offer?.[0]?.price ?? 0,
            exchangeRate.creatorExchangeRate.cere_units_per_penny,
          );
      const markerNft: MarkerNft = {
        index,
        cmsNftIncrementId: nft.cmsNft.id,
        title: nft.cmsNft.title,
        description,
        image: getFirstInAssetsImage(nft.cmsNft.assets),
        price: formatPrice(amount),
      };
      nft.videoMarkers.forEach((marker) => {
        const [startMinutes = 0, startSeconds = 0] = marker.markerStartTime?.split(':') || [];
        const [endMinutes = 0, endSeconds = 0] = marker.markerEndTime?.split(':') || [];
        const normalizedMarker: [number, number] = [
          Number(startMinutes) * 60 + Number(startSeconds),
          Number(endMinutes) * 60 + Number(endSeconds),
        ];
        markerNfts.current.set(normalizedMarker, markerNft);
        setIntervals((state) => new Set(state).add(normalizedMarker));
      });
    });
  }, [auctionPerNfts, exchangeRate, exclusiveNfts, t]);

  useEffect(() => {
    const interval = Array.from(intervals.values())
      .filter((item) => {
        const [start, end] = item;
        return currentTime >= start && currentTime <= end;
      })
      .shift();
    const markerNft = interval && markerNfts.current.get(interval);
    setCurrentMarker(markerNft);
  }, [currentTime, intervals]);

  return (
    <Box className={classes.root}>
      <Button
        color="primary"
        variant="contained"
        className={clsx(classes.button, GoogleAnalyticsId.ExhibitBidBtn)}
        onClick={onClick}
      >
        {t('Purchase exclusive NFTs')}
      </Button>
      {currentMarker && (
        <div data-index={currentMarker.index} className={classes.marker} onClick={markerClickHandler}>
          <Box className={classes.markerImage}>
            {currentMarker.image && <img alt={currentMarker.title} src={currentMarker.image} />}
          </Box>
          <Box>
            <Typography variant="h3">{currentMarker.title}</Typography>
            <Typography className={classes.markerText} variant="body1">
              {currentMarker.description}
            </Typography>
          </Box>
          <Typography className={classes.markerPrice} variant="body1">
            {currentMarker.price}
          </Typography>
        </div>
      )}
    </Box>
  );
}
