import {CreatorAuction} from './creator-auction';
import {Numberish} from '../numberish';
import {isRecord} from '../is-record';
import {CmsNft} from './cms-nft';

export interface CreatorWalletNft {
  wallet: string;
  quantity: number;
  nft_id: {
    nft_id: string;
    cmsNft: CmsNft;
  };
}

export type CreatorNft = {
  id: Numberish;
  minter: string;
  collection_address: string;
  nft_id: string;
  supply: number;
  creator_take_offer: Array<{
    amount: number;
    price: number;
    buyer: string;
  }>;
  creator_wallet_nfts: CreatorWalletNft[];
  creator_make_offer: Array<{price: number; seller: string; published_at: string}>;
  creator_auctions: [CreatorAuction];
};

export type CreatorNftWithWallets = CreatorNft & {
  creator_wallet_nfts: CreatorWalletNft[];
};

export type CreatorNftWithAuctions = {
  id: string;
  is_settled: boolean;
  creator_auctions: CreatorAuction[];
};

export const isCreatorNftWithBalance = (value: unknown): value is CreatorNftWithBalance =>
  isRecord(value) &&
  value.nftId !== undefined &&
  typeof value.nftId === 'string' &&
  value.minter !== undefined &&
  typeof value.minter === 'string' &&
  value.supply !== undefined &&
  typeof value.supply === 'number' &&
  value.quantity !== undefined &&
  typeof value.quantity === 'number';

export type CreatorNftWithBalance = {
  nftId: string;
  minter: string;
  supply: number;
  quantity: number;
  priceInUsdCents: number;
  priceInCereUnits: number;
  collectionAddress?: string;
  balance?: number;
};
