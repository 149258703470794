import {Box} from '@material-ui/core';
import {Counter, Typography} from '@cere/rxb-template-ui-kit';
import clsx from 'clsx';

import {isEventStarted} from 'shared/lib/CompareDates';
import {useLocalization} from 'shared/hooks/use-locale.hook';
import {CmsExhibition} from 'shared/types/graphql';
import {ExhibitionTeaserType} from 'shared/types/exhibition-teaser';

import {ExhibitTeaser} from '../Exhibition-preview-container/exhibit-teaser';
import {useStyles} from '../Exhibition-preview-container/live-styles';
import {typoColor} from '../styles';
import {SoldOut} from './sold-out';

type Props = {
  event: CmsExhibition;
};

export const AccessSoldOut = ({event}: Props) => {
  const shouldShowTeaser = Boolean(event.teaser?.content && !event.allowFreeAccess);
  const styles = useStyles({});
  const {t} = useLocalization();

  return (
    <Box className={styles.soldOutExhibitionContainer}>
      <Box display="flex" flexDirection="column">
        <Box className={styles.soldOutExhibitionCounter}>
          <Typography style={typoColor} variant="caption1">
            {isEventStarted(event.endsAt) ? t('Exhibit ends in') : t('Exhibit starts in')}
          </Typography>
          <Counter
            withSec
            withCellParameters
            startsAt={isEventStarted(event.endsAt) ? new Date(event.endsAt) : new Date(event.startsAt)}
          />
        </Box>

        <Box className={clsx(styles.exhibitionTitlesSoldOut, styles.notStartedTitles)}>
          <Typography className={styles.eventSubtitle} variant="h5">
            {event?.subtitle}
          </Typography>
          <Box className={styles.exhibitionTitleWithoutTheme}>{event?.title}</Box>
        </Box>
        <SoldOut />
      </Box>
      {shouldShowTeaser && (
        <Box mt="40px" mb="16px" display="flex" justifyContent="center">
          <Box className={styles.exhibitionTeaserContainer}>
            <ExhibitTeaser type={ExhibitionTeaserType.REGULAR} />
          </Box>
        </Box>
      )}
    </Box>
  );
};
