import {alpha, Box, makeStyles, Theme} from '@material-ui/core';
import clsx from 'clsx';
import {isVideoUrl} from 'shared/lib/checkIsImageUrl';
import colors from 'styles/colors';

const useBaseStyles = makeStyles<Theme, {isHovered: boolean}>((theme) => ({
  wrapper: {
    backgroundImage: ({isHovered}) =>
      isHovered
        ? `linear-gradient(to top, ${colors.primaryDark} 0%, ${alpha(colors.primaryDark, 0.7)} 100%)`
        : `linear-gradient(to top, ${colors.primaryDark} 0%, ${alpha(colors.primaryDark, 0)} 100%)`,
    position: 'relative',
    height: '100%',
    width: '100%',
  },
  images: {
    minWidth: '43%',
    display: ({isHovered}) => (!isHovered ? 'none' : 'block'),
    [theme.breakpoints.down('lg')]: {
      display: 'none',
    },
  },
  image1: {
    height: '201px',
    width: '201px',
    objectFit: 'cover',
    position: 'absolute',
    zIndex: 7,
    top: '137px',
  },
  image2: {
    height: '255px',
    width: '255px',
    objectFit: 'cover',
    position: 'absolute',
    zIndex: 5,
    top: '30px',
    left: '82px',
  },
  image3: {
    height: '233px',
    width: '233px',
    objectFit: 'cover',
    position: 'absolute',
    zIndex: 7,
    top: '137px',
    left: '271px',
  },
}));

interface BannerImagesBlockProps {
  isHovered: boolean;
  url1?: string;
  url2?: string;
  url3?: string;
}

export const BannerImagesBlock = ({isHovered, url1, url2, url3}: BannerImagesBlockProps) => {
  const baseClasses = useBaseStyles({isHovered});
  return (
    <Box className={clsx(baseClasses.images, baseClasses.wrapper)}>
      {url1 &&
        (isVideoUrl(url1) ? (
          <video className={baseClasses.image1} src={url1} />
        ) : (
          <img className={baseClasses.image1} src={url1} alt="img1" />
        ))}

      {url2 &&
        (isVideoUrl(url2) ? (
          <video className={baseClasses.image2} src={url2} />
        ) : (
          <img className={baseClasses.image2} src={url2} alt="img2" />
        ))}

      {url3 &&
        (isVideoUrl(url3) ? (
          <video className={baseClasses.image3} src={url3} />
        ) : (
          <img className={baseClasses.image3} src={url3} alt="img3" />
        ))}
    </Box>
  );
};
