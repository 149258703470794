import {ReactElement, useCallback, useEffect, useState} from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import {Cancel} from '@material-ui/icons';

import {useStyles} from './styles';
import {NftItem} from './nft-item';
import {CreatorWalletNft} from '../../shared/types/graphql/creator-wallet-nfts';
import {Transition} from '../../shared/components/transition';
import {useLocalization} from '../../shared/hooks/use-locale.hook';

type Props = {
  value: Set<string>;
  nfts: CreatorWalletNft[];
  open: boolean;
  multiple?: boolean;
  title?: string;
  description?: string;
  selectButtonText?: string;
  onClose: () => unknown;
  onChange: (selection: Set<string>) => unknown;
};

export function NftSelector({
  nfts,
  open,
  value: selection,
  description,
  multiple = true,
  title,
  selectButtonText,
  onChange,
  onClose,
}: Props): ReactElement {
  const {t} = useLocalization();
  const classes = useStyles();
  const theme = useTheme();
  const nftSelectFullPage = useMediaQuery(theme.breakpoints.down('lg'));
  const [selects, setSelects] = useState(selection);

  useEffect(() => {
    setSelects(selection);
  }, [selection]);

  const onToggle = useCallback(
    (nftId: string) => {
      if (!multiple) {
        setSelects(new Set([nftId]));
        return;
      }

      const newSelection = new Set(selects);
      if (newSelection.has(nftId)) {
        newSelection.delete(nftId);
      } else {
        newSelection.add(nftId);
      }
      setSelects(newSelection);
    },
    [multiple, selects],
  );

  const onCloseSelect = useCallback(() => {
    onChange(selects);
    onClose();
  }, [onClose, onChange, selects]);

  const onCloseDialog = useCallback(() => {
    setSelects(selection);
    onChange(selection);
    onClose();
  }, [onClose, onChange, selection]);

  return (
    <Dialog
      classes={{paperFullScreen: classes.fullScreen, root: classes.selectNfts}}
      onClose={onCloseDialog}
      open={open}
      fullWidth
      maxWidth="md"
      TransitionComponent={Transition}
      fullScreen={nftSelectFullPage}
    >
      <DialogTitle classes={{root: classes.selectTitle}}>
        {title || t('NFT selection')}
        <button type="button" onClick={onCloseDialog} className="close">
          <Cancel />
        </button>
      </DialogTitle>
      <DialogContent>
        {description && <Box marginBottom={2}>{description}</Box>}
        <div className={classes.selectContent}>
          {nfts.map((nft) => (
            <NftItem onChange={onToggle} selected={selects.has(nft.nft_id.nft_id)} key={nft.id} nft={nft} />
          ))}
        </div>
      </DialogContent>
      <DialogActions classes={{root: classes.selectActions}}>
        <span>{t('{{count}} selected', {count: selects.size})}</span>
        <Button
          classes={{contained: classes.selectButton, root: classes.selectButtonRoot}}
          disableElevation
          variant="contained"
          onClick={onCloseSelect}
        >
          {selectButtonText || t('Select')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
