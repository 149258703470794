import {useMemo} from 'react';
import {Typography, Box} from '@material-ui/core';
import {random} from 'lodash';

import {useHomePageNfts} from 'api/hooks/use-home-page-nfts';
import {usePendingTransactionsContext} from '../../../context/pending-transaction-context';
import {useUserHasEventAccess} from '../../../pages/ExhibitPage/use-user-has-event-access';
import {useLocalization} from '../../hooks/use-locale.hook';
import {NftProvider2} from '../Nft/nft-provider-v2';
import {checkIsDisabledNft} from '../Nft/utils';
import {NftAdditionsSkeleton} from '../Skeletons';

import {NFTAdditionsCard} from './NftAdditionsCard';
import {useAdditionsBlockStyles} from './styles';

export const NFTAdditions = () => {
  const {t} = useLocalization();
  const styles = useAdditionsBlockStyles();

  const {getPendingTransactionForNft} = usePendingTransactionsContext();
  const userHasEventAccess = useUserHasEventAccess();
  const {nfts, loading: nftsLoading} = useHomePageNfts();

  const notDisabledNfts = useMemo(
    () =>
      (nfts ?? []).filter((nft) => {
        const pendingTransaction = getPendingTransactionForNft(nft.address);

        return (
          !!nft.image &&
          !checkIsDisabledNft({
            nft,
            userHasEventAccess,
            hasUserOffer: true,
            pendingTransaction,
          })
        );
      }),
    [getPendingTransactionForNft, nfts, userHasEventAccess],
  );

  const additionsNfts = useMemo(() => {
    if (notDisabledNfts.length === 0) {
      return [];
    }

    if (notDisabledNfts.length < 3) {
      return notDisabledNfts;
    }

    return new Array(3)
      .fill(null)
      .map(() => random(0, notDisabledNfts.length - 1))
      .map((index) => notDisabledNfts[index]);
  }, [notDisabledNfts]);

  // Since additionsNfts contains duplicate keys,
  // it is necessary to generate unique keys in order to avoid rendering problems.
  const additionsNftsWithKeys = useMemo(() => {
    return additionsNfts.map((nft) => ({nft, key: Math.random().toString()}));
  }, [additionsNfts]);

  if (nftsLoading) {
    return <NftAdditionsSkeleton />;
  }

  return (
    <Box>
      <Typography className={styles.title}> {t('More collectibles')}</Typography>
      <Box className={styles.additionsBox}>
        {additionsNftsWithKeys.map(({nft, key}) => (
          <Box key={key} className={styles.additionsItem}>
            <NftProvider2 nft={nft}>
              <NFTAdditionsCard />
            </NftProvider2>
          </Box>
        ))}
      </Box>
    </Box>
  );
};
