import clsx from 'clsx';
import {Dispatch} from 'redux';
import {connect} from 'react-redux';
import {makeStyles} from '@material-ui/core';
import colors from '../../../../styles/colors';
import {useLocalization} from '../../../utils/hooks/use-localization';
import {Button} from '../../primitives/Button';
import {StartSecondaryNFTPurchaseCommand} from '../../../redux/modules/purchase/actions';

const useStyles = makeStyles((theme) => ({
  default: {
    background: `linear-gradient(277.13deg, ${colors.buttonSecondary} 11.98%, ${theme.palette.secondary.main} 83.77%)`,
    color: colors.light,
  },
  green: {
    color: colors.green,
    background: colors.snowWhite,
    border: `1px solid ${colors.green}`,
    fontSize: '12px',
    lineHeight: '20px',
  },
}));

export interface BuyOnMarketplaceButtonProps {
  type?: 'default' | 'green';
  className?: string;
  text?: string;
  disabled?: boolean;
  onClick: () => void;
}

const PlainBuyOnMarketplaceButton = ({
  type = 'default',
  className,
  text,
  disabled,
  onClick,
}: BuyOnMarketplaceButtonProps) => {
  const {t} = useLocalization();
  const styles = useStyles()[type];

  return (
    <Button className={clsx(className, styles)} onClick={onClick} disabled={disabled}>
      {text || t('Buy')}
    </Button>
  );
};

type ButtonProps = {
  nftId: string;
  sellerWalletAddress: string;
  price: number;
};

const mapDispatchToProps = (dispatch: Dispatch, {nftId, sellerWalletAddress, price}: ButtonProps) => ({
  onClick: () => dispatch(StartSecondaryNFTPurchaseCommand.create(nftId, sellerWalletAddress, price)),
});

export const BuyOnMarketplaceButton = connect(() => ({}), mapDispatchToProps)(PlainBuyOnMarketplaceButton);
