import {memo} from 'react';
import {Avatar, Box, Typography, makeStyles} from '@material-ui/core';
import clsx from 'clsx';

import {ReactComponent as VerifiedAuthor} from './verifiedAuthor.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    width: '22px',
    height: '22px',
    marginRight: '3px',
    fontSize: '12px',
    backgroundColor: theme.palette.secondary.light,
  },
  name: {
    maxWidth: '90%',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '22px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: theme.palette.text.secondary,
  },
  verifiedBadge: {
    minWidth: '12px',
    maxWidth: '12px',
    marginLeft: '6px',
  },
}));

type Props = {
  name: string;
  image?: string;
  isVerified?: boolean;
  classes?: Partial<Record<'root' | 'avatar' | 'name' | 'verifiedBadge', string>>;
};

export const AvatarWithName = memo(({name, image, isVerified, classes}: Props) => {
  const styles = useStyles();

  const initials = name
    .split(' ')
    .slice(0, 2)
    .map((text) => text[0])
    .join('');

  return (
    <Box className={clsx(styles.root, classes?.root)}>
      {!image ? (
        <Avatar className={clsx(styles.avatar, classes?.avatar)}>{initials}</Avatar>
      ) : (
        <Avatar className={clsx(styles.avatar, classes?.avatar)} src={image} alt={name} />
      )}
      <Typography className={clsx(styles.name, classes?.name)}>{name}</Typography>
      {isVerified && <VerifiedAuthor className={clsx(styles.verifiedBadge, classes?.verifiedBadge)} />}
    </Box>
  );
});
