import {useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import {useMediaQuery, useTheme, Box} from '@material-ui/core';
import {useQuery} from '@apollo/client';
import {isEqual, noop} from 'lodash';

import {GoogleAnalyticsId} from 'analytics-ids';
import {useFeaturedNfts} from 'api/hooks/use-featured-nfts';
import {useHomePageExhibits} from 'api/hooks/use-home-page-featured-exhibits';
import {useHomePageNfts} from 'api/hooks/use-home-page-nfts';
import {useHomePageCreators} from 'api/hooks/use-home-page-creators';
import {AVAILABLE_LANGUAGES} from 'config/common';
import {AppContext} from 'context/app-context';
import {FiltersParams} from 'context/filters-context';
import {PageLayout} from 'shared/components/PageLayout';
import {PageContainer} from 'shared/components/PageContainer';
import {Condition, ConditionsList} from 'shared/components/Conditions';
import {Title, SubTitle} from 'shared/components/Title';
import {NftProvider2} from 'shared/components/Nft/nft-provider-v2';
import {NFTFilteredGrid} from 'shared/components/NFTFilteredGrid';
import {AppMetaTags} from 'shared/components/SeoHeaders/seo-headers.component';
import {
  HomePageBannerSkeleton,
  NftsListSkeleton,
  ExhibitsRowSkeleton,
  CreatorsRowSkeleton,
  ValuePropositionSkeleton,
  NftCollectiblesSceleton,
} from 'shared/components/Skeletons';
import {usePageLoaded} from '../../shared/services/loading.service';
import analyticService, {AnalyticEventsEnum} from 'shared/services/analytic.service';
import {useOtpVerification} from 'shared/hooks/use-otp-verification.hook';
import {useLocalization} from 'shared/hooks/use-locale.hook';
import {Numberish} from 'shared/types/numberish';

import {HomePageBanner} from './HomePageBanner';
import {ENTITIES_TYPES, HomeBannerEntity} from './HomePageBanner/types';
import {HomePageContext} from './home-page-context';
import {HomePageCreatorsRow} from './home-page-creators-row';
import {HomePageCuratedRow} from './home-page-curated-row-v2';
import {HomePageExhibitsRow} from './home-page-exhibits-row';
import {CMS_HOMES} from './homes-queries';
import {NftCardV3} from './NftCard/nft-card-v3';
import {ValueProposition} from './ValueProposition';
import {GetStarted} from './GetStarted';
import {CmsHome, Exhibition} from './types';

export const HomePage = () => {
  usePageLoaded();
  useOtpVerification();
  const theme = useTheme();
  const {nfts, updateSearchParams, loading} = useHomePageNfts();
  const {featuredNfts} = useFeaturedNfts();
  const {creators} = useHomePageCreators();
  const {exhibits} = useHomePageExhibits();
  const {appConfig} = useContext(AppContext);
  const {t, locale} = useLocalization();

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isTablet = useMediaQuery(theme.breakpoints.between('md', 'lg'));

  const nftIdToEventMap = useRef(new Map<Numberish, Exhibition>());

  const findExhibition = useCallback(
    (nftCmsNftId): Exhibition | undefined => nftIdToEventMap.current.get(nftCmsNftId),
    [],
  );

  const context = useMemo(() => ({findExhibition}), [findExhibition]);

  const [entities, setEntities] = useState<HomeBannerEntity[]>([]);

  const {
    data: homeData,
    loading: homeLoading,
    error: homeError,
  } = useQuery<{homes: CmsHome[]}, {limit: number; locale: string}>(CMS_HOMES, {variables: {locale, limit: 1}});

  const someQueryError = homeError;
  const pageLoading = homeLoading;

  if (someQueryError) {
    throw someQueryError;
  }

  const trackNFTCollectableClick = useCallback(
    (nftId: Numberish) => analyticService.track(AnalyticEventsEnum.NFT_COLLECTABLE_CLICKED, {nftId}),
    [],
  );

  useEffect(() => {
    if (homeData) {
      const banners = homeData.homes?.[0]?.home_banner_visual || [];
      const updatedEntities = banners.map((banner) => {
        const image = isMobile ? banner.mobile : isTablet ? banner.tablet : banner.desktop;
        const type = banner.cms_exhibit ? ENTITIES_TYPES.EXHIBIT : ENTITIES_TYPES.NFT;

        return {type, value: banner, image: image?.url || ''};
      });

      if (!isEqual(entities, updatedEntities)) {
        setEntities(updatedEntities);
      }
    }
  }, [homeData, entities, isMobile, isTablet, featuredNfts]);

  const onFiltersUpdate = (params: FiltersParams) => {
    updateSearchParams(params);
  };

  const localizations = AVAILABLE_LANGUAGES().map((language) => ({
    locale: language,
    link: `/${language}/home`,
  }));

  return (
    <PageLayout hideBackButton>
      <AppMetaTags
        title={
          homeData?.homes[0]?.seoTitle ||
          t('{{appTitle}} - NFT Experience & Marketplace Platform', {appTitle: appConfig.appTitle})
        }
        description={
          homeData?.homes[0]?.seoDescription ||
          t(
            'Innovative NFT Experience & Marketplace Platform - World-class artists, hyper-personalized NFT experiences, and decentralized ownership',
          )
        }
        canonical={homeData?.homes[0]?.seoCanonical}
        image={homeData?.homes[0]?.seoImage?.url}
        localizations={localizations}
      />
      <HomePageContext.Provider value={context}>
        <PageContainer isHomePage>
          <ConditionsList>
            <Condition condition={!entities || entities.length === 0}>
              <HomePageBannerSkeleton />
            </Condition>
            <Condition condition>
              <HomePageBanner entities={entities} />
            </Condition>
          </ConditionsList>

          <ConditionsList>
            <Condition condition={!featuredNfts}>
              <NftsListSkeleton title={t('Featured listings')} />
            </Condition>
            <Condition condition={!!featuredNfts}>
              <HomePageCuratedRow featuredNfts={featuredNfts} />
            </Condition>
          </ConditionsList>

          <Box>
            <Title>{t('Exhibits')}</Title>
            <SubTitle>
              {t('Hand-curated and unique artist experiences. View, buy, and auction for valuable NFT collectibles')}
            </SubTitle>
          </Box>
          <ConditionsList>
            <Condition condition={!exhibits}>
              <Box mb={3}>
                <ExhibitsRowSkeleton />
              </Box>
            </Condition>
            <Condition condition={!!exhibits}>
              <HomePageExhibitsRow featuredExhibits={exhibits} />
            </Condition>
          </ConditionsList>

          <Title>{t('Creators')}</Title>
          <Box mt="4px" mb="20px" width="75%">
            <SubTitle>
              {t(
                'Verified creators bringing transforming their best work in unique NFT experiences which are exclusively sold on DaVinci.',
              )}
            </SubTitle>
          </Box>
          <ConditionsList>
            <Condition condition={!creators}>
              <CreatorsRowSkeleton />
            </Condition>
            <Condition condition={!!creators}>
              <HomePageCreatorsRow featuredCreators={creators} />
            </Condition>
          </ConditionsList>

          <ConditionsList>
            <Condition condition={pageLoading}>
              <ValuePropositionSkeleton />
            </Condition>
            <Condition condition={!pageLoading}>
              <ValueProposition />
            </Condition>
          </ConditionsList>

          <ConditionsList>
            <Condition condition={!nfts}>
              <NftCollectiblesSceleton />
            </Condition>
            <Condition condition={!!nfts}>
              <NFTFilteredGrid
                title={t('NFT collectibles')}
                subTitle={t('Exclusive and verified digital collectibles which unlock unseen experiences of creators')}
                onLoadMore={noop}
                loading={loading}
                onFiltersUpdate={onFiltersUpdate}
                isNftCollectiblesCard
              >
                {(nfts ?? []).map((nft) => (
                  <NftProvider2 key={nft.id} nft={nft}>
                    <NftCardV3
                      nftId={nft.id}
                      imageUrl={nft.image}
                      nftType={nft.nftType}
                      availableFrom={nft.availableFrom?.toString()}
                      availableTo={nft.availableTo?.toString()}
                      availability={nft.availability}
                      nftAddress={nft.address}
                      nftTitle={nft.title}
                      nftDescription={nft.description}
                      balance={nft.balance}
                      supply={nft.supply}
                      usdPrice={nft.priceUsd}
                      onClick={trackNFTCollectableClick}
                      buttonClassName={GoogleAnalyticsId.BuyCollectiblesBtn}
                      isNftCollectiblesCard
                      isCutTitle
                      withOutShadow
                    />
                  </NftProvider2>
                ))}
              </NFTFilteredGrid>
            </Condition>
          </ConditionsList>

          <GetStarted />
        </PageContainer>
      </HomePageContext.Provider>
    </PageLayout>
  );
};
