import {useCallback} from 'react';
import {useHistory} from 'react-router-dom';
import {alpha, Box, makeStyles} from '@material-ui/core';
import {Swiper, SwiperSlide} from 'swiper/react/swiper-react';
import {Autoplay, Pagination} from 'swiper';
import 'swiper/swiper-bundle.css';

import {SellNftContextProvider} from 'context/sell-nft-context';
import WithPurchaseNft from 'shared/components/WithPurchaseNft/v2';
import analyticService, {AnalyticEventsEnum} from 'shared/services/analytic.service';
import {useLocalization} from 'shared/hooks/use-locale.hook';
import colors from 'styles/colors';

import {ENTITIES_TYPES, HomeBannerEntity} from './types';
import {ExhibitsBanner} from './ExhibitsBanner';
import {NftBanner} from './NftBanner';

interface HomePageBannerProps {
  entities: HomeBannerEntity[];
}

export const useHomePageBannerStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    width: '100%',
    minHeight: '440px',
    maxHeight: '440px',
    marginTop: '24px',
    marginBottom: '64px',
    borderRadius: '24px',
    overflow: 'hidden',

    [theme.breakpoints.down('md')]: {
      minHeight: '375px',
      maxHeight: '375px',
      marginTop: 0,
      width: '100vw',

      marginLeft: '-16px',
      marginRight: '-16px',
      borderRadius: 0,
    },
    [theme.breakpoints.down('lg')]: {
      marginBottom: '36px',
    },
  },
  mainSwiper: {
    height: '100%',
    width: '100%',

    '& .swiper-pagination': {
      display: 'flex',
      justifyContent: 'center',
      height: '2px',
      position: 'absolute',
      zIndex: 10,
      width: '100%',
      padding: '0 40px',
      bottom: '20px',
      pointerEvents: 'none',

      [theme.breakpoints.down('md')]: {
        padding: '0 16px',
        bottom: '16px',
      },

      '& .swiper-pagination-bullet-active': {
        '&::before': {
          width: 0,
        },
        '&::after': {
          width: '100%',
          background: colors.white,
          transition: 'width 3s linear',
        },
      },

      '& .swiper-pagination-bullet-active ~ .swiper-pagination-bullet': {
        '&::before': {
          width: 0,
        },
        '&::after': {
          width: 0,
        },
      },

      '& .swiper-pagination-bullet': {
        position: 'relative',

        '&::before': {
          content: '""',
          position: 'absolute',
          height: '100%',
          left: 0,
          width: 0,
        },

        '&::after': {
          content: '""',
          position: 'absolute',
          height: '100%',
          left: 0,
          width: 0,
        },
      },

      '& .swiper-pagination-bullet:not(.swiper-pagination-bullet-active)': {
        '&::before': {
          background: colors.white,
          width: '100%',
        },
        '&::after': {
          width: 0,
        },
      },
    },
  },
  swiperSlide: {
    maxWidth: '100vw',
    height: '100%',
  },
  paginationCard: {
    flexGrow: 1,
    height: '100%',
    marginRight: '0.5% !important',
    marginLeft: '0 !important',

    background: alpha(colors.white, 0.5),
    pointerEvents: 'none',
    borderRadius: 0,
    opacity: 1,

    '&:(last-child)': {
      marginRight: '0 !important',
    },
  },
}));

export const HomePageBanner = ({entities}: HomePageBannerProps) => {
  const {locale} = useLocalization();
  const history = useHistory();
  const classes = useHomePageBannerStyles();

  const onExhibitLoad = useCallback(
    (slug) => {
      analyticService.track(AnalyticEventsEnum.HOMEPAGE_BANNER_EXHIBIT_CLICKED, {
        slug,
      });
      history.push(`/${locale}/home/exhibit/${slug}`);
    },
    [history, locale],
  );

  const onNftLoad = useCallback(
    (event, id) => {
      event.preventDefault();
      analyticService.track(AnalyticEventsEnum.HOMEPAGE_BANNER_NFT_CLICKED, {
        nftId: id,
      });
      history.push(`/${locale}/home/nft/${id}`);
    },
    [history, locale],
  );

  return (
    <SellNftContextProvider>
      <Box className={classes.root}>
        <Swiper
          className={classes.mainSwiper}
          centeredSlides={true}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: false,
            renderBullet: (_index, className) => `<span class="${className} ${classes.paginationCard}"></span>`,
          }}
          modules={[Autoplay, Pagination]}
        >
          {entities.map((entity, index: number) => (
            <SwiperSlide className={classes.swiperSlide} key={`key=${index}`}>
              <WithPurchaseNft>
                {(props) => (
                  <>
                    {entity.type === ENTITIES_TYPES.NFT && (
                      <NftBanner
                        {...props}
                        image={entity.image}
                        nft={entity.value.cms_nft}
                        onClick={(event) => onNftLoad(event, entity.value.cms_nft.id)}
                      />
                    )}
                    {entity.type === ENTITIES_TYPES.EXHIBIT && (
                      <ExhibitsBanner
                        {...props}
                        image={entity.image}
                        exhibit={entity.value.cms_exhibit}
                        onClick={() => onExhibitLoad(entity.value.cms_exhibit.slug)}
                      />
                    )}
                  </>
                )}
              </WithPurchaseNft>
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
    </SellNftContextProvider>
  );
};
