import {useGetFreeport} from './use-get-freeport';
import {useCallback, useEffect, useState} from 'react';
import {useSelectedWallet} from './use-selected-wallet';
import {MAX_FRACTION_VALUE} from '../shared/lib/formatPrice';

export const useNftRoyalty = (nftId: string) => {
  const getFreeport = useGetFreeport();
  const selectedWallet = useSelectedWallet();
  const [royalties, setRoyalties] = useState<number>(0);

  const getRoyalties = useCallback(
    async (nftId: string) => {
      const freeport = await getFreeport(selectedWallet.wallet);
      const royalties = await freeport.getRoyalties(nftId);
      setRoyalties(royalties.secondaryRoyaltyCut.toNumber() / MAX_FRACTION_VALUE);
    },
    [getFreeport, selectedWallet.wallet],
  );

  useEffect(() => {
    void getRoyalties(nftId);
  }, [nftId, getRoyalties]);

  return royalties;
};
