import {ContractsProvider} from '../blockchain/ContractsProvider';
import {WalletConnectionService} from '../wallet/WalletConnectionService';

export class WalletsBalanceService {
  constructor(
    private readonly contractProvider: ContractsProvider,
    private readonly walletConnectionService: WalletConnectionService,
  ) {}

  async getWalletsBalance(): Promise<{[key: string]: number}> {
    const userWalletsAddresses = this.walletConnectionService.getConnectedWalletsAddresses();
    if (!userWalletsAddresses.length) {
      return {};
    }
    const erc20 = await this.contractProvider.getERC20Contract();
    const balances = await Promise.all(userWalletsAddresses.map((address) => erc20.balanceOf(address)));
    return userWalletsAddresses.reduce((acc, wallet, index) => {
      acc[wallet] = balances[index].toNumber();
      return acc;
    }, {} as {[key: string]: number});
  }
}
