import * as Sentry from '@sentry/react';

import {isRecord} from '../types/is-record';
import {get} from '../lib/request';

import i18n from '../../i18n';
import {CMS_URL} from '../../config/common';

type FindExhibitionResult = {
  id: number;
  slug: string;
};

const isFindExhibitionResult = (value: unknown): value is FindExhibitionResult =>
  isRecord(value) && !isNaN(Number(value.id)) && Number(value.id) > 0;

export function findExhibitionByAttachedNft(nftId: string): Promise<FindExhibitionResult> {
  const url = new URL(CMS_URL());
  url.pathname = 'creator-nfts/cms-exhibitions';
  url.searchParams.set('nft_id', nftId);
  return get(`${url.pathname}${url.search}`, {base: url.origin})
    .catch((error) => {
      Sentry.captureException(error);
      throw error;
    })
    .then((response) => {
      const result = Array.isArray(response) ? response.filter((item) => item?.published_at != null)[0] : null;
      if (isFindExhibitionResult(result)) {
        return result;
      }
      throw Error(i18n.t(`There are no exhibitions with attached {{nftId}} found`, {nftId}));
    });
}

const isPromiseFulfilledResult = <T>(value: PromiseSettledResult<T>): value is PromiseFulfilledResult<T> =>
  value.status === 'fulfilled';

export function findAllExhibitionsByNfts(nftId: string[]): Promise<Map<string, FindExhibitionResult>> {
  return Promise.allSettled(nftId.map((nftId) => Promise.all([nftId, findExhibitionByAttachedNft(nftId)]))).then(
    (results) => {
      const successResults = results.filter(isPromiseFulfilledResult);
      return new Map(successResults.map((result) => result.value));
    },
  );
}
