import {ReactElement, useContext, useCallback} from 'react';

import {ExhibitContext} from 'context/exhibition/exhibit-context';
import {EventTimelineStatus} from 'shared/types/event';
import {getTimelineStatus} from 'pages/Сms/Exhibit/utils';

import {StartedEventPreview} from '../EventStatuses/started-event-preview';
import {FinishedEventPreview} from '../EventStatuses/finished-event-preview';
import {NotStartedEventPreview} from '../EventStatuses/not-started-event-preview';
import {ExhibitionPreviewContainerLive} from './exhibition-preview-container-live';

type Props = {
  localIsLoading: boolean;
  userHasEventAccess: boolean;
  userHasJwtAccess: boolean;
  accessPending: boolean;
  isLiveMode: boolean;
  onPlayVideoClick: () => unknown;
  topCardsContainerRef: HTMLDivElement | null;
};

export function ExhibitionPreview({
  localIsLoading,
  userHasEventAccess,
  userHasJwtAccess,
  accessPending,
  isLiveMode,
  onPlayVideoClick,
  topCardsContainerRef,
}: Props): ReactElement {
  const {event, eventTimelineStatus} = useContext(ExhibitContext);
  const status = getTimelineStatus(event);

  const onExploreNFTsClick = useCallback(() => {
    const scrollHeiht = document.body.scrollTop + (topCardsContainerRef?.getBoundingClientRect()?.top ?? 0);
    window.scrollTo({top: scrollHeiht, behavior: 'smooth'});
  }, [topCardsContainerRef]);

  if (!event) {
    return <></>;
  }

  if (isLiveMode) {
    return (
      <ExhibitionPreviewContainerLive
        accessPending={accessPending}
        localIsLoading={localIsLoading}
        onPlayVideoClick={onPlayVideoClick}
        userHasJwtAccess={userHasJwtAccess}
        onExploreNFTsClick={onExploreNFTsClick}
      />
    );
  }

  if (status === EventTimelineStatus.NOT_STARTED) {
    return (
      <NotStartedEventPreview
        event={event}
        eventTimelineStatus={eventTimelineStatus}
        accessPending={accessPending}
        isLoading={localIsLoading}
        userHasEventAccess={userHasEventAccess}
        onExploreNFTsClick={onExploreNFTsClick}
      />
    );
  }

  if (status === EventTimelineStatus.STARTED) {
    return (
      <StartedEventPreview
        isLoading={localIsLoading}
        accessPending={accessPending}
        userHasEventAccess={userHasEventAccess}
        userHasJwtAccess={userHasJwtAccess}
        onPlayVideoClick={onPlayVideoClick}
      />
    );
  }

  if (status === EventTimelineStatus.FINISHED) {
    return <FinishedEventPreview onPlayVideoClick={onPlayVideoClick} userHasJwtAccess={userHasJwtAccess} />;
  }

  return <></>;
}
