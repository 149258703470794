import {Box, Typography, makeStyles, Button} from '@material-ui/core';
import {useContext, useEffect, useState} from 'react';
import {useLocalization} from '../../hooks/use-locale.hook';
import colors from 'styles/colors';
import {ReactComponent as AttentionIcon} from '../../../assets/icons/fail.svg';
import {AppContext} from '../../../context/app-context';

const useStyles = makeStyles((theme) => ({
  container: {
    background: colors.snowWhite,
    borderRadius: '12px',
    padding: '17px 12px',
    marginTop: '16px',

    [theme.breakpoints.up('md')]: {
      marginTop: 'unset',
    },
    [theme.breakpoints.up('lg')]: {
      padding: '23px 12px',
      marginTop: '38px',
      display: 'flex',
      alignItems: 'center',
    },
  },
  button: {
    height: '28px',
    width: '100%',
    backgroundColor: colors.light,
    borderRadius: '100px',
    marginTop: '10px',
    textTransform: 'unset',

    [theme.breakpoints.up('md')]: {
      height: '34px',
    },
    [theme.breakpoints.up('lg')]: {
      marginTop: 'unset',
      width: '284px',
      display: 'inline',
      marginLeft: 'auto',
    },
  },
  description: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '21px',
  },
  icon: {
    minWidth: '17px',
    minHeight: '17px',
    marginRight: '14px',

    '& circle': {
      fill: '#42A4FF',
    },
  },
}));

export const SellingExplainer = () => {
  const {t} = useLocalization();
  const {appConfig} = useContext(AppContext);
  const styles = useStyles();
  const [open, setOpen] = useState(true);

  const onCloseHandler = () => {
    localStorage.setItem('isSellExplainerNotificationOpen', '');
    setOpen(false);
  };

  useEffect(() => {
    setOpen(Boolean(localStorage.getItem('isSellExplainerNotificationOpen')));
  }, []);

  if (!open) return null;

  return (
    <Box className={styles.container}>
      <Box display="flex">
        <AttentionIcon className={styles.icon} />
        <Typography className={styles.description}>
          {t(
            'You can sell this item on the marketplace and immediately receive funds in your {{walletTitle}} once sold.',
            {walletTitle: appConfig.cereWalletTitle || 'Cere wallet'},
          )}
        </Typography>
      </Box>
      <Button onClick={onCloseHandler} className={styles.button}>
        {t('Got it')}
      </Button>
    </Box>
  );
};
