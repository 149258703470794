import {useCallback, useContext} from 'react';
import {NftTicketBadge2} from '../../shared/components/NftTicketCard/NftTicketBadge2';
import {NFTCard} from './nft-card/nft-card';
import {NftContext} from '../../shared/components/Nft/nft-context-v2';
import {useLocalization} from '../../shared/hooks/use-locale.hook';

export const MarketplaceNFTCard = () => {
  const {t} = useLocalization();
  const {attributes, nft} = useContext(NftContext);

  const actionButtonText = attributes.purchased ? t('Sell') : t('Buy');

  const renderNftBadge = useCallback(() => {
    return nft ? <NftTicketBadge2 nft={nft} isYourNftIconIncluded /> : <></>;
  }, [nft]);

  return (
    <NFTCard
      isSharable
      actionButtonText={actionButtonText}
      renderBadge={renderNftBadge}
      isCutTitle={true}
      isFeaturedListingsCard={true}
    />
  );
};
