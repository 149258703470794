import {MouseEventHandler, ReactElement} from 'react';
import {Typography} from '@cere/rxb-template-ui-kit';
import clsx from 'clsx';
import {ReactComponent as InfoIcon} from '../../../../../assets/info.svg';
import {desktopTitleText, tabletTitleText} from '../styles';
import {Theme, useMediaQuery, makeStyles, Box} from '@material-ui/core';

const useIconStyles = makeStyles((theme) => ({
  icon: {
    cursor: 'pointer',
    width: '17px',
    height: '17px',
    marginLeft: '4px',

    '& path': {
      fill: theme.palette.info.main,
    },
  },
  access: {
    whiteSpace: 'break-spaces',
    svg: {
      cursor: 'pointer',
      width: '17px',
      height: '17px',

      path: {
        fill: `${String(theme.palette.primary.main)}`,
      },
    },
  },
}));

interface TitleStyleProps {
  isDesktop: boolean;
  isMobile: boolean;
}

export const AccessNftSectionTitle = ({
  toggleShowModal,
  infoText = 'NFT tickets',
}: {
  toggleShowModal: MouseEventHandler;
  infoText?: string;
}): ReactElement | null => {
  const isMobile = useMediaQuery<Theme>((theme) => `${theme.breakpoints.down('sm')}`);
  const isDesktop = useMediaQuery<Theme>((theme) => `${theme.breakpoints.up('lg')}`);
  const styles = useIconStyles();

  const getTitleStyle = ({isDesktop, isMobile}: TitleStyleProps) => {
    if (isDesktop) {
      return clsx(styles.access, desktopTitleText);
    }
    if (!isMobile) {
      return clsx(styles.access, tabletTitleText);
    }
    return styles.access;
  };

  return (
    <Box sx={{display: 'flex', alignItems: 'center'}}>
      <Typography
        variant={isMobile ? 'h3' : 'h2'}
        className={getTitleStyle({isDesktop: isDesktop, isMobile: isMobile})}
      >
        {infoText}
        <InfoIcon className={styles.icon} onClick={toggleShowModal} />
      </Typography>
    </Box>
  );
};
