import {useContext} from 'react';
import {noop} from 'lodash';
import {NftCardInterface} from '@cere/services-types/dist/types';
import {Box, makeStyles} from '@material-ui/core';

import {useMarketplaceNfts} from 'api/hooks/use-marketplace-nfts';
import {GoogleAnalyticsId} from 'analytics-ids';
import {AppContext} from 'context/app-context';
import {FiltersParams} from 'context/filters-context';

import {PageLayout} from 'shared/components/PageLayout';
import {PageContainer} from 'shared/components/PageContainer';
import {MainTitle, SubTitle} from 'shared/components/Title';
import {CuratedRow} from 'shared/components/CuratedRow';
import {NftProvider2 as NftProviderV2} from 'shared/components/Nft/nft-provider-v2';
import {NFTFilteredGrid} from 'shared/components/NFTFilteredGrid';
import {Condition, ConditionsList} from 'shared/components/Conditions';
import {NftsListSkeleton, NftCollectiblesSceleton} from 'shared/components/Skeletons';
import {useLocalization} from 'shared/hooks/use-locale.hook';

import {NftCardV3} from '../HomePage/NftCard/nft-card-v3';
import {MarketplaceNFTCard} from './MarketplaceNFTCard';
import {useFeaturedMarketplaceNfts} from './use-featured-marketplace-nfts';

const useStyles = makeStyles((theme) => ({
  header: {
    marginBottom: '40px',
    [theme.breakpoints.up('md')]: {
      marginBottom: '48px',
    },
    [theme.breakpoints.up('lg')]: {
      marginBottom: '64px',
    },
  },
}));

export const Marketplace = () => {
  const {t} = useLocalization();
  const styles = useStyles();
  const {nfts, loading: nftsLoading, updateSearchParams} = useMarketplaceNfts();
  const {featuredNfts, loading: featuredNtsLoading} = useFeaturedMarketplaceNfts();
  const {appConfig} = useContext(AppContext);

  const onFiltersUpdate = (params: FiltersParams) => {
    updateSearchParams(params);
  };

  return (
    <PageLayout hideBackButton>
      <PageContainer>
        <Box className={styles.header}>
          <MainTitle>{t('Marketplace')}</MainTitle>
          <Box mt="4px" mb="20px" width="75%">
            <SubTitle>
              {t(
                'A gas-free marketplace for verified {{appTitle}} NFTs on Polygon. Buy, sell, and auction valuable NFTs that unlock unique NFT experiences.',
                {
                  appTitle: appConfig.appTitle,
                },
              )}
            </SubTitle>
          </Box>
        </Box>
        <ConditionsList>
          <Condition condition={featuredNtsLoading}>
            <NftsListSkeleton title={t('Trending listings')} />
          </Condition>
          <Condition condition>
            <CuratedRow title={t('Trending listings')}>
              {(featuredNfts ?? []).map((nft) => (
                <NftProviderV2 key={nft.id} nft={nft}>
                  <MarketplaceNFTCard />
                </NftProviderV2>
              ))}
            </CuratedRow>
          </Condition>
        </ConditionsList>

        <ConditionsList>
          <Condition condition={!nfts}>
            <NftCollectiblesSceleton />
          </Condition>
          <Condition condition>
            <NFTFilteredGrid
              title={t('NFT collectibles')}
              subTitle={t('Exclusive and verified digital collectibles which unlock unseen experiences of creators')}
              onLoadMore={noop}
              loading={nftsLoading}
              onFiltersUpdate={onFiltersUpdate}
              isNftCollectiblesCard
            >
              {(nfts ?? []).map((nft: NftCardInterface) => {
                return (
                  <NftProviderV2 key={nft.id} nft={nft}>
                    <NftCardV3
                      nftId={nft.id}
                      imageUrl={nft.image}
                      nftType={nft.nftType}
                      availableFrom={nft.availableFrom?.toString()}
                      availableTo={nft.availableTo?.toString()}
                      availability={nft.availability}
                      nftAddress={nft.address}
                      nftTitle={nft.title}
                      nftDescription={nft.description}
                      balance={nft.balance}
                      supply={nft.supply}
                      usdPrice={nft.priceUsd}
                      buttonClassName={GoogleAnalyticsId.BuyCollectiblesBtn}
                      isNftCollectiblesCard
                      isCutTitle
                      withOutShadow
                    />
                  </NftProviderV2>
                );
              })}
            </NFTFilteredGrid>
          </Condition>
        </ConditionsList>
      </PageContainer>
    </PageLayout>
  );
};
