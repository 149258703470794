import {AnyAction, combineReducers} from 'redux';
import {NftCardInterface, FullCreatorInterface} from '@cere/services-types';
import {
  MarketplaceCollectableNftsDocument,
  MarketplaceFeaturedCreatorsDocument,
  MarketplaceFeaturedNftsDocument,
} from './actions';

type LoadingState = {
  featuredNftsLoading: boolean;
  collectableNftsLoading: boolean;
  featuredCreatorsLoading: boolean;
};

const defaultLoadingState: LoadingState = {
  featuredNftsLoading: true,
  collectableNftsLoading: true,
  featuredCreatorsLoading: true,
};

export const marketplacePageLoadingStateReducer = (
  state: LoadingState = defaultLoadingState,
  action: AnyAction,
): LoadingState => {
  switch (action.type) {
    case MarketplaceFeaturedNftsDocument.type:
      return {
        ...state,
        featuredNftsLoading: false,
      };
    case MarketplaceCollectableNftsDocument.type:
      return {
        ...state,
        collectableNftsLoading: false,
      };
    case MarketplaceFeaturedCreatorsDocument.type:
      return {
        ...state,
        featuredCreatorsLoading: false,
      };
    default:
      return state;
  }
};

export const marketplacePageFeaturedNftsReducer = (state: string[] = [], action: AnyAction): string[] => {
  if (action.type === MarketplaceFeaturedNftsDocument.type) {
    return (action.payload as NftCardInterface[]).map((nft) => nft.id);
  }
  return state;
};

export const marketplacePageNftCollectablesReducer = (state: string[] = [], action: AnyAction): string[] => {
  if (action.type === MarketplaceCollectableNftsDocument.type) {
    return (action.payload as NftCardInterface[]).map((nft) => nft.id);
  }
  return state;
};

export const marketplacePageFeaturedCreatorsReducer = (
  state: FullCreatorInterface[] = [],
  action: AnyAction,
): FullCreatorInterface[] => {
  if (action.type === MarketplaceFeaturedCreatorsDocument.type) {
    return action.payload as FullCreatorInterface[];
  }
  return state;
};

export const marketplacePageModuleReducer = combineReducers({
  featuredNfts: marketplacePageFeaturedNftsReducer,
  nftCollectables: marketplacePageNftCollectablesReducer,
  featuredCreators: marketplacePageFeaturedCreatorsReducer,
  loadingState: marketplacePageLoadingStateReducer,
});
