import {AnyAction, MiddlewareAPI} from 'redux';
import {IMarketplaceNftsApi} from '../../../api/marketplace-page/IMarketplaceNftsApi';
import {ICreatorsApi} from '../../../api/creators/ICreatorsApi';
import {
  LoadMarketplaceDataCommand,
  MarketplaceCollectableNftsDocument,
  MarketplaceCollectablesNftsLoadingFailedEvent,
  MarketplaceFeaturedCreatorsDocument,
  MarketplaceFeaturedCreatorsLoadingFailedEvent,
  MarketplaceFeaturedNftsDocument,
  MarketplaceFeaturedNftsLoadingFailedEvent,
} from './actions';
import {selectCurrentLocale} from '../localization/selectors';
import {NftsDocument} from '../nfts/actions';

export const loadMarketplaceDataMiddleware =
  (nftsApi: IMarketplaceNftsApi, creatorsApi: ICreatorsApi) =>
  ({dispatch, getState}: MiddlewareAPI) =>
  (next: Function) =>
  (action: AnyAction) => {
    next(action);

    if (action.type === LoadMarketplaceDataCommand.type) {
      const locale = selectCurrentLocale(getState());
      nftsApi
        .getFeaturedNfts(locale)
        .then((nfts) => {
          dispatch(NftsDocument.create(nfts));
          dispatch(MarketplaceFeaturedNftsDocument.create(nfts));
        })
        .catch((error) => dispatch(MarketplaceFeaturedNftsLoadingFailedEvent.create(error.message)));
      nftsApi
        .getPageNfts({locale})
        .then((nfts) => {
          dispatch(NftsDocument.create(nfts));
          dispatch(MarketplaceCollectableNftsDocument.create(nfts));
        })
        .catch((error) => dispatch(MarketplaceCollectablesNftsLoadingFailedEvent.create(error.message)));
      creatorsApi
        .getFeaturedCreators(locale)
        .then((creators) => dispatch(MarketplaceFeaturedCreatorsDocument.create(creators)))
        .catch((error) => dispatch(MarketplaceFeaturedCreatorsLoadingFailedEvent.create(error.message)));
    }
  };
