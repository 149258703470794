import {FC, MouseEvent, ReactElement, useContext, useMemo} from 'react';
import {Counter} from '@cere/rxb-template-ui-kit';
import {Box, Card, CardMedia, makeStyles, Theme, Typography} from '@material-ui/core';
import useResizeObserver from 'use-resize-observer';
import clsx from 'clsx';
import colors from '../../../styles/colors';
import {ShareIcon} from '../ShareIcon';
import dayjs from 'dayjs';
import {ReactComponent as ShoppingCartIcon} from '../../../assets/icons/shoppingCart.svg';
import {NftContext} from '../Nft/nft-context-v2';
import {Condition, ConditionsList} from '../Conditions';
import {NftLink} from './nft-link';
import {useTranslation} from 'react-i18next';
import {AvailabilityStatus, NftSellingType} from '@cere/services-types';

const useStyles = makeStyles<Theme, {width?: number; order: number; comingSoon: boolean}>((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '12px',
    position: 'relative',
    background: 'transparent',
    boxShadow: 'none',
    maxWidth: '400px',
    margin: '0 auto',
    order: ({order}) => order,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    background: colors.light,

    [theme.breakpoints.up('md')]: {
      minHeight: '176px',
    },
  },
  mediaWrapper: {
    overflow: 'hidden',
    borderRadius: '12px 12px 0 0',
  },
  mediaWrapperCentred: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  badge: {
    position: 'absolute',
    zIndex: 2,
    top: '12px',
    left: '12px',
  },
  share: {
    position: 'absolute',
    top: '12px',
    right: '12px',
    zIndex: 2,
  },
  overlay: {
    position: 'relative',
    filter: 'blur(15px)',
    '&:after': {
      content: '""',
      position: 'absolute',
      width: '100%',
      height: '100%',
      bottom: 0,
      zIndex: 1,
      background: '#505050',
      opacity: 0.5,
    },
  },
  pointer: {
    cursor: 'pointer',
  },
  containerClassName: {
    zIndex: 3,
    position: 'absolute',
    left: '12px',
    top: ({width}) => `${width ? width - 50 : 0}px`,

    background: 'rgba(22, 22, 22, 0.5)',
    backdropFilter: 'blur(22px)',
    borderRadius: '6px',
    padding: '6px',
    '& h3': {
      fontSize: '16px',
      [theme.breakpoints.up('lg')]: {
        fontSize: '18px',
      },
    },
  },
  overlayText: {
    position: 'absolute',
    color: colors.light,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  comingSoonText: {
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '20px',
  },
  overlayTextDate: {
    fontSize: '20px',
    color: colors.light,
    fontWeight: 700,
    lineHeight: '26px',
  },
  badgeContainer: {
    position: 'absolute',
    zIndex: 15,
    left: '12px',
    top: '12px',
    height: '26px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '2px 14px 2px 12px',
    background: 'rgba(22, 22, 22, 0.7)',
    backdropFilter: 'blur(80px)',
    borderRadius: '100px',
  },
  badgeText: {
    paddingLeft: '5px',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '22px',
    color: colors.light,
  },
  shoppingCartIcon: {
    width: '17px',
    height: '16px',
    '& path': {
      fill: '#3CBCAC',
    },
  },
}));

const useMediaStyles = makeStyles(() => ({
  root: {
    width: '100%',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    height: ({width}: {width?: number}) => `${width || 0}px`,
  },
}));

interface NftCardBigBaseProps {
  badge?: ReactElement | null;
  withOverlay?: boolean;
  withShare?: boolean;
  className?: string;
  onShare?: () => unknown;
}

export const NftCardBigBase: FC<NftCardBigBaseProps> = ({
  badge = null,
  withOverlay = false,
  withShare = false,
  children,
  className,
  onShare,
}) => {
  const {t} = useTranslation();
  const {button, attributes, nft} = useContext(NftContext);
  const {ref, width} = useResizeObserver();
  const classes = useStyles({
    width: width,
    order: attributes.order,
    comingSoon: nft.availability === AvailabilityStatus.COMING_SOON,
  });
  const mediaClasses = useMediaStyles({width});
  const startDate = nft?.availableFrom;

  const handleShareClick = (event: MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    return onShare ? onShare() : button.onShare();
  };

  const comingSoonDate: string | null = useMemo(
    () => (startDate && dayjs(startDate).isAfter(dayjs()) ? dayjs(startDate).format('MM.DD.YYYY') : null),
    [startDate],
  );

  const isPrimarySale =
    nft.sellingType === NftSellingType.IS_PRIMARY || nft.sellingType === NftSellingType.BOTH_PRIMARY_AND_SECONDARY;

  return (
    <Card className={clsx(classes.root, className)} ref={ref}>
      <NftLink className={clsx(classes.mediaWrapper, withOverlay && classes.mediaWrapperCentred)}>
        <Box position="relative" overflow="hidden">
          <CardMedia classes={mediaClasses} className={withOverlay ? classes.overlay : ''} image={nft?.image ?? ''} />
          {withOverlay && (
            <Box className={classes.overlayText}>
              <Typography className={classes.comingSoonText}>{t('Coming soon')}</Typography>
              <Box className={classes.overlayTextDate}>{comingSoonDate}</Box>
            </Box>
          )}
        </Box>
        <ConditionsList multiply>
          <Condition condition={Boolean(attributes.counter && !withOverlay && isPrimarySale)}>
            <Counter withSec startsAt={attributes.counter} containerClassName={classes.containerClassName} />
          </Condition>
          <Condition
            condition={
              !badge &&
              nft.balance !== 0 &&
              (nft.sellingType === NftSellingType.IS_SECONDARY ||
                (nft.sellingType === NftSellingType.BOTH_PRIMARY_AND_SECONDARY &&
                  nft?.availability === AvailabilityStatus.OFFER_ENDED))
            }
          >
            <Box className={classes.badgeContainer}>
              <ShoppingCartIcon className={classes.shoppingCartIcon} />
              <span className={classes.badgeText}>{t('Available on marketplace')}</span>
            </Box>
          </Condition>
        </ConditionsList>
      </NftLink>
      {badge && <Box className={classes.badge}>{badge}</Box>}
      {withShare && <ShareIcon onClick={handleShareClick} className={classes.share} />}
      <Box p="12px" className={classes.content}>
        {children}
      </Box>
    </Card>
  );
};
