import {AxiosInstance} from 'axios';
import {IPaymentApi} from './IPaymentApi';
import {PaymentUrlFetchError} from './PaymentUrlFetchError';
import {getPopupPaymentUrl} from './helpers';

export class PaymentRESTApi implements IPaymentApi {
  constructor(private readonly client: AxiosInstance) {}

  async getFiatPaymentUrl(
    buyerEmail: string,
    nftId: string,
    quantity: number,
    eventId: number,
    locale: string,
  ): Promise<string> {
    const {successUrl, cancelUrl} = getPopupPaymentUrl(locale);
    const {data} = await this.client.post('/buy-nft-for-fiat', {
      buyerEmail,
      nftId,
      quantity,
      eventId,
      successUrl,
      cancelUrl,
      locale,
    });
    if (!data?.paymentUrl || typeof data.paymentUrl !== 'string') {
      throw new PaymentUrlFetchError();
    }
    return data.paymentUrl;
  }
}
