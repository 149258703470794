import {useCallback, useContext, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {
  CheckUserHasNftEnum,
  AuctionStatus,
  NftCardInterface,
  AvailabilityStatus,
  NftType,
  NftSellingType,
} from '@cere/services-types';
import {NftContext} from '../Nft/nft-context-v2';
import {SellButton} from './SellButton';
import {BidButton} from './BidButton';
import {BuyFromMinterButton} from './BuyFromMinterButton';
import {BuyOnMarketplaceButton} from './BuyOnMarketplaceButton';
import {DisabledButton} from './DisabledButton';
import {useAuctionFactory} from '../../hooks/use-auction-factory';
import {useSelectedWallet} from '../../../context/use-selected-wallet';
import {useOwnerOffer} from '../../hooks/use-owner-offer';
import {usePendingTransactionsContext} from '../../../context/pending-transaction-context';
import clsx from 'clsx';
import {GoogleAnalyticsId} from '../../../analytics-ids';
import {ExhibitContext, ExhibitionEmptyContext} from '../../../context/exhibition/exhibit-context';

export interface ActionButtonProps {
  nft: NftCardInterface;
  className?: string;
}

export const NftSellButton = ({nft, className}: ActionButtonProps) => {
  const ownerOffer = useOwnerOffer(nft?.address);
  const {getPendingTransactionForNft} = usePendingTransactionsContext();
  return (
    <SellButton
      className={clsx(className, GoogleAnalyticsId.SellNftBtn)}
      nftId={nft.id}
      nftAddress={nft.address}
      nftTitle={nft.title}
      nftImage={nft.image}
      nftUsdPrice={nft.priceUsd}
      nftCollectionAddress={nft.collectionAddress}
      disabled={
        Boolean(ownerOffer.data?.creatorMakeOffers.length || ownerOffer.data?.creatorMakeOffers.length) ||
        Boolean(getPendingTransactionForNft(nft?.address))
      }
      ownedQty={1}
    />
  );
};

export const NftBuyButton = ({nft, className}: ActionButtonProps) => {
  const {getPendingTransactionForNft} = usePendingTransactionsContext();
  const {symbol} = useContext(ExhibitContext);
  const isExhibitionPage = symbol !== ExhibitionEmptyContext;
  const {t} = useTranslation();
  const isDisbledMarketplaceButton = useMemo(
    () => Boolean(getPendingTransactionForNft(nft?.address)) || !nft.price || !nft.balance,
    [nft, getPendingTransactionForNft],
  );

  if (
    nft.sellingType === NftSellingType.IS_PRIMARY ||
    (nft.sellingType === NftSellingType.BOTH_PRIMARY_AND_SECONDARY &&
      nft?.availability !== AvailabilityStatus.OFFER_ENDED)
  ) {
    return (
      <BuyFromMinterButton
        className={clsx(className, GoogleAnalyticsId.BuyNftBtn)}
        nftId={nft.id}
        nftAddress={nft.address}
        nftTitle={nft.title}
        nftImage={nft.image}
        nftRelType={nft.nftType}
        nftAmount={nft.balance!}
        nftMinterAddress={nft.minter}
        nftEventId={nft.exhibitionId ?? ''}
        nftEventSlug={nft.exhibitionSlug ?? ''}
        nftCreatorName={nft.creatorName}
        nftUsdPrice={nft.priceUsd}
        nftCollectionAddress={nft.collectionAddress}
        disabled={Boolean(getPendingTransactionForNft(nft?.address)) || !nft.price}
      />
    );
  }
  return (
    <BuyOnMarketplaceButton
      className={clsx(className, GoogleAnalyticsId.BuyNftBtn)}
      nftId={nft.id}
      nftAddress={nft.address}
      nftTitle={nft.title}
      nftDescription={nft.description}
      nftImage={nft.image}
      nftRelType={nft.nftType}
      nftAmount={nft.balance!}
      sellerWallet={(nft as any).sellerWalletAddress}
      nftEventId={nft.exhibitionId ?? ''}
      nftEventSlug={nft.exhibitionSlug ?? ''}
      nftCreatorId={nft.creatorId}
      nftCreatorName={nft.creatorName}
      nftMinter={nft.minter}
      nftPrice={nft.price}
      nftUsdPrice={nft.priceUsd}
      nftCollectionAddress={nft.collectionAddress}
      disabled={isDisbledMarketplaceButton}
      text={isExhibitionPage && !isDisbledMarketplaceButton ? t('Buy on marketplace') : undefined}
    />
  );
};

export const NftBidButton = ({nft, className}: ActionButtonProps) => {
  const auctionFactory = useAuctionFactory();
  const selectedWallet = useSelectedWallet();

  const auction = useMemo(
    () =>
      auctionFactory.createAuctionFromNftData(
        nft.address,
        nft.sellerWalletAddress || nft.minter,
        selectedWallet?.publicKey ?? '',
        nft.auction!.price || nft.price,
        nft.auction!.bids,
        nft.collectionAddress,
      ),
    [auctionFactory, nft, selectedWallet?.publicKey],
  );

  return (
    <BidButton
      className={clsx(className, GoogleAnalyticsId.BidBtn)}
      auction={auction}
      nftId={nft.id}
      nftAddress={nft.address}
      nftTitle={nft.title}
      nftImage={nft.image ?? ''}
      nftCreatorName={nft.creatorName}
      nftRelType={nft.nftType}
      nftEventId={nft.exhibitionId ?? ''}
      nftEventSlug={nft.exhibitionSlug}
    />
  );
};

export const NftActionButton = ({className, excludeSellButton}: {className?: string; excludeSellButton?: boolean}) => {
  const {nft} = useContext(NftContext);
  const {t} = useTranslation();

  const renderDisabledButton = useCallback(
    (text: string) => <DisabledButton text={text} className={className} />,
    [className],
  );

  const renderActionButton = useCallback((): JSX.Element => {
    if (!excludeSellButton && nft?.purchaseStatus === CheckUserHasNftEnum.USER_HAS_NFT) {
      return <NftSellButton nft={nft} className={className} />;
    }
    if (
      nft?.auction &&
      nft?.auction?.bids?.length > 0 &&
      nft?.auctionStatus === AuctionStatus.ACTIVE &&
      nft.availability === AvailabilityStatus.OFFER_ENDED
    ) {
      return renderDisabledButton(t('Sold out'));
    }
    if (
      nft?.auction &&
      nft?.auctionStatus === AuctionStatus.ACTIVE &&
      nft.availability === AvailabilityStatus.OFFER_ENDED
    ) {
      return renderDisabledButton(t('Offer ended'));
    }
    if (nft?.auction && nft?.auctionStatus === AuctionStatus.ACTIVE) {
      return <NftBidButton nft={nft} className={className} />;
    }
    if (nft?.availability === AvailabilityStatus.COMING_SOON) {
      return renderDisabledButton(t('Coming soon'));
    }

    if (nft?.auctionStatus === AuctionStatus.NOT_STARTED && nft.nftType !== NftType.ACCESS) {
      return renderDisabledButton(t('Coming soon'));
    }
    if (nft?.availability === AvailabilityStatus.SOLD_OUT || !nft.balance) {
      return renderDisabledButton(t('Sold out'));
    }
    if (nft?.availability === AvailabilityStatus.OFFER_ENDED && nft.sellingType !== NftSellingType.IS_PRIMARY) {
      return <NftBuyButton nft={nft!} className={className} />;
    }
    if (nft?.availability === AvailabilityStatus.OFFER_ENDED) {
      return renderDisabledButton(t('Offer ended'));
    }
    if (nft?.auctionStatus === AuctionStatus.SETTLED && nft.nftType === NftType.AUCTIONED) {
      if (nft.balance) {
        return <NftBuyButton nft={nft} className={className} />;
      }
      return renderDisabledButton(t('Sold out'));
    }
    return <NftBuyButton nft={nft!} className={className} />;
  }, [className, excludeSellButton, nft, renderDisabledButton, t]);

  return renderActionButton();
};
