import {Box, makeStyles} from '@material-ui/core';

import {Skeleton} from './Skeleton';
import {SocialNetworksInfoSkeleton} from './SocialNetworksInfoSkeleton';

const useStyles = makeStyles((theme) => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  desc: {
    borderRadius: '12px',
    height: '90px',
    width: '100%',
  },
  creator: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: '12px',
  },
  creatorLogo: {
    width: '32px',
    height: '32px',
    marginRight: '12px',
  },
  creatorName: {
    width: '130px',
    height: '22px',
    borderRadius: '4px',
  },
}));
export const ExhibitAboutInfo = () => {
  const styles = useStyles();

  return (
    <Box className={styles.root}>
      <Skeleton variant="rect" className={styles.desc} />

      <Box className={styles.creator}>
        <Skeleton variant="circle" className={styles.creatorLogo} />
        <Skeleton variant="rect" className={styles.creatorName} />
      </Box>
      <Box pt={2} pb={4}>
        <SocialNetworksInfoSkeleton />
      </Box>
    </Box>
  );
};
