import {Button} from '@cere/rxb-template-ui-kit';
import analyticService, {AnalyticEventsEnum} from '../../../../shared/services/analytic.service';
import clsx from 'clsx';
import {GoogleAnalyticsId} from '../../../../analytics-ids';
import {ctaClassName} from './styled';
import {CmsExhibitionNft} from '../../../../shared/types/graphql';
import {useCallback, useContext} from 'react';
import {NftContext} from '../../../../shared/components/Nft/nft-context';
import {isAccessNft} from '../../../../shared/helpers/auction';
import {useLocalization} from '../../../../shared/hooks/use-locale.hook';

interface InterstitialEventPageBuyButtonProps {
  setSelectedNft: (nft: CmsExhibitionNft) => unknown;
  canBePurchased: boolean;
  handlePurchase: (nftId: CmsExhibitionNft, amount: number) => void;
}

export const InterstitialEventPageBuyButton = ({
  setSelectedNft,
  canBePurchased,
  handlePurchase,
}: InterstitialEventPageBuyButtonProps) => {
  const {t} = useLocalization();
  const mainPurchaseButtonTitle = t('Buy NFT ticket');
  const {eventNft} = useContext(NftContext);

  const handler = useCallback(() => {
    if (eventNft && isAccessNft(eventNft)) {
      analyticService.track(AnalyticEventsEnum.INTERSTITIAL_MAIN_BUY_ACCESS_CLICKED);
      setSelectedNft(eventNft);

      return handlePurchase(eventNft, 1);
    }
  }, [eventNft, handlePurchase, setSelectedNft]);

  return (
    <Button
      title={mainPurchaseButtonTitle}
      color="primary"
      variant="contained"
      size="large"
      style={{fontWeight: 'bold'}}
      onClick={handler}
      fullWidth
      disabled={!canBePurchased}
      className={clsx(ctaClassName, GoogleAnalyticsId.BuyAccessBtn)}
    >
      {mainPurchaseButtonTitle}
    </Button>
  );
};
