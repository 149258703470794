import {Freeport, Marketplace} from '@cere/freeport-sdk';
import {IPurchaseNFTApi} from './IPurchaseNFTApi';
import {ContractsProvider} from '../blockchain/ContractsProvider';
import {AllowanceBalanceError} from './errors/AllowanceBalanceError';
import {NFTPurchaseError} from './errors/NFTPurchaseError';

const ONE_BILLION = 1e9 * 1e6;
const METAMASK_GAS_REQUIRED_EXCEEDS_ALLOWANCE_ERROR_CODE = -32000;

export class FreeportPurchaseNFTApi implements IPurchaseNFTApi {
  constructor(
    private readonly contractProvider: ContractsProvider,
    private readonly maxPurchaseRetries: number = 3,
    private readonly gasLimit: number,
    private readonly gasPrice: number,
    private readonly maxAllowance: number = ONE_BILLION,
  ) {}

  async purchaseNft(
    buyerWalletAddress: string,
    sellerWalletAddress: string,
    nftAddress: string,
    price: number,
    amount: number,
    collectionAddress?: string,
  ): Promise<number> {
    const contract = await this.getContract(Boolean(collectionAddress));
    await this.checkAllowedBalance(contract.address, buyerWalletAddress, price);
    return await this.purchaseNftWithRetryStrategy(
      contract,
      buyerWalletAddress,
      sellerWalletAddress,
      nftAddress,
      price,
      amount,
      collectionAddress,
    );
  }

  private async purchaseNftWithRetryStrategy(
    contract: Marketplace | Freeport,
    buyerWalletAddress: string,
    sellerWalletAddress: string,
    nftAddress: string,
    price: number,
    amount: number,
    collectionAddress?: string,
    attempt: number = 1,
  ): Promise<number> {
    try {
      const tx = await contract.takeOffer(buyerWalletAddress, sellerWalletAddress, nftAddress, price, amount, {
        gasLimit: this.gasLimit,
        gasPrice: this.gasPrice,
      });
      await tx.wait();
      return tx.timestamp || Date.now();
    } catch (error) {
      if (error?.code === METAMASK_GAS_REQUIRED_EXCEEDS_ALLOWANCE_ERROR_CODE && attempt < this.maxPurchaseRetries) {
        return await this.purchaseNftWithRetryStrategy(
          contract,
          buyerWalletAddress,
          sellerWalletAddress,
          nftAddress,
          price,
          amount,
          collectionAddress,
          attempt + 1,
        );
      }
      console.error(error);
      throw new NFTPurchaseError();
    }
  }

  private async checkAllowedBalance(contractAddress: string, buyerWalletAddress: string, price: number): Promise<void> {
    const erc20 = await this.contractProvider.getERC20Contract();
    const allowanceBalance = await erc20.allowance(buyerWalletAddress, contractAddress);
    if (allowanceBalance.toNumber() < price) {
      try {
        await erc20.approve(contractAddress, this.maxAllowance, {
          gasLimit: this.gasLimit,
          gasPrice: this.gasPrice,
        });
      } catch (err) {
        console.error(err);
        throw new AllowanceBalanceError();
      }
    }
  }

  private async getContract(hasCollectionAddress: boolean) {
    if (hasCollectionAddress) {
      return this.contractProvider.getMarketplaceContract();
    }
    return this.contractProvider.getFreeportContract();
  }
}
