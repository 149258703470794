import {Box, makeStyles} from '@material-ui/core';
import colors from '../../../styles/colors';
import {useCallback, useContext, useState} from 'react';
import analyticService, {AnalyticEventsEnum} from '../../services/analytic.service';
import {Checkbox} from '../Checkbox';
import {Typography} from '@cere/rxb-template-ui-kit';
import {Trans} from 'react-i18next';
import {AppContext} from '../../../context/app-context';

const useStyles = makeStyles(() => ({
  label: {
    color: colors.lightGrey,
  },
  link: {
    color: colors.primaryDark,
    textDecoration: 'none',
    fontWeight: 600,
  },
}));

export const TermsAndConditions = ({
  isDefaultChecked = false,
  onClick,
}: {
  isDefaultChecked?: boolean;
  onClick?: Function;
}) => {
  const classes = useStyles();
  const [readTerms, setReadTerms] = useState(isDefaultChecked);

  const onTosClicked = useCallback(() => {
    analyticService.track(AnalyticEventsEnum.SIGN_UP_TOS_TOGGLED);

    onClick && onClick(!readTerms);
    setReadTerms(!readTerms);
  }, [readTerms, setReadTerms, onClick]);
  const {appConfig} = useContext(AppContext);

  return (
    <Box display="flex" alignItems="flex-start" mt="16px">
      <Checkbox required checked={readTerms} onChange={onTosClicked} />

      <Typography variant="caption1" className={classes.label}>
        <Trans>
          I agree with the{' '}
          <Typography
            variant="caption1"
            component="a"
            href={appConfig.termsOfServicesUrl}
            target="_blank"
            className={classes.link}
          >
            Terms & Conditions
          </Typography>{' '}
          and <br /> have read the{' '}
          <Typography
            variant="caption1"
            component="a"
            href={appConfig.privacyPolicyUrl}
            target="_blank"
            className={classes.link}
          >
            Privacy Policy
          </Typography>
        </Trans>
      </Typography>
    </Box>
  );
};
