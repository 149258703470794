import {FC, ReactElement, useEffect, useRef} from 'react';
import {Box} from '@material-ui/core';
import {CmsExhibitAsset} from '@cere/services-types';
import {MediaPlayerWithRestrictedAccess} from '../../Player/MediaPlayerWithRestrictedAccess';
import {buildAbsoluteCmsUrl} from '../../../queries/utils';
import {getMediaUrl, parseMediaAlternativeText} from '../../../lib/media';

const TIMEOUT = 5000;

interface AuctionCarouselProps {
  asset: CmsExhibitAsset;
  play: boolean;
  setNextAsset: Function;
  progress: number;
  onPlayback: Function;
  onPlayerPause?: Function;
  onPlayerPlay?: Function;
  onVolumeChange?: Function;
  onFullscreenChange?: Function;
  onPlayerTimeUpdate: (time: number) => unknown;
}

export const Carousel: FC<AuctionCarouselProps> = ({
  asset,
  play,
  setNextAsset,
  onPlayerTimeUpdate,
  progress,
  onPlayback,
  onPlayerPlay,
  onPlayerPause,
  onVolumeChange,
  onFullscreenChange,
}): ReactElement | null => {
  const startTimestamp = useRef<number>();
  const remainingTime = useRef<number>();
  const timer = useRef<number>();
  const interval = useRef<number>();

  const handlePlayback = (percentage: number) => {
    onPlayback(percentage);
    if (percentage === 100) {
      setTimeout(() => {
        setNextAsset();
      }, 1000);
    }
  };

  const cleanupTimers = () => {
    clearInterval(interval.current);
    clearTimeout(timer.current);
    interval.current = undefined;
    timer.current = undefined;
  };

  const setupTimeout = (timeout = TIMEOUT) => {
    startTimestamp.current = Date.now();
    let currentProgress = progress;

    if (currentProgress === 100) return;

    interval.current = window.setInterval(() => {
      onPlayback(++currentProgress);
    }, TIMEOUT / 100);

    timer.current = window.setTimeout(() => {
      cleanupTimers();

      setNextAsset();
    }, timeout);
  };

  const pause = () => {
    remainingTime.current = Math.round((startTimestamp.current as number) + TIMEOUT - Date.now());
    startTimestamp.current = undefined;
    cleanupTimers();
  };

  const resume = () => {
    const time = remainingTime.current;
    remainingTime.current = undefined;

    setupTimeout(time);
  };

  useEffect(() => {
    if (!asset?.content?.alternativeText) {
      if (!play) {
        pause();
      } else {
        if (remainingTime.current) resume();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [play]);

  useEffect(() => {
    if (!asset?.content?.alternativeText) {
      setupTimeout();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asset]);

  useEffect(() => {
    return () => cleanupTimers();
  }, []);

  if (!asset) return null;

  const {brightcoveId: videoId} = parseMediaAlternativeText(asset?.content?.alternativeText);

  return asset?.content?.alternativeText ? (
    <MediaPlayerWithRestrictedAccess
      autoplay
      videoId={videoId || ''}
      onPlayerTimeUpdate={onPlayerTimeUpdate}
      onPlayback={handlePlayback}
      play={play}
      onPlay={onPlayerPlay}
      onPause={onPlayerPause}
      onVolumeChange={onVolumeChange}
      onFullscreenChange={onFullscreenChange}
    />
  ) : (
    <Box
      width="100%"
      height="100vh"
      style={{
        backgroundImage: `url(${buildAbsoluteCmsUrl(getMediaUrl(asset?.content))})`,
        backgroundSize: 'cover',
      }}
    />
  );
};
