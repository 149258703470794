import {useContext} from 'react';

import {ExhibitContext} from 'context/exhibition/exhibit-context';

import {useAccessTicket} from '../../../use-access-ticket';
import {PreviewLoader} from '../PreviewLoader';
import {ActiveEvent} from './active-event';
import {ActiveAllocationPendingEvent} from './active-allocation-pending-event';
import {ActiveUnsignedFreeAccessEvent} from './active-unsigned-free-access-event';
import {NoAccess} from './no-access';
import {AccessSoldOut} from './AccessSoldOut';

type Props = {
  isLoading: boolean;
  accessPending: boolean;
  userHasEventAccess: boolean;
  userHasJwtAccess: boolean;
  onPlayVideoClick: () => void;
};

export const StartedEventPreview = ({
  isLoading = true,
  accessPending,
  userHasEventAccess,
  userHasJwtAccess,
  onPlayVideoClick,
}: Props) => {
  const {event} = useContext(ExhibitContext);
  const accessNft = useAccessTicket();
  const hasAccessNfts = Boolean(accessNft);

  if (isLoading || !event) {
    return <PreviewLoader />;
  }

  if (userHasEventAccess && userHasJwtAccess) {
    return <ActiveEvent event={event} onPlayVideoClick={onPlayVideoClick} />;
  }

  if (accessPending) {
    return <ActiveAllocationPendingEvent event={event} />;
  }

  if (Boolean(event?.allowFreeAccess)) {
    return <ActiveUnsignedFreeAccessEvent event={event} endsAt={event.endsAt} />;
  }

  if (!hasAccessNfts) {
    return <NoAccess event={event} />;
  }

  return <AccessSoldOut event={event} />;
};
