import * as Sentry from '@sentry/react';
import {getPaymentUrl, getPopupPaymentUrl} from '../hooks/payment.hook';
import {get, post, RequestOptions} from '../lib/request';
import {paymentHistoryRawToPaymentHistoryMapper} from '../mappers/payment-history-item.mapper';
import {isPaymentHistoryItemRaw} from '../types/payment-history-item-raw';
import {PaymentHistoryItem} from '../types/payment-history-item';
import {getFreeportNonCustody} from '../lib/get-freeport';
import {CheckUserHasNftEnum, PaymentStatusEnum, PaymentTypeEnum} from '@cere/services-types';
import {isRecord} from '../types/is-record';
import {NonCustodyWalletTypeEnum} from '../types/non-custody-wallet';
import {CmsExhibitionIdSlugOnly} from '../types/graphql';
import {getERC20NonCustody} from '../lib/get-erc20';
import {
  Freeport,
  getAuctionAddress,
  getFreeportAddress,
  getMarketplaceAddress,
  getSimpleAuctionAddress,
} from '@cere/freeport-sdk';
import {PaymentSessionParams} from '../types/payment-session-params';
import {METAMASK_GAS_REQUIRED_EXCEEDS_ALLOWANCE_ERROR_CODE} from '../constants/errors';
import {Numberish} from '../types/numberish';
import {findExhibitionByAttachedNft} from './exhibition.service';
import {
  APPLICATION,
  CONTRACTS_DEPLOYMENT,
  GAS_LIMIT as gasLimit,
  GAS_PRICE as gasPrice,
  PAYMENT_SERVICE_API_URL,
  tenantId,
  webAppUrl,
} from '../../config/common';
import i18n from '../../i18n';
import {getMarketplaceNonCustody} from '../lib/get-marketplace';
import {Marketplace} from '@cere/freeport-sdk/dist/abi-types/Marketplace';
import {TestERC20 as ERC20} from '@cere/freeport-sdk';

const ERROR_CODES_TO_RETRY = [METAMASK_GAS_REQUIRED_EXCEEDS_ALLOWANCE_ERROR_CODE];
const MAX_RETRIES = 3;

type PaymentUrlProps = {
  buyerEmail: string;
  nftId: string;
  quantity: number;
  eventId: number;
  successUrl: string;
  cancelUrl: string;
  locale: string;
};

const getPaymentOptions = (): RequestOptions => {
  return {
    base: PAYMENT_SERVICE_API_URL,
    headers: {
      'X-Tenant-Id': tenantId(),
    },
  };
};

const getFiatPaymentUrl = async ({
  buyerEmail,
  nftId,
  quantity,
  eventId,
  successUrl,
  cancelUrl,
  locale,
}: PaymentUrlProps): Promise<string> => {
  const json = await post(
    `/buy-nft-for-fiat`,
    {buyerEmail, nftId, quantity, eventId: Number(eventId), successUrl, cancelUrl, currency: 'USD', locale},
    getPaymentOptions(),
  );
  if (!isRecord(json) || typeof json.paymentUrl !== 'string' || json.paymentUrl === '') {
    throw new Error(i18n.t('Error fetching Payment URL from API.'));
  }
  return json.paymentUrl;
};

type Params = {
  buyerEmail: string;
  nftId: string;
  quantity: number;
  event?: CmsExhibitionIdSlugOnly;
  previewKey?: string;
  cmsNftIncrementId?: Numberish;
  locale: string;
};

export const getUrlByEmailAndNftId = async ({
  buyerEmail,
  nftId,
  quantity,
  previewKey,
  cmsNftIncrementId,
  locale,
}: Params): Promise<string> => {
  const eventData = await findExhibitionByAttachedNft(nftId);
  const {successUrl, cancelUrl} = getPaymentUrl({
    eventName: eventData.slug,
    previewKey,
    cmsNftIncrementId,
    nftId,
    locale,
  });
  return getFiatPaymentUrl({buyerEmail, nftId, quantity, eventId: eventData.id, cancelUrl, successUrl, locale});
};

export const getPopupUrlByEmailAndNftId = async ({buyerEmail, nftId, quantity, locale}: Params): Promise<string> => {
  const eventData = await findExhibitionByAttachedNft(nftId);
  const {successUrl, cancelUrl} = getPopupPaymentUrl(locale);
  return getFiatPaymentUrl({buyerEmail, nftId, quantity, eventId: eventData.id, cancelUrl, successUrl, locale});
};

export const getUrlBuyTokenForFiat = async (usdAmountCents: number, buyerEmail: string): Promise<string> => {
  const successUrl = `${webAppUrl()}/home/user/wallet`;
  const cancelUrl = `${webAppUrl()}/home/user/wallet`;
  const json = (await post(
    `/buy-cere-for-fiat`,
    {buyerEmail, usdAmountCents, successUrl, cancelUrl, currency: 'USD'},
    getPaymentOptions(),
  )) as Record<string, string>;

  const url = json.paymentUrl;

  if (!url) {
    throw new Error(i18n.t('Error fetching Payment URL from API.'));
  }

  return url;
};

const isCheckUserHasNftEnum = (value: unknown): value is CheckUserHasNftEnum => {
  return (
    value === CheckUserHasNftEnum.USER_HAS_NFT ||
    value === CheckUserHasNftEnum.USER_DOES_NOT_HAVE_NFT ||
    value === CheckUserHasNftEnum.USER_PAYMENT_PROCESSING
  );
};

export const checkIfUserHasNft = async (email: string, nftId: string): Promise<CheckUserHasNftEnum> => {
  const json = await get(`/user-has-nft?email=${encodeURIComponent(email)}&nftId=${nftId}`, getPaymentOptions()).catch(
    (error) => {
      Sentry.captureException(error);
      throw error;
    },
  );

  if (!isRecord(json) || json.code !== 'SUCCESS' || !isCheckUserHasNftEnum(json.data)) {
    throw new Error(i18n.t('Something went wrong. Check logs!'));
  }

  return json.data;
};

type PaymentHistoryParams = {
  ethAddress: string;
  status?: PaymentStatusEnum;
  nftId?: string;
};

export const getPaymentHistoryByPublicKey = async ({
  ethAddress,
  nftId,
  status,
}: PaymentHistoryParams): Promise<PaymentHistoryItem[]> => {
  const params = {buyerEthAddress: ethAddress, nftId, status};
  const query = new URLSearchParams();
  Object.entries(params).forEach(([key, value]) => {
    if (value) {
      query.append(key, value);
    }
  });
  const json = await get(`/payment-history/by-address?${query.toString()}`, getPaymentOptions()).catch((error) => {
    Sentry.captureException(error);
    throw error;
  });

  if (!isRecord(json) || json.code !== 'SUCCESS' || !Array.isArray(json.data)) {
    throw new Error(i18n.t('Something went wrong. Check logs!'));
  }

  const items = json.data;

  return items.filter(isPaymentHistoryItemRaw).map(paymentHistoryRawToPaymentHistoryMapper).reverse();
};

export const getEmailByStripeSessionId = async (stripeSessionId: string): Promise<string> => {
  const json = await get(
    `/payment-history/get-user-email-by-session-id?sessionId=${stripeSessionId}`,
    getPaymentOptions(),
  );

  if (isRecord(json) && json.email && typeof json.email === 'string') {
    return json.email;
  }

  throw new Error(i18n.t('Email is not found by stripeSessionId!'));
};

export const getAveragePaymentProcessingTime = async (type: PaymentTypeEnum): Promise<number> => {
  const json = await get(`/get-average-payment-time?type=${type}`, getPaymentOptions());

  if (isRecord(json) && isRecord(json.data)) {
    if (typeof json.data.ms === 'number') {
      return json.data.ms || 0;
    }
  }

  throw new Error(i18n.t('Error during get average payment time'));
};

export const buyNftForTokenNonCustody = async (
  type: NonCustodyWalletTypeEnum,
  buyer: string,
  seller: string,
  nftAddress: string,
  price: number,
  amount: number,
  collectionAddress?: string,
  retries: number = 0,
): Promise<void> => {
  try {
    const contract: Marketplace | Freeport = collectionAddress
      ? await getMarketplaceNonCustody(type)
      : await getFreeportNonCustody(type);
    const tx = await contract.takeOffer(buyer, seller, nftAddress, price, amount, {
      gasLimit,
      gasPrice,
    });
    await tx.wait();
  } catch (error) {
    if (error?.code && ERROR_CODES_TO_RETRY.includes(error.code) && retries < MAX_RETRIES) {
      return buyNftForTokenNonCustody(type, buyer, seller, nftAddress, price, amount, collectionAddress, retries + 1);
    }

    throw error;
  }
};

export const checkAllowanceBalanceAuction = async (
  type: NonCustodyWalletTypeEnum,
  buyer: string,
  price: number,
  collectionAddress?: string,
) => {
  const [erc20, signer] = await getERC20NonCustody(type);
  let address = await getSimpleAuctionAddress(signer.provider, CONTRACTS_DEPLOYMENT, APPLICATION());
  if (collectionAddress) {
    address = await getAuctionAddress(signer.provider, CONTRACTS_DEPLOYMENT, APPLICATION());
  }

  return checkAllowedBalanceAndSubmit(erc20, buyer, address, price);
};

const checkAllowedBalanceAndSubmit = async (erc20: ERC20, buyer: string, address: string, price: number) => {
  const allowBalance = await erc20.allowance(buyer, address);

  const oneBillion = 1e9 * 1e6;
  if (allowBalance.toNumber() < price) {
    try {
      await erc20.approve(address, oneBillion, {
        gasLimit,
        gasPrice,
      });
    } catch (err) {
      console.error(err);
      throw err;
    }
  }
};

export const checkAllowanceBalanceFreeport = async (
  type: NonCustodyWalletTypeEnum,
  buyer: string,
  price: number,
  collectionAddress?: string,
) => {
  const [erc20, signer] = await getERC20NonCustody(type);
  let address = await getFreeportAddress(signer.provider, CONTRACTS_DEPLOYMENT, APPLICATION());
  if (collectionAddress) {
    address = await getMarketplaceAddress(signer.provider, CONTRACTS_DEPLOYMENT, APPLICATION());
  }

  return checkAllowedBalanceAndSubmit(erc20, buyer, address, price);
};

export const getSessionsParamsByIds = async (
  userPayments: PaymentHistoryItem[],
): Promise<Record<string, PaymentSessionParams>> => {
  return userPayments.reduce(
    (acc, paymentItem) => ({
      ...acc,
      [paymentItem.nftId]: {
        sessionId: paymentItem.meta.sessionId,
        paymentUrl: paymentItem.meta.paymentUrl || '',
      },
    }),
    {},
  );
};
