import {Box, makeStyles} from '@material-ui/core';
import {Button, Dialog, Typography} from '@cere/rxb-template-ui-kit';
import {Checkbox} from '../Checkbox';
import {useContext} from 'react';
import colors from '../../../styles/colors';
import {AppContext} from '../../../context/app-context';
import {ReactComponent as WarningIcon} from '../../../assets/icons/warning_triangle.svg';
import {useLocalization} from '../../hooks/use-locale.hook';
import {useSingletonCheckUserConsent} from './hooks';

const useStyles = makeStyles((theme) => ({
  label: {
    color: colors.lightGrey,
  },
  warning: {
    marginBottom: '14px',
    [theme.breakpoints.up('md')]: {
      marginBottom: '6px',
    },
  },
  warningBg: {
    backgroundColor: '#f1f1f4',
  },
  noTopMargin: {
    marginTop: '0',
  },
}));

export const UserConsentPopup = () => {
  const closeDialog = () => {};
  const {t} = useLocalization();

  const classes = useStyles();
  const {isModalVisible, readTerms, onReadClicked, onSubmit, isSubmitDisabled} = useSingletonCheckUserConsent();

  const {appConfig} = useContext(AppContext);

  return (
    <Dialog
      desktop={false}
      title="Disclaimer"
      maxWidth="sm"
      open={isModalVisible}
      onClose={closeDialog}
      disableCloseIcon
    >
      <Box
        className={classes.warningBg}
        p="15px"
        flexDirection="row"
        display="flex"
        justifyContent="center"
        alignItems="center"
        borderRadius="12px"
        mb="15px"
      >
        <WarningIcon width="50px" />
        <Box ml="5px">
          {t(
            'Please read this disclaimer carefully before using the {{appTitle}} NFT marketplace website operated by {{appTitle}}',
            {appTitle: appConfig.appTitle},
          )}
          .
        </Box>
      </Box>
      <Box maxHeight="250px" overflow="auto">
        <p className={classes.noTopMargin}>
          {t(
            'Before entering the {{appTitle}} NFT marketplace please ensure that the email address registered with your {{appTitle}} user account is accessible to you, since the recovery keys for your marketplace wallet will be tied to this email address. If you don’t have access to this email address, please change your email address on the {{appTitle}} website now.',
            {appTitle: appConfig.appTitle},
          )}
        </p>

        <p>
          {t(
            'The content displayed on the website is the intellectual property of the {{appTitle}}. You may not reuse, republish, or reprint such content without our written consent. Should you decide to act upon any information on this website, you do so at your own risk. While the information on this website has been verified to the best of our abilities, we cannot guarantee that there are no mistakes or errors. We reserve the right to change this policy at any given time, of which you will be promptly updated. If you want to make sure that you are up to date with the latest changes, we advise you to frequently visit this page.',
            {appTitle: appConfig.appTitle},
          )}
        </p>
      </Box>
      <Box display="flex" alignItems="flex-start" mt="15px">
        <Checkbox checked={readTerms} onChange={onReadClicked} />

        <Typography variant="caption1" className={classes.label}>
          {t('Please acknowledge that you understand the implications of using the LiveOne NFT marketplace.')}
        </Typography>
      </Box>

      <Box mt="24px" mb="12px">
        <Button
          color="primary"
          size="large"
          variant="contained"
          fullWidth
          type="submit"
          disabled={isSubmitDisabled}
          onClick={onSubmit}
        >
          {t('I Accept')}
        </Button>
      </Box>
    </Dialog>
  );
};
