import {makeStyles} from '@material-ui/core';
import colors from '../../../styles/colors';

export const useListingsCardStyles = makeStyles((theme) => ({
  firstColumnText: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '21px',
    color: colors.primaryDark,
    '& svg': {
      marginRight: '0.5rem',
    },
    '& .gray': {
      marginLeft: '4px',
      color: colors.lightGrey,
    },
  },
  tableCellText: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '21px',
    color: colors.lightGrey,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  link: {
    textDecoration: 'none',
  },
  tableButton: {
    width: '96px',
    height: '28px',
    padding: '4px 8px',
    textTransform: 'none',
    borderRadius: '50px',
    '&:hover': {
      backgroundColor: theme.palette.common.white,
      boxShadow: 'none',
    },
  },
  tableButtonList: {
    width: '100%',
    height: '36px',
    marginTop: '12px',
    padding: '4px 8px',
    textTransform: 'none',
    borderRadius: '50px',
  },
  buyButton: {
    color: colors.green,
    border: `1px solid ${colors.green}`,
  },
  cancelButton: {
    color: theme.palette.secondary.main,
    border: `1px solid ${theme.palette.secondary.main}`,
  },
  centeredContainer: {
    display: 'flex',
    width: '100%',
    height: '296px',
    justifyContent: 'center',
    alignItems: 'center',
  },
  tableWrapper: {
    maxHeight: '296px',
    overflow: 'auto',
  },
  tableHeadCell: {
    border: 'none',
    padding: '8px 0',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',
    color: colors.primaryDark,

    '&.MuiTableCell-stickyHeader': {
      backgroundColor: colors.snowWhite,
    },
  },
  tableCellButton: {
    height: '36px',
  },
  tableBodyCell: {
    backgroundColor: colors.snowWhite,
    border: 'none',
    padding: '8px 0',
  },
  tableBodyButtonCell: {
    textAlign: 'center',
    '& p': {
      justifyContent: 'start',
    },
  },
  tableBody: {
    maxHeight: '296px',
    overflow: 'auto',
  },
  tableRow: {
    backgroundColor: colors.light,
  },
}));
