import {useCallback, useState} from 'react';
import {Box, makeStyles} from '@material-ui/core';
import {css} from '@linaria/core';
import clsx from 'clsx';
import {Button, Typography} from '@cere/rxb-template-ui-kit';
import {PaymentTypeEnum} from '@cere/services-types';

import {ReactComponent as CalendarIcon} from 'assets/calendar.svg';
import {ReactComponent as LockHourglass} from 'assets/hourglass.svg';
import {NFTModalSimple} from 'shared/components/NFTModalSimple';
import {ConfirmationModalContent} from 'shared/components/ConfirmationModal';
import {Calendar} from 'shared/components/Calendar';
import AverageNftPaymentCountdown from 'shared/components/average-payment-countdown/average-payment-countdown.component';
import {useAutoAddEventToCalendar} from 'shared/hooks/auto-add-event-to-calendar.hook';
import {useNotifications} from 'shared/hooks/use-notifications';
import {useLocalization} from 'shared/hooks/use-locale.hook';
import {createSubscription} from 'shared/services/subscription.service';
import {addEventToCalendar} from 'shared/services/event.service';
import analyticService, {AnalyticEventsEnum} from 'shared/services/analytic.service';
import {ExhibitionTeaserType} from 'shared/types/exhibition-teaser';
import {CmsExhibition} from 'shared/types/graphql';
import {SubscriptionTypeEnum} from 'shared/types/subscription';
import {EventTimelineStatus} from 'shared/types/event';
import colors from 'styles/colors';
import {GoogleAnalyticsId} from 'analytics-ids';

import {SubscribeModal} from '../../../../InterstitialEventPage/routes/InterstitialEventPage/SubcribeModal';
import {useStyles as useLiveStyles} from '../Exhibition-preview-container/live-styles';
import {ExhibitTeaser} from '../Exhibition-preview-container/exhibit-teaser';
import {PreviewLoader} from '../PreviewLoader';
import {ExploreNftsButton} from '../Exhibition-preview-container/explore-nfts-button';

const useStyles = makeStyles(() => ({
  countdown: {
    '& span': {
      color: colors['navbar-text'],
    },
  },
}));

const useCalendarStyles = makeStyles((theme) => ({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    width: 'fit-content',
    whiteSpace: 'nowrap',

    marginBottom: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      marginBottom: 0,
      marginRight: theme.spacing(3),
    },

    '& > *:nth-child(1)': {
      marginBottom: 0,
      minWidth: '36px',
      maxWidth: '36px',
      minHeight: '36px',
      maxHeight: '36px',

      [theme.breakpoints.up('sm')]: {
        minWidth: '44px',
        maxWidth: '44px',
        minHeight: '44px',
        maxHeight: '44px',
      },
    },
    '& > *:nth-child(2)': {
      marginTop: 0,
    },
  },
  dateAndTime: {
    marginBottom: '0',
    marginLeft: '10px',
  },
}));

const iconStyle = css`
  width: 40px;
  height: 40px;
  background: rgba(140, 140, 148, 0.5);
  backdrop-filter: blur(8px);
  border-radius: 50%;

  svg {
    width: 22px;
    height: 22px;

    path {
      fill: ${colors.light};
    }
  }
`;

type Props = {
  event: CmsExhibition;
  eventTimelineStatus: EventTimelineStatus;
  isLoading: boolean;
  accessPending: boolean;
  userHasEventAccess: boolean;
  onExploreNFTsClick: () => void;
};

export const NotStartedEventPreview = ({
  event,
  eventTimelineStatus,
  isLoading = true,
  accessPending,
  userHasEventAccess,
  onExploreNFTsClick,
}: Props) => {
  const {locale, t} = useLocalization();
  const shouldShowTeaser = Boolean(event?.teaser?.content);
  const styles = useLiveStyles({});
  const classes = useStyles();
  const calendarClasses = useCalendarStyles();
  const [showSubscribeModal, setShowSubscribeModal] = useState(false);
  const {warning} = useNotifications();
  const [activeCountdown, setActiveCountdown] = useState(true);

  const disableAddToCalendarBtn = !event.startsAt;
  const addToCalendar = useCallback(async () => addEventToCalendar(event), [event]);
  useAutoAddEventToCalendar(event);

  const handleSubscribeToExhibit = async (email: string) => {
    setShowSubscribeModal(false);
    await createSubscription(email, SubscriptionTypeEnum.EXHIBIT, Number(event.id), locale).catch((error) => {
      warning(t("Couldn't subscribe at the moment. Please, try again later, sorry for the inconvenience."));
      throw error;
    });
    analyticService.track(AnalyticEventsEnum.SUBSCRIBE_EMAIL, {
      exhibitId: event.id,
      subscribedAt: new Date().toLocaleString('en-US'),
    });
  };

  const [showStayUpdatedModalConfirmation, setShowStayUpdatedModalConfirmation] = useState(false);

  const handleOpenStayUpdatedModalConfirmation = () => {
    setShowStayUpdatedModalConfirmation(true);
  };

  const handleCloseStayUpdatedModalConfirmation = () => {
    setShowStayUpdatedModalConfirmation(false);
  };

  if (isLoading) {
    return <PreviewLoader />;
  }

  const isAccessMode = userHasEventAccess || accessPending;

  return (
    <>
      <Box className={clsx(styles.exhibitionInfo, styles.exhibitionInfoNotStarded)}>
        <Box className={styles.exhibitionInfoLeft}>
          <Box className={clsx(styles.exhibitionTitles, styles.notStartedTitles)}>
            <Typography className={styles.eventSubtitle} variant="h5">
              {event?.subtitle}
            </Typography>
            <Box className={styles.exhibitionTitleWithoutTheme}>{event?.title}</Box>
            <Box
              className={clsx(
                styles.exhibitionCalendarNotStarted,
                isAccessMode && styles.exhibitionCalendarNotStartedLockedView,
              )}
            >
              <Calendar endDate={event.endsAt} startDate={event.startsAt} classes={calendarClasses} />

              <Box>
                {isAccessMode ? (
                  accessPending ? (
                    activeCountdown && (
                      <>
                        <Box
                          className={iconStyle}
                          mb="16px"
                          mx="auto"
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <LockHourglass />
                        </Box>

                        <Typography className={styles.grey} variant="body2" align="center">
                          <AverageNftPaymentCountdown
                            className={classes.countdown}
                            text={t('Approximate time for NFT delivery')}
                            type={PaymentTypeEnum.USD_TO_NFT}
                            onComplete={() => setActiveCountdown(false)}
                          />
                        </Typography>
                      </>
                    )
                  ) : (
                    <Button
                      className={clsx(GoogleAnalyticsId.AddToCalendarBtn)}
                      variant="contained"
                      fullWidth
                      size="large"
                      startIcon={<CalendarIcon className={styles.calendarIcon} />}
                      onClick={addToCalendar}
                      disabled={disableAddToCalendarBtn}
                    >
                      <span className={styles.addToCalendarText}> {t('Add to Calendar')} </span>
                    </Button>
                  )
                ) : (
                  <Button
                    color="primary"
                    variant="outlined"
                    size="large"
                    className={clsx(styles.stayUpdatedButton)}
                    fullWidth
                    onClick={() => setShowSubscribeModal(true)}
                  >
                    <span className={styles.addToCalendarText}>{t('Stay updated')}</span>
                  </Button>
                )}
              </Box>
            </Box>
            <ExploreNftsButton eventTimelineStatus={eventTimelineStatus} onExploreNFTsClick={onExploreNFTsClick} />
          </Box>

          {!event.isComingSoon && (
            <Typography
              className={clsx(styles.description, styles.descriptionNotStarted, isAccessMode && styles.descriptionHide)}
            >
              {t('Purchase a NFT ticket below to enter the exhibit when started')}
            </Typography>
          )}
        </Box>

        <Box className={styles.lockExhibitionImageBlock}>
          <Box className={clsx(styles.enterButtonBlock)}>
            {shouldShowTeaser && (
              <Box className={styles.exhibitionTeaserContainer}>
                <ExhibitTeaser type={ExhibitionTeaserType.REGULAR} />
              </Box>
            )}
          </Box>
        </Box>
      </Box>

      {showSubscribeModal && (
        <SubscribeModal
          onClose={() => setShowSubscribeModal(!showSubscribeModal)}
          onClick={handleSubscribeToExhibit}
          onClickStayUpdatedConfirmation={handleOpenStayUpdatedModalConfirmation}
        />
      )}

      {showStayUpdatedModalConfirmation && (
        <NFTModalSimple
          open={true}
          onClose={handleCloseStayUpdatedModalConfirmation}
          title={t('Share your email to buy this NFT')}
        >
          <ConfirmationModalContent onClick={handleCloseStayUpdatedModalConfirmation} />
        </NFTModalSimple>
      )}
    </>
  );
};
