import {useContext, useEffect, useMemo} from 'react';

import {UserContext} from '../../context/user-context/user-context';
import {isAppWallet} from '../../shared/types/supported-wallet';
import {useLoadWalletNfts} from './use-load-wallet-nfts';
import {AllWallets, ALL_WALLET} from './types';
import {CreatorWalletNft} from 'shared/types/graphql/creator-wallet-nfts';

export const useWalletNfts = (wallet: AllWallets): {isLoading: boolean; nfts: CreatorWalletNft[]} => {
  const {userData, nonCustodyWallets} = useContext(UserContext);

  const nftQueryParam = useMemo(() => {
    const davinciWallet = userData.userPublicKey ? userData.userPublicKey.toLowerCase() : '';
    if (wallet === ALL_WALLET) {
      return [
        ...[davinciWallet].filter(Boolean),
        ...nonCustodyWallets.map(({publicKey}) => publicKey.toLowerCase()).filter(Boolean),
      ];
    }
    if (isAppWallet(wallet)) {
      return [davinciWallet].filter(Boolean);
    }

    if (wallet === 'TORUS') {
      return [
        ...[davinciWallet].filter(Boolean),
        ...nonCustodyWallets
          .filter((wallet) => wallet.type === 'TORUS')
          .map(({publicKey}) => publicKey.toLowerCase())
          .filter(Boolean),
      ];
    }

    return [nonCustodyWallets.find(({type}) => type === wallet)?.publicKey.toLowerCase() ?? ''].filter(Boolean);
  }, [nonCustodyWallets, wallet, userData.userPublicKey]);

  const {nfts, isLoading, refetch} = useLoadWalletNfts(nftQueryParam);

  useEffect(() => {
    refetch({wallet: nftQueryParam});
  }, [nftQueryParam, refetch]);

  return {nfts, isLoading};
};
