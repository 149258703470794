import {useContext} from 'react';
import {Box} from '@material-ui/core';
import {Typography} from '@cere/rxb-template-ui-kit';
import clsx from 'clsx';

import {UserContext} from 'context/user-context/user-context';
import {useExhibitionPreview} from 'shared/hooks/use-exhibition-preview.hook';
import {IdleTimer} from 'shared/components/IdleTimer';
import {Calendar} from 'shared/components/Calendar';
import {ExhibitionTeaserType} from 'shared/types/exhibition-teaser';
import {CmsExhibition} from 'shared/types/graphql';

import {useAccessTicket} from '../../../use-access-ticket';
import {useUserHasEventAccess} from '../../../use-user-has-event-access';
import {useStyles} from '../Exhibition-preview-container/live-styles';
import {ExhibitLiveEnterButton} from '../Exhibition-preview-container/exhibit-live-enter-button';
import {ExhibitTeaser} from '../Exhibition-preview-container/exhibit-teaser';

type Props = {
  event: CmsExhibition;
  onPlayVideoClick: () => void;
};

export const ActiveEvent = ({event, onPlayVideoClick}: Props) => {
  const userHasEventAccess = useUserHasEventAccess();
  const {exhibitionPreview} = useExhibitionPreview(event);
  const nftPurchasedByUser = useAccessTicket({fromAllNFTs: true});
  const isUserBoughtAnyNft = Boolean(nftPurchasedByUser);

  const shouldShowTeaser =
    Boolean(event?.allowFreeAccess) && !isUserBoughtAnyNft && Boolean(event?.liveTeaser?.content);
  const styles = useStyles({exhibitionPreview});

  const {isExhibitFirstOpen, userData} = useContext(UserContext);

  const isShowVideo = Boolean(userData.userPublicKey) && isUserBoughtAnyNft;

  return (
    <Box className={clsx(styles.exhibitionInfo)}>
      <Box className={styles.exhibitionInfoLeft}>
        <Box className={styles.exhibitionCalendar}>
          <Calendar endDate={event?.endsAt} startDate={event!.startsAt} />
        </Box>
        <Box className={styles.exhibitionTitles}>
          <Typography className={styles.eventSubtitle} variant="h5">
            {event?.subtitle}
          </Typography>
          <Box className={styles.exhibitionTitleWithoutTheme}>{event?.title}</Box>
        </Box>
      </Box>
      {shouldShowTeaser && (
        <Box className={styles.exhibitionTeaserContainer}>
          <ExhibitTeaser type={ExhibitionTeaserType.LIVE} />
        </Box>
      )}

      {isShowVideo && (
        <Box className={styles.exhibitionImageBlock}>
          <Box className={styles.exhibitionImagePreview}>
            <ExhibitLiveEnterButton onClick={onPlayVideoClick} variant="primary" />
          </Box>
        </Box>
      )}

      {isExhibitFirstOpen && userHasEventAccess && <IdleTimer onInActive={onPlayVideoClick} />}
    </Box>
  );
};
