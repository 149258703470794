import {createContext, useContext} from 'react';
import {TorusWallet} from '../../shared/services/torus';

export const TorusWalletContext = createContext<TorusWallet | null>(null);

export const useTorusWallet = () => {
  const wallet = useContext(TorusWalletContext);

  if (!wallet) {
    throw new Error('[Tor.us] Not in the wallet context');
  }

  return wallet;
};
