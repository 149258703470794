import {useSelector} from 'react-redux';
import {selectCurrentLocale} from '../../redux/modules/localization/selectors';
import {useTranslation} from 'react-i18next';
import {useEffect} from 'react';

export const useLocalization = () => {
  const locale = useSelector(selectCurrentLocale);
  const {t, i18n} = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(locale);
  }, [locale, i18n]);

  return {
    t,
    locale,
  };
};
