import React, {useContext, useEffect, useMemo} from 'react';

import {TransactionalNotificationsContext} from './transactional-notifications-context';
import {TransactionalNotifications} from '../../shared/services/transactional-notifications/transactional-notifications.service';
import {NotificationItem} from '../../shared/services/transactional-notifications/types';
import {UserContext} from '../user-context/user-context';
import {MessagesContext} from '../messages-context';
import {MessageEvent} from '../../shared/types/message-event';

export const TransactionalNotificationsContextProvider: React.FC = ({children}) => {
  const {userData} = useContext(UserContext);
  const messagesContext = useContext(MessagesContext);
  const notificationsContext = useMemo(() => new TransactionalNotifications(), []);

  useEffect(() => {
    if (!userData?.token) {
      return notificationsContext.reset();
    }

    notificationsContext.setUserCredentials(userData.token);
    notificationsContext.loadNewNotificationsCount();
  }, [userData?.token, notificationsContext]);

  useEffect(() => {
    if (userData?.token) {
      return messagesContext.on<NotificationItem>(MessageEvent.NOTIFICATION_CREATED, (data: NotificationItem) => {
        notificationsContext.onNotificationCreated(data);
      });
    }
  }, [messagesContext, userData?.token, notificationsContext]);

  return (
    <TransactionalNotificationsContext.Provider value={notificationsContext}>
      {children}
    </TransactionalNotificationsContext.Provider>
  );
};
