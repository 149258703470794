import {useCallback, useContext, useMemo} from 'react';
import {useHistory} from 'react-router-dom';
import {
  AuctionStatus,
  AvailabilityStatus,
  CheckUserHasNftEnum,
  NftCardInterface,
  NftType,
  ExhibitCardInterface,
} from '@cere/services-types';
import {usePendingTransactionsContext} from '../../../context/pending-transaction-context';
import {enableMarketplaceView} from '../../../config/common';
import {ExhibitContext, ExhibitionEmptyContext} from '../../../context/exhibition/exhibit-context';
import analyticService, {AnalyticEventsEnum} from '../../services/analytic.service';
import {useUserHasEventAccess} from '../../../pages/ExhibitPage/use-user-has-event-access';
import {useLocalization} from '../../hooks/use-locale.hook';
import {checkIsDisabledNft} from './utils';

type Params = {
  balance: number;
  buy: () => Promise<unknown>;
  event?: ExhibitCardInterface;
  nft: NftCardInterface;
  owned: number;
  share: () => unknown;
  sell: () => unknown;
  availableOnlyOnMarketplace: boolean;
  hasUserOffer: boolean;
};

export function useNftCtaButtonProps({
  nft,
  availableOnlyOnMarketplace,
  sell,
  share,
  buy,
  event,
  hasUserOffer,
}: Params): {
  disabled: boolean;
  label: string;
  loading: boolean;
  isLightButton: boolean;
  onClick: () => unknown;
  onShare: () => unknown;
} {
  const {t, locale} = useLocalization();
  const purchased = nft.purchaseStatus === CheckUserHasNftEnum.USER_HAS_NFT;
  const history = useHistory();

  const userHasEventAccess = useUserHasEventAccess();

  const actualAuction = nft.auction;
  const isSettled = actualAuction?.isSettled;
  const {symbol} = useContext(ExhibitContext);
  const isExhibitionPage = symbol !== ExhibitionEmptyContext;

  const buttonLabel = useMemo((): string => {
    if (
      nft.purchaseStatus === CheckUserHasNftEnum.USER_HAS_NFT ||
      nft.purchaseStatus === CheckUserHasNftEnum.USER_PAYMENT_PROCESSING
    ) {
      return enableMarketplaceView() ? t('Sell') : t('View NFT');
    }

    if (nft.availability === AvailabilityStatus.COMING_SOON) {
      return t('Coming soon');
    }
    if (nft.availability === AvailabilityStatus.OFFER_ENDED) {
      return t('Offer ended');
    }
    if (nft.availability === AvailabilityStatus.SOLD_OUT || !nft.balance) {
      return t('Sold out');
    }
    if (nft.auctionStatus === AuctionStatus.ACTIVE) {
      return t('Bid');
    }
    if (nft.auctionStatus === AuctionStatus.NOT_STARTED && nft.nftType !== NftType.ACCESS) {
      return t('Coming soon');
    }
    if (availableOnlyOnMarketplace) {
      return isExhibitionPage ? t('Buy on Marketplace') : t('Buy');
    }
    if (isSettled && nft.nftType === NftType.AUCTIONED) {
      if (nft.balance) {
        return t('Buy');
      }
      return t('Sold out');
    }
    return t('Buy');
  }, [
    nft.availability,
    nft.auctionStatus,
    nft.nftType,
    nft.balance,
    nft.purchaseStatus,
    availableOnlyOnMarketplace,
    isExhibitionPage,
    isSettled,
    t,
  ]);

  const {getPendingTransactionForNft} = usePendingTransactionsContext();
  const pendingTransaction = getPendingTransactionForNft(nft.address);

  const disabled = useMemo(
    () =>
      checkIsDisabledNft({
        nft,
        userHasEventAccess,
        hasUserOffer,
        pendingTransaction,
      }),
    [nft, userHasEventAccess, hasUserOffer, pendingTransaction],
  );

  const isLightButton = useMemo(() => {
    if (nft.purchaseStatus === CheckUserHasNftEnum.USER_HAS_NFT) {
      return false;
    }

    if (nft.availability === AvailabilityStatus.COMING_SOON) {
      return true;
    }

    if (nft.nftType !== NftType.AUCTIONED && nft.availability === AvailabilityStatus.SOLD_OUT) {
      return true;
    }

    if (nft.nftType !== NftType.AUCTIONED && nft.availability === AvailabilityStatus.OFFER_ENDED) {
      return true;
    }

    if (
      nft.nftType === NftType.AUCTIONED &&
      nft.auctionStatus === AuctionStatus.NOT_AUCTION &&
      nft.availability === AvailabilityStatus.OFFER_ENDED
    ) {
      return true;
    }

    if (!nft.auction && nft.availability === AvailabilityStatus.OFFER_ENDED) {
      return !availableOnlyOnMarketplace;
    }

    if (nft.auction && nft.auctionStatus === AuctionStatus.ACTIVE) {
      return false;
    }

    return nft.availability === AvailabilityStatus.SOLD_OUT;
  }, [availableOnlyOnMarketplace, nft.auction, nft.auctionStatus, nft.availability, nft.nftType, nft.purchaseStatus]);

  const viewNft = useCallback(
    (nft: NftCardInterface) => {
      analyticService.track(AnalyticEventsEnum.EXHIBIT_NFT_VIEW_CLICKED, {
        nftId: nft.address,
        nftTitle: nft.title,
        artistName: nft.creatorName,
        eventName: event?.title || '',
        nftType: nft.nftType,
      });

      history.push(`/${locale}/home/nft/${nft.id}`, {
        referrer: history.location.pathname,
        nft,
      });
    },
    [event?.title, history, locale],
  );

  const onClick = useCallback(() => {
    if (isExhibitionPage && purchased) {
      return viewNft(nft);
    }

    if (enableMarketplaceView() && nft.purchaseStatus === CheckUserHasNftEnum.USER_HAS_NFT) {
      return sell();
    }

    return buy();
  }, [buy, isExhibitionPage, nft, purchased, sell, viewNft]);

  return useMemo(
    () => ({
      label: buttonLabel,
      disabled: Boolean(disabled),
      onClick,
      loading: false,
      onShare: share,
      isLightButton,
    }),
    [buttonLabel, disabled, isLightButton, onClick, share],
  );
}
