import {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {useParams} from 'react-router-dom';
import {Modal} from '@cere/rxb-template-ui-kit';
import {NftCardInterface} from '@cere/services-types';
import {Box, Grid} from '@material-ui/core';
import {head} from 'lodash';

import {useExhibitNftsBySlug} from 'api/hooks/use-exhibit-nfts-by-slug';
import {ExhibitContext} from 'context/exhibition/exhibit-context';
import {isInterstitialPageList} from 'config/common';
import {AccessNftInfoModal} from 'shared/components/AccessNftInfoModal';
import {NftTicketCardEvent} from 'shared/components/NftTicketCard/NftTicketCardEvent';
import {Condition, ConditionsList, Defaults} from 'shared/components/Conditions';
import {NftsListSkeleton} from 'shared/components/Skeletons';
import {NftExhibitionCard} from 'shared/components/NftCardBig/nft-exhibition-card';
import {NftProvider2} from 'shared/components/Nft/nft-provider-v2';
import {sortNftsByAvailability, sortNftsByNftType} from 'shared/services/nft.service';
import {isAccessNftType, isExclusiveNftType} from 'shared/helpers/auction';
import {isPending} from 'shared/helpers/paymentStatus';
import {EventTimelineStatus} from 'shared/types/event';

import {ExhibitPageRouteParams} from '../../../exhibit-page-route-params';
import {useUserHasEventAccess} from '../../../use-user-has-event-access';
import {NftOrderCard} from './nft-order-card';
import {AccessNftSectionTitle} from './AccessNftSectionTitle';

const canShowExclusiveNfts = (eventStatus: EventTimelineStatus, hasEventAccess: boolean) =>
  eventStatus !== EventTimelineStatus.NOT_STARTED && hasEventAccess;

const EXHIBIT_NFTS_POLLING_INTERVAL = 3000;

type Props = {
  isLoading: boolean;
};

export const ExhibitPageTopCards = ({isLoading}: Props) => {
  const {exhibitSlug} = useParams<ExhibitPageRouteParams>();
  const intervalRef = useRef<number | undefined>(undefined);
  const [showModal, setShowModal] = useState(false);
  const [displayedNfts, setDisplayedNfts] = useState<NftCardInterface[]>([]);
  const {eventTimelineStatus, event} = useContext(ExhibitContext);
  const hasEventAccess = useUserHasEventAccess();
  const artist = event?.cmsCreator;

  const {nfts, loading} = useExhibitNftsBySlug(exhibitSlug);

  const exclusiveNfts = nfts?.filter(isExclusiveNftType);
  const topNfts = event?.allowFreeAccess
    ? exclusiveNfts
    : exclusiveNfts?.filter((nft: NftCardInterface) => !isAccessNftType(nft));
  const accessNfts = nfts?.filter(isAccessNftType);
  const someOfAccessNFTsIsPending = nfts?.filter((nft: NftCardInterface) => isAccessNftType(nft) && isPending(nft));

  const showTicket = !isInterstitialPageList() && exclusiveNfts?.length! > 0 && isAccessNftType(head(exclusiveNfts));

  const updateDisplayedNfts = useCallback(async () => {
    const sectionNfts = canShowExclusiveNfts(eventTimelineStatus, hasEventAccess) ? topNfts! : accessNfts!;
    const sortedNftsByAvailability = sortNftsByAvailability(sectionNfts);
    const sortedNftsByNftType =
      eventTimelineStatus === EventTimelineStatus.STARTED && !hasEventAccess
        ? sortNftsByNftType(sortedNftsByAvailability)
        : sortedNftsByAvailability;

    setDisplayedNfts(sortedNftsByNftType);
  }, [accessNfts, eventTimelineStatus, hasEventAccess, topNfts]);

  useEffect(() => {
    if (someOfAccessNFTsIsPending) {
      intervalRef.current = Number(setInterval(() => updateDisplayedNfts(), EXHIBIT_NFTS_POLLING_INTERVAL));
    }
    return () => {
      clearInterval(intervalRef.current);
    };
  }, [accessNfts, nfts, hasEventAccess, displayedNfts, updateDisplayedNfts, someOfAccessNFTsIsPending]);

  const toggleShowModal = () => {
    setShowModal((show) => !show);
  };

  return (
    <>
      <Box pb="8px">
        <ConditionsList>
          <Condition condition={loading || isLoading}>
            <AccessNftSectionTitle toggleShowModal={toggleShowModal} infoText={'Exclusive NFTs '} />
          </Condition>
          <Condition condition={displayedNfts.length > 0}>
            <AccessNftSectionTitle
              toggleShowModal={toggleShowModal}
              infoText={
                canShowExclusiveNfts(eventTimelineStatus, hasEventAccess)
                  ? `Exclusive NFTs from ${artist?.name}`
                  : `NFT tickets from ${artist?.name}`
              }
            />
          </Condition>
        </ConditionsList>
      </Box>

      <ConditionsList>
        <Condition condition={loading || isLoading}>
          <NftsListSkeleton />
        </Condition>
        <Condition condition={showTicket}>
          <NftTicketCardEvent />
        </Condition>
        <Defaults>
          <Grid container spacing={4}>
            {displayedNfts.map((nft) => (
              <NftProvider2 nft={nft} key={nft.id}>
                <NftOrderCard>
                  <Box p="8px 0">
                    <NftExhibitionCard nft={nft} />
                  </Box>
                </NftOrderCard>
              </NftProvider2>
            ))}
          </Grid>
        </Defaults>
      </ConditionsList>

      <Modal
        open={showModal}
        onClose={toggleShowModal}
        title={
          eventTimelineStatus === EventTimelineStatus.FINISHED ||
          (eventTimelineStatus === EventTimelineStatus.STARTED && hasEventAccess)
            ? 'Exclusive NFTs'
            : 'NFT tickets'
        }
        maxWidth="sm"
      >
        <AccessNftInfoModal
          handleButtonClick={toggleShowModal}
          accessTokenType={
            !(
              eventTimelineStatus === EventTimelineStatus.FINISHED ||
              (eventTimelineStatus === EventTimelineStatus.STARTED && hasEventAccess)
            )
          }
        />
      </Modal>
    </>
  );
};
