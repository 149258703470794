import {useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {addEventToCalendar} from '../services/event.service';
import {CmsExhibition} from '../types/graphql';

const ADD_TO_CALENDAR_QUERY = 'add_to_calendar';

export const useAutoAddEventToCalendar = (event: CmsExhibition) => {
  const history = useHistory();

  useEffect(() => {
    const currentUrl = new URLSearchParams(history.location.search);
    const shouldAddToCalendar = currentUrl.get(ADD_TO_CALENDAR_QUERY) === 'true';

    if (event && shouldAddToCalendar) {
      history.push(history.location.pathname);
      addEventToCalendar(event);
    }
  }, [event, history]);
};
