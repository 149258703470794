import {createContext, PropsWithChildren, useCallback, useContext, useMemo, useRef, useState} from 'react';

export type TorusWalletDialogOptions = {
  showReadyButton?: boolean;
};

type ContextValue = {
  dialog: TorusWalletDialogOptions | null;
  openDialog: (props?: TorusWalletDialogOptions) => Promise<void>;
  closeDialog: () => void;
};

export const TorusWalletDialogContext = createContext<ContextValue>({
  dialog: null,
  openDialog: () => Promise.resolve(),
  closeDialog: () => undefined,
});

const Provider = (props: PropsWithChildren<{}>) => {
  const promiseRef = useRef<Promise<void>>();
  const resolveRef = useRef<() => void>();
  const [dialog, setDialog] = useState<TorusWalletDialogOptions | null>(null);

  const closeDialog = useCallback(() => {
    setDialog(null);
    resolveRef.current?.();
    promiseRef.current = undefined;
    resolveRef.current = undefined;
  }, []);

  const openDialog: ContextValue['openDialog'] = useCallback((props = {}) => {
    promiseRef.current =
      promiseRef.current ||
      new Promise((resolve) => {
        resolveRef.current = resolve;
        setDialog(props);
      });

    return promiseRef.current;
  }, []);

  const value = useMemo(() => ({dialog, openDialog, closeDialog}), [dialog, openDialog, closeDialog]);

  return <TorusWalletDialogContext.Provider {...props} value={value} />;
};

export const useTorusWalletDialog = ({showReadyButton = false}: TorusWalletDialogOptions = {}) => {
  const {dialog, openDialog, closeDialog} = useContext(TorusWalletDialogContext);
  const open = useCallback(
    (options: TorusWalletDialogOptions = {}) => openDialog({showReadyButton, ...options}),
    [openDialog, showReadyButton],
  );

  return {
    isOpen: dialog !== null,
    dialogProps: dialog || {},
    openDialog: open,
    closeDialog,
  };
};

export const TorusWalletDialogProvider = (props: PropsWithChildren<{}>) => {
  return <Provider {...props} />;
};
