import {useFiltersStyles} from './styles';
import {CollapsibleCard} from '../CollapsibleCard';
import {Select, MenuItem, useMediaQuery, Theme} from '@material-ui/core';
import {FiltersContext, useFilter} from '../../../context/filters-context';
import {ChangeEvent, useCallback, useContext} from 'react';
import {VerifiedAuthorBadge} from '../VerifiedAuthorIcon';
import {useLocalization} from '../../hooks/use-locale.hook';
import {CmsCreator} from '@cere/services-types/dist/types';

const CREATOR = 'creatorId';

export const CreatorFilter = () => {
  const {t} = useLocalization();
  const styles = useFiltersStyles();
  const {artistsWithAvailaiableNfts} = useContext(FiltersContext);

  const {filterParams, setFilterValue} = useFilter();
  const isDesktop = useMediaQuery<Theme>((theme) => `${theme.breakpoints.up('lg')}`);

  const updateFilterValue = useCallback(
    (event: ChangeEvent<{value: unknown}>) => {
      setFilterValue(CREATOR, event.target.value === t('All creators') ? '' : (event.target.value as string));
    },
    [setFilterValue, t],
  );

  return (
    <CollapsibleCard
      className={styles.collapsible}
      title={t('Creator')}
      initiallyOpened={isDesktop}
      stayOpened={isDesktop}
      noDivider
    >
      <Select
        className={styles.select}
        variant="outlined"
        value={filterParams[CREATOR] ?? t('All creators')}
        onChange={updateFilterValue}
        MenuProps={{
          disableScrollLock: true,
        }}
      >
        <MenuItem value={t('All creators')}>{t('All creators')}</MenuItem>
        {artistsWithAvailaiableNfts.map((artist: CmsCreator) => (
          <MenuItem key={artist.id} value={artist.id}>
            {artist.name}
            <VerifiedAuthorBadge />
          </MenuItem>
        ))}
      </Select>
    </CollapsibleCard>
  );
};
