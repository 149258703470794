import {makeStyles, Button as MUIButton} from '@material-ui/core';
import clsx from 'clsx';
import colors from '../../../../styles/colors';
import {FC} from 'react';

const useStyles = makeStyles((theme) => ({
  default: {
    height: '48px',
    padding: '4px 16px',
    borderRadius: '100px',
    textTransform: 'none',
    textDecoration: 'none',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',

    [theme.breakpoints.up('md')]: {
      height: '44px',
    },
  },
  secondary: {
    border: `1px solid ${theme.palette.secondary.main}`,
    background: theme.palette.background.default,
    color: theme.palette.secondary.main,
  },
  disabled: {
    background: `${colors.lighter} !important`,
    color: `${colors.disable} !important`,
    cursor: 'default',
    fontSize: '14px',
    lineHeight: '20px',
  },
}));

export type ButtonProps = {
  className?: string;
  secondary?: boolean;
  disabled?: boolean;
  onClick?: () => void;
};

export const Button: FC<ButtonProps> = ({children, className, secondary, disabled, onClick}) => {
  const styles = useStyles();
  return (
    <MUIButton
      className={clsx(className, styles.default, {[styles.secondary]: secondary, [styles.disabled]: disabled})}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </MUIButton>
  );
};
