import {Box, makeStyles, Theme} from '@material-ui/core';
import {Avatar} from '@cere/rxb-template-ui-kit';

import {VerifiedAuthorBadge} from '../../../shared/components/VerifiedAuthorIcon';

const useHeaderStyles = makeStyles<Theme, {bgImgUrl: string}>((theme) => ({
  header: {
    width: '100%',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundImage: ({bgImgUrl}) => bgImgUrl && `url(${bgImgUrl})`,
    [theme.breakpoints.down('md')]: {
      height: '375px',
    },
    [theme.breakpoints.up('md')]: {
      height: '340px',
      borderRadius: '12px',
    },
    [theme.breakpoints.up('lg')]: {
      height: '440px',
      borderRadius: '12px',
    },
  },
}));

interface HeaderProps {
  bgImgUrl: string;
  avatarUrl: string;
  artistName: string;
}

export const Header = ({bgImgUrl, avatarUrl, artistName}: HeaderProps) => {
  const classes = useHeaderStyles({bgImgUrl});
  return (
    <>
      <Box className={classes.header} />
      <Box display="flex" flexDirection="column" alignItems="center" mt="-40px">
        <Avatar src={avatarUrl} size="tablet" />
        <Box fontWeight="700" fontSize="20px" pt="15px">
          {artistName}
          <VerifiedAuthorBadge />
        </Box>
      </Box>
    </>
  );
};
