import {CmsExhibitionNftRelType} from '../../types/graphql';
import {NftAvailability} from '../../services/nft.service';
import {useContext, useMemo} from 'react';
import {UserPaymentHistoryContext} from '../../../context/payments-history/user-payment-history.context';
import {getIsPaymentPending} from '../../helpers/paymentStatus';
import {EventTimelineStatus} from '../../types/event';
import {useNftIsOfferEnded} from './use-nft-is-offer-ended';
import {Exhibition, ExhibitionNft, NftAttributes} from './types';
import {useNftListing} from '../../hooks/use-nft-listing';
import {ExhibitContext} from '../../../context/exhibition/exhibit-context';
import {useNftIsFailed} from '../../hooks/use-nft-is-failed';
import {usePendingTransactionsContext} from '../../../context/pending-transaction-context';
import {UserContext} from 'context/user-context/user-context';
import {getNFTPrimaryOfferQty} from '../../helpers/nfts';
import {useLocalization} from '../../hooks/use-locale.hook';

type Params = {
  event: Exhibition | undefined;
  nft?: ExhibitionNft;
  balance: number;
  availability?: NftAvailability;
  eventStatus: EventTimelineStatus;
};

export function useNftAttributes({nft, balance, availability, eventStatus, event}: Params): NftAttributes {
  const {locale} = useLocalization();
  const {getNftIsPurchased, getPaymentStatuses} = useContext(UserPaymentHistoryContext);
  const nftId = nft?.cmsNft?.creatorNft?.nft_id;
  const {eventTimelineStatus: timelineStatus} = useContext(ExhibitContext);
  const {getPendingTransactionForNft} = usePendingTransactionsContext();
  const failed = useNftIsFailed(nftId);

  const actualAuction = nft?.cmsNft?.creatorNft?.creator_auctions?.find((auction) => !auction.is_settled);
  const auctionSettled = actualAuction ? actualAuction?.is_settled : true;
  const soldOut = nft?.relType === CmsExhibitionNftRelType.AUCTIONED ? auctionSettled : balance === 0;

  const offerEnded = useNftIsOfferEnded({nft, eventStatus, balance});
  const {offers, ownerOffer} = useNftListing({cmsNftIncrementId: nft?.cmsNft.id, start: 0});
  const purchased = useMemo(() => getNftIsPurchased(nftId), [nftId, getNftIsPurchased]);

  const {userData} = useContext(UserContext);
  const hasPendingTransaction = Boolean(
    getPendingTransactionForNft(nft?.cmsNft?.creatorNft?.nft_id) && userData.userPublicKey,
  );

  const counter =
    !soldOut &&
    !offerEnded &&
    nft?.relType === CmsExhibitionNftRelType.ACCESS &&
    timelineStatus === EventTimelineStatus.STARTED
      ? event?.endsAt ?? ''
      : '';

  const primaryOfferBalance = !!nft ? getNFTPrimaryOfferQty(nft.cmsNft) : 0;
  const availableOnlyOnMarketplace =
    (offers.length > 0 && !ownerOffer && (offerEnded || soldOut || primaryOfferBalance === 0)) || purchased;
  const order = useMemo(() => {
    if (purchased) {
      return 1;
    }

    if (nft?.cmsNft.isComingSoon) {
      return 4;
    }

    if (soldOut) {
      return 5;
    }

    if (offerEnded && offers.length === 0) {
      return 6;
    }

    if (
      (availability === NftAvailability.ONGOING && nft?.relType === CmsExhibitionNftRelType.AUCTIONED) ||
      offers.length > 0
    ) {
      return 2;
    }

    if (
      (availability === NftAvailability.ONGOING && nft?.relType === CmsExhibitionNftRelType.LIMITED) ||
      offers.length > 0
    ) {
      return 3;
    }

    if (availability === NftAvailability.COMING_SOON) {
      return 4;
    }

    return 1000;
  }, [availability, nft?.cmsNft.isComingSoon, nft?.relType, offerEnded, offers.length, purchased, soldOut]);

  return useMemo(
    () => ({
      counter,
      purchased,
      order,
      pending: getIsPaymentPending(...getPaymentStatuses(nftId)),
      offerEnded: Boolean(offerEnded),
      failed,
      availability,
      soldOut: Boolean(soldOut),
      link: `/${locale}/home/nft/${nft?.cmsNft?.id}`,
      auctionSettled: Boolean(auctionSettled),
      availableOnlyOnMarketplace: Boolean(availableOnlyOnMarketplace),
      hasPendingTransaction,
    }),
    [
      auctionSettled,
      availability,
      availableOnlyOnMarketplace,
      hasPendingTransaction,
      counter,
      failed,
      getPaymentStatuses,
      nft?.cmsNft?.id,
      nftId,
      offerEnded,
      order,
      purchased,
      soldOut,
      locale,
    ],
  );
}
