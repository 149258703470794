import {useContext} from 'react';
import {Box} from '@material-ui/core';
import {Typography} from '@cere/rxb-template-ui-kit';
import clsx from 'clsx';

import {UserContext} from 'context/user-context/user-context';
import {ExhibitContext} from 'context/exhibition/exhibit-context';
import {useExhibitionPreview} from 'shared/hooks/use-exhibition-preview.hook';

import {useAccessTicket} from '../../../use-access-ticket';
import {ExhibitLiveEnterButton} from '../Exhibition-preview-container/exhibit-live-enter-button';
import {useStyles} from '../Exhibition-preview-container/live-styles';
import {SoldOut} from './sold-out';

type Props = {
  userHasJwtAccess: boolean;
  onPlayVideoClick: () => void;
};

export const FinishedEventPreview = ({onPlayVideoClick, userHasJwtAccess}: Props) => {
  const {event} = useContext(ExhibitContext);
  const {exhibitionPreview} = useExhibitionPreview(event);
  const {userData} = useContext(UserContext);
  const nftPurchasedByUser = useAccessTicket({fromAllNFTs: true});
  const isUserBoughtAnyNft = Boolean(nftPurchasedByUser);

  const isShowVideo = userHasJwtAccess && Boolean(userData.userPublicKey) && isUserBoughtAnyNft;

  const styles = useStyles({exhibitionPreview});
  return (
    <Box className={clsx(styles.exhibitionInfo, styles.finishedExhibitionInfo)}>
      <Box className={styles.exhibitionInfoLeft}>
        <Box className={clsx(styles.exhibitionTitlesSoldOut, styles.notStartedTitles)}>
          <Typography className={styles.eventSubtitle} variant="h5">
            {event?.subtitle}
          </Typography>
          <Box className={styles.exhibitionTitleWithoutTheme}>{event?.title}</Box>
        </Box>
        <SoldOut />
      </Box>

      {isShowVideo && (
        <Box className={styles.finishedExhibitionImageBlock}>
          <Box className={clsx(styles.exhibitionImagePreview, styles.closedExhibitionImagePreview)}>
            <ExhibitLiveEnterButton onClick={onPlayVideoClick} variant="primary" />
          </Box>
        </Box>
      )}
    </Box>
  );
};
