import {useCallback} from 'react';
import {Button, TableBody, TableCell, TableRow} from '@material-ui/core';
import {Typography} from '@cere/rxb-template-ui-kit';
import clsx from 'clsx';
import dayjs from 'dayjs';
import {Waypoint} from 'react-waypoint';
import {NFTListingTableProps} from './NFTListingTable';
import {ReactComponent as CurrencyIcon} from '../../../assets/coins/usd_blue.svg';
import {Avatar} from '../Avatar';
import {useListingsCardStyles} from './styles';
import {NFTOffer, NFTOfferTypes} from '../../hooks/use-nft-listing';
import {useStartCancelingNFTListing} from '../../../context/sell-nft-context';
import {PendingTransactionTypes, usePendingTransactionsContext} from '../../../context/pending-transaction-context';
import {WarningMessage} from '../WarningMessage';
import {useLocalization} from '../../hooks/use-locale.hook';
import {useAuctionFactory} from '../../hooks/use-auction-factory';
import {useSelectedWallet} from '../../../context/use-selected-wallet';
import {BuyFromMinterButton} from '../ActionButtons/BuyFromMinterButton';
import {BidButton} from '../ActionButtons/BidButton';
import {BuyOnMarketplaceButton} from '../ActionButtons/BuyOnMarketplaceButton';
import {TOKEN_DECIMALS} from '../../lib/formatPrice';

export const NFTListingTableBody = ({event, offers, loadMoreData, nft}: NFTListingTableProps) => {
  const {t} = useLocalization();
  const styles = useListingsCardStyles();
  const cancelListing = useStartCancelingNFTListing();
  const auctionFactory = useAuctionFactory();
  const selectedWallet = useSelectedWallet();
  const {getPendingTransactionForNft} = usePendingTransactionsContext();

  const renderSellerData = useCallback(
    (offer: NFTOffer) => {
      if (offer.isPrimaryOffer) {
        return <Avatar name={offer.creator!.name} imageUrl={offer.creator!.avatar.url} creatorId={offer.creator?.id} />;
      }
      if (offer.isOwnerOffer) {
        return <Avatar name={t('You')} />;
      }
      return <Typography className={styles.tableCellText}>{offer.seller}</Typography>;
    },
    [styles.tableCellText, t],
  );

  const cancelNftListing = useCallback(() => cancelListing(nft.address), [nft, cancelListing]);

  const renderColumnCell = useCallback(
    (offer: NFTOffer) => {
      if (!event) {
        return null;
      }
      if (offer.isOwnerOffer) {
        const pendingTransaction = getPendingTransactionForNft(nft.address);
        if (
          pendingTransaction?.type === PendingTransactionTypes.SELL_NFT ||
          pendingTransaction?.type === PendingTransactionTypes.CANCEL_NFT_LISTING
        ) {
          return <WarningMessage message={t('NFT transaction processing')} />;
        }
        return (
          <Button
            className={clsx(styles.tableButton, styles.cancelButton)}
            variant="outlined"
            onClick={cancelNftListing}
          >
            {t('Cancel')}
          </Button>
        );
      }
      if (offer.type === NFTOfferTypes.Auction && nft.auction) {
        const latestBid = nft.price;
        const currentAuction = auctionFactory.createAuctionFromNftData(
          nft.address,
          offer.seller,
          selectedWallet?.publicKey!,
          latestBid,
          nft.auction.bids ?? [],
        );
        return (
          <BidButton
            type="green"
            className={styles.tableButton}
            text={t('Place bid')}
            auction={currentAuction}
            nftId={nft.id}
            nftAddress={nft.address}
            nftImage={nft.image ?? ''}
            nftTitle={nft.title}
            nftCreatorName={nft.creatorName}
            nftEventId={event.id.toString()}
            nftEventSlug={event.slug}
            nftRelType={nft.nftType}
          />
        );
      }
      if (offer.isPrimaryOffer) {
        return (
          <BuyFromMinterButton
            type="green"
            className={styles.tableButton}
            nftId={nft.id}
            nftAddress={nft.address}
            nftImage={nft.image}
            nftTitle={nft.title}
            nftMinterAddress={offer.seller}
            nftUsdPrice={offer.usdPrice}
            nftCreatorName={nft.creatorName}
            nftCollectionAddress={nft.collectionAddress}
            nftAmount={offer.qty}
            nftEventId={event.id.toString()}
            nftEventSlug={event.slug}
            nftRelType={nft.nftType}
          />
        );
      }
      return (
        <BuyOnMarketplaceButton
          type="green"
          className={styles.tableButton}
          nftId={nft.id}
          nftAddress={nft.address}
          nftImage={nft.image}
          nftTitle={nft.title}
          nftMinter={nft.minter}
          nftDescription={nft.description}
          nftCreatorId={nft.creatorId}
          sellerWallet={offer.seller}
          nftUsdPrice={offer.usdPrice}
          nftPrice={offer.usdPrice * TOKEN_DECIMALS}
          nftCreatorName={nft.creatorName}
          nftCollectionAddress={nft.collectionAddress}
          nftAmount={offer.qty}
          nftEventId={event.id.toString()}
          nftEventSlug={event.slug}
          nftRelType={nft.nftType}
          selectedOfferPrice={offer.usdPrice.toFixed(2)}
        />
      );
    },
    [
      event,
      nft.auction,
      nft.id,
      nft.address,
      nft.image,
      nft.title,
      nft.minter,
      nft.description,
      nft.creatorId,
      nft.creatorName,
      nft.nftType,
      nft.price,
      styles.tableButton,
      styles.cancelButton,
      getPendingTransactionForNft,
      cancelNftListing,
      t,
      auctionFactory,
      selectedWallet?.publicKey,
      nft.collectionAddress,
    ],
  );

  return (
    <TableBody>
      {offers.map((offer, index) => (
        <TableRow key={offer.id}>
          <TableCell className={styles.tableBodyCell}>
            <Typography className={styles.firstColumnText}>
              <CurrencyIcon />
              {offer.price.toFixed(2)} <span className="gray">USDC</span>
              {offers.length > 6 && index === offers.length - 2 && <Waypoint onEnter={loadMoreData} />}
            </Typography>
          </TableCell>
          <TableCell className={styles.tableBodyCell}>
            <Typography className={styles.tableCellText}>${offer.usdPrice.toFixed(2)}</Typography>
          </TableCell>
          <TableCell className={styles.tableBodyCell}>{renderSellerData(offer)}</TableCell>
          <TableCell className={styles.tableBodyCell}>
            <Typography className={styles.tableCellText}>{offer.qty}</Typography>
          </TableCell>
          <TableCell className={styles.tableBodyCell}>
            <Typography className={styles.tableCellText}>
              {dayjs(offer.timestamp).format('MM/DD/YYYY, hh:mm:ss A')}
            </Typography>
          </TableCell>
          <TableCell className={clsx(styles.tableBodyCell, styles.tableBodyButtonCell)}>
            {renderColumnCell(offer)}
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  );
};
