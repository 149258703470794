import {NftCardInterface} from '@cere/services-types';

export const isNftCardInterface = (data: any): data is NftCardInterface =>
  Boolean(data) &&
  data.id &&
  typeof data.id === 'string' &&
  data.address &&
  typeof data.address === 'string' &&
  data.creatorId &&
  typeof data.creatorId === 'string' &&
  data.creatorName &&
  typeof data.creatorName === 'string' &&
  data.minter &&
  typeof data.minter === 'string' &&
  data.auctionStatus &&
  typeof data.auctionStatus === 'string' &&
  data.nftType &&
  typeof data.nftType === 'string' &&
  data.title &&
  typeof data.title === 'string' &&
  data.description &&
  typeof data.description === 'string' &&
  data.price &&
  typeof data.price === 'number' &&
  data.priceUsd &&
  typeof data.priceUsd === 'number' &&
  data.priceType &&
  typeof data.priceType === 'string' &&
  data.availability &&
  typeof data.availability === 'string';
