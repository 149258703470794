import {styled} from '@material-ui/core';
import colors from '../../../styles/colors';
import {Typography} from '@cere/rxb-template-ui-kit';
import {ReactComponent as Usd} from '../../../assets/coins/usd.svg';
import {formatPrice} from '../../lib/formatPrice';
import {ReactComponent as WarningIcon} from '../../../assets/icons/warning.svg';

export type WalletBalanceProps = {
  balance?: number;
  caption?: string;
  hint?: string;
  classes?: Partial<Record<'root', string>>;
};

const StyledWarningIcon = styled(WarningIcon)({
  margin: '0 8px 0 15px',
  flexWrap: 'nowrap',
});

const Root = styled('div')({
  borderRadius: '12px',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  border: `1px solid ${colors.lighter}`,
});

const Caption = styled(Typography)({
  color: colors.grey,
  margin: '0 0 8px 15px',
});

const Balance = styled(Typography)({
  display: 'flex',
  alignItems: 'center',
  height: '22px',
  margin: '8px 15px 0 15px',
});

const Hint = styled(Typography)({
  display: 'flex',
  backgroundColor: colors.lighter,
  alignItems: 'center',
  height: '64px',
  verticalAlign: 'top',
});

const HintText = styled(Typography)({
  margin: '0 8px 0 0',
  fontSize: '13px',
});

const CurrencyIcon = styled(Usd)({
  height: '26px',
  width: '26px',
  marginLeft: 'auto',
});

export const WalletBalance = ({balance, caption, hint, classes}: WalletBalanceProps) => (
  <Root className={classes?.root}>
    <Balance>
      <Typography variant="h4">${balance && formatPrice(balance, {currency: ''})}</Typography>
      <CurrencyIcon />
    </Balance>
    <Caption variant="caption2">{caption}</Caption>
    {hint && (
      <Hint variant="body2">
        <StyledWarningIcon />
        <HintText>{hint}</HintText>
      </Hint>
    )}
  </Root>
);
