import {makeStyles} from '@material-ui/core';

import colors from '../../styles/colors';

export const useStyles = makeStyles((theme) => ({
  page: {
    padding: '0 1rem',

    [theme.breakpoints.up('md')]: {
      padding: '0 2.5rem',
    },

    [theme.breakpoints.up('lg')]: {
      padding: '0',
      width: '1280px',
      margin: '0 auto',
    },
  },
  link: {
    textDecoration: 'unset',
    color: theme.palette.secondary.main,
    display: 'block',
    marginTop: '2px',
  },
  popoverText: {
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '16px',

    [theme.breakpoints.up('md')]: {
      display: 'initial',
    },

    [theme.breakpoints.up('lg')]: {
      display: 'block',
    },
  },
  header: {
    margin: '1rem 0 .5rem',
    fontWeight: 700,
    fontSize: '20px',
    lineHeight: '26px',

    [theme.breakpoints.up('md')]: {
      fontSize: '24px',
    },

    [theme.breakpoints.up('lg')]: {
      fontSize: '30px',
    },
  },
  tabs: {
    margin: '1rem -0.5rem 0 -0.5rem',
    background: theme.palette.grey['200'],
    padding: '0.25rem',
    borderRadius: '2rem',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    cursor: 'pointer',

    '& > div': {
      textAlign: 'center',
      height: '34px',
      paddingTop: '7px',
    },

    [theme.breakpoints.up('sm')]: {
      '& > div': {
        height: '40px',
        paddingTop: '10px',
      },
    },
    [theme.breakpoints.up('lg')]: {
      '& > div': {
        height: '44px',
        paddingTop: '12px',
      },
    },
  },
  activeTab: {
    cursor: 'default',
    color: colors.white,
    borderRadius: '2rem',
    background: theme.palette.info.main,
    fontWeight: 600,
  },
  formLine: {
    margin: '2rem 0',

    [theme.breakpoints.up('lg')]: {
      margin: '1.5rem 0 0 0',
    },
  },
  label: {
    display: 'block',
    color: colors.primaryDark,
    fontWeight: 600,
    fontSize: '1.2rem',
    marginBottom: '.8rem',
  },
  networkSelectRoot: {
    '&:hover': {
      background: '#b8e6e6',
    },
  },
  networkSelectOutline: {
    borderColor: theme.palette.info.main,
    color: theme.palette.info.main,
  },
  nftSelector: {
    background: 'transparent',
    border: `2px dashed ${theme.palette.grey['400']}`,
    padding: '1rem 2rem',
    boxSizing: 'border-box',
    borderRadius: '1rem',
    cursor: 'pointer',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '14px',
    fontWeight: 600,
    gap: '0 .5rem',

    '& > span': {
      color: theme.palette.info.main,
    },
  },
  selectNfts: {
    '--padding': '1rem 1.5rem',
    '--size': '155px',

    [theme.breakpoints.up('md')]: {
      '--size': '208px',
    },
  },
  fullScreen: {
    position: 'fixed',
    left: 0,
    right: 0,
    bottom: 0,
    height: '80vh',
    borderRadius: '1rem 1rem 0 0',
  },
  selectTitle: {
    padding: 'var(--padding)',

    '& > *': {
      fontWeight: 600,
      display: 'flex',
      alignItems: 'center',
    },

    '& .close': {
      border: 'none',
      padding: 0,
      background: 'transparent',
      marginLeft: 'auto',
      cursor: 'pointer',
      color: theme.palette.grey['400'],
    },
  },
  selectContent: {
    padding: '6px 0',
    display: 'grid',
    gap: '1.5rem',
    alignItems: 'start',
    gridTemplateColumns: 'repeat(auto-fill, var(--size))',
  },
  selectActions: {
    padding: 'var(--padding)',
    justifyContent: 'space-between',
  },
  dissableButton: {
    '& button': {
      backgroundColor: `${theme.palette.grey[500]} !important`,
    },
  },
  selectButtonRoot: {
    textTransform: 'none',
    padding: '0.5rem 4rem',
    fontSize: '1rem',
  },
  selectButton: {
    background: colors.primaryDark,
    color: colors.white,
    borderRadius: '2rem',

    '&:hover': {
      background: colors.primaryDark,
      color: theme.palette.grey['400'],
    },
  },
  selectorNftRoot: {
    position: 'relative',
    overflow: 'unset',
    boxShadow: '1px 1px 15px rgba(0, 0, 0, .2)',
  },
  selectorNftContent: {
    padding: '0.5rem',

    '&:last-child': {
      paddingBottom: '0.5rem',
    },
  },
  selectorNftMedia: {
    borderRadius: '12px 12px 0 0',
    width: 'var(--size)',
    height: 'var(--size)',
  },
  selectorNftCheckbox: {
    '--size': '24px',
    width: 'var(--size)',
    height: 'var(--size)',
    borderRadius: 'var(--size)',
    background: colors.white,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    zIndex: 10,
    top: '1rem',
    right: '1rem',
    color: theme.palette.primary.main,
    transition: 'opacity 100ms linear',
  },
  selectorNftCheckboxChecked: {
    opacity: 1,
  },
  selectorNftCheckboxUnChecked: {
    opacity: 0,
  },
  selectedNfts: {
    display: 'grid',
    margin: '0.5rem 0 1rem',
    gap: '1rem',
  },

  selectedNftBlock: {
    '--size': '56px',
    boxSizing: 'border-box',
    overflow: 'hidden',
    height: 'var(--size)',
    border: `1px solid ${theme.palette.grey['300']}`,
    borderRadius: '.5rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
    gap: '0 .5rem',

    '& > img': {
      height: 'var(--size)',
      width: 'var(--size)',
      objectFit: 'cover',
    },

    '& > .remove': {
      marginLeft: 'auto',
      color: theme.palette.grey['300'],
    },
  },
  submitBlock: {
    margin: '2rem 0 4rem',
    background: theme.palette.common.white,
    borderRadius: '1rem',
    padding: '1.5rem',

    [theme.breakpoints.up('md')]: {
      borderRadius: '2rem',
    },
  },
  submitBlockContent: {
    margin: '0 auto',
  },
  submitBlockTotal: {
    display: 'flex',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px',
    color: theme.palette.grey[700],
    marginTop: '12px',

    '& .total': {
      marginLeft: 'auto',
      fontWeight: 600,
    },
  },
  submitBlockButton: {
    width: '100%',
    marginTop: '1rem',
    background: theme.palette.common.black,
    borderRadius: '2rem',
    color: theme.palette.common.white,
    textTransform: 'none',
    height: '48px',

    '& .MuiButton-label': {
      color: theme.palette.common.white,
    },

    '&:hover': {
      background: theme.palette.common.black,
      opacity: 0.8,
    },
  },
  noAvailablecoinTransferBlock: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '155px',
  },
  noAvailableTransferTextMobile: {
    color: colors.grey,
    marginTop: '12px',
    fontSize: '14px',
  },
  noAvailableTransferTextDesktop: {
    color: colors.grey,
    marginTop: '12px',
    fontSize: '16px',
  },
  divider: {
    margin: '16px 0 4px 0',
  },
  infoIcon: {
    cursor: 'pointer',
    width: '17px',
    height: '17px',
    marginLeft: '4px',
    marginTop: '1px',

    '& path': {
      fill: theme.palette.info.main,
    },
  },
  tokensBlock: {
    [theme.breakpoints.up('lg')]: {
      display: 'grid',
      gridTemplateColumns: '2fr 1fr',
      gap: '0 40px',
      margin: '40px 0 60px 0',
    },
  },
  sedingAndReceivingBlock: {
    [theme.breakpoints.up('lg')]: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gap: '0 24px',
    },
  },
}));
