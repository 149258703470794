import {makeStyles} from '@material-ui/core';
import colors from '../../../../styles/colors';

export const useStyles = makeStyles((theme) => ({
  listContainer: {
    padding: '0 16px 14px 42px',
  },
  link: {
    textDecoration: 'none',
    color: colors.primaryDark,
    margin: 'auto 0',
  },
  title: {
    lineHeight: '20px',
    fontSize: '14px',
    fontWeight: 500,
    paddingLeft: '12px',
  },
  activeItem: {
    fontWeight: 700,
    color: colors.primaryDark,
  },
  iconContainer: {
    height: '20px',
    width: '22px',
    display: 'flex',
  },
  bellIcon: {
    width: '15px',
    height: '18px',
    margin: 'auto',
  },
  newNotificationsMarker: {
    width: '5px',
    height: '5px',
    borderRadius: '50%',
    marginLeft: '12px',
    backgroundColor: theme.palette.secondary.main,
  },
  menuItem: {
    margin: '-6px 0 16px -18px',
  },
}));
