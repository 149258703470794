import {Box, makeStyles} from '@material-ui/core';

import {useLocalization} from 'shared/hooks/use-locale.hook';

import {Title} from '../Title';
import {Skeleton} from './Skeleton';
import {SocialNetworksInfoSkeleton} from './SocialNetworksInfoSkeleton';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '0 12px 24px 12px',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '0 0 12px 12px',
    overflow: 'hidden',
  },
  description: {
    width: '100%',
    height: '104px',
    borderRadius: '12px',
    marginTop: '12px',
  },
  creatorBox: {
    paddingTop: '20px',
  },
  creatorName: {
    width: '200px',
    height: '36px',
    borderRadius: '4px',
    marginBottom: '12px',
    [theme.breakpoints.up('md')]: {
      borderRadius: '8px',
    },
  },
}));

export const NftCreator = () => {
  const {t} = useLocalization();
  const styles = useStyles();

  return (
    <Box className={styles.root}>
      <Title>{t('Creator')}</Title>
      <Skeleton variant="rect" className={styles.description} />
      <Box className={styles.creatorBox}>
        <Skeleton variant="rect" className={styles.creatorName} />
        <SocialNetworksInfoSkeleton />
      </Box>
    </Box>
  );
};
