import {IAuctionApi} from './IAuctionApi';
import {CmsNft} from '../../shared/types/graphql';
import {Auction} from './Auction';
import {findActualAuction} from '../../shared/components/Nft/utils';
import {AuctionBid} from './types';

export class FreeportSCAuctionsFactory {
  constructor(private readonly auctionsApi: IAuctionApi) {}

  createAuctionFromCmsData(nft: Partial<CmsNft>, userWalletAddress: string): Auction {
    const nftAddress = nft?.creatorNft?.nft_id;
    if (!nftAddress) {
      throw new Error('Cannot create an auction out of nft with empty address field');
    }
    if (!nft.creatorNft) {
      throw new Error('Cannot find required field: creatorNft');
    }
    const cmsAuction = findActualAuction(nft);
    if (!cmsAuction) {
      throw new Error(`Cannot find a valid auction for CmsNft with id: ${nft.id}`);
    }

    const bids: AuctionBid[] = cmsAuction.creator_auction_bids;
    return new Auction(
      this.auctionsApi,
      nftAddress,
      userWalletAddress,
      cmsAuction.seller,
      bids,
      cmsAuction.price,
      nft?.creatorNft.collection_address,
    );
  }

  createAuctionFromNftData(
    nftAddress: string,
    sellerWalletAddress: string,
    userWalletAddress: string,
    price: number,
    bids: AuctionBid[],
    collectionAddress?: string,
  ): Auction {
    return new Auction(
      this.auctionsApi,
      nftAddress,
      userWalletAddress,
      sellerWalletAddress,
      bids,
      price,
      collectionAddress,
    );
  }

  newAuction(
    userWalletAddress: string,
    nftAddress: string,
    startingPrice: number,
    collectionAddress?: string,
  ): Auction {
    return new Auction(
      this.auctionsApi,
      nftAddress,
      userWalletAddress,
      userWalletAddress,
      [],
      startingPrice,
      collectionAddress,
    );
  }

  resetProviders() {
    this.auctionsApi.resetProviders();
  }
}
