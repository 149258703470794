import {useQuery} from '@apollo/client';
import {GET_ARTISTS} from '../queries';
import {CmsCreator} from '../types/graphql';
import {useLocalization} from './use-locale.hook';

export const useArtists = () => {
  const {locale} = useLocalization();
  const {data, loading} = useQuery<{artists: CmsCreator[]}, {locale: string}>(GET_ARTISTS, {variables: {locale}});

  return {
    artists: data?.artists ?? [],
    loading,
  };
};
