import {useCallback, useContext} from 'react';
import {UserContext} from '../../context/user-context/user-context';

export const useConnectNonCustodyWallet = () => {
  const {setIsConnectWalletVisible, nonCustodyWallets} = useContext(UserContext);
  const showConnectWalletModal = useCallback(() => {
    setIsConnectWalletVisible(true);
  }, [setIsConnectWalletVisible]);
  return {
    showConnectWalletModal,
    wallets: nonCustodyWallets,
  };
};
