import {createContext} from 'react';
import {
  CmsExhibitionNftAccess,
  CmsExhibitionNftAuctioned,
  CmsExhibitionNftLimited,
  CmsExhibitionWithTheme,
} from '../../shared/types/graphql';
import {EventTimelineStatus} from '../../shared/types/event';

export const ExhibitionEmptyContext = Symbol('exhibition-empty-context');

type ExhibitContextType = {
  refetch: (variables?: {slug: string}) => Promise<unknown>;
  startPolling: (timeout: number) => unknown;
  stopPolling: () => unknown;
  event?: CmsExhibitionWithTheme;
  accessNfts: CmsExhibitionNftAccess[];
  exclusiveNfts: Array<CmsExhibitionNftLimited | CmsExhibitionNftAuctioned>;
  loading: boolean;
  symbol: Symbol;
  error: string | undefined;
  eventTimelineStatus: EventTimelineStatus;
};

export const ExhibitContext = createContext<ExhibitContextType>({
  refetch: () => Promise.reject('Empty ExhibitQueryContext context'),
  startPolling: () => null,
  stopPolling: () => null,
  accessNfts: [],
  exclusiveNfts: [],
  loading: true,
  error: undefined,
  symbol: ExhibitionEmptyContext,
  eventTimelineStatus: EventTimelineStatus.STARTED,
});
