import {makeStyles} from '@material-ui/core';
import {Typography} from '@cere/rxb-template-ui-kit';
import colors from '../../../styles/colors';
import {ReactComponent as WarningIcon} from '../../../assets/icons/warning_triangle.svg';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  warning: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    textTransform: 'uppercase',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',
    color: colors.darkYellow,
    [theme.breakpoints.up('md')]: {
      justifyContent: 'center',
    },
    '& svg': {
      '--size': '12px',
      minHeight: 'var(--size)',
      minWidth: 'var(--size)',
      marginRight: '4px',
    },
  },
}));

export interface WarningMessageProps {
  className?: string;
  message: string;
}

export const WarningMessage = ({className, message}: WarningMessageProps) => {
  const styles = useStyles();

  return (
    <Typography className={clsx(className, styles.warning)}>
      <WarningIcon /> {message}
    </Typography>
  );
};
