import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import {Box, Button, makeStyles} from '@material-ui/core';
import {NFTModal} from '../NFTModal';
import colors from '../../../styles/colors';
import {useLocalization} from '../../hooks/use-locale.hook';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    margin: '24px -8px 0 -8px',
    [theme.breakpoints.up('md')]: {
      marginTop: '24px',
    },
  },
  button: {
    height: '36px',
    width: '311px',
    borderRadius: '50px',
    border: `1px solid ${colors.primaryDark}`,
  },
  controlsContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
  },
  icon: {
    '--size': '22px',
    height: 'var(--size)',
    width: 'var(--size)',
    color: theme.palette.primary.main,
  },
}));

export interface CancelNFTListingConfirmedModalProps {
  open: boolean;
  onClose: () => void;
}

export const CancelNFTListingConfirmedModal = ({open, onClose}: CancelNFTListingConfirmedModalProps) => {
  const styles = useStyles();
  const {t} = useLocalization();

  return (
    <NFTModal
      open={open}
      icon={<CheckCircleIcon className={styles.icon} />}
      title={t('Listing cancelled')}
      subTitle={t('Your listing on the marketplace has been cancelled')}
      onClose={onClose}
    >
      <Box className={styles.root}>
        <Box className={styles.controlsContainer}>
          <Button className={styles.button} variant="outlined" onClick={onClose}>
            {t('Ok')}
          </Button>
        </Box>
      </Box>
    </NFTModal>
  );
};
