import {makeStyles} from '@material-ui/core';
import colors from '../../../../styles/colors';

export const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    bottom: 'calc(env(safe-area-inset-bottom) + 45px)',
    left: 0,
    width: '100%',

    [theme.breakpoints.up('xs')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '1rem 0',
    },

    [theme.breakpoints.up('md')]: {
      flexDirection: 'row-reverse',
      justifyContent: 'space-around',
      padding: '0 1.5rem',
    },

    [theme.breakpoints.up('lg')]: {
      flexDirection: 'column',
    },
  },
  marker: {
    '--size': '44px',
    color: '#fff',
    display: 'grid',
    padding: '0.5rem',
    width: 'calc(100% - 2rem)',
    textDecoration: 'none',
    borderRadius: '.75rem',
    margin: '0 auto',
    gridTemplateColumns: 'max-content 1fr max-content',
    gap: '0 0.5rem',
    alignItems: 'center',
    cursor: 'pointer',
    background: colors.primaryDark,

    [theme.breakpoints.up('md')]: {
      position: 'static',
      width: '340px',
      margin: 'unset',
    },

    [theme.breakpoints.up('lg')]: {
      position: 'fixed',
      margin: 'unset',
      bottom: 'unset',
      top: '100px',
      left: '36px',
    },
    '& h3': {
      fontSize: '1rem',
      fontWeight: 600,
    },
  },
  markerImage: {
    width: 'var(--size)',
    height: 'var(--size)',
    borderRadius: '6px',
    background: colors.primaryDark,
    overflow: 'hidden',

    '& img': {
      display: 'block',
      width: 'var(--size)',
      height: 'var(--size)',
      objectFit: 'cover',
    },
  },
  markerText: {
    fontSize: '0.8rem',
  },
  markerPrice: {
    alignSelf: 'end',
    fontSize: '1rem',
    fontWeight: 600,
  },
  button: {
    background: 'linear-gradient(87.67deg, #92FE9D 3.35%, #00C9FF 109.43%)',
    borderRadius: '20px',
    height: '40px',
    width: '200px',
    transform: 'scale(1)',
    animation: '$pulse 2s infinite',
    border: '1.5px solid rgba(146, 254, 157, 0.7)',
    borderWidth: '1.5px',
    borderImageSlice: '1',
    color: colors.primaryDark,
    display: 'block',

    '&:hover': {
      border: '1.5px solid #F8F8FA',
    },
  },
  '@keyframes pulse': {
    '0%': {
      transform: 'scale(0.95)',
      boxShadow: '0 0 0 0 rgba(146, 254, 157, 0.7)',
    },
    '70%': {
      transform: 'scale(1)',
      boxShadow: '0 0 0 10px rgba(146, 254, 157, 0)',
    },
    '100%': {
      transform: 'scale(0.95)',
      boxShadow: '0 0 0 0 rgba(146, 254, 157, 0)',
    },
  },
}));
