import {FullCreatorInterface} from '@cere/services-types';

export const isCreator = (data: unknown): data is FullCreatorInterface => {
  const creator = data as FullCreatorInterface;
  return Boolean(
    creator.id &&
      creator.name &&
      creator.about &&
      creator.instagramFollowerNumber &&
      creator.facebookFollowerNumber &&
      creator.youtubeFollowerNumber &&
      creator.tiktokFollowerNumber &&
      creator.avatar?.url &&
      creator.desktopBackgroundImage?.url &&
      creator.tabletBackgroundImage?.url &&
      creator.mobileBackgroundImage?.url,
  );
};
