import {useMemo} from 'react';
import {Box, makeStyles} from '@material-ui/core';
import {Typography} from '@cere/rxb-template-ui-kit';
import {ConnectWalletItem} from '../ConnectWalletDialog/ConnectWalletItem';
import {NonCustodyWalletTypeEnum} from '../../types/non-custody-wallet';
import colors from '../../../styles/colors';
import {isMobileDetect} from '../../lib/isMobile';
import {Trans} from 'react-i18next';
import {useLocalization} from '../../hooks/use-locale.hook';

const useStyles = makeStyles({
  text: {
    color: colors.green,
  },
  description: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '21px',
    color: '#000000',

    '& strong': {
      color: colors.green,
      fontWeight: 400,
    },
  },
});

const errorMessageFromMetamask = 'MetaMask Message Signature: User denied message signature.';

interface ConnectWalletContentProps {
  onConnectTorus: () => void;
  onConnectMetamask: () => void;
  onConnectWalletConnect: () => void;
  isTorusLoading: boolean;
  isMetamaskLoading: boolean;
  isWalletConnectLoading: boolean;
  error: string;
}

export const ConnectWalletContent = ({
  onConnectMetamask,
  onConnectWalletConnect,
  isMetamaskLoading,
  isWalletConnectLoading,
  error,
}: ConnectWalletContentProps) => {
  const isMobile = useMemo(() => isMobileDetect(), []);
  const classes = useStyles();
  const {t} = useLocalization();

  return (
    <Box m="-8px 15px 15px 15px">
      <Typography className={classes.description}>
        <Trans i18nKey="Pick one of our available wallet providers to purchase and bid during the live exhibit. Connect your wallet to <strong>POLYGON</strong> network" />
      </Typography>
      <Box display="flex" justifyContent="space-between" mt="10px" flexDirection="column">
        {!isMobile && (
          <ConnectWalletItem
            type={NonCustodyWalletTypeEnum.METAMASK}
            onClick={onConnectMetamask}
            isLoading={isMetamaskLoading}
          />
        )}

        <ConnectWalletItem
          type={NonCustodyWalletTypeEnum.WALLET_CONNECT}
          onClick={onConnectWalletConnect}
          isLoading={isWalletConnectLoading}
        />
      </Box>
      {error && (
        <Box color={colors.error} pt="5px">
          {error === errorMessageFromMetamask
            ? t('Wallet not connected as signature was not provided, please try again.')
            : error}
        </Box>
      )}
    </Box>
  );
};
