import {getFreeport, getFreeportNonCustody} from '../lib/get-freeport';
import {NonCustodyWalletTypeEnum} from '../types/non-custody-wallet';
import {AppWallet, SupportedWallet} from '../types/supported-wallet';
import {ContractReceipt} from 'ethers';
import {safeTransferFrom} from './token-transfer.service';
import {Marketplace} from '@cere/freeport-sdk/dist/abi-types/Marketplace';
import {Freeport} from '@cere/freeport-sdk';
import {getMarketplace, getMarketplaceNonCustody} from '../lib/get-marketplace';
import {getCollection} from '../lib/get-collection';
import {GAS_LIMIT as gasLimit, GAS_PRICE as gasPrice} from '../../config/common';

export const makeNftOfferCustody = async (
  userToken: string,
  nftId: string,
  price: string,
  collectionAddress?: string,
) => {
  const contract: Marketplace | Freeport = collectionAddress
    ? await getMarketplace(userToken)
    : await getFreeport(userToken);
  const tx = await contract.makeOffer(nftId, price, {
    gasLimit,
    gasPrice,
  });
  return tx.wait();
};

export const makeNftOfferNonCustody = async (
  type: NonCustodyWalletTypeEnum,
  nftId: string,
  price: string,
  collectionAddress?: string,
) => {
  const contract: Marketplace | Freeport = collectionAddress
    ? await getMarketplaceNonCustody(type)
    : await getFreeportNonCustody(type);
  const tx = await contract.makeOffer(nftId, price, {
    gasLimit,
    gasPrice,
  });
  return tx.wait();
};

export const makeNftOffer = async (
  nftId: string,
  price: string,
  wallet: SupportedWallet,
  collectionAddress?: string,
  userToken?: string,
): Promise<ContractReceipt> => {
  if (wallet === AppWallet.DAVINCI && userToken) {
    return makeNftOfferCustody(userToken, nftId, price, collectionAddress);
  }
  return makeNftOfferNonCustody(wallet as NonCustodyWalletTypeEnum, nftId, price, collectionAddress);
};

export const transferNft = async (
  userToken: string,
  userPublicKey: string,
  toAccount: string,
  nftId: string,
  collectionAddress?: string,
): Promise<void> => {
  const contract = collectionAddress
    ? await getCollection(userToken, collectionAddress!)
    : await getFreeport(userToken);

  await safeTransferFrom(contract, {
    from: userPublicKey,
    toAccount: toAccount,
    tokenId: nftId,
    amount: 1,
  });
};
