import useSWR from 'swr';
import {exhibitApi, nftApi} from '../index';
import {useLocalization} from '../../shared/hooks/use-locale.hook';

export const useExhibitWithNfts = (slug?: string) => {
  const {locale} = useLocalization();
  const {data: exhibitWithNfts} = useSWR(`exhibit-${slug}-with-nfts`, async () => {
    if (!slug) {
      return;
    }
    const [exhibit, nfts] = await Promise.all([
      exhibitApi.getExhibitBySlug(slug, locale),
      nftApi.getExhibitNftsBySlug(slug, locale),
    ]);
    return {
      ...exhibit,
      nfts,
    };
  });
  return exhibitWithNfts;
};
