import {createContext, ReactNode, useCallback, useMemo} from 'react';
import dayjs from 'dayjs';
import useSWR from 'swr';
import {PaymentStatusEnum} from '@cere/services-types';
import {PaymentHistoryItem} from '../../shared/types/payment-history-item';
import {getPaymentsHistoryForWallets} from './get-payments-history-for-wallets';
import {useUserWalletsPubKeys} from '../use-user-wallets-pub-keys';
import {getIsPaymentPending, getIsPaymentSuccess} from '../../shared/helpers/paymentStatus';
import {PAYMENT_SUCCESS_VALIDITY_INTERVAL, POLL_INTERVAL} from './constants';
import {DELAY_BETWEEN_FREEPORT_AND_CMS} from '../../config/common';
import {getNftsForWallets} from './get-nfts-owned-by-wallet-mapped';
import {hashToArray} from '../../shared/lib/hash-utils';

type HistoryItem = {
  nftId: string;
  status: PaymentStatusEnum;
  buyerEthAddress: string;
  timestamp: Date;
};

type Context = {
  payments: HistoryItem[];
  getFiatPaymentHistoryItem: (nftId: string | undefined) => PaymentHistoryItem | undefined;
  updatePaymentsHistory: () => Promise<unknown>;
  getPaymentStatuses: (nftId: string | undefined) => PaymentStatusEnum[];
  getNftIsPurchased: (nftId: string | undefined) => boolean;
  getWalletWithNftPublicKey: (nftId: string | undefined) => string | undefined;
};
export const UserPaymentHistoryContext = createContext<Context>({
  getFiatPaymentHistoryItem: () => undefined,
  getNftIsPurchased: () => false,
  getPaymentStatuses: () => [PaymentStatusEnum.INITIAL],
  getWalletWithNftPublicKey: () => undefined,
  payments: [],
  updatePaymentsHistory: () => Promise.resolve(),
});

export type CmsHistoryResult = {
  wallet: string;
  updated_at: string;
  nft_id: {
    nft_id: string;
  };
};

export function UserPaymentHistoryProvider({children}: {children: ReactNode}) {
  const walletsHash = useUserWalletsPubKeys().join('.');
  const historyParams = useMemo(() => {
    const wallets = hashToArray(walletsHash, '.');
    return wallets.length === 0 ? null : [wallets, 'getPaymentsHistoryForWallets'];
  }, [walletsHash]);
  const {data: paymentsHistory, mutate} = useSWR<PaymentHistoryItem[], Error>(
    historyParams,
    getPaymentsHistoryForWallets,
    {
      fallbackData: [],
      errorRetryInterval: 5000,
      errorRetryCount: 10,
      refreshInterval: POLL_INTERVAL,
    },
  );

  const walletParams = useMemo(() => {
    const wallets = hashToArray(walletsHash, '.');
    return wallets.length === 0 ? null : [wallets, 'getNftsForWallets'];
  }, [walletsHash]);

  const {data: walletsResponse, mutate: mutateWallets} = useSWR<CmsHistoryResult[], Error>(
    walletParams,
    getNftsForWallets,
    {
      fallbackData: [],
      errorRetryInterval: 5000,
      errorRetryCount: 10,
      refreshInterval: POLL_INTERVAL,
    },
  );

  const userWalletsHistory = useMemo(() => walletsResponse ?? [], [walletsResponse]);

  const payments: HistoryItem[] = useMemo(() => {
    const davinciHistory = (paymentsHistory ?? []).map((item) => ({...item, timestamp: new Date(item.updatedAt)}));
    return [
      ...davinciHistory.map((davinciItem) => {
        const diffSeconds = dayjs().diff(davinciItem.timestamp, 'seconds');
        return {
          ...davinciItem,
          status:
            getIsPaymentSuccess(davinciItem.status) && diffSeconds < DELAY_BETWEEN_FREEPORT_AND_CMS
              ? PaymentStatusEnum.TOKEN_TRANSFER_PENDING
              : davinciItem.status,
        };
      }),
      ...userWalletsHistory.map((historyItem) => ({
        nftId: historyItem.nft_id.nft_id,
        status: PaymentStatusEnum.PAYMENT_SUCCESS,
        buyerEthAddress: historyItem.wallet,
        timestamp: new Date(historyItem.updated_at),
      })),
    ];
  }, [paymentsHistory, userWalletsHistory]);

  const getFiatPaymentHistoryItem = useCallback(
    (nftId: string | undefined): PaymentHistoryItem | undefined =>
      (paymentsHistory ?? []).find((historyItem) => historyItem.nftId === nftId),
    [paymentsHistory],
  );

  const getPaymentStatuses = useCallback(
    (nftId: string | undefined) =>
      (payments ?? [])
        .filter((historyItem) => historyItem.nftId === nftId)
        .map((historyItem) => {
          const status = historyItem.status ?? PaymentStatusEnum.INITIAL;
          const expiredDate = dayjs(historyItem.timestamp).add(PAYMENT_SUCCESS_VALIDITY_INTERVAL);
          if (getIsPaymentSuccess(status) || getIsPaymentPending(status)) {
            return dayjs().isBefore(expiredDate) ? status : PaymentStatusEnum.INITIAL;
          }
          return status;
        }),
    [payments],
  );

  const getNftIsPurchased = useCallback(
    (nftId: string | undefined) => {
      return userWalletsHistory.some((item) => item.nft_id.nft_id === nftId);
    },
    [userWalletsHistory],
  );

  const getWalletWithNftPublicKey = useCallback(
    (nftId): string | undefined => userWalletsHistory.find((wallet) => wallet.nft_id.nft_id === nftId)?.wallet,
    [userWalletsHistory],
  );

  const updatePaymentsHistory = useCallback(() => {
    return Promise.all([mutate(), mutateWallets()]);
  }, [mutate, mutateWallets]);

  const context = useMemo(
    () => ({
      payments: payments,
      updatePaymentsHistory,
      getPaymentStatuses,
      getFiatPaymentHistoryItem,
      getNftIsPurchased,
      getWalletWithNftPublicKey,
    }),
    [
      getFiatPaymentHistoryItem,
      getNftIsPurchased,
      getPaymentStatuses,
      getWalletWithNftPublicKey,
      payments,
      updatePaymentsHistory,
    ],
  );
  return <UserPaymentHistoryContext.Provider value={context}>{children}</UserPaymentHistoryContext.Provider>;
}
