import {IAuctionSCApi} from './IAuctionSCApi';
import {TransactionResult} from './types';
import {ContractsProvider} from '../blockchain/ContractsProvider';
import {BidAllowanceError} from './errors/BidAllowanceError';

const ONE_BILLION = 1e9 * 1e6;

export class FreeportAuctionApi implements IAuctionSCApi {
  constructor(
    private readonly contractProvider: ContractsProvider,
    private readonly gasLimit: number,
    private readonly gasPrice: number,
    private readonly maxAllowance: number = ONE_BILLION,
  ) {}

  async placeBid(
    userWalletAddress: string,
    sellerWalletAddress: string,
    nftAddress: string,
    price: number,
    collectionAddress: string | null,
  ): Promise<TransactionResult> {
    const contract = await this.getContract(Boolean(collectionAddress));
    await this.checkAllowedBalance(contract.address, userWalletAddress, price);
    const tx = await contract.bidOnAuction(sellerWalletAddress, nftAddress, price, {
      gasLimit: this.gasLimit,
      gasPrice: this.gasPrice,
    });
    await tx.wait();
    return {
      hash: tx.hash,
      timestamp: tx.timestamp,
    };
  }

  async settleAuction(
    sellerWalletAddress: string,
    nftAddress: string,
    collectionAddress: string | null,
  ): Promise<void> {
    const contract = await this.getContract(Boolean(collectionAddress));
    const tx = await contract.settleAuction(sellerWalletAddress, nftAddress, {
      gasLimit: this.gasLimit,
      gasPrice: this.gasPrice,
    });
    await tx.wait();
  }

  async startAuction(
    nftAddress: string,
    startingPrice: number,
    closingTime: number,
    collectionAddress: string | null,
  ): Promise<void> {
    const contract = await this.getContract(Boolean(collectionAddress));
    const tx = await contract.startAuction(nftAddress, startingPrice, closingTime, {
      gasLimit: this.gasLimit,
      gasPrice: this.gasPrice,
    });
    await tx.wait();
  }

  private async checkAllowedBalance(contractAddress: string, buyerWalletAddress: string, price: number): Promise<void> {
    const erc20 = await this.contractProvider.getERC20Contract();
    const allowanceBalance = await erc20.allowance(buyerWalletAddress, contractAddress);
    if (allowanceBalance.toNumber() < price) {
      try {
        await erc20.approve(contractAddress, this.maxAllowance, {
          gasLimit: this.gasLimit,
          gasPrice: this.gasPrice,
        });
      } catch (err) {
        console.error(err);
        throw new BidAllowanceError();
      }
    }
  }

  private getContract(hasCollectionAddress: boolean) {
    if (hasCollectionAddress) {
      return this.contractProvider.getAuctionContract();
    }
    return this.contractProvider.getSimpleAuctionContract();
  }
}
