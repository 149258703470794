import CloseIcon from '@material-ui/icons/Close';
import {IconButton} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import colors from '../../../styles/colors';

const useStyles = makeStyles({
  closeButton: {
    '--size': '30px',
    height: 'var(--size)',
    width: 'var(--size)',
    backgroundColor: colors.lighter,
    '& svg': {
      '--iconSize': '18px',
      height: 'var(--iconSize)',
      width: 'var(--iconSize)',
    },
  },
});

export interface CloseButtonProps {
  onClick: () => void;
}

export const CloseButton = ({onClick}: CloseButtonProps) => {
  const styles = useStyles();

  return (
    <IconButton size="small" className={styles.closeButton} onClick={onClick}>
      <CloseIcon />
    </IconButton>
  );
};
