import {Box, makeStyles} from '@material-ui/core';
import {Typography} from '@cere/rxb-template-ui-kit';

import {formatPrice} from '../../../lib/formatPrice';
import colors from '../../../../styles/colors';
import {getPriceBidCaption} from './helpers/get-price-bid-caption';
import {CreatorAuctionBid} from '../../../types/graphql';
import {useLocalization} from '../../../hooks/use-locale.hook';

interface BidInfoProps {
  price: number;
  bids: CreatorAuctionBid[];
  containerProps?: Record<string, string | number>;
  isAuction: boolean;
  hidePrice?: boolean;
}

const useStyles = makeStyles(() => ({
  bid: {
    color: colors.grey,
  },
  bidCaption: {
    color: colors.lighter,
  },
}));

export const BidInfo = ({bids, price, containerProps = {}, isAuction = true, hidePrice = false}: BidInfoProps) => {
  const {t} = useLocalization();
  const classes = useStyles();

  return (
    <Box maxWidth="130px" width="100%" flex="0 1 130px" {...containerProps}>
      {!hidePrice && (
        <>
          {getPriceBidCaption(isAuction, !!bids?.length)}
          {isAuction && !!bids?.length && (
            <>
              <Typography variant="caption1" component="span" className={classes.bidCaption}>
                {t('Highest bid')}
              </Typography>
              <Typography variant="caption1" component="span" className={classes.bid}>
                {t('({{count}} bids)', {count: bids?.length})}
              </Typography>
            </>
          )}

          <Typography variant="h3" display="block">
            {formatPrice(price)}
          </Typography>
        </>
      )}
    </Box>
  );
};
