import {FC, useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import {NavLink, useHistory} from 'react-router-dom';
import {Box, Divider, makeStyles, Theme, Typography, useMediaQuery, useTheme} from '@material-ui/core';
import {Button} from '@cere/rxb-template-ui-kit';
import clsx from 'clsx';
import {noop} from 'lodash';

import {ReactComponent as BackIcon} from '../../../assets/icons/back-arrow.svg';
import {UserContext} from '../../../context/user-context/user-context';
import UserProfileMenuComponent from '../UserProfileMenu/user-profile-menu.component';
import analyticService, {AnalyticEventsEnum} from '../../services/analytic.service';
import useEventListener from '../../hooks/useEventListener';
import colors from '../../../styles/colors';
import {enableModernHomepage} from '../../../config/common';
import {NotificationsMenuComponent} from '../Notifications/notifications-menu.component';
import {AppContext} from '../../../context/app-context';
import {useLocalization} from '../../hooks/use-locale.hook';

interface PageHeaderProps {
  textWhite?: boolean;
  hideBackButton?: boolean;
  callback?: Function;
  textHeader?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  page: {
    margin: '0',
    transition: 'background .2s linear',
    padding: '16px',

    [theme.breakpoints.up('md')]: {
      padding: '12px 0',
      minHeight: 'unset',
    },
    [theme.breakpoints.up('lg')]: {
      padding: '0',
    },
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    [theme.breakpoints.up('md')]: {
      minHeight: 'unset',
      margin: '0 40px',
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: '1280px',
      minWidth: '1280px',
      margin: '0 auto',
    },
  },
  fixedMenu: {
    background: theme.palette.background.paper,
    position: 'fixed',
    zIndex: 999,
    left: 0,
    right: 0,
    top: 0,
  },
  scrolled: {
    background: colors.scrolledHeader,
  },
  profile: {
    margin: '0',
    padding: '0',
  },
  logo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '0 auto',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    [theme.breakpoints.up('lg')]: {
      margin: '0 auto 0 0',
    },
  },
  logoImg: {
    maxHeight: '30px',
  },
  logoMobile: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },

    margin: '0 auto',
  },
  logoHome: {
    [theme.breakpoints.down('lg')]: {
      paddingLeft: '20px',
    },
  },
  button: {
    color: `${theme.palette.secondary.main} !important`,

    '& *': {
      fontWeight: 600,
    },
  },
  backButton: {
    minWidth: 'unset',
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  textHeader: {
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '26px',
    color: '#000000',
    margin: '0 auto',
    paddingLeft: '64px',
  },
  notFoundHome: {
    paddingLeft: '36px',
  },
  divider: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  comingSoonLabel: {
    display: 'inline',
    marginLeft: '4px',
    borderRadius: '30px',
    color: colors.snowWhite,
    backgroundColor: theme.palette.primary.main,
    width: '98px',
    height: '20px',
    padding: '3px 7px 4px 7px',
    fontWeight: 700,
    fontSize: '12px',
    lineHeight: '14px',
  },
  menuItem: {
    display: 'inline-block',
    fontSize: '14px',
    lineHeight: '20px',
    textDecoration: 'none',
    padding: '26px 10px 26px 10px',
    '&:focus': {
      color: colors.grey,
    },
  },
  activeItem: {
    fontWeight: 600,
    color: colors.primaryDark,
    padding: '26px 10px 5px 10px',
  },
  inActiveItem: {
    fontWeight: 500,
    color: colors.secondaryDark,
  },
  activeItemDesktop: {
    height: '3px',
    backgroundColor: theme.palette.secondary.main,
    borderRadius: '30px 30px 0px 0px',
    marginTop: '18px',
  },
}));

const PageHeader: FC<PageHeaderProps> = ({textWhite = false, hideBackButton, callback = noop, textHeader}) => {
  const {t, locale} = useLocalization();
  const classes = useStyles();
  const theme = useTheme();
  const {userData} = useContext(UserContext);
  const {appConfig} = useContext(AppContext);
  const {push, goBack} = useHistory();
  const [isScrolled, setIsScrolled] = useState(false);
  const headerRef = useRef<HTMLDivElement>(null);
  const [headerHeight, setHeaderHeight] = useState<number>(60);
  const isTablet = useMediaQuery(theme.breakpoints.up('md'));
  const isMobile = useMediaQuery<Theme>((theme) => `${theme.breakpoints.down('sm')}`);
  const isDesktop = useMediaQuery<Theme>((theme) => `${theme.breakpoints.up('lg')}`);

  const onNavigate = useCallback(() => {
    callback && callback();
  }, [callback]);

  const onSignInClick = useCallback(() => {
    analyticService.track(AnalyticEventsEnum.SIGN_IN_HEADER_CLICK);
    push(`/${locale}/home/auth/signin`);
    onNavigate();
  }, [onNavigate, push, locale]);

  const onGoBack = useCallback(() => {
    onNavigate();
    goBack();
  }, [goBack, onNavigate]);

  const onScroll = () => setIsScrolled(isMobile && window.pageYOffset > 0);
  useEventListener('scroll', onScroll);

  useEffect(() => {
    if (headerRef.current) {
      setHeaderHeight(headerRef.current.getBoundingClientRect().height);
    }
  }, [headerRef, isTablet]);

  const {pathname} = useMemo(() => new URL(window.location.href), []);

  return (
    <>
      <div ref={headerRef} className={clsx(classes.page, classes.fixedMenu, isScrolled && classes.scrolled)}>
        <Box className={classes.headerContainer}>
          {!hideBackButton && (
            <Button onClick={onGoBack} className={classes.backButton}>
              <BackIcon />
            </Button>
          )}

          {textHeader && !isDesktop ? (
            <Typography className={clsx(classes.textHeader, userData.userPublicKey && classes.notFoundHome)}>
              {textHeader}
            </Typography>
          ) : (
            <>
              <NavLink
                to={`/${locale}/home`}
                className={clsx(classes.logo, hideBackButton && classes.logoHome)}
                onClick={onNavigate}
              >
                <img src={appConfig.logoFull?.url || ''} alt="logo" className={classes.logoImg} />
              </NavLink>

              <NavLink
                to={`/${locale}/home`}
                className={clsx(classes.logoMobile, hideBackButton && classes.logoHome)}
                onClick={onNavigate}
              >
                <Box color={textWhite ? '#fff' : '#000'}>
                  <img src={appConfig.logoFull?.url || ''} alt="logo" className={classes.logoImg} />
                </Box>
              </NavLink>
            </>
          )}

          {isDesktop && (
            <Box display="flex">
              <Box pr="12px">
                <NavLink
                  className={clsx(
                    classes.menuItem,
                    pathname === `/${locale}/home` ? classes.activeItem : classes.inActiveItem,
                  )}
                  to={`/${locale}/home`}
                >
                  {t('Home')}
                </NavLink>
                {enableModernHomepage() && pathname === `/${locale}/home` && (
                  <Box className={classes.activeItemDesktop} />
                )}
              </Box>
              {enableModernHomepage() && (
                <>
                  <Box pr="12px">
                    <NavLink
                      className={clsx(
                        classes.menuItem,
                        pathname === `/${locale}/marketplace` ? classes.activeItem : classes.inActiveItem,
                      )}
                      to={`/${locale}/marketplace`}
                    >
                      {t('Marketplace')}
                    </NavLink>
                    {pathname === `/${locale}/marketplace` && <Box className={classes.activeItemDesktop} />}
                  </Box>

                  <Box pr="12px">
                    <NavLink
                      className={clsx(
                        classes.menuItem,
                        pathname === `/${locale}/exhibits` ? classes.activeItem : classes.inActiveItem,
                      )}
                      to={`/${locale}/exhibits`}
                    >
                      {t('Exhibits')}
                    </NavLink>

                    {pathname === `/${locale}/exhibits` && <Box className={classes.activeItemDesktop} />}
                  </Box>

                  <Box pr="12px">
                    <NavLink
                      className={clsx(
                        classes.menuItem,
                        pathname === `/${locale}/creators` ? classes.activeItem : classes.inActiveItem,
                      )}
                      to={`/${locale}/creators`}
                    >
                      {t('Creators')}
                    </NavLink>
                    {pathname === `/${locale}/creators` && <Box className={classes.activeItemDesktop} />}
                  </Box>
                </>
              )}
            </Box>
          )}

          {userData.userPublicKey && <NotificationsMenuComponent isDesktop={isDesktop} />}

          {!userData.userPublicKey && isDesktop ? (
            <Button className={classes.button} variant="text" color="secondary" onClick={onSignInClick}>
              {t('Sign in')}
            </Button>
          ) : (
            <UserProfileMenuComponent onClose={onNavigate} />
          )}
        </Box>
      </div>
      <Divider className={classes.divider} />
      <div style={{height: headerHeight}} />
    </>
  );
};

export default PageHeader;
