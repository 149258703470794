import {Box, Button, CircularProgress, makeStyles, Theme} from '@material-ui/core';
import clsx from 'clsx';
import {useLocation, Link} from 'react-router-dom';
import {usePollUserWalletBalance} from '../../../shared/hooks/user-balance.hook';
import {formatPrice, TOKEN_TITLE} from '../../../shared/lib/formatPrice';
import colors from '../../../styles/colors';
import {ReactComponent as CereIcon} from '../../../assets/coins/cere.svg';
import {ReactComponent as UsdIcon} from '../../../assets/coins/usd.svg';
import {SupportedWallet} from '../../../shared/types/supported-wallet';
import {useTorusWallet} from '../../../context/torus-context';
import {useLocalization} from '../../../shared/hooks/use-locale.hook';
import {SupportedWalletTypes} from 'app-v2/models/wallet/types';

const useStyles = makeStyles<Theme, {isTorusWallet?: boolean}>((theme) => ({
  page: {
    backgroundColor: colors.lighter,
    borderRadius: '12px',
    padding: '24px 16px',
  },
  loader: {
    color: 'white',
    margin: 'auto',
  },
  balanceCere: {
    fontSize: '20px',
    fontWeight: 800,
    display: 'flex',
    alignItems: 'center',

    '& svg': {
      width: '46px',
      height: '46px',
      marginLeft: 'auto',
    },
  },
  balanceLegend: {
    fontWeight: 400,
    color: colors.grey,
    fontSize: '12px',
    display: 'block',
  },
  actions: {
    display: 'grid',
    gap: '1rem 0',
    marginTop: '2rem',
    justifyContent: 'stretch',
  },
  link: {
    borderRadius: '50px',
    boxSizing: 'border-box',
    textDecoration: 'none',
    textAlign: 'center',
    fontWeight: 600,
    fontSize: '1rem',
    padding: '12px 40px',

    [theme.breakpoints.up('lg')]: {
      padding: '12px 49px',
    },
  },
  depositLink: {
    background: `linear-gradient(277.13deg, ${colors.buttonSecondary} 11.98%, ${theme.palette.secondary.main} 83.77%)`,
    color: colors.white,
  },
  transferLink: {
    color: colors.primaryDark,
    border: `1px solid currentColor`,
    minWidth: '183px',
  },
  button: {
    height: '48px',
    backgroundColor: colors.primaryDark,
    color: colors.light,
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '22px',
    textTransform: 'none',
    borderRadius: '50px',
    marginBottom: '12px',

    '&:hover': {
      backgroundColor: colors.primaryDark,
    },

    [theme.breakpoints.up('lg')]: {
      marginBottom: 'unset',
      marginRight: '12px',
      padding: '13px 20px',
    },
  },
  buttonsBlock: {
    display: 'flex',
    flexDirection: 'column',

    [theme.breakpoints.up('lg')]: {
      flexDirection: ({isTorusWallet}) => (isTorusWallet ? 'row' : 'column'),
    },
  },
}));

type Props = {
  showDepositButton?: boolean;
  showUsdBalance?: boolean;
  showTokenBalance?: boolean;
  selectedWallet?: SupportedWallet;
  isTorusWallet?: boolean;
  walletName?: string;
};

export const UserWalletBalanceComponent = ({
  showDepositButton = false,
  showUsdBalance = true,
  showTokenBalance = false,
  selectedWallet,
  isTorusWallet = false,
  walletName,
}: Props) => {
  const {t, locale} = useLocalization();
  const classes = useStyles({isTorusWallet});
  const location = useLocation();

  const {walletBalance, isLoading} = usePollUserWalletBalance(selectedWallet);

  const depositLocation = {
    pathname: `/${locale}/home/user/wallet/deposit`,
    state: {from: location.pathname},
  };

  const transferLocation = {
    pathname: `/${locale}/home/user/wallet/transfer`,
    state: {from: location.pathname, wallet: selectedWallet},
  };

  const wallet = useTorusWallet();

  if (isLoading) {
    return (
      <Box className={classes.page} display="flex">
        <CircularProgress className={classes.loader} />
      </Box>
    );
  }

  return (
    <Box className={clsx(classes.page, 'user-wallet-balance-component')}>
      {showTokenBalance && (
        <div className={classes.balanceCere}>
          <div>
            <div>
              {walletBalance.usdc || 0} {TOKEN_TITLE}
            </div>
            <div className={classes.balanceLegend}>
              {walletName !== SupportedWalletTypes.Metamask && t('My ')} {walletName} {t(' balance')}
            </div>
          </div>
          <CereIcon />
        </div>
      )}
      {showUsdBalance && (
        <div className={classes.balanceCere}>
          <div>
            <div>{formatPrice(walletBalance.usdc || '0')}</div>
            <div className={classes.balanceLegend}>
              {walletName !== SupportedWalletTypes.Metamask && t('My ')} {walletName} {t(' balance')}
            </div>
          </div>
          <UsdIcon />
        </div>
      )}
      <div className={classes.actions}>
        {showDepositButton && (
          <Link to={depositLocation} className={clsx(classes.depositLink, classes.link)}>
            {t('Deposit')}
          </Link>
        )}
        <Box className={classes.buttonsBlock}>
          {isTorusWallet && (
            <Button onClick={() => wallet.openWallet()} className={classes.button}>
              {t('Manage my wallet')}
            </Button>
          )}
          <Link to={transferLocation} className={clsx(classes.transferLink, classes.link)}>
            {t('Transfer')}
          </Link>
        </Box>
      </div>
    </Box>
  );
};
