import {useContext, useMemo} from 'react';
import {alpha, Box, CardContent, makeStyles, Theme} from '@material-ui/core';
import {Typography} from '@cere/rxb-template-ui-kit';
import clsx from 'clsx';

import {Card} from '../Card';
import {Condition, ConditionsList} from '../Conditions';
import {GoogleAnalyticsId} from 'analytics-ids';
import {enableMarketplaceView} from '../../../config/common';
import {formatPrice, usdcUnitsToUSD} from '../../lib/formatPrice';
import {useNftCardStyles} from 'shared/components/NFTDetailsCard/styles';
import colors from 'styles/colors';

import {BUTTON_CLASSES} from '../../../pages/HomePage/HomePageBanner/NftBanner/nftModalContent';
import {Trans} from 'react-i18next';
import {useLocalization} from '../../hooks/use-locale.hook';
import {NftActionButton} from '../ActionButtons';
import {NftContext} from '../Nft/nft-context-v2';
import {NftType} from '@cere/services-types';

export interface NftCardProps {
  styles: ReturnType<typeof useNftCardStyles>;
  onCardClick?: () => void;
  className?: string;
  isDarkTheme?: boolean;
}

export const useStyles = makeStyles<Theme, {isDarkTheme?: boolean}>((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    padding: '12px',
    backgroundColor: colors.snowWhite,
    bordeRadius: '20px',
    marginTop: '0px',
  },
  border: {
    border: '1px solid #E0E0E7',
  },
  priceBlock: {
    display: 'flex',
    flexDirection: 'column',
  },
  qty: {
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '24px',
    color: ({isDarkTheme}) => (!isDarkTheme ? colors.lightGrey : colors.snowWhite),
    margin: '4px 0 17px 0',
  },
  qtyAuction: {
    [theme.breakpoints.down('md')]: {
      margin: '4px 0 11px 0',
    },
  },
  title: {
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',
    color: ({isDarkTheme}) => (!isDarkTheme ? 'inherit' : colors.snowWhite),

    [theme.breakpoints.down('md')]: {
      overflowY: 'hidden',
      display: '-webkit-box',
      WebkitLineClamp: 1,
      WebkitBoxOrient: 'vertical',
    },
  },
  card: {
    background: 'rgba(22, 22, 22, 0.6)',
    backdropFilter: 'blur(66px)',
  },
  price: {
    fontWeight: 700,
    fontSize: '20px',
    lineHeight: '26px',
    color: ({isDarkTheme}) => (!isDarkTheme ? colors.primaryDark : colors.snowWhite),
    width: '35%',
    display: 'flex',
    flexDirection: 'row',

    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  ctaButtonContainer: {
    position: 'absolute',
    top: '81px',
    right: '12px',
    minWidth: '90px',
    '& button': {
      minHeight: '34px',
      maxHeight: '34px',
      paddingLeft: '0',
      paddingRight: '0',
      '& p': {
        fontWeight: ({isDarkTheme}) => (!isDarkTheme ? 600 : 400),
        fontSize: '12px',
        lineHeight: '20px',
      },
    },
  },
  content: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  image: {
    overflow: 'hidden',
    height: '100%',
  },
  imageBox: {
    marginRight: '12px',
    borderRadius: '12px',
    minWidth: '100px',
    minHeight: '100px',
    maxWidth: '100px',
    maxHeight: '100px',
    overflow: 'hidden',
  },
  comingSoon: {
    position: 'relative',
    filter: 'blur(5px)',
    '&:after': {
      content: '""',
      position: 'absolute',
      width: '100%',
      height: '100%',
      bottom: 0,
      zIndex: 1,
      background: '#505050',
      opacity: 0.5,
    },
  },
  overlayText: {
    position: 'absolute',
    zIndex: 5,
    fontSize: '14px',
    color: colors.red,
    fontWeight: 'bold',
  },
  comingSoonButton: {
    color: ({isDarkTheme}) => (isDarkTheme ? colors.snowWhite : colors.disable),
    backgroundColor: ({isDarkTheme}) => (isDarkTheme ? colors.disable : colors.lighter),
    minHeight: '34px',
    maxHeight: '34px',
    borderRadius: '50px',
    padding: '7px 0 0 7px',
  },
  comingSoonText: {
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '20px',
  },
  priceDescriptionText: {
    color: ({isDarkTheme}) => (!isDarkTheme ? 'inherit' : colors.snowWhite),
  },
  cardContent: {
    padding: 0,
    flexDirection: 'column',
    display: 'flex',
    width: '100%',
    '--size': '100px',
    maxHeight: 'var(--size)',
  },
  darkButton: {
    background: theme.palette.info.main,
    color: theme.palette.common.white,
    border: 'none',
    minWidth: '72px',
    maxHeight: '36px',

    '& span': {
      maxWidth: '120px',
    },

    [theme.breakpoints.up('md')]: {
      '& span': {
        padding: '0 8px',
        maxWidth: 'unset',
      },
    },

    '&:hover': {
      background: alpha(theme.palette.info.main, 0.6),
    },
    '&.Mui-disabled': {
      background: alpha(theme.palette.info.main, 0.3),
    },
  },
}));

export const NftCardMedium = ({styles, className, isDarkTheme}: NftCardProps) => {
  const {balance, supply, market, relType, price, auction, nft} = useContext(NftContext);
  const {t} = useLocalization();

  const analyticClassName = useMemo(() => {
    if (enableMarketplaceView() && market.owned > 0) {
      return GoogleAnalyticsId.SellNftBtn;
    }
    if (nft && balance) {
      return GoogleAnalyticsId.BuyNftBtn;
    }
    if (relType) {
      return BUTTON_CLASSES[relType];
    }
  }, [balance, nft, market.owned, relType]);

  const isComingSoon = Boolean(nft?.isComingSoon);

  const classes = useStyles({isDarkTheme});

  return (
    <Card className={clsx(classes.wrapper, isDarkTheme ? classes.card : classes.border, className)}>
      <Box className={classes.imageBox}>
        {nft.image !== undefined && (
          <img src={nft.image} className={clsx(isComingSoon && classes.comingSoon, classes.image)} alt="nft" />
        )}
      </Box>
      <CardContent className={clsx(styles.cardContent, isDarkTheme && classes.cardContent, classes)}>
        <Typography className={classes.title}> {isComingSoon ? t('Coming soon') : nft.title}</Typography>
        <Box>
          <ConditionsList multiply>
            <Condition condition={!isComingSoon && market.owned === 0 && relType === NftType.AUCTIONED}>
              <Typography className={clsx(classes.qty, classes.qtyAuction)}>{t('1 Auctioned original')}</Typography>
              <Typography variant="caption1" className={clsx(classes.price)}>
                <Typography variant="h4" component="span" className={styles.priceText}>
                  {auction.priceText}&nbsp;
                </Typography>
                <Typography variant="h4" component="span" className={styles.priceDescriptionText}>
                  {formatPrice(usdcUnitsToUSD(price.units))}
                </Typography>
              </Typography>
            </Condition>
            <Condition condition={!isComingSoon && market.owned === 0 && relType !== NftType.AUCTIONED}>
              <Typography className={classes.qty}>
                <Trans
                  i18nKey="{{leftAmount}}/{{totalAmount}} NFTs Left"
                  values={{leftAmount: balance, totalAmount: supply}}
                  count={balance}
                />
              </Typography>
              <Typography
                variant="h4"
                component="span"
                className={clsx(styles.priceDescriptionText, classes.priceDescriptionText)}
              >
                ${price.usd}
              </Typography>
            </Condition>
            <Condition condition={!isComingSoon && Boolean(nft)}>
              <Box className={classes.ctaButtonContainer}>
                <NftActionButton className={clsx(isDarkTheme && classes.darkButton, analyticClassName)} />
              </Box>
            </Condition>
            <Condition condition={isComingSoon}>
              <Box className={classes.ctaButtonContainer}>
                <Box className={classes.comingSoonButton}>
                  <Typography className={classes.comingSoonText}> {t('Coming soon')}</Typography>
                </Box>
              </Box>
            </Condition>
          </ConditionsList>
        </Box>
      </CardContent>
    </Card>
  );
};
