import {ethers} from 'ethers';
import {enableBiconomy} from '@cere/freeport-sdk';
import jwtDecode, {JwtPayload} from 'jwt-decode';
import {EmbedWallet, WalletEvent, WalletInitOptions, Context} from '@cere/embed-wallet';

import {getIdToken} from '../auth.service';

type IDTokenPayload = JwtPayload & {
  email: string;
};

export type TorusWalletOptions = WalletInitOptions & {
  biconomyApiKey?: string;
  completeUrl?: string;
};

export type TorusWalletEvent = WalletEvent;

export type TopUpOptions = {
  title: string;
  imageUrl: string;
  description: string;
  price: number;
  priceCaption?: string;
};

const ID_TOKEN_LIFETIME = 120; // idToken lifetime - 2 mins
const isIdTokenExpired = (idToken: string) => {
  const {iat = 0} = jwtDecode<IDTokenPayload>(idToken);
  const tokenLifetime = Date.now() / 1000 - iat;

  return tokenLifetime > ID_TOKEN_LIFETIME;
};

export class TorusWallet {
  private idToken?: string;
  private wallet = new EmbedWallet();

  get status() {
    return this.wallet.status;
  }

  constructor(private options: TorusWalletOptions) {}

  async init() {
    if (this.status !== 'not-ready') {
      return;
    }

    await this.wallet.init(this.options);
  }

  async getSigner() {
    if (this.status !== 'connected') {
      await this.authenticate();
    }

    const web3provider = this.getProvider();
    const provider = this.options.biconomyApiKey
      ? await enableBiconomy(web3provider, this.options.biconomyApiKey, this.options.env === 'dev')
      : web3provider;

    return provider.getSigner();
  }

  getAddress() {
    return this.getProvider().getSigner().getAddress();
  }

  async connect() {
    await this.authenticate();

    return this.getAddress();
  }

  async disconnect() {
    await this.wallet.disconnect();
    this.idToken = undefined;
  }

  subscribe(event: WalletEvent, handler: () => void) {
    return this.wallet.subscribe(event, handler);
  }

  switchNetwork() {
    this.wallet.showWallet('settings');
  }

  clearContext() {
    this.wallet.setContext(null);
  }

  topUp(options?: TopUpOptions) {
    const context: Context | undefined = options && {
      banner: {
        thumbnailUrl: options.imageUrl,
        badgeUrl: this.options.context?.app?.logoUrl,
        right: [
          {variant: 'primary', color: 'primary.main', text: `${options.price} USDC`},
          {variant: 'secondary', text: options.priceCaption || ''},
        ],
        content: [
          {variant: 'secondary', text: options.description},
          {variant: 'primary', text: options.title},
        ],
      },
    };

    this.wallet.setContext(context || null);
    this.wallet.showWallet('topup', {
      onClose: () => this.clearContext(),
    });
  }

  openWallet() {
    this.wallet.showWallet();
  }

  private getProvider() {
    const provider = this.wallet.provider;

    if (!provider) {
      throw new Error('[Tor.us] Provider is not ready');
    }

    return new ethers.providers.Web3Provider(provider);
  }

  private async authenticate() {
    if (!this.idToken || isIdTokenExpired(this.idToken)) {
      await this.refreshIdToken();
    }

    const idToken = this.idToken;

    /**
     * Clear current `idToken` since it can only be used once
     */
    this.idToken = undefined;

    try {
      await this.validateAccount();
      await this.wallet.connect({idToken, mode: 'redirect', redirectUrl: this.options.completeUrl});
    } catch (error) {
      await this.refreshIdToken();

      throw error;
    }
  }

  private async validateAccount() {
    if (this.wallet.status !== 'connected') {
      return;
    }

    if (!this.idToken) {
      await this.refreshIdToken();
    }

    const {email} = jwtDecode<IDTokenPayload>(this.idToken!);
    const userInfo = await this.wallet.getUserInfo();

    if (userInfo.email !== email) {
      console.warn(`[Tor.us] Another account connected (${userInfo.email}). Disconnecting...`);

      await this.disconnect();
    }
  }

  private async refreshIdToken() {
    this.idToken = await getIdToken();
  }
}
