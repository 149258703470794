import {useCallback, useEffect} from 'react';
import {useTorusWallet} from '../../../context/torus-context';
import {NFTCTAButton, NFTCTAButtonProps} from '../NFTCTAButton';
import analyticService, {AnalyticEventsEnum} from '../../../shared/services/analytic.service';
import {GoogleAnalyticsId} from '../../../analytics-ids';
import {useLocalization} from '../../hooks/use-locale.hook';

type TopUpContextInfo = {
  price: number;
  imageUrl: string;
  title: string;
  description: string;
};

export type TorusWalletFundButtonProps = Omit<NFTCTAButtonProps, 'text' | 'onClick'> & {
  nftAddress?: string | undefined | null;
  contextInfo?: TopUpContextInfo;
  text?: string;
};

export const TorusWalletFundButton = ({nftAddress = null, text, contextInfo, ...props}: TorusWalletFundButtonProps) => {
  const {t} = useLocalization();
  const textValue: string = text || t('Fund wallet');
  const wallet = useTorusWallet();

  const handleClick = useCallback(() => {
    wallet.topUp(contextInfo && {...contextInfo, priceCaption: t('Required')});

    analyticService.track(AnalyticEventsEnum.TORUS_WALLET_FUND_WALLET_CLICK, {nftId: nftAddress});
  }, [wallet, contextInfo, t, nftAddress]);

  useEffect(
    () => () => {
      wallet.clearContext(); // Remove the wallet banner on the button unmount
    },
    [wallet],
  );

  return <NFTCTAButton {...props} id={GoogleAnalyticsId.TorusWalletFundBtn} text={textValue} onClick={handleClick} />;
};
