import {useMemo} from 'react';
import {Box} from '@material-ui/core';
import {ReactComponent as NotFoundImage} from '../../../assets/not_found.svg';
import {CollapsibleCard} from '../CollapsibleCard';
import {IsLoading} from '../IsLoading';
import {useListingsCardStyles} from './styles';
import {NFTListingTable} from './NFTListingTable';
import {Condition, ConditionsList} from '../Conditions';
import {useNftListing} from '../../hooks/use-nft-listing';
import {useNftContextV2} from '../Nft/nft-context-v2';
import {useLocalization} from '../../hooks/use-locale.hook';
import {AuctionStatus} from '@cere/services-types';

export const NFTListingsCard = () => {
  const {t} = useLocalization();
  const styles = useListingsCardStyles();
  const {nft, event, relType} = useNftContextV2();
  const {loading, offers, primaryOffer, ownerOffer, loadMoreData} = useNftListing({
    cmsNftIncrementId: nft.id,
    start: 0,
  });

  const allOffers = useMemo(() => {
    const total = [...offers];
    const isSettled = nft.auctionStatus === AuctionStatus.SETTLED;
    if (primaryOffer && !isSettled) {
      total.unshift(primaryOffer);
    }
    if (ownerOffer) {
      total.unshift(ownerOffer);
    }
    return total;
  }, [offers, nft.auctionStatus, primaryOffer, ownerOffer]);

  if (!event || !relType) {
    return null;
  }

  return (
    <CollapsibleCard title={t('Listings')}>
      <ConditionsList>
        <Condition condition={loading}>
          <Box className={styles.centeredContainer}>
            <IsLoading />
          </Box>
        </Condition>
        <Condition condition={!loading && !allOffers.length}>
          <Box className={styles.centeredContainer}>
            <NotFoundImage />
          </Box>
        </Condition>
        <Condition condition={!loading && !!allOffers.length}>
          <NFTListingTable event={event} offers={allOffers} loadMoreData={loadMoreData} nft={nft} />
        </Condition>
      </ConditionsList>
    </CollapsibleCard>
  );
};
