import clsx from 'clsx';
import {Dispatch} from 'redux';
import {connect} from 'react-redux';
import {makeStyles} from '@material-ui/core';
import {useLocalization} from '../../../utils/hooks/use-localization';
import colors from '../../../../styles/colors';
import {Button} from '../../primitives/Button';
import {StartPrimaryNFTPurchaseCommand} from '../../../redux/modules/purchase/actions';

const useStyles = makeStyles((theme) => ({
  default: {
    background: `linear-gradient(277.13deg, ${colors.buttonSecondary} 11.98%, ${theme.palette.secondary.main} 83.77%)`,
    color: colors.light,
  },
  green: {
    color: colors.green,
    background: colors.snowWhite,
    border: `1px solid ${colors.green}`,
    fontSize: '12px',
    lineHeight: '20px',
  },
}));

export interface BuyFromMinterButtonProps {
  type?: 'default' | 'green';
  className?: string;
  text?: string;
  disabled?: boolean;
  onClick: () => void;
}

const PlainBuyFromMinterButton = ({type = 'default', className, text, disabled, onClick}: BuyFromMinterButtonProps) => {
  const {t} = useLocalization();
  const styles = useStyles()[type];

  return (
    <Button className={clsx(className, styles)} onClick={onClick} disabled={disabled}>
      {text || t('Buy')}
    </Button>
  );
};

const mapDispatchToProps = (dispatch: Dispatch, {nftId}: {nftId: string}) => ({
  onClick: () => dispatch(StartPrimaryNFTPurchaseCommand.create(nftId)),
});

export const BuyFromMinterButton = connect(() => ({}), mapDispatchToProps)(PlainBuyFromMinterButton);
