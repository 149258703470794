import {IRESTClient} from '../api-clients/IRESTClient';
import {INftsApi} from './INftsApi';
import {NftCardInterface} from '@cere/services-types/dist/types';
import {isNftCardInterface} from '../../utils/type-guards/nfts';
import {NftNotFoundError} from './NftNotFoundError';

export const createNftsApi = (restClient: IRESTClient): INftsApi => {
  const getNftById = async (id: string, locale: string): Promise<NftCardInterface> => {
    const nft = await restClient.makeRequest<NftCardInterface>(
      'get',
      `/nfts/${id}?locale=${locale}`,
      isNftCardInterface,
    );
    if (!nft) {
      throw new NftNotFoundError('id', id);
    }
    return nft;
  };

  return {
    getNftById,
  };
};
