import {useEffect, useState} from 'react';
import useSWR from 'swr';
import {NftCardInterface} from '@cere/services-types';
import {nftApi} from '../index';
import {HomePageNftsRequestParams} from '../nft';
import {useLocalization} from '../../shared/hooks/use-locale.hook';

type SearchParams = {
  sorting?: string;
  minPrice?: number;
  maxPrice?: number;
  date?: number;
  creatorId?: string;
};

export const useMarketplaceNfts = (): {
  loading: boolean;
  nfts: NftCardInterface[] | undefined;
  updateSearchParams: (params: SearchParams) => {} | void;
} => {
  const {locale} = useLocalization();
  const [loading, setLoading] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useState<HomePageNftsRequestParams>({locale});
  const {data: nfts} = useSWR(['home-page-nfts', searchParams], async (_, params) => {
    const nfts = await nftApi.getMarketplacePageNfts(params);
    setLoading(false);
    return nfts;
  });

  useEffect(() => {
    setSearchParams((prevSearchParams) => ({
      ...prevSearchParams,
      locale,
    }));
  }, [locale]);

  const updateSearchParams = (params: SearchParams): void => {
    setLoading(true);
    const {sorting, ...restParams} = params;
    const actualSearchParams: HomePageNftsRequestParams = {...restParams, locale};
    if (sorting) {
      const [fieldName, order] = sorting.split(':');
      actualSearchParams.sorting = {fieldName, order} as HomePageNftsRequestParams['sorting'];
    }
    setSearchParams(actualSearchParams);
    setLoading(false);
  };

  return {
    loading,
    nfts,
    updateSearchParams,
  };
};
