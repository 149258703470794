import {
  Box,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import {noop} from 'lodash';
import {FC, useContext, useMemo} from 'react';
import clsx from 'clsx';
import {cereToUSD, formatPrice} from '../../shared/lib/formatPrice';
import {ReactComponent as UserAvatarCircled} from '../../assets/circle-user-avatar.svg';
import colors from '../../styles/colors';
import {CreatorAuctionBidWithStatus, ExchangeRate} from '../../shared/types/graphql';
import {useQuery} from '@apollo/client';
import {CERE_EXCHANGE_RATE} from '../../shared/queries';
import {UserContext} from '../../context/user-context/user-context';
import {getIsUserBid, sortBids} from '../../shared/helpers/auction';
import {BidHistoryStatus} from '../../shared/components/BidHistoryStatus';
import {useLocalization} from '../../shared/hooks/use-locale.hook';
import {getMaticNetworkUrl} from '../../shared/components/NftDetailsInfo/helpers';
import {getDottedDateAndTime} from '../../shared/lib/utils';

const useStyles = makeStyles<Theme, {isLightTheme: boolean}>((theme) => ({
  cellMinWidth: {
    '& .MuiTableCell-root': {
      '&:nth-child(1)': {
        minWidth: '102px',
        width: '100%',
      },

      '&:nth-child(2)': {
        minWidth: '93px',
        width: '100%',
      },
      '&:nth-child(3)': {
        minWidth: '56px',
        width: '100%',
      },

      '&:nth-child(4)': {
        minWidth: '32px',
        width: '100%',
      },

      [theme.breakpoints.up('sm')]: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        '&:nth-child(1)': {
          minWidth: '23%',
        },
        '&:nth-child(2)': {
          minWidth: '23%',
        },
        '&:nth-child(3)': {
          minWidth: '23%',
        },
        '&:nth-child(4)': {
          minWidth: '23%',
        },
      },

      [theme.breakpoints.up('lg')]: {
        '&:nth-child(1)': {
          minWidth: '25%',
        },
        '&:nth-child(2)': {
          minWidth: '23%',
        },
        '&:nth-child(3)': {
          width: '25%',
        },
        '&:nth-child(4)': {
          minWidth: '25%',
        },
      },
    },
  },
  table: {
    display: 'block',

    '& .MuiTableHead-root': {
      display: 'block',

      '& .MuiTableRow-root ': {
        display: 'flex',
        alignItems: 'center',

        '& .MuiTableCell-root': {
          padding: '0 0 6px 0',
          color: colors.grey,
          borderColor: '#E0E0E7',
          fontWeight: 'bold',
          fontSize: '10px',
          lineHeight: '14px',

          [theme.breakpoints.up('sm')]: {
            fontWeight: 600,
            fontSize: '12px',
            lineHeight: '20px',
          },
        },
      },
    },

    '& .MuiTableBody-root': {
      maxHeight: '205px',
      overflow: 'auto',
      display: 'block',

      '&::-webkit-scrollbar': {
        width: '6px',
        opacity: '0.5',
        borderRadius: '4px',
        backgroundColor: '#8C8C94',
        backgroundClip: 'content-box',
      },

      '&::-webkit-scrollbar-thumb': {
        width: '6px',
        borderRadius: '4px',
        backgroundColor: colors.lightGrey,
        backgroundClip: 'content-box',
      },

      '& .MuiTableRow-root': {
        display: 'flex',
        alignItems: 'center',
        '& .MuiTableCell-root': {
          padding: '8px 5px 8px 0',
          border: 'none',
          display: 'flex',
          fontWeight: 'normal',
          fontSize: '12px',
          lineHeight: '16px',
          color: ({isLightTheme}) => (isLightTheme ? colors.lightGrey : colors.light),

          '& svg': {
            fill: theme.palette.primary.main,
            margin: 'auto 20px auto 0',
          },

          '&:nth-child(4)': {
            '& svg': {
              margin: 'auto 0',
            },
          },

          [theme.breakpoints.up('lg')]: {
            fontWeight: 'normal',
            fontSize: '14px',
            lineHeight: '21px',
          },
        },
      },
    },
  },
  userBidRow: {
    background: 'rgba(60, 188, 172, .12)',
  },
  transactionId: {
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '26px',
    color: ({isLightTheme}) => (isLightTheme ? colors.lightGrey : colors.light),

    [theme.breakpoints.down('md')]: {
      fontSize: '14px',
      lineHeight: '21px',
    },
  },
  bidStatus: {
    paddingLeft: '10px',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));

interface BidHistoryParams {
  isLightTheme: boolean;
  bids: CreatorAuctionBidWithStatus[];
}

interface Column {
  id: string;
  label: string;
  shortLabel?: string;
}

const BidHistory: FC<BidHistoryParams> = ({bids, isLightTheme}: BidHistoryParams) => {
  const {t} = useLocalization();
  const classes = useStyles({isLightTheme: isLightTheme});
  const {userData, nonCustodyWallets} = useContext(UserContext);
  const {data: exchangeRate} = useQuery<{creatorExchangeRate: ExchangeRate}>(CERE_EXCHANGE_RATE);
  const theme = useTheme();
  const isMobile = useMediaQuery<Theme>(() => `${theme.breakpoints.down('sm')}`);
  const columns: Column[] = useMemo(
    () => [
      {id: 'priceInUsdCents', label: t('Bids')},
      {id: 'timestamp', label: t('Timestamp')},
      {id: 'transactionId', label: t('Transaction ID'), shortLabel: t('Trans. ID')},
      {id: 'status', label: t('Status')},
    ],
    [t],
  );
  const networkUrl = getMaticNetworkUrl();

  return (
    <Table className={classes.table}>
      <TableHead>
        <TableRow className={classes.cellMinWidth}>
          {columns.map((column) => (
            <TableCell key={column.id}>{isMobile && column.shortLabel ? column.shortLabel : column.label}</TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {sortBids(bids).map((bid) => {
          const isUserBid = getIsUserBid(bid, userData.userPublicKey || '', nonCustodyWallets);

          return (
            <TableRow onClick={noop} className={clsx(classes.cellMinWidth, isUserBid && classes.userBidRow)}>
              <TableCell key={`price-${bid.id}`}>
                {formatPrice(cereToUSD(bid.price, exchangeRate?.creatorExchangeRate.cere_units_per_penny || 0))}
                {isUserBid && (
                  <Box ml="10px">
                    <Tooltip title={t('Your bid')}>
                      <UserAvatarCircled />
                    </Tooltip>
                  </Box>
                )}
              </TableCell>
              <TableCell key={`timestamp-${bid.timestamp}`}>{getDottedDateAndTime(bid.timestamp)}</TableCell>
              <TableCell key={`transactionId-${bid.id}`}>
                {!!bid.txHash ? (
                  <a href={`${networkUrl}/tx/${bid.txHash}`} className={classes.transactionId}>
                    {bid.id}
                  </a>
                ) : (
                  <Typography className={classes.transactionId}>{bid.id}</Typography>
                )}
              </TableCell>
              <TableCell key={`successBid-${bid.timestamp}`}>
                <BidHistoryStatus bidStatus={bid.bidStatus} />
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

export default BidHistory;
