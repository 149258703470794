import {useContext, SyntheticEvent} from 'react';
import {Box} from '@material-ui/core';
import {UserContext} from '../../../../context/user-context/user-context';
import {WalletItem} from './WalletItem';
import {SupportedWallet} from '../../../types/supported-wallet';
import {useHistory} from 'react-router-dom';
import {noop} from '../../../types/noop';
import {useLocalization} from '../../../hooks/use-locale.hook';

type Props = {
  onClose: noop;
};

export const UserWallets = ({onClose}: Props) => {
  const history = useHistory();
  const {locale} = useLocalization();

  const {nonCustodyWallets, selectedWallet, setSelectedWallet} = useContext(UserContext);

  const handleSelectWallet = (type: SupportedWallet, e: SyntheticEvent) => {
    e.stopPropagation();

    setSelectedWallet(type);
  };

  const handleClickWallet = (type: SupportedWallet, e: SyntheticEvent) => {
    e.stopPropagation();
    history.push(`/${locale}/home/user/wallet?${new URLSearchParams({type}).toString()}`);
    onClose();
  };

  return (
    <Box>
      {nonCustodyWallets.map((wallet) => (
        <WalletItem
          key={wallet.type}
          type={wallet.type}
          onSelect={handleSelectWallet.bind(null, wallet.type)}
          onClick={(e) => handleClickWallet(wallet.type, e)}
          selectedWallet={selectedWallet}
        />
      ))}
    </Box>
  );
};
