import React, {createContext, useCallback, useContext, useEffect, useMemo, useState} from 'react';
import dayjs from 'dayjs';
import {NonCustodyWallet} from '../shared/types/non-custody-wallet';
import {useSelectedWallet} from './use-selected-wallet';
import {UserContext} from './user-context/user-context';
import {makeNftOffer, transferNft} from '../shared/services/sell-nft.service';
import {SetNFTListingPriceModal} from '../shared/components/SetNFTListingPriceModal';
import {NFTListingConfirmedModal} from '../shared/components/NFTListingConfirmedModal';
import {TOKEN_DECIMALS} from '../shared/lib/formatPrice';
import {useNotifications} from '../shared/hooks/use-notifications';
import {CancelNFTListingModal} from '../shared/components/CancelNFTListingModal';
import {CancelNFTListingConfirmedModal} from '../shared/components/CancelNFTListingConfirmedModal';
import {TransferNftToSellModal} from '../shared/components/TransferNftToSellModal';
import {AppWallet} from '../shared/types/supported-wallet';
import {CreatorWalletNft} from '../shared/types/graphql';
import {TransactionProcessingModal} from '../shared/components/TransactionProcessingModal';
import {PendingTransactionTypes, usePendingTransactionsContext} from './pending-transaction-context';
import analyticService, {AnalyticEventsEnum} from '../shared/services/analytic.service';
import {Numberish} from '../shared/types/numberish';
import {useCheckConnectedMetamaskWallet} from '../shared/hooks/use-check-connected-metamask-wallet';
import {useWalletNfts} from '../pages/UserPages/use-wallet-nfts';
import {ALL_WALLET} from '../pages/UserPages/types';
import {useAuctionFactory} from '../shared/hooks/use-auction-factory';
import {UploadFile} from '@cere/services-types';
import {humanReadableError} from '../shared/lib/error-handler';
import {useLocalization} from '../shared/hooks/use-locale.hook';

export type SellingNftDetails = {
  cmsNftIncrementId: Numberish;
  nftId: string;
  nftName: string;
  ownedQty: number;
  collectionAddress?: string;
  nftMedia?: UploadFile;
  quantity: number;
  price: number;
  auctionStartingPrice?: number;
  currency: string;
  creatorWalletNfts?: CreatorWalletNft[];
};

export const isSellingNftDetails = (details: Partial<SellingNftDetails>): details is SellingNftDetails => {
  return (
    details.cmsNftIncrementId !== undefined &&
    details.nftId !== undefined &&
    details.nftName !== undefined &&
    details.ownedQty !== undefined &&
    details.nftMedia !== undefined &&
    details.quantity !== undefined &&
    details.price !== undefined &&
    details.currency !== undefined
  );
};

type ListingModalData = Pick<SellingNftDetails, 'quantity' | 'price' | 'currency' | 'auctionStartingPrice'>;

interface SellNftContextType {
  startNftSell: (
    details: Pick<
      SellingNftDetails,
      | 'nftId'
      | 'nftMedia'
      | 'nftName'
      | 'ownedQty'
      | 'price'
      | 'creatorWalletNfts'
      | 'cmsNftIncrementId'
      | 'collectionAddress'
    >,
  ) => void;
  startListingCanceling: (nftId: string) => void;
}

const SELL_TRANSACTION_PROCESSING_TIME = 225000; // 3.45 minutes

export const SellNftContext = createContext<SellNftContextType>({
  startNftSell: () => {},
  startListingCanceling: () => {},
});

export const useStartNftSale = () => {
  const {startNftSell} = useContext(SellNftContext);
  return startNftSell;
};

export const useStartCancelingNFTListing = () => {
  const {startListingCanceling} = useContext(SellNftContext);
  return startListingCanceling;
};

enum SellNFTModalsTypes {
  TRANSFER_NFT_TO_SELL = 'TRANSFER_NFT_TO_SELL',
  SET_LISTING_PRICE_MODAL = 'SET_LISTING_PRICE_MODAL',
  LISTING_PRICE_CONFIRMED_MODAL = 'LISTING_PRICE_CONFIRMED_MODAL',
  CANCEL_LISTING_MODAL = 'CANCEL_LISTING_MODAL',
  LISTING_CANCELING_CONFIRMED_MODAL = 'LISTING_CANCELING_CONFIRMED_MODAL',
}

export const SellNftContextProvider: React.FC = ({children}) => {
  const {t} = useLocalization();
  const selectedWallet = useSelectedWallet();
  const auctionFactory = useAuctionFactory();
  const {nonCustodyWallets, setSelectedWallet, userData, connectNonCustodialWallet} = useContext(UserContext);
  const {nfts} = useWalletNfts(ALL_WALLET);

  const {error: errorMessage} = useNotifications();
  const [listingNftId, setListingNftId] = useState<string | null>(null);
  const [activeModalType, setActiveModalType] = useState<SellNFTModalsTypes | null>(null);
  const [nftDetails, setNftDetails] = useState<Partial<SellingNftDetails>>({});
  const [isProcessing, setProcessing] = useState<boolean>(false);
  const [authRequired, setAuthRequired] = useState<boolean>(false);
  const [isCountdownVisible, setCountdownVisible] = useState<boolean>(false);
  const {setPendingTransactionForNft, getPendingTransactionsByType} = usePendingTransactionsContext();
  const checkConnectedMetamaskWallet = useCheckConnectedMetamaskWallet();

  const getNonCustodialWalletWithNft = useCallback(
    (creatorWalletNfts: CreatorWalletNft[], details: Partial<SellingNftDetails>) => {
      const userNonCustodialWallets = nonCustodyWallets.map(({publicKey}) => publicKey);
      const nftWalletAddress = creatorWalletNfts!.find(
        ({wallet, quantity, nft_id}) =>
          userNonCustodialWallets.includes(wallet) && nft_id.cmsNft.id === details?.cmsNftIncrementId && quantity > 0,
      );
      return nonCustodyWallets.find((wallet) => wallet.publicKey === nftWalletAddress?.wallet);
    },
    [nonCustodyWallets],
  );

  const changeWalletIfRequired = useCallback(
    (creatorWalletNfts: CreatorWalletNft[], details: Partial<SellingNftDetails>) => {
      const isNftOnSelectedNonCustodialWallet =
        selectedWallet.wallet !== AppWallet.DAVINCI &&
        creatorWalletNfts.find(
          ({wallet, quantity, nft_id}) =>
            wallet === selectedWallet.publicKey && nft_id.cmsNft.id === details?.cmsNftIncrementId && quantity > 0,
        );

      if (isNftOnSelectedNonCustodialWallet) {
        return;
      }

      const nonCustodyWalletWithNft = getNonCustodialWalletWithNft(creatorWalletNfts, details);
      if (!nonCustodyWalletWithNft) {
        return;
      }
      setSelectedWallet(nonCustodyWalletWithNft.type);
    },
    [setSelectedWallet, getNonCustodialWalletWithNft, selectedWallet.publicKey, selectedWallet.wallet],
  );

  useEffect(() => {
    if (authRequired && nonCustodyWallets.length && activeModalType !== SellNFTModalsTypes.SET_LISTING_PRICE_MODAL) {
      setAuthRequired(false);
      setActiveModalType(SellNFTModalsTypes.SET_LISTING_PRICE_MODAL);
    }
  }, [authRequired, activeModalType, nonCustodyWallets.length]);

  const startNftSell = useCallback(
    async (details: Partial<SellingNftDetails>) => {
      try {
        await checkConnectedMetamaskWallet();
      } catch (error) {
        errorMessage(error.message);
        return;
      }

      setNftDetails(details);

      if (!nonCustodyWallets.length) {
        try {
          await connectNonCustodialWallet({showReadyButton: false});
          setAuthRequired(true);
        } catch (error) {
          console.warn(error.message);
        }

        return;
      }

      changeWalletIfRequired(nfts, details);
      setActiveModalType(SellNFTModalsTypes.SET_LISTING_PRICE_MODAL);
    },
    [
      nonCustodyWallets.length,
      nfts,
      changeWalletIfRequired,
      checkConnectedMetamaskWallet,
      errorMessage,
      connectNonCustodialWallet,
    ],
  );

  const sellNft = useCallback(
    async (listingDetails: ListingModalData) => {
      try {
        const walletNft = nfts.find((nft) => nft.nft_id.nft_id === nftDetails.nftId);
        if (walletNft?.wallet !== selectedWallet.publicKey) {
          throw new Error(t('Check your wallet config and try again'));
        }
        const price = listingDetails.price * TOKEN_DECIMALS;
        await makeNftOffer(
          nftDetails.nftId!,
          price.toString(),
          selectedWallet.wallet,
          nftDetails.collectionAddress,
          userData.token,
        );
        analyticService.track(AnalyticEventsEnum.MARKETPLACE_SELL, {
          nftId: nftDetails.cmsNftIncrementId!,
        });
        setCountdownVisible(true);
        await setPendingTransactionForNft({
          nftId: nftDetails.nftId!,
          wallet: selectedWallet?.publicKey!,
          type: PendingTransactionTypes.SELL_NFT,
          qty: nftDetails.ownedQty,
          timestamp: new Date().toString(),
          price,
        });
      } catch (error) {
        errorMessage(t(`Error occurred. {{errorDetails}}`, {errorDetails: humanReadableError(error)}));
        throw error;
      }
    },
    [
      nfts,
      nftDetails,
      selectedWallet.wallet,
      selectedWallet?.publicKey,
      userData.token,
      errorMessage,
      setPendingTransactionForNft,
      t,
    ],
  );

  const startNftAuction = useCallback(
    async ({auctionStartingPrice}: ListingModalData) => {
      const auction = auctionFactory.newAuction(
        selectedWallet.publicKey!,
        nftDetails.nftId!,
        auctionStartingPrice! * TOKEN_DECIMALS,
        nftDetails.collectionAddress,
      );
      const auctionClosingTime = dayjs().add(1, 'year').unix();
      try {
        await auction.start(auctionClosingTime);
        await setPendingTransactionForNft({
          nftId: nftDetails.nftId!,
          wallet: selectedWallet?.publicKey!,
          type: PendingTransactionTypes.SELL_NFT,
          qty: nftDetails.ownedQty,
          timestamp: new Date().toString(),
          price: auctionStartingPrice,
        });
      } catch (e) {
        errorMessage(e.message);
        throw e;
      }
    },
    [
      auctionFactory,
      errorMessage,
      nftDetails.nftId,
      selectedWallet.publicKey,
      nftDetails.collectionAddress,
      nftDetails.ownedQty,
      setPendingTransactionForNft,
    ],
  );

  const handleNftSell = useCallback(
    async (data: ListingModalData, isAuction: boolean) => {
      const listingDetails = {...data};
      if (!isAuction) {
        delete listingDetails.auctionStartingPrice;
      }
      setNftDetails((details) => ({
        ...details,
        ...listingDetails,
      }));
      setProcessing(true);
      try {
        if (isAuction) {
          await startNftAuction(data);
        } else {
          await sellNft(data);
        }
        setActiveModalType(SellNFTModalsTypes.LISTING_PRICE_CONFIRMED_MODAL);
      } catch (e) {
        setProcessing(false);
      }
    },
    [sellNft, startNftAuction, setProcessing, setActiveModalType],
  );

  const startListingCanceling = useCallback((nftId: string) => {
    setListingNftId(nftId);
    setActiveModalType(SellNFTModalsTypes.CANCEL_LISTING_MODAL);
  }, []);

  const cancelNFTListing = useCallback(async () => {
    if (!listingNftId) {
      return;
    }
    setProcessing(true);
    const zeroPrice = '0';
    try {
      await makeNftOffer(listingNftId, zeroPrice, selectedWallet.wallet, nftDetails.collectionAddress, userData?.token);
      await setPendingTransactionForNft({
        nftId: listingNftId!,
        wallet: selectedWallet?.publicKey!,
        type: PendingTransactionTypes.CANCEL_NFT_LISTING,
        qty: nftDetails.ownedQty,
        timestamp: new Date().toString(),
        price: Number(zeroPrice),
      });
      setProcessing(false);
      setActiveModalType(SellNFTModalsTypes.LISTING_CANCELING_CONFIRMED_MODAL);
      setCountdownVisible(true);
    } catch (error) {
      errorMessage(error.message);
      setProcessing(false);
    }
  }, [
    errorMessage,
    selectedWallet.wallet,
    userData?.token,
    listingNftId,
    selectedWallet?.publicKey,
    nftDetails.ownedQty,
    nftDetails.collectionAddress,
    setPendingTransactionForNft,
  ]);

  const closeModalAndClearData = useCallback(() => {
    setProcessing(false);
    setActiveModalType(null);
    setNftDetails({});
    setListingNftId(null);
  }, []);

  const closeCountdownModal = useCallback(() => setCountdownVisible(false), [setCountdownVisible]);

  const onTransferNftApprove = useCallback(
    async (wallet: NonCustodyWallet) => {
      setProcessing(true);

      try {
        await transferNft(
          userData.token!,
          userData.userPublicKey!,
          wallet.publicKey,
          nftDetails.nftId!,
          nftDetails.collectionAddress,
        );

        changeWalletIfRequired(nfts, nftDetails);
        setActiveModalType(SellNFTModalsTypes.SET_LISTING_PRICE_MODAL);
      } catch (e) {
        console.error(e);
        errorMessage(t(`NFT transfer has been failed. {{errorDetails}}`, {errorDetails: humanReadableError(e)}));
      } finally {
        setProcessing(false);
      }
    },
    [userData.token, userData.userPublicKey, nftDetails, changeWalletIfRequired, nfts, errorMessage, t],
  );

  const hasPendingSellTransactions = useMemo(() => {
    const pendingSellTransactions = [
      ...getPendingTransactionsByType(PendingTransactionTypes.SELL_NFT),
      ...getPendingTransactionsByType(PendingTransactionTypes.CANCEL_NFT_LISTING),
    ];
    return pendingSellTransactions.length > 0;
  }, [getPendingTransactionsByType]);

  const value = useMemo<SellNftContextType>(
    () => ({
      startNftSell,
      startListingCanceling,
    }),
    [startNftSell, startListingCanceling],
  );

  return (
    <SellNftContext.Provider value={value}>
      {children}
      {nftDetails.nftId && (
        <>
          <SetNFTListingPriceModal
            nftId={nftDetails.nftId!}
            ownedQty={nftDetails.ownedQty!}
            price={nftDetails.price!}
            open={activeModalType === SellNFTModalsTypes.SET_LISTING_PRICE_MODAL}
            isProcessing={isProcessing}
            onCancel={closeModalAndClearData}
            sellNft={handleNftSell}
          />
          {isSellingNftDetails(nftDetails) && (
            <NFTListingConfirmedModal
              nftDetails={nftDetails}
              open={activeModalType === SellNFTModalsTypes.LISTING_PRICE_CONFIRMED_MODAL}
              onClose={closeModalAndClearData}
            />
          )}
          <TransferNftToSellModal
            open={activeModalType === SellNFTModalsTypes.TRANSFER_NFT_TO_SELL}
            wallets={nonCustodyWallets}
            isProcessing={isProcessing}
            onCancel={closeModalAndClearData}
            onApprove={onTransferNftApprove}
          />
        </>
      )}
      {listingNftId && (
        <>
          <CancelNFTListingModal
            open={activeModalType === SellNFTModalsTypes.CANCEL_LISTING_MODAL}
            isProcessing={isProcessing}
            onCancel={closeModalAndClearData}
            cancelNftListing={cancelNFTListing}
          />
          <CancelNFTListingConfirmedModal
            open={activeModalType === SellNFTModalsTypes.LISTING_CANCELING_CONFIRMED_MODAL}
            onClose={closeModalAndClearData}
          />
        </>
      )}
      <TransactionProcessingModal
        open={isCountdownVisible && hasPendingSellTransactions}
        expectedProcessingTime={SELL_TRANSACTION_PROCESSING_TIME}
        headerText={t('Your transaction is being processed')}
        message={t('Your transaction is currently being processed via our blockchain service.')}
        countdownTitle={t('Approximate time for NFT delivery')}
        onClose={closeCountdownModal}
      />
    </SellNftContext.Provider>
  );
};
