import {memo, useCallback, useMemo, useState} from 'react';
import {Box, Button} from '@material-ui/core';
import {FullCreatorInterface} from '@cere/services-types';

import {PageLayout} from 'shared/components/PageLayout';

import {MainTitle, SubTitle} from '../../components/primitives/Title';
import {PageContainer} from '../../components/shared/PageContainer';
import {NftFilters} from '../../components/shared/NftFilters';
import type {FilterParams} from '../../components/shared/NftFilters/types';
import {SortByButton} from '../../components/shared/SortByButton';
import {NftsListSkeleton, CuratedRowHeaderSkeloton, NftCollectiblesSkeleton} from '../../components/shared/Skeletons';
import {SortingVariants, SortingOrder} from '../../types/filters';

import {TrendingListings} from './components/TrendingListings';
import {NftCollectibles} from './components/NftCollectibles';
import {EmptyNftCollectibles} from './components/EmptyNftCollectibles';
import {useStyles} from './styles';
import {useLocalization} from '../../utils/hooks/use-localization';
import {useOnComponentRendered} from '../../utils/hooks/use-on-component-rendered';

type Props = {
  isFeaturedListLoading: boolean;
  featuredNftsIds: string[];
  isNftListLoading: boolean;
  collectableNftsIds: string[];
  creators: FullCreatorInterface[];
  hideShowMoreButton: boolean;
  loadData: () => void;
};

export const MarketplacePageView = memo(
  ({
    isFeaturedListLoading,
    featuredNftsIds,
    isNftListLoading,
    collectableNftsIds,
    creators,
    hideShowMoreButton,
    loadData,
  }: Props) => {
    const {t} = useLocalization();
    const styles = useStyles();

    useOnComponentRendered(loadData);

    const onShowMoreClick = useCallback(() => {
      // TODO
    }, []);

    const [filterParams, setFilterParams] = useState<FilterParams>({});

    const setCreatorFilter = useCallback((creator: string) => setFilterParams((prev) => ({...prev, creator})), []);
    const setFromFilter = useCallback((from: string) => setFilterParams((prev) => ({...prev, from})), []);
    const setToFilter = useCallback((to: string) => setFilterParams((prev) => ({...prev, to})), []);
    const setDateFilter = useCallback((date: number) => setFilterParams((prev) => ({...prev, date})), []);

    const clearFilters = useCallback(() => setFilterParams({}), []);
    const onApplyFilters = useCallback(() => {
      // TODO
    }, []);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const setSortingOrder = useCallback((variant: SortingVariants, order: SortingOrder) => {
      // TODO and remove comments for unused variable
    }, []);

    const rightFilterElement = useMemo(() => <SortByButton setSortingOrder={setSortingOrder} />, [setSortingOrder]);

    return (
      <PageLayout hideBackButton>
        <PageContainer>
          <Box className={styles.titlesBox}>
            <MainTitle>{t('Marketplace')}</MainTitle>
            <Box mt="4px" mb="20px" width="75%">
              <SubTitle>
                {t(
                  'A gas-free marketplace for verified DaVinci NFTs on Polygon. Buy, sell, and auction valuable NFTs that unlock unique NFT experiences.',
                )}
              </SubTitle>
            </Box>
          </Box>

          <Box className={styles.trendingListingsBox}>
            {isFeaturedListLoading ? (
              <>
                <CuratedRowHeaderSkeloton title={t('Trending listings')} />
                <NftsListSkeleton />
              </>
            ) : (
              <TrendingListings ids={featuredNftsIds} />
            )}
          </Box>

          <Box className={styles.nftCollectiblesBox}>
            <NftFilters
              title={t('NFT collectibles')}
              subTitle={t('Exclusive and verified digital collectibles which unlock unseen experiences of creators')}
              hideFilters={isNftListLoading || collectableNftsIds.length === 0}
              rightButton={rightFilterElement}
              creators={creators}
              filterParams={filterParams}
              setCreatorFilter={setCreatorFilter}
              setFromFilter={setFromFilter}
              setToFilter={setToFilter}
              setDateFilter={setDateFilter}
              clearFilters={clearFilters}
              applyFilters={onApplyFilters}
            />

            {isNftListLoading ? (
              <NftCollectiblesSkeleton xs={12} md={6} lg={4} />
            ) : collectableNftsIds.length ? (
              <NftCollectibles ids={collectableNftsIds} />
            ) : (
              <EmptyNftCollectibles />
            )}

            {!hideShowMoreButton && (
              <Box className={styles.showMoreBox}>
                <Button
                  variant="contained"
                  color="default"
                  className={styles.showMoreButton}
                  disabled={isNftListLoading}
                  onClick={onShowMoreClick}
                >
                  {t('Show more NFTs')}
                </Button>
              </Box>
            )}
          </Box>
        </PageContainer>
      </PageLayout>
    );
  },
);
