import {styled} from '@linaria/react';
import {Link} from 'react-router-dom';
import {css} from '@linaria/core';

export const StyledLink = styled(Link)`
  button > * {
    pointer-events: none;
  }
`;

export const link = css`
  text-decoration: none;
  color: #161616;
`;
