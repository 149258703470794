import {useLocalization} from '../../shared/hooks/use-locale.hook';
import useSWR from 'swr';
import {nftApi} from '../index';
import {useState} from 'react';

export const useExhibitNftsBySlug = (slug: string) => {
  const {locale} = useLocalization();
  const [loading, setLoading] = useState<boolean>(false);
  const {data: nfts} = useSWR(`exhibit-${slug}-nfts`, async () => {
    const nfts = await nftApi.getExhibitNftsBySlug(slug, locale);
    setLoading(false);
    return nfts;
  });

  return {
    loading,
    nfts,
  };
};
