import {ExhibitionBFF} from 'pages/HomePage/types';
import {useState} from 'react';
import useSWR from 'swr';
import {exhibitApi} from '../index';
import {useLocalization} from '../../shared/hooks/use-locale.hook';

const INTERVAL_TIME_MS = 5000;

export const useHomePageExhibits = (): {
  loading: boolean;
  exhibits: ExhibitionBFF[] | undefined;
} => {
  const [loading, setLoading] = useState<boolean>(true);
  const {locale} = useLocalization();
  const {data: exhibits} = useSWR(
    ['home-page-exhibits'],
    async () => {
      const creators = await exhibitApi.getHomePageExhibits(locale);
      setLoading(false);
      return creators;
    },
    {
      errorRetryInterval: INTERVAL_TIME_MS,
    },
  );

  return {
    loading,
    exhibits,
  };
};
