import {StoreModule} from '../../base/types';
import {MARKETPLACE_PAGE_MODULE_NAME} from './constants';
import {marketplacePageModuleReducer} from './reducers';
import {loadMarketplaceDataMiddleware} from './middlewares';
import {IMarketplaceNftsApi} from '../../../api/marketplace-page/IMarketplaceNftsApi';
import {ICreatorsApi} from '../../../api/creators/ICreatorsApi';

export const createMarketplacePageModule = (nftsApi: IMarketplaceNftsApi, creatorsApi: ICreatorsApi): StoreModule => ({
  title: MARKETPLACE_PAGE_MODULE_NAME,
  moduleReducer: marketplacePageModuleReducer,
  middlewares: [loadMarketplaceDataMiddleware(nftsApi, creatorsApi)],
});
