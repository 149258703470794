import {ReactElement} from 'react';
import {Grid} from '@material-ui/core';
import {css} from '@linaria/core';
import {Typography} from '@cere/rxb-template-ui-kit';

import {ExhibitsRowSkeleton} from 'shared/components/Skeletons';
import {EventCardV2} from 'shared/components/EventCardBig/EventCardV2';

import {useLocalization} from 'shared/hooks/use-locale.hook';
import {sortAllEvents} from 'shared/services/event.service';
import {CmsExhibition} from 'shared/types/graphql';
import {ExhibitionBFF} from 'pages/HomePage/types';

export type Props = {
  exhibitions?: (CmsExhibition | ExhibitionBFF)[];
  loading: boolean;
  noAvatar?: boolean;
  isSorted?: boolean;
};

const flex = css`
  display: flex;
  justify-content: center;
  min-height: 270px;
`;

export function ExhibitionsList({loading, exhibitions, noAvatar, isSorted}: Props): ReactElement {
  const {t} = useLocalization();

  if (loading) {
    return (
      <Grid item xs={12} className={flex}>
        <ExhibitsRowSkeleton />
      </Grid>
    );
  }

  if (!exhibitions?.length) {
    return (
      <Grid item xs={12} className={flex}>
        <Typography variant="h4" className="text-center w-100">
          {t('No events for now')}
        </Typography>
      </Grid>
    );
  }

  const sortedExhibitions = isSorted ? sortAllEvents(exhibitions) : [...exhibitions];

  return (
    <>
      {sortedExhibitions.map((exhibition) => (
        <Grid item key={exhibition.id} xs={12} sm={6} lg={4}>
          <EventCardV2 event={{...exhibition} as any} noAvatar={noAvatar} />
        </Grid>
      ))}
    </>
  );
}
