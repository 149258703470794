import {makeStyles} from '@material-ui/core';
import colors from '../../styles/colors';

export const useSharedStyles = makeStyles((theme) => ({
  page: {
    padding: '0 15px',

    [theme.breakpoints.up('md')]: {
      maxWidth: '688px',
      minWidth: '688px',
      margin: '0 auto',
      padding: '0',
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: '832px',
      minWidth: '832px',
    },
  },
  balance: {
    margin: '16px 0',

    [theme.breakpoints.up('md')]: {
      width: '343px',
    },
  },
  title: {
    display: 'block',
    fontSize: '20px',
    fontWeight: 'bold',
    paddingTop: '1.5rem',
    margin: '0',
  },
  walletSelector: {
    display: 'flex',
    margin: '.2rem 0 .5rem -0.2rem',
    alignItems: 'baseline',
    justifyContent: 'flex-start',
    flexFlow: 'row wrap',
    gap: `${theme.spacing(0.5)}px ${theme.spacing(0.5)}px`,
  },
  walletItem: {
    padding: '0 .8rem !important',

    '&.active': {
      background: colors.primaryDark,
      color: colors.white,
    },
  },
  walletItemActive: {
    paddingLeft: 'var(--padding)',
    paddingRight: 'var(--padding)',
    textTransform: 'capitalize',
    background: colors.primaryDark,
    color: colors.white,
  },
}));
