import {useCallback, useEffect, useState} from 'react';
import * as Sentry from '@sentry/react';
import {getUrlByEmailAndNftId} from '../services/payment.service';
import {CmsExhibitionIdSlugOnly} from '../types/graphql';
import {unwrap} from '../lib/unwrap';
import {useExhibitionPreviewKey} from './use-preview-mode-hooks';
import {Numberish} from '../types/numberish';
import {useLocalization} from './use-locale.hook';
import {webAppUrl} from '../../config/common';

type GetPaymentUrlParams = {
  eventName?: string;
  previewKey?: string;
  cmsNftIncrementId?: Numberish;
  nftId?: string;
  locale: string;
};

export const getPaymentUrl = ({
  eventName,
  previewKey,
  cmsNftIncrementId,
  nftId,
  locale,
}: GetPaymentUrlParams): {path: string; successUrl: string; cancelUrl: string} => {
  if (!eventName && !cmsNftIncrementId) {
    return {path: '', successUrl: '', cancelUrl: ''};
  }
  const successUrl = new URL(webAppUrl());
  successUrl.searchParams.append('paymentStatus', 'success');
  if (nftId) {
    successUrl.searchParams.append('nftId', nftId);
  }
  const cancelUrl = window.location.href;
  if (cmsNftIncrementId) {
    const nftDetailsPath = `/${locale}/home/nft/${cmsNftIncrementId}`;
    successUrl.pathname = nftDetailsPath;
    return {
      path: nftDetailsPath,
      successUrl: successUrl.href,
      cancelUrl,
    };
  }

  const shouldRedirectToExhibition = window.location.href.includes(`event=${eventName}`);

  const path = shouldRedirectToExhibition ? `/${locale}/home/exhibit/${eventName}` : window.location.pathname;
  successUrl.pathname = path;
  if (previewKey) {
    successUrl.searchParams.append('preview_key', previewKey);
  }

  return {path, successUrl: successUrl.href, cancelUrl};
};

export const getPopupPaymentUrl = (locale: string): {successUrl: string; cancelUrl: string} => {
  const link = new URL(webAppUrl());
  link.hash = `/${locale}/service/callback`;
  const cancelUrl = link.href;
  link.searchParams.append('paymentStatus', 'success');
  const successUrl = link.href;
  return {successUrl, cancelUrl};
};

export const useFetchPaymentLink = (
  email: string | undefined,
  nftId: string | undefined,
  quantity: number,
  event: CmsExhibitionIdSlugOnly,
): {eventUrl: string; error: string; url: string} => {
  const {t, locale} = useLocalization();
  const [url, setUrl] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [eventUrl, setEventUrl] = useState<string>('');
  const eventName: string = new URLSearchParams(window.location.search).get('event') ?? '';
  const previewKey = useExhibitionPreviewKey();

  const {path} = getPaymentUrl({eventName, locale});

  const loadData = useCallback(async () => {
    try {
      setEventUrl(`${webAppUrl()}${path}`);
      const fetchedUrl = await getUrlByEmailAndNftId({
        buyerEmail: unwrap(email),
        nftId: unwrap(nftId),
        quantity,
        event,
        previewKey,
        locale,
      });

      setUrl(fetchedUrl);
    } catch (e) {
      setError(t('Oops, something unexpected happened. The team is notified. Please, try again later!'));
      Sentry.captureException(e);
      console.error('Error occurred in useFetchStripeLink call', e.message);
    }
  }, [email, nftId, path, event, previewKey, quantity, t, locale]);

  useEffect(() => {
    if (!email || !nftId) return;
    loadData();
  }, [loadData, email, nftId]);

  return {
    eventUrl,
    url,
    error,
  };
};

export const usePaymentStatus = (successCallback: () => void, errorCallback: () => void) => {
  const [processed, setProcessed] = useState<boolean>(false);
  const paymentStatus = '' as string;

  useEffect(() => {
    if (!processed && paymentStatus && paymentStatus === 'success') {
      successCallback();
      setProcessed(true);
    }

    if (!processed && paymentStatus && paymentStatus === 'error') {
      errorCallback();
      setProcessed(true);
    }
  }, [paymentStatus, successCallback, errorCallback, processed]);
};
