import {makeStyles, Theme} from '@material-ui/core';
import colors from '../../../styles/colors';

export const useGettingStartedStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    padding: 'var(--padding)',
    paddingBottom: '52px',

    [theme.breakpoints.up('md')]: {
      padding: '0 0 52px 0',
    },

    [theme.breakpoints.up('lg')]: {
      padding: 'var(--padding)',
      paddingBottom: '96px',
    },
  },
  header: {
    fontSize: '20px',
    fontWeight: 'bold',
    marginBottom: '16px',

    [theme.breakpoints.up('lg')]: {
      fontSize: '36px',
      marginBottom: '32px',
    },
  },
  stepsWrapper: {
    display: 'flex',
    width: '100%',
    height: '100%',
    flexDirection: 'column',

    [theme.breakpoints.up('lg')]: {
      height: '130px',
    },

    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row',
    },
  },
  stepWrapper: {
    display: 'flex',
    background: colors.snowWhite,
    flexGrow: 1,
    marginRight: 0,
    alignItems: 'center',
    flexDirection: 'row',
    marginBottom: '12px',
    borderRadius: '85px 85px 24px 85px',

    '&:last-child': {
      marginBottom: 0,
    },

    [theme.breakpoints.up('md')]: {
      maxWidth: '664px',
    },

    [theme.breakpoints.up('lg')]: {
      flex: '1 0 295px',
      marginRight: '28px',
      borderRadius: '12px',
      padding: '32px',
      flexDirection: 'column',
      alignItems: 'unset',
      marginBottom: 0,

      '&:last-child': {
        marginRight: 0,
      },
    },
  },
  stepService: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  stepIconWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    order: 1,
    height: '68px',
    width: '68px',
    border: `1px solid ${colors.lighter}`,
    borderRadius: '50%',

    [theme.breakpoints.up('lg')]: {
      order: 2,
      borderRadius: 'unset',
      height: 'unset',
      width: 'unset',
      border: 'none',
    },
  },
  stepIcon: {
    '& path': {
      fill: colors.blue,
    },
    width: '24px',
    height: '24px',
  },
  stepNumber: {
    order: 2,
    fontSize: '20px',
    fontFamily: 'Montserrat',
    fontWeight: 800,
    color: colors.grey,
    opacity: '50%',
    marginLeft: '13px',

    [theme.breakpoints.up('lg')]: {
      order: 1,
      marginLeft: 0,
    },
  },
  stepInfo: {
    order: 3,
    maxWidth: '183px',
    fontSize: '12px',
    fontWeight: 'bold',
    lineHeight: '14px',

    display: 'flex',
    alignItems: 'center',
    marginLeft: '17px',

    [theme.breakpoints.up('md')]: {
      maxWidth: '100%',
      whiteSpace: 'nowrap',
    },

    [theme.breakpoints.up('lg')]: {
      marginLeft: 0,
      maxWidth: '210px',
      fontSize: '14px',
      lineHeight: '17px',
      whiteSpace: 'normal',
    },
  },
  infoWrapper: {
    display: 'flex',
    alignItems: 'center',

    [theme.breakpoints.up('lg')]: {
      paddingTop: '8px',
    },
  },
  linkArrow: {
    order: 4,
    transform: 'rotate(180deg)',
    height: '12px',
    marginLeft: '13px',

    '& path': {
      fill: theme.palette.primary.main,
    },
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'underline',

    [theme.breakpoints.up('lg')]: {
      inlineSize: 'min-content',
      minWidth: '148px',
    },
  },
}));
