import {ReactElement, ReactNode, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {noop, orderBy} from 'lodash';
import * as Sentry from '@sentry/react';
import analyticService from '../../shared/services/analytic.service';
import {isNonCustodyWalletType, NonCustodyWalletTypeEnum} from '../../shared/types/non-custody-wallet';
import {AppWallet, isSupportedWallet, SupportedWallet} from '../../shared/types/supported-wallet';
import {getSelectedWalletPublicKey} from '../../shared/services/wallet.service';
import {useNotifications} from '../../shared/hooks/use-notifications';
import {useTorusWalletDialog, TorusWalletDialogOptions} from '../torus-context';
import {UserContext} from './user-context';
import {SocialUserData, UserData} from './types';
import {getInitialSocialData, storeSocialData} from './utils';
import {useGetNonCustodyWallets} from './use-get-non-custody-wallets';
import {LOCAL_STORAGE_KEY_TOKEN, LOCAL_STORAGE_SELECTED_WALLET} from '../../const/storage-keys';
import {useLocaleUpdated} from '../../shared/hooks/use-locale-updated.hook';
import {updateUserLocale} from '../../shared/services/auth.service';

interface Provider {
  children: ReactNode;
}

export function UserContextProvider({children}: Provider): ReactElement {
  // do not keep private key in localstorage
  localStorage.removeItem('userPrivateKey');

  const connectWalletResolveRef = useRef(noop);
  const [userData, setUserData] = useState<UserData>({
    userEmail: localStorage.getItem('userEmail') || '',
    userPublicKey: localStorage.getItem('userPublicKey') || '',
    token: localStorage.getItem(LOCAL_STORAGE_KEY_TOKEN) || '',
    nonCustodyWallets: [],
  });
  const [isExhibitFirstOpen, setIsExhibitFirstOpen] = useState(true);

  const [selectedWallet, setSelectedWallet] = useState<SupportedWallet>(() => {
    const wallet = localStorage.getItem(LOCAL_STORAGE_SELECTED_WALLET);
    return isSupportedWallet(wallet) ? wallet : AppWallet.DAVINCI;
  });
  const [isConnectWalletVisible, setIsConnectWalletVisibleInternal] = useState<boolean>(false);
  const {warning} = useNotifications();
  const [socialUserData, setSocialUserDataIntoState] = useState<SocialUserData>(getInitialSocialData);
  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState<boolean>(false);
  const {isNonCustodyWalletsLoaded, nonCustodyWallets, getNonCustodyWallets} = useGetNonCustodyWallets(userData.token);
  const {openDialog: openTorusDialog, closeDialog: closeTorusDialog} = useTorusWalletDialog({showReadyButton: true});

  const handleSetSelectedWallet = useCallback((wallet: SupportedWallet) => {
    setSelectedWallet(wallet);
    localStorage.setItem(LOCAL_STORAGE_SELECTED_WALLET, wallet);
  }, []);

  const setSocialUserData = useCallback(
    (socialAuthData: SocialUserData) => {
      storeSocialData(socialAuthData);
      setSocialUserDataIntoState(socialAuthData);
    },
    [setSocialUserDataIntoState],
  );

  const setIsConnectWalletVisible = useCallback((state: boolean) => {
    setIsConnectWalletVisibleInternal(state);

    if (!state) {
      connectWalletResolveRef.current();
    }
  }, []);

  const isCurrentWalletNonCustody = useCallback(() => {
    const storedWallet = localStorage.getItem(LOCAL_STORAGE_SELECTED_WALLET);
    const wallet = isSupportedWallet(storedWallet) ? storedWallet : AppWallet.DAVINCI;

    return isNonCustodyWalletType(wallet);
  }, []);

  const connectNonCustodialWallet = useCallback(
    async (options: TorusWalletDialogOptions = {}) => {
      const wallets = isNonCustodyWalletsLoaded ? nonCustodyWallets : await getNonCustodyWallets();

      if (isCurrentWalletNonCustody()) {
        return;
      }

      const isTorusWalletConfigured = wallets?.some(({type}) => type === NonCustodyWalletTypeEnum.TORUS);

      if (isTorusWalletConfigured) {
        // Auto-select Tor.us wallet in case it is configured
        handleSetSelectedWallet(NonCustodyWalletTypeEnum.TORUS);

        return;
      }

      connectWalletResolveRef.current = () => {
        if (isCurrentWalletNonCustody()) {
          closeTorusDialog(); // Close Torus dialog in case another wallet was configured
        }
      };

      await openTorusDialog(options);

      connectWalletResolveRef.current = noop;

      if (!isCurrentWalletNonCustody()) {
        warning('Please, connect wallet to continue');

        throw new Error('Wallet has not been connected');
      }
    },
    [
      closeTorusDialog,
      getNonCustodyWallets,
      handleSetSelectedWallet,
      isCurrentWalletNonCustody,
      isNonCustodyWalletsLoaded,
      nonCustodyWallets,
      openTorusDialog,
      warning,
    ],
  );

  useEffect(() => {
    setUserData((prevData) => ({...prevData, nonCustodyWallets}));

    if (selectedWallet === AppWallet.DAVINCI && nonCustodyWallets.length) {
      setSelectedWallet(nonCustodyWallets[0].type);
      localStorage.setItem(LOCAL_STORAGE_SELECTED_WALLET, nonCustodyWallets[0].type);
    }
  }, [nonCustodyWallets, selectedWallet]);

  /**
   * Ordered wallet list with Tor.us wallet on top
   */
  const orderedNonCustodyWallets = useMemo(
    () => orderBy(nonCustodyWallets, (wallet) => wallet.type === NonCustodyWalletTypeEnum.TORUS, 'desc'),
    [nonCustodyWallets],
  );

  useLocaleUpdated(
    useCallback(
      async (locale) => {
        if (userData.token) {
          await updateUserLocale(userData.token, locale);
        }
      },
      [userData.token],
    ),
  );

  const context = useMemo(
    () => ({
      userData,
      setUserData,
      nonCustodyWallets: orderedNonCustodyWallets,
      connectNonCustodialWallet,
      selectedWallet,
      setSelectedWallet: handleSetSelectedWallet,
      isConnectWalletVisible,
      setIsConnectWalletVisible,
      selectedWalletPublicKey: getSelectedWalletPublicKey(selectedWallet, userData.userPublicKey, nonCustodyWallets),
      getNonCustodyWallets,
      socialUserData,
      setSocialUserData,
      isExhibitFirstOpen,
      setIsExhibitFirstOpen,
      isProfileMenuOpen,
      setIsProfileMenuOpen,
    }),
    [
      userData,
      nonCustodyWallets,
      orderedNonCustodyWallets,
      connectNonCustodialWallet,
      selectedWallet,
      handleSetSelectedWallet,
      isConnectWalletVisible,
      setIsConnectWalletVisible,
      getNonCustodyWallets,
      socialUserData,
      isExhibitFirstOpen,
      setSocialUserData,
      isProfileMenuOpen,
      setIsProfileMenuOpen,
    ],
  );

  useEffect(() => {
    analyticService.identify({
      email: userData.userEmail,
    });
    Sentry.setContext('user', {
      email: userData.userEmail,
    });
  }, [userData.userEmail]);

  return <UserContext.Provider value={context}>{children}</UserContext.Provider>;
}
