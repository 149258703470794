import {AnyAction, MiddlewareAPI} from 'redux';
import {CheckUserHasNftEnum} from '@cere/services-types';
import {PendingTransactionsStorage} from '../../../models/pending-transactions/PendingTransactionsStorage';
import {InitAppCommand} from '../../base/actions';
import {
  AllPendingTransactionsDocument,
  PendingTransactionDocument,
  RemovePendingTransactionForNftCommand,
  SetPendingTransactionForNftCommand,
  StartPollingForCreatedOfferCommand,
  StartPollingForPurchasedNftCommand,
} from './actions';
import {PendingTransaction, PendingTransactionType} from '../../../models/pending-transactions/types';
import {IPurchaseHistoryApi} from '../../../api/purchase-history/IPurchaseHistoryApi';
import {UpdateNftCommand} from '../nfts/actions';

const UPDATES_POLLING_INTERVAL = 5000;

export const restorePendingTransactionsMiddleware =
  (pendingTransactionsStorage: PendingTransactionsStorage) =>
  ({dispatch}: MiddlewareAPI) =>
  (next: Function) =>
  (action: AnyAction) => {
    next(action);
    if (action.type === InitAppCommand.type) {
      const pendingTransactions = pendingTransactionsStorage.getStoredTransactions();
      dispatch(AllPendingTransactionsDocument.create(pendingTransactions));
      Object.values(pendingTransactions).forEach((transaction) => {
        const {userWalletAddress, nftId} = transaction;
        switch (transaction.type) {
          case PendingTransactionType.BuyTransaction:
            dispatch(StartPollingForPurchasedNftCommand.create(userWalletAddress, nftId));
            break;
          case PendingTransactionType.SellTransaction:
            dispatch(StartPollingForCreatedOfferCommand.create(userWalletAddress, nftId));
        }
      });
    }
  };

export const addPendingTransactionMiddleware =
  (pendingTransactionsStorage: PendingTransactionsStorage) =>
  ({dispatch}: MiddlewareAPI) =>
  (next: Function) =>
  (action: AnyAction) => {
    next(action);
    if (action.type === SetPendingTransactionForNftCommand.type) {
      const pendingTransaction = action.payload as PendingTransaction;
      const {userWalletAddress, nftId} = pendingTransaction;
      pendingTransactionsStorage.setPendingTransaction(pendingTransaction);
      dispatch(PendingTransactionDocument.create(pendingTransaction));
      switch (pendingTransaction.type) {
        case PendingTransactionType.BuyTransaction:
          dispatch(StartPollingForPurchasedNftCommand.create(userWalletAddress, nftId));
          break;
        case PendingTransactionType.SellTransaction:
          dispatch(StartPollingForCreatedOfferCommand.create(userWalletAddress, nftId));
      }
    }
  };

export const removePendingTransactionMiddleware =
  (pendingTransactionsStorage: PendingTransactionsStorage) =>
  ({dispatch}: MiddlewareAPI) =>
  (next: Function) =>
  (action: AnyAction) => {
    next(action);
    if (action.type === RemovePendingTransactionForNftCommand.type) {
      const {nftId} = action.payload;
      pendingTransactionsStorage.removePendingTransactionByNftId(nftId);
      dispatch(AllPendingTransactionsDocument.create(pendingTransactionsStorage.getStoredTransactions()));
    }
  };

export const purchasedNftPollingMiddleware =
  (purchaseHistoryApi: IPurchaseHistoryApi) =>
  ({dispatch}: MiddlewareAPI) =>
  (next: Function) =>
  async (action: AnyAction) => {
    next(action);
    if (action.type === StartPollingForPurchasedNftCommand.type) {
      const {nftId, userWalletAddress} = action.payload;
      const intervalId = setInterval(async () => {
        const isPurchased = await purchaseHistoryApi.checkIfUserHasNft(userWalletAddress, nftId);
        if (isPurchased) {
          dispatch(UpdateNftCommand.create(nftId, {purchaseStatus: CheckUserHasNftEnum.USER_HAS_NFT}));
          dispatch(RemovePendingTransactionForNftCommand.create(nftId));
          clearInterval(intervalId);
        }
      }, UPDATES_POLLING_INTERVAL);
    }
  };

export const createdNftOfferPollingMiddleware =
  (purchaseHistoryApi: IPurchaseHistoryApi) =>
  ({dispatch}: MiddlewareAPI) =>
  (next: Function) =>
  async (action: AnyAction) => {
    next(action);
    if (action.type === StartPollingForCreatedOfferCommand.type) {
      const {nftId, userWalletAddress} = action.payload;
      const intervalId = setInterval(async () => {
        const isOfferExists = await purchaseHistoryApi.checkIfNftOffersExists(userWalletAddress, nftId);
        if (isOfferExists) {
          dispatch(RemovePendingTransactionForNftCommand.create(nftId));
          clearInterval(intervalId);
        }
      }, UPDATES_POLLING_INTERVAL);
    }
  };
