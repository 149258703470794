import {useCallback, useContext, useMemo} from 'react';
import {useHistory} from 'react-router-dom';
import {Box} from '@material-ui/core';
import {Button, Typography} from '@cere/rxb-template-ui-kit';
import {useFetchPaymentLink} from '../../hooks/payment.hook';
import BootstrapLoader from '../../../shared/components/bootstrap-loader';
import analyticService, {AnalyticEventsEnum} from '../../services/analytic.service';
import {GoogleAnalyticsId} from '../../../analytics-ids';
import {CmsExhibitionIdSlugOnly} from '../../types/graphql';
import {UserContext} from '../../../context/user-context/user-context';
import {UserPaymentHistoryContext} from '../../../context/payments-history/user-payment-history.context';
import {getIsPaymentPending, getIsPaymentSuccess} from '../../helpers/paymentStatus';
import {useLocalization} from '../../hooks/use-locale.hook';

type Exhibition = CmsExhibitionIdSlugOnly & {
  cmsCreator: {
    name: string;
  };
};

interface StripeParams {
  email: string | undefined;
  event: Exhibition;
  nftAddress: string;
  relType: string;
  nftTitle: string;
  quantity: number;
}

export const Stripe = ({email, nftAddress, nftTitle, relType, event, quantity}: StripeParams) => {
  const {t, locale} = useLocalization();
  const {url, error, eventUrl} = useFetchPaymentLink(email, nftAddress, quantity, event);
  const {getPaymentStatuses, getNftIsPurchased} = useContext(UserPaymentHistoryContext);
  const {userData} = useContext(UserContext);
  const {push} = useHistory();

  const userBoughtNftOrPaymentInProgress = useMemo(() => {
    const paymentStatus = getPaymentStatuses(nftAddress);
    return (
      getIsPaymentSuccess(...paymentStatus) || getIsPaymentPending(...paymentStatus) || getNftIsPurchased(nftAddress)
    );
  }, [getNftIsPurchased, getPaymentStatuses, nftAddress]);

  const goToEvent = useCallback(() => {
    analyticService.track(AnalyticEventsEnum.INTERSTITIAL_GO_TO_EVENT_CLICKED);

    if (userData.userPublicKey) {
      window.location.href = eventUrl;
    } else {
      push(`/${locale}/home/auth`);
    }
  }, [userData.userPublicKey, eventUrl, push, locale]);

  if (userBoughtNftOrPaymentInProgress) {
    return (
      <Box>
        <Typography>
          {t('This email is already registered. Enter the exhibit directly with your purchased NFT ticket.')}
        </Typography>
        <Button
          className={GoogleAnalyticsId.GoToTheEventBtn}
          color="primary"
          variant="outlined"
          size="small"
          fullWidth
          onClick={goToEvent}
        >
          {t('Enter exhibit')}
        </Button>
      </Box>
    );
  }

  if (url) {
    analyticService.track(AnalyticEventsEnum.CHECKOUT_STARTED, {
      nftId: nftAddress,
      nftTitle: nftTitle || '',
      artistName: event?.cmsCreator?.name || '',
      nftType: relType,
    });

    window.location.href = url;
  }

  return (
    <div className="d-flex justify-content-center align-items-center h-100 pt-3">
      {error ? <div className="alert alert-danger">{error}</div> : <BootstrapLoader color="text-primary" />}
    </div>
  );
};
