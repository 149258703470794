import {PENDING_TRANSACTIONS_MODULE_NAME} from './constants';
import {pendingTransactionsReducer} from './reducers';
import {PendingTransaction} from '../../../models/pending-transactions/types';
import {NoSuchModuleError} from '../../base/NoSuchModuleError';

type PendingTransactionsStore = {[PENDING_TRANSACTIONS_MODULE_NAME]: ReturnType<typeof pendingTransactionsReducer>};

export const selectPendingTransactionByNftId = (
  store: PendingTransactionsStore,
  nftId: string,
): PendingTransaction | null => {
  if (!store[PENDING_TRANSACTIONS_MODULE_NAME]) {
    throw new NoSuchModuleError(PENDING_TRANSACTIONS_MODULE_NAME);
  }
  return store[PENDING_TRANSACTIONS_MODULE_NAME][nftId] ?? null;
};
