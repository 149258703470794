import {ReactElement, useContext} from 'react';
import {Box, Container, Theme, useMediaQuery} from '@material-ui/core';

import {useExhibitionPreview} from 'shared/hooks/use-exhibition-preview.hook';
import {ShareIcon} from 'shared/components/ShareIcon';
import {ExhibitContext} from 'context/exhibition/exhibit-context';

import {useIsLiveThemeApplied} from '../use-is-live-theme-applied';
import {useContainerStyles} from './live-styles';
import {ExhibitionPreview} from './exhibition-preview';

type Props = {
  onToggleShareModal: () => unknown;
  onPlayVideoClick: () => unknown;
  accessPending: boolean;
  localIsLoading: boolean;
  userHasEventAccess: boolean;
  userHasJwtAccess: boolean;
  topCardsContainerRef: HTMLDivElement | null;
};

export function ExhibitionPreviewContainer({
  onToggleShareModal,
  onPlayVideoClick,
  accessPending,
  localIsLoading,
  userHasEventAccess,
  userHasJwtAccess,
  topCardsContainerRef,
}: Props): ReactElement {
  const {event} = useContext(ExhibitContext);
  const isLiveMode = useIsLiveThemeApplied();
  const {previewImage} = useExhibitionPreview(event, isLiveMode);
  const styles = useContainerStyles({previewImage, isLiveMode});
  const isDesktop = useMediaQuery<Theme>((theme) => `${theme.breakpoints.up('md')}`);

  if (!event) {
    return <></>;
  }

  return (
    <Box className={styles.main}>
      <Container>
        <Box className={styles.shareIconBox} onClick={onToggleShareModal}>
          <ShareIcon isShort={isDesktop} />
        </Box>
        <ExhibitionPreview
          accessPending={accessPending}
          localIsLoading={localIsLoading}
          onPlayVideoClick={onPlayVideoClick}
          userHasEventAccess={userHasEventAccess}
          userHasJwtAccess={userHasJwtAccess}
          isLiveMode={isLiveMode}
          topCardsContainerRef={topCardsContainerRef}
        />
      </Container>
    </Box>
  );
}
