import {Box, makeStyles} from '@material-ui/core';

import {Skeleton} from './Skeleton';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '343px',
    height: '130px',
    borderRadius: '12px',
    overflow: 'hidden',
    display: 'flex',
  },
  logo: {
    width: '130px',
    height: '130px',
  },
  ticketInfo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    flexGrow: 2,
  },
  ticketName: {
    width: '104px',
    height: '48px',
    borderRadius: '12px',
    marginLeft: '12px',
    marginBottom: '22px',
  },
  ticketDesc: {
    width: '100%',
    height: '36px',
    backgroundColor: theme.palette.grey[400],
  },
}));

export const ExhibitionTicketSkeleton = () => {
  const styles = useStyles();

  return (
    <Box className={styles.root}>
      <Skeleton variant="rect" className={styles.logo} />
      <Box className={styles.ticketInfo}>
        <Skeleton variant="rect" className={styles.ticketName} />
        <Box className={styles.ticketDesc} />
      </Box>
    </Box>
  );
};
