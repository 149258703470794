import {memo, ReactElement} from 'react';
import {Box, Typography, makeStyles} from '@material-ui/core';

import {ReactComponent as ShareIcon} from '../../../assets/svg/share.svg';
import {AvatarWithName} from '../../primitives/AvatarWithName';
import {IconButton} from '../../primitives/IconButton';
import {ImageSquare} from '../../primitives/ImageSquare';
import {StyledLink} from '../../primitives/StyledLink';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    borderRadius: '12px',
    overflow: 'hidden',
  },
  badge: {
    position: 'absolute',
    top: '12px',
    left: '12px',
  },
  shareButton: {
    position: 'absolute',
    top: '12px',
    right: '12px',
  },
  infoBox: {
    position: 'absolute',
    bottom: '12px',
    left: '12px',
    right: '12px',
  },
  title: {
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '20px',
    color: theme.palette.common.white,
    paddingBottom: '8px',
  },
  subTitle: {
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '20px',
    color: theme.palette.common.white,
    paddingBottom: '4px',
  },
  whiteColor: {
    color: theme.palette.common.white,
  },
}));

type Props = {
  link: string;
  image: string;
  title: string;
  subTitle: string;
  creatorName?: string;
  creatorLogo: string;
  creatorLink: string;
  onShareClick: () => void;
  renderBadge: () => ReactElement;
};

export const CardSquare = memo(
  ({image, link, title, subTitle, creatorName, creatorLogo, creatorLink, onShareClick, renderBadge}: Props) => {
    const styles = useStyles();

    return (
      <Box className={styles.root}>
        <StyledLink to={link}>
          <ImageSquare image={image} />
        </StyledLink>

        <Box className={styles.badge}>{renderBadge()}</Box>

        <IconButton className={styles.shareButton} onClick={onShareClick}>
          <ShareIcon />
        </IconButton>

        <Box className={styles.infoBox}>
          <Typography className={styles.subTitle}>{subTitle}</Typography>
          <Typography className={styles.title}>{title}</Typography>
          <StyledLink to={creatorLink}>
            <AvatarWithName
              name={creatorName ?? ''}
              image={creatorLogo}
              isVerified
              classes={{name: styles.whiteColor}}
            />
          </StyledLink>
        </Box>
      </Box>
    );
  },
);
