import {useCallback} from 'react';
import {Box, Grid, makeStyles, Theme} from '@material-ui/core';
import {NftCardInterface, UploadFile, NftType} from '@cere/services-types';
import {useQuery} from '@apollo/client';
import {useParams} from 'react-router-dom';
import {noop} from 'lodash';

import {useCreatorPageNfts} from 'api/hooks/use-creators-page-nfts';
import {useCreatorPageCreator} from 'api/hooks/use-creator-page-creator';
import {GoogleAnalyticsId} from 'analytics-ids';
import {ReactComponent as DiscordIcon} from 'assets/discord-icon.svg';
import {PageContainer} from 'shared/components/PageContainer';
import {Title, SubTitle} from 'shared/components/Title';
import {Condition, ConditionsList, Defaults} from 'shared/components/Conditions';
import {
  Skeleton,
  CreatorHeaderSkeleton,
  SocialNetworksInfoSkeleton,
  ExhibitsRowSkeleton,
  NftsListSkeleton,
} from 'shared/components/Skeletons';
import {getMediaUrl} from 'shared/lib/media';
import {SocialNetworksInfo} from 'shared/components/SocialNetworksInfo';
import {AppMetaTags} from 'shared/components/SeoHeaders/seo-headers.component';
import {NftProvider2} from 'shared/components/Nft/nft-provider-v2';
import {NFTGrid} from 'shared/components/NftGrid';
import {PageLayout} from 'shared/components/PageLayout';
import {usePageLoaded} from 'shared/services/loading.service';
import analyticService, {AnalyticEventsEnum} from 'shared/services/analytic.service';
import {useLocalization} from 'shared/hooks/use-locale.hook';
import {useOtpVerification} from 'shared/hooks/use-otp-verification.hook';
import {useValueByResolution} from 'shared/hooks/use-resolution.hook';
import {CmsCreator, CmsCreatorWithExhibitions} from 'shared/types/graphql';
import {Numberish} from 'shared/types/numberish';
import {GET_ARTIST} from 'shared/queries';

import {NftCardV3} from '../HomePage/NftCard/nft-card-v3';
import {ExhibitionsList} from '../ExhibitsPage/exhibitions-list';
import {Header} from './Header';
import {Markdown} from 'shared/components/Markdown';

interface ArtistPageRouteParams {
  artistId: string;
}

const useStyles = makeStyles<Theme>((theme) => ({
  discordButton: {
    display: 'flex',
    justifyContent: 'center',

    '& > a': {
      display: 'inline-flex',
      fontSize: '0.9rem',
      padding: '0rem 1rem 0rem 0.5rem',
      alignItems: 'center',
      borderRadius: '2rem',
      border: `1px solid ${theme.palette.divider}`,
      textDecoration: 'none',
      fontWeight: 600,
      lineHeight: '20px',
      color: theme.palette.common.black,
      background: theme.palette.common.white,
    },
  },
  discordButtonSkeleton: {
    width: '180px',
    height: '36px',
    borderRadius: '18px',
  },
  creatorAboutSkeleton: {
    width: '340px',
    height: '84px',
    borderRadius: '12px',

    [theme.breakpoints.up('md')]: {
      width: '680px',
      height: '42px',
    },
  },
}));

const CreatorPage = () => {
  usePageLoaded();
  useOtpVerification();
  const {t} = useLocalization();

  const {nfts, loading: isLoading} = useCreatorPageNfts();
  const ticketsAndFreeNfts = nfts?.filter((nft) => nft.nftType === NftType.ACCESS || nft.isExhibitionAllowFreeAccess);
  const {creator: artist, loading: isCreatorLoading} = useCreatorPageCreator();

  const trackNFTCollectableClick = useCallback(
    (nftId: Numberish) => analyticService.track(AnalyticEventsEnum.NFT_COLLECTABLE_CLICKED, {nftId}),
    [],
  );

  let {artistId} = useParams<ArtistPageRouteParams>();

  const classes = useStyles();

  const {data, error, loading} = useQuery<{artist: CmsCreatorWithExhibitions}, {id: string}>(GET_ARTIST, {
    variables: {id: artistId},
  });
  const events = data?.artist?.cmsExhibitions;

  const {value: bgImageByResolution} = useValueByResolution<UploadFile>({
    desktop: artist?.desktopBackgroundImage as UploadFile,
    tablet: artist?.tabletBackgroundImage as UploadFile,
    mobile: artist?.mobileBackgroundImage as UploadFile,
  });

  if (error) {
    throw error;
  }

  const localizations = !data
    ? []
    : [
        {
          locale: data.artist.locale,
          link: `/${data.artist.locale}/home/creator/${data.artist.id}`,
        },
        ...data.artist.localizations?.map((localizedArtist) => ({
          locale: data.artist.locale,
          link: `/${localizedArtist.locale}/home/creator/${localizedArtist.id}`,
        })),
      ];

  return (
    <PageLayout hideBackButton>
      {data && (
        <AppMetaTags
          title={data.artist?.seoTitle || data.artist.name}
          description={data.artist?.seoDescription || data.artist.about}
          canonical={data.artist?.seoCanonical}
          image={data.artist?.seoImage?.url || data.artist.avatar.url}
          localizations={localizations}
        />
      )}

      <PageContainer>
        {isCreatorLoading ? (
          <CreatorHeaderSkeleton />
        ) : (
          <Header
            bgImgUrl={getMediaUrl(bgImageByResolution)}
            avatarUrl={getMediaUrl(artist?.avatar as UploadFile)}
            artistName={artist?.name!}
          />
        )}

        <Box display="flex" justifyContent="center" fontWeight="400" fontSize="14px" pt="25px">
          {isCreatorLoading ? (
            <Skeleton className={classes.creatorAboutSkeleton} variant="text" />
          ) : (
            <Box>
              <Markdown>{artist?.about || ''}</Markdown>
            </Box>
          )}
        </Box>

        <Box pb={4} pt={1}>
          {isCreatorLoading ? (
            <Box display="flex" justifyContent="center">
              <Skeleton className={classes.discordButtonSkeleton} variant="rect" />
            </Box>
          ) : (
            artist?.discordLink && (
              <Box className={classes.discordButton}>
                <a target="_blank" rel="noopener noreferrer" title={t('Discord Channel')} href={artist.discordLink}>
                  <DiscordIcon width={36} height={36} />
                  {t('Discord channel')}
                </a>
              </Box>
            )
          )}
        </Box>
        <Box display="flex" justifyContent="center">
          {isCreatorLoading ? (
            <SocialNetworksInfoSkeleton />
          ) : (
            <SocialNetworksInfo artist={artist! as CmsCreator} withoutBackground iconSize="20px" centered noTitle />
          )}
        </Box>
        <Box mt="32px" mb="28px">
          <Box pb={2}>
            <Title>{t('Exhibits')}</Title>
            <SubTitle>
              {t('Hand-curated and unique artist experiences. View, buy, and auction for valuable NFT collectibles')}
            </SubTitle>
          </Box>
          <ConditionsList>
            <Condition condition={loading}>
              <ExhibitsRowSkeleton />
            </Condition>
            <Defaults>
              <Grid container spacing={4}>
                <ExhibitionsList exhibitions={events} loading={loading} noAvatar isSorted />
              </Grid>
            </Defaults>
          </ConditionsList>

          <ConditionsList>
            <Condition condition={isLoading}>
              <Box pt={4}>
                <NftsListSkeleton title={t('NFTs')} />
              </Box>
            </Condition>
            <Defaults>
              <NFTGrid
                title={t('NFTs')}
                subTitle={t('Exclusive digital art collectibles which unlock unseen experiences created by artists')}
                onLoadMore={noop}
              >
                {(ticketsAndFreeNfts || [])?.map((nft: NftCardInterface) => {
                  return (
                    <NftProvider2 nft={nft}>
                      <NftCardV3
                        nftId={nft.id}
                        imageUrl={nft.image}
                        nftType={nft.nftType}
                        availableFrom={nft.availableFrom?.toString()}
                        availableTo={nft.availableTo?.toString()}
                        availability={nft.availability}
                        nftAddress={nft.address}
                        nftTitle={nft.title}
                        nftDescription={nft.description}
                        balance={nft.balance}
                        supply={nft.supply}
                        usdPrice={nft.priceUsd}
                        onClick={trackNFTCollectableClick}
                        buttonClassName={GoogleAnalyticsId.BuyCollectiblesBtn}
                        withOutShadow
                      />
                    </NftProvider2>
                  );
                })}
              </NFTGrid>
            </Defaults>
          </ConditionsList>
        </Box>
      </PageContainer>
    </PageLayout>
  );
};

export default CreatorPage;
