import {useFiltersStyles} from './styles';
import {Box, Button} from '@material-ui/core';
import {PriceFilter} from './PriceFilter';
import {CreatorFilter} from './CreatorFilter';
import {DateFilter} from './DateFilter';
import clsx from 'clsx';
import {useLocalization} from '../../hooks/use-locale.hook';

export interface FilterDesktopViewProps {
  onApply: () => void;
  onClear: () => void;
}

export const FilterDesktopView = ({onApply, onClear}: FilterDesktopViewProps) => {
  const {t} = useLocalization();
  const styles = useFiltersStyles();

  return (
    <Box className={styles.desktopContainer}>
      <Box className={styles.container}>
        <PriceFilter />
        <CreatorFilter />
        <DateFilter />
      </Box>
      <Box className={styles.controlsContainer}>
        <Button className={styles.controlButton} onClick={onClear}>
          {t('Clear')}
        </Button>
        <Button className={clsx(styles.controlButton, styles.blackButton)} onClick={onApply}>
          {t('Apply')}
        </Button>
      </Box>
    </Box>
  );
};
