import {FullCreatorInterface} from '@cere/services-types';
import {CREATORS_MODULE_NAME} from './constants';
import {NoSuchModuleError} from '../../base/NoSuchModuleError';
import {CreatorsModuleStoreType} from './types';

export const selectAllCreators = (store: CreatorsModuleStoreType): FullCreatorInterface[] => {
  if (!store[CREATORS_MODULE_NAME]) {
    throw new NoSuchModuleError(CREATORS_MODULE_NAME);
  }
  return store[CREATORS_MODULE_NAME].allCreators;
};

export const selectCreatorsLoading = (store: CreatorsModuleStoreType): boolean => {
  if (!store[CREATORS_MODULE_NAME]) {
    throw new NoSuchModuleError(CREATORS_MODULE_NAME);
  }
  return store[CREATORS_MODULE_NAME].loading;
};
