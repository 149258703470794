import {gql, useQuery} from '@apollo/client';
import {useContext, useMemo} from 'react';
import {CmsExhibitionNftRelType} from '../types/graphql';
import {CREATOR_NFT_BASE_PROPS} from '../queries/creator-nft-fragments';
import {Numberish} from '../types/numberish';
import {ExhibitContext, ExhibitionEmptyContext} from '../../context/exhibition/exhibit-context';
import {useIsPreviewMode} from './use-preview-mode-hooks';
import {PublicationState} from '../types/cms-exhibit';
import {useFindRelatedExhibitionId} from '../components/Nft/use-find-related-exhibition';
import {useLocalization} from './use-locale.hook';

const query = gql`
  ${CREATOR_NFT_BASE_PROPS}
  query findExhibitionNft($id: ID!, $publicationState: PublicationState, $locale: String) {
    cmsExhibition(id: $id, publicationState: $publicationState) {
      startsAt
      endsAt
      allowFreeAccess
      nfts {
        cmsNft {
          id
          creatorNft {
            ...CreatorNftBaseProps
          }
        }
        relType
        availableFrom
        availableTo
      }
    }
  }
`;

type Nft = {
  cmsNft: {
    id: Numberish;
    creatorNft?: {
      id: Numberish;
      minter: string;
      supply: number;
      nft_id: string;
    };
  };
  relType: CmsExhibitionNftRelType;
  availableFrom: string;
  availableTo: string;
};

export type FindExhibitionWithNftResult = {
  startsAt: string;
  endsAt: string;
  allowFreeAccess: boolean;
  nfts: [Nft];
};

type Result = {
  startsAt: string;
  endsAt: string;
  allowFreeAccess: boolean;
  nft: Nft | undefined;
  accessNfts: Nft[];
};

export function useFindExhibitionWithNft(nftId: string | undefined): {
  data: Result | undefined;
  error?: Error;
  loading: boolean;
} {
  const {locale} = useLocalization();
  const {event, symbol} = useContext(ExhibitContext);
  const skip = !nftId || symbol !== ExhibitionEmptyContext;
  const isPreviewMode = useIsPreviewMode();
  const eventId = useFindRelatedExhibitionId(event ? undefined : nftId) || event?.id;
  const {
    data: exhibition,
    error: queryError,
    loading: exhibitionLoading,
  } = useQuery<{cmsExhibition: FindExhibitionWithNftResult}>(query, {
    variables: {
      id: eventId,
      publicationState: isPreviewMode ? PublicationState.PREVIEW : PublicationState.LIVE,
      locale,
    },
    skip: !!event || skip || !eventId,
  });
  const loadedExhibition = useMemo(() => exhibition?.cmsExhibition || event, [event, exhibition?.cmsExhibition]);
  const nfts = useMemo(() => loadedExhibition?.nfts ?? [], [loadedExhibition?.nfts]);
  const data = useMemo(
    () =>
      loadedExhibition
        ? {
            startsAt: loadedExhibition.startsAt,
            endsAt: loadedExhibition.endsAt,
            allowFreeAccess: Boolean(loadedExhibition.allowFreeAccess),
            nft: nfts.find((nft) => nft.cmsNft?.creatorNft?.nft_id === nftId),
            accessNfts: nfts.filter((nft) => nft.relType === CmsExhibitionNftRelType.ACCESS),
          }
        : undefined,
    [loadedExhibition, nftId, nfts],
  );

  return {data, error: queryError, loading: exhibitionLoading};
}
