import {alpha, Box, CardMedia, makeStyles, Theme} from '@material-ui/core';
import {Typography} from '@cere/rxb-template-ui-kit';
import {SyntheticEvent, useContext, useMemo} from 'react';
import dayjs from 'dayjs';
import clsx from 'clsx';
import useResizeObserver from 'use-resize-observer';
import {EventCounter} from '../EventCounter';
import {AuctionMoreInfo} from '../AuctionMoreInfo';
import {BidInfo} from './BidInfo';
import {GoogleAnalyticsId} from '../../../../analytics-ids';
import CardDetailNavigation from 'shared/components/CardDetailNavigation';
import {OverlayDetailCardProps} from './types';
import {getNftRelTypeTitle} from '../../../helpers/get-nft-rel-type-title.helper';
import {NftContext} from 'shared/components/Nft/nft-context-v2';
import {AvailabilityStatus} from '@cere/services-types';
import {NftActionButton} from 'shared/components/ActionButtons';

const useStyles = makeStyles<
  Theme,
  {
    isComingSoon: boolean;
    image?: string;
    width?: number;
  }
>((theme) => ({
  bid: {
    color: '#717178',
    marginLeft: '5px',
  },
  button: {
    background: theme.palette.info.main,
    maxWidth: '140px',
    minWidth: '140px',
    maxHeight: '36px',
    color: theme.palette.common.white,
    border: 'none',
    '&:hover': {
      background: alpha(theme.palette.info.main, 0.6),
    },
    '&.Mui-disabled': {
      background: alpha(theme.palette.info.main, 0.3),
    },
  },
  grey: {
    color: '#717178',
  },
  rotateLeft: {
    transform: 'rotate(90deg)',
  },
  rotateRight: {
    transform: 'rotate(270deg)',
  },
  card: {
    borderRadius: '12px',
    background: 'rgba(22, 22, 22, 0.6)',
    backdropFilter: 'blur(66px)',
    padding: '16px 8px 20px',
    marginTop: '16px',
  },
  subtitle: {
    display: 'block',
    marginTop: '5px',
  },
  description: {
    color: '#717178',

    '& .MuiTypography-root': {
      color: 'inherit',
    },
  },
  bottomWrapper: {
    flex: '0 0 100%',
  },
  nftType: {
    backgroundColor: '#7F38F3',
    borderRadius: '4px',
    padding: '1px 7px',
    fontWeight: 700,
  },
  media: {
    height: '100%',
    width: '100%',
    marginRight: '16px',
    borderRadius: '12px',
    flex: ({width}: {width?: number}) => `0 0 ${width || 0}px`,
    position: 'relative',
    overflow: 'hidden',
    backgroundImage: 'unset !important',

    '&:after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      width: '100%',
      height: '100%',
      bottom: 0,
      borderRadius: '12px',
      backgroundImage: ({image}) => `url(${image})`,
      backgroundSize: 'cover',
      filter: ({isComingSoon}) => (isComingSoon ? 'blur(1.3rem)' : 'unset'),
      zIndex: 10,
    },

    '@media (max-width:576px)': {
      marginRight: '12px',
      flexBasis: '168px',
    },
  },

  comingSoon: {
    overflow: 'hidden',
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#fff',
    fontWeight: 700,
    zIndex: 100,

    '& span': {
      fontSize: '1.2rem',
    },
  },
}));

const useMediaStyles = makeStyles(() => ({
  root: {
    width: '100%',
    borderRadius: '12px',
    flex: ({width}: {width?: number}) => `0 0 ${width || 0}px`,
    position: 'relative',

    '&:after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      width: '100%',
      height: '100%',
      bottom: '-2px',
      zIndex: 1,
      backgroundImage: 'linear-gradient(180deg, rgba(22, 22, 22, 0) 0%, #161616 94.27%)',
      borderRadius: '12px',
    },
  },
}));

export const OverlayDetailCardPortrait = ({
  title,
  subtitle,
  description,
  description1,
  price,
  imgSrc,
  disabled = false,
  isBadge,
  selectedIndex,
  totalLength,
  bids = [],
  isAuction,
  isOpen,
  endsAt,
  handlePrevClick,
  handleNextClick,
  hideNavigation,
  onVisualClick,
  relType,
  hidePrice,
}: OverlayDetailCardProps) => {
  const {ref, width, height} = useResizeObserver();

  const mediaClasses = useMediaStyles({width});

  const isCounterVisible = useMemo(
    () => !disabled && isAuction && dayjs().isBefore(dayjs(endsAt)),
    [disabled, endsAt, isAuction],
  );

  const {nft} = useContext(NftContext);

  const isComingSoon = useMemo(() => {
    if (nft.availability === AvailabilityStatus.COMING_SOON || nft.isComingSoon) {
      return true;
    }
    return nft.isComingSoon ?? false;
  }, [nft]);

  const isComingSoonCaption = useMemo(() => {
    if (nft.availability === AvailabilityStatus.COMING_SOON || nft.isComingSoon) {
      return dayjs(nft.availableFrom).format('MM.DD.YYYY');
    }
  }, [nft]);

  const isFullLoaded = nft?.nftType != null;

  const classes = useStyles({
    isComingSoon: isComingSoon,
    image: nft.image,
    width: width,
  });

  return (
    <Box onClick={(event: SyntheticEvent) => event.stopPropagation()}>
      <Box display="flex" flexDirection="column">
        <CardMedia
          className={!isComingSoon ? mediaClasses.root : classes.media}
          image={imgSrc}
          ref={ref}
          onClick={onVisualClick}
        >
          {isComingSoon && isFullLoaded && (
            <div className={classes.comingSoon}>
              Coming soon
              {isComingSoonCaption && <span>{isComingSoonCaption}</span>}
            </div>
          )}
          {!isCounterVisible ? null : (
            <Box height={height}>
              <EventCounter date={endsAt} />
            </Box>
          )}
        </CardMedia>
        <Box className={classes.card}>
          <Box display="flex" alignItems="center" justifyContent="space-between" mb="6px">
            <Typography variant="h3">{title}</Typography>

            {!hideNavigation && (
              <CardDetailNavigation
                selectedIndex={selectedIndex}
                totalLength={totalLength}
                handleNextClick={handleNextClick}
                handlePrevClick={handlePrevClick}
              />
            )}
          </Box>

          {!isComingSoon && (
            <>
              <Typography variant="caption1" className={classes.nftType}>
                {relType && getNftRelTypeTitle(relType)}
              </Typography>

              <Typography variant="caption1" className={classes.subtitle}>
                {subtitle}
              </Typography>

              {!!description && (
                <Typography variant="caption1" className={classes.description}>
                  {description}
                </Typography>
              )}

              <Box mb="24px" mt="10px">
                <Typography variant="body2">{description1}</Typography>
              </Box>
            </>
          )}

          <Box display="flex" justifyContent="space-between">
            <BidInfo
              hidePrice={hidePrice}
              isAuction={isAuction}
              price={price}
              bids={bids}
              containerProps={{maxWidth: '130px', width: '100%', flex: '0 1 130px'}}
            />

            <Box maxWidth="140px" ml="20px" flex="0 1 140px" display="flex" alignItems="center">
              <NftActionButton
                className={clsx(classes.button, isAuction ? GoogleAnalyticsId.BidBtn : GoogleAnalyticsId.BuyDropBtn)}
              />
            </Box>
          </Box>

          {isAuction && (
            <Box className={classes.bottomWrapper}>
              <AuctionMoreInfo bids={bids} isOpen={isOpen} isHighestBid={isBadge} />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};
