import {Dispatch} from 'redux';
import {connect} from 'react-redux';

import {MarketplacePageView} from './view';
import {
  selectMarketplacePageCollectableNftsIds,
  selectMarketplacePageFeaturedCreators,
  selectMarketplacePageFeaturedNftsIds,
  selectMarketplacePageLoadingState,
} from '../../redux/modules/marketplace-page/selectors';
import {LoadMarketplaceDataCommand} from '../../redux/modules/marketplace-page/actions';

const mapStateToProps = (state: any) => {
  const loadingState = selectMarketplacePageLoadingState(state);
  return {
    featuredNftsIds: selectMarketplacePageFeaturedNftsIds(state),
    collectableNftsIds: selectMarketplacePageCollectableNftsIds(state),
    creators: selectMarketplacePageFeaturedCreators(state),
    isFeaturedListLoading: loadingState.featuredNftsLoading,
    isNftListLoading: loadingState.collectableNftsLoading,
    hideShowMoreButton: true,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  loadData: () => dispatch(LoadMarketplaceDataCommand.create()),
});

export const MarketplacePage = connect(mapStateToProps, mapDispatchToProps)(MarketplacePageView);
