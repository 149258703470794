import {UserData} from '../../../models/auth/types';
import {AnyAction} from 'redux';
import {UserDataDocument} from './actions';

export const userDataReducer = (userData: UserData | null = null, action: AnyAction): UserData | null => {
  if (action.type === UserDataDocument.type) {
    return action.payload;
  }
  return userData;
};
