import {useContext, useMemo} from 'react';
import {NftType, AvailabilityStatus} from '@cere/services-types';
import {Box, Typography} from '@material-ui/core';
import {Trans} from 'react-i18next';
import {Link} from 'react-router-dom';
import clsx from 'clsx';

import {formatPrice} from '../../lib/formatPrice';
import {NftContext} from '../Nft/nft-context-v2';
import {useLocalization} from '../../hooks/use-locale.hook';
import {useStyles} from './styles';
import {NftActionButton} from '../ActionButtons';

export const NFTAdditionsCard = () => {
  const {nft, price, auction} = useContext(NftContext);

  const {t, locale} = useLocalization();
  const classes = useStyles({comingSoon: false});

  const link = `/${locale}/home/nft/${nft.id}`;
  const isAuction = nft.nftType === NftType.AUCTIONED;
  const isComingSoon = AvailabilityStatus.COMING_SOON === nft.availability;
  const priceText = useMemo(
    () =>
      nft.nftType !== NftType.AUCTIONED
        ? `${formatPrice(price.usd)}`
        : `${auction.priceText} ${formatPrice(price.usd)}`,
    [auction.priceText, nft.nftType, price],
  );

  if (!nft) {
    return null;
  }

  return (
    <Box className={classes.container}>
      <Box className={classes.infoBox}>
        {isComingSoon ? (
          <Box className={classes.imageBox}>
            <img className={clsx(classes.image, classes.comingSoonImage)} src={nft.image} alt="" />
            <Typography className={classes.comingSoonText}>{t('Coming soon')}</Typography>
          </Box>
        ) : (
          <Link to={link}>
            <Box className={classes.imageBox}>
              <img className={classes.image} src={nft.image} alt="" />
            </Box>
          </Link>
        )}

        <Box className={classes.textBox}>
          <Box>
            {isComingSoon ? (
              <Typography className={classes.title}>{nft.title}</Typography>
            ) : (
              <Link to={link} className={classes.link}>
                <Typography className={classes.title}>{nft.title}</Typography>
              </Link>
            )}
            {!isComingSoon && (
              <Typography className={classes.subTitle}>
                {isAuction ? (
                  t('1 Auctioned Original')
                ) : (
                  <Trans
                    i18nKey="<strong>{{leftAmount}}</strong>/{{totalAmount}} NFTs left"
                    values={{leftAmount: nft.balance, totalAmount: nft.supply || 0}}
                    count={nft.balance}
                  />
                )}
              </Typography>
            )}
          </Box>
          <Typography className={classes.price}>{priceText}</Typography>
        </Box>
      </Box>
      <Box className={classes.buttonBox}>
        <NftActionButton />
      </Box>
    </Box>
  );
};
