import {CmsHistoryResult} from './user-payment-history.context';
import {getNftsOwnedByWallet} from '../../shared/services/nft.service';

export function getNftsForWallets(wallets: string[]): Promise<CmsHistoryResult[]> {
  return Promise.all(wallets.map((wallet) => getNftsOwnedByWallet(wallet))).then((nftsByWallets) =>
    nftsByWallets
      .map((nftsByWallet, index) =>
        nftsByWallet.map((nft) => ({
          wallet: wallets[index],
          updated_at: '',
          nft_id: {
            nft_id: nft.nftId,
          },
        })),
      )
      .flat(),
  );
}
