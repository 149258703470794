import {useState} from 'react';
import useSWR from 'swr';
import {ExhibitionStatus} from '@cere/services-types';
import {useLocalization} from '../../shared/hooks/use-locale.hook';
import {exhibitApi} from '../index';

type Params = {
  timelineStatus?: ExhibitionStatus;
};

export const useAllExhibits = (params?: Params) => {
  const {locale} = useLocalization();
  const [loading, setLoading] = useState<boolean>(false);
  const {data: exhibits, error} = useSWR(`exhibits-timelineStatus=${params?.timelineStatus}`, async () => {
    setLoading(true);
    const exhibits = await exhibitApi.getAllExhibits(locale, params?.timelineStatus);
    setLoading(false);
    return exhibits;
  });

  return {
    loading,
    exhibits,
    error,
  };
};
