import {gql} from '@apollo/client';
import {UPLOAD_FILE_FRAGMENT} from '../../shared/queries/fragments';
import {EXHIBITION_MAIN} from '../../shared/queries/exhibitions-fragments';
import {NFT_FRAGMENT} from '../../shared/queries/nfts';

export const BANNER_FRAGMENT = gql`
  ${NFT_FRAGMENT}
  ${EXHIBITION_MAIN}
  ${UPLOAD_FILE_FRAGMENT}
  fragment cmsBanner on ComponentCmsHomeBannerVisual {
    id
    cms_nft {
      ...nftFragment
      cmsCreator {
        id
        name
        avatar {
          ...Media
        }
        cmsExhibitions {
          id
          nfts {
            id
            cmsNft {
              id
            }
          }
        }
      }
    }
    cms_exhibit {
      ...ExhibitionMain
    }
    desktop {
      ...Media
    }
    mobile {
      ...Media
    }
    tablet {
      ...Media
    }
  }
`;

export const FEATURED_ROW_FRAGMENT = gql`
  ${NFT_FRAGMENT}
  fragment cmsFeaturedRow on ComponentCmsMarketplaceNft {
    id
    featuredNfts {
      ...nftFragment
    }
  }
`;

export const CMS_HOMES = gql`
  ${BANNER_FRAGMENT}
  ${FEATURED_ROW_FRAGMENT}
  query Homes($limit: Int, $locale: String) {
    homes: cmsHomes(limit: $limit, locale: $locale) {
      id
      home_banner_visual {
        ...cmsBanner
      }
      home_featured_row {
        ...cmsFeaturedRow
      }
      seoTitle
      seoDescription
      seoCanonical
      seoImage {
        ...Media
      }
    }
  }
`;
