import {makeStyles} from '@material-ui/core';
import colors from '../../../styles/colors';

export const useFiltersStyles = makeStyles((theme) => ({
  root: {
    '& .MuiDialog-paper': {
      width: '100vw',
      height: '100vh',
      maxWidth: 'none',
      maxHeight: 'none',
      margin: 0,
      padding: 0,
      background: colors.white,
      borderRadius: 0,
    },
  },
  desktopContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',

    '& .collapsible-container': {
      [theme.breakpoints.up('lg')]: {
        width: '415px',
        margin: 0,
      },
    },
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '12px 1rem',
    borderBottom: `1px solid ${colors.lighter}`,
    [theme.breakpoints.up('md')]: {
      padding: '1rem 0',
      margin: '0 40px',
    },
  },
  headerText: {
    fontWeight: 600,
    fontSize: '1rem',
    lineHeight: '22px',
    color: colors.primaryDark,
  },
  collapsible: {
    boxShadow: 'none',
    '& > .MuiBox-root': {
      padding: '1rem',
      '& p': {
        fontWeight: 600,
        fontSize: '1rem',
        lineHeight: '22px',
      },
    },
    '& > .MuiBox-root:first-child': {
      [theme.breakpoints.up('lg')]: {
        padding: '1rem 1rem 4px 1rem',
      },
    },
  },
  container: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    '& > .MuiCard-root': {
      boxShadow: 'none',
      backgroundColor: colors.snowWhite,
      margin: '0 0 10px 0',
      [theme.breakpoints.up('lg')]: {
        margin: 0,
      },
    },
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  },
  controlsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '20px 26px',
    backgroundColor: colors.snowWhite,
    [theme.breakpoints.up('md')]: {
      justifyContent: 'center',
    },
    [theme.breakpoints.up('lg')]: {
      justifyContent: 'end',
      padding: 0,
      backgroundColor: colors.light,
      marginTop: '2rem',
    },
  },
  filterContent: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  input: {
    width: '149.5px',
    '& .MuiOutlinedInput-root': {
      borderRadius: '12px',
    },
    '& fieldset': {
      border: `1px solid ${colors.lighter}`,
    },
  },
  priceInput: {
    '--gap': '12px',
    width: 'calc(50% - var(--gap) / 2)',
  },
  select: {
    width: '100%',
    '& fieldset': {
      border: `1px solid ${colors.lighter}`,
      borderRadius: '12px',
    },
  },
  datePicker: {
    width: '100%',
  },
  controlButton: {
    width: '160px',
    height: '36px',
    padding: '10px 18px',
    borderRadius: '30px',
    color: colors.primaryDark,
    border: `1px solid ${colors.primaryDark}`,
    textTransform: 'none',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',
    [theme.breakpoints.up('md')]: {
      width: '322px',
      height: '44px',
    },
    [theme.breakpoints.up('lg')]: {
      width: '201.5px',
      height: '48px',
    },
  },
  blackButton: {
    color: colors.light,
    backgroundColor: colors.primaryDark,
    [theme.breakpoints.up('md')]: {
      marginLeft: '12px',
    },

    '&:hover': {
      backgroundColor: colors.primaryDark,
    },
  },
}));
