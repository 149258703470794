import {useMemo} from 'react';
import {Box, Theme, useMediaQuery} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {Avatar, Button, Typography} from '@cere/rxb-template-ui-kit';
import {useQuery} from '@apollo/client';
import clsx from 'clsx';

import {CmsCreator, CmsExhibition, CreatorAuction, ExchangeRate} from '../../../types/graphql';
import {CERE_EXCHANGE_RATE} from '../../../queries';
import {buildAbsoluteCmsUrl} from '../../../queries/utils';
import {getMediaUrl} from '../../../lib/media';
import colors from '../../../../styles/colors';
import {useSingletonPollNftStatus} from '../../../hooks/nft-status-poll.hook';
import {SyntheticEvent, useEffect} from 'react';
import {getIsPaymentPending} from '../../../helpers/paymentStatus';
import {GoogleAnalyticsId} from '../../../../analytics-ids';
import {link, StyledLink} from '../../StyledLink';
import {isString} from '../../../types/is-string';
import {VerifiedAuthorBadge} from '../../VerifiedAuthorIcon';
import {NftProvider2} from '../../Nft/nft-provider-v2';
import {ExclusiveNftCard} from '../../Overlay';
import {useLocalization} from '../../../hooks/use-locale.hook';
import {useExhibitNftsBySlug} from '../../../../api/hooks/use-exhibit-nfts-by-slug';
import {NftType} from '@cere/services-types';

const useStyles = makeStyles((theme) => ({
  scrollable: {
    overflowY: 'auto',
    maxWidth: '100%',
    display: 'flex',
    flex: '0 0 auto',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
    [theme.breakpoints.only('md')]: {
      flexDirection: 'column',
    },

    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  dialogAuthor: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    '& .MuiAvatar-root': {
      marginRight: '8px',
    },
  },
  showMore: {
    flex: '0 0 auto',
    background: 'linear-gradient(87.67deg, #92FE9D 3.35%, #00C9FF 109.43%)',
    borderRadius: '20px',
    height: '40px',
    width: '200px',
    transform: 'scale(1)',
    animation: '$pulse 2s infinite',
    border: '1.5px solid rgba(146, 254, 157, 0.7)',
    borderWidth: '1.5px',
    borderImageSlice: '1',
    color: colors.primaryDark,
    margin: '0 auto',
    display: 'block',

    '&:hover': {
      border: '1.5px solid #F8F8FA',
    },
  },
  cardMedium: {
    flex: '0 0 360px',
    marginRight: '12px',
    marginBottom: 0,
    [theme.breakpoints.down('sm')]: {
      flex: '0 0 auto',
      marginBottom: '12px',
      marginRight: 0,
    },
    [theme.breakpoints.only('md')]: {
      flex: '0 0 360px',
      marginBottom: '12px',
      marginRight: 0,
    },
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '32px',
  },
  '@keyframes pulse': {
    '0%': {
      transform: 'scale(0.95)',
      boxShadow: '0 0 0 0 rgba(146, 254, 157, 0.7)',
    },
    '70%': {
      transform: 'scale(1)',
      boxShadow: '0 0 0 10px rgba(146, 254, 157, 0)',
    },
    '100%': {
      transform: 'scale(0.95)',
      boxShadow: '0 0 0 0 rgba(146, 254, 157, 0)',
    },
  },
  authorName: {
    color: colors.snowWhite,
  },
}));

interface AboutExhibitProps {
  event: CmsExhibition;
  artist: CmsCreator;
  onButtonClick: Function;
  onOverlayClick: Function;
  auctionPerNfts: Record<string, CreatorAuction>;
}

const AboutExhibitDialog = ({event, artist, onOverlayClick}: AboutExhibitProps) => {
  const {nfts} = useExhibitNftsBySlug(event?.slug || '');
  const exhibitNfts = useMemo(() => nfts?.filter((nft) => nft.nftType !== NftType.ACCESS), [nfts]);

  const {t, locale} = useLocalization();
  const isMobile = useMediaQuery<Theme>((theme) => `${theme.breakpoints.down('sm')}`);
  const isTablet = useMediaQuery<Theme>((theme) => `${theme.breakpoints.only('md')}`);
  const classes = useStyles();

  const {data: exchangeRate} = useQuery<{creatorExchangeRate: ExchangeRate}>(CERE_EXCHANGE_RATE);

  const {setPollingEnabled, setNftIds, statusesByNftId} = useSingletonPollNftStatus();

  useEffect(() => {
    if (event && exhibitNfts) {
      setNftIds(exhibitNfts.map((nft) => nft.id).filter(isString));
      const isAnyPending = Object.values(statusesByNftId).some((status) => getIsPaymentPending(status));

      isAnyPending ? setPollingEnabled(true) : setPollingEnabled(false);
    }

    return () => setPollingEnabled(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event, exhibitNfts, statusesByNftId]);

  useEffect(() => {
    return () => {
      setNftIds([]);
      setPollingEnabled(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // TODO: TO REMOVE
  if (!exchangeRate) {
    return null;
  }

  return (
    <>
      <Box className={classes.dialogAuthor} mb="24px">
        <Box>
          <Box mb="8px">
            <Typography variant="h1">{event.title}</Typography>
          </Box>
          <StyledLink
            to={`/${locale}/home/creator/${artist.id}`}
            className={clsx(link, GoogleAnalyticsId.ViewCreatorBtn)}
          >
            <Box display="flex" alignItems="center">
              <Box mr="8px">
                <Avatar src={buildAbsoluteCmsUrl(getMediaUrl(artist.avatar))} size="small" />
              </Box>
              <Typography variant="h5" className={classes.authorName}>
                {artist.name} <VerifiedAuthorBadge />
              </Typography>
            </Box>
          </StyledLink>
        </Box>
        {!isMobile && !isTablet && (
          <Box maxWidth="200px" flex="0 0 200px" ml="16px">
            <Button
              color="primary"
              fullWidth
              onClick={onOverlayClick}
              className={clsx(classes.showMore, GoogleAnalyticsId.ExhibitBidBtn)}
            >
              {t('Purchase exclusive NFTs')}
            </Button>
          </Box>
        )}
      </Box>

      <Box mb="24px">
        <Typography variant="body2">{event.description}</Typography>
      </Box>

      {(isMobile || isTablet) && (
        <Box className={classes.buttonWrapper}>
          <Box maxWidth="200px" flex="0 0 200px">
            <Button
              color="primary"
              fullWidth
              onClick={onOverlayClick}
              className={clsx(classes.showMore, GoogleAnalyticsId.ExhibitBidBtn)}
            >
              {t('Purchase exclusive NFTs')}
            </Button>
          </Box>
        </Box>
      )}
      <Box mb="40px">
        <Box mb="16px">
          <Typography variant="h4">{t('More NFTs')}</Typography>
        </Box>
        <Box className={classes.scrollable}>
          {exhibitNfts?.map((nft) => {
            return (
              <Box className={classes.cardMedium} onClick={(e: SyntheticEvent) => e.stopPropagation()}>
                <NftProvider2 nft={nft}>
                  <ExclusiveNftCard showMore={false} nftId={nft.id} />
                </NftProvider2>
              </Box>
            );
          })}
        </Box>
      </Box>
    </>
  );
};

export default AboutExhibitDialog;
