import {Link} from 'react-router-dom';
import {styled} from '@linaria/react';

export const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  button > * {
    pointer-events: none;
  }
`;
