import React, {createContext, useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {NftCardInterface, NftType} from '@cere/services-types';
import {Modal} from '@material-ui/core';
import {IsLoading} from '../shared/components/IsLoading';
import {BuyNFTModal} from '../shared/components/BuyNFTModal';
import {NFTPurchaseConfirmedModal} from '../shared/components/NFTPurchaseConfirmedModal';
import {usePurchaseWithNonCustody} from '../shared/hooks/purchase.hook';
import {BuyNftGuard} from '../shared/components/BuyNftGuard';
import {UserContext} from './user-context/user-context';
import {getPopupUrlByEmailAndNftId} from '../shared/services/payment.service';
import {useNotifications} from '../shared/hooks/use-notifications';
import analyticService, {AnalyticEventsEnum} from '../shared/services/analytic.service';
import {PendingTransactionTypes, usePendingTransactionsContext} from './pending-transaction-context';
import {useSelectedWallet} from './use-selected-wallet';
import {TransactionProcessingModal} from '../shared/components/TransactionProcessingModal';
import {useCheckConnectedMetamaskWallet} from '../shared/hooks/use-check-connected-metamask-wallet';
import {BuyNFTLimitedOrTicketModal} from 'shared/components/BuyNFTLimitedModal';
import {NFTPurchaseDeniedModal} from '../shared/components/NFTPurchaseDeniedModal';
import {useSingletonPollNftStatus} from '../shared/hooks/nft-status-poll.hook';
import {PaymentStatusEnum} from '@cere/services-types';
import {getIsPaymentFailed, getIsPaymentPending, getIsPaymentSuccess} from '../shared/helpers/paymentStatus';
import {usePurchasedNftsPolling} from './pending-transaction-context/use-purchased-nfts-polling';
import {nftApi} from '../api';
import {useStartAuctionFlow} from './auction.context';
import {auctionFactory} from '../model/auction';
import {humanReadableError} from '../shared/lib/error-handler';
import {useLocalization} from '../shared/hooks/use-locale.hook';
import {Optional} from '../shared/types/optional';
import {BuyNFTLimitedOrTicketModalContinue} from '../shared/components/BuyNFTLimitedModalContinue';

type NFTPurchaseData = {
  amount: number;
} & Optional<NftCardInterface, 'createdAt' | 'updatedAt'>;

type NFTPurchaseError = {
  nftId: string;
  message: string;
};

interface PurchaseNFTContextType {
  isPurchaseInProgress: boolean;
  purchase: NFTPurchaseData | null;
  startNFTPurchase: (nftData: NFTPurchaseData, isContinue?: boolean, price?: string) => Promise<void>;
  setPurchaseData: (nftData: NFTPurchaseData | null) => void;
}

enum PurchaseNFTModalTypes {
  PURCHASE_MODAL = 'PURCHASE_MODAL',
  LIMITED_TICKET_PURCHASE_MODAL = 'LIMITED_TICKET_PURCHASE_MODAL',
  LIMITED_TICKET_PURCHASE_MODAL_CONTINUE = 'LIMITED_TICKET_PURCHASE_MODAL_CONTINUE',
  AUCTION_PURCHASE_MODAL = 'AUCTION_PURCHASE_MODAL',
  PURCHASE_CONFIRMED_MODAL = 'PURCHASE_CONFIRMED_MODAL',
  PURCHASE_FAIL_MODAL = 'PURCHASE_FAIL_MODAL',
}

enum PurchaseCallbackTypes {
  BUY_LIMITED_NFT = 'buyLimitedNft',
  BUY_NFT_BY_CARD = 'buyNftByCard',
  BUY_NFT = 'buyNft',
}

const PURCHASE_TRANSACTION_PROCESSING_TIME = 225000; // 3.45 minutes

export const PurchaseNFTContext = createContext<PurchaseNFTContextType>({
  isPurchaseInProgress: false,
  startNFTPurchase: () => Promise.resolve(),
  purchase: null,
  setPurchaseData: () => {},
});

export const secondaryOfferPrice = window.sessionStorage.getItem('selectedOfferPrice');

export const usePurchaseNftContext = () => useContext(PurchaseNFTContext);

export const useStartNFTPurchase = () => {
  const {startNFTPurchase} = useContext<PurchaseNFTContextType>(PurchaseNFTContext);
  return startNFTPurchase;
};

export const usePurchaseInProgress = () => {
  const {isPurchaseInProgress} = useContext<PurchaseNFTContextType>(PurchaseNFTContext);
  return isPurchaseInProgress;
};

export const usePurchaseById = () => {
  const {setPurchaseData, purchase, startNFTPurchase} = useContext<PurchaseNFTContextType>(PurchaseNFTContext);
  const [loadingNftId, setLoadingNftId] = useState<string | null>(null);
  const {locale} = useLocalization();

  const setPurchaseDataById = useCallback(
    async (nftId: string, quantity: number = 1): Promise<NFTPurchaseData | null> => {
      if (nftId === purchase?.id || loadingNftId === nftId) {
        return purchase;
      }
      let ret: null | NFTPurchaseData = null;
      try {
        setLoadingNftId(nftId);
        const nftCard: NftCardInterface | null = await nftApi.getNftById(nftId, locale);
        ret = nftCard ? {...nftCard, amount: quantity} : null;
        setPurchaseData(ret);
      } catch (err) {
        console.error(err);
      } finally {
        setLoadingNftId(null);
      }
      return ret;
    },
    [loadingNftId, purchase, setPurchaseData, locale],
  );

  const startNFTPurchaseById = useCallback(
    async (nftId: string, quantity: number = 1) => {
      const purchaseData = await setPurchaseDataById(nftId, quantity);
      if (purchaseData) {
        await startNFTPurchase(purchaseData);
      } else {
        console.error(`Nft with id ${nftId} is not found`);
      }
    },
    [startNFTPurchase, setPurchaseDataById],
  );

  return {
    startNFTPurchaseById,
    setPurchaseDataById,
    loadingNftId,
    purchaseData: purchase,
  };
};

export const PurchaseNFTContextProvider: React.FC = ({children}) => {
  const {t, locale} = useLocalization();
  const authResolveRef = useRef<(result: boolean) => void>(() => null);
  const {userData} = useContext(UserContext);
  const selectedWallet = useSelectedWallet();
  const {error: errorMessage} = useNotifications();
  const {checkAndShowSwitchNetworkDialog, handleNonCustodyPurchase, showConnectWalletDialog} =
    usePurchaseWithNonCustody();
  const [isOpeningModal, setIsOpeningModal] = useState(false);
  const [isPurchaseInProgress, setPurchaseInProgress] = useState<boolean>(false);
  const [activeModalType, setActiveModalType] = useState<PurchaseNFTModalTypes | null>(null);
  const [prevModalType, setPrevModalType] = useState<PurchaseNFTModalTypes | null>(null);
  const [purchaseCallback, setPurchaseCallback] = useState<PurchaseCallbackTypes | null>(null);
  const [isProcessing, setProcessing] = useState<boolean>(false);
  const [isAuthModalOpened, setAuthModalOpened] = useState<boolean>(false);
  const [purchaseData, setPurchaseData] = useState<NFTPurchaseData | null>(null);
  const [quantity, setQuantity] = useState(1);
  const [purchaseError, setPurchaseError] = useState<NFTPurchaseError | null>(null);
  const [isCountdownVisible, setCountdownVisible] = useState<boolean>(false);
  const {setPendingTransactionForNft} = usePendingTransactionsContext();
  const pendingNftsIds = usePurchasedNftsPolling();
  const checkConnectedMetamaskWallet = useCheckConnectedMetamaskWallet();
  const history = useHistory();
  const location = useLocation();
  const startAuctionFlow = useStartAuctionFlow();

  const closeCountdownModal = useCallback(() => setCountdownVisible(false), [setCountdownVisible]);

  const authenticate = useCallback(async () => {
    if (userData.userPublicKey || userData.userEmail) {
      return true;
    }
  }, [userData.userEmail, userData.userPublicKey]);

  const closeAuthModal = (email?: string) => {
    authResolveRef.current(!!email);
    setAuthModalOpened(false);
  };

  const bidNft = useCallback(
    (nftData: NFTPurchaseData | null = purchaseData) => {
      if (!nftData || !nftData.auction) {
        return;
      }
      const auction = auctionFactory.createAuctionFromNftData(
        nftData.address,
        nftData.auction?.buyer || nftData.minter,
        selectedWallet?.publicKey!,
        nftData.auction?.bids[0]?.price ?? nftData.price,
        nftData.auction?.bids || [],
        nftData.collectionAddress,
      );
      return startAuctionFlow(auction, {
        nftId: nftData.id,
        creatorName: nftData.creatorName,
        title: nftData.title,
        address: nftData.address,
        image: nftData.image || '',
        eventSlug: nftData.exhibitionSlug,
      });
    },
    [purchaseData, selectedWallet?.publicKey, startAuctionFlow],
  );

  const startNFTPurchase = useCallback(
    async (nftData: NFTPurchaseData, isContinue = false, selectedOfferPrice) => {
      setPurchaseInProgress(true);
      setPurchaseData(nftData);
      setIsOpeningModal(true);
      const isAuthenticated = await authenticate();
      if (!isAuthenticated) {
        setIsOpeningModal(false);
        setPurchaseInProgress(false);
        if (!location.pathname.includes(`/home/auth?nftId`)) {
          return;
        }
        if (!location.pathname.includes(`/home/auth`)) {
          history.push(`/${locale}/home/auth?nftId=${nftData.id}`, {
            pathname: window.location.pathname,
            price: selectedOfferPrice,
          });
        }
        return;
      }

      const isConnected = await showConnectWalletDialog();
      if (!isConnected) {
        return;
      }
      try {
        await checkConnectedMetamaskWallet();
      } catch (error) {
        setIsOpeningModal(false);
        errorMessage(error.message);
        return;
      }

      switch (nftData.nftType) {
        case NftType.LIMITED:
        case NftType.ACCESS:
          setActiveModalType(
            isContinue
              ? PurchaseNFTModalTypes.LIMITED_TICKET_PURCHASE_MODAL_CONTINUE
              : PurchaseNFTModalTypes.LIMITED_TICKET_PURCHASE_MODAL,
          );
          break;
        case NftType.AUCTIONED:
          await bidNft(purchaseData);
          break;
        default:
          setActiveModalType(PurchaseNFTModalTypes.PURCHASE_MODAL);
          break;
      }
      setIsOpeningModal(false);
    },
    [
      authenticate,
      showConnectWalletDialog,
      checkConnectedMetamaskWallet,
      errorMessage,
      history,
      location.pathname,
      purchaseData,
      bidNft,
      locale,
    ],
  );

  const finishPurchase = () => {
    setPurchaseInProgress(false);
    setPurchaseData(null);
    setActiveModalType(null);
    setProcessing(false);
  };

  const {setPollingEnabled, statusesByNftId, sessionParamsById} = useSingletonPollNftStatus();

  const buyLimitedNft = useCallback(async () => {
    setPurchaseCallback(PurchaseCallbackTypes.BUY_LIMITED_NFT);
    try {
      setProcessing(true);
      setPollingEnabled(true);
      const {address: nftAddress, sellerWalletAddress, minter, price, collectionAddress} = purchaseData!;

      const isCorrectNetworkSelected = await checkAndShowSwitchNetworkDialog();
      if (!isCorrectNetworkSelected) {
        errorMessage(t('Wrong network selected!'));
        setActiveModalType(null);
        return;
      }

      await handleNonCustodyPurchase(sellerWalletAddress || minter, nftAddress, price, quantity, collectionAddress);
      await setPendingTransactionForNft({
        nftId: nftAddress,
        wallet: selectedWallet?.publicKey!,
        type: PendingTransactionTypes.BUY_NFT,
        qty: quantity,
        timestamp: new Date().toString(),
        price,
      });
      setCountdownVisible(true);
      setActiveModalType(PurchaseNFTModalTypes.PURCHASE_CONFIRMED_MODAL);
    } catch (error) {
      console.error(error);

      const errMessage = t('Error occurred. {{errorDetails}}', {errorDetails: humanReadableError(error)});

      setPurchaseError({message: errMessage, nftId: `${purchaseData?.address}`});

      errorMessage(errMessage);
      setPrevModalType(activeModalType);
      setActiveModalType(PurchaseNFTModalTypes.PURCHASE_FAIL_MODAL);
    } finally {
      setPollingEnabled(false);
      setProcessing(false);
    }
  }, [
    quantity,
    errorMessage,
    handleNonCustodyPurchase,
    purchaseData,
    selectedWallet?.publicKey,
    setPendingTransactionForNft,
    setPollingEnabled,
    checkAndShowSwitchNetworkDialog,
    activeModalType,
    t,
  ]);

  useEffect(() => {
    const nftId = purchaseData?.address ?? '';
    if (statusesByNftId && statusesByNftId[nftId]) {
      const status = statusesByNftId[nftId];
      if (getIsPaymentFailed(status)) {
        setProcessing(false);
        setPollingEnabled(false);
        setActiveModalType(PurchaseNFTModalTypes.PURCHASE_FAIL_MODAL);
      } else if (getIsPaymentSuccess(status)) {
        finishPurchase();
        setPollingEnabled(false);
      }
    }
  }, [purchaseData?.address, setPollingEnabled, statusesByNftId]);

  const buyNftByCard = useCallback(async () => {
    setPurchaseCallback(PurchaseCallbackTypes.BUY_NFT_BY_CARD);
    let win;

    try {
      setProcessing(true);
      const {id: nftId, title: nftTitle, creatorName, address: nftAddress} = purchaseData!;
      win = window.open('', t('Pay buy card'), 'left=100,top=100,width=800,height=600');

      let url;
      const status = statusesByNftId[nftId];
      if (status === PaymentStatusEnum.FIAT_PAYMENT_PENDING) {
        url = sessionParamsById[nftId].paymentUrl;
      } else {
        url = await getPopupUrlByEmailAndNftId({
          buyerEmail: userData.userEmail!,
          nftId: nftAddress,
          quantity,
          locale,
        });
      }

      if (url) {
        analyticService.track(AnalyticEventsEnum.CHECKOUT_STARTED, {
          nftId,
          nftTitle,
          artistName: creatorName,
        });
        await win?.location.replace(url);
      }
    } catch (error) {
      win?.close();
      errorMessage(error.message);
      setPurchaseError(error);
      setPrevModalType(activeModalType);
      setActiveModalType(PurchaseNFTModalTypes.PURCHASE_FAIL_MODAL);
      console.error(error);
    }
  }, [
    errorMessage,
    purchaseData,
    quantity,
    sessionParamsById,
    statusesByNftId,
    userData.userEmail,
    activeModalType,
    t,
    locale,
  ]);

  useEffect(() => {
    if (statusesByNftId && purchaseData) {
      const {id: nftId} = purchaseData;
      const paymentStatus = statusesByNftId[nftId];

      if (getIsPaymentPending(paymentStatus)) {
        setProcessing(true);
      } else {
        setProcessing(false);
      }
    }
  }, [statusesByNftId, purchaseData]);

  const buyNft = useCallback(async () => {
    setPurchaseCallback(PurchaseCallbackTypes.BUY_NFT);
    setProcessing(true);
    const {sellerWalletAddress, minter, price, address: nftAddress, collectionAddress} = purchaseData!;

    try {
      await handleNonCustodyPurchase(sellerWalletAddress || minter, nftAddress, price, quantity, collectionAddress);
      analyticService.track(AnalyticEventsEnum.MARKETPLACE_BUY, {
        nftId: purchaseData?.id!,
      });
      await setPendingTransactionForNft({
        nftId: purchaseData?.address!,
        wallet: selectedWallet?.publicKey!,
        type: PendingTransactionTypes.BUY_NFT,
        qty: quantity,
        timestamp: new Date().toString(),
        price,
      });
      setCountdownVisible(true);
      setActiveModalType(PurchaseNFTModalTypes.PURCHASE_CONFIRMED_MODAL);
    } catch (error) {
      errorMessage(error.message);
      setPurchaseError(error);
      setPrevModalType(activeModalType);
      setActiveModalType(PurchaseNFTModalTypes.PURCHASE_FAIL_MODAL);
      console.error(error);
    } finally {
      setProcessing(false);
    }
  }, [
    errorMessage,
    handleNonCustodyPurchase,
    purchaseData,
    activeModalType,
    selectedWallet?.publicKey,
    quantity,
    setPendingTransactionForNft,
  ]);

  const CALLBACKS = useMemo(
    () => ({
      [PurchaseCallbackTypes.BUY_LIMITED_NFT]: buyLimitedNft,
      [PurchaseCallbackTypes.BUY_NFT_BY_CARD]: buyNftByCard,
      [PurchaseCallbackTypes.BUY_NFT]: buyNft,
    }),
    [buyLimitedNft, buyNftByCard, buyNft],
  );

  const onTryAgainClick = useCallback(() => {
    if (purchaseCallback) {
      setActiveModalType(prevModalType);
      CALLBACKS[purchaseCallback]();
    }
  }, [purchaseCallback, prevModalType, CALLBACKS]);

  const updatePurchaseNftQuantity = useCallback((newQuantity: number) => {
    setQuantity(newQuantity);
  }, []);

  const value = useMemo(
    () => ({
      purchase: purchaseData ?? null,
      isPurchaseInProgress,
      startNFTPurchase,
      updatePurchaseNftQuantity,
      setPurchaseData,
    }),
    [isPurchaseInProgress, purchaseData, startNFTPurchase, updatePurchaseNftQuantity, setPurchaseData],
  );

  return (
    <PurchaseNFTContext.Provider value={value}>
      {children}
      {isOpeningModal && (
        <Modal open>
          <IsLoading />
        </Modal>
      )}
      {purchaseData && (
        <>
          <BuyNftGuard
            nftId={purchaseData.id}
            nftTitle={purchaseData.title}
            nftAddress={purchaseData.address}
            nftImage={purchaseData.image}
            relType={purchaseData.nftType}
            nftPrice={purchaseData.price}
            open={isAuthModalOpened}
            eventId={purchaseData.exhibitionId ? +purchaseData.exhibitionId : undefined}
            onClose={closeAuthModal}
            onSuccess={closeAuthModal}
            button={t('Share email')}
            sellerWalletAddress={purchaseData.sellerWalletAddress}
          />
          <BuyNFTModal
            nftTitle={purchaseData.title}
            nftAddress={purchaseData.address}
            nftImage={purchaseData.image}
            price={purchaseData.price}
            open={activeModalType === PurchaseNFTModalTypes.PURCHASE_MODAL}
            isProcessing={isProcessing}
            onCancel={finishPurchase}
            buyNft={buyNft}
          />
          <BuyNFTLimitedOrTicketModalContinue
            nftId={purchaseData.id}
            nftTitle={purchaseData.title}
            nftImage={purchaseData.image}
            price={Number(secondaryOfferPrice) || purchaseData.priceUsd}
            open={activeModalType === PurchaseNFTModalTypes.LIMITED_TICKET_PURCHASE_MODAL_CONTINUE}
            isProcessing={isProcessing}
            onCancel={finishPurchase}
            buyNft={buyLimitedNft}
            buyNftByCard={buyNftByCard}
            onlyCrypto={true}
          />
          <BuyNFTLimitedOrTicketModal
            nftId={purchaseData.id}
            nftTitle={purchaseData.title}
            nftAddress={purchaseData.address}
            nftImage={purchaseData.image}
            author={purchaseData.creatorName}
            price={purchaseData.priceUsd}
            open={activeModalType === PurchaseNFTModalTypes.LIMITED_TICKET_PURCHASE_MODAL}
            isProcessing={isProcessing}
            onCancel={finishPurchase}
            updateNftQuantity={updatePurchaseNftQuantity}
            buyNft={buyLimitedNft}
            buyNftByCard={buyNftByCard}
            onlyCrypto={true}
            maxCount={purchaseData.amount}
          />
          <NFTPurchaseConfirmedModal
            nftId={purchaseData.id}
            nftTitle={purchaseData.title}
            nftAddress={purchaseData.address}
            nftImage={purchaseData.image}
            price={purchaseData!.priceUsd}
            author={purchaseData!.creatorName}
            quantity={quantity}
            open={activeModalType === PurchaseNFTModalTypes.PURCHASE_CONFIRMED_MODAL}
            onClose={finishPurchase}
          />
          <NFTPurchaseDeniedModal
            nftId={purchaseData.id}
            author={purchaseData.creatorName}
            nftTitle={purchaseData.title}
            nftAddress={purchaseData.address}
            nftImage={purchaseData.image}
            eventSlug={purchaseData!.exhibitionSlug!}
            price={purchaseData!.priceUsd}
            quantity={quantity}
            error={purchaseError!}
            open={activeModalType === PurchaseNFTModalTypes.PURCHASE_FAIL_MODAL}
            onClose={finishPurchase}
            onTryAgainClick={onTryAgainClick}
            isProcessing={isProcessing}
          />
        </>
      )}
      <TransactionProcessingModal
        open={isCountdownVisible && pendingNftsIds.length > 0}
        expectedProcessingTime={PURCHASE_TRANSACTION_PROCESSING_TIME}
        headerText={t('Your transaction is being processed')}
        message={t('Your transaction is currently being processed via our blockchain service.')}
        countdownTitle={t('Approximate time for NFT delivery')}
        onClose={closeCountdownModal}
      />
    </PurchaseNFTContext.Provider>
  );
};
