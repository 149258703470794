import {Box, Typography, makeStyles} from '@material-ui/core';

import {useLocalization} from '../../hooks/use-locale.hook';
import {Title} from '../Title';
import {Skeleton} from './Skeleton';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 'auto',
    marginLeft: 'auto',
    paddingTop: '48px',

    [theme.breakpoints.down('md')]: {
      flexDirection: 'column-reverse',
    },

    [theme.breakpoints.up('md')]: {
      paddingTop: '60px',
    },

    [theme.breakpoints.up('lg')]: {
      paddingTop: '130px',
    },
  },
  textBox: {
    flexGrow: 2,
  },
  title: {
    paddingBottom: '24px',
    [theme.breakpoints.up('lg')]: {
      paddingBottom: '42px',
    },
  },
  item: {
    display: 'flex',
    marginBottom: '20px',

    '&:not(:last-child)': {
      marginBottom: '20px',

      [theme.breakpoints.up('lg')]: {
        marginBottom: '44px',
      },
    },
  },
  itemImage: {
    width: '36px',
    height: '36px',
    marginRight: '20px',
    [theme.breakpoints.up('md')]: {
      marginRight: '16px',
    },
    [theme.breakpoints.up('lg')]: {
      marginRight: '32px',
      width: '60px',
      height: '60px',
    },
  },
  itemTitle: {
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '22px',
    color: theme.palette.primary.dark,
    [theme.breakpoints.up('lg')]: {
      fontWeight: 700,
      fontSize: '20px',
      lineHeight: '26px',
    },
  },
  itemSubtitle: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '21px',
    color: theme.palette.grey[700],
  },
  image: {
    width: '335px',
    height: '425px',
    borderRadius: '12px',
    marginBottom: '24px',

    [theme.breakpoints.up('md')]: {
      flexGrow: 0,
      width: '278px',
      height: '351px',
      borderRadius: '12px',
      marginLeft: '28px',
      marginBottom: 0,
    },
    [theme.breakpoints.up('lg')]: {
      width: '604px',
      height: '756px',
      borderRadius: '24px',
      marginLeft: '48px',
    },
  },
}));

export const AboutUpcomingAuctionInfoSkeleton = () => {
  const {t} = useLocalization();

  const styles = useStyles();

  return (
    <Box className={styles.root}>
      <Box className={styles.textBox}>
        <Box className={styles.title}>
          <Title>{t('About the upcoming live auction')}</Title>
        </Box>
        <Box className={styles.item}>
          <Skeleton variant="circle" className={styles.itemImage} />
          <Box>
            <Typography className={styles.itemTitle}>{t('View unseen video')}</Typography>
            <Typography className={styles.itemSubtitle}>
              {t('Сontent from the Creator, only visible to NFT ticket holders.')}
            </Typography>
          </Box>
        </Box>

        <Box className={styles.item}>
          <Skeleton variant="circle" className={styles.itemImage} />
          <Box>
            <Typography className={styles.itemTitle}>{t('Auction NFTs to bid')}</Typography>
            <Typography className={styles.itemSubtitle}>
              {t('Unique NFTs that can be purchased with the winning auction bid')}
            </Typography>
          </Box>
        </Box>

        <Box className={styles.item}>
          <Skeleton variant="circle" className={styles.itemImage} />
          <Box>
            <Typography className={styles.itemTitle}>{t('Limited NFTs to buy')}</Typography>
            <Typography className={styles.itemSubtitle}>{t('Limited items available for direct purchase')}</Typography>
          </Box>
        </Box>
      </Box>

      <Skeleton variant="rect" className={styles.image} />
    </Box>
  );
};
