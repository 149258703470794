import {findActualAuction} from '../../../shared/components/Nft/utils';
import {isAuctionHasBids} from '../../../shared/services/auction.service';
import {useUserWalletsPubKeys} from '../../../context/use-user-wallets-pub-keys';
import {useExchangeRate} from '../../../shared/hooks/use-exchange-rate';
import {useContext, useMemo} from 'react';
import {centToUSD, cereToUSD, formatPrice} from '../../../shared/lib/formatPrice';
import {Box, makeStyles} from '@material-ui/core';
import clsx from 'clsx';
import {CmsExhibitionNftRelType, CmsNft} from '../../../shared/types/graphql';
import {Typography} from '@cere/rxb-template-ui-kit';
import {useStyles} from './styles';
import {sortBy} from 'lodash';
import {UserPaymentHistoryContext} from '../../../context/payments-history/user-payment-history.context';

type NftInfoProps = {
  nft: CmsNft;
  nftType: CmsExhibitionNftRelType | undefined;
  limit: number;
  balance: number;
  isNftDetails?: boolean;
  qtyOwned: number;
};

const useStylesNftDetails = makeStyles((theme) => ({
  infoWrapper: {
    flexDirection: 'row',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'column',
      marginBottom: '12px',
    },
  },
  price: {
    [theme.breakpoints.up('lg')]: {
      fontWeight: 700,
      fontSize: '20px',
      lineHeight: '26px',
    },
  },
  priceText: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '21px',
  },
}));

export const NftInfo = ({nft, nftType, isNftDetails = false, qtyOwned}: NftInfoProps) => {
  const classes = useStyles();
  const nftDetailsClasses = useStylesNftDetails();
  const {getFiatPaymentHistoryItem} = useContext(UserPaymentHistoryContext);
  let priceText = '';
  const actualAuction = findActualAuction(nft);
  let hasBids = isAuctionHasBids(actualAuction);
  const wallets = useUserWalletsPubKeys();

  if (hasBids) {
    priceText = 'Top bid';
  } else {
    priceText = 'Starting price';
  }

  const unitPerPenny = useExchangeRate();

  const sourcePrice = useMemo(() => {
    const creatorNft = nft?.creatorNft;
    const actualAuction = creatorNft?.creator_auctions.find((auction) => !auction.is_settled);
    if (nftType === CmsExhibitionNftRelType.AUCTIONED) {
      if (!actualAuction) {
        return 0;
      }
      const latestBid = actualAuction.creator_auction_bids?.[0];
      return latestBid ? latestBid.price : actualAuction.price;
    }
    return sortBy(creatorNft?.creator_make_offer ?? [], 'published_at')[0]?.price ?? 0;
  }, [nft?.creatorNft, nftType]);

  const purchasePrice = useMemo(() => {
    if (nftType === CmsExhibitionNftRelType.ACCESS) {
      const fiatPayment = getFiatPaymentHistoryItem(nft?.creatorNft?.nft_id);
      return fiatPayment
        ? formatPrice(centToUSD(fiatPayment.nftPrice))
        : formatPrice(cereToUSD(sourcePrice, unitPerPenny));
    }
    const lastTakeOffer = nft?.creatorNft?.creator_take_offer.filter((offer) => wallets.includes(offer.buyer)).shift();
    const resultPrice = lastTakeOffer ? lastTakeOffer.price : sourcePrice;
    return formatPrice(cereToUSD(resultPrice, unitPerPenny));
  }, [
    getFiatPaymentHistoryItem,
    nft?.creatorNft?.creator_take_offer,
    nft?.creatorNft?.nft_id,
    nftType,
    sourcePrice,
    unitPerPenny,
    wallets,
  ]);

  return (
    <Box className={clsx(classes.infoWrapper, isNftDetails && nftDetailsClasses.infoWrapper)}>
      <Box className={classes.qtyOwnedLabel}>{qtyOwned} owned by you</Box>

      {nftType === CmsExhibitionNftRelType.AUCTIONED ? (
        <Typography variant="caption1">
          <Typography variant="h4" component="span" className={clsx(nftDetailsClasses.priceText)}>
            {priceText}&nbsp;
          </Typography>
          <Typography variant="h4" component="span" className={clsx(nftDetailsClasses.price)}>
            {purchasePrice}
          </Typography>
        </Typography>
      ) : (
        <Typography variant="h4" className={nftDetailsClasses.price}>
          {purchasePrice}
        </Typography>
      )}
    </Box>
  );
};
