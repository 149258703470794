import {CssBaseline, Slide, useMediaQuery, useTheme} from '@material-ui/core';
import {ApolloProvider} from '@apollo/client';
import {SnackbarProvider} from 'notistack';
import {SingletonHooksContainer} from 'react-singleton-hook';
import {AppProvider} from './context/app-provider';
import DialogProvider from './context/dialog-provider';
import {ErrorsContainer} from './shared/components/ErrorsContainer';
import {apolloClient} from './apollo-client';
import {UserContextProvider} from './context/user-context/user-context-provider';
import packageJson from '../package.json';
import {ConnectWalletDialog} from './shared/components/ConnectWalletDialog';
import {SwitchNetworkDialog} from './pages/ExhibitPage/SwitchNetworkDialog';
import {SwitchNetworkContextProvider} from './context/switch-network-context';
import {UserPaymentHistoryProvider} from './context/payments-history/user-payment-history.context';
import {PendingTransactionContextProvider} from './context/pending-transaction-context';
import {TorusWalletDialogProvider, TorusWalletProvider} from './context/torus-context';
import {useStyles} from './styles/shared-styles';
import {TransactionalNotificationsContextProvider} from './context/transactional-notifications-context';
import {MessagesContextProvider} from './context/messages-context';
import {VerifyEmailGuard} from './shared/components/VerifyEmailGuard';
import {UserConsentPopup} from './shared/components/UserConsentPopup';
import {AppRouter} from './router';
import {LocalizationContextProvider} from './context/localization-context';
import './i18n';
import {appStore} from './app-v2/redux';
import {Provider} from 'react-redux';
import TagManager from 'react-gtm-module';
import {gtmId} from './config/common';

if (gtmId()) {
  TagManager.initialize({
    gtmId: gtmId(),
  });
}

export function App() {
  console.log(
    `%cClient App Version: ${packageJson.version}`,
    ` color:white; background-color:black; border-left: 1px solid yellow; padding: 4px;`,
  );
  const theme = useTheme();
  const isTabletOrAbove = useMediaQuery(theme.breakpoints.up('md'));
  const classes = useStyles();

  return (
    <Provider store={appStore.store}>
      <ApolloProvider client={apolloClient}>
        <CssBaseline>
          <SnackbarProvider
            maxSnack={isTabletOrAbove ? 3 : 2}
            autoHideDuration={5000}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            classes={{containerRoot: classes.snackBar}}
            TransitionComponent={Slide as any}
          >
            <LocalizationContextProvider>
              <AppProvider>
                <TorusWalletDialogProvider>
                  <UserContextProvider>
                    <TorusWalletProvider>
                      <UserPaymentHistoryProvider>
                        <PendingTransactionContextProvider>
                          <MessagesContextProvider>
                            <TransactionalNotificationsContextProvider>
                              <SingletonHooksContainer />
                              <ErrorsContainer />
                              <SwitchNetworkContextProvider>
                                <DialogProvider>
                                  <AppRouter />
                                </DialogProvider>
                                <UserConsentPopup />
                                <ConnectWalletDialog />
                                <SwitchNetworkDialog />
                                <VerifyEmailGuard />
                              </SwitchNetworkContextProvider>
                            </TransactionalNotificationsContextProvider>
                          </MessagesContextProvider>
                        </PendingTransactionContextProvider>
                      </UserPaymentHistoryProvider>
                    </TorusWalletProvider>
                  </UserContextProvider>
                </TorusWalletDialogProvider>
              </AppProvider>
            </LocalizationContextProvider>
          </SnackbarProvider>
        </CssBaseline>
      </ApolloProvider>
    </Provider>
  );
}
