import {Box, Select, MenuItem, Typography} from '@material-ui/core';
import {useCallback, ChangeEvent, useState} from 'react';

import USFlag from '../../../assets/flags/united-states.png';
import PortugalFlag from '../../../assets/flags/portugal.png';
import {AVAILABLE_LANGUAGES} from '../../../config/common';
import {useStyles} from './styles';
import {useLocalization} from '../../hooks/use-locale.hook';

export const LanguageSelect = () => {
  const {t, locale, updateLocale} = useLocalization();
  const classes = useStyles();
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  const languagesList = [
    {label: t('English'), flagImage: USFlag, value: 'en'},
    {label: t('Portugal'), flagImage: PortugalFlag, value: 'pt'},
  ].filter(({value}) => AVAILABLE_LANGUAGES().includes(value));

  const onLanguageSelect = useCallback(
    async (event: ChangeEvent<{value: unknown}>) => {
      if (
        typeof event.target.value === 'string' &&
        languagesList.map(({value}) => value).includes(event.target.value)
      ) {
        setIsDisabled(true);

        try {
          await updateLocale(event.target.value as string);
        } finally {
          setIsDisabled(false);
        }
      }
    },
    [languagesList, updateLocale],
  );

  return (
    <Box>
      <Select
        variant="outlined"
        className={classes.selectWrapper}
        value={locale}
        onChange={onLanguageSelect}
        disabled={isDisabled}
      >
        {languagesList.map((language) => (
          <MenuItem value={language.value} key={language.value}>
            <img className={classes.flagImage} src={language.flagImage} alt={language.label} />
            <Typography component="span" className={classes.languageTitle}>
              {language.label}
            </Typography>
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};
