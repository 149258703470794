import {ApolloError, gql, useQuery} from '@apollo/client';

const creatorNftWithWallets = gql`
  query getCreatorNftWithWallets($nftId: String) {
    creatorNfts(where: {nft_id: $nftId}) {
      supply
      minter
      creator_wallet_nfts(where: {quantity_gt: 0}) {
        wallet
        quantity
      }
    }
  }
`;

type Result = {
  supply: number;
  minter: string;
  creator_wallet_nfts: Array<{
    wallet: string;
    quantity: number;
  }>;
};

export function useNftBalance(nftId: string | undefined): {
  data: number;
  loading: boolean;
  error: ApolloError | undefined;
} {
  const {data, error, loading} = useQuery<{creatorNfts: [Result?]}>(creatorNftWithWallets, {
    variables: {nftId},
    skip: !nftId,
  });
  const creatorNft = data ? data.creatorNfts[0] : void 0;

  const value = creatorNft
    ? creatorNft.supply -
      creatorNft.creator_wallet_nfts.reduce(
        (acc, {wallet, quantity}) => (wallet === creatorNft.minter ? acc : acc + quantity),
        0,
      )
    : 0;
  return {data: value, loading, error};
}
