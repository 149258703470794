import {makeStyles} from '@material-ui/core';
import dayjs from 'dayjs';
import {Box} from '@material-ui/core';
import {ReactComponent as NotStartedTimer} from '../../../assets/icons/notStartedTimer.svg';
import {ReactComponent as LiveTimer} from '../../../assets/icons/liveTimer.svg';
import colors from 'styles/colors';
import clsx from 'clsx';
import {t} from 'i18next';
import {Trans} from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  container: {
    minWidth: '299px',
    minHeight: '98px',
    color: '#F8F8FA',
    background: 'rgba(22, 22, 22, 0.5)',
    padding: '18px 10px',
    borderRadius: '12px',
    display: 'flex',
    flexDirection: 'column',
    boxShadow: '0 4px 20px 4px rgba(255, 0, 0, 0.15)',
    backdropFilter: 'blur(30px)',

    [theme.breakpoints.up('lg')]: {
      minWidth: '432px',
      minHeight: '62px',
      border: '1px solid rgba(255, 81, 81, 0.15)',
      flexDirection: 'row',
      padding: '16px 0 16px 16px',
    },
  },
  liveExhibit: {
    padding: '4px 14px 2px 6px',
    background: colors.red,
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '20px',
    color: colors.light,
    position: 'relative',
    width: 'max-content',
    borderRadius: '6px',
    margin: '0 auto 14px',
    order: 1,

    '&::after': {
      content: '""',
      width: '4px',
      height: '4px',
      borderRadius: '50%',
      background: colors.light,
      position: 'absolute',
      right: '6px',
      top: '11px',
    },

    [theme.breakpoints.up('lg')]: {
      order: 2,
      margin: 'unset',
      marginLeft: '24px',
      fontSize: '14px',
    },
  },
  dateBlock: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    order: 2,
    [theme.breakpoints.up('lg')]: {
      order: 1,
    },
  },
  dateAndTime: {
    paddingLeft: '14px',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '22px',
    color: colors.snowWhite,
    '& strong': {
      color: colors.lighter,
      fontWeight: 600,
    },
  },
  notStartedContainer: {
    minWidth: '193px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row',
      minWidth: 'unset',
    },
  },
  dateAndTimeNotStarted: {
    paddingLeft: '0',
    width: 'content',
    marginBottom: '32px',
    [theme.breakpoints.up('lg')]: {
      margin: 'auto 0 auto 12px',
    },
  },
  notStartedTimer: {
    width: '12px',
    height: '12px',
    [theme.breakpoints.up('lg')]: {
      width: '16px',
      height: '16px',
    },
  },

  notStartedTimerContainer: {
    margin: '0 auto 8px auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '44px',
    height: '44px',
    background: 'rgba(26, 204, 151, 0.07)',
    backdropFilter: 'blur(30px)',
    borderRadius: '30px',

    [theme.breakpoints.up('lg')]: {
      margin: 'unset',
      width: '36px',
      height: '36px',
    },
  },
}));

export interface CalendarProps {
  endDate: string | undefined;
  startDate: string | undefined;
  classes?: Partial<Record<'container' | 'liveExhibit' | 'dateAndTime', string>>;
}

export const Calendar = ({endDate, startDate, classes}: CalendarProps) => {
  const styles = useStyles();
  const isNotStartedExhibit = dayjs(endDate) > dayjs() && dayjs(startDate) < dayjs();
  const time = dayjs(endDate).format('hh:mm a');

  return (
    <>
      {isNotStartedExhibit ? (
        <Box className={clsx(styles.container, classes?.container)}>
          <Box className={styles.dateBlock}>
            <LiveTimer />
            <Box className={clsx(styles.dateAndTime, classes?.dateAndTime)}>
              <Trans
                i18nKey="Ends: {{date}} <strong>at</strong> {{time}}"
                values={{date: dayjs(endDate).format('MM.DD.YYYY'), time}}
              />
            </Box>
          </Box>

          <Box className={clsx(styles.liveExhibit, classes?.liveExhibit)}>{t('Exhibit LIVE!')}</Box>
        </Box>
      ) : (
        <Box className={clsx(styles.notStartedContainer, classes?.container)}>
          <Box className={styles.notStartedTimerContainer}>
            <NotStartedTimer className={styles.notStartedTimer} />
          </Box>
          <Box className={clsx(styles.dateAndTime, styles.dateAndTimeNotStarted, classes?.dateAndTime)}>
            {startDate ? (
              <Trans
                i18nKey="{{date}} <strong>at</strong> {{time}}"
                values={{date: dayjs(startDate).format('MM.DD.YYYY'), time}}
              />
            ) : (
              t('Live auction date coming soon')
            )}
          </Box>
        </Box>
      )}
    </>
  );
};
