import {createContext, ReactNode, useContext, useMemo, useState} from 'react';
import {BrightcovePlayerInterface} from './brightcove-player-interface';

type PlayerContextType = {
  player?: BrightcovePlayerInterface;
  setPlayer: (player: BrightcovePlayerInterface) => unknown;
};

const PlayerContext = createContext<PlayerContextType>({
  setPlayer: () => null,
});

export const usePlayer = (): PlayerContextType => {
  return useContext(PlayerContext);
};

export const PlayerContextProvider = ({children}: {children: ReactNode}) => {
  const [player, setPlayer] = useState<BrightcovePlayerInterface | undefined>();

  const context = useMemo(
    () => ({
      player,
      setPlayer,
    }),
    [player],
  );

  return <PlayerContext.Provider value={context}>{children}</PlayerContext.Provider>;
};
