import {useCallback} from 'react';
import {Box, Button, makeStyles, Table, TableBody, TableCell, TableRow, Typography} from '@material-ui/core';
import clsx from 'clsx';
import dayjs from 'dayjs';
import {Waypoint} from 'react-waypoint';
import {NFTListingTableProps} from './NFTListingTable';
import {ReactComponent as CurrencyIcon} from '../../../assets/coins/usd_blue.svg';
import {Avatar} from '../Avatar';
import {useListingsCardStyles} from './styles';
import {NFTOffer, NFTOfferTypes} from '../../hooks/use-nft-listing';
import colors from '../../../styles/colors';
import {useStartCancelingNFTListing} from '../../../context/sell-nft-context';
import {PendingTransactionTypes, usePendingTransactionsContext} from '../../../context/pending-transaction-context';
import {Condition, ConditionsList} from '../Conditions';
import {WarningMessage} from '../WarningMessage';
import {useLocalization} from '../../hooks/use-locale.hook';
import {useAuctionFactory} from '../../hooks/use-auction-factory';
import {useSelectedWallet} from '../../../context/use-selected-wallet';
import {BuyFromMinterButton} from '../ActionButtons/BuyFromMinterButton';
import {BidButton} from '../ActionButtons/BidButton';
import {BuyOnMarketplaceButton} from '../ActionButtons/BuyOnMarketplaceButton';
import {TOKEN_DECIMALS} from '../../lib/formatPrice';

const useStyles = makeStyles({
  table: {
    display: 'block',

    '& .MuiTableBody-root': {
      display: 'block',

      '& .MuiTableRow-root': {
        display: 'flex',

        '& .MuiTableCell-root': {
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          padding: '8px 5px 8px 0',
          border: 'none',

          '&:nth-child(1)': {
            overflow: 'unset',
            fontWeight: 600,
            fontSize: '14px',
            lineHeight: '20px',
          },

          '&:nth-child(2)': {
            marginLeft: 'auto',
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '21px',
            color: colors.lightGrey,
            textAlign: 'end',
          },
        },
      },
    },
  },
  firstColumnText: {
    alignItems: 'end',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '21px',
    color: colors.primaryDark,
    '& svg': {
      marginRight: '0.5rem',
    },
    '& .gray': {
      marginLeft: '4px',
      color: colors.lightGrey,
    },
  },
  listTableWrapper: {
    width: '100%',
  },
});

export const NFTListingList = ({offers, loadMoreData, nft, event}: NFTListingTableProps) => {
  const {t} = useLocalization();
  const classes = useStyles();
  const styles = useListingsCardStyles();
  const auctionFactory = useAuctionFactory();
  const selectedWallet = useSelectedWallet();
  const cancelListing = useStartCancelingNFTListing();
  const {getPendingTransactionForNft} = usePendingTransactionsContext();

  const pendingTransaction = getPendingTransactionForNft(nft.address);

  const cancelNftListing = useCallback(() => {
    cancelListing(nft.address);
  }, [cancelListing, nft]);

  const renderSellerData = useCallback(
    (offer: NFTOffer) => {
      if (offer.isPrimaryOffer) {
        return <Avatar name={offer.creator!.name} imageUrl={offer.creator!.avatar.url} creatorId={offer.creator?.id} />;
      }
      if (offer.isOwnerOffer) {
        return <Avatar name={t('You')} />;
      }
      return <Typography className={styles.tableCellText}>{offer.seller}</Typography>;
    },
    [styles.tableCellText, t],
  );

  const renderButton = useCallback(
    (offer: NFTOffer) => {
      if (offer.isOwnerOffer) {
        return (
          <Button
            className={clsx(styles.tableButtonList, styles.cancelButton)}
            variant="outlined"
            onClick={cancelNftListing}
          >
            {t('Cancel')}
          </Button>
        );
      }
      if (offer.type === NFTOfferTypes.Auction && nft.auction) {
        const latestBid = nft.price;
        const currentAuction = auctionFactory.createAuctionFromNftData(
          nft.address,
          offer.seller,
          selectedWallet?.publicKey!,
          latestBid,
          nft.auction.bids ?? [],
        );
        return (
          <BidButton
            type="green"
            className={styles.tableButtonList}
            text={t('Place bid')}
            auction={currentAuction}
            nftId={nft.id}
            nftAddress={nft.address}
            nftImage={nft.image ?? ''}
            nftTitle={nft.title}
            nftCreatorName={nft.creatorName}
            nftEventId={event.id.toString()}
            nftEventSlug={event.slug}
            nftRelType={nft.nftType}
          />
        );
      }
      if (offer.isPrimaryOffer) {
        return (
          <BuyFromMinterButton
            type="green"
            className={styles.tableButtonList}
            nftId={nft.id}
            nftAddress={nft.address}
            nftImage={nft.image}
            nftTitle={nft.title}
            nftMinterAddress={offer.seller}
            nftUsdPrice={offer.usdPrice}
            nftCreatorName={nft.creatorName}
            nftCollectionAddress={nft.collectionAddress}
            nftAmount={offer.qty}
            nftEventId={event.id.toString()}
            nftEventSlug={event.slug}
            nftRelType={nft.nftType}
          />
        );
      }
      return (
        <BuyOnMarketplaceButton
          type="green"
          className={styles.tableButtonList}
          nftId={nft.id}
          nftAddress={nft.address}
          nftImage={nft.image}
          nftTitle={nft.title}
          nftMinter={nft.minter}
          nftDescription={nft.description}
          nftCreatorId={nft.creatorId}
          sellerWallet={offer.seller}
          nftUsdPrice={offer.usdPrice}
          nftPrice={offer.usdPrice * TOKEN_DECIMALS}
          nftCreatorName={nft.creatorName}
          nftCollectionAddress={nft.collectionAddress}
          nftAmount={offer.qty}
          nftEventId={event.id.toString()}
          nftEventSlug={event.slug}
          nftRelType={nft.nftType}
        />
      );
    },
    [
      nft.auction,
      nft.id,
      nft.address,
      nft.image,
      nft.title,
      nft.minter,
      nft.description,
      nft.creatorId,
      nft.creatorName,
      nft.collectionAddress,
      nft.nftType,
      nft.price,
      styles.tableButtonList,
      styles.cancelButton,
      event.id,
      event.slug,
      cancelNftListing,
      t,
      auctionFactory,
      selectedWallet?.publicKey,
    ],
  );

  return (
    <Box className={classes.listTableWrapper}>
      {offers.map((offer, index) => (
        <Table classes={{root: classes.table}} key={offer.id}>
          <TableBody>
            <TableRow>
              <TableCell>{t('Price')}</TableCell>
              <TableCell>
                <Typography className={classes.firstColumnText}>
                  <CurrencyIcon />
                  {offer.price.toFixed(2)} <span className="gray">USDC</span>
                  {offers.length > 6 && index === offers.length - 2 && <Waypoint onEnter={loadMoreData} />}
                </Typography>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>{t('USD Price')}</TableCell>
              <TableCell>
                <Typography className={styles.tableCellText}>${offer.usdPrice.toFixed(2)}</Typography>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>{t('Seller')}</TableCell>
              <TableCell>
                <Typography className={styles.tableCellText}>{renderSellerData(offer)}</Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t('Qty')}</TableCell>
              <TableCell>{offer.qty}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t('Timestamp')}</TableCell>
              <TableCell>
                <Typography className={styles.tableCellText}>
                  {dayjs(offer.timestamp).format('MM/DD/YYYY, hh:mm:ss A')}
                </Typography>
              </TableCell>
            </TableRow>
            <ConditionsList>
              <Condition condition={!pendingTransaction || !!offer.isPrimaryOffer || !offer.isOwnerOffer}>
                <TableRow>{renderButton(offer)}</TableRow>
              </Condition>
              <Condition
                condition={
                  !!pendingTransaction &&
                  pendingTransaction.type === PendingTransactionTypes.SELL_NFT &&
                  !!offer.isOwnerOffer
                }
              >
                <TableRow>
                  <TableCell>{t('Status')}</TableCell>
                  <TableCell>
                    <WarningMessage message={t('Transaction processing')} />
                  </TableCell>
                </TableRow>
              </Condition>
            </ConditionsList>
          </TableBody>
        </Table>
      ))}
    </Box>
  );
};
