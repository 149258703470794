import {IPurchaseHistoryApi} from './IPurchaseHistoryApi';
import {PurchasedNft, UserAuction, UserWalletNft} from './types';
import {IGraphQLClient} from '../api-clients/IGraphQLClient';
import {PURCHASED_NFT, USER_AUCTIONS, USER_NFT_OFFER, USER_WALLETS_NFTS} from './purchase-history.query';
import {isPurchasedNft, isUserAuction, isUserNftOffer, isUserWalletNft} from './type-guards';

export class GraphQLPurchaseHistoryApi implements IPurchaseHistoryApi {
  constructor(private readonly client: IGraphQLClient) {}

  async getPurchasedNftsWithWallets(userWalletsAddresses: string[], locale: string): Promise<PurchasedNft[]> {
    const [purchasedNftsFromWallets, purchasedNftsFromAuctions] = await Promise.all([
      this.getUserWalletNfts(userWalletsAddresses, locale),
      this.getUserAuctions(userWalletsAddresses, locale),
    ]);
    return [...purchasedNftsFromWallets, ...purchasedNftsFromAuctions];
  }

  async checkIfUserHasNft(userWalletAddress: string, nftId: string): Promise<boolean> {
    const purchasedNfts = await this.client.makeQuery<
      Array<{quantity: number}>,
      {userWalletAddress: string; nftId: string}
    >(PURCHASED_NFT, {userWalletAddress, nftId}, 'purchasedNfts', isPurchasedNft);
    const ownedQty = purchasedNfts?.[0]?.quantity ?? 0;
    return ownedQty > 0;
  }

  async checkIfNftOffersExists(userWalletAddress: string, nftId: string): Promise<boolean> {
    const offers = await this.client.makeQuery<
      Array<{seller: string; price: number}>,
      {userWalletAddress: string; nftId: string}
    >(USER_NFT_OFFER, {userWalletAddress, nftId}, 'offers', isUserNftOffer);
    return (offers?.length ?? 0) > 0;
  }

  private async getUserWalletNfts(userWalletsAddresses: string[], locale: string): Promise<PurchasedNft[]> {
    const walletNfts = await this.client.makeQuery<UserWalletNft[], {userWallets: string[]; locale: string}>(
      USER_WALLETS_NFTS,
      {userWallets: userWalletsAddresses, locale},
      'walletNfts',
      isUserWalletNft,
    );
    return this.transformUserWalletNftsToPurchasedNfts(walletNfts ?? []);
  }

  private async getUserAuctions(userWalletsAddresses: string[], locale: string): Promise<PurchasedNft[]> {
    const userAuctions = await this.client.makeQuery<UserAuction[], {userWallets: string[]; locale: string}>(
      USER_AUCTIONS,
      {userWallets: userWalletsAddresses, locale},
      'auctions',
      isUserAuction,
    );
    return this.transformUserAuctionsToPurchasedNfts(userAuctions ?? []);
  }

  private transformUserWalletNftsToPurchasedNfts(walletNfts: UserWalletNft[]): PurchasedNft[] {
    return walletNfts
      .map((nft) => ({
        id: nft.nft_id.cmsNfts[0].id,
        userWalletAddress: nft.wallet,
        qty: nft.quantity,
      }))
      .filter((nft) => !!nft.id);
  }

  private transformUserAuctionsToPurchasedNfts(userAuctions: UserAuction[]): PurchasedNft[] {
    return userAuctions
      .map((auction) => ({
        id: auction.nft_id.cmsNfts[0].id,
        userWalletAddress: auction.seller,
        qty: 1,
      }))
      .filter((nft) => !!nft.id);
  }
}
