import {Button} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import clsx from 'clsx';
import {useBuyButtonStyles, useDefaultButtonStyles} from './styles';
import {useStartPrimaryPurchase} from '../../../context/primary-purchase-context';
import {withCheckBeforePurchase, withPrimaryPurchaseContext} from './hocs';
import {DisabledButton} from './DisabledButton';

export interface BuyFromMinterButtonProps {
  type?: 'default' | 'green';
  className?: string;
  text?: string;
  nftId: string;
  nftAddress: string;
  nftMinterAddress: string;
  nftTitle: string;
  nftImage?: string;
  nftCreatorName: string;
  nftUsdPrice: number;
  nftAmount: number;
  nftEventSlug: string;
  disabled?: boolean;
  nftCollectionAddress?: string;
  checkBeforePurchase?: () => void;
  quantity?: number;
}

const PlainBuyFromMinterButton = ({
  type = 'default',
  className,
  text,
  nftId,
  nftAddress,
  nftMinterAddress,
  nftTitle,
  nftImage,
  nftCreatorName,
  nftUsdPrice,
  nftAmount,
  nftEventSlug,
  nftCollectionAddress,
  disabled,
  checkBeforePurchase,
  quantity,
}: BuyFromMinterButtonProps) => {
  const buttonStyles = useDefaultButtonStyles().default;
  const buyButtonStyles = useBuyButtonStyles()[type];

  const {t} = useTranslation();
  const startPrimaryPurchase = useStartPrimaryPurchase();

  const buyFromMinter = async () => {
    checkBeforePurchase?.();
    startPrimaryPurchase({
      nftId,
      nftAddress,
      nftMinterAddress,
      nftTitle,
      nftImage,
      nftCreatorName,
      nftUsdPrice,
      nftAmount,
      nftEventSlug,
      nftCollectionAddress,
      quantity,
    });
  };

  if (disabled) {
    return <DisabledButton text={text || t('Buy')} className={className} />;
  }

  return (
    <Button
      className={clsx(className, buttonStyles, buyButtonStyles)}
      onClick={buyFromMinter}
      color="primary"
      variant="contained"
    >
      {text || t('Buy')}
    </Button>
  );
};

export const BuyFromMinterButton = withPrimaryPurchaseContext(withCheckBeforePurchase(PlainBuyFromMinterButton));
