import {Typography} from '@cere/rxb-template-ui-kit';
import {Box, makeStyles} from '@material-ui/core';
import colors from '../../../../styles/colors';
import {Calendar} from '../../../../shared/components/Calendar';
import {useContext} from 'react';
import {ExhibitContext} from '../../../../context/exhibition/exhibit-context';

const typoColor = {color: colors.light};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    maxWidth: '343px',
    [theme.breakpoints.up('md')]: {
      maxWidth: '720px',
    },
  },
  titlesContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    marginTop: '45px',
    order: 1,
    [theme.breakpoints.up('lg')]: {
      alignItems: 'start',
      marginTop: '32px',
      marginBottom: '8px',
      order: 2,
    },
  },
  calendarContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '16px',
    order: 2,
    [theme.breakpoints.up('lg')]: {
      alignItems: 'start',
      marginTop: '16px',
      order: 1,
    },
  },
}));

const useCalendarStyles = makeStyles((theme) => ({
  container: {
    minHeight: 'unset',
    padding: '8px 16px',
    background: 'rgba(22, 22, 22, 0.5)',
    border: '1px solid rgba(255, 255, 255, 0.1)',
    boxShadow: '0px 0px 16px rgba(26, 204, 151, 0.16)',
    backdropFilter: 'blur(15px)',
    borderRadius: '30px',
    [theme.breakpoints.up('lg')]: {
      padding: '8px 16px',
    },
  },
}));

export const InterstitialEventPageBanner = () => {
  const {event} = useContext(ExhibitContext);
  const classes = useStyles();
  const calendarClasses = useCalendarStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.titlesContainer}>
        <Typography title={event?.subtitle} style={typoColor} variant="h5">
          {event?.subtitle}
        </Typography>
        <Typography title={event?.title} style={typoColor} variant="h1" className={classes.title}>
          {event?.title}
        </Typography>
      </Box>
      <Box className={classes.calendarContainer}>
        <Calendar endDate={event?.endsAt} startDate={event?.startsAt} classes={calendarClasses} />
      </Box>
    </Box>
  );
};
