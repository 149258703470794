import {SyntheticEvent, useCallback, useContext, useEffect} from 'react';
import {Typography} from '@cere/rxb-template-ui-kit';
import {Box} from '@material-ui/core';
import clsx from 'clsx';
import {ReactComponent as Arrow} from '../../../../../assets/arrow.svg';
import {OverlayLandscape} from './overlay-landscape';
import BootstrapLoader from '../../../../../shared/components/bootstrap-loader';
import analyticService, {AnalyticEventsEnum} from '../../../../../shared/services/analytic.service';
import {buildAbsoluteCmsUrl} from '../../../../../shared/queries/utils';
import {getMediaUrl} from '../../../../../shared/lib/media';
import {ExhibitContext} from '../../../../../context/exhibition/exhibit-context';
import {useStyles} from './styles';
import {CmsExhibitionNft} from '../../../../../shared/types/graphql';
import {AuctionContext} from '../context/auction-context';
import {usePlayer} from '../../../../../shared/components/Player/player.context';
import {useScrollToElement} from '../../../../../shared/hooks/scroll-to-element.hook';
import {useLocalization} from '../../../../../shared/hooks/use-locale.hook';

type Updater = (value: boolean) => boolean;

interface AuctionOverlayProps {
  onPlaceBid: (nftId: string) => void;
  onBuy: (nft: CmsExhibitionNft) => Promise<void>;
  setShowMore: (updater: Updater) => unknown;
  showMore: boolean;
}

export const AuctionOverlay = ({onPlaceBid, onBuy, showMore, setShowMore}: AuctionOverlayProps) => {
  const {event, loading} = useContext(ExhibitContext);
  const {setSelectedNftIndex, selectedNftIndex} = useContext(AuctionContext);
  const url = event && buildAbsoluteCmsUrl(getMediaUrl(event?.image));
  const classes = useStyles({url});
  const {player} = usePlayer();
  const {refElement, scrollToElement} = useScrollToElement();
  const {t} = useLocalization();

  const onToggleDetailsClick = useCallback(
    (e: SyntheticEvent) => {
      e.stopPropagation();
      setShowMore((state: boolean) => {
        analyticService.track(AnalyticEventsEnum.AUCTION_TOGGLE_DETAILS_CLICKED, {
          expand: !state,
        });
        return !state;
      });
      setSelectedNftIndex(0);
    },
    [setSelectedNftIndex, setShowMore],
  );

  useEffect(() => {
    player?.pause();
    return () => player?.play();
  }, [player]);

  useEffect(() => {
    showMore && scrollToElement();
  }, [scrollToElement, showMore, selectedNftIndex]);

  if (!event || loading) {
    return <BootstrapLoader />;
  }

  return (
    <Box className={classes.main}>
      <Box pt="60px" marginTop="auto" {...{ref: refElement}}>
        <Typography variant="h4" className={classes.title}>
          {event.title}
        </Typography>
      </Box>

      <OverlayLandscape onPlaceBid={onPlaceBid} onBuy={onBuy} showMore={showMore} setShowMore={setShowMore} />

      <Box className={classes.pointer} onClick={onToggleDetailsClick}>
        <Typography variant="h5">{showMore ? t('Show less info') : t('Show more info')}</Typography>
        <Arrow className={clsx(classes.moreIcon, showMore && classes.moreIconRotate)} />
      </Box>
    </Box>
  );
};
