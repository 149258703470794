import {ReactNode, Children, useRef} from 'react';
import {Box, IconButton, makeStyles} from '@material-ui/core';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Navigation} from 'swiper';

import {ArrowForwardIos} from '@material-ui/icons';
// Import Swiper styles
import 'swiper/swiper.min.css';

import {Title, SubTitle} from '../../primitives/Title';

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  titlesBox: {
    '&:not(:empty)': {
      paddingBottom: '16px',

      [theme.breakpoints.up('lg')]: {
        paddingBottom: '24px',
      },
    },
  },
  headerButtons: {
    display: 'none',
    [theme.breakpoints.up('lg')]: {
      height: '100%',
      minHeight: '40px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
      width: '140px',
      paddingRight: '24px',
      paddingBottom: '24px',
    },
  },
  headerButton: {
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.divider}`,
    color: theme.palette.text.primary,
  },
  rotateIcon: {transform: 'rotate(180deg)'},
  swiper: {
    width: '100%',
    height: '100%',
  },
  slide: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

type Props = {
  children: ReactNode;
  title?: string;
  subTitle?: string;
  slidesPerView?: number;
  gap?: number;
};
export const CuratedRow = ({children, title, subTitle, slidesPerView, gap}: Props) => {
  const styles = useStyles();

  const nextRef = useRef(null);
  const prevRef = useRef(null);

  const childrenArray = Children.toArray(children);

  return (
    <Box>
      <Box className={styles.header}>
        <Box className={styles.titlesBox}>
          {title && <Title>{title}</Title>}
          {title && <SubTitle>{subTitle}</SubTitle>}
        </Box>
        <Box className={styles.headerButtons}>
          <IconButton className={styles.headerButton} ref={prevRef}>
            <ArrowForwardIos className={styles.rotateIcon} />
          </IconButton>
          <IconButton className={styles.headerButton} ref={nextRef}>
            <ArrowForwardIos />
          </IconButton>
        </Box>
      </Box>

      <Swiper
        className={styles.swiper}
        slidesPerView={slidesPerView ?? 1}
        spaceBetween={gap ?? 10}
        navigation={{
          nextEl: nextRef.current,
          prevEl: prevRef.current,
        }}
        modules={[Navigation]}
      >
        {childrenArray.map((childrenElement, index) => (
          <SwiperSlide key={`key=${index}`} className={styles.slide}>
            {childrenElement}
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
};
