import React, {useCallback, useState} from 'react';
import {Button, Divider, makeStyles, Menu, MenuItem, Typography} from '@material-ui/core';
import clsx from 'clsx';
import {ReactComponent as SortIcon} from '../../../assets/icons/sort.svg';
import CheckIcon from '@material-ui/icons/Check';
import {CloseButton} from '../CloseButton';
import colors from '../../../styles/colors';
import {SortingOrder, useFilter} from '../../../context/filters-context';
import {useLocalization} from '../../hooks/use-locale.hook';

const useStyles = makeStyles((theme) => ({
  menuCard: {
    '& .MuiPaper-rounded': {
      borderRadius: '12px',
    },
    '& ul': {
      width: '232px',
      padding: '16px 16px 16px 24px',
    },
    '& li': {
      padding: '8px 0',
      '&:hover': {
        backgroundColor: theme.palette.common.white,
      },
    },
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    height: '40px',
    padding: '10px 18px',
    borderRadius: '30px',
    color: theme.palette.common.black,
    border: `1px solid ${theme.palette.grey[300]}`,
    textTransform: 'none',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',
    backgroundColor: theme.palette.common.white,
    [theme.breakpoints.up('md')]: {
      padding: '12px 20px',
      height: '44px',
      width: '169.5px',
    },
    [theme.breakpoints.up('lg')]: {
      height: '44px',
      width: '193.5px',
    },
  },
  divider: {
    margin: '16px 0 12px 0',
    backgroundColor: colors.lighter,
  },
  headerItem: {
    cursor: 'default',
  },
  menuItem: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  headerText: {
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '22px',
    color: colors.grey,
  },
  menuItemText: {
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '22px',
    color: colors.primaryDark,
  },
  icon: {
    color: theme.palette.primary.main,
  },
}));

export const SortingMenu = () => {
  const {t} = useLocalization();
  const styles = useStyles();
  const {filterParams, setSortingOrder} = useFilter();
  const [anchor, setAnchor] = useState<Element | null>(null);
  const open = (event: React.MouseEvent<HTMLButtonElement>) => setAnchor(event.currentTarget);
  const sorting = filterParams.sorting?.toString();
  const SortingVariants = {
    RECENTLY_ADDED: t('Recently added'),
    LOWEST_PRICE: t('Lowest price'),
    HIGHEST_PRICE: t('Highest price'),
  };

  const close = useCallback(() => setAnchor(null), [setAnchor]);

  const sortByPublicationDate = useCallback(() => {
    setSortingOrder('createdAt', SortingOrder.DESC);
    close();
  }, [setSortingOrder, close]);

  const sortByLowestPrice = useCallback(() => {
    setSortingOrder('price', SortingOrder.ASC);
    close();
  }, [setSortingOrder, close]);

  const sortByHighestPrice = useCallback(() => {
    setSortingOrder('price', SortingOrder.DESC);
    close();
  }, [setSortingOrder, close]);

  const getActiveSortingVariant = () => {
    if (sorting?.includes('createdAt')) {
      return SortingVariants.RECENTLY_ADDED;
    }
    if (sorting?.includes(SortingOrder.ASC)) {
      return SortingVariants.LOWEST_PRICE;
    }
    if (sorting?.includes(SortingOrder.DESC)) {
      return SortingVariants.HIGHEST_PRICE;
    }
    return SortingVariants.RECENTLY_ADDED;
  };

  return (
    <>
      <Button className={styles.button} onClick={open}>
        <SortIcon />
        {getActiveSortingVariant()}
      </Button>
      <Menu className={styles.menuCard} open={!!anchor} anchorEl={anchor} onClose={close} keepMounted>
        <MenuItem className={clsx(styles.menuItem, styles.headerItem)}>
          <Typography className={styles.headerText}>{t('Sort by')}</Typography>
          <CloseButton onClick={close} />
        </MenuItem>
        <Divider className={styles.divider} />
        <MenuItem className={styles.menuItem} onClick={sortByPublicationDate}>
          <Typography className={styles.menuItemText}>{SortingVariants.RECENTLY_ADDED}</Typography>
          {sorting?.includes('createdAt') && <CheckIcon className={styles.icon} fontSize="small" />}
        </MenuItem>
        <MenuItem className={styles.menuItem} onClick={sortByLowestPrice}>
          <Typography className={styles.menuItemText}>{SortingVariants.LOWEST_PRICE}</Typography>
          {sorting?.includes(SortingOrder.ASC) && <CheckIcon className={styles.icon} fontSize="small" />}
        </MenuItem>
        <MenuItem className={styles.menuItem} onClick={sortByHighestPrice}>
          <Typography className={styles.menuItemText}>{SortingVariants.HIGHEST_PRICE}</Typography>
          {sorting?.includes(SortingOrder.DESC) && sorting?.includes('price') && (
            <CheckIcon className={styles.icon} fontSize="small" />
          )}
        </MenuItem>
      </Menu>
    </>
  );
};
