import {useContext} from 'react';
import {UserContext} from '../../context/user-context/user-context';
import {ExhibitContext} from '../../context/exhibition/exhibit-context';
import {NftContext} from '../../shared/components/Nft/nft-context';
import {useAccessTicket} from './use-access-ticket';

export function useUserHasEventAccess(): boolean {
  const {event: eventFromExhibition} = useContext(ExhibitContext);
  const {event: eventFromNft} = useContext(NftContext);
  const event = eventFromExhibition || eventFromNft;
  const {userData} = useContext(UserContext);
  const accessTicket = useAccessTicket();
  const isUserOwnSomeOfAccessNfts = Boolean(accessTicket);

  if (event?.allowFreeAccess) {
    return true;
  }

  if (!userData.userPublicKey || !userData.token) {
    return false;
  }

  return isUserOwnSomeOfAccessNfts;
}
