import {useCallback, useMemo, useState} from 'react';
import {useFilter} from '../../../context/filters-context';

export const useNFTFilterState = () => {
  const [opened, setOpened] = useState<boolean>(false);
  const open = useCallback(() => setOpened(true), [setOpened]);
  const close = useCallback(() => setOpened(false), [setOpened]);

  return {
    opened,
    open,
    close,
  };
};

export const useActiveFiltersNumber = () => {
  const {filterParams} = useFilter();
  return useMemo(
    () => Object.entries(filterParams).filter(([key, value]) => key !== 'sorting' && !!value).length,
    [filterParams],
  );
};
