import {useHistory} from 'react-router-dom';
import {useCallback} from 'react';
import {alpha, Box, CardMedia, makeStyles, Typography} from '@material-ui/core';
import {ReactComponent as VerifiedAuthorIcon} from '../../../assets/icons/verifiedAuthor.svg';
import colors from '../../../styles/colors';
import {useLocalization} from '../../hooks/use-locale.hook';

interface NftModalHighlightCardProps {
  nftId: string;
  title: string;
  nftTitle: string;
  nftImage?: string;
  onClick: () => void;
  withoutVerifiedAuthorIcon?: boolean;
}

const useStyles = makeStyles(() => ({
  nftInfoBlock: {
    '--media-size': '74px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: '82px',
    borderRadius: '12px',
    boxShadow: `0 4px 20px ${alpha(colors.black, 0.12)}`,
    cursor: 'pointer',
  },
  infoBlockDescription: {
    padding: '20px 12px 20px 16px',
    overflow: 'hidden',
  },
  infoBlockAuthor: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '20px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: colors.lightGrey,
    fontFamily: 'inherit',
    letterSpacing: 'inherit',
  },
  infoBlockAuthorVerified: {
    width: '12px',
    height: '12px',
    margin: '0 4px',
  },
  infoBlockTitle: {
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: colors.primaryDark,
    fontFamily: 'inherit',
    letterSpacing: 'inherit',
  },
  media: {
    minWidth: 'var(--media-size)',
    minHeight: 'var(--media-size)',
    objectFit: 'fill',
    borderRadius: '6px',
    margin: '4px',
  },
}));

const NftModalHighlightCard = ({
  nftId,
  title,
  nftImage = '',
  nftTitle,
  onClick,
  withoutVerifiedAuthorIcon,
}: NftModalHighlightCardProps) => {
  const history = useHistory();
  const {locale} = useLocalization();
  const styles = useStyles();

  const onViewNft = useCallback(
    (e) => {
      e.stopPropagation();
      history.push(`/${locale}/home/nft/${nftId}`);
      onClick();
    },
    [history, nftId, onClick, locale],
  );

  return (
    <Box className={styles.nftInfoBlock} onClick={onViewNft}>
      <CardMedia className={styles.media} image={nftImage} />
      <Box className={styles.infoBlockDescription}>
        <Typography className={styles.infoBlockAuthor}>
          {title}
          {!withoutVerifiedAuthorIcon && <VerifiedAuthorIcon className={styles.infoBlockAuthorVerified} />}
        </Typography>
        <Typography className={styles.infoBlockTitle}>{nftTitle}</Typography>
      </Box>
    </Box>
  );
};

export default NftModalHighlightCard;
