import {StoreModule} from '../../base/types';
import {INftsApi} from '../../../api/nfts/INftsApi';
import {ISellNftApi} from '../../../models/selling/ISellNftApi';
import {WalletConnectionService} from '../../../models/wallet/WalletConnectionService';
import {SELLING_MODULE_NAME} from './constants';
import {cancelNftSellingMiddleware, sellNftMiddleware, startNftSellingMiddleware} from './middlewares';

export const createSellingModule = (
  nftApi: INftsApi,
  sellApi: ISellNftApi,
  walletConnectionService: WalletConnectionService,
): StoreModule => ({
  title: SELLING_MODULE_NAME,
  middlewares: [
    startNftSellingMiddleware(nftApi),
    sellNftMiddleware(sellApi, walletConnectionService),
    cancelNftSellingMiddleware(sellApi, walletConnectionService),
  ],
});
