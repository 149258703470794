import {useEffect} from 'react';
const warningTime = 3000;

type IdleTimerProps = {
  onInActive: () => void;
};

const block = true;

export const IdleTimer = ({onInActive}: IdleTimerProps) => {
  useEffect(() => {
    const warn = () => {
      !block && onInActive();
    };
    let warnTimeout = setTimeout(() => {}, warningTime);
    const clearTimeouts = () => {
      if (warnTimeout) clearTimeout(warnTimeout);
    };

    const setTimeouts = () => {
      warnTimeout = setTimeout(warn, warningTime);
    };

    const events = ['mousemove', 'mousedown', 'click', 'scroll', 'keypress', 'touchstart', 'touchmove', 'touchend'];

    const resetTimeout = () => {
      clearTimeouts();
      setTimeouts();
    };

    for (let i in events) {
      window.addEventListener(events[i], resetTimeout);
    }

    setTimeouts();
    return () => {
      for (let i in events) {
        window.removeEventListener(events[i], resetTimeout);
        clearTimeouts();
      }
    };
  }, [onInActive]);

  return null;
};
