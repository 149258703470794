import {createContext, ReactElement, ReactNode, useMemo, useState} from 'react';
import {CmsExhibition, CmsExhibitionNft, CreatorAuction} from '../../../../../shared/types/graphql';

type AuctionContextType = {
  nfts: CmsExhibitionNft[];
  selectedNftIndex: number;
  setSelectedNftIndex: Function;
  event?: CmsExhibition;
  auction?: CreatorAuction;
  auctionPerNfts: Record<string, CreatorAuction>;
  setAuction: Function;
  setEvent: Function;
  setNfts: Function;
  setAuctionPerNfts: Function;
};

type AuctionProviderType = {
  children: ReactNode;
};

export const AuctionContext = createContext<AuctionContextType>({
  nfts: [] as CmsExhibitionNft[],
  selectedNftIndex: 0,
  setSelectedNftIndex: Function,
  event: undefined,
  auction: undefined,
  auctionPerNfts: {},
  setAuction: Function,
  setEvent: Function,
  setNfts: Function,
  setAuctionPerNfts: Function,
});

export function AuctionContextProvider({children}: AuctionProviderType): ReactElement {
  const [nfts, setNfts] = useState<CmsExhibitionNft[]>([]);
  const [selectedNftIndex, setSelectedNftIndex] = useState(0);
  const [event, setEvent] = useState<CmsExhibition>();
  const [auction, setAuction] = useState<CreatorAuction | undefined>(undefined);

  const [auctionPerNfts, setAuctionPerNfts] = useState<Record<string, CreatorAuction>>({});

  const context = useMemo(
    () => ({
      nfts,
      setNfts,
      selectedNftIndex,
      event,
      auctionPerNfts,
      setSelectedNftIndex,
      setEvent,
      auction,
      setAuction,
      setAuctionPerNfts,
    }),
    [
      nfts,
      setNfts,
      selectedNftIndex,
      event,
      auctionPerNfts,
      setSelectedNftIndex,
      setEvent,
      auction,
      setAuction,
      setAuctionPerNfts,
    ],
  );

  return <AuctionContext.Provider value={context}>{children}</AuctionContext.Provider>;
}
