import {CmsAppConfig} from '@cere/services-types';
import {AppConfigStore} from './types';
import {APP_CONFIG_MODULE_NAME} from './constants';
import {NoSuchModuleError} from '../../base/NoSuchModuleError';

export const selectAppConfig = (state: AppConfigStore): CmsAppConfig => {
  if (!state[APP_CONFIG_MODULE_NAME]) {
    throw new NoSuchModuleError(APP_CONFIG_MODULE_NAME);
  }
  return state[APP_CONFIG_MODULE_NAME];
};
