import {makeStyles} from '@material-ui/core';
import colors from '../../../styles/colors';

export const useStyles = makeStyles({
  selectWrapper: {
    borderRadius: '30px',
    minWidth: '142px',

    '& .MuiSelect-root': {
      padding: '8px 28px 8px 12px',
    },
    '& fieldset': {
      borderColor: colors.primaryDark,
    },
    '& .MuiSelect-icon': {
      color: colors.primaryDark,
    },
  },
  flagImage: {
    width: '20px',
    height: '20px',
    objectFit: 'contain',
  },
  languageTitle: {
    marginLeft: '4px',
    fontSize: '12px',
    lineHeight: '20px',
    fontWeight: 600,
    color: colors.primaryDark,
  },

  root: {
    width: 51,
    height: 31,
    padding: 0,
  },
  switchBase: {
    padding: 1,
    top: 1,
    transform: 'translateX(2px)',

    '&$checked': {
      transform: 'translateX(21px)',

      '& + $track': {
        backgroundColor: colors.green,
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      border: `6px solid ${colors.snowWhite}`,
    },
  },
  thumb: {
    width: 27,
    height: 27,
    boxShadow: 'none',
    background: colors.snowWhite,
  },
  track: {
    borderRadius: 20,
    backgroundColor: colors.lighter,
    opacity: 1,
  },
  disabled: {
    '& + $track': {
      backgroundColor: `${colors.disable} !important`,
      opacity: `0.25 !important`,
    },
    '& $thumb': {
      borderColor: 'transparent',
    },
  },
  checked: {},
});
