import Share from '../../shared/Share';
import {useLocalization} from '../../../utils/hooks/use-localization';
import {getShareUrl} from '../../shared/Share/utils/get-share-url';

type ShareNftModalProps = {
  locale: string;
  appTitle: string;
  nftId: string;
  nftTitle: string;
  nftDescription: string;
  nftImage: string;
  onClose: () => void;
};

export const PlainShareNftModal = ({
  locale,
  appTitle,
  nftId,
  nftTitle,
  nftDescription,
  nftImage,
  onClose,
}: ShareNftModalProps) => {
  const {t} = useLocalization();
  return (
    <Share
      appTitle={appTitle}
      title={t('Share NFT')}
      description={nftDescription}
      imgSrc={nftImage}
      onClose={onClose}
      url={getShareUrl(`/${locale}/home/nft/${nftId}`, nftTitle, nftDescription, nftImage)}
    />
  );
};
