import {useContext, useEffect, useState, useCallback} from 'react';
import {useHistory} from 'react-router-dom';

import {useLocaleUpdated} from '../../shared/hooks/use-locale-updated.hook';
import {TransactionalNotificationsContext} from '../../context/transactional-notifications-context';
import {UserContext} from '../../context/user-context/user-context';
import {TransactionalNotificationsEvent} from '../../shared/services/transactional-notifications/enums';
import {TransactionalNotification} from '../../shared/services/transactional-notifications/types';
import {Numberish} from '../../shared/types/numberish';
import {useLocalization} from '../../shared/hooks/use-locale.hook';

export const useNotifications = () => {
  const history = useHistory();
  const {locale} = useLocalization();
  const transactionalNotifications = useContext(TransactionalNotificationsContext);
  const {userData} = useContext(UserContext);

  const [notifications, setNotifications] = useState<TransactionalNotification[]>(transactionalNotifications.list);
  const [isFirstPageLoading, setIsFirstPageLoading] = useState<boolean>(transactionalNotifications.isFirstPageLoading);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [isNextPageLoading, setIsNextPageLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!userData?.token) {
      return history.push(`/${locale}/home`);
    }

    transactionalNotifications.setUserCredentials(userData.token);
    transactionalNotifications.loadFirstPage();
  }, [transactionalNotifications, userData?.token, history, locale]);

  useLocaleUpdated(
    useCallback(() => {
      transactionalNotifications.resetList();
    }, [transactionalNotifications]),
  );

  useEffect(() => {
    const unsubscribes = [
      transactionalNotifications.subscribe(TransactionalNotificationsEvent.LIST_UPDATED, () =>
        setNotifications(transactionalNotifications.list),
      ),
      transactionalNotifications.subscribe(TransactionalNotificationsEvent.IS_FIRST_PAGE_LOADING_UPDATED, () =>
        setIsFirstPageLoading(transactionalNotifications.isFirstPageLoading),
      ),
      transactionalNotifications.subscribe(TransactionalNotificationsEvent.TOTAL_COUNT_UPDATED, () =>
        setHasMore(transactionalNotifications.totalCount > transactionalNotifications.list.length),
      ),
      transactionalNotifications.subscribe(TransactionalNotificationsEvent.IS_NEXT_PAGE_LOADING_UPDATED, () =>
        setIsNextPageLoading(transactionalNotifications.isNextPageLoading),
      ),
    ];

    return () => unsubscribes.forEach((unsubscribe) => unsubscribe());
  }, [transactionalNotifications]);

  const markAsUnread = useCallback(
    (id: Numberish) => {
      transactionalNotifications.markAsUnread(id);
    },
    [transactionalNotifications],
  );
  const markAsRead = useCallback(
    (id: Numberish) => {
      transactionalNotifications.markAsRead(id);
    },
    [transactionalNotifications],
  );
  const markAllAsRead = useCallback(() => {
    transactionalNotifications.markAllAsRead();
  }, [transactionalNotifications]);

  const loadNextPage = useCallback(() => transactionalNotifications.loadNextPage(), [transactionalNotifications]);

  return {
    notifications,
    isFirstPageLoading,
    isNextPageLoading,
    markAsUnread,
    markAsRead,
    markAllAsRead,
    hasMore,
    loadNextPage,
  };
};
