import {useCallback, useMemo, useState} from 'react';
import {styled} from '@material-ui/core';

import {useLazyNftData} from '../../../shared/hooks/use-nft-data';
import {CreatorWalletNft} from '../../../shared/types/graphql/creator-wallet-nfts';
import {useStartNftSale} from '../../../context/sell-nft-context';
import {NFTCTAButton, NFTCTAButtonProps} from '../../../shared/components/NFTCTAButton';
import {NftSelector} from '../../TransferNfts/nft-selector';
import {useLocalization} from '../../../shared/hooks/use-locale.hook';

type Props = Omit<NFTCTAButtonProps, 'text'> & {
  nfts: CreatorWalletNft[];
};

const SellButton = styled(NFTCTAButton)(({theme}) => ({
  maxHeight: '100%',
  minWidth: '150px',
  width: 'auto',

  [theme.breakpoints.down('lg')]: {
    minWidth: '130px',
  },

  [theme.breakpoints.down('md')]: {
    minWidth: '95px',
  },
}));

const isNftAvailbale = (nft: CreatorWalletNft) =>
  !nft.nft_id.cmsNft?.creatorNft?.creator_make_offer.some(({seller, price}) => price && seller === nft.wallet);

export const WalletNftsSellButton = ({nfts, loading, ...props}: Props) => {
  const {t} = useLocalization();
  const [isSelectModalOpen, setSelectModalOpen] = useState(false);
  const [selectedNftIds, setSelectedNftIds] = useState<Set<string>>(new Set());
  const [loadNftData, {loading: isNftDataLoading}] = useLazyNftData();
  const startNftSale = useStartNftSale();
  const availableNfts = useMemo(() => nfts.filter(isNftAvailbale), [nfts]);

  const handleSelectModalClose = useCallback(() => setSelectModalOpen(false), []);
  const handleClick = useCallback(() => {
    setSelectedNftIds(new Set());
    setSelectModalOpen(true);
  }, []);

  const handleSelectionChange = useCallback(
    async (selection: Set<string>) => {
      setSelectedNftIds(selection);

      const nft = nfts.find(({nft_id}) => selection.has(nft_id.nft_id));
      const nftIncrementId = nft?.nft_id.cmsNft.id;

      if (!nftIncrementId) {
        return;
      }

      const {nftData, price, qtyOwned} = await loadNftData(nftIncrementId);

      startNftSale({
        nftName: nftData!.title,
        nftId: nftData!.creatorNft?.nft_id ?? '',
        cmsNftIncrementId: nftData!.id,
        nftMedia: nftData!.assets[0]?.content,
        ownedQty: qtyOwned!,
        price,
        creatorWalletNfts: nftData?.creatorNft?.creator_wallet_nfts!,
      });
    },
    [loadNftData, nfts, startNftSale],
  );

  return (
    <>
      <SellButton
        {...props}
        text="Sell"
        disabled={!availableNfts.length}
        loading={isNftDataLoading || loading}
        onClick={handleClick}
      />
      <NftSelector
        multiple={false}
        title={t('Select NFT to sell')}
        description={t('Sell one owned NFT and set the listing price.')}
        selectButtonText={t('Sell')}
        value={selectedNftIds}
        nfts={availableNfts}
        open={isSelectModalOpen}
        onClose={handleSelectModalClose}
        onChange={handleSelectionChange}
      />
    </>
  );
};
