import {Box, Button, Dialog, Theme, Typography, useMediaQuery} from '@material-ui/core';
import clsx from 'clsx';
import {CloseButton} from '../CloseButton';
import {useFiltersStyles} from './styles';
import {PriceFilter} from './PriceFilter';
import {CreatorFilter} from './CreatorFilter';
import {DateFilter} from './DateFilter';
import {FilterDesktopView} from './FilterDesktopView';
import {useFilter} from '../../../context/filters-context';
import {useCallback} from 'react';
import {useLocalization} from '../../hooks/use-locale.hook';

export interface NFTFilterModalProps {
  opened: boolean;
  onClose: () => void;
}

export const NFTFilterModal = ({opened, onClose}: NFTFilterModalProps) => {
  const {t} = useLocalization();
  const styles = useFiltersStyles();
  const {applyFilters, clearFilters} = useFilter();
  const isDesktop = useMediaQuery<Theme>((theme) => `${theme.breakpoints.up('lg')}`);

  const handleApply = useCallback(() => {
    applyFilters();
    onClose();
  }, [applyFilters, onClose]);

  const handleClear = useCallback(() => {
    clearFilters();
    onClose();
  }, [clearFilters, onClose]);

  if (isDesktop && opened) {
    return <FilterDesktopView onApply={handleApply} onClear={handleClear} />;
  }

  return (
    <Dialog className={styles.root} open={opened} onClose={onClose}>
      <Box className={styles.header}>
        <Typography className={styles.headerText}>{t('Filters')}</Typography>
        <CloseButton onClick={onClose} />
      </Box>
      <Box className={styles.container}>
        <PriceFilter />
        <CreatorFilter />
        <DateFilter />
      </Box>
      <Box className={styles.controlsContainer}>
        <Button className={styles.controlButton} onClick={handleClear}>
          {t('Clear')}
        </Button>
        <Button className={clsx(styles.controlButton, styles.blackButton)} onClick={handleApply}>
          {t('Apply')}
        </Button>
      </Box>
    </Dialog>
  );
};
