import {WalletsStore} from './types';
import {USER_WALLETS_MODULE_NAME} from './constants';
import {NoSuchModuleError} from '../../base/NoSuchModuleError';
import {SupportedWalletTypes} from '../../../models/wallet/types';
import {walletConnectionService} from '../../../models/wallet';

export const selectActiveWallet = (store: WalletsStore): SupportedWalletTypes => {
  if (!store[USER_WALLETS_MODULE_NAME]) {
    throw new NoSuchModuleError(USER_WALLETS_MODULE_NAME);
  }
  return store[USER_WALLETS_MODULE_NAME].selectedWallet;
};

export const selectActiveWalletBalance = (store: WalletsStore): number => {
  const activeWallet = selectActiveWallet(store);
  const activeWalletAddress = walletConnectionService.getWalletAddressByType(activeWallet); // ToDo: get rid of direct service calling here
  return store[USER_WALLETS_MODULE_NAME].walletsBalance[activeWalletAddress];
};
