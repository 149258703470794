import {useCallback, useState} from 'react';

import {CreatorWalletNft} from '../../shared/types/graphql/creator-wallet-nfts';
import {SubmitStepFn, TransferFormSubmitFn} from './types';
import {AsyncResult, createAsyncResult, PromiseReject, PromiseResolve} from '../../shared/lib/async-result';
import {delay} from '../../shared/lib/delay';

type StepValue = {
  confirm: PromiseResolve;
  cancel: PromiseReject;
  values: {address: string; nft: CreatorWalletNft; network: string};
};

export const useConfirmProcess = (
  submitStep: SubmitStepFn,
): {
  submit: TransferFormSubmitFn;
  step?: StepValue;
  open: boolean;
} => {
  const [open, setOpen] = useState(false);
  const [step, setStep] = useState<StepValue | undefined>();

  const updateStep = useCallback(
    (index: number, defers: AsyncResult[], nfts: CreatorWalletNft[], address: string, network: string) => {
      setStep({
        confirm: defers[index].resolve,
        cancel: defers[index].reject,
        values: {address, nft: nfts[index], network},
      });
      setOpen(index < nfts.length);
    },
    [],
  );

  const submit: TransferFormSubmitFn = useCallback(
    ({nfts, address, network, amount, sender}) => {
      let index = 0;
      const asyncResults = Array.from(new Array(nfts.length)).map(() => createAsyncResult());
      const promises = asyncResults.map(async (defer, i) => {
        try {
          await defer.promise;
          setOpen(false);
          await submitStep({nft: nfts[i], address, network, amount, sender});
        } finally {
          setOpen(false);
          await delay(200);
          index = index + 1;
          updateStep(index, asyncResults, nfts, address, network);
        }
      });
      updateStep(index, asyncResults, nfts, address, network);
      return Promise.allSettled(promises);
    },
    [submitStep, updateStep],
  );

  return {
    submit,
    open,
    step,
  };
};
