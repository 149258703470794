import {IPurchaseNFTApi} from './IPurchaseNFTApi';
import {FreeportPurchaseNFTApi} from './FreeportPurchaseNFTApi';
import {contractProvider} from '../blockchain';

export const purchaseNftApi: IPurchaseNFTApi = new FreeportPurchaseNFTApi(
  contractProvider,
  3,
  Number(process.env.REACT_APP_GAS_LIMIT),
  Number(process.env.REACT_APP_GAS_PRICE),
);
