import {FC, ReactElement, useCallback} from 'react';
import {Box, makeStyles} from '@material-ui/core';
import {Button, Typography} from '@cere/rxb-template-ui-kit';
import {noop} from 'lodash';

import {ReactComponent as Google} from '../../../../assets/social/ic-google.svg';
import {ReactComponent as Facebook} from '../../../../assets/social/ic-facebook.svg';
import {SocialNetworks} from '../../../services/auth.service';
import {useAuth} from '../../../hooks/auth.hook';
import analyticService, {AnalyticEventsEnum} from '../../../../shared/services/analytic.service';
import clsx from 'clsx';
import {SubmittedAuthType} from '../../../../pages/SignInPage/types';
import {SocialData} from './types';
import {useLocalization} from '../../../hooks/use-locale.hook';
import {FIREBASE_API_KEY} from '../../../../config/common';

const useStyles = makeStyles({
  wrapper: {
    gap: '16px',
    flexWrap: 'wrap',
  },
  button: {
    border: '1px solid #E0E0E7',
    display: 'flex',
    alignItems: 'center',
    flex: '0 0 65px',
    borderRadius: '12px',

    '& *': {
      display: 'flex',
    },
  },
  disabled: {
    background: 'transparent !important',
    filter: 'grayscale(100%)',
  },
  separator: {
    position: 'relative',
    display: 'inline',
    padding: '0 8px',
    background: '#fff',
    color: '#6E6E79',
  },
  strikethrough: {
    position: 'relative',
    textAlign: 'center',

    '&::before': {
      position: 'absolute',
      content: '""',
      left: '0',
      top: '10px',
      height: '1px',
      width: '100%',
      background: '#E0E0E7',
    },
  },
});

const NETWORKS: {network: SocialNetworks; icon: ReactElement}[] = [
  {
    network: 'google.com',
    icon: <Google />,
  },
  {
    network: 'facebook.com',
    icon: <Facebook />,
  },
];

interface SocialButtonsProps {
  onSuccess?: (userEmail: string, subscribed: boolean) => void;
  subscribed?: boolean;
  disabled?: boolean;
  authType?: SubmittedAuthType;
  onRequestOtp: (data: SocialData) => unknown;
}

const SocialButtons: FC<SocialButtonsProps> = ({
  authType,
  subscribed = false,
  onSuccess = noop,
  disabled = false,
  onRequestOtp,
}): ReactElement => {
  const classes = useStyles();
  const {t} = useLocalization();

  const {handleSocialSignIn} = useAuth();

  const onSocialSigninSuccess = useCallback(
    (email: string) => {
      if (authType === SubmittedAuthType.SIGN_UP) {
        const now = new Date();
        const payload = {
          email: String(email),
          createdAt: now.toUTCString(),
        };
        analyticService.gtmTrack(AnalyticEventsEnum.ACCOUNT_CREATED, payload);
      }

      onSuccess(email, subscribed);
    },
    [authType, onSuccess, subscribed],
  );

  const authenticate = async (socialNetwork: SocialNetworks) => {
    analyticService.track(AnalyticEventsEnum.SIGN_IN_SOCIAL_AUTH_CLICKED, {socialNetwork});
    try {
      await handleSocialSignIn(socialNetwork, onSocialSigninSuccess, onRequestOtp);
    } catch (e) {
      console.error(t('Authenticate error. Handle Sign in went wrong: '), e.message);
    }
  };

  if (!FIREBASE_API_KEY()) {
    return <></>;
  }

  return (
    <>
      <Box className={classes.strikethrough} m="24px 0">
        <Typography variant="body2" className={classes.separator}>
          {t('Or')}
        </Typography>
      </Box>

      <Box display="flex" justifyContent="center" alignItems="center" className={classes.wrapper} mb="32px">
        {NETWORKS.map(({network, icon}) => (
          <Button
            color="primary"
            size="large"
            className={clsx(classes.button, disabled && classes.disabled)}
            onClick={() => authenticate(network)}
            key={network}
            disabled={disabled}
          >
            {icon}
          </Button>
        ))}
      </Box>
    </>
  );
};

export default SocialButtons;
