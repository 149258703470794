import {alpha, makeStyles, Theme} from '@material-ui/core';
import colors from '../../../styles/colors';

export const useNFTCardMediaStyles = makeStyles<
  Theme,
  {
    comingSoon: boolean;
    image?: string;
  }
>((theme) => ({
  root: {
    width: '100%',
    height: '320px',
    position: 'relative',
    overflow: 'hidden',
    backgroundImage: 'unset!important',

    '&:after': {
      content: '""',
      width: '100%',
      height: '100%',
      position: 'absolute',
      backgroundImage: ({image}) => `url(${image})`,
      backgroundSize: 'cover',
      filter: ({comingSoon}) => (comingSoon ? 'blur(1.3rem)' : 'unset'),
      zIndex: 10,
    },

    [theme.breakpoints.up('md')]: {
      height: '400px',
    },
  },
}));

export const useNFTCardStyles = makeStyles<Theme, {comingSoon: boolean}>((theme) => ({
  container: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    borderRadius: '12px',
    boxShadow: `0px 4px 20px ${alpha(colors.black, 0.12)}`,
    width: '343px',

    [theme.breakpoints.down('sm')]: {
      width: '320px',
    },

    [theme.breakpoints.down('lg')]: {
      width: '320px',
    },

    [theme.breakpoints.up('lg')]: {
      width: '400px',
      boxShadow: 'none',
    },
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
  },
  comingSoon: {
    overflow: 'hidden',
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#fff',
    fontWeight: 700,
    zIndex: 100,

    '& span': {
      fontSize: '1.2rem',
    },
  },
  cardTitle: {
    fontWeight: 'bold',
    marginBototm: '6px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',

    [theme.breakpoints.down('sm')]: {
      marginBottom: '8px',
    },
  },
  cardAmount: {
    display: 'flex',
  },
  amountCommon: {
    fontWeight: 'bold',
    color: colors.lightGrey,
  },
  amountAuction: {
    paddingLeft: '1ch',
  },
  amountLimited: {
    fontWeight: 'normal',
  },
  cardService: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '12px 12px 16px 12px',
    flexGrow: 1,
  },
  share: {
    position: 'absolute',
    top: '12px',
    right: '12px',
    zIndex: 10,
  },
  serviceTexts: {
    display: 'flex',
    flexDirection: 'column',
  },
  serviceInfo: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  serviceInfoText: {
    display: 'flex',
    color: colors.primaryDark,
    fontWeight: 'bold',
  },
  serviceInfoSubText: {
    fontWeight: 'normal',
    paddingRight: '1ch',
  },
  serviceInfoButton: {
    minWidth: '122px',
  },
  button: {
    display: 'flex',
    maxHeight: '44px',
    minWidth: '122px',
    marginLeft: 'auto',

    '& button': {
      width: '90px',
      height: '34px',
      marginLeft: 'auto',
    },
  },
  link: {
    textDecoration: 'none',
  },
  inactiveLink: {
    cursor: 'default',
  },
}));

export const useAdditionsNFTsStyles = makeStyles((theme) => ({
  cardBlock: {
    position: 'relative',
    borderRadius: '12px',
  },

  container: {
    display: 'flex',
    width: '100%',
    height: '124px',
    borderRadius: '12px',
    overflow: 'hidden',

    '&.MuiCard-root': {
      boxShadow: 'none',
    },

    [theme.breakpoints.up('lg')]: {
      width: '383px',
    },
  },

  root: {
    minWidth: '100px',
    maxWidth: '100px',
    minHeight: '100px',
    maxHeight: '100px',
    margin: '12px',
    borderRadius: '12px',
  },

  button: {
    width: '90px',
    height: '34px',
    minHeight: '34px',
    padding: '0',
    marginLeft: 'auto',
  },
  cardTitle: {
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',
    color: colors.primaryDark,
    maxWidth: '207px',
    marginBottom: '4px',
    overflowY: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',

    [theme.breakpoints.between('sm', 'md')]: {
      width: 'unset',
      overflowY: 'unset',
      display: 'unset',
      WebkitLineClamp: 'unset',
      WebkitBoxOrient: 'unset',
    },
  },
  cardService: {
    padding: '12px 12px 16px 0',
  },
  serviceInfoText: {
    color: colors.black,
  },
}));
