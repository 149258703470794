import {NftSellingType} from '@cere/services-types';
import {BuyFromMinterButton} from './BuyFromMinterButton';
import {BuyOnMarketplaceButton} from './BuyOnMarketplaceButton';

export type BuyButtonProps = {
  className?: string;
  nftId: string;
  sellingType: NftSellingType;
  sellerWalletAddress: string;
  price: number;
  disabled?: boolean;
};

export const BuyButton = ({className, nftId, sellingType, sellerWalletAddress, price, disabled}: BuyButtonProps) => {
  if (sellingType === NftSellingType.IS_PRIMARY || sellingType === NftSellingType.BOTH_PRIMARY_AND_SECONDARY) {
    return <BuyFromMinterButton className={className} nftId={nftId} />;
  }
  return (
    <BuyOnMarketplaceButton
      className={className}
      nftId={nftId}
      sellerWalletAddress={sellerWalletAddress}
      price={price}
      disabled={disabled}
    />
  );
};
