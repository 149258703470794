import {FC, MouseEventHandler, useCallback, useContext} from 'react';
import {Box, makeStyles} from '@material-ui/core';
import {Button, Typography} from '@cere/rxb-template-ui-kit';
import colors from '../../../styles/colors';
import {NftTicketCardBase} from './NftTicketCardBase';
import {NftContext} from '../Nft/nft-context';

interface NftTicketCardProps {
  handleClickLearn: MouseEventHandler<unknown>;
  analyticClassname?: string;
  canBePurchased: boolean;
  withCtaAction?: () => unknown;
  withInfoIcon?: boolean;
}

const useStyles = makeStyles(() => ({
  grey: {
    color: colors.lightGrey,
  },
}));

export const NftTicketCard: FC<NftTicketCardProps> = ({
  handleClickLearn,
  analyticClassname,
  canBePurchased,
  withCtaAction,
  withInfoIcon,
}) => {
  const classes = useStyles();
  const {button, balance, supply} = useContext(NftContext);

  const handler = useCallback(() => {
    withCtaAction?.();
    button.onClick();
  }, [button, withCtaAction]);

  return (
    <NftTicketCardBase
      handleClickLearn={handleClickLearn}
      buttonContent={
        <Box mt="20px">
          <Button
            className={analyticClassname}
            fullWidth
            color="primary"
            variant="contained"
            size="large"
            onClick={handler}
            disabled={!canBePurchased}
          >
            {button.label || 'Buy'}
          </Button>
        </Box>
      }
      countContent={
        <Typography variant="caption1" className={classes.grey}>
          <Typography variant="h4" component="span">
            {balance}
          </Typography>
          /{supply}
          &nbsp; NFTs left
        </Typography>
      }
      justifyContentInfo="space-between"
      withInfoIcon={withInfoIcon}
    />
  );
};
