import {useCallback, useMemo} from 'react';
import {
  AuctionInterface,
  AvailabilityStatus,
  CheckUserHasNftEnum,
  NftSellingType,
  AuctionStatus,
  NftType,
} from '@cere/services-types';
import {Trans, useTranslation} from 'react-i18next';
import clsx from 'clsx';
import {Box, Typography, makeStyles} from '@material-ui/core';
import {Card, CardProps} from '../../shared/Card';
import {LimitedBadge} from '../../primitives/Badge/LimitedBadge';
import {NftCardCompositeButton} from '../ActionButton/NftCardCompositeButton';
import {TransactionProcessing} from '../../primitives/Badge/TransactionProcessing';

const useStyles = makeStyles((theme) => ({
  bold: {
    fontWeight: 600,
  },
  actionColor: {
    color: theme.palette.secondary.main,
  },
  button: {
    width: '122px',
    height: '44px',
  },
  flexText: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    '& > *': {
      marginRight: '2px',
    },
  },
}));

export type ConnectedCardViewProps = {
  nftId: string;
  hasPendingTransaction?: boolean;
  nftPurchaseStatus?: CheckUserHasNftEnum;
  nftAuction?: AuctionInterface;
  nftAvailability: AvailabilityStatus;
  nftSellingType?: NftSellingType;
  nftType: NftType;
  nftBalance?: number;
  nftSupply?: number;
  nftPrice: number;
  priceUsd: number;
  minterWalletAddress: string;
  sellerWalletAddress?: string;
  auctionStatus: AuctionStatus;
} & Omit<CardProps, 'cardBadge' | 'bottomInfo' | 'rightBottomElement'>;

export const ConnectedCardView = ({
  nftId,
  hasPendingTransaction,
  nftPurchaseStatus,
  nftAuction,
  nftAvailability,
  nftSellingType,
  nftType,
  nftBalance,
  nftSupply,
  nftPrice,
  minterWalletAddress,
  sellerWalletAddress,
  title,
  image,
  auctionStatus,
  priceUsd,
  cardLink,
  creatorImage,
  creatorName,
  creatorLink,
  onShareClick,
  onLikeClick,
}: ConnectedCardViewProps) => {
  const {t} = useTranslation();
  const styles = useStyles();
  const cardBadge = useMemo(() => {
    if (hasPendingTransaction) {
      return <TransactionProcessing />;
    }
    return <LimitedBadge />;
  }, [hasPendingTransaction]);

  const subTitle = useMemo(
    () =>
      auctionStatus === AuctionStatus.ACTIVE ? (
        t('1 Auctioned Original')
      ) : (
        <Trans
          i18nKey="<strong>{{leftAmount}}</strong>/{{totalAmount}} NFTs left"
          values={{
            leftAmount: nftBalance,
            totalAmount: nftSupply,
          }}
          count={nftBalance}
        />
      ),
    [auctionStatus, nftBalance, nftSupply, t],
  );

  const auctionHasBids = !!nftAuction?.bids?.[0];

  const renderCardPriceBadge = useCallback(() => {
    if (auctionStatus === AuctionStatus.ACTIVE && auctionHasBids) {
      return (
        <Box className={styles.actionColor}>
          <Typography>{t('Top bid')}</Typography>
          <Typography className={styles.bold}>${priceUsd?.toFixed(2)}</Typography>
        </Box>
      );
    }
    if (auctionStatus === AuctionStatus.ACTIVE) {
      return (
        <Box className={clsx(styles.flexText, styles.actionColor)}>
          <Typography>{t('Starting price')}</Typography>
          <Typography className={styles.bold}>${priceUsd?.toFixed(2)}</Typography>
        </Box>
      );
    }
    return <Typography className={styles.bold}>${priceUsd?.toFixed(2)}</Typography>;
  }, [styles, priceUsd, auctionStatus, auctionHasBids, t]);

  const renderCardButton = useCallback(
    () => (
      <NftCardCompositeButton
        className={styles.button}
        nftId={nftId}
        nftPrice={nftPrice}
        nftPurchaseStatus={nftPurchaseStatus}
        nftAuction={nftAuction}
        nftAuctionStatus={auctionStatus}
        nftAvailability={nftAvailability}
        nftSellingType={nftSellingType}
        nftType={nftType}
        nftBalance={nftBalance}
        sellerWalletAddress={sellerWalletAddress || minterWalletAddress}
        disabled={hasPendingTransaction}
      />
    ),
    [
      hasPendingTransaction,
      minterWalletAddress,
      nftAuction,
      auctionStatus,
      nftAvailability,
      nftBalance,
      nftId,
      nftPrice,
      nftPurchaseStatus,
      nftSellingType,
      nftType,
      sellerWalletAddress,
      styles.button,
    ],
  );

  return (
    <Card
      title={title}
      image={image}
      cardLink={cardLink}
      subTitle={subTitle}
      creatorImage={creatorImage}
      creatorName={creatorName}
      creatorLink={creatorLink}
      cardBadge={cardBadge}
      onShareClick={onShareClick}
      onLikeClick={onLikeClick}
      leftBottomInfo={renderCardPriceBadge()}
      rightBottomElement={renderCardButton()}
    />
  );
};
