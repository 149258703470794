import {PendingTransaction, PendingTransactionType} from '../../../models/pending-transactions/types';
import {NormalizedPendingTransactions} from './types';

const PENDING_TRANSACTIONS = '[PENDING TRANSACTIONS]';

export class SetPendingTransactionForNftCommand {
  static type = `${PENDING_TRANSACTIONS} Set pending transaction`;
  static create(
    userWalletAddress: string,
    nftId: string,
    type: PendingTransactionType,
    price: number,
    timestamp: number,
  ) {
    return {
      type: this.type,
      payload: {
        nftId,
        type,
        price,
        timestamp,
        userWalletAddress,
      },
    };
  }
}

export class RemovePendingTransactionForNftCommand {
  static type = `${PENDING_TRANSACTIONS} Remove pending transaction`;
  static create(nftId: string) {
    return {
      type: this.type,
      payload: {nftId},
    };
  }
}

export class AllPendingTransactionsDocument {
  static type = `${PENDING_TRANSACTIONS} All pending transactions`;
  static create(pendingTransactions: NormalizedPendingTransactions) {
    return {
      type: this.type,
      payload: pendingTransactions,
    };
  }
}

export class PendingTransactionDocument {
  static type = `${PENDING_TRANSACTIONS} Pending transaction`;
  static create(pendingTransaction: PendingTransaction) {
    return {
      type: this.type,
      payload: pendingTransaction,
    };
  }
}

export class StartPollingForPurchasedNftCommand {
  static type = `${PENDING_TRANSACTIONS} Start purchased nft polling`;
  static create(userWalletAddress: string, nftId: string) {
    return {
      type: this.type,
      payload: {
        nftId,
        userWalletAddress,
      },
    };
  }
}

export class StartPollingForCreatedOfferCommand {
  static type = `${PENDING_TRANSACTIONS} Start nft offer polling`;
  static create(userWalletAddress: string, nftId: string) {
    return {
      type: this.type,
      payload: {
        nftId,
        userWalletAddress,
      },
    };
  }
}
