export const TOKEN_TITLE = 'USDC';
export const TOKEN_DECIMALS_POW = 6;
export const TOKEN_DIVIDER = 1000000;
export const ROUND_DIVIDER = 10000;
export const TOKEN_DECIMALS = 10 ** TOKEN_DECIMALS_POW;
export const MAX_FRACTION_VALUE = 10_000;
const CENTS_IN_USD = 100;

type FormatPriceParams = {
  currency?: string;
  prettify?: boolean;
};

export const cereToUSDCents = (price: number, unitsPerPenny: number = 1) => price / unitsPerPenny;
// Cere units to USD
export const cereToUSD = (cereAmount: number, unitsPerPenny: number = 1) =>
  centToUSD(cereToUSDCents(cereAmount, unitsPerPenny));
export const usdToCere = (usdAmount: number, unitsPerPenny: number) => cereCentToCere(usdAmount * 100 * unitsPerPenny);
export const cereCentToCere = (cerePrice: number | string | BigInteger) =>
  Number((parseFloat(cerePrice as string) / TOKEN_DECIMALS).toFixed(5));
export const centToUSD = (price: number | string | BigInteger) =>
  Number((parseFloat(price as string) / 100).toFixed(2));
export const formatPrice = (price: number | string, {currency = '$', prettify = true}: FormatPriceParams = {}) => {
  return price ? `${currency}${prettify ? Number(price).toLocaleString('en-US') : price}` : '';
};
export const usdcUnitsToUSD = (amount: number): number => centToUSD((amount / TOKEN_DECIMALS) * CENTS_IN_USD);
export const roundTwoNonZeroDecimals = (value: number) => {
  const log10 = value ? Math.floor(Math.log10(value)) : 0,
    div = log10 < 0 ? Math.pow(10, 1 - log10) : 100;

  return Math.round(value * div) / div;
};

export const formatBidValue = (value: string): string => {
  const numericValue = value.replace(/([^0-9,.])/, '').replace(/^0+(?=\d+)/, '');
  const amountExec = /^([1-9][0-9]*|0)([.,]\d*)?/.exec(numericValue);

  return amountExec ? amountExec[0] : '0';
};
