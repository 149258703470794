import {SyntheticEvent, useContext, useMemo} from 'react';
import {Box} from '@material-ui/core';
import {Notification} from '@cere/rxb-template-ui-kit';
import {useCardStyles} from './styles';
import {ReactComponent as BidIcon} from '../../../assets/icons/success.svg';
import {AuctionCardLarge} from '../AuctionCardLarge/auctionCardLarge';
import {NftContext} from '../Nft/nft-context-v2';
import {AuctionsContext} from '../../../context/auctions-context';
import {EventCounter} from '../auction/EventCounter';
import {formatNumber} from '../../lib/formatNumber';
import {useLocalization} from '../../hooks/use-locale.hook';
import {AvailabilityStatus, NftType} from '@cere/services-types';
import {formatPrice, usdcUnitsToUSD} from 'shared/lib/formatPrice';

interface BigExclusiveNftCardProps {
  showMore: boolean;
  onCardClick: () => void;
  checkHighestBid: (nftId: string) => boolean;
}

export const BigExclusiveNftCard = ({onCardClick, checkHighestBid, showMore}: BigExclusiveNftCardProps) => {
  const {t} = useLocalization();
  const classes = useCardStyles({showMore});
  const {nft, event, button} = useContext(NftContext);

  const isAuctionItem = useMemo(() => Boolean(nft.nftType === NftType.AUCTIONED), [nft]);
  const {auctionNfts} = useContext(AuctionsContext);
  const isHighestBid = checkHighestBid(nft.id ?? '');

  return (
    <Box className={classes.item} key={nft.id}>
      <AuctionCardLarge
        imgSrc={nft.image || ''}
        title={nft.title || ''}
        subtitle={
          isAuctionItem
            ? ''
            : t('{{leftAmount}}/{{totalAmount}} left', {
                leftAmount: nft.balance,
                totalAmount: formatNumber(nft.supply ?? 1),
              })
        }
        price={formatPrice(usdcUnitsToUSD(nft.price))}
        offersCount={
          auctionNfts[nft.id ?? '']?.creator_auction_bids?.length
            ? t('({{count}} bids)', {count: auctionNfts[nft.id ?? '']?.creator_auction_bids.length})
            : ''
        }
        mediaContent={
          nft.availability !== AvailabilityStatus.ONGOING || !isAuctionItem ? null : (
            <EventCounter date={new Date(isAuctionItem ? auctionNfts[nft.id]?.ends_at || '' : event?.endsAt || '')} />
          )
        }
        description={isAuctionItem ? t('1 Auctioned original') : ''}
        key={nft.id ?? ''}
        disabled={button.disabled}
        isFixedPrice={!isAuctionItem}
        onCardClick={onCardClick}
        onClick={(e: SyntheticEvent) => {
          e.stopPropagation();
          button.onClick();
        }}
        buttonText={button.label}
        hidePrice={nft.availability === AvailabilityStatus.COMING_SOON}
      />
      {isHighestBid && (
        <Notification
          icon={<BidIcon />}
          text={t('You have the highest bid!')}
          textVariant="caption1"
          variant="secondary"
          size="md"
          className={classes.notification}
        />
      )}
    </Box>
  );
};
