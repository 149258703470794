import {ApolloQueryResult, useQuery} from '@apollo/client';
import {useMemo, useCallback} from 'react';

import {CreatorWalletNft, CreatorWalletNftQueryResult} from '../../shared/types/graphql/creator-wallet-nfts';
import {GET_WALLETS_NFTS} from '../../shared/queries/wallet';
import {useLocalization} from '../../shared/hooks/use-locale.hook';

type Variables = {wallet: string[] | string; locale: string};

export const useLoadWalletNfts = (
  wallet: string[] | string,
): {
  isLoading: boolean;
  nfts: CreatorWalletNft[];
  refetch: (
    variables?: Partial<Variables>,
  ) => Promise<ApolloQueryResult<{creatorWalletNfts: CreatorWalletNftQueryResult[]}>>;
} => {
  const {locale} = useLocalization();
  const {
    data,
    loading: isLoading,
    refetch,
  } = useQuery<{creatorWalletNfts: CreatorWalletNftQueryResult[]}, Variables>(GET_WALLETS_NFTS, {
    variables: {
      wallet: Array.isArray(wallet) ? wallet.map((item) => item.toLowerCase()) : wallet.toLowerCase(),
      locale,
    },
  });

  const nfts = useMemo(
    () =>
      (data?.creatorWalletNfts ?? [])
        .filter((creatorWalletNft) => creatorWalletNft.nft_id?.cmsNfts?.length > 0)
        .map((creatorWalletNft) => ({
          id: creatorWalletNft.id,
          wallet: creatorWalletNft.wallet,
          quantity: creatorWalletNft.quantity,
          nft_id: {
            id: creatorWalletNft.nft_id?.id,
            nft_id: creatorWalletNft.nft_id?.nft_id,
            cmsNft: creatorWalletNft.nft_id?.cmsNfts?.[0],
          },
        }))
        .filter((item) => item.nft_id?.cmsNft?.id),
    [data?.creatorWalletNfts],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const refetchCallback = useCallback((variables?: Partial<any> | undefined) => refetch(variables), []);

  return {isLoading, nfts, refetch: refetchCallback};
};
