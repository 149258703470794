import {Redirect, useHistory} from 'react-router-dom';
import {styled} from '@linaria/react';

import {ExhibitionProvider} from '../../context/exhibition/exhibition-provider';
import {InterstitialEventPage as InterstitialEventPageRoute} from './routes/InterstitialEventPage';
import {useLocalization} from '../../shared/hooks/use-locale.hook';

const Container = styled.div`
  width: 100%;
  height: 100vh;
`;

export const InterstitialEventPage = () => {
  const history = useHistory();
  const {locale} = useLocalization();

  const query = new URLSearchParams(history.location.search);
  const eventSlug = query.get('event') ?? undefined;

  if (!eventSlug) {
    return <Redirect to={`/${locale}/home`} />;
  }

  if (history.location.pathname === '/') {
    return <Redirect to={`/${locale}/?${query.toString()}`} />;
  }

  return (
    <Container>
      <ExhibitionProvider slug={eventSlug}>
        <InterstitialEventPageRoute />
      </ExhibitionProvider>
    </Container>
  );
};
