import {Box, MenuItem} from '@material-ui/core';
import {Link} from 'react-router-dom';
import clsx from 'clsx';
import {useContext, useEffect, useState} from 'react';
import {TransactionalNotificationsContext} from '../../../../context/transactional-notifications-context';
import {TransactionalNotificationsEvent} from '../../../../shared/services/transactional-notifications/enums';
import {ReactComponent as BellIcon} from '../../../../assets/menuIcons/notificationsIcon.svg';
import {ReactComponent as FeedIcon} from '../../../../assets/menuIcons/feedIcon.svg';
import {useStyles} from './styles';
import {useStyles as menuItemsStyles} from '../../UserProfileMenu/styles';
import {noop} from '../../../../shared/types/noop';
import {useLocalization} from '../../../hooks/use-locale.hook';

const useNotifications = () => {
  const transactionalNotifications = useContext(TransactionalNotificationsContext);
  const [hasNewNotifications, setHasNewNotifications] = useState<boolean>(
    transactionalNotifications.newNotificationsCount > 0,
  );

  useEffect(() => {
    const unsubscribe = transactionalNotifications.subscribe(TransactionalNotificationsEvent.NEW_COUNT_UPDATED, () =>
      setHasNewNotifications(transactionalNotifications.newNotificationsCount > 0),
    );

    return () => unsubscribe();
  }, [transactionalNotifications]);

  return {
    hasNewNotifications,
  };
};

type Props = {
  onClose: noop;
  pathname: string;
  navigate?: (path: string) => void;
};

export const UserAccount = ({onClose, pathname, navigate}: Props) => {
  const {t, locale} = useLocalization();

  const classes = useStyles();
  const itemsClasses = menuItemsStyles({});
  const {hasNewNotifications} = useNotifications();

  return (
    <Box className={classes.listContainer}>
      <MenuItem
        classes={{root: clsx(itemsClasses.menuItemRoot, classes.menuItem)}}
        className={clsx(
          itemsClasses.menuItem,
          pathname === `/${locale}/feed` ? itemsClasses.activeItem : itemsClasses.inActiveItem,
        )}
        onClick={navigate?.bind(null, `/${locale}/feed`)}
      >
        <FeedIcon />
        {t('Personal feed')}
      </MenuItem>

      <Link to={`/${locale}/notifications`} className={classes.link} onClick={onClose}>
        <Box display="flex" flexDirection="row" alignItems="center">
          <Box className={classes.iconContainer}>
            <BellIcon />
          </Box>
          <Box className={clsx(classes.title, pathname === '/notifications' && classes.activeItem)}>
            {t('Notifications')}
          </Box>
          {hasNewNotifications && <Box className={classes.newNotificationsMarker} />}
        </Box>
      </Link>
    </Box>
  );
};
