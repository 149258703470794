import {FC, MouseEventHandler, ReactElement, useContext, useMemo} from 'react';
import {styled} from '@linaria/react';
import clsx from 'clsx';
import {Box, makeStyles} from '@material-ui/core';
import {Typography} from '@cere/rxb-template-ui-kit';
import {NftCardBigBase} from '../NftCardBig/NftCardBigBase';
import colors from '../../../styles/colors';
import {ReactComponent as InfoIcon} from '../../../assets/info.svg';
import {GoogleAnalyticsId} from '../../../analytics-ids';
import {NftContext} from '../Nft/nft-context';
import {NftAllocationPendingBadge, NftPaymentFailedBadge} from '../NftCardBig/NftCardBigBadge';
import {getNftCardBadge} from '../NftCardBig/helpers';
import {NftAvailability} from '../../services/nft.service';
import {useUserHasEventAccess} from '../../../pages/ExhibitPage/use-user-has-event-access';
import {isNumberish} from '../../types/numberish';
import {NftCardBigDescription} from '../NftCardBig/NftCardBigDescription';
import {useLoadNftById} from 'api/hooks/use-load-nft-by-id';
import {NftProvider2} from '../Nft/nft-provider-v2';
import {isEmpty} from 'lodash';

interface NftTicketCardBaseProps {
  handleClickLearn: MouseEventHandler<unknown>;
  buttonContent: ReactElement;
  selectContent?: ReactElement | null;
  countContent?: ReactElement | null;
  justifyContentInfo: string;
  withShare?: boolean;
  withInfoIcon?: boolean;
}

const StyledInfoIcon = styled(InfoIcon)`
  button > * {
    pointer-events: none;
  }
`;

const useStyles = makeStyles((theme) => ({
  grey: {
    color: colors.lightGrey,
  },
  check: {
    width: '13px',
    height: '13px',

    '& path': {
      fill: colors.lightGrey,
    },
  },
  info: {
    width: '17px',
    height: '17px',
    marginLeft: '6px',
    cursor: 'pointer',

    '& path': {
      fill: theme.palette.info.main,
    },
  },
}));

export const NftTicketCardBase: FC<NftTicketCardBaseProps> = ({
  handleClickLearn,
  buttonContent,
  selectContent = null,
  countContent = null,
  justifyContentInfo,
  withInfoIcon = true,
}) => {
  const classes = useStyles();
  const {price, attributes, eventNft} = useContext(NftContext);
  const hasEventAccess = useUserHasEventAccess();

  const badge = useMemo(() => {
    if (attributes.hasPendingTransaction) {
      return <NftAllocationPendingBadge />;
    } else if (attributes.failed) {
      return <NftPaymentFailedBadge />;
    }
    return getNftCardBadge(hasEventAccess && attributes.purchased, false);
  }, [attributes.failed, attributes.hasPendingTransaction, attributes.purchased, hasEventAccess]);

  const isPriceAndCountShown = attributes.availability !== NftAvailability.COMING_SOON;
  // TODO: remove when interstitial page is migrated. NFT should be provided from page https://cerenetwork.atlassian.net/browse/DAV-2990
  const {nft} = useLoadNftById(eventNft?.cmsNft?.id?.toString());
  return (
    <>
      {!isEmpty(nft) && (
        <NftProvider2 nft={nft!}>
          <NftCardBigBase badge={badge} withShare>
            <Box display="flex" alignItems="center">
              <Typography variant="h4">{nft?.title}</Typography>
              {withInfoIcon && (
                <StyledInfoIcon className={clsx(classes.info, GoogleAnalyticsId.LearnBtn)} onClick={handleClickLearn} />
              )}
            </Box>

            <NftCardBigDescription description={eventNft?.cmsNft?.cardDescription || ''} />
            <Box m="12px 0 4px 0">
              {isPriceAndCountShown && (
                <Box display="flex" alignItems="center" justifyContent={justifyContentInfo}>
                  {countContent}
                  {isNumberish(price.usd) && <Typography variant="h4">${price.usd}</Typography>}
                </Box>
              )}
              {selectContent}
              {buttonContent}
            </Box>
          </NftCardBigBase>
        </NftProvider2>
      )}
    </>
  );
};
