import {Box, Button, makeStyles} from '@material-ui/core';
import {useLocalization} from '../../hooks/use-locale.hook';
import {ReactComponent as FilterIcon} from '../../../assets/icons/filter.svg';
import colors from '../../../styles/colors';
import {SortingMenu} from '../SortingMenu';
import {useActiveFiltersNumber} from './hooks';

const useStyles = makeStyles((theme) => ({
  button: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '40px',
    padding: '10px 18px',
    borderRadius: '30px',
    color: colors.primaryDark,
    border: `1px solid ${theme.palette.grey[300]}`,
    textTransform: 'none',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',
    backgroundColor: theme.palette.common.white,
    marginRight: '10px',
    [theme.breakpoints.up('md')]: {
      height: '44px',
      width: '169.5px',
      marginRight: '0',
    },
    [theme.breakpoints.up('lg')]: {
      height: '44px',
      width: '193.5px',
    },
    '& svg': {
      marginRight: '8px',
    },
  },
  badge: {
    '--size': '18px',
    width: 'var(--size)',
    height: 'var(--size)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 4.5px',
    marginLeft: '8px',
    borderRadius: '50%',
    backgroundColor: theme.palette.info.main,
    color: colors.light,
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '20px',
  },
}));

export interface NFTFilterProps {
  openFilterModal: () => void;
}

export const NFTFilter = ({openFilterModal}: NFTFilterProps) => {
  const styles = useStyles();
  const {t} = useLocalization();

  const activeFilterNumber = useActiveFiltersNumber();

  return (
    <>
      <Button className={styles.button} onClick={openFilterModal}>
        <FilterIcon />
        {t('Filters')}
        {activeFilterNumber > 0 && <Box className={styles.badge}>{activeFilterNumber}</Box>}
      </Button>
      <SortingMenu />
    </>
  );
};
