import {Box, makeStyles} from '@material-ui/core';
import {CircularProgress, CircularProgressProps} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  loader: {
    overflowY: 'auto',
    padding: '0',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

type Props = Pick<CircularProgressProps, 'color'>;

export const IsLoading = ({color = 'inherit'}: Props) => {
  const classes = useStyles();
  return (
    <Box className={classes.loader}>
      <CircularProgress color={color} />
    </Box>
  );
};
