import {ReactNode, useCallback, useMemo, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {useNftCtaButtonProps} from './use-nft-cta-button-props-v2';
import analyticService, {AnalyticEventsEnum} from '../../services/analytic.service';
import {useStartNftSale} from '../../../context/sell-nft-context';
import {usePurchaseById} from '../../../context/purchase-nft-context';
import {CheckUserHasNftEnum, NftCardInterface, AuctionStatus, ExhibitCardInterface} from '@cere/services-types';
import Share from '../Share';
import {getShareUrl} from '../Share/utils/get-share-url';
import {NftPurchaseParams} from '../WithPurchaseNft/v2';
import {useCreatorDataById} from '../../../api/hooks/use-creator-data-by-id';
import {useNftAttributesV2} from './use-nft-attributes-v2';
import {NftContext, NftContextTypeV2} from './nft-context-v2';
import {useExhibitWithNfts} from '../../../api/hooks/use-exhibit-with-nfts';
import {useOwnerOffer} from '../../hooks/use-owner-offer';
import {useLocalization} from '../../hooks/use-locale.hook';

type Props = {
  children: ReactNode;
  nft: NftCardInterface;
  startNFTBuyOrRequestAuth: (nft: NftPurchaseParams) => Promise<unknown>;
};

export const NftInnerProvider = ({children, nft, startNFTBuyOrRequestAuth}: Props) => {
  const {t, locale} = useLocalization();
  const history = useHistory();
  const startNftSell = useStartNftSale();
  const {startNFTPurchaseById} = usePurchaseById();
  const creator = useCreatorDataById(nft.creatorId);
  const exhibition = useExhibitWithNfts(nft.exhibitionSlug);
  const {data: offers} = useOwnerOffer(nft.address);

  const attributes = useNftAttributesV2(nft, exhibition as ExhibitCardInterface);
  const [showShareNftModal, setShowShareNftModal] = useState(false);
  const [quantity, setQuantity] = useState(1);

  const eventUrl = useMemo(() => {
    return nft.exhibitionSlug
      ? `/${locale}/home/exhibit/${nft.exhibitionSlug}`
      : Object(history.location?.state).referrer ?? '';
  }, [nft.exhibitionSlug, history.location?.state, locale]);

  const priceText = useMemo(() => {
    const hasBids = !!nft?.auction?.bids?.[0];
    return hasBids ? t('Top bid') : t('Starting price');
  }, [nft?.auction?.bids, t]);

  const owned: number = nft.purchaseStatus === CheckUserHasNftEnum.USER_HAS_NFT ? 1 : 0;

  const openShare = useCallback(() => {
    setShowShareNftModal(true);
  }, []);

  const closeShare = useCallback(() => {
    setShowShareNftModal(false);
  }, []);

  const handleSellClick = useCallback(() => {
    if (nft) {
      if (nft.id) {
        analyticService.track(AnalyticEventsEnum.MARKETPLACE_SELL, {nftId: nft.id});
      }
      startNftSell({
        cmsNftIncrementId: nft.id,
        nftName: nft.title,
        nftId: nft.address,
        nftMedia: {
          url: nft.image || '',
          alternativeText: '',
          caption: '',
        },
        ownedQty: owned,
        price: nft.priceUsd,
        collectionAddress: nft?.collectionAddress,
      });
    }
  }, [nft, owned, startNftSell]);

  const handlePurchase = useCallback(
    async (nftParams: NftPurchaseParams) => {
      await startNFTBuyOrRequestAuth(nftParams);
    },
    [startNFTBuyOrRequestAuth],
  );

  const handleBuyClick = useCallback(() => {
    if (nft.auctionStatus !== AuctionStatus.NOT_AUCTION) {
      if (nft.address) {
        analyticService.track(AnalyticEventsEnum.EXHIBIT_NFT_BID_CLICKED, {nftId: nft.address});
      }
    }

    if (nft.auctionStatus !== AuctionStatus.NOT_AUCTION && nft.balance) {
      if (nft.address) {
        analyticService.track(AnalyticEventsEnum.AUCTION_NFT_CARD_BUY_CLICKED, {nftId: nft.address});
      }
    }

    if (nft.auctionStatus !== AuctionStatus.NOT_AUCTION) {
      return handlePurchase({
        relType: nft.nftType,
        nftId: nft.id,
        nftAddress: nft.address,
        nftTitle: nft.title,
        nftImage: nft.image ?? '',
        nftPrice: nft.price,
        nftUSDPrice: nft.priceUsd,
        creatorName: nft.creatorName,
        minter: nft.minter,
        amount: 1,
        eventId: Number(nft.exhibitionId),
        eventSlug: nft.exhibitionSlug,
        auction: {
          latestBid: nft.auction!.price,
          bids: nft.auction!.bids,
        },
        collectionAddress: nft.collectionAddress,
      });
    }

    return startNFTPurchaseById(nft.id, 1);
  }, [nft, handlePurchase, startNFTPurchaseById]);

  const buttonProps = useNftCtaButtonProps({
    nft,
    event: exhibition as ExhibitCardInterface,
    balance: nft.balance ?? 0,
    buy: handleBuyClick,
    sell: handleSellClick,
    owned,
    share: openShare,
    hasUserOffer: Boolean(offers?.creatorMakeOffers?.length || offers?.creatorAuctions?.length),
    availableOnlyOnMarketplace: attributes.availableOnlyOnMarketplace,
  });

  const context: NftContextTypeV2 = {
    relType: nft.nftType,
    eventNftStatus: 'ready',
    cmsNftId: nft.id,
    attributes,
    event: exhibition as ExhibitCardInterface,
    eventUrl,
    nft: nft,
    creator: creator,
    balance: nft.balance || 0,
    supply: nft.supply || 0,
    price: {
      units: nft.price,
      usd: Math.floor(nft.priceUsd),
      cents: nft.priceUsd - Math.floor(nft.priceUsd),
    },
    auction: {priceText},
    market: {
      owned,
    },
    button: buttonProps,
    quantity: quantity,
    setQuantity: setQuantity,
    hasUserOffer: Boolean(offers?.creatorMakeOffers?.length || offers?.creatorAuctions?.length),
  };

  return (
    <NftContext.Provider value={context}>
      {children}
      <Share
        isOpen={showShareNftModal}
        onClose={closeShare}
        title={t('Share NFT')}
        description={nft.title || ''}
        imgSrc={nft.image}
        url={getShareUrl(`/${locale}/home/nft/${nft.id}`, nft.title || '', nft.description || '', nft.image || '')}
      />
    </NftContext.Provider>
  );
};
