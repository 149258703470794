import {CmsNft, ExchangeRate} from '../../types/graphql';
import {CmsNftAsset} from '@cere/services-types';
import {cereToUSD} from '../../lib/formatPrice';
import {gql, useQuery} from '@apollo/client';
import {CERE_EXCHANGE_RATE} from '../../queries';
import {useEffect, useMemo} from 'react';
import {useNotifications} from '../../hooks/use-notifications';
import {useNftBalance} from '../../hooks/use-nft-balance';
import {useGetNFTPrice} from '../../helpers/nfts';
import {Optional} from '../../types/optional';

type Nft = Optional<CmsNft, 'published_at'>;

export const useNftPriceAndBalance = (cmsNft?: Nft) => {
  const {error: errorMessage} = useNotifications();
  const {
    data: exchangeRate,
    loading,
    error,
    previousData,
  } = useQuery<{creatorExchangeRate: ExchangeRate}>(CERE_EXCHANGE_RATE);
  const {data: balance} = useNftBalance(cmsNft?.creatorNft?.nft_id);
  const price = useGetNFTPrice(cmsNft);

  useEffect(() => {
    if (error) {
      errorMessage(error.message);
    }
  }, [error, errorMessage]);

  const unitsExchangeRate = useMemo(
    () => exchangeRate?.creatorExchangeRate?.cere_units_per_penny || 0,
    [exchangeRate?.creatorExchangeRate?.cere_units_per_penny],
  );

  return useMemo(
    () => ({
      loading: loading && !previousData,
      totalQty: cmsNft?.creatorNft?.supply,
      availableQty: balance,
      price: cereToUSD(price, unitsExchangeRate),
      exchangeRate: unitsExchangeRate,
    }),
    [loading, previousData, cmsNft?.creatorNft?.supply, balance, price, unitsExchangeRate],
  );
};

const ASSETS_QUERY = gql`
  query nftAssets($id: ID!) {
    cmsNft(id: $id) {
      assets {
        content {
          url
          alternativeText
          caption
        }
      }
    }
  }
`;

export const useNftAssets = (nftId: string): CmsNftAsset[] | null => {
  const {data, loading} = useQuery<{cmsNft: {assets: CmsNftAsset[]}}>(ASSETS_QUERY, {variables: {id: nftId}});
  if (!data || loading) {
    return null;
  }
  return data.cmsNft.assets;
};
