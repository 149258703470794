import {NonCustodyWallet} from '../../shared/types/non-custody-wallet';
import {AppWallet, SupportedWallet} from '../../shared/types/supported-wallet';
import {createContext} from 'react';
import {noop} from 'lodash';
import {SocialUserData, UserData} from './types';
import {TorusWalletDialogOptions} from '../torus-context';

export type UserContextType = {
  userData: UserData;
  setUserData: Function;
  connectNonCustodialWallet: (options?: TorusWalletDialogOptions) => Promise<unknown>;
  nonCustodyWallets: NonCustodyWallet[];
  getNonCustodyWallets: () => Promise<unknown>;
  selectedWallet: SupportedWallet;
  setSelectedWallet: Function;
  isConnectWalletVisible: boolean;
  setIsConnectWalletVisible: (state: boolean) => unknown;
  selectedWalletPublicKey: string | undefined;
  socialUserData: SocialUserData | undefined;
  setSocialUserData: Function;
  isExhibitFirstOpen: boolean;
  setIsExhibitFirstOpen: Function;
  isProfileMenuOpen: boolean;
  setIsProfileMenuOpen: Function;
};

export const UserContext = createContext<UserContextType>({
  userData: {token: ''},
  setUserData: () => {},
  nonCustodyWallets: [],
  connectNonCustodialWallet: () => Promise.resolve(),
  selectedWallet: AppWallet.DAVINCI,
  setSelectedWallet: noop,
  getNonCustodyWallets: () => Promise.resolve(),
  socialUserData: undefined,
  setSocialUserData: noop,

  // Connect Wallet
  isConnectWalletVisible: false,
  setIsConnectWalletVisible: noop,
  selectedWalletPublicKey: '',
  isExhibitFirstOpen: true,
  setIsExhibitFirstOpen: noop,
  isProfileMenuOpen: false,
  setIsProfileMenuOpen: noop,
});
