import {CmsNftAsset} from '@cere/services-types';
import {buildAbsoluteCmsUrl} from '../queries/utils';
import {getMediaUrl} from '../lib/media';

export const getFirstAssetImage = (asset: CmsNftAsset): string | undefined => {
  return asset?.content ? buildAbsoluteCmsUrl(getMediaUrl(asset?.content)) : undefined;
};

export const getFirstInAssetMedia = (assets?: CmsNftAsset[]): undefined | CmsNftAsset => {
  if (!assets) {
    return undefined;
  }
  const assetsWithImages = assets.filter((asset) => getFirstAssetImage(asset));
  return assetsWithImages[0];
};

export const getFirstInAssetsImage = (assets?: CmsNftAsset[]): string | undefined => {
  if (!assets) {
    return '';
  }
  const assetsWithImages = assets.filter((asset) => getFirstAssetImage(asset));
  return assetsWithImages[0] && getFirstAssetImage(assetsWithImages[0]);
};
