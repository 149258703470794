import WalletConnectProvider from '@walletconnect/web3-provider';
import {providers} from 'ethers';

import {BiconomyConnectorDecorator} from './BiconomyConnectorDecorator';
import {IWalletConnector} from './IWalletConnector';
import {SupportedWalletTypes} from '../types';
import {HTTP_PROVIDER_URL, INFURA_ID, NETWORK_ID} from '../../../../config/common';

export class WalletConnectConnector implements IWalletConnector {
  readonly type = SupportedWalletTypes.WalletConnect;

  constructor(
    private readonly networkId: number,
    private readonly infuraId: string,
    private readonly httpProviderUrl: string,
  ) {}

  async connectToWallet(): Promise<providers.Web3Provider> {
    const wcProvider = new WalletConnectProvider({
      infuraId: this.infuraId,
      rpc: {
        [this.networkId]: this.httpProviderUrl,
      },
      qrcode: true,
    });
    await wcProvider.enable();
    return new providers.Web3Provider(wcProvider);
  }
}

export const getWalletConnectConnector = () =>
  new BiconomyConnectorDecorator(new WalletConnectConnector(NETWORK_ID, INFURA_ID || '', HTTP_PROVIDER_URL || ''));
