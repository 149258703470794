import {ReactElement, useContext, useMemo} from 'react';
import {Avatar, Typography} from '@cere/rxb-template-ui-kit';
import {FullCreatorInterface, UploadFile} from '@cere/services-types';
import {Box, Theme, useMediaQuery} from '@material-ui/core';
import clsx from 'clsx';

import {buildAbsoluteCmsUrl} from '../../../../../shared/queries/utils';
import {CmsExhibition} from '../../../../../shared/types/graphql';
import {getMediaUrl} from '../../../../../shared/lib/media';
import {getTimelineStatus} from '../../../../Сms/Exhibit/utils';
import {EventTimelineStatus} from '../../../../../shared/types/event';
import {SocialNetworksInfo} from '../../../../../shared/components/SocialNetworksInfo';
import {useStyles} from './styles';
import {AboutTheDrop} from './about-the-drop';
import {GoogleAnalyticsId} from '../../../../../analytics-ids';
import {link, StyledLink} from '../../../../../shared/components/StyledLink';
import {UserPaymentHistoryContext} from '../../../../../context/payments-history/user-payment-history.context';
import {getIsPaymentSuccess} from '../../../../../shared/helpers/paymentStatus';
import {ExhibitContext} from '../../../../../context/exhibition/exhibit-context';
import {VerifiedAuthorBadge} from '../../../../../shared/components/VerifiedAuthorIcon';
import {useLocalization} from '../../../../../shared/hooks/use-locale.hook';

interface ExhibitInfoProps {
  artist: FullCreatorInterface | undefined;
  event: CmsExhibition;
}

export const ExhibitInfo = ({artist, event}: ExhibitInfoProps): ReactElement => {
  const {t, locale} = useLocalization();
  const {accessNfts} = useContext(ExhibitContext);
  const {getPaymentStatuses, getNftIsPurchased} = useContext(UserPaymentHistoryContext);
  const someOfAccessNFTsIsPurchased = useMemo(
    () =>
      accessNfts.some(
        (nft) =>
          getNftIsPurchased(nft.cmsNft?.creatorNft?.nft_id) ||
          getIsPaymentSuccess(...getPaymentStatuses(nft.cmsNft?.creatorNft?.nft_id)),
      ),
    [accessNfts, getNftIsPurchased, getPaymentStatuses],
  );

  const isMomentBeforeExhibitionStarted = getTimelineStatus(event!) === EventTimelineStatus.NOT_STARTED;

  const isShort = !(
    !event?.artistQuote ||
    !event?.artistQuoteVisual ||
    !someOfAccessNFTsIsPurchased ||
    !isMomentBeforeExhibitionStarted
  );

  const classes = useStyles();
  const isDesktop = useMediaQuery<Theme>((theme) => `${theme.breakpoints.up('lg')}`);
  const isMobile = useMediaQuery<Theme>((theme) => `${theme.breakpoints.down('sm')}`);

  return (
    <Box>
      {(isMobile || isShort) && <AboutTheDrop isShort />}
      <Box width="fit-content">
        <StyledLink
          to={`/${locale}/home/creator/${artist?.id || event.cmsCreator.id}`}
          className={clsx(link, GoogleAnalyticsId.ViewCreatorBtn)}
        >
          <Box display="flex" alignItems="center" width="100%" mt="22px">
            <Avatar
              size={isMobile || isShort ? 'small' : 'large'}
              src={buildAbsoluteCmsUrl(getMediaUrl((artist?.avatar || event.cmsCreator.avatar) as UploadFile))}
              style={{maxWidth: '58px', maxHeight: '58px'}}
            />
            <Box pl="10px">
              {!isMobile && !isShort && (
                <Typography variant="h3" className={classes.aboutDropTextDesktop}>
                  {t('ABOUT THE DROP')}
                </Typography>
              )}
              <Typography variant="h4" className={!isMobile && !isShort && classes.artistNameDesktop}>
                {artist?.name || event.cmsCreator.name} <VerifiedAuthorBadge />
              </Typography>
            </Box>
          </Box>
        </StyledLink>
      </Box>
      <SocialNetworksInfo artist={event.cmsCreator} isShort={isShort} iconSize="15px" />
      {isDesktop && !isShort && <AboutTheDrop isShort={isShort} />}
      {!isMobile && !isDesktop && !isShort && <AboutTheDrop isShort={isShort} />}
    </Box>
  );
};
