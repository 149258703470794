import useSWR from 'swr';
import {NftCardInterface} from '@cere/services-types';
import {nftApi} from '../index';
import {useLocalization} from '../../shared/hooks/use-locale.hook';

export const useLoadNftByAddress = (address?: string | null): NftCardInterface | null => {
  const {locale} = useLocalization();
  const {data: nft} = useSWR(`nft-${address}`, async () => {
    if (!address) {
      return null;
    }
    return nftApi.getNftByAddress(address, locale);
  });
  return nft ?? null;
};
