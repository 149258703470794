import {useEffect, useContext, useMemo} from 'react';
import {throttle} from 'lodash';

import {ExhibitContext} from 'context/exhibition/exhibit-context';
import {EventTimelineStatus} from 'shared/types/event';

import {getTimelineStatus} from '../Сms/Exhibit/utils';
import {useAccessTicket} from './use-access-ticket';

type Params = {
  goToExhibitPage: () => void;
  userHasJwtAccess: boolean;
};

const KEY_VIEWED_EXHIBIT = 'viewed_exhibits';
const KEY_SCROLLED_HEIGHT = (id: number) => `scrolled_height_on_exhibit_${id}`;

export const useExhibitAutoPlay = ({goToExhibitPage, userHasJwtAccess}: Params) => {
  const {event, loading} = useContext(ExhibitContext);
  const timelineStatus = useMemo(() => getTimelineStatus(event), [event]);
  const nftPurchasedByUser = useAccessTicket({fromAllNFTs: true});

  const notDisable =
    event && !loading && userHasJwtAccess && !!nftPurchasedByUser && timelineStatus === EventTimelineStatus.STARTED;

  useEffect(() => {
    if (!notDisable) {
      return;
    }

    const viewedExhibitsStringValue = window.sessionStorage.getItem(KEY_VIEWED_EXHIBIT);
    let viewedExhibits = JSON.parse(viewedExhibitsStringValue ?? '[]') as string[];

    if (!Array.isArray(viewedExhibits)) {
      // transform wrong data format from local storage value
      window.sessionStorage.setItem(KEY_VIEWED_EXHIBIT, '[]');
      viewedExhibits = [];
    }

    if (viewedExhibits.includes(event.id.toString())) {
      return;
    }

    setTimeout(() => {
      const newViewedExhibits = [...viewedExhibits, event.id];
      window.sessionStorage.setItem(KEY_VIEWED_EXHIBIT, JSON.stringify(newViewedExhibits));
      goToExhibitPage();
    }, 3000);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notDisable]);

  useEffect(() => {
    if (!notDisable) {
      return;
    }

    const onScroll = throttle(() => {
      const scrollHeiht = document.body.scrollTop || document.documentElement.scrollTop;
      const viewPortHeight = document.body.clientHeight || document.documentElement.clientHeight;
      window.sessionStorage.setItem(KEY_SCROLLED_HEIGHT(event.id), (scrollHeiht + viewPortHeight).toString());
    }, 100);

    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!notDisable) {
      return;
    }

    const winScroll = window.sessionStorage.getItem(KEY_SCROLLED_HEIGHT(event.id));
    if (!winScroll || isNaN(Number(winScroll))) {
      return;
    }
    window.scrollTo({top: Number(winScroll), behavior: 'smooth'});

    return () => {
      window.sessionStorage.setItem(KEY_SCROLLED_HEIGHT(event.id), '0');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
