import {useRef} from 'react';
import {Box, Button, Divider, Typography, makeStyles} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import clsx from 'clsx';
import {ReactComponent as ArrowIcon} from '../../../assets/icons/arrow_right.svg';
import colors from '../../../styles/colors';
import {CopyButton} from '../CopyButton';
import {TOKEN_DECIMALS, TOKEN_TITLE} from '../../lib/formatPrice';
import {Trans} from 'react-i18next';
import NftModalHighlightCard from '../NftModalHighlightCard';
import {PurchaseModal} from '../PurchaseModal';
import {useLocalization} from '../../hooks/use-locale.hook';
import {mobileLandscapeMediaQuery} from '../../../styles/mediaQueries';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '24px',

    [mobileLandscapeMediaQuery(theme)]: {
      marginTop: '6px',
      display: 'flex',
      gap: '20px',
    },
  },

  dataBox: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'end',
    [mobileLandscapeMediaQuery(theme)]: {
      width: '50%',
    },
  },
  description: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    marginTop: '8px',
    textAlign: 'left',
    fontFamily: 'inherit',
    letterSpacing: 'inherit',

    '@media (orientation:portrait)': {
      textAlign: 'center',
      maxWidth: '343px',
    },
    [`${theme.breakpoints.up('md')} and (max-aspect-ratio:812/376)`]: {
      textAlign: 'center',
      maxWidth: '343px',
    },
  },
  descriptionWrapper: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    flexDirection: 'row',
    [`@media (orientation:portrait)`]: {
      justifyContent: 'center',
      flexDirection: 'column',
      marginTop: '16px',
    },
    [`${theme.breakpoints.up('md')} and (max-aspect-ratio:812/376)`]: {
      flexDirection: 'column',
      justifyContent: 'center',
      marginTop: '16px',
    },
  },
  button: {
    width: '311px',
    height: '36px',
    borderRadius: '50px',
    border: `1px solid ${colors.primaryDark}`,
    textTransform: 'none',

    [theme.breakpoints.up('lg')]: {
      height: '44px',
    },
  },
  title: {
    [`${theme.breakpoints.up('md')} and (max-aspect-ratio:812/376)`]: {
      fontSize: '28px',
      fontWeight: 800,
      lineHeight: '36px',
    },
  },
  link: {
    textDecoration: 'none',
  },
  icon: {
    '--size': '18px',
    height: 'var(--size)',
    width: 'var(--size)',
    color: theme.palette.info.main,
  },
  dataLabel: {
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '22px',
    color: colors.grey,
    marginBottom: '4px',
    fontFamily: 'inherit',
    letterSpacing: 'inherit',
  },
  dark: {
    color: colors.primaryDark,
  },
  dataText: {
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '22px',
    color: colors.primaryDark,
    marginBottom: '4px',
    maxWidth: '325px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontFamily: 'inherit',
    letterSpacing: 'inherit',
  },
  dataContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  copyButtonContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& button, & button svg': {
      width: '20px',
      height: '20px',
    },
  },
  dataLine: {
    marginBottom: '16px',
  },
  dataLabelWrapper: {
    display: 'flex',
    fontSize: '14px',
    marginBottom: '4px',
    color: colors.grey,
  },
  dataSubLabel: {
    fontWeight: 'normal',
    marginLeft: '1ch',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  media: {
    width: 'var(--media-size)',
    height: 'var(--media-size)',
    objectFit: 'fill',
    borderRadius: '6px',
  },
  arrowIcon: {
    marginTop: '-3px',
  },
}));

export interface NFTPurchaseConfirmedModalProps {
  nftId: string;
  author: string;
  nftTitle: string;
  nftAddress: string;
  nftImage?: string;
  lastPrice: number;
  quantity: number;
  bidsQty: number;
  open: boolean;
  onClose: () => void;
}

export const NFTBidConfirmedModal = ({
  nftId,
  author,
  nftTitle,
  nftAddress,
  nftImage,
  quantity,
  lastPrice,
  open,
  bidsQty,
  onClose,
}: NFTPurchaseConfirmedModalProps) => {
  const {t} = useLocalization();
  const styles = useStyles();
  const nftIdRef = useRef<HTMLElement | null>(null);

  return (
    <PurchaseModal
      open={open}
      classes={{title: styles.title}}
      icon={<CheckCircleIcon className={styles.icon} />}
      title={t('Bid successful')}
      onClose={onClose}
    >
      <Box className={styles.descriptionWrapper}>
        <Typography className={styles.description}>
          <Trans i18nKey="Your bid will be placed. It might take some time to show up in the bid history. You will be notified when your transaction is completed." />
        </Typography>
      </Box>
      <Box className={styles.root}>
        <Box className={styles.dataBox}>
          <Box className={clsx(styles.dataContainer, styles.dataLine)}>
            <Box className={styles.column}>
              <Typography className={styles.dataLabel}>Pending status</Typography>
              <Typography className={styles.dataText}>
                {t('Bid transaction processing')} <ArrowIcon className={styles.arrowIcon} />
              </Typography>
            </Box>
          </Box>
          <Box className={clsx(styles.dataContainer, styles.dataLine)}>
            <Box className={styles.column}>
              <NftModalHighlightCard
                nftId={nftId}
                title={author}
                nftTitle={nftTitle}
                nftImage={nftImage}
                onClick={onClose}
              />
            </Box>
          </Box>
          <Box className={clsx(styles.dataContainer, styles.dataLine)}>
            <Box className={styles.column}>
              <Typography className={styles.dataLabel}>{t('NFT ID')}</Typography>
              <Box className={styles.copyButtonContainer}>
                <Typography className={styles.dataText} ref={nftIdRef}>
                  {nftAddress}
                </Typography>
                <CopyButton node={nftIdRef} />
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className={styles.dataBox}>
          <Box className={clsx(styles.column, styles.dataLine)}>
            <Box className={styles.dataContainer}>
              <Typography className={styles.dataLabel}>{t('Quantity')}</Typography>
              <Typography className={styles.dataText}>{quantity}</Typography>
            </Box>
            <Divider />
          </Box>
          <Box className={styles.column}>
            <Box className={styles.dataContainer}>
              <Box className={styles.dataLabelWrapper}>
                <Typography className={styles.dataLabel}>{t('Your bid')}</Typography>
                <Typography className={styles.dataSubLabel}>
                  <Trans i18nKey="({{count}} bids)" values={{count: bidsQty}} count={bidsQty} />
                </Typography>
              </Box>
              <Typography className={styles.dataText}>{`${
                (lastPrice / TOKEN_DECIMALS) * quantity
              } ${TOKEN_TITLE}`}</Typography>
            </Box>
            <Divider />
          </Box>
        </Box>
      </Box>
      <Box className={styles.buttonContainer}>
        <Button className={styles.button} onClick={onClose}>
          {t('OK')}
        </Button>
      </Box>
    </PurchaseModal>
  );
};
