import {Web3Provider} from '@ethersproject/providers';
import {IWalletConnector} from './IWalletConnector';
import {ConnectWalletOptions, SupportedWalletTypes} from '../types';
import {TorusWallet} from '../TorusWallet';
import {BiconomyConnectorDecorator} from './BiconomyConnectorDecorator';

export class TorusConnector implements IWalletConnector {
  readonly type = SupportedWalletTypes.Torus;

  constructor(private readonly torusWallet: TorusWallet) {}

  async connectToWallet(options?: ConnectWalletOptions): Promise<Web3Provider> {
    await this.torusWallet.connect(options);
    return this.torusWallet.getProvider();
  }

  async disconnect(): Promise<void> {
    await this.torusWallet.disconnect();
  }
}

export const getTorusConnector = (torusWallet: TorusWallet) =>
  new BiconomyConnectorDecorator(new TorusConnector(torusWallet));
