import {createContext} from 'react';

export interface LocalizationContextInterface {
  locale: string;
  userLocale: string;
  updateLocale: (locale: string) => void;
}

export const LocalizationContext = createContext<LocalizationContextInterface>({
  locale: '',
  userLocale: '',
  updateLocale: () => null,
});
