import {Box} from '@material-ui/core';
import {Counter, Typography} from '@cere/rxb-template-ui-kit';

import {CmsExhibition} from 'shared/types/graphql';
import {ExhibitionTeaserType} from 'shared/types/exhibition-teaser';

import {useStyles} from '../Exhibition-preview-container/live-styles';
import {ExhibitTeaser} from '../Exhibition-preview-container/exhibit-teaser';
import {PendingTransaction} from './PendingTransaction';

type Props = {
  event: CmsExhibition;
  endsAt: string;
};

export const ActiveUnsignedFreeAccessEvent = ({event, endsAt}: Props) => {
  const shouldShowTeaser = Boolean(event?.liveTeaser?.content);
  const styles = useStyles({});

  return (
    <Box className={styles.exhibitionInfo}>
      <Box className={styles.exhibitionInfoLeft}>
        <Box className={styles.exhibitionCounter}>
          <Typography variant="caption1">Exhibit ends in</Typography>
          <Counter
            withSec
            startsAt={new Date(endsAt)}
            withCellParameters={true}
            cellParametersClassName={styles.cellParamClassName}
            cellClassName={styles.cellClassName}
            delimiterClassName={styles.cellClassName}
          >
            <Box className={styles.liveExhibit}>Exhibit LIVE!</Box>
          </Counter>
        </Box>
        <Box className={styles.exhibitionTitles}>
          <Typography className={styles.eventSubtitle} variant="h5">
            {event?.subtitle}
          </Typography>
          <Box className={styles.exhibitionTitleWithoutTheme}>{event?.title}</Box>
        </Box>
        <PendingTransaction />
      </Box>
      {shouldShowTeaser && (
        <Box className={styles.exhibitionImageBlock}>
          <Box className={styles.exhibitionTeaserContainer}>
            <ExhibitTeaser type={ExhibitionTeaserType.LIVE} />
          </Box>
        </Box>
      )}
    </Box>
  );
};
