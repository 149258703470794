import {IAuthApi} from '../../../api/auth/IAuthApi';
import {IAuthService} from '../../../models/auth/IAuthService';
import {UserDataStorage} from '../../../models/auth/UserDataStorage';
import {StoreModule} from '../../base/types';
import {AUTH_MODULE_NAME} from './constants';
import {userDataReducer} from './reducers';
import {
  initUserDataRestorationMiddleware,
  logOutMiddleware,
  resendOTPCodeMiddleware,
  restoreUserDataMiddleware,
  signInByEmailMiddleware,
  signInBySocialMiddleware,
  verifyEmailOTPMiddleware,
  verifyOTPMiddleware,
  verifySocialOTPMiddleware,
} from './middlewares';

export const createAuthModule = (
  authApi: IAuthApi,
  authService: IAuthService,
  userDataStorage: UserDataStorage,
): StoreModule => ({
  title: AUTH_MODULE_NAME,
  moduleReducer: userDataReducer,
  middlewares: [
    initUserDataRestorationMiddleware,
    restoreUserDataMiddleware(userDataStorage),
    verifyOTPMiddleware(userDataStorage),
    signInByEmailMiddleware(authService, userDataStorage),
    verifyEmailOTPMiddleware(authApi, userDataStorage),
    resendOTPCodeMiddleware(authApi, userDataStorage),
    signInBySocialMiddleware(authService, userDataStorage),
    verifySocialOTPMiddleware(authApi, userDataStorage),
    logOutMiddleware(userDataStorage),
  ],
});
