import {Box, Theme, useTheme} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import {Button, Typography} from 'shared/components/UI';
import {TYPOGRAPHY_VARIANTS} from '../../../shared/components/UI/Typography/types';
import colors from '../../../styles/colors';
import valueProposition from '../../../assets/valueProposition.png';
import {BUTTON_COLORS, BUTTON_SIZES, BUTTON_VARIANTS} from '../../../shared/components/UI/Button/types';
import clsx from 'clsx';
import {useHistory} from 'react-router-dom';
import {useCallback, useContext} from 'react';
import {AppContext} from '../../../context/app-context';
import {useLocalization} from '../../../shared/hooks/use-locale.hook';

export const useStyles = makeStyles((theme: Theme) => ({
  propositionContent: {
    display: 'flex',
    backgroundColor: colors['background-grey'],
    flexDirection: 'row',
    justifyContent: 'center',
    minHeight: '572px',
    marginTop: '64px',
    borderRadius: '32px',

    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      marginTop: '36px',
      paddingLeft: '20px',
      paddingRight: '20px',
    },

    [theme.breakpoints.between('sm', 'lg')]: {
      marginTop: '40px',
    },
  },
  contentImage: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingTop: '28px',
    paddingBottom: '38px',

    marginRight: 0,

    [theme.breakpoints.up('lg')]: {
      marginRight: '47px',
    },
  },
  image: {
    minHeight: '312px',
    backgroundOrigin: 'border-box',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',

    [theme.breakpoints.between('sm', 'lg')]: {
      minHeight: '290px',
    },

    [theme.breakpoints.up('lg')]: {
      minHeight: '400px',
    },
  },
  imageShadow: {
    height: '24px',
    borderRadius: '50%',
    marginLeft: '34px',
    marginRight: '34px',
    marginTop: '14px',
    backgroundImage: `radial-gradient(ellipse at center, ${colors.primaryDark}, ${colors.lightBackground})`,
    filter: 'blur(8px)',
    opacity: 0.2,

    [theme.breakpoints.up('lg')]: {
      height: '36px',
    },
  },
  contentInfo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    maxWidth: '620px',
    paddingBottom: '36px',
  },
  infoHeader: {
    fontWeight: 'bold',
    fontSize: '28px',

    [theme.breakpoints.between('sm', 'lg')]: {
      fontSize: '32px',
    },

    [theme.breakpoints.up('lg')]: {
      fontSize: '68px',
    },
  },
  infoText: {
    marginTop: '12px',
    maxWidth: '585px',
    color: colors.primaryDark,
    opacity: 0.7,
    fontSize: '18px',
  },
  infoButtons: {
    marginTop: '33px',

    [theme.breakpoints.between('sm', 'lg')]: {
      marginTop: '28px',
    },

    [theme.breakpoints.up('lg')]: {
      marginTop: '40px',
    },
  },
  button: {
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    minWidth: '161px',

    [theme.breakpoints.up('lg')]: {
      minWidth: '223px',
    },
  },
  buttonBrowse: {
    background: colors.primaryDark,

    [theme.breakpoints.down('md')]: {
      marginBottom: '12px',
    },
    [theme.breakpoints.up('md')]: {
      marginRight: '12px',
    },
    '&:hover': {
      background: colors.primaryDark,
    },
  },
  buttonSell: {
    border: `1px solid ${theme.palette.secondary.main}`,
  },
}));

export const ValueProposition = () => {
  const {t, locale} = useLocalization();
  const theme = useTheme();
  const history = useHistory();
  const classes = useStyles(theme);
  const {appConfig} = useContext(AppContext);

  const onBrowseExhibits = useCallback(() => {
    history.push(`/${locale}/exhibits`);
  }, [history, locale]);

  const onSell = useCallback(() => {
    history.push(`/${locale}/home/user/nfts`);
  }, [history, locale]);

  return (
    <Box className={classes.propositionContent}>
      <Box className={classes.contentImage}>
        <img className={classes.image} src={valueProposition} alt="" />
        <i className={classes.imageShadow} />
      </Box>
      <Box className={classes.contentInfo}>
        <Typography variant={TYPOGRAPHY_VARIANTS.body1} className={classes.infoHeader}>
          {t('Unique NFT')}
          <br />
          {t('Experiences')}
        </Typography>
        <Typography variant={TYPOGRAPHY_VARIANTS.body1} className={classes.infoText}>
          {t(
            'More than just an NFT collectible, {{appTitle}} NFTs unlock exclusive experiences from hand selected creators especially for you.',
            {appTitle: appConfig.appTitle},
          )}
        </Typography>
        <Box className={classes.infoButtons}>
          <Button
            size={BUTTON_SIZES.large}
            color={BUTTON_COLORS.primary}
            variant={BUTTON_VARIANTS.contained}
            className={clsx(classes.button, classes.buttonBrowse)}
            onClick={onBrowseExhibits}
          >
            {t('Browse exhibits')}
          </Button>
          <Button
            size={BUTTON_SIZES.large}
            color={BUTTON_COLORS.secondary}
            variant={BUTTON_VARIANTS.outlined}
            className={clsx(classes.button, classes.buttonSell)}
            onClick={onSell}
          >
            {t('Sell')}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
