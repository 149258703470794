import {useContext, useEffect, useState} from 'react';
import {AppContext} from '../../context/app-context';

interface LocalStorageInterface {
  value: string | null;
  oldValue: string | null;
}

export const useLocalStorage = (key: string): LocalStorageInterface => {
  const [value, setValue] = useState<string | null>(localStorage.getItem(key));
  const [oldValue, setOldValue] = useState<string | null>(localStorage.getItem(key));
  const {appInstanceId} = useContext(AppContext);

  Object.keys(localStorage)
    .filter((item) => !item.startsWith(appInstanceId.substring(0, 6)) && item.endsWith(':nextPage'))
    .map((item) => localStorage.removeItem(item));

  useEffect(() => {
    function updateValue({key: changedKey, newValue, oldValue}: StorageEvent) {
      if (changedKey === key) {
        setValue(newValue);
        setOldValue(oldValue);
      }
    }
    window.addEventListener('storage', updateValue);
    return function cleanup() {
      window.removeEventListener('storage', updateValue);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    value,
    oldValue,
  };
};
