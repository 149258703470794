import 'swiper/swiper-bundle.css';
import {CuratedRow} from '../../shared/components/CuratedRow';

import {EventCardV2} from 'shared/components/EventCardBig/EventCardV2';
import {ExhibitionBFF} from './types';

interface HomePageExhibitsRowProps {
  featuredExhibits: ExhibitionBFF[] | undefined;
}

export const HomePageExhibitsRow = ({featuredExhibits}: HomePageExhibitsRowProps) => {
  return (
    <CuratedRow>
      {featuredExhibits && featuredExhibits.map((event) => <EventCardV2 key={event.id} event={event} />)}
    </CuratedRow>
  );
};
