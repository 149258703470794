import {ReactNode} from 'react';
import {makeStyles} from '@material-ui/core';
import clsx from 'clsx';

import {useSharedStyles} from '../shared-styles';

type Props = {
  title: string;
  rightElement?: ReactNode;
};

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '1.5rem 0',
    height: '44px',

    [theme.breakpoints.down('lg')]: {
      height: '36px',
    },
  },

  title: {
    paddingTop: 0,

    fontWeight: 'bold',
    fontSize: '20px',
    lineHeight: '26px',

    [theme.breakpoints.up('md')]: {
      fontWeight: '800',
      fontSize: '24px',
      lineHeight: '32px',
      marginBottom: '8px',
    },

    [theme.breakpoints.up('lg')]: {
      fontWeight: '800',
      fontSize: '30px',
      lineHeight: '40px',
      marginBottom: '8px',
    },
  },
}));

export const PageHeader = ({title, rightElement}: Props) => {
  const styles = useStyles();
  const sharedStyles = useSharedStyles();

  return (
    <div className={styles.header}>
      <h1 className={clsx(sharedStyles.title, styles.title)}>{title}</h1>
      {rightElement}
    </div>
  );
};
