import {useMemo} from 'react';
import {makeStyles, ThemeProvider, Button as MaterialButton, useTheme} from '@material-ui/core';
import {mergeClasses} from '@material-ui/styles';
import {noop} from 'lodash';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {Typography} from '../Typography';
import colors from '../../../../styles/colors';
import {BUTTON_COLORS, BUTTON_SIZES, BUTTON_VARIANTS} from './types';

const VARIANTS = {
  [BUTTON_SIZES.small]: 'body2',
  [BUTTON_SIZES.large]: 'body1',
};

const useStyles = makeStyles((theme) => ({
  root: {
    borderWidth: 0,
    borderRadius: '50px',
    '&> *': {
      pointerEvents: 'none',
    },
  },
  sizeSmall: {
    minHeight: '36px',
  },
  sizeLarge: {
    minHeight: '48px',
    padding: '10px 16px',
  },
  containedSecondary: {
    background: `linear-gradient(277.13deg, ${colors.buttonSecondary} 11.98%, ${theme.palette.secondary.main} 83.77%)`,

    '&.Mui-disabled': {
      background: 'rgba(0, 0, 0, 0.12)',
    },
  },
  disabled: {
    backgroundColor: `${colors.disable} !important`,
    color: `${colors.light} !important`,
  },
}));

const Button = ({
  variant,
  color,
  size = BUTTON_SIZES.small,
  fullWidth,
  children,
  onClick = noop,
  style,
  textStyle,
  className,
  classes = {},
  ...props
}: ButtonProps) => {
  const theme = useTheme();
  const defaultClasses = useStyles(variant);
  const computedClasses = useMemo(
    // @ts-ignore
    () => mergeClasses({baseClasses: defaultClasses, newClasses: classes}),
    [classes, defaultClasses],
  );

  return (
    <ThemeProvider theme={theme}>
      <MaterialButton
        {...props}
        style={style}
        classes={computedClasses}
        className={clsx(className)}
        variant={variant}
        color={color}
        size={size}
        fullWidth={fullWidth}
        disableElevation
        disableFocusRipple
        onClick={onClick}
      >
        {/* @ts-ignore */}
        <Typography variant={VARIANTS[size]} style={textStyle}>
          {children}
        </Typography>
      </MaterialButton>
    </ThemeProvider>
  );
};

Button.propTypes = {
  variant: PropTypes.oneOf(['contained', 'outlined', 'text']),
  color: PropTypes.oneOf(['primary', 'secondary']),
  size: PropTypes.oneOf(['small', 'large']),
  fullWidth: PropTypes.bool,
  children: PropTypes.node,
  onClick: PropTypes.func,
  style: PropTypes.object,
  textStyle: PropTypes.object,
  className: PropTypes.string,
  classes: PropTypes.object,
};

interface ButtonProps {
  variant?: BUTTON_VARIANTS;
  color?: BUTTON_COLORS;
  size?: BUTTON_SIZES;
  fullWidth?: boolean;
  children?: string | React.ReactNode;
  onClick?: any;
  style?: Record<string, string | number>;
  textStyle?: Record<string, string | number>;
  className?: string;
  classes?: any;
}

export {Button};
