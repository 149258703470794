import {TorusWallet, TorusWalletOptions} from './wallet';
import {
  BICONOMY_API_KEY,
  CERE_WALLET_DISABLE_BICONOMY,
  CERE_WALLET_POPUP_MODE,
  ENV,
  HTTP_PROVIDER_URL,
  NETWORK_ID,
  webAppUrl,
} from '../../../config/common';

/**
 * Singleton Torus Wallet instance
 * TODO: Figure out how to stop using it as Singleton in favor of React Context
 */
let walletInstance: TorusWallet | undefined;

type WalletOptions = {
  completeUrl?: string;
  replaceInstance?: boolean;
  logoUrl?: string;
  appName?: string;
};

export const getWallet = (params?: WalletOptions) => {
  if (params?.replaceInstance) {
    walletInstance = undefined;
  }

  if (walletInstance) {
    return walletInstance;
  }

  /**
   * Allow disabling Biconomy for Cere wallet integration
   */

  const POPUP_MODE = CERE_WALLET_POPUP_MODE() === 'popup' ? 'popup' : 'modal';

  if (!NETWORK_ID || !HTTP_PROVIDER_URL) {
    throw new Error('Tor.us: Incorrect configuration in .env');
  }

  const defaultName = window.location.hostname;
  walletInstance = new TorusWallet({
    env: ENV as TorusWalletOptions['env'],
    popupMode: POPUP_MODE,
    biconomyApiKey: CERE_WALLET_DISABLE_BICONOMY() ? undefined : BICONOMY_API_KEY!,
    completeUrl: params?.completeUrl ? `${webAppUrl()}${params.completeUrl}` : undefined,
    context: {
      app: {
        logoUrl: params?.logoUrl,
        url: window.location.origin,
        name: params?.appName || defaultName,
      },
    },
    network: {
      host: HTTP_PROVIDER_URL,
      chainId: NETWORK_ID,
    },
  });

  return walletInstance;
};

export const switchNetwork = () => getWallet().switchNetwork();

export const connectAndSignIn = async (params?: WalletOptions) => {
  const wallet = getWallet(params);

  await wallet.init();

  if (wallet.status === 'connected') {
    return wallet.getAddress();
  }

  return wallet.connect();
};

export const getSigner = async () => {
  const wallet = getWallet();

  if (wallet.status !== 'connected') {
    await connectAndSignIn();
  }

  return wallet.getSigner();
};

export const disconnect = async () => {
  try {
    await getWallet().disconnect();
  } catch (e) {
    return false;
  }

  return true;
};

export {TorusWallet};
