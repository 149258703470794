import clsx from 'clsx';
import {Box, Card, CardMedia, Grid} from '@material-ui/core';
import Share from '../../../shared/components/Share';
import {getShareUrl} from '../../../shared/components/Share/utils/get-share-url';
import {ShareIcon} from '../../../shared/components/ShareIcon';
import {Typography} from '../../../shared/components/UI';
import {TYPOGRAPHY_VARIANTS} from '../../../shared/components/UI/Typography/types';
import {formatPrice, usdcUnitsToUSD} from '../../../shared/lib/formatPrice';
import {useAdditionsNFTsStyles, useNFTCardMediaStyles, useNFTCardStyles} from './styles';
import {MouseEvent, ReactElement, useCallback, useContext, useMemo, useState} from 'react';
import dayjs from 'dayjs';
import {NftContext} from '../../../shared/components/Nft/nft-context-v2';
import {NftLink} from './nft-link';
import {Avatar} from 'shared/components/Avatar';
import {Trans} from 'react-i18next';
import {NftActionButton} from '../../../shared/components/ActionButtons';
import {AvailabilityStatus, NftType} from '@cere/services-types';
import {useLocalization} from '../../../shared/hooks/use-locale.hook';

export interface NFTCardProps {
  actionButtonText?: string;
  actionButtonClassName?: string;
  isSharable?: boolean;
  renderBadge?: () => ReactElement;
  withAdditionNFTs?: boolean;
  isCutTitle?: boolean;
  isFeaturedListingsCard?: boolean;
}

export const NFTCard = ({
  isSharable,
  renderBadge,
  withAdditionNFTs = false,
  isCutTitle,
  isFeaturedListingsCard,
}: NFTCardProps) => {
  const {t, locale} = useLocalization();
  const [isShareModalOpened, setShareModalOpened] = useState<boolean>(false);
  const {nft, auction, price, event, eventNftStatus, creator} = useContext(NftContext);
  const isComingSoon = nft.availability === AvailabilityStatus.COMING_SOON || !event;
  const mediaClasses = useNFTCardMediaStyles({comingSoon: isComingSoon, image: nft.image});
  const classes = useNFTCardStyles({comingSoon: isComingSoon});
  const additionNFTsClasses = useAdditionsNFTsStyles();

  const closeShareModal = useCallback(() => setShareModalOpened(false), [setShareModalOpened]);
  const openShareModal = useCallback(
    (event: MouseEvent) => {
      event.stopPropagation();
      setShareModalOpened(true);
    },
    [setShareModalOpened],
  );
  const isComingSoonCaption = useMemo(() => {
    if (isComingSoon && event?.startsAt) {
      return dayjs(event?.startsAt).format('MM.DD.YYYY');
    }
  }, [event?.startsAt, isComingSoon]);

  const isFullyLoaded = eventNftStatus !== 'loading';
  const isAuction = nft.nftType === NftType.AUCTIONED;

  return (
    <Box className={withAdditionNFTs ? additionNFTsClasses.cardBlock : classes.container}>
      {isFullyLoaded && renderBadge?.()}
      <Share
        isOpen={isShareModalOpened}
        onClose={closeShareModal}
        title={t('Share NFT')}
        description={nft.title}
        imgSrc={nft.image}
        url={getShareUrl(`/${locale}/home/nft/${event?.id}`, nft.title ?? '', nft.description ?? '', nft.image ?? '')}
      />
      <Card className={withAdditionNFTs ? additionNFTsClasses.container : classes.container}>
        <NftLink>
          <CardMedia
            className={clsx(mediaClasses.root, withAdditionNFTs && additionNFTsClasses.root)}
            image={nft.image}
          >
            {isComingSoon && isFullyLoaded && (
              <div className={classes.comingSoon}>
                {t('Coming soon')}
                {isComingSoonCaption && <span>{isComingSoonCaption}</span>}
              </div>
            )}
          </CardMedia>
        </NftLink>
        {isSharable && <ShareIcon onClick={openShareModal} className={classes.share} />}
        <Grid item className={classes.cardService}>
          <Box className={classes.serviceTexts}>
            {!withAdditionNFTs && (
              <Avatar
                name={creator?.name}
                imageUrl={creator?.avatar.url}
                creatorId={creator?.id}
                isCutTitle={isCutTitle}
                isNftCard
                isFeaturedListingsCard={isFeaturedListingsCard}
              />
            )}
            <NftLink>
              <Typography
                variant={TYPOGRAPHY_VARIANTS.body1}
                className={clsx(classes.cardTitle, withAdditionNFTs && additionNFTsClasses.cardTitle)}
              >
                {nft.title}
              </Typography>
            </NftLink>
            {!isComingSoon && (
              <Box className={classes.cardAmount}>
                {isAuction ? (
                  <Typography variant={TYPOGRAPHY_VARIANTS.body1} className={classes.amountCommon}>
                    {t('{{amount}} Auctioned Original', {amount: nft.balance})}
                  </Typography>
                ) : (
                  <Typography
                    variant={TYPOGRAPHY_VARIANTS.body1}
                    className={clsx(classes.amountCommon, classes.amountLimited)}
                  >
                    <Trans
                      i18nKey="<strong>{{leftAmount}}</strong>/{{totalAmount}} NFTs left"
                      values={{
                        leftAmount: nft.balance,
                        totalAmount: nft.supply,
                      }}
                      count={nft.supply}
                    />
                  </Typography>
                )}
              </Box>
            )}
          </Box>
          <Box className={classes.serviceInfo}>
            {!isComingSoon && (
              <Typography variant={TYPOGRAPHY_VARIANTS.button1} className={classes.serviceInfoText}>
                {auction.priceText && isAuction && (
                  <Typography
                    variant={TYPOGRAPHY_VARIANTS.button1}
                    className={clsx(classes.serviceInfoText, withAdditionNFTs && additionNFTsClasses.serviceInfoText)}
                  >
                    {auction.priceText}
                  </Typography>
                )}
                {formatPrice(usdcUnitsToUSD(price.units))}
              </Typography>
            )}
            <NftActionButton className={classes.button} />
          </Box>
        </Grid>
      </Card>
    </Box>
  );
};
