import {createContext, FC, useCallback, useContext, useMemo, useState} from 'react';
import {Auction} from '../model/auction/Auction';
import {BidNFTAuctionModal} from '../shared/components/BidNFTAuctionModal';
import {NFTBidConfirmedModal} from '../shared/components/NFTBidConfirmedModal';
import {NFTBidDeniedModal} from '../shared/components/NFTBidDeniedModal';

export type AuctionNftData = {
  nftId: string;
  creatorName: string;
  title: string;
  address: string;
  image: string;
  eventSlug?: string;
};

export interface IAuctionContext {
  startAuctionFlow: (auction: Auction, nftData: AuctionNftData) => void;
}

export const AuctionContext = createContext<IAuctionContext>({
  startAuctionFlow: () => {},
});

export const useStartAuctionFlow = (): ((auction: Auction, nftData: AuctionNftData) => void) => {
  const {startAuctionFlow} = useContext<IAuctionContext>(AuctionContext);
  return startAuctionFlow;
};

export enum AuctionModalTypes {
  MAKE_BID_MODAL = 'MAKE_BID_MODAL',
  BID_CONFIRMED_MODAL = 'BID_CONFIRMED_MODAL',
  BID_DENIED_MODAL = 'BID_DENIED_MODAL',
}

export const AuctionContextProvider: FC = ({children}) => {
  const [nftData, setNftData] = useState<AuctionNftData | null>(null);
  const [currentAuction, setCurrentAuction] = useState<Auction | null>(null);
  const [activeModalType, setActiveModalType] = useState<AuctionModalTypes | null>(null);
  const [auctionPrice, setAuctionPrice] = useState(0);
  const [isProcessing, setProcessing] = useState<boolean>(false);
  const [bidError, setBidError] = useState<Error | null>(null);

  const isFlowActive = Boolean(nftData) && Boolean(currentAuction);

  const showMakeBidModal = () => setActiveModalType(AuctionModalTypes.MAKE_BID_MODAL);
  const showBidConfirmedModal = () => setActiveModalType(AuctionModalTypes.BID_CONFIRMED_MODAL);
  const showBidDeniedModal = () => setActiveModalType(AuctionModalTypes.BID_DENIED_MODAL);

  const startAuctionFlow = useCallback((auction: Auction, auctionNftData: AuctionNftData) => {
    setCurrentAuction(auction);
    setNftData(auctionNftData);
    showMakeBidModal();
  }, []);

  const closeModal = useCallback(() => {
    setActiveModalType(null);
    setCurrentAuction(null);
    setNftData(null);
    setBidError(null);
  }, [setActiveModalType, setCurrentAuction, setNftData]);

  const makeBid = useCallback(
    async (price: number) => {
      try {
        setProcessing(true);
        setAuctionPrice(price);
        await currentAuction?.makeBid(price);
        showBidConfirmedModal();
      } catch (e) {
        console.error(e);
        setBidError(e);
        showBidDeniedModal();
      } finally {
        setProcessing(false);
      }
    },
    [currentAuction],
  );

  const onTryAgainClick = useCallback(async () => {
    showMakeBidModal();
    await makeBid(auctionPrice);
  }, [auctionPrice, makeBid]);

  const value = useMemo(() => ({startAuctionFlow}), [startAuctionFlow]);

  return (
    <AuctionContext.Provider value={value}>
      {children}
      {currentAuction && nftData && (
        <>
          <BidNFTAuctionModal
            nftId={nftData.nftId}
            author={nftData.creatorName}
            nftTitle={nftData.title}
            nftAddress={nftData.address}
            nftImage={nftData.image ?? ''}
            latestBidPrice={currentAuction.getLatestBidPrice()!}
            bidsQty={currentAuction.getBids().length ?? 0}
            nextMinBid={currentAuction.getNextMinBid() ?? 0}
            validateBidPrice={currentAuction.validateBid.bind(currentAuction)}
            open={isFlowActive && activeModalType === AuctionModalTypes.MAKE_BID_MODAL}
            isProcessing={isProcessing}
            makeBid={makeBid}
            onClose={closeModal}
            initAuctionPrice={auctionPrice}
          />
          <NFTBidConfirmedModal
            nftId={nftData.nftId}
            author={nftData.creatorName}
            nftTitle={nftData.title}
            nftAddress={nftData.address}
            nftImage={nftData.image ?? ''}
            lastPrice={currentAuction.getLatestBidPrice() ?? 0}
            quantity={1}
            bidsQty={currentAuction.getBids().length ?? 0}
            open={isFlowActive && activeModalType === AuctionModalTypes.BID_CONFIRMED_MODAL}
            onClose={closeModal}
          />
          <NFTBidDeniedModal
            nftId={nftData.nftId}
            author={nftData.creatorName}
            nftTitle={nftData.title}
            nftAddress={nftData.address}
            nftImage={nftData.image ?? ''}
            lastPrice={auctionPrice || (currentAuction?.getLatestBidPrice() ?? 0)}
            quantity={1}
            bidsQty={currentAuction?.getBids().length ?? 0}
            eventSlug={nftData.eventSlug ?? ''}
            error={bidError!}
            open={isFlowActive && activeModalType === AuctionModalTypes.BID_DENIED_MODAL}
            onClose={closeModal}
            onTryAgainClick={onTryAgainClick}
            isProcessing={isProcessing}
          />
        </>
      )}
    </AuctionContext.Provider>
  );
};
