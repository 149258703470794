import {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {singletonHook} from 'react-singleton-hook';
import {UserContext} from '../../context/user-context/user-context';
import {PaymentStatusEnum} from '@cere/services-types';
import {isEqual, noop} from 'lodash';
import {PaymentSessionParams} from '../types/payment-session-params';
import {getPaymentStatusesByNftIds} from '../services/nft.service';
import {getPaymentHistoryByPublicKey, getSessionsParamsByIds} from '../services/payment.service';
import {isNewTokenPurchased} from '../helpers/paymentStatus';
import {useLocalization} from './use-locale.hook';

const init = {
  statusesByNftId: {},
  sessionParamsById: {},
  setPollingEnabled: noop,
  setNftIds: noop,
  newTokenPurchased: false,
  setNewTokenPurchased: noop,
};

const DELAY_BETWEEN_REQUESTS = 5 * 1000;

interface UsePollNftStatusResult {
  setPollingEnabled: (status: boolean) => void;
  setNftIds: (nftIds: string[]) => void;
  statusesByNftId: {[key: string]: PaymentStatusEnum};
  sessionParamsById: {[key: string]: PaymentSessionParams};
  newTokenPurchased: boolean;
  setNewTokenPurchased: (status: boolean) => void;
}

export const usePollNftStatus = (): UsePollNftStatusResult => {
  const [pollingEnabled, setPollingEnabled] = useState<boolean>(false);
  const [nftIds, setNftIds] = useState<string[]>([]);
  const [statusesByNftId, setStatusesByNftId] = useState<{[key: string]: PaymentStatusEnum}>({});
  const [sessionParamsById, setSessionParamsById] = useState<Record<string, PaymentSessionParams>>({});
  const [newTokenPurchased, setNewTokenPurchased] = useState(false);
  const checkPaymentIntervalId = useRef<number>();
  const {locale} = useLocalization();

  const {userData, nonCustodyWallets} = useContext(UserContext);

  const fetchStatusesForNfts = useCallback(async () => {
    if (userData.userPublicKey) {
      try {
        const userPayments = await getPaymentHistoryByPublicKey({ethAddress: userData.userPublicKey});
        const sessionParams = await getSessionsParamsByIds(userPayments);
        const statuses = await getPaymentStatusesByNftIds(
          nftIds,
          userData.userPublicKey,
          nonCustodyWallets.map((wallet) => wallet.publicKey),
          userPayments,
          locale,
        );

        if (!isEqual(statusesByNftId, statuses)) {
          if (isNewTokenPurchased(Object.values(statusesByNftId), Object.values(statuses))) {
            setNewTokenPurchased(true);
          }

          setStatusesByNftId(statuses);
        }

        if (!isEqual(sessionParamsById, sessionParams)) {
          setSessionParamsById(sessionParams);
        }
      } catch (e) {
        console.error('Failed to fetch payment history for the user. ', e.message);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nftIds, userData.userPublicKey, statusesByNftId, sessionParamsById]);

  useEffect(() => {
    clearInterval(checkPaymentIntervalId.current);

    if (userData.token && nftIds.length && pollingEnabled) {
      checkPaymentIntervalId.current = window.setInterval(fetchStatusesForNfts, DELAY_BETWEEN_REQUESTS);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData.userPublicKey, pollingEnabled, nftIds, checkPaymentIntervalId.current]);

  useEffect(() => {
    void fetchStatusesForNfts();
  }, [nftIds, fetchStatusesForNfts]);

  return {
    setPollingEnabled,
    setNftIds,
    statusesByNftId,
    sessionParamsById,
    newTokenPurchased,
    setNewTokenPurchased,
  };
};

export const useSingletonPollNftStatus = singletonHook<UsePollNftStatusResult>(init, usePollNftStatus);
