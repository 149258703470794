import {useState} from 'react';
import useSWR from 'swr';
import {creatorApi} from '../index';
import {FullCreatorInterface} from '@cere/services-types';
import {useLocalization} from '../../shared/hooks/use-locale.hook';

const INTERVAL_TIME_MS = 5000;

export const useHomePageCreators = (): {
  loading: boolean;
  creators: FullCreatorInterface[] | undefined;
} => {
  const [loading, setLoading] = useState<boolean>(true);
  const {locale} = useLocalization();
  const {data: creators} = useSWR(
    ['home-page-creators'],
    async () => {
      const creators = await creatorApi.getHomePageCreators(locale);
      setLoading(false);
      return creators;
    },
    {
      errorRetryInterval: INTERVAL_TIME_MS,
    },
  );

  return {
    loading,
    creators,
  };
};
