import {apolloClient} from './graphqlClient';
import {createNftsApi} from './nfts/NftsBFFApi';
import {restClient} from './restClient';
import {createPurchaseHistoryApi} from './purchase-history';
import {createAuctionsApi} from './auctions';
import {createAppConfigApi} from './app-config';
import {createHomePageApi} from './home-page';
import {createCreatorsApi} from './creators/CreatorsBFFApi';
import {createEventsApi} from './events';
import {createMarketplacePageApi} from './marketplace-page';

export const creatorsApi = createCreatorsApi(restClient);
export const eventsApi = createEventsApi(restClient);
export const nftsApi = createNftsApi(restClient);
export const purchaseHistoryApi = createPurchaseHistoryApi(apolloClient);
export const auctionsApi = createAuctionsApi(apolloClient);
export const appConfigApi = createAppConfigApi(apolloClient);
export const homePageApi = createHomePageApi(restClient);
export const marketplacePageApi = createMarketplacePageApi(restClient);
