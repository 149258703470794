import {useCallback, useRef} from 'react';
import {Box, Button, Divider, Typography, CircularProgress, makeStyles} from '@material-ui/core';
import ErrorCircleIcon from '@material-ui/icons/Error';
import clsx from 'clsx';
import {PurchaseModal} from '../PurchaseModal';
import colors from '../../../styles/colors';
import {CopyButton} from '../CopyButton';
import {TOKEN_DECIMALS, TOKEN_TITLE} from '../../lib/formatPrice';
import {useHistory} from 'react-router-dom';
import NftModalHighlightCard from '../NftModalHighlightCard';
import {Trans} from 'react-i18next';
import {humanReadableError} from '../../lib/error-handler';
import {useLocalization} from '../../hooks/use-locale.hook';
import {mobileLandscapeMediaQuery} from '../../../styles/mediaQueries';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',

    [theme.breakpoints.up('lg')]: {
      flexDirection: 'column',
      justifyContent: 'start',
    },
    [mobileLandscapeMediaQuery(theme)]: {
      display: 'flex',
      gap: '20px',
    },
  },
  dataBox: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'end',
    [theme.breakpoints.up('lg')]: {
      width: '100%',
    },
    [mobileLandscapeMediaQuery(theme)]: {
      width: '50%',
    },
  },
  description: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    margin: '8px 0 24px 0',
    textAlign: 'center',
    fontFamily: 'inherit',
    letterSpacing: 'inherit',
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '16px',
    [theme.breakpoints.up('md')]: {
      marginTop: '24px',
    },
    [mobileLandscapeMediaQuery(theme)]: {
      flexDirection: 'row-reverse',
      justifyContent: 'flex-start',
    },
  },
  button: {
    width: '100%',
    height: '36px',
    borderRadius: '50px',
    border: `1px solid ${colors.primaryDark}`,
    textTransform: 'none',

    '&:not(:last-child)': {
      marginBottom: '8px',
      [mobileLandscapeMediaQuery(theme)]: {
        marginBottom: 0,
        marginLeft: '8px',
      },
    },

    [mobileLandscapeMediaQuery(theme)]: {
      width: '180px',
    },

    [theme.breakpoints.up('md')]: {
      width: '200px',
    },
    [theme.breakpoints.up('lg')]: {
      width: '311',
      height: '44px',
    },
  },
  link: {
    textDecoration: 'none',
  },
  icon: {
    '--size': '18px',
    height: 'var(--size)',
    width: 'var(--size)',
    color: theme.palette.secondary.main,
  },
  dataLabel: {
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '22px',
    color: colors.grey,
    marginBottom: '4px',
    fontFamily: 'inherit',
    letterSpacing: 'inherit',
  },
  dark: {
    color: colors.primaryDark,
  },
  dataText: {
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '22px',
    color: colors.primaryDark,
    marginBottom: '4px',
    maxWidth: '325px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontFamily: 'inherit',
    letterSpacing: 'inherit',
  },
  dataContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  copyButtonContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& button, & button svg': {
      width: '20px',
      height: '20px',
    },
  },
  dataLine: {
    marginBottom: '18px',
  },
  dataLabelWrapper: {
    display: 'flex',
    fontSize: '14px',
    marginBottom: '4px',
    color: colors.grey,
  },
  dataSubLabel: {
    fontWeight: 'normal',
    marginLeft: '1ch',
    fontFamily: 'inherit',
    letterSpacing: 'inherit',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  media: {
    width: 'var(--media-size)',
    height: 'var(--media-size)',
    objectFit: 'fill',
    borderRadius: '6px',
  },
  arrowIcon: {
    marginTop: '-3px',
  },
}));

export interface NFTPurchaseDeniedModalProps {
  nftId: string;
  author: string;
  nftTitle: string;
  nftAddress: string;
  nftImage?: string;
  lastPrice: number;
  quantity: number;
  bidsQty: number;
  error: {message: string};
  open: boolean;
  eventSlug: string;
  onClose: () => void;
  onTryAgainClick?: () => void;
  isProcessing?: boolean;
}

export const NFTBidDeniedModal = ({
  nftId,
  author,
  nftTitle,
  nftAddress,
  nftImage,
  lastPrice,
  quantity,
  eventSlug,
  bidsQty,
  error,
  open,
  onClose,
  onTryAgainClick,
  isProcessing,
}: NFTPurchaseDeniedModalProps) => {
  const {t, locale} = useLocalization();
  const styles = useStyles();
  const nftIdRef = useRef<HTMLElement | null>(null);
  const history = useHistory();
  const onBackToExhibit = useCallback(() => {
    eventSlug && history.push(`/${locale}/home/exhibit/${eventSlug}`);
    onClose && onClose();
  }, [eventSlug, history, onClose, locale]);

  const errMessage = t('Error occurred. {{errorDetails}}', {errorDetails: humanReadableError(error)});

  return (
    <PurchaseModal
      open={open}
      icon={<ErrorCircleIcon className={styles.icon} />}
      title={t('Bid failed')}
      onClose={onClose}
    >
      <Typography className={styles.description}>{errMessage}</Typography>
      <Box className={styles.root}>
        <Box className={styles.dataBox}>
          <Box className={clsx(styles.dataContainer, styles.dataLine)}>
            <Box className={styles.column}>
              <NftModalHighlightCard
                nftId={nftId}
                title={author}
                nftTitle={nftTitle}
                nftImage={nftImage}
                onClick={onClose}
              />
            </Box>
          </Box>
          <Box className={clsx(styles.dataContainer, styles.dataLine)}>
            <Box className={styles.column}>
              <Typography className={styles.dataLabel}>{t('NFT ID')}</Typography>
              <Box className={styles.copyButtonContainer}>
                <Typography className={styles.dataText} ref={nftIdRef}>
                  {nftAddress}
                </Typography>
                <CopyButton node={nftIdRef} />
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className={styles.dataBox}>
          <Box className={clsx(styles.column, styles.dataLine)}>
            <Box className={styles.dataContainer}>
              <Typography className={styles.dataLabel}>{t('Quantity')}</Typography>
              <Typography className={styles.dataText}>{quantity}</Typography>
            </Box>
            <Divider />
          </Box>
          <Box className={styles.column}>
            <Box className={styles.dataContainer}>
              <Box className={styles.dataLabelWrapper}>
                <Typography className={styles.dataLabel}>{t('Your bid')}</Typography>
                <Typography className={styles.dataSubLabel}>
                  <Trans i18nKey="({{count}} bids)" values={{count: bidsQty}} count={bidsQty} />
                </Typography>
              </Box>
              <Typography className={styles.dataText}>{`${
                (lastPrice / TOKEN_DECIMALS) * quantity
              } ${TOKEN_TITLE}`}</Typography>
            </Box>
            <Divider />
          </Box>
        </Box>
      </Box>
      <Box className={styles.buttonContainer}>
        {onTryAgainClick && (
          <>
            <Button
              color="primary"
              variant="contained"
              className={styles.button}
              onClick={onTryAgainClick}
              disabled={isProcessing}
            >
              {isProcessing ? <CircularProgress /> : t('Try again')}
            </Button>
          </>
        )}
        <Button className={styles.button} onClick={onBackToExhibit}>
          {t('Back to exhibit')}
        </Button>
      </Box>
    </PurchaseModal>
  );
};
