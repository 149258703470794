import {Numberish} from '../../types/numberish';
import jwtDecode from 'jwt-decode';

import {
  fetchNotifications,
  getExhibitionNotificationsData,
  getNftNotificationsData,
  patchNotification,
  patchNotifications,
} from './notifications.service';
import {NotificationItem, TransactionalNotification, TransactionalNotificationsInterface} from './types';
import {TransactionalNotificationsEvent, TransactionalNotificationStatus} from './enums';

export class TransactionalNotifications implements TransactionalNotificationsInterface {
  public list: TransactionalNotification[] = [];
  public totalCount: number = 0;
  public isFirstPageLoading: boolean = true;
  public isNextPageLoading: boolean = false;
  public newNotificationsCount: number = 0;
  private events: EventTarget;
  private userId?: number;
  private token?: string;
  private pageSize = 10;

  constructor() {
    this.events = new EventTarget();
  }

  setUserCredentials(token: string): void {
    if (this.token === token) {
      return;
    }

    const {id} = jwtDecode<{id: number}>(token);

    this.reset();
    this.token = token;
    this.userId = id;
  }

  public reset() {
    this.setNotificationsList([]);
    this.setTotalCount(0);
    this.userId = undefined;
    this.token = undefined;
    this.setIsNextPageLoading(true);
    this.setIsFirstPageLoading(true);
  }

  public resetList() {
    this.setNotificationsList([]);
  }

  subscribe(event: TransactionalNotificationsEvent, handler: () => void) {
    this.events.addEventListener(event, handler);

    return () => this.events.removeEventListener(event, handler);
  }

  private setNotificationsList(notifications: TransactionalNotification[]) {
    this.list = notifications;
    this.events.dispatchEvent(new Event(TransactionalNotificationsEvent.LIST_UPDATED));
  }

  private setTotalCount(total: number) {
    this.totalCount = total;
    this.events.dispatchEvent(new Event(TransactionalNotificationsEvent.TOTAL_COUNT_UPDATED));
  }

  public resetNotificationsList() {
    this.setNotificationsList([]);
  }

  private async fetchNotificationsList(page: number) {
    if (!this.userId || !this.token) {
      return;
    }

    const {data, total} = await fetchNotifications(this.userId, this.token, page, this.pageSize);
    const notifications = await this.extendNotificationsData(data);
    const deduplicatedNotifications = this.deduplicateNotifications(notifications);

    this.setNotificationsList(deduplicatedNotifications);
    this.setTotalCount(total);
  }

  public async loadFirstPage() {
    if (this.list.length > this.pageSize) {
      return;
    }
    this.setIsFirstPageLoading(true);

    try {
      await this.fetchNotificationsList(0);
    } finally {
      this.setIsFirstPageLoading(false);
    }
  }

  public async loadNextPage() {
    const nextPage = Math.floor(this.list.length / this.pageSize);
    this.setIsNextPageLoading(true);
    try {
      await this.fetchNotificationsList(nextPage);
    } finally {
      this.setIsNextPageLoading(false);
    }
  }

  public async loadNewNotificationsCount() {
    if (!this.userId || !this.token) {
      return;
    }

    const {total} = await fetchNotifications(this.userId, this.token, 0, 1, [
      TransactionalNotificationStatus.NEW,
      TransactionalNotificationStatus.UNREAD,
    ]);

    this.newNotificationsCount = total;
    this.events.dispatchEvent(new Event(TransactionalNotificationsEvent.NEW_COUNT_UPDATED));
  }

  private async extendNotificationsData(data: NotificationItem[]): Promise<TransactionalNotification[]> {
    const [nftsData, exhibitsData] = await Promise.all([
      getNftNotificationsData(data),
      getExhibitionNotificationsData(data),
    ]);

    return data.map((notification) => ({
      id: notification.id,
      type: notification.type,
      status: notification.status,
      createdAt: notification.createdAt,
      nft: notification.cmsNftId ? nftsData?.find(({id}) => Number(notification.cmsNftId) === Number(id)) : undefined,
      exhibit: notification.exhibitId
        ? exhibitsData?.find(({id}) => Number(notification.exhibitId) === Number(id))
        : undefined,
    }));
  }

  private deduplicateNotifications(notifications: TransactionalNotification[]): TransactionalNotification[] {
    const newUniqueNotifications = notifications.filter(
      (notification) => !this.list.find(({id}) => id === notification.id),
    );

    return [...this.list, ...newUniqueNotifications];
  }

  private setIsFirstPageLoading(isLoading: boolean) {
    this.isFirstPageLoading = isLoading;
    this.events.dispatchEvent(new Event(TransactionalNotificationsEvent.IS_FIRST_PAGE_LOADING_UPDATED));
  }

  private setIsNextPageLoading(isLoading: boolean) {
    this.isNextPageLoading = isLoading;
    this.events.dispatchEvent(new Event(TransactionalNotificationsEvent.IS_NEXT_PAGE_LOADING_UPDATED));
  }

  public async fetchNewNotificationCount() {
    if (!this.userId || !this.token) {
      return;
    }
    fetchNotifications(this.userId, this.token, 0, 0);
  }

  public async markAsUnread(id: Numberish): Promise<void> {
    if (!this.userId || !this.token) {
      return;
    }
    return this.updateNotificationStatus(id, this.userId, this.token, TransactionalNotificationStatus.UNREAD);
  }

  public async markAsRead(id: Numberish): Promise<void> {
    if (!this.userId || !this.token) {
      return;
    }
    return this.updateNotificationStatus(id, this.userId, this.token, TransactionalNotificationStatus.READ);
  }

  private async updateNotificationStatus(
    id: Numberish,
    userId: number,
    token: string,
    status: TransactionalNotificationStatus,
  ): Promise<void> {
    const notificationIndex = this.list.findIndex((n) => n.id === id);

    if (notificationIndex !== -1) {
      await patchNotification(userId, token, id, status);

      this.list[notificationIndex] = {...this.list[notificationIndex], status};
      this.list = [...this.list];
      this.events.dispatchEvent(new Event(TransactionalNotificationsEvent.LIST_UPDATED));

      await this.loadNewNotificationsCount();
    }
  }

  public async markAllAsRead(): Promise<void> {
    if (!this.userId || !this.token) {
      return;
    }

    await patchNotifications(this.userId, this.token, TransactionalNotificationStatus.READ);

    this.list = this.list.map((n) => ({
      ...n,
      status: TransactionalNotificationStatus.READ,
    }));
    this.events.dispatchEvent(new Event(TransactionalNotificationsEvent.LIST_UPDATED));
    await this.loadNewNotificationsCount();
  }

  public async onNotificationCreated(payload: NotificationItem): Promise<void> {
    const [notification] = await this.extendNotificationsData([payload]);

    this.list = [notification, ...this.list];
    this.events.dispatchEvent(new Event(TransactionalNotificationsEvent.LIST_UPDATED));
    this.events.dispatchEvent(
      new CustomEvent<TransactionalNotification>(TransactionalNotificationsEvent.NOTIFICATION_CREATED, {
        detail: notification,
      }),
    );

    this.events.dispatchEvent(new Event(TransactionalNotificationsEvent.NEW_COUNT_UPDATED));
    await this.loadNewNotificationsCount();
  }
}
