import {ReactElement, useCallback} from 'react';
import {PageLayout} from '../../shared/PageLayout';
import {HeaderControl} from '../../shared/HeaderControl';
import {HeaderNavLinkType} from '../../shared/HeaderControl/types';

type AppContainerViewProps = {
  isUserAuthenticated: boolean;
  children: ReactElement | ReactElement[];
};

export const AppContainerView = ({children, isUserAuthenticated}: AppContainerViewProps) => {
  const renderUserControls = useCallback(
    (navLinks: HeaderNavLinkType[]) => {
      return (
        <HeaderControl
          navLinks={navLinks}
          wallets={[]}
          activeWallet={''}
          setActiveWallet={() => {}}
          isHaveUnreadedNotifications={false}
          isUserAuth={isUserAuthenticated}
          // TODO - add userName and userLogo to UserData
          // userName?: string;
          // userLogo?: string;
          onLogOut={() => {}}
        />
      );
    },
    [isUserAuthenticated],
  );

  return <PageLayout children={children} renderHeaderUserControl={renderUserControls} />;
};
