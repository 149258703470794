import {Box, Grid} from '@material-ui/core';
import {generatePath} from 'react-router-dom';
import {FullCreatorInterface} from '@cere/services-types';
import {useTranslation} from 'react-i18next';

import {ROUTES} from '../../constants/routes';
import {Title, SubTitle} from '../../components/primitives/Title';
import {PageContainer} from '../../components/shared/PageContainer';
import {CreatorsRowSkeleton} from '../../components/shared/Skeletons';
import {useOnComponentRendered} from '../../utils/hooks/use-on-component-rendered';

import {CreatorCard} from './components/CreatorCard';

export type CreatorsPageViewProps = {
  locale: string;
  loading: boolean;
  creators: FullCreatorInterface[];
  loadCreators: () => void;
};

export const CreatorsPageView = ({locale, creators, loading, loadCreators}: CreatorsPageViewProps) => {
  const {t} = useTranslation();

  useOnComponentRendered(loadCreators);

  return (
    <Box overflow="hidden">
      <PageContainer>
        <Box pt="32px">
          <Title>{t('Creators')}</Title>
        </Box>
        <Box mt="4px" mb="20px" width="75%">
          <SubTitle>
            {t(
              'Verified creators bringing transforming their best work in unique NFT experiences which are exclusively sold on DaVinci.',
            )}
          </SubTitle>
        </Box>

        <Box pb="32px">
          {loading ? (
            <CreatorsRowSkeleton />
          ) : (
            <Grid container spacing={4}>
              {creators.map((creator) => (
                <Grid key={creator.id} item xs={12} md={6} lg={4}>
                  <CreatorCard
                    key={creator.id}
                    link={generatePath(ROUTES.CREATOR, {locale, artistId: creator.id})}
                    creatorName={creator.name}
                    creatorAbout={creator.about}
                    creatorAvatar={creator.avatar.url}
                    desktopBackgroundImage={creator.desktopBackgroundImage}
                    tabletBackgroundImage={creator.tabletBackgroundImage}
                    mobileBackgroundImage={creator.mobileBackgroundImage}
                  />
                </Grid>
              ))}
            </Grid>
          )}
        </Box>
      </PageContainer>
    </Box>
  );
};
