import {makeStyles, Theme} from '@material-ui/core';

export const useCardStyles = makeStyles<Theme, {showMore: boolean}>((theme) => ({
  scrollable: {
    overflowY: 'auto',
    maxWidth: '100%',
    display: 'flex',
    flex: '0 0 auto',
    flexDirection: 'column',
    gap: '1rem 1rem',

    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },

    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  item: {
    '& div': {
      '--size': 'unset',
      width: ({showMore}) => (showMore ? 'unset' : undefined),
    },

    [theme.breakpoints.up('md')]: {
      flex: ({showMore}) => (showMore ? '0 0 360px' : '0 0 405px'),
    },
  },
  notification: {
    margin: '16px 0',
  },
  selectedNFT: {
    border: '1px solid rgba(60, 188, 172, 0.5)',
  },
}));
