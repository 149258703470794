import {StoreModule} from '../../base/types';
import {USER_WALLETS_MODULE_NAME} from './constants';
import {userWalletsModuleReducer} from './reducers';
import {WalletConnectionService} from '../../../models/wallet/WalletConnectionService';
import {
  connectWalletMiddleware,
  disconnectWalletMiddleware,
  selectWalletMiddleware,
  walletsBalancePollingMiddleware,
  walletsConnectionRestoreMiddleware,
  walletsInitMiddleware,
} from './middlewares';
import {WalletsBalanceService} from '../../../models/balance/WalletsBalanceService';

export * from './actions';

const BALANCE_POLLING_INTERVAL = 10000;

export const createUserWalletsModule = (
  walletConnectionService: WalletConnectionService,
  walletsBalanceService: WalletsBalanceService,
): StoreModule => ({
  title: USER_WALLETS_MODULE_NAME,
  moduleReducer: userWalletsModuleReducer,
  middlewares: [
    walletsInitMiddleware,
    walletsConnectionRestoreMiddleware(walletConnectionService),
    connectWalletMiddleware(walletConnectionService),
    selectWalletMiddleware(walletConnectionService),
    disconnectWalletMiddleware(walletConnectionService),
    walletsBalancePollingMiddleware(walletsBalanceService, BALANCE_POLLING_INTERVAL),
  ],
});
