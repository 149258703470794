import {useEffect, useState, useCallback} from 'react';
import useSWR from 'swr';
import {CmsCreator, NftCardInterface} from '@cere/services-types';
import {nftApi} from '../index';
import {useParams} from 'react-router-dom';
import {useLocalization} from '../../shared/hooks/use-locale.hook';
import {GET_ARTISTS} from 'shared/queries';
import {useQuery} from '@apollo/client';

export interface ArtistPageRouteParams {
  artistId: string;
}

export const useCreatorPageNfts = (): {
  loading: boolean;
  nfts: NftCardInterface[] | undefined;
} => {
  const {artistId} = useParams<ArtistPageRouteParams>();
  const [loading, setLoading] = useState<boolean>(true);
  const {locale} = useLocalization();
  const {data: nfts} = useSWR(
    ['creator-page-nfts'],
    async () => {
      const nfts = await nftApi.getAllNfts({creatorId: artistId, locale});
      setLoading(false);
      return nfts;
    },
    {
      errorRetryInterval: 5000,
    },
  );

  return {
    loading,
    nfts,
  };
};

export const useNftsByCreator = (): {
  artistsWithAvailaiableNfts: CmsCreator[];
} => {
  const {locale} = useLocalization();
  const {data} = useQuery<{artists: CmsCreator[]}, {locale: string}>(GET_ARTISTS, {variables: {locale}});
  const [artistsWithAvailaiableNfts, setArtistsWithAvailaiableNfts] = useState<CmsCreator[]>([]);

  const processArtists = useCallback(
    async (artists: CmsCreator[]) => {
      const allResults = await Promise.allSettled(
        artists.map((artist) => nftApi.getCreatorPageNftsByCreatorId(artist.id, locale)),
      );

      return artists.filter((_, i) => {
        const nftsRes = allResults[i];
        return nftsRes.status === 'fulfilled' && nftsRes.value.some(({balance}) => balance && balance > 0);
      });
    },
    [locale],
  );

  useEffect(() => {
    if (data?.artists) {
      processArtists(data?.artists).then(setArtistsWithAvailaiableNfts);
    }
  }, [data?.artists, processArtists]);

  return {
    artistsWithAvailaiableNfts,
  };
};
