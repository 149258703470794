import ical from 'ical-generator';
import {ExhibitionStatus} from '@cere/services-types';

import {getTimelineStatus} from '../../pages/Сms/Exhibit/utils';
import {dispatchError} from '../lib/error-handler';
import {attachFileForDownload} from '../lib/utils';
import {CmsExhibition} from '../types/graphql';
import i18n from '../../i18n';
import {ExhibitionBFF} from 'pages/HomePage/types';
import {VipEventType} from 'shared/types/vipEvent';

export const sortAllEvents = (events: (CmsExhibition | ExhibitionBFF)[]): (CmsExhibition | ExhibitionBFF)[] => {
  return events
    .map((event) => ({
      ...event,
      status: event.isComingSoon ? ExhibitionStatus.NOT_STARTED : getTimelineStatus(event as ExhibitionBFF),
    }))
    .sort((prev, next) => {
      if (prev.status === next.status) {
        return 0;
      }

      if (next.status === ExhibitionStatus.STARTED) {
        return 1;
      }

      if (next.status === ExhibitionStatus.NOT_STARTED) {
        return prev.status === ExhibitionStatus.STARTED ? -1 : 1;
      }

      return -1;
    });
};

export const addEventToCalendar = async (event: CmsExhibition) => {
  try {
    const calendar = ical();

    const {startsAt, endsAt, title, description} = event;

    if (!startsAt) {
      console.error(`Exhibition with ID=${event.id} don't have startsAt property`);
      return;
    }

    calendar.createEvent({
      start: startsAt,
      url: window.location.href,
      ...(endsAt ? {end: endsAt} : {}),
      ...(title ? {summary: title} : {}),
      ...(description ? {description} : {}),
    });

    attachFileForDownload(calendar.toURL(), 'event');
  } catch {
    dispatchError(i18n.t('Something went wrong!'));
  }
};

export const addVipEventToCalendar = async (event: VipEventType) => {
  try {
    const calendar = ical();

    const {date, title, address} = event;

    if (!date) {
      console.error(`Exhibition with ID=${event.id} don't have startsAt property`);
      return;
    }

    calendar.createEvent({
      start: date,
      url: window.location.href,
      ...(title ? {summary: title} : {}),
      ...(address ? {address} : {}),
    });

    attachFileForDownload(calendar.toURL(), 'event');
  } catch {
    dispatchError(i18n.t('Something went wrong!'));
  }
};
