import {Children, FC} from 'react';
import {makeStyles} from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  tabsContainer: {
    '--padding': '0.5rem',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    background: theme.palette.grey['200'],
    padding: '0.25rem',
    borderRadius: '2rem',
    cursor: 'pointer',

    '& .tab': {
      width: (childrenQty: number) => `calc(${100 / childrenQty}% - var(--padding))`,
    },
  },
}));

export interface TabsContainerProps {
  className?: string;
}

export const TabsContainer: FC<TabsContainerProps> = ({children, className}) => {
  const styles = useStyles(Children.toArray(children).length);
  return <div className={clsx(styles.tabsContainer, className)}>{children}</div>;
};
