import {useCallback, useEffect, useMemo, useState} from 'react';
import {useQuery} from '@apollo/client';
import {CmsCreator, CmsNftWithWallets} from '../types/graphql';
import {TOKEN_DECIMALS} from '../lib/formatPrice';
import {useSelectedWallet} from '../../context/use-selected-wallet';
import {useNftData} from './use-nft-data';
import {GET_NFT_OFFERS, GET_NFT_PRIMARY_OFFER} from '../queries/offers';
import {getNFTOfferQuantity} from '../helpers/nfts';
import {PendingTransactionTypes, usePendingTransactionsContext} from '../../context/pending-transaction-context';
import {Numberish} from '../types/numberish';
import {useOwnerOffer} from './use-owner-offer';
import {AUCTION_SERVICE_WALLETS} from '../../config/common';

export type CMSNFTOffer = {
  id: string;
  seller: string;
  price: number;
  published_at: string;
  creator_nft: {
    creator_wallet_nfts: Array<{
      wallet: string;
      quantity: number;
    }>;
  };
};

export type CMSNFTAuction = {
  id: string;
  seller: string;
  price: number;
  published_at: string;
  nft_id: {
    creator_wallet_nfts: Array<{
      wallet: string;
      quantity: number;
    }>;
  };
};

export enum NFTOfferTypes {
  FixedPrice = 'FIXED_PRICE',
  Auction = 'AUCTION',
}

export type NFTOffer = {
  id?: string;
  seller: string;
  price: number;
  qty: number;
  usdPrice: number;
  timestamp: string;
  isPrimaryOffer?: boolean;
  isOwnerOffer?: boolean;
  creator?: CmsCreator;
  type: NFTOfferTypes;
};

type Variables = {
  cmsNftIncrementId?: Numberish;
  start: number;
  limit?: number;
  wallets?: string[];
};

type AuctionsVariables = {
  cmsNftIncrementId?: Numberish;
  start: number;
  limit?: number;
  restrictedWallets?: string[];
};

const transformToTableData = (cmsOffers?: (CMSNFTOffer | CMSNFTAuction)[]): NFTOffer[] => {
  if (!cmsOffers) {
    return [];
  }

  return cmsOffers
    .map((offer) => {
      let qty: number = 0;
      let type: NFTOfferTypes;
      if ('nft_id' in offer) {
        qty = 1;
        type = NFTOfferTypes.Auction;
      } else {
        qty = getNFTOfferQuantity(offer);
        type = NFTOfferTypes.FixedPrice;
      }
      return {
        id: offer.id,
        seller: offer.seller,
        qty,
        price: offer.price / TOKEN_DECIMALS,
        usdPrice: offer.price / TOKEN_DECIMALS,
        timestamp: offer.published_at,
        type,
      };
    })
    .filter((offer) => offer.qty > 0);
};

const getPrimaryOffer = (
  cmsOffers?: CMSNFTOffer[],
  cmsAuctions?: CMSNFTAuction[],
  nftData?: CmsNftWithWallets,
): NFTOffer | null => {
  if (!cmsOffers && !cmsAuctions) {
    return null;
  }
  if (!nftData) {
    return null;
  }

  const [primaryAuction] = transformToTableData(cmsAuctions);
  const [primaryOffer] = transformToTableData(cmsOffers);
  const offer = primaryOffer || primaryAuction;

  if (!offer) {
    return null;
  }

  offer.creator = nftData.cmsCreator;
  offer.isPrimaryOffer = true;

  return offer;
};

const transformOwnerOffer = (cmsOffers?: CMSNFTOffer[], cmsAuctions?: CMSNFTAuction[]): NFTOffer | null => {
  const [ownerOffer] = transformToTableData(cmsOffers);
  const [ownerAuction] = transformToTableData(cmsAuctions);
  const offer = ownerAuction || ownerOffer;
  if (!offer) {
    return null;
  }
  offer.isOwnerOffer = true;
  return offer;
};

export const useNftListing = ({cmsNftIncrementId, start, limit = 10}: Variables) => {
  const selectedWallet = useSelectedWallet();
  const [offers, setOffers] = useState<NFTOffer[]>([]);
  const {getPendingTransactionForNft} = usePendingTransactionsContext();
  const {loading: nftDataLoading, nftData} = useNftData(cmsNftIncrementId);
  const {data: nftPrimaryOffers, loading: nftPrimaryOffersLoading} = useQuery<{
    creatorMakeOffers: CMSNFTOffer[];
    creatorAuctions: CMSNFTAuction[];
  }>(GET_NFT_PRIMARY_OFFER, {
    variables: {nftId: cmsNftIncrementId, wallet: nftData?.creatorNft?.minter, creatorId: nftData?.cmsCreator?.id},
    skip: cmsNftIncrementId == null || (!nftData?.creatorNft?.minter && !nftData?.cmsCreator?.id),
  });
  const {data: nftOwnerOffers, loading: nftOwnerOffersLoading} = useOwnerOffer(nftData?.creatorNft?.nft_id);
  const {data, loading, fetchMore} = useQuery<
    {creatorMakeOffers: CMSNFTOffer[]; creatorAuctions: CMSNFTAuction[]},
    AuctionsVariables
  >(GET_NFT_OFFERS, {
    variables: {
      cmsNftIncrementId,
      start,
      limit,
      restrictedWallets: [nftData?.creatorNft?.minter!, selectedWallet.publicKey!, ...AUCTION_SERVICE_WALLETS()],
    },
    skip: cmsNftIncrementId == null || !nftData?.creatorNft?.minter,
  });

  useEffect(() => {
    if (data) {
      setOffers(transformToTableData([...data.creatorMakeOffers, ...data.creatorAuctions]));
    }
  }, [data, setOffers, loading]);

  const loadMoreData = useCallback(async () => {
    const moreData = await fetchMore({
      variables: {start: offers.length},
    });
    setOffers((prevOffers) => [...prevOffers, ...transformToTableData(moreData.data.creatorMakeOffers)]);
  }, [fetchMore, offers.length]);

  const pendingTransaction = useMemo(
    () => getPendingTransactionForNft(nftData?.creatorNft?.nft_id),
    [nftData, getPendingTransactionForNft],
  );

  const ownerOffer: NFTOffer | null = useMemo(() => {
    if (nftOwnerOffers?.creatorMakeOffers.length || nftOwnerOffers?.creatorAuctions.length) {
      return transformOwnerOffer(nftOwnerOffers?.creatorMakeOffers, nftOwnerOffers?.creatorAuctions);
    }
    if (!pendingTransaction || pendingTransaction.type !== PendingTransactionTypes.SELL_NFT) {
      return null;
    }
    return {
      id: '',
      seller: pendingTransaction.wallet,
      qty: pendingTransaction.qty || 0,
      price: (pendingTransaction.price || 0) / TOKEN_DECIMALS,
      usdPrice: (pendingTransaction.price || 0) / TOKEN_DECIMALS,
      timestamp: pendingTransaction.timestamp || new Date().toString(),
      isOwnerOffer: true,
      type: NFTOfferTypes.FixedPrice,
    };
  }, [pendingTransaction, nftOwnerOffers]);

  const listings = useMemo(
    () => ({
      offers,
      primaryOffer: getPrimaryOffer(nftPrimaryOffers?.creatorMakeOffers, nftPrimaryOffers?.creatorAuctions, nftData),
      loading: loading || nftDataLoading || nftOwnerOffersLoading || nftOwnerOffersLoading || nftPrimaryOffersLoading,
      ownerOffer,
      loadMoreData,
    }),
    [
      offers,
      nftData,
      loading,
      ownerOffer,
      nftDataLoading,
      nftOwnerOffersLoading,
      nftPrimaryOffersLoading,
      nftPrimaryOffers?.creatorMakeOffers,
      nftPrimaryOffers?.creatorAuctions,
      loadMoreData,
    ],
  );

  return listings;
};
