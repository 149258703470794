import useSWR from 'swr';
import {fetchNonCustodyWallets} from '../../shared/services/non-custody-wallet.service';
import {isMobileDetect} from '../../shared/lib/isMobile';
import {useMemo} from 'react';
import {NonCustodyWalletTypeEnum} from '../../shared/types/non-custody-wallet';

const isMobile = isMobileDetect();

export const useGetNonCustodyWallets = (token?: string) => {
  const {data, mutate} = useSWR(token ? [token, 'fetchNonCustodyWallets'] : null, fetchNonCustodyWallets, {
    errorRetryInterval: 5000,
    errorRetryCount: 5,
  });

  const nonCustodyWallets = useMemo(
    () =>
      (data ?? []).filter(({type}) => {
        // ignore metamask on mobile as it doesn't work on mobile
        return isMobile ? type !== NonCustodyWalletTypeEnum.METAMASK : true;
      }),
    [data],
  );

  return {
    isNonCustodyWalletsLoaded: data !== undefined,
    nonCustodyWallets,
    getNonCustodyWallets: mutate,
  };
};
