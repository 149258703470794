import {Avatar as MUIAvatar} from '@material-ui/core';
import {Typography} from '@cere/rxb-template-ui-kit';
import clsx from 'clsx';
import {Box} from '@material-ui/core';
import {makeStyles, Theme} from '@material-ui/core/styles';
import {ReactComponent as AvatarIcon} from '../../../assets/avatar.svg';
import colors from '../../../styles/colors';
import {link, StyledLink} from '../StyledLink';
import {Numberish} from '../../types/numberish';
import {VerifiedAuthorBadge} from '../VerifiedAuthorIcon';
import {useLocalization} from '../../../shared/hooks/use-locale.hook';

const useStyles = makeStyles<
  Theme,
  {isFeaturedListingsCard?: boolean; isExhibitCard?: boolean; isCuttedCard?: boolean}
>((theme) => ({
  avatarContainer: {
    display: 'inline-flex',
    alignItems: 'center',
  },
  avatar: {
    height: '24px',
    width: '24px',
  },
  avatarForNft: {
    height: '22px',
    width: '22px',
  },
  avatarText: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    color: colors.primaryDark,
    marginLeft: '4px',
  },
  avatarCutText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: ({isExhibitCard, isFeaturedListingsCard, isCuttedCard}) =>
      isExhibitCard ? (isCuttedCard ? '252px' : '272px') : isFeaturedListingsCard ? '250px' : '100px',

    [theme.breakpoints.up('md')]: {
      maxWidth: ({isExhibitCard, isFeaturedListingsCard, isCuttedCard}) =>
        isExhibitCard || isFeaturedListingsCard || isCuttedCard ? '256px' : '256px',
    },

    [theme.breakpoints.up('lg')]: {
      maxWidth: ({isFeaturedListingsCard, isExhibitCard, isCuttedCard}) =>
        isExhibitCard || isFeaturedListingsCard ? (isCuttedCard ? '250px' : '330px') : '230px',
    },
  },
  avatarTextForNft: {
    fontWeight: 500,
    fontSize: '13px',
    lineHeight: '22px',
    color: colors.lightGrey,
    marginLeft: '4px',

    [theme.breakpoints.down('md')]: {
      width: 'fit-content',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
}));

export interface AvatarProps {
  imageUrl?: string;
  name: string | undefined;
  creatorId?: Numberish;
  isCutTitle?: boolean;
  isNftCard?: boolean;
  isExhibitCard?: boolean;
  isFeaturedListingsCard?: boolean;
  isCuttedCard?: boolean;
}

export const AvatarCore = ({
  name,
  imageUrl,
  creatorId,
  isCutTitle,
  isNftCard,
  isExhibitCard,
  isFeaturedListingsCard,
  isCuttedCard,
}: AvatarProps) => {
  const styles = useStyles({isFeaturedListingsCard, isExhibitCard, isCuttedCard});

  return (
    <Box className={clsx('avatar', styles.avatarContainer)}>
      <MUIAvatar className={clsx(styles.avatar, isNftCard && styles.avatarForNft)} src={imageUrl}>
        <AvatarIcon />
      </MUIAvatar>
      <Typography
        className={clsx(styles.avatarText, isNftCard && styles.avatarTextForNft, isCutTitle && styles.avatarCutText)}
      >
        {name}
      </Typography>
      {creatorId && <VerifiedAuthorBadge />}
    </Box>
  );
};

export const Avatar = ({
  name,
  imageUrl,
  creatorId,
  isCutTitle,
  isNftCard,
  isExhibitCard,
  isFeaturedListingsCard,
  isCuttedCard,
}: AvatarProps) => {
  const {locale} = useLocalization();

  return !creatorId ? (
    <AvatarCore
      name={name}
      imageUrl={imageUrl}
      isNftCard={isNftCard}
      isFeaturedListingsCard={isFeaturedListingsCard}
      isCutTitle={isCutTitle}
      isExhibitCard={isExhibitCard}
      isCuttedCard={isCuttedCard}
    />
  ) : (
    <StyledLink
      to={`/${locale}/home/creator/${creatorId}`}
      onClick={(event) => event.stopPropagation()}
      className={link}
    >
      <AvatarCore
        name={name}
        imageUrl={imageUrl}
        isNftCard={isNftCard}
        isExhibitCard={isExhibitCard}
        creatorId={creatorId}
        isFeaturedListingsCard={isFeaturedListingsCard}
        isCutTitle={isCutTitle}
        isCuttedCard={isCuttedCard}
      />
    </StyledLink>
  );
};
