import {memo, useMemo, useCallback, useState} from 'react';
import {Box, Button, Divider, CircularProgress, makeStyles} from '@material-ui/core';

import {NftModalHighlightCard} from '../../../primitives/NftModalHighlightCard';
import {NftModalValueBlock} from '../../../primitives/NftModalValueBlock';
import {WalletBalanceCard} from '../../../primitives/WalletBalanceCard';
import {CopyBox} from '../../../primitives/CopyBox';
import {ErrorMsg} from '../../../primitives/ErrorMsg';
import {StyledLink} from '../../../primitives/StyledLink';
import {QuantitySelect} from '../../../primitives/QuantitySelect';

import {ReactComponent as BuyIcon} from '../assets/buy.svg';
import {PurchaseModalBox} from '../PurchaseModalBox';
import {PurchaseModalBoxInner} from '../PurchaseModalBoxInner';
import {transformTokensToPrice} from '../../../../utils/helpers/price';
import {useLocalization} from '../../../../utils/hooks/use-localization';

const useStyles = makeStyles((theme) => ({
  button: {
    width: '200px',
    textTransform: 'none',
    [theme.breakpoints.up('md')]: {
      height: '44px',
    },
  },
  quantitySelectBox: {
    width: '200px',
  },
}));

type Props = {
  image: string;
  creatorName: string;
  title: string;
  nftLink: string;
  sellerWalletAddress: string;
  walletName: string;
  walletBalance: number;
  nftAddress: string;
  quantityOptions: string[];
  disabledQuantiy?: boolean;
  quantity: number;
  priceUSDC: number;
  onClose: () => void;
  disabledBuy?: boolean;
  onBuyClick: (sellerWalletAddress: string, price: number, qty: number) => void;
  disabledCardPay?: boolean;
  onPayByCard: () => void;
  onFundWalletClick: () => void;
};

export const BuyFromMinterModal = memo(
  ({
    image,
    creatorName,
    title,
    nftLink,
    walletName,
    walletBalance,
    nftAddress,
    quantityOptions,
    disabledQuantiy,
    sellerWalletAddress,
    quantity,
    priceUSDC,
    onClose,
    disabledBuy,
    onBuyClick,
    disabledCardPay,
    onPayByCard,
    onFundWalletClick,
  }: Props) => {
    const {t} = useLocalization();
    const styles = useStyles();
    const [qtyToPurchase, setQtyToPurchase] = useState<number>(quantity);
    const [isProcessing, setProcessing] = useState<boolean>(false);

    const totalAmount = priceUSDC * qtyToPurchase;
    const insufficientFunds = totalAmount > walletBalance;

    const handleBuyClick = useCallback(() => {
      setProcessing(true);
      onBuyClick(sellerWalletAddress, totalAmount, qtyToPurchase);
    }, [onBuyClick, sellerWalletAddress, totalAmount, qtyToPurchase]);

    const handleQuantityChange = useCallback(
      (value: string) => {
        setQtyToPurchase(Number(value));
      },
      [setQtyToPurchase],
    );

    const isHidePrice = useMemo(
      () => qtyToPurchase?.toString() === '1' && quantityOptions.length === 1 && quantityOptions.includes('1'),
      [quantityOptions, qtyToPurchase],
    );

    const renderNftCardAndUserWallet = useCallback(
      () => (
        <>
          <StyledLink to={nftLink}>
            <NftModalHighlightCard image={image} creator={creatorName} title={title} />
          </StyledLink>
          <Box pt="16px">
            <WalletBalanceCard balance={transformTokensToPrice(walletBalance)} walletName={walletName} />
          </Box>
        </>
      ),
      [creatorName, image, nftLink, title, walletBalance, walletName],
    );

    const renderNftPriceInfo = useCallback(
      () => (
        <>
          <Box pb="8px">
            <CopyBox title={t('NFT ID')} copiedText={nftAddress} />
          </Box>
          <Divider />
          <Box pb="8px" pt="16px">
            <NftModalValueBlock title={t('Quantity')}>
              <Box className={styles.quantitySelectBox}>
                <QuantitySelect
                  quantityOptions={quantityOptions}
                  quantity={quantity}
                  setQuantity={handleQuantityChange}
                  disabledQuantiy={disabledQuantiy}
                />
              </Box>
            </NftModalValueBlock>
          </Box>
          <Divider />
          {!isHidePrice && (
            <>
              <Box pb="8px" pt="16px">
                <NftModalValueBlock title={t('Price')} value={`${priceUSDC.toFixed(2)} USDC `} />
              </Box>
              <Divider />
            </>
          )}
          <Box pb="8px" pt="16px">
            <NftModalValueBlock
              title={t('Total amount')}
              value={`${transformTokensToPrice(totalAmount).toFixed(2)} USDC `}
              subValue={`(~${transformTokensToPrice(totalAmount).toFixed(2)} USD)`}
            />
          </Box>
          <Divider />
        </>
      ),
      [
        disabledQuantiy,
        handleQuantityChange,
        isHidePrice,
        nftAddress,
        priceUSDC,
        quantity,
        quantityOptions,
        styles.quantitySelectBox,
        t,
        totalAmount,
      ],
    );

    const renderAdditionalInfoBlock = useCallback(
      () => (insufficientFunds ? <ErrorMsg variant="error" text="Insufficient balance, fund your wallet" /> : <></>),
      [insufficientFunds],
    );

    const renderButtons = useCallback(
      () => (
        <>
          {insufficientFunds && (
            <Button
              variant="contained"
              color="primary"
              className={styles.button}
              disabled={isProcessing}
              onClick={onFundWalletClick}
            >
              {t('Fund wallet')}
            </Button>
          )}
          <Button
            variant="contained"
            color="primary"
            className={styles.button}
            disabled={disabledBuy || insufficientFunds || isProcessing}
            onClick={handleBuyClick}
          >
            {isProcessing ? <CircularProgress /> : t('Buy')}
          </Button>
          <Button
            variant="text"
            color="secondary"
            className={styles.button}
            disabled={disabledCardPay || insufficientFunds || isProcessing}
            onClick={onPayByCard}
          >
            {t('Pay by card')}
          </Button>
        </>
      ),
      [
        disabledBuy,
        disabledCardPay,
        isProcessing,
        handleBuyClick,
        onFundWalletClick,
        onPayByCard,
        insufficientFunds,
        styles,
        t,
      ],
    );

    return (
      <PurchaseModalBox icon={<BuyIcon />} title={t('Buy this NFT')} onClose={onClose}>
        <PurchaseModalBoxInner
          renderNftCardAndUserWallet={renderNftCardAndUserWallet}
          renderNftPriceInfo={renderNftPriceInfo}
          renderAdditionalInfoBlock={renderAdditionalInfoBlock}
          renderButtons={renderButtons}
        />
      </PurchaseModalBox>
    );
  },
);
