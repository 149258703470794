import {BaseAction} from '../../base/BaseAction';
import {PurchasedNft} from '../../../api/purchase-history/types';

const HISTORY = '[HISTORY]';

export class LoadPurchasedNFTsCommand extends BaseAction {
  static type = `${HISTORY} Load purchased NFTs`;
}

export class ReloadPurchasedNFTsCommand extends BaseAction {
  static type = `${HISTORY} Reload purchased NFTs`;
}

export class PurchasedNFTDocument {
  static type = `${HISTORY} Purchased NFT`;
  static create(purchasedNft: PurchasedNft) {
    return {
      type: this.type,
      payload: purchasedNft,
    };
  }
}

export class PurchasedNFTsDocument {
  static type = `${HISTORY} Purchased NFTs`;
  static create(purchasedNts: PurchasedNft[]) {
    return {
      type: this.type,
      payload: purchasedNts,
    };
  }
}

export class ReplacedPurchasedNFTsDocument {
  static type = `${HISTORY} Replaced purchased NFTs`;
  static create(purchasedNts: PurchasedNft[]) {
    return {
      type: this.type,
      payload: purchasedNts,
    };
  }
}

export class PurchaseHistoryLoadingFailedEvent {
  static type = `${HISTORY} Purchased NFTs loading failed`;
  static create(message: string) {
    return {
      type: this.type,
      payload: message,
    };
  }
}
