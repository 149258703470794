export const ROUTES = {
  HOME: '/:locale/home',
  MARKETPLACE: '/:locale/marketplace',
  NFT_PAGE: '/:locale/home/nft/:nftId',
  EXHIBITS: '/:locale/exhibits',
  EXHIBIT: '/:locale/home/exhibit/:exhibitSlug',
  CREATORS: '/:locale/creators',
  CREATOR: '/:locale/home/creator/:artistId',
  WISHLIST: '/:locale/wishlist',
  NOTIFICATIONS: '/:locale/notifications',
  ACTIVITY: '/:locale/activity',
  SIGN_IN: '/:locale/home/auth/signin',
  SIGN_UP: '/:locale/home/auth/signup',
  USER_NFTS: '/:locale/home/user/nfts',
  USER_WALLET: '/:locale/home/user/wallet',
  WALLET_TRANSFER: '/:locale/home/user/wallet/transfer',
};
