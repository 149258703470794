import {useCallback, useState, useContext} from 'react';
import {UserContext} from '../../context/user-context/user-context';
import {SwitchNetworkContext} from '../../context/switch-network-context';
import {buyNftForTokenNonCustody, checkAllowanceBalanceFreeport} from '../services/payment.service';
import {useGetSelectedNonCustodialWallet} from './non-custody-wallet.hook';
import {checkNetwork} from '../services/non-custody-wallet';
import {humanReadableError} from '../lib/error-handler';
import {useTranslation} from 'react-i18next';

interface UserPurchaseProps {
  interstitialCallback?: Function;
}

export const usePurchase = ({interstitialCallback}: UserPurchaseProps) => {
  const handlePurchase = useCallback(
    async (nftId, amount): Promise<void> => {
      return interstitialCallback ? interstitialCallback(nftId, amount) : null;
    },
    [interstitialCallback],
  );

  return {
    handlePurchase,
  };
};

export const usePurchaseWithNonCustody = () => {
  const [isPurchaseLoading, setIsPurchaseLoading] = useState<boolean>(false);
  const [purchaseError, setPurchaseError] = useState<string>('');
  const [purchaseSuccess, setPurchaseSuccess] = useState<boolean>(false);
  const {connectNonCustodialWallet} = useContext(UserContext);
  const {showNetworkDialog} = useContext(SwitchNetworkContext);
  const {getSelectedWallet} = useGetSelectedNonCustodialWallet();
  const {t} = useTranslation();

  const showConnectWalletDialog = useCallback(async () => {
    try {
      await connectNonCustodialWallet();

      return true;
    } catch (error) {
      console.warn(error.message);

      return false;
    }
  }, [connectNonCustodialWallet]);

  const checkAndShowSwitchNetworkDialog = async (): Promise<boolean> => {
    let isValid = true;
    try {
      const wallet = getSelectedWallet();

      await checkNetwork(wallet.type);
    } catch (e) {
      console.log(e.message);

      isValid = false;
    }

    if (!isValid) {
      showNetworkDialog();
    }

    return isValid;
  };

  const handleNonCustodyPurchase = async (
    seller: string,
    nftId: string,
    price: number,
    amount: number,
    collectionAddress?: string,
  ): Promise<void> => {
    setPurchaseSuccess(false);

    const connected = await showConnectWalletDialog();
    if (!connected) {
      return;
    }

    const validNetwork = await checkAndShowSwitchNetworkDialog();
    if (!validNetwork) {
      return;
    }

    const wallet = getSelectedWallet();

    try {
      setIsPurchaseLoading(true);
      await checkAllowanceBalanceFreeport(wallet.type, wallet.publicKey, price, collectionAddress);
      await buyNftForTokenNonCustody(wallet.type, wallet.publicKey, seller, nftId, price, amount, collectionAddress);
    } catch (error) {
      console.error(error);

      setPurchaseError(t('Error occurred. {{errorDetails}}', {errorDetails: humanReadableError(error)}));
      throw error;
    } finally {
      setIsPurchaseLoading(false);
    }

    setPurchaseSuccess(true);
  };

  return {
    checkAndShowSwitchNetworkDialog,
    handleNonCustodyPurchase,
    showConnectWalletDialog,
    isPurchaseLoading,
    purchaseSuccess,
    purchaseError,
  };
};
