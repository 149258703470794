import {gql} from '@apollo/client';
import {EXHIBITION_BASE_PROPS} from '../../shared/queries/exhibitions-fragments';
import {NFT_FRAGMENT} from '../../shared/queries/nfts';

export const FIND_EXHIBITIONS_BY_IDS = gql`
  ${NFT_FRAGMENT}
  ${EXHIBITION_BASE_PROPS}
  query findExhibitionsByIds($ids: [Int]!, $locale: String) {
    exhibitions: cmsExhibitions(where: {id_in: $ids}, locale: $locale) {
      ...ExhibitionBaseProps
      nfts {
        availableFrom
        availableTo
        id
        relType
        cmsNft {
          ...nftFragment
        }
      }
    }
  }
`;
