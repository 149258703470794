import {ReactElement, ReactNode} from 'react';
import WithPurchaseNft from '../WithPurchaseNft/v2';
import {NftInnerProvider} from './nft-inner-provider-v2';
import {NftCardInterface} from '@cere/services-types';

type Props = {
  children: ReactNode;
  nft: NftCardInterface;
};

export function NftProvider2({nft, children}: Props): ReactElement {
  return (
    <WithPurchaseNft>
      {({startNFTBuyOrRequestAuth}) => (
        <NftInnerProvider nft={nft} startNFTBuyOrRequestAuth={startNFTBuyOrRequestAuth}>
          {children}
        </NftInnerProvider>
      )}
    </WithPurchaseNft>
  );
}
