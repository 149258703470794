import {Dispatch} from 'redux';
import {connect} from 'react-redux';
import {generatePath} from 'react-router-dom';
import {CheckUserHasNftEnum} from '@cere/services-types';
import {createSelectNftById} from '../../../redux/modules/nfts/selectors';
import {selectCurrentLocale} from '../../../redux/modules/localization/selectors';
import {ROUTES} from '../../../constants/routes';
import {ConnectedCardView} from './view';
import {selectPurchasedNft} from '../../../redux/modules/purchase-history/selectors';
import {selectPendingTransactionByNftId} from '../../../redux/modules/pending-transactions/selectors';
import {ShowShareNftModalCommand} from '../../../redux/modules/nfts/actions';

const getCardLink = (nftId: string, locale: string) => generatePath(ROUTES.NFT_PAGE, {nftId, locale});
const getCreatorLink = (creatorId: string, locale: string) =>
  generatePath(ROUTES.CREATOR, {artistId: creatorId, locale});

const createMapStateToProps = () => {
  const selectNftById = createSelectNftById();

  return (state: any, {nftId}: {nftId: string}) => {
    const nft = selectNftById(state, nftId);
    const purchasedNft = selectPurchasedNft(state, nftId);
    const locale = selectCurrentLocale(state);
    const pendingTransaction = selectPendingTransactionByNftId(state, nftId);
    return {
      nftId: nft.id,
      hasPendingTransaction: Boolean(pendingTransaction),
      nftPurchaseStatus: purchasedNft ? CheckUserHasNftEnum.USER_HAS_NFT : nft.purchaseStatus,
      nftAuction: nft.auction,
      nftAuctionStatus: nft.auctionStatus,
      nftAvailability: nft.availability,
      nftSellingType: nft.sellingType,
      nftType: nft.nftType,
      nftBalance: nft.balance,
      nftSupply: nft.supply,
      nftPrice: nft.price,
      minterWalletAddress: nft.minter,
      sellerWalletAddress: nft.sellerWalletAddress,
      title: nft.title,
      image: nft.image,
      auctionStatus: nft.auctionStatus,
      priceUsd: nft.priceUsd,
      cardLink: getCardLink(nft.id, locale),
      creatorImage: '',
      creatorName: nft.creatorName,
      creatorLink: getCreatorLink(nft.creatorId, locale),
    };
  };
};

const mapDispatchToProps = (dispatch: Dispatch, {nftId}: {nftId: string}) => ({
  onShareClick: () => dispatch(ShowShareNftModalCommand.create(nftId)),
});

export const ConnectedCard = connect(createMapStateToProps, mapDispatchToProps)(ConnectedCardView);
