import {ReactElement} from 'react';
import {Redirect} from 'react-router-dom';
import {useLocalization} from '../../shared/hooks/use-locale.hook';

export function ServiceCallback(): ReactElement {
  const {locale} = useLocalization();

  if (window.opener) {
    window.close();
    return <></>;
  }
  return <Redirect to={`/${locale}/home`} />;
}
