import {Box, MobileStepper, Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/core';

import colors from '../../../styles/colors';
import {ReactElement, useState} from 'react';
import {useLocalization} from '../../hooks/use-locale.hook';
import {Button} from '@material-ui/core';
import {ReactComponent as ArrowRight} from '../../../assets/icons/icon-arrow.svg';
import {WalletAddressChip} from '../WalletAddressChip';
import {NonCustodyWalletTypeEnum} from '../../types/non-custody-wallet';

const useStyles = makeStyles(() => ({
  root: {
    marginTop: '20px',
    padding: '6px',
    display: 'flex',
    borderRadius: '16px',
    flexDirection: 'column',
    background: colors.lightBackground,
    '& h6': {
      fontWeight: 700,
      fontSize: '16px',
      lineHeight: '24px',
      padding: '16px',
    },
    '& button': {
      fontSize: '16px',
      fontStyle: 'normal',
      textTransform: 'none',
    },
  },
  subtitle: {
    paddingBottom: '1rem',
  },
  stepper: {
    marginTop: 'auto',
    background: colors.lightBackground,
    borderRadius: '16px',
  },
  dot: {
    marginRight: '12px',
  },
  dotActive: {
    backgroundColor: colors.primaryDark,
  },
  nextButton: {
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px',
  },
}));

const listStyle = {
  margin: '0',
};

const STEPPER_PAGES: ReactElement[] = [
  <>
    <Typography variant="subtitle1">Fund your wallet with USDC on Polygon Network</Typography>
    <ul style={listStyle}>
      <li>In order to purchase an NFT you must send USDC funds to your wallet address</li>
      <li>Top up your wallet with your credit card in the wallet app </li>
    </ul>
  </>,
  <>
    <WalletAddressChip walletType={NonCustodyWalletTypeEnum.TORUS} />
    <Typography variant="subtitle1">This is your wallet address</Typography>
    <ul style={listStyle}>
      <li>Use this address to send funds to your wallet</li>
      <li>
        Your wallet address will always stay the same. You can find your address in your profile or in the wallet app
      </li>
    </ul>
  </>,
  <>
    <Typography variant="subtitle1">Your wallet has been created and is ready to be used!</Typography>
    <ul style={listStyle}>
      <li>You can find your wallet in your profile.</li>
      <li>Access your wallet by clicking on the widget in the bottom left corner</li>
    </ul>
  </>,
];

export const FundWalletInfoStepper = () => {
  const {t} = useLocalization();
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => (prevActiveStep < STEPPER_PAGES.length - 1 ? prevActiveStep + 1 : 0));
  };

  return (
    <Box className={classes.root}>
      {STEPPER_PAGES[activeStep]}
      <MobileStepper
        className={classes.stepper}
        variant="dots"
        steps={STEPPER_PAGES.length}
        position="static"
        activeStep={activeStep}
        classes={{
          dot: classes.dot,
          dotActive: classes.dotActive,
        }}
        nextButton={
          <Button className={classes.nextButton} onClick={handleNext}>
            {t('Next')}
            <ArrowRight />
          </Button>
        }
        backButton={<></>}
      />
    </Box>
  );
};
