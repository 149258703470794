import {makeStyles} from '@material-ui/core/styles';
import colors from '../../../styles/colors';

export const useNftCardStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    '--size': '100%',
    height: 'var(--size)',

    [theme.breakpoints.up('md')]: {
      display: 'flex',
      flexDirection: 'row',
      '--size': '328px',
    },
    [theme.breakpoints.up('lg')]: {
      '--size': '508px',
    },
  },
  media: {
    '--size': '343px',
    width: 'calc(100vw-32px)',
    minHeight: 'var(--size)',
    minWidth: 'var(--size)',
    maxHeight: 'var(--size)',
    maxWidth: 'var(--size)',

    '& container': {
      height: 'unset',
    },

    [theme.breakpoints.up('md')]: {
      '--size': '328px',
    },

    [theme.breakpoints.up('lg')]: {
      '--size': '508px',
      height: 'var(--size)',
    },
  },
  mediaNotOwner: {
    '--size': '343px',
    maxHeight: 'var(--size)',

    [theme.breakpoints.up('md')]: {
      '--size': '328px',
    },

    [theme.breakpoints.up('lg')]: {
      '--size': '508px',
    },
  },
  quantity: {
    width: '196px',
    margin: '0 0 0 28px',
    padding: '0',
    '& .MuiOutlinedInput-root': {
      padding: '0 0 0 10px',
      margin: '0',
      border: `1px solid ${colors.lighter}`,
      borderRadius: `12px`,
      backgroundColor: colors.light,
    },
  },
  cardContent: {
    padding: 0,
    flexDirection: 'column',
    display: 'flex',
    width: '100%',
    '--size': '343px',
    maxHeight: 'var(--size)',
    '& .avatar': {
      padding: '12px 0',
    },
    '&:last-child': {
      padding: '0 !important',
    },
    [theme.breakpoints.up('md')]: {
      '--size': '328px',
      height: 'var(--size)',
      minHeight: 'var(--size)',
      justifyContent: 'space-between',
    },
    [theme.breakpoints.up('lg')]: {
      '--size': '508px',
    },
  },
  content: {
    padding: '12px',
    flexDirection: 'column',
    alignItems: 'start',
    display: 'flex',
    width: '100%',

    [theme.breakpoints.up('lg')]: {
      padding: '32px',
    },
  },
  assetSontent: {
    [theme.breakpoints.down('md')]: {
      marginTop: '72px',
    },
  },
  title: {
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '20px',
    lineHeight: '26px',
    color: colors.primaryDark,

    [theme.breakpoints.up('md')]: {
      fontStyle: 'normal',
      fontWeight: 800,
      fontSize: '24px',
      lineHeight: '40px',
    },

    [theme.breakpoints.up('lg')]: {
      fontSize: '30px',
      lineHeight: '40px',
      width: '52%',
    },
  },
  qty: {
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '24px',
    color: colors.lightGrey,
    margin: '0.5rem 0',
    [theme.breakpoints.up('md')]: {
      fontSize: '14px',
      lineHeight: '24px',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '1rem',
      lineHeight: '22px',
    },
  },
  price: {
    fontWeight: 700,
    fontSize: '20px',
    lineHeight: '26px',
    margin: '0.5rem 0',
    [theme.breakpoints.up('md')]: {
      fontWeight: 800,
      fontSize: '2rem',
      lineHeight: '40px',
      margin: 'unset',
    },
  },
  priceBlock: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    [theme.breakpoints.up('md')]: {
      width: '292px',
    },
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'column',
    },
  },
  chip: {
    backgroundColor: theme.palette.info.main,
    color: '#ffffff',
    minWidth: '128px',
    height: '26px',
    marginBottom: '14px',
    '& span': {
      fontWeight: 600,
      fontSize: '14px',
      lineHeight: '22px',
      padding: '0 10px',
    },

    [theme.breakpoints.up('md')]: {
      marginBottom: '28px',
    },
    [theme.breakpoints.up('lg')]: {
      marginBottom: 'unset',
    },
  },
  ctaButtonContainer: {
    width: '100%',
    '& button': {
      width: '100%',
    },
    [theme.breakpoints.up('md')]: {
      width: '292px',
    },
  },
  sellButtonContainer: {
    marginTop: '14px',

    [theme.breakpoints.up('sm')]: {
      marginTop: '50px',
    },

    [theme.breakpoints.up('lg')]: {
      marginTop: '84px',
    },
  },
  buyButtonContainer: {
    marginTop: '0',
    [theme.breakpoints.up('lg')]: {
      marginTop: '24px',
    },
  },
  button: {
    textTransform: 'none',
    fontSize: '16px',
    lineHeight: '22px',
    '&:hover': {
      backgroundColor: theme.palette.common.white,
    },
  },
  controlsContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    marginBottom: '16px',

    '& button': {
      width: '100%',
    },

    [theme.breakpoints.up('md')]: {
      width: '313px',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginTop: '26px',

      '& button': {
        width: '150px',
      },
    },

    [theme.breakpoints.up('lg')]: {
      width: '476px',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginTop: '38px',

      '& button': {
        width: '230px',
      },
    },
  },
  exhibitLink: {
    display: 'flex',
    justifyContent: 'center',
    padding: '0',
    color: colors.primaryDark,
    textDecoration: 'none',

    [theme.breakpoints.up('md')]: {
      justifyContent: 'flex-start !important',
      padding: '0 80px',
    },

    [theme.breakpoints.up('lg')]: {
      justifyContent: 'center !important',
      padding: '19px 0',
    },
  },
  exhibitLinkDescription: {
    fontWeight: 600,
  },
  nftType: {
    margin: '5px auto 8px 0',
    display: 'inline-block',
    backgroundColor: '#7F38F3',
    borderRadius: '100px',
    padding: '1px 7px',
    fontWeight: 700,
    fontSize: '12px',
    lineHeight: '20px',
    color: '#F8F8FA',
    [theme.breakpoints.up('lg')]: {
      fontSize: '14px',
    },
  },
  accent: {
    color: colors.primaryDark,
  },
  priceText: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '21px',
  },
  priceDescriptionText: {
    [theme.breakpoints.up('lg')]: {
      fontWeight: 700,
      fontSize: '20px',
      lineHeight: '26px',
    },
  },
  divider: {
    [theme.breakpoints.down('lg')]: {
      display: 'none',
    },
  },
  descriptionDivider: {
    marginTop: '24px',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  dividerWidth: {
    width: '292px',
    marginBottom: '16px',
  },
  warning: {
    marginBottom: '14px',
    [theme.breakpoints.up('md')]: {
      marginBottom: '6px',
    },
  },
  image: {
    width: '311px',
    height: '311px',
    borderRadius: '12px',
    objectFit: 'cover',
    [theme.breakpoints.up('md')]: {
      width: '640px',
      height: '640px',
    },
  },
  quantityBlock: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 0 28px 0',

    [theme.breakpoints.up('md')]: {
      margin: '8px 0',
    },

    [theme.breakpoints.up('lg')]: {
      margin: '0',
    },
  },
}));
