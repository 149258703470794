import {ReactElement, useContext, useEffect, useMemo, useRef, useState} from 'react';
import {useStyles} from './styles';
import {Box, Theme, useMediaQuery} from '@material-ui/core';
import {Typography} from '@cere/rxb-template-ui-kit';
import {
  aboutTextButtonStyle,
  aboutTextButtonTitleStyle,
  inVisibleAboutTextDesktopStyle,
  inVisibleAboutTextStyle,
  visibleAboutTextStyle,
} from '../styles';
import {Markdown} from '../../../../../shared/components/Markdown';
import {ExhibitContext} from 'context/exhibition/exhibit-context';
import {NftContext} from 'shared/components/Nft/nft-context';
import {useLocalization} from '../../../../../shared/hooks/use-locale.hook';

type Props = {
  isShort?: boolean;
  hideHeader?: boolean;
};

export const AboutTheDrop = ({isShort, hideHeader = false}: Props): ReactElement => {
  const classes = useStyles();
  const {event: eventExhibit} = useContext(ExhibitContext);
  const {eventNft} = useContext(NftContext);
  const [isAboutTextVisible, setIsAboutTextVisible] = useState<boolean>(false);
  const [visibleText, setVisibleText] = useState<boolean>(false);
  const isDesktop = useMediaQuery<Theme>((theme) => `${theme.breakpoints.up('lg')}`);
  const isMobile = useMediaQuery<Theme>((theme) => `${theme.breakpoints.down('sm')}`);
  const refElement = useRef<HTMLDivElement>();
  const {t} = useLocalization();

  useEffect(() => {
    if (!!refElement.current && refElement?.current.scrollHeight > refElement?.current.clientHeight) {
      setVisibleText(true);
    }
  }, []);

  const description = useMemo(
    () => eventNft?.cmsNft.cmsCreator.about || eventExhibit?.cmsCreator.about,
    [eventExhibit, eventNft],
  );

  if (!description) {
    return <></>;
  }

  return (
    <Box width="100%" mt={isMobile ? '40px' : isShort ? '5px' : isDesktop ? '10px' : '24px'}>
      {!hideHeader && (
        <Typography
          variant={isDesktop ? (isShort ? 'h2' : 'body1') : 'h3'}
          className={!isShort && classes.shortAboutText}
        >
          {isShort ? t('About the drop') : t('About')}
        </Typography>
      )}

      <Box pt="10px">
        <Typography
          component="span"
          variant="body2"
          className={
            !isAboutTextVisible
              ? isMobile || isShort
                ? inVisibleAboutTextStyle
                : inVisibleAboutTextDesktopStyle
              : visibleAboutTextStyle
          }
        >
          <Box {...{ref: refElement}}>
            <Markdown>{description || ''}</Markdown>
          </Box>
        </Typography>
      </Box>
      {(visibleText || isAboutTextVisible) && (
        <Box>
          <Typography
            variant="body2"
            onClick={() => setIsAboutTextVisible(!isAboutTextVisible)}
            className={aboutTextButtonStyle}
          >
            <span className={aboutTextButtonTitleStyle}>{!isAboutTextVisible ? t('See more') : t('See less')}</span>
          </Typography>
        </Box>
      )}
    </Box>
  );
};
