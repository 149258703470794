import {memo, useMemo, ReactElement} from 'react';
import {Box, Divider, Button, makeStyles} from '@material-ui/core';
import {generatePath} from 'react-router-dom';
import {HelpOutline, ExitToApp, ArrowBackIos} from '@material-ui/icons';
import {useTranslation} from 'react-i18next';

import {ReactComponent as Activity} from './assets/activity.svg';
import {ReactComponent as Notifications} from './assets/notifications.svg';
import {ReactComponent as MyAccount} from './assets/myAccount.svg';
import {ReactComponent as Wishlist} from './assets/wishlist.svg';
import {ReactComponent as MyCollection} from './assets/myCollection.svg';
import {ReactComponent as SellNFTsIcon} from './assets/sellNFTsIcon.svg';

import {ROUTES} from '../../../constants/routes';
import {FAQ_URL} from '../../../constants/common';
import {useThemeBreakpoints} from '../../../styles/useThemeBreakpoints';
import {AvatarWithName} from '../../primitives/AvatarWithName';
import {CloseButton} from '../../primitives/CloseButton';
import {StyledLink} from '../../primitives/StyledLink';
import {CollapseBox} from '../../primitives/CollapseBox';
import {LinkIconBox} from '../../primitives/LinkIconBox';
import {WalletsBox} from '../WalletsBox';
import {WalletType} from '../Wallet/types';

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.up('md')]: {
      minWidth: '343px',
    },
  },
  root: {
    padding: '30px 20px',
    backgroundColor: theme.palette.background.default,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: '30px',
  },
  closeButton: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  mobileModalHeader: {
    [theme.breakpoints.down('md')]: {
      width: '100%',
      height: '54px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '12px 16px',
      borderBottom: `1px solid ${theme.palette.divider}`,
      backgroundColor: theme.palette.common.white,
    },
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  colapseBoxRoot: {
    backgroundColor: theme.palette.background.default,
  },
  backButton: {
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'none',
  },
  linksBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    paddingLeft: '20px',
  },
  myCollection: {
    display: 'flex',
    gap: '8px',
    alignItems: 'center',

    [theme.breakpoints.down('md')]: {
      paddingLeft: '12px',
      paddingBottom: '20px',
    },
    [theme.breakpoints.up('md')]: {
      padding: '20px 0 20px 20px',
    },
  },
  myCollectionBadge: {
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
  },
  myCollectionBadgeCount: {
    width: '20px',
    height: '20px',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.common.white,
    backgroundColor: theme.palette.info.main,
  },
  exitButton: {
    cursor: 'pointer',
  },
  signUpButton: {
    width: '100%',
    textTransform: 'none',
    color: theme.palette.common.white,
    backgroundColor: theme.palette.info.light,
  },
  signInButton: {
    width: '100%',
    textTransform: 'none',
  },
}));

const useAvatarStyles = makeStyles((theme) => ({
  avatar: {
    width: '41px',
    height: '41px',
    marginRight: '16px',
  },
  name: {
    fontSize: '16px',
    fontWeight: 600,
    color: theme.palette.primary.dark,
  },
}));

type Props = {
  isUserAuth: boolean;
  userName?: string;
  userLogo?: string;
  isHaveUnreadedNotifications?: boolean;
  usersNftsCount?: number;
  onClose: () => void;
  onExitClick: () => void;
  navLinks: {title: string; link: string; icon: ReactElement}[];
  activeWallet: string;
  setActiveWallet: (walletId: string) => void;
  wallets: WalletType[];
  hideBackButton?: boolean;
};

export const UserDropdownBox = memo(
  ({
    isUserAuth,
    userLogo,
    isHaveUnreadedNotifications,
    usersNftsCount,
    userName,
    onClose,
    onExitClick,
    navLinks,
    activeWallet,
    setActiveWallet,
    wallets,
    hideBackButton,
  }: Props) => {
    const {
      i18n: {language},
      t,
    } = useTranslation();
    const styles = useStyles();
    const avatarClasses = useAvatarStyles();
    const {isDesktop} = useThemeBreakpoints();

    const USER_LINKS = useMemo(
      () => [
        {
          title: t('Wishlist'),
          link: generatePath(ROUTES.WISHLIST, {locale: language}),
          icon: <Wishlist />,
          pointer: false,
        },
        {
          title: t('Notifications'),
          link: generatePath(ROUTES.NOTIFICATIONS, {locale: language}),
          icon: <Notifications />,
          pointer: !!isHaveUnreadedNotifications,
        },
        {
          title: t('Activity'),
          link: generatePath(ROUTES.ACTIVITY, {locale: language}),
          icon: <Activity />,
          pointer: false,
        },
      ],
      [isHaveUnreadedNotifications, language, t],
    );
    const MY_COLLECTION_LINK = useMemo(() => generatePath(ROUTES.USER_NFTS, {locale: language}), [language]);
    const SIGN_IN_LINK = useMemo(() => generatePath(ROUTES.SIGN_IN, {locale: language}), [language]);
    const SIGN_UP_LINK = useMemo(() => generatePath(ROUTES.SIGN_UP, {locale: language}), [language]);

    const {pathname} = useMemo(() => new URL(window.location.href), []);

    return (
      <Box className={styles.container}>
        <Box className={styles.mobileModalHeader}>
          <Box>
            {!hideBackButton && (
              <Button className={styles.backButton} variant="text" startIcon={<ArrowBackIos />}>
                {t('Back')}
              </Button>
            )}
          </Box>

          <CloseButton onClick={onClose} />
        </Box>
        <Box className={styles.root}>
          <Box className={styles.header}>
            {userName && <AvatarWithName name={userName} image={userLogo} classes={avatarClasses} />}
            <CloseButton className={styles.closeButton} onClick={onClose} />
          </Box>

          {isUserAuth && !isDesktop && (
            <>
              <Divider />
              <CollapseBox
                classes={{root: styles.colapseBoxRoot}}
                summary={<LinkIconBox icon={<MyAccount />} text={t('My account')} />}
              >
                <Box className={styles.linksBox}>
                  {USER_LINKS.map(({icon, title, link, pointer}) => (
                    <StyledLink key={title} to={link}>
                      <LinkIconBox icon={icon} text={title} isActive={pathname === link} isMarked={pointer} />
                    </StyledLink>
                  ))}
                </Box>
              </CollapseBox>
            </>
          )}

          {isUserAuth && isDesktop && (
            <Box className={styles.linksBox}>
              {USER_LINKS.map(({icon, title, link, pointer}) => (
                <StyledLink key={title} to={link}>
                  <LinkIconBox icon={icon} text={title} isActive={pathname === link} isMarked={pointer} />
                </StyledLink>
              ))}
            </Box>
          )}

          {isUserAuth && (
            <StyledLink to={MY_COLLECTION_LINK}>
              <Box className={styles.myCollection}>
                <LinkIconBox
                  icon={<MyCollection />}
                  text={t('My сollection')}
                  isActive={pathname === MY_COLLECTION_LINK}
                />
                {!!usersNftsCount && (
                  <Box className={styles.myCollectionBadge}>
                    <Box className={styles.myCollectionBadgeCount}>{usersNftsCount}</Box>
                    <SellNFTsIcon />
                  </Box>
                )}
              </Box>
            </StyledLink>
          )}

          {!isDesktop && (
            <>
              <Divider />
              <Box className={styles.linksBox} pt="20px">
                {navLinks.map(({icon, title, link}) => (
                  <StyledLink to={link}>
                    <LinkIconBox icon={icon} text={title} isActive={pathname === link} />
                  </StyledLink>
                ))}
              </Box>
            </>
          )}

          {isUserAuth && (
            <Box pt="20px">
              <Divider />
              <WalletsBox
                wallets={wallets}
                onAddClick={() => {}}
                activeWallet={activeWallet}
                setActiveWallet={setActiveWallet}
              />
            </Box>
          )}

          {!isUserAuth && (
            <Box pt="24px" pb="24px">
              <Divider />
              <Box pt="24px">
                <StyledLink to={SIGN_UP_LINK}>
                  <Button variant="contained" size="large" className={styles.signUpButton}>
                    {t('Sign up')}
                  </Button>
                </StyledLink>
              </Box>
              <Box pt="12px">
                <StyledLink to={SIGN_IN_LINK}>
                  <Button variant="outlined" size="large" className={styles.signInButton}>
                    {t('Sign in')}
                  </Button>
                </StyledLink>
              </Box>
            </Box>
          )}

          <Divider />
          <Box pt="24px">
            <StyledLink to={FAQ_URL}>
              <LinkIconBox icon={<HelpOutline />} text={t('Help & Feedback')} isActive={pathname === FAQ_URL} />
            </StyledLink>
          </Box>

          {isUserAuth && (
            <Box pt="24px">
              <Divider />
              <Box pt="24px">
                <Box className={styles.exitButton} onClick={onExitClick}>
                  <LinkIconBox icon={<ExitToApp />} text={t('Sign out')} />
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    );
  },
);
