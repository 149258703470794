import {Table, TableCell, TableContainer, TableHead, TableRow, Theme, useMediaQuery, useTheme} from '@material-ui/core';
import {useListingsCardStyles} from './styles';
import {NFTListingTableBody} from './NFTListingTableBody';
import {NFTOffer} from '../../hooks/use-nft-listing';
import {NFTListingList} from './NFTListingList ';
import {PendingTransactionTypes, usePendingTransactionsContext} from '../../../context/pending-transaction-context';
import {Condition, ConditionsList, Defaults} from '../Conditions';
import {ExhibitCardInterface, NftCardInterface} from '@cere/services-types';
import {useLocalization} from '../../hooks/use-locale.hook';

export interface NFTListingTableProps {
  event: ExhibitCardInterface;
  offers: NFTOffer[];
  nft: NftCardInterface;
  loadMoreData: () => void;
}

export const NFTListingTable = ({offers, loadMoreData, nft, event}: NFTListingTableProps) => {
  const {t} = useLocalization();
  const styles = useListingsCardStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery<Theme>(() => `${theme.breakpoints.down('sm')}`);

  const {getPendingTransactionForNft} = usePendingTransactionsContext();

  const isPendingTransactionStatus = (offer: NFTOffer) => {
    const pendingTransaction = getPendingTransactionForNft(nft.address);
    return offer.isOwnerOffer && pendingTransaction && pendingTransaction.type === PendingTransactionTypes.SELL_NFT;
  };

  return (
    <ConditionsList>
      <Condition condition={!isMobile}>
        <TableContainer className={styles.tableWrapper}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell className={styles.tableHeadCell}>{t('Price')}</TableCell>
                <TableCell className={styles.tableHeadCell}>{t('USD Price')}</TableCell>
                <TableCell className={styles.tableHeadCell}>{t('Seller')}</TableCell>
                <TableCell className={styles.tableHeadCell}>{t('Qty')}</TableCell>
                <TableCell className={styles.tableHeadCell}>{t('Timestamp')}</TableCell>
                <TableCell className={styles.tableHeadCell}>
                  {offers.some(isPendingTransactionStatus) && t('Status')}
                </TableCell>
              </TableRow>
            </TableHead>
            <NFTListingTableBody event={event} offers={offers} loadMoreData={loadMoreData} nft={nft} />
          </Table>
        </TableContainer>
      </Condition>
      <Defaults>
        <NFTListingList event={event} offers={offers} loadMoreData={loadMoreData} nft={nft} />
      </Defaults>
    </ConditionsList>
  );
};
