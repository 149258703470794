import {FC, ReactElement, useContext} from 'react';
import {Box, makeStyles} from '@material-ui/core';
import {useHistory} from 'react-router-dom';
import {Typography} from '@cere/rxb-template-ui-kit';
import analyticService, {AnalyticEventsEnum} from '../../shared/services/analytic.service';
import {AuthPageState, AuthType} from './types';
import {AppContext} from '../../context/app-context';
import clsx from 'clsx';
import i18n from '../../i18n';
import {Trans} from 'react-i18next';
import {Condition, ConditionsList} from '../../shared/components/Conditions';
import {NftCardMiniature} from '../../shared/components/NftCardMiniature';
import {usePurchaseNftContext} from '../../context/purchase-nft-context';
import {AuctionStatus} from '@cere/services-types';
import {useLocalization} from '../../shared/hooks/use-locale.hook';

const useStyles = makeStyles((theme) => ({
  subtitle: {
    color: '#6E6E79',
  },
  pointer: {
    cursor: 'pointer',
  },
  logo: {
    maxHeight: '30px',
  },
  highlight: {
    '& string': {
      color: theme.palette.primary.main,
      display: 'inline',
      fontWeight: 'normal',
    },
  },
}));

interface SignInHeaderProps {
  page: AuthPageState;
  authType: AuthType;
  nftId?: string | null;
}

export const SignInHeader: FC<SignInHeaderProps> = ({authType, page, nftId}): ReactElement => {
  const {t, locale} = useLocalization();
  const classes = useStyles();
  const {appConfig} = useContext(AppContext);

  const getTextByType = (page: AuthPageState, appName: string, authType?: AuthType) => {
    if (page === AuthPageState.AUTH) {
      return {
        title: authType === 'Sign in' ? i18n.t('Sign in') : i18n.t('Sign up'),
        subtitle: i18n.t('Access the exclusive {{appName}} NFT experience', {appName}),
      };
    }

    if (page === AuthPageState.VERIFY) {
      return {
        title: i18n.t('Verify email'),
        subtitle: (
          <Typography className={classes.highlight}>
            <Trans
              i18nKey="Sign in to {{appName}} with a <strong>registered account</strong> and the verification code sent to your email"
              values={{appName}}
            />
          </Typography>
        ),
      };
    }

    return {title: '', subtitle: ''};
  };

  const {title, subtitle} = getTextByType(page, appConfig.appTitle, authType);
  const {purchase} = usePurchaseNftContext();
  const history = useHistory();

  const onLogoClick = () => {
    analyticService.track(AnalyticEventsEnum.SIGN_IN_LOGO_CLICKED);

    history.push(`/${locale}/home`);
  };

  return (
    <Box mb="18px">
      <Box display="flex" justifyContent="space-between" alignItems="center" mb="8px">
        <Typography variant="h2">{title}</Typography>
        <img
          className={clsx(classes.pointer, classes.logo)}
          onClick={onLogoClick}
          src={appConfig.logoShort?.url || ''}
          alt={t('logo')}
        />
      </Box>

      <ConditionsList>
        <Condition condition={!!nftId}>
          <Typography variant="body2">{t('You’re one step away from your NFT')}</Typography>
          <Box m="20px 0 15px">
            {purchase && (
              <NftCardMiniature
                imgSrc={purchase.image || ''}
                title={purchase.title}
                content={
                  <ConditionsList key={purchase.auctionStatus}>
                    <Condition condition={purchase.auctionStatus === AuctionStatus.ACTIVE}>
                      {
                        <Typography variant="h4">
                          {purchase?.auction?.bids?.length === 0 ? t('Starting bid') : t('Top bid')} $
                          {purchase.priceUsd}
                        </Typography>
                      }
                    </Condition>
                    <Condition condition={purchase.auctionStatus !== AuctionStatus.ACTIVE}>
                      {<Typography variant="h4">${history?.location?.state?.price || purchase.priceUsd}</Typography>}
                    </Condition>
                  </ConditionsList>
                }
                creatorName={purchase.creatorName}
              />
            )}
          </Box>
        </Condition>
        <Condition condition={!nftId}>
          <Typography variant="body2" className={classes.subtitle}>
            {subtitle}
          </Typography>
        </Condition>
      </ConditionsList>
    </Box>
  );
};
