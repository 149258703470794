import {Box, makeStyles} from '@material-ui/core';

import {Skeleton} from './Skeleton';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    padding: '60px 16px 16px',

    [theme.breakpoints.up('md')]: {
      padding: '115px 40px 90px',
    },

    [theme.breakpoints.up('lg')]: {
      display: 'flex',
      alignItems: 'center',
      padding: '170px 85px 125px',
    },
  },
  textBox: {
    margin: '0 auto',

    [theme.breakpoints.up('lg')]: {
      flexBasis: '555px',
    },
  },
  calendar: {
    width: '245px',
    height: '100px',
    margin: '0 auto 24px auto',
    borderRadius: '30px',

    [theme.breakpoints.up('md')]: {
      position: 'absolute',
      top: '48px',
      left: '50%',
      transform: 'translateX(-50%)',
      width: '464px',
      height: '60px',
      margin: 'unset',
    },

    [theme.breakpoints.up('lg')]: {
      top: '48px',
      left: '85px',
      transform: 'unset',
    },
  },
  title: {
    maxWidth: '343px',
    height: '64px',
    borderRadius: '12px',
    margin: '0 auto 12px auto',

    [theme.breakpoints.up('md')]: {
      width: '464px',
      height: '60px',
      margin: '0 auto 32px auto',
    },

    [theme.breakpoints.up('lg')]: {
      width: '95%',
      height: '90px',
      margin: 'unset',
      marginBottom: '12px',
    },
  },
  subTitle: {
    width: '272px',
    height: '42px',
    borderRadius: '12px',
    margin: '0 auto 24px auto',

    [theme.breakpoints.up('lg')]: {
      width: '400px',
      height: '21px',
      borderRadius: '8px',
      margin: 'unset',
      marginBottom: '12px',
    },
  },
  button: {
    width: '343px',
    height: '48px',
    borderRadius: '24px',
    margin: '0 auto 24px auto',

    [theme.breakpoints.up('lg')]: {
      margin: 'unset',
    },
  },

  videoBox: {
    width: '85vw',
    height: '50vw',
    margin: '0 auto',
    borderRadius: '14px',
    maxWidth: '686px',
    maxHeight: '400px',

    [theme.breakpoints.up('lg')]: {
      width: '686px',
      height: '400px',
    },
  },
}));

export const ExhibitBannerSkeleton = () => {
  const styles = useStyles();

  return (
    <Box className={styles.root}>
      <Box className={styles.textBox}>
        <Skeleton variant="rect" className={styles.calendar} />
        <Skeleton variant="rect" className={styles.title} />
        <Skeleton variant="rect" className={styles.subTitle} />
        <Skeleton variant="rect" className={styles.button} />
      </Box>
      <Skeleton variant="rect" className={styles.videoBox} />
    </Box>
  );
};
