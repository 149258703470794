import {useEffect, useMemo, useState} from 'react';
import useSWR from 'swr';
import {NftCardInterface, CheckUserHasNftEnum, AvailabilityStatus, NftType, AuctionStatus} from '@cere/services-types';
import {nftApi} from '../index';
import {HomePageNftsRequestParams} from '../nft';
import {useLocalization} from '../../shared/hooks/use-locale.hook';

type SearchParams = {
  sorting?: string;
  minPrice?: number;
  maxPrice?: number;
  date?: number;
  creatorId?: string;
};

const reorderNfts = (nfts: NftCardInterface[]): NftCardInterface[] => {
  const groupedNfts = nfts.reduce(
    (acc, nft) => {
      if (nft.purchaseStatus === CheckUserHasNftEnum.USER_HAS_NFT) {
        acc.purchased.push(nft);
        return acc;
      }
      if (
        nft.availability === AvailabilityStatus.SOLD_OUT ||
        (nft.auctionStatus !== AuctionStatus.ACTIVE && !nft.balance)
      ) {
        acc.soldOut.push(nft);
        return acc;
      }
      if (
        nft.auctionStatus === AuctionStatus.ACTIVE ||
        (nft.availability === AvailabilityStatus.ONGOING && nft.nftType === NftType.AUCTIONED)
      ) {
        acc.ongoingAuctioned.push(nft);
        return acc;
      }
      if (
        nft.availability === AvailabilityStatus.ONGOING &&
        (nft.nftType === NftType.LIMITED || nft.nftType === NftType.ACCESS)
      ) {
        acc.ongoingAuctioned.push(nft);
        return acc;
      }
      if (nft.availability === AvailabilityStatus.COMING_SOON) {
        acc.comingSoon.push(nft);
        return acc;
      }
      if (nft.availability === AvailabilityStatus.OFFER_ENDED) {
        acc.offerEnded.push(nft);
        return acc;
      }
      acc.rest.push(nft);
      return acc;
    },
    {
      purchased: [],
      ongoingAuctioned: [],
      ongoingLimited: [],
      comingSoon: [],
      soldOut: [],
      offerEnded: [],
      rest: [],
    } as {[key: string]: NftCardInterface[]},
  );
  return [
    ...groupedNfts.purchased,
    ...groupedNfts.ongoingAuctioned,
    ...groupedNfts.ongoingLimited,
    ...groupedNfts.comingSoon,
    ...groupedNfts.soldOut,
    ...groupedNfts.offerEnded,
    ...groupedNfts.rest,
  ];
};

export const useHomePageNfts = (): {
  loading: boolean;
  nfts: NftCardInterface[] | undefined;
  updateSearchParams: (params: SearchParams) => {} | void;
} => {
  const {locale} = useLocalization();
  const [loading, setLoading] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useState<HomePageNftsRequestParams>({locale});
  const {data: nfts} = useSWR(['home-page-nfts', searchParams], async (_, params) => {
    const nfts = await nftApi.getHomePageNfts(params);
    setLoading(false);
    return nfts;
  });

  const updateSearchParams = (params: SearchParams): void => {
    setLoading(true);
    const {sorting, ...restParams} = params;
    const actualSearchParams: HomePageNftsRequestParams = {...restParams, locale};
    if (sorting) {
      const [fieldName, order] = sorting.split(':');
      actualSearchParams.sorting = {fieldName, order} as HomePageNftsRequestParams['sorting'];
    }
    setSearchParams(actualSearchParams);
  };

  useEffect(() => {
    setSearchParams((prevSearchParams) => ({
      ...prevSearchParams,
      locale,
    }));
  }, [locale]);

  const orderedNfts = useMemo(() => {
    if (!nfts) {
      return;
    }
    const filterSearchParams = {...searchParams};
    delete filterSearchParams.locale;
    if (Object.keys(filterSearchParams).length) {
      return nfts;
    }
    return reorderNfts(nfts);
  }, [nfts, searchParams]);

  return {
    loading,
    nfts: orderedNfts,
    updateSearchParams,
  };
};
