import {WalletConnectionManager} from './WalletConnectionManager';
import {WalletConnectionStorage} from './WalletConnectionStorage';
import {SupportedWalletTypes} from './types';
import {IWalletConnector} from './connectors/IWalletConnector';
import {getMetamaskConnector} from './connectors/MetamaskConnector';
import {getWalletConnectConnector} from './connectors/WalletConnectConnector';
import {createTorusWallet} from './TorusWallet';
import {authApi} from '../../api/auth';
import {getTorusConnector} from './connectors/TorusConnector';
import {WalletConnectionService} from './WalletConnectionService';
import {NETWORK_ID} from '../../../config/common';

export const torusWallet = createTorusWallet(authApi);

const connectorsMap = new Map<SupportedWalletTypes, IWalletConnector>();
connectorsMap.set(SupportedWalletTypes.Metamask, getMetamaskConnector());
connectorsMap.set(SupportedWalletTypes.WalletConnect, getWalletConnectConnector());
connectorsMap.set(SupportedWalletTypes.Torus, getTorusConnector(torusWallet));

const walletConnectionStorage = new WalletConnectionStorage();
const connectionManager = new WalletConnectionManager(walletConnectionStorage, NETWORK_ID);

export const walletConnectionService = new WalletConnectionService(
  connectionManager,
  walletConnectionStorage,
  connectorsMap,
);
