import {Box, makeStyles, Theme, Typography} from '@material-ui/core';
import clsx from 'clsx';
import {Avatar} from '@cere/rxb-template-ui-kit';
import {FullCreatorInterface, UploadFile, UploadFileInterface} from '@cere/services-types';

import {VerifiedAuthorBadge} from '../../../shared/components/VerifiedAuthorIcon';
import {useValueByResolution} from '../../../shared/hooks/use-resolution.hook';
import {getMediaUrl} from '../../../shared/lib/media';
import colors from '../../../styles/colors';
import {Markdown} from '../../../shared/components/Markdown';
import {useHover} from '../../../shared/hooks/use-hover.hook copy';
import {link, StyledLink} from '../../../shared/components/StyledLink';
import {GoogleAnalyticsId} from 'analytics-ids';
import {CmsCreator} from '../../../shared/types/graphql';
import {useLocalization} from '../../../shared/hooks/use-locale.hook';

const useHeaderStyles = makeStyles<Theme, {bgImgUrl: string; isHovered: boolean; isHomePageCard?: boolean}>(
  (theme) => ({
    container: {
      borderRadius: '12px',
      width: ({isHomePageCard}) => (isHomePageCard ? '80vw' : 'calc(100vw - 32px)'),
      height: '281px',
      border: `1px solid ${colors.lighter}`,

      [theme.breakpoints.up('md')]: {
        maxWidth: '328px',
        minWidth: '328px',
        height: '276px',
        width: '328px',
      },
      [theme.breakpoints.up('lg')]: {
        width: '400px',
        maxWidth: '400px',
        minWidth: '400px',
        height: '285px',
      },
    },

    header: {
      width: '100%',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundImage: ({bgImgUrl}) => bgImgUrl && `url(${bgImgUrl})`,
      borderRadius: '12px 12px 0 0',
      height: '119px',

      [theme.breakpoints.up('md')]: {
        height: '114px',
      },
      [theme.breakpoints.up('lg')]: {
        height: ({isHovered}) => (isHovered ? '83px' : '139px'),
      },
    },

    artistDescription: {
      overflow: 'hidden',
      display: '-webkit-box',
      WebkitLineClamp: ({isHovered}) => (isHovered ? 5 : 4),
      WebkitBoxOrient: 'vertical',
      textAlign: 'center',
      color: 'rgba(0, 0, 0, 0.7)',

      '& > p': {
        fontWeight: 400,
        fontSize: '12px !important',
        lineHeight: '16px',
      },
      '& > ul': {
        display: 'none',
      },
      height: ({isHovered}) => (isHovered ? '85px' : '65px'),

      [theme.breakpoints.up('lg')]: {
        WebkitLineClamp: ({isHovered}) => (isHovered ? 5 : 3),
        height: ({isHovered}) => (isHovered ? '85px' : '55px'),
      },
    },
    artistName: {
      fontWeight: 600,
      fontSize: '16px',
      lineHeight: '22px',
      paddingTop: '4px',
    },
  }),
);

type Props = {
  artist: CmsCreator | FullCreatorInterface;
  isHomePageCard?: boolean;
};

export const CreatorCard = ({artist, isHomePageCard}: Props) => {
  const {locale} = useLocalization();
  const {value: bgImageByResolution} = useValueByResolution<UploadFile | UploadFileInterface>({
    desktop: artist?.desktopBackgroundImage,
    tablet: artist?.tabletBackgroundImage,
    mobile: artist?.mobileBackgroundImage,
  });

  const [hoverRef, isHovered] = useHover();

  const bgImgUrl = getMediaUrl(bgImageByResolution);
  const classes = useHeaderStyles({bgImgUrl, isHovered, isHomePageCard});

  return (
    <StyledLink
      to={`/${locale}/home/creator/${artist.id}`}
      className={clsx(link, GoogleAnalyticsId.ViewCreatorBtn)}
      onClick={(event) => event.stopPropagation()}
    >
      <div ref={hoverRef} className={classes.container}>
        <Box className={classes.header} />
        <Box display="flex" flexDirection="column" alignItems="center" margin="0 24px">
          <Box mt="-29px">
            <Avatar src={getMediaUrl(artist?.avatar)} size="large" style={{maxWidth: '58px', maxHeight: '58px'}} />
          </Box>
          <Box className={classes.artistName}>
            {artist.name}
            <VerifiedAuthorBadge />
          </Box>
          <Typography className={classes.artistDescription}>
            <Markdown>{artist.about}</Markdown>
          </Typography>
        </Box>
      </div>
    </StyledLink>
  );
};
