import {PaymentStatusEnum} from '@cere/services-types';
import {NftCardInterface, CheckUserHasNftEnum} from '@cere/services-types';

export const isPending = (nft: NftCardInterface): boolean => {
  return nft.purchaseStatus === CheckUserHasNftEnum.USER_PAYMENT_PROCESSING;
};

export const getIsPaymentPrePending = (...statuses: PaymentStatusEnum[]): boolean =>
  statuses.some((status) => status && [PaymentStatusEnum.FIAT_PAYMENT_PENDING].includes(status));

export const getIsPaymentPending = (...statuses: PaymentStatusEnum[]): boolean =>
  statuses.some(
    (status) =>
      status &&
      !getIsPaymentPrePending(status) &&
      !getIsPaymentSuccess(status) &&
      !getIsPaymentFailed(status) &&
      // FIXME please, refer FIAT_PAYMENT_PENDING blocks https://cerenetwork.atlassian.net/browse/DAV-1100
      ![PaymentStatusEnum.INITIAL, PaymentStatusEnum.FIAT_PAYMENT_EXPIRED].includes(status),
  );

export const getIsPaymentSuccess = (...statuses: PaymentStatusEnum[]): boolean =>
  statuses.some((status) =>
    [
      PaymentStatusEnum.TOKEN_TRANSFER_SUCCESS,
      PaymentStatusEnum.PAYMENT_SUCCESS,
      PaymentStatusEnum.FIAT_PAYMENT_SUCCESS,
    ].includes(status),
  );

export const getIsPaymentFailed = (...statuses: PaymentStatusEnum[]): boolean =>
  statuses.some((status) =>
    [
      PaymentStatusEnum.FIAT_REFUND_SUCCESS,
      PaymentStatusEnum.FIAT_PAYMENT_ERROR,
      PaymentStatusEnum.FIAT_REFUND_ERROR,
      PaymentStatusEnum.FIAT_REFUND_PENDING,
      PaymentStatusEnum.UNKNOWN_ERROR,
    ].includes(status),
  );

export const isNewTokenPurchased = (
  prevPaymentStatuses: PaymentStatusEnum[],
  newPaymentStatuses: PaymentStatusEnum[],
): boolean =>
  prevPaymentStatuses.filter((status) => getIsPaymentSuccess(status)).length !==
  newPaymentStatuses.filter((status) => getIsPaymentSuccess(status)).length;
