import {ReactElement, useContext, useMemo} from 'react';
import {Avatar, Typography} from '@cere/rxb-template-ui-kit';
import {Box, makeStyles, Theme, useMediaQuery} from '@material-ui/core';
import {SocialNetworksInfo} from '../../../../../shared/components/SocialNetworksInfo';
import {buildAbsoluteCmsUrl} from '../../../../../shared/queries/utils';
import {getMediaUrl} from '../../../../../shared/lib/media';
import {AboutTheDrop} from '../ExhibitInfo/about-the-drop';
import clsx from 'clsx';
import {GoogleAnalyticsId} from '../../../../../analytics-ids';
import {link, StyledLink} from '../../../../../shared/components/StyledLink';
import {ExhibitContext} from '../../../../../context/exhibition/exhibit-context';
import {VerifiedAuthorBadge} from '../../../../../shared/components/VerifiedAuthorIcon';
import {NftContext} from 'shared/components/Nft/nft-context';
import {useLocalization} from '../../../../../shared/hooks/use-locale.hook';

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: '1.625rem',

    [theme.breakpoints.up('md')]: {
      fontSize: '1.875rem',
    },
  },
  titleCreator: {
    fontSize: '20px',
  },
  avatarBlock: {
    display: 'flex',
    alignItems: 'center',
    gap: '0 .5rem',
  },
  aboutTheDrop: {
    margin: '-40px 0 8px 0',
    [theme.breakpoints.up('md')]: {
      margin: '-16px 0 16px 0',
    },
  },
  artistName: {
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '22px',
  },
}));

export function ExhibitionInfoAndAccess(): ReactElement {
  const {t, locale} = useLocalization();
  const styles = useStyles();
  const {event: eventExhibit} = useContext(ExhibitContext);
  const {eventNft} = useContext(NftContext);
  const isAboveTablet = useMediaQuery<Theme>((theme) => `${theme.breakpoints.up('md')}`);
  const cmsCreator = useMemo(() => eventNft?.cmsNft.cmsCreator || eventExhibit?.cmsCreator, [eventExhibit, eventNft]);

  if (!cmsCreator) {
    return <></>;
  }

  return (
    <div>
      <Typography variant="h3" className={eventNft ? styles.titleCreator : styles.title}>
        {eventNft ? t('Creator') : t('About the drop')}
      </Typography>
      <Box className={styles.aboutTheDrop}>
        <AboutTheDrop hideHeader />
      </Box>
      <StyledLink
        to={`/${locale}/home/creator/${cmsCreator.id}`}
        className={clsx(link, GoogleAnalyticsId.ViewCreatorBtn)}
      >
        <Box display="flex" alignItems="center" className={styles.avatarBlock}>
          <Avatar
            size={!isAboveTablet ? 'small' : 'large'}
            src={buildAbsoluteCmsUrl(getMediaUrl(cmsCreator.avatar))}
            style={{maxWidth: '32px', maxHeight: '32px'}}
          />
          <Typography className={styles.artistName} variant="h4">
            {cmsCreator.name} <VerifiedAuthorBadge />
          </Typography>
        </Box>
      </StyledLink>
      <SocialNetworksInfo artist={cmsCreator} isShort={false} iconSize="15px" noTitle />
    </div>
  );
}
