import {MutableRefObject, ReactElement, useCallback} from 'react';
import {FilterNone} from '@material-ui/icons';
import {IconButton, makeStyles} from '@material-ui/core';
import clsx from 'clsx';
import {useNotifications} from '../../hooks/use-notifications';
import {copyToClipboard} from '../../lib/utils';
import {useLocalization} from '../../hooks/use-locale.hook';

type Props = {
  node: MutableRefObject<HTMLElement | null>;
  className?: string;
};

const useStyles = makeStyles((theme) => ({
  button: {
    color: theme.palette.primary.main,
  },
}));

export function CopyButton({node, className}: Props): ReactElement {
  const classes = useStyles();
  const {t} = useLocalization();
  const {info} = useNotifications();

  const handler = useCallback(
    (event) => {
      copyToClipboard(event, node.current?.innerText ?? '', () => info(t('Copied')));
    },
    [info, node, t],
  );

  return (
    <IconButton onClick={handler} className={clsx(classes.button, className)}>
      <FilterNone />
    </IconButton>
  );
}
