import {Box} from '@material-ui/core';
import {useCardStyles} from './styles';
import {NftCardMedium} from '../AuctionCardMedium';
import {useNftCardStyles} from '../NFTDetailsCard/styles';
import {Numberish} from 'shared/types/numberish';

interface ExclusiveNftCardProps {
  onCardClick?: () => void;
  selectedNftId?: string;
  showMore: boolean;
  nftId: Numberish;
}

export const ExclusiveNftCard = ({onCardClick, selectedNftId, showMore, nftId}: ExclusiveNftCardProps) => {
  const classes = useCardStyles({showMore});

  const styles = useNftCardStyles();

  return (
    <Box className={classes.item} key={nftId} onClick={onCardClick}>
      <NftCardMedium
        styles={styles}
        onCardClick={onCardClick}
        className={selectedNftId === nftId && showMore ? classes.selectedNFT : undefined}
        isDarkTheme={true}
      />
    </Box>
  );
};
