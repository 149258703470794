import {AuctionStatus, AvailabilityStatus, CheckUserHasNftEnum, NftCardInterface, NftType} from '@cere/services-types';
import {PendingTransaction} from '../../../context/pending-transaction-context';
import {sortBy} from 'lodash';

import {CmsNft, CreatorAuction} from '../../types/graphql';
import {PartialCmsNft, PartialExhibitionNft} from './types';
import {Numberish} from '../../types/numberish';

export const makePartialCmsNft = (id: Numberish, nft?: Partial<CmsNft>): PartialCmsNft => ({
  ...nft,
  id,
});

export const makePartialExhibitionNft = (cmsNftId: Numberish, nft?: Partial<CmsNft>): PartialExhibitionNft => ({
  cmsNft: makePartialCmsNft(cmsNftId, nft),
});

export const findActualAuction = (nft?: Pick<CmsNft, 'creatorNft'>): CreatorAuction | undefined =>
  nft?.creatorNft?.creator_auctions.find((auction) => !auction.is_settled);

export const findLastCreatedAuction = (nft?: Pick<CmsNft, 'creatorNft'>): CreatorAuction | undefined => {
  const actualAuction = findActualAuction(nft);
  if (actualAuction) {
    return actualAuction;
  }
  return sortBy(nft?.creatorNft?.creator_auctions, (auction) => new Date(auction.published_at).getTime()).shift();
};

type DisabledNftParams = {
  nft: NftCardInterface;
  userHasEventAccess: boolean;
  hasUserOffer: boolean;
  pendingTransaction: PendingTransaction | null;
};

export const checkIsDisabledNft = ({
  nft,
  userHasEventAccess,
  hasUserOffer,
  pendingTransaction,
}: DisabledNftParams): boolean => {
  const isAuctionedOrLimited = nft.nftType !== NftType.ACCESS;
  const purchased = nft.purchaseStatus === CheckUserHasNftEnum.USER_HAS_NFT;
  const pending = nft.purchaseStatus === CheckUserHasNftEnum.USER_PAYMENT_PROCESSING;
  const isSettled = nft.auction?.isSettled;

  if (nft.purchaseStatus === CheckUserHasNftEnum.USER_HAS_NFT && !pendingTransaction) {
    return hasUserOffer;
  }
  if (nft.availability === AvailabilityStatus.COMING_SOON) {
    return true;
  }
  if (nft.nftType !== NftType.AUCTIONED && nft.availability === AvailabilityStatus.SOLD_OUT) {
    return true;
  }
  if (nft.availability === AvailabilityStatus.OFFER_ENDED) {
    return true;
  }
  if (isSettled) {
    return !nft.balance;
  }
  if (pendingTransaction) {
    return true;
  }
  if (purchased) {
    return true;
  }
  if (pending) {
    return true;
  }
  if (!isSettled && nft.nftType === NftType.AUCTIONED) {
    return nft.auctionStatus !== AuctionStatus.ACTIVE;
  }
  if (isAuctionedOrLimited && nft.isExhibitionAllowFreeAccess) {
    return false;
  }
  if (isAuctionedOrLimited && !nft.isExhibitionAllowFreeAccess && !pendingTransaction && purchased) {
    return !userHasEventAccess;
  }
  return !nft.balance;
};
