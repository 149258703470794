import {Box, makeStyles, Theme, Typography} from '@material-ui/core';
import clsx from 'clsx';
import colors from '../../../../styles/colors';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useLocalization} from '../../../../shared/hooks/use-locale.hook';
import {Markdown} from '../../../../shared/components/Markdown';
import {link, StyledLink} from '../../../../shared/components/StyledLink';
import {GoogleAnalyticsId} from 'analytics-ids';
import {SocialNetworksInfo} from '../../../../shared/components/SocialNetworksInfo';
import {Avatar} from '../../../../shared/components/Avatar';
import {FullCreatorInterface} from '@cere/services-types/dist/types';

export interface Props {
  creator: FullCreatorInterface;
}

const useStyles = makeStyles((theme: Theme) => ({
  block: {
    marginTop: '48px',
    [theme.breakpoints.up('md')]: {
      marginTop: '44px',
    },
    [theme.breakpoints.up('md')]: {
      marginTop: '60px',
    },
  },
  cardTitle: {
    fontWeight: 700,
    fontSize: '20px',
    lineHeight: '26px',
    marginBottom: '12px',
    color: colors.primaryDark,
    [theme.breakpoints.up('md')]: {
      marginTop: '16px',
      fontSize: '24px',
      lineHeight: '29px',
    },
    [theme.breakpoints.up('lg')]: {
      marginTop: '16px',
      fontSize: '30px',
      lineHeight: '36px',
    },
  },
  divider: {
    margin: '24px 0',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  link: {
    color: '#3CBCAC',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',
  },
  collapsibleWrapper: {
    '&:first-child': {
      padding: '0 !important',
    },
    '&:last-child': {
      padding: '0 !important',
    },
  },
  avatar: {
    borderRadius: '100px',
    height: '91px',
    width: '91px',

    [theme.breakpoints.down('md')]: {
      height: '73px',
      width: '73px',
    },
  },
  artistInfo: {
    marginTop: '25px',
    [theme.breakpoints.down('md')]: {
      marginTop: '28px',
    },
  },
  description: {
    display: 'flex',
  },
  info: {
    marginLeft: '32px',
    [theme.breakpoints.down('md')]: {
      marginLeft: '28px',
    },
  },
  artistAbout: {
    [theme.breakpoints.up('md')]: {
      marginTop: '24px',
    },
  },
  moreButton: {
    color: theme.palette.primary.main,
    margin: '.3rem 0',
    width: '12%',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
  },
}));

export const InterstitialInfo = ({creator}: Props) => {
  const {t, locale} = useLocalization();

  const styles = useStyles();
  const [isDescriptionExpanded, setIsDescriptionExpanded] = useState<boolean>(false);
  const [isDescriptionBig, setIsDescriptionBig] = useState<boolean>(true);
  const isMultiline = useMemo(
    () =>
      Boolean(
        creator?.about &&
          (creator.about
            ?.split('\n')
            .map((line) => line.trim())
            .filter(Boolean).length > 1 ||
            creator.about?.length > 40),
      ),
    [creator],
  );
  useEffect(
    () =>
      setIsDescriptionBig(() => {
        if (!refElement.current) {
          return false;
        }
        return (
          refElement.current?.scrollHeight > refElement.current.clientHeight ||
          refElement.current?.scrollWidth > refElement.current.clientWidth ||
          isMultiline
        );
      }),
    [creator, isMultiline],
  );

  const toggleDescription = useCallback(() => {
    setIsDescriptionExpanded(!isDescriptionExpanded);
  }, [isDescriptionExpanded]);

  const refElement = useRef<HTMLDivElement>();

  if (!creator) {
    return null;
  }

  return (
    <Box className={styles.block}>
      {creator && (
        <>
          <Typography className={styles.cardTitle}>{t('About the drop')}</Typography>
          {creator && (
            <Box {...{ref: refElement}} mb="16px">
              <Markdown isShort={!isDescriptionBig || !isDescriptionExpanded}>{creator.about}</Markdown>
            </Box>
          )}
          {isDescriptionBig && (
            <Box onClick={toggleDescription}>
              <Typography className={styles.moreButton}>{isDescriptionExpanded ? t('less') : t('more')}</Typography>
            </Box>
          )}

          <Box className={styles.artistAbout}>
            <StyledLink
              to={`/${locale}/home/creator/${creator?.id}`}
              className={clsx(link, GoogleAnalyticsId.ViewCreatorBtn)}
            >
              <Box display="flex" alignItems="center">
                <Avatar name={creator.name} imageUrl={creator.avatar?.url} creatorId={creator.id} />
              </Box>
            </StyledLink>
            <SocialNetworksInfo artist={creator} isShort={false} iconSize="15px" noTitle />
          </Box>
        </>
      )}
    </Box>
  );
};
