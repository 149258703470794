import React, {useContext, useEffect, useMemo, useCallback, useState} from 'react';
import jwtDecode from 'jwt-decode';

import {MessagesContext} from './messages-context';
import {UserContext} from '../user-context/user-context';
import {connectToSocket} from '../../shared/services/socket.service';

export const MessagesContextProvider: React.FC = ({children}) => {
  const {userData} = useContext(UserContext);
  const [wsConnection, setWsConnection] = useState<SocketIOClient.Socket>();

  useEffect(() => {
    if (userData.token) {
      const {id} = jwtDecode<{id: string}>(userData.token);
      const connection = connectToSocket(id);

      setWsConnection(connection);

      return () => {
        connection.disconnect();
        setWsConnection(undefined);
      };
    }

    return () => {};
  }, [userData.token]);

  const on = useCallback(
    function onMessage<T>(event: string, handler: (payload: T) => void) {
      wsConnection?.on(event, handler);

      return () => wsConnection?.off(event);
    },
    [wsConnection],
  );

  const context = useMemo(() => ({on}), [on]);

  return <MessagesContext.Provider value={context}>{children}</MessagesContext.Provider>;
};
