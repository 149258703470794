import {ConnectWalletOptions, SupportedWalletTypes} from '../types';
import {Web3Provider} from '@ethersproject/providers';
import {enableBiconomy} from '@cere/freeport-sdk';
import {IWalletConnector} from './IWalletConnector';
import {BICONOMY_API_KEY, ENV} from '../../../../config/common';

export class BiconomyConnectorDecorator implements IWalletConnector {
  type: SupportedWalletTypes;

  constructor(private readonly connector: IWalletConnector) {
    this.type = connector.type;
    Object.defineProperty(BiconomyConnectorDecorator, 'name', {value: connector.constructor.name});
  }

  async connectToWallet(options?: ConnectWalletOptions): Promise<Web3Provider> {
    let provider = await this.connector.connectToWallet(options);
    if (BICONOMY_API_KEY) {
      provider = await enableBiconomy(provider, BICONOMY_API_KEY, ['dev', 'stage'].includes(ENV));
    }
    return provider;
  }

  async disconnect(): Promise<void> {
    await this.connector.disconnect?.();
  }
}
