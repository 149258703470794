import {useCallback, useContext, useState} from 'react';
import {Button, makeStyles} from '@material-ui/core';
import CereUsdConverterComponent from './components/cere-usd-converter.component';
import {UserWalletBalanceComponent} from './components/user-wallet-balance.component';
import {GoogleAnalyticsId} from '../../analytics-ids';
import {useAuthorized} from '../../shared/hooks/auth.hook';
import {TOKEN_TITLE} from '../../shared/lib/formatPrice';
import {BigNumber} from 'bignumber.js';
import {getUrlBuyTokenForFiat} from '../../shared/services/payment.service';
import {PageLayout} from '../../shared/components/PageLayout';
import {UserContext} from '../../context/user-context/user-context';
import {useLocalization} from '../../shared/hooks/use-locale.hook';

const useStyles = makeStyles(() => ({
  page: {
    padding: '0 16px 0 16px',
  },
  balance: {},
  title: {
    display: 'block',
    fontSize: '20px',
    fontWeight: 'bold',
    padding: '24px 0 0 0',
    margin: '0 0 4px 0',
  },
  amountTitle: {
    fontSize: '16px',
    fontWeight: 600,
    margin: '4px 0 4px 0',
  },
  converter: {},
  button: {
    width: '100%',
    margin: '22px 0 22px 0',
  },
}));

export const UserDepositFromFiatPage = () => {
  const {t} = useLocalization();
  const classes = useStyles();
  const [isValid, setIsValid] = useState<boolean>(false);
  const [usdAmount, setUsdAmount] = useState<number>(0);
  const {userData} = useContext(UserContext);

  const onValidChanged = (isValidValue: boolean) => {
    setIsValid(isValidValue);
  };

  const onAmountChanged = (usdAmount: string) => {
    const amount = new BigNumber(usdAmount || 0).times(100).toNumber();
    setUsdAmount(amount);
  };

  useAuthorized();

  const handleOnDeposit = useCallback(async (): Promise<void> => {
    const {userEmail} = userData;

    if (!userEmail) {
      return;
    }
    window.location.href = await getUrlBuyTokenForFiat(usdAmount, userEmail);
  }, [usdAmount, userData]);

  return (
    <PageLayout>
      <div className={classes.page}>
        <h1 className={classes.title}>Deposit</h1>
        <div className={classes.balance}>
          <UserWalletBalanceComponent showUsdBalance={true} showTokenBalance={true} />
        </div>
        <p>
          {t(
            'Easily buy {{tokenTitle}} with your card via stripe. Enter {{tokenTitle}} or USD amount to see calculated conversion before making your card deposit.',
            {tokenTitle: TOKEN_TITLE},
          )}
        </p>
        <h2 className={classes.amountTitle}>{t('Buy amount')}</h2>
        <div className={classes.converter}>
          <CereUsdConverterComponent onValidChanged={onValidChanged} onAmountChanged={onAmountChanged} />
        </div>
        <Button
          id={GoogleAnalyticsId.CereBuyBtn}
          color="primary"
          variant="contained"
          className={classes.button}
          disabled={!isValid}
          onClick={handleOnDeposit}
        >
          {t('Buy {{tokenTitle}}', {tokenTitle: TOKEN_TITLE})}
        </Button>
      </div>
    </PageLayout>
  );
};
