import {ReactElement} from 'react';
import {Box, makeStyles} from '@material-ui/core';
import {Typography} from '@cere/rxb-template-ui-kit';
import {VerifiedAuthorBadge} from '../VerifiedAuthorIcon';

const useStyles = makeStyles((theme) => ({
  media: {
    borderRadius: '12px',
    width: '74px',
    height: '74px',
    marginRight: '12px',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundImage: ({imgSrc}: {imgSrc: string}) => `url(${imgSrc})`,
  },
  badge: {
    background: '#E0E0E7',
    borderRadius: '12px',
    padding: '8px',
  },
  creatorName: {
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',
    color: theme.palette.grey[700],
  },
}));

interface NftCardMiniatureProps {
  imgSrc: string;
  title: string;
  content: ReactElement;
  creatorName?: string;
}

export const NftCardMiniature = ({imgSrc, title, content, creatorName}: NftCardMiniatureProps) => {
  const classes = useStyles({imgSrc});

  return (
    <Box display="flex" className={classes.badge}>
      <Box className={classes.media} />

      <Box>
        {creatorName && (
          <Typography className={classes.creatorName}>
            {creatorName}
            <VerifiedAuthorBadge />
          </Typography>
        )}
        <Box my="5px">
          <Typography variant="h5">{title}</Typography>
        </Box>
        {content}
      </Box>
    </Box>
  );
};
