import {MouseEvent, useCallback, useContext, useMemo, useState} from 'react';
import {Box, Card, CardMedia, Grid} from '@material-ui/core';
import clsx from 'clsx';
import dayjs from 'dayjs';
import {Trans} from 'react-i18next';
import {AvailabilityStatus, CheckUserHasNftEnum, NftType} from '@cere/services-types';

import {Typography} from '../../../shared/components/UI';
import {TYPOGRAPHY_VARIANTS} from '../../../shared/components/UI/Typography/types';
import {formatPrice} from '../../../shared/lib/formatPrice';
import {EventTimelineStatus} from '../../../shared/types/event';
import {getTimelineStatus} from '../../Сms/Exhibit/utils';
import {useCardMediaStyles, useCardStyles} from './styles';
import {getShareUrl} from '../../../shared/components/Share/utils/get-share-url';
import Share from '../../../shared/components/Share';
import {ShareIcon} from '../../../shared/components/ShareIcon';
import {NftTicketBadge2} from 'shared/components/NftTicketCard/NftTicketBadge2';
import {NftContext} from '../../../shared/components/Nft/nft-context-v2';
import {LinkWrapper} from './link-wrapper';
import {NftActionButton} from '../../../shared/components/ActionButtons';
import {Avatar} from '../../../shared/components/Avatar';
import {useLocalization} from '../../../shared/hooks/use-locale.hook';

interface Props {
  nftId: string;
  imageUrl?: string;
  nftType: string;
  availableFrom?: string;
  availableTo?: string;
  availability: AvailabilityStatus;
  purchaseStatus?: CheckUserHasNftEnum;
  nftAddress: string;
  nftTitle: string;
  nftDescription: string;
  balance?: number;
  supply?: number;
  usdPrice: number;
  buttonClassName?: string;
  onClick?: (nftId: string) => unknown;
  isFeaturedListingsCard?: boolean;
  isNftCollectiblesCard?: boolean;
  isCutTitle?: boolean;
  withOutShadow?: boolean;
}

export const NftCardV3 = ({
  imageUrl,
  nftId,
  // nftAddress,
  availability,
  availableFrom,
  availableTo,
  nftType,
  nftTitle,
  nftDescription,
  balance,
  supply,
  usdPrice,
  isFeaturedListingsCard = false,
  isNftCollectiblesCard = false,
  isCutTitle = false,
  withOutShadow,
}: Props) => {
  const {t, locale} = useLocalization();
  const {attributes, auction, nft, creator} = useContext(NftContext);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const handleClickShare = useCallback((ev: MouseEvent) => {
    ev.stopPropagation();
    ev.preventDefault();
    setIsModalOpen((prev) => !prev);
  }, []);

  const eventStatus = getTimelineStatus({
    startsAt: availableFrom || '',
    endsAt: availableTo || '',
    isComingSoon: availability === AvailabilityStatus.COMING_SOON,
  });

  const isFullLoaded = Boolean(nftType);

  const isComingSoon = useMemo(() => {
    if (eventStatus === EventTimelineStatus.NOT_STARTED && nftType !== NftType.ACCESS) {
      return true;
    }

    return availability === AvailabilityStatus.COMING_SOON;
  }, [eventStatus, nftType, availability]);

  const isComingSoonCaption = useMemo(() => {
    if (
      (eventStatus === EventTimelineStatus.NOT_STARTED && nftType !== NftType.ACCESS) ||
      availability === AvailabilityStatus.COMING_SOON
    ) {
      return dayjs(availableFrom).format('MM.DD.YYYY');
    }
  }, [availability, nftType, eventStatus, availableFrom]);

  const mediaClasses = useCardMediaStyles({
    comingSoon: isComingSoon,
    image: imageUrl,
    isNftCollectiblesCard: isNftCollectiblesCard,
  });
  const classes = useCardStyles({
    comingSoon: isComingSoon,
    order: 1,
    isFeaturedListingsCard: isFeaturedListingsCard,
    isNftCollectiblesCard: isNftCollectiblesCard,
    withOutShadow: withOutShadow,
  });

  const priceText = useMemo(
    () => (nftType !== NftType.AUCTIONED ? undefined : auction.priceText),
    [auction.priceText, nftType],
  );

  const isAuction = nftType === NftType.AUCTIONED;

  const isSharable = useMemo(() => {
    return !nft.isComingSoon;
  }, [nft]);

  return (
    <Box className={clsx(classes.container, isComingSoon && classes.inactiveLink)}>
      {isFullLoaded && nft && <NftTicketBadge2 nft={nft} isYourNftIconIncluded />}
      <Share
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title={t('Share NFT')}
        description={nftTitle}
        imgSrc={imageUrl}
        url={getShareUrl(`/${locale}/home/nft/${nft.id}`, nftTitle, nftDescription, imageUrl ?? '')}
      />
      <Card className={classes.container}>
        <LinkWrapper link={isComingSoon ? null : `/${locale}/home/nft/${nftId}`}>
          <CardMedia className={mediaClasses.root} image={imageUrl}>
            {isComingSoon && isFullLoaded && (
              <div className={classes.comingSoon}>
                {t('Coming soon')}
                {isComingSoonCaption && <span>{isComingSoonCaption}</span>}
              </div>
            )}
          </CardMedia>
        </LinkWrapper>
        {isSharable && <ShareIcon onClick={handleClickShare} className={classes.share} />}
        <Grid item className={classes.cardService}>
          <Box className={classes.serviceTexts}>
            {!isComingSoon && (
              <Avatar
                name={creator?.name}
                imageUrl={creator?.avatar.url}
                creatorId={creator?.id}
                isNftCard
                isCutTitle={isCutTitle}
                isFeaturedListingsCard={isFeaturedListingsCard}
              />
            )}
            <LinkWrapper link={isComingSoon ? null : `/${locale}/home/nft/${nftId}`}>
              <Typography variant={TYPOGRAPHY_VARIANTS.body1} className={classes.cardTitle}>
                {nftTitle}
              </Typography>
            </LinkWrapper>
            {!isComingSoon && (
              <Box className={classes.cardAmount}>
                {isAuction ? (
                  <Typography variant={TYPOGRAPHY_VARIANTS.body1} className={classes.amountCommon}>
                    {t('1 Auctioned Original')}
                  </Typography>
                ) : (
                  <Typography
                    variant={TYPOGRAPHY_VARIANTS.body1}
                    className={clsx(classes.amountCommon, classes.amountLimited)}
                  >
                    <Trans
                      i18nKey="<strong>{{leftAmount}}</strong>/{{totalAmount}} NFTs left"
                      values={{leftAmount: balance, totalAmount: supply || 0}}
                      count={balance}
                    />
                  </Typography>
                )}
              </Box>
            )}
          </Box>
          <Box className={classes.serviceInfo}>
            {!isComingSoon && (
              <Typography variant={TYPOGRAPHY_VARIANTS.button1} className={classes.serviceInfoText}>
                {priceText && !attributes.availableOnlyOnMarketplace && (
                  <Typography variant={TYPOGRAPHY_VARIANTS.button1} className={classes.serviceInfoSubText}>
                    {priceText}
                  </Typography>
                )}
                {formatPrice(usdPrice)}
              </Typography>
            )}
            <Box className={classes.buttonContainer}>
              <NftActionButton />
            </Box>
          </Box>
        </Grid>
      </Card>
    </Box>
  );
};
