import {useContext, useEffect} from 'react';
import {Dialog, Typography} from '@cere/rxb-template-ui-kit';
import {useConnectNonCustodyWallets} from '../../hooks/non-custody-wallet.hook';
import {UserContext} from '../../../context/user-context/user-context';
import {ConnectWalletContent} from '../ConnectWalletContent';
import {makeStyles} from '@material-ui/core';
import analyticService, {AnalyticEventsEnum} from '../../services/analytic.service';
import {NonCustodyWalletTypeEnum} from '../../types/non-custody-wallet';
import {useLocalization} from '../../hooks/use-locale.hook';

const useStyles = makeStyles(() => ({
  headerStyles: {
    height: '62px',
  },
}));

export const ConnectWalletDialog = () => {
  const {t} = useLocalization();
  const {
    connectTorus,
    connectMetamask,
    connectWalletConnect,
    error,
    isTorusLoading,
    isWalletConnectLoading,
    isMetamaskLoading,
    success,
    setIsTorusLoading,
    setIsWalletConnectLoading,
    setIsMetamaskLoading,
  } = useConnectNonCustodyWallets();
  const {isConnectWalletVisible, setIsConnectWalletVisible} = useContext(UserContext);
  const classes = useStyles();

  const onCloseHandler = () => {
    setIsConnectWalletVisible(false);
    setIsTorusLoading(false);
    setIsWalletConnectLoading(false);
    setIsMetamaskLoading(false);
  };

  useEffect(() => {
    if (success) {
      setIsConnectWalletVisible(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success]);

  const handleConnectMetamask = async () => {
    analyticService.track(AnalyticEventsEnum.METAMASK_WALLET_CONNECT_CLICKED, {
      type: NonCustodyWalletTypeEnum.METAMASK,
    });

    await connectMetamask();
  };

  const handleConnectWalletConnect = async () => {
    analyticService.track(AnalyticEventsEnum.WALLETCONNECT_WALLET_CONNECT_CLICKED, {
      type: NonCustodyWalletTypeEnum.WALLET_CONNECT,
    });

    await connectWalletConnect(setIsWalletConnectLoading);
  };

  const handleTorusConnect = async () => {
    analyticService.track(AnalyticEventsEnum.TORUS_WALLET_CONNECT_CLICKED, {
      type: NonCustodyWalletTypeEnum.TORUS,
    });

    await connectTorus();
  };

  return (
    <Dialog
      open={isConnectWalletVisible}
      maxWidth="sm"
      onClose={onCloseHandler}
      headerContent={<Typography variant="h3">{t('Connect wallet')}</Typography>}
      headerClassName={classes.headerStyles}
    >
      <ConnectWalletContent
        onConnectTorus={handleTorusConnect}
        onConnectMetamask={handleConnectMetamask}
        onConnectWalletConnect={handleConnectWalletConnect}
        isTorusLoading={isTorusLoading}
        isWalletConnectLoading={isWalletConnectLoading}
        isMetamaskLoading={isMetamaskLoading}
        error={error}
      />
    </Dialog>
  );
};
