import {makeStyles} from '@material-ui/core';
import colors from '../../../../../styles/colors';

export const useStyles = makeStyles({
  about: {
    color: colors.lightGrey,
    fontWeight: 600,
  },
  button: {
    paddingLeft: '0',
  },
  aboutDropTextDesktop: {
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',
    color: colors.lightGrey,
    padding: '2px 0 0 0',
  },
  artistNameDesktop: {
    fontWeight: 600,
    fontSize: '24px',
    lineHeight: '32px',
  },
  shortAboutText: {
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '22px',
  },
});
