import WalletConnect from '@walletconnect/client';
import QRCodeModal from '@walletconnect/qrcode-modal';
import {IInternalEvent} from '@walletconnect/types';
import {providers} from 'ethers';
import WalletConnectProvider from '@walletconnect/web3-provider';
import {enableBiconomy} from '@cere/freeport-sdk';
import i18n from '../../i18n';
import {BICONOMY_API_KEY, ENV, HTTP_PROVIDER_URL, INFURA_ID, NETWORK_ID} from '../../config/common';

const bridge = 'https://bridge.walletconnect.org';

let connector: WalletConnect | null = null;

const provider = new WalletConnectProvider({
  infuraId: INFURA_ID,
  rpc: {
    [NETWORK_ID]: HTTP_PROVIDER_URL || '',
  },
  qrcode: true,
});

export const getSigner = async (): Promise<providers.JsonRpcSigner> => {
  await provider.enable();
  if (BICONOMY_API_KEY) {
    const biconomyProvider = await enableBiconomy(provider, BICONOMY_API_KEY, ENV === 'dev');
    return biconomyProvider.getSigner();
  }
  return provider.getSigner();
};

export const connectAndSignIn = async (setIsWalletConnectLoading: Function): Promise<string> => {
  connector = new WalletConnect({bridge, qrcodeModal: QRCodeModal});
  return new Promise(async (res) => {
    if (connector!.connected) {
      await disconnect();
    }

    await connector!.createSession();

    connector!.on('connect', (error, payload: IInternalEvent) => {
      if (error) {
        throw error;
      }

      const {accounts} = payload.params[0];
      const address = accounts[0];

      res(address);
    });
    connector!.on('disconnect', (error) => {
      if (error) {
        console.error(error);
      }

      console.log('[Wallet Connect] Disconnected');
    });
    connector!.on('call_request', (error) => {
      if (error) {
        console.error(error);
      }

      console.log('[Wallet Connect] Call requested');
    });
    setIsWalletConnectLoading(false);
  });
};

export const disconnect = async () => {
  try {
    await connector!.killSession();
    return true;
  } catch (e) {
    console.log('[Wallet Connect] Error during disconnect');
    return false;
  }
};

export const switchNetwork = async () => {
  throw new Error(i18n.t('Change network manually on your application!'));
};
