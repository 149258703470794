import {Box, makeStyles, Theme} from '@material-ui/core';
import {useContext, useMemo, useState} from 'react';
import clsx from 'clsx';

import {UserNftCollectionComponent} from './components/user-nft-collection.component';
import {UserWalletBalanceComponent} from './components/user-wallet-balance.component';
import {enableMarketplaceView} from '../../config/common';
import {useAuthorized} from '../../shared/hooks/auth.hook';
import {PageLayout} from '../../shared/components/PageLayout';
import {UserWalletDetails} from './components/user-wallet-details.component';
import {NonCustodyWalletTypeEnum, isNonCustodyWalletType} from '../../shared/types/non-custody-wallet';
import {AppWallet, isSupportedWallet, SupportedWallet} from '../../shared/types/supported-wallet';
import {SellNftContextProvider} from '../../context/sell-nft-context';
import {useSharedStyles} from './shared-styles';
import {useHistory, useLocation} from 'react-router-dom';
import {DisconnectButton} from './components/DisconnectWallet';
import {ConfirmModal} from '../../shared/components/ConfirmModal';
import {useConnectNonCustodyWallets} from '../../shared/hooks/non-custody-wallet.hook';
import {useWalletNfts} from './use-wallet-nfts';
import {PageHeader} from './components/page-header.component';
import {WalletNftsSellButton} from './components/wallet-nfts-sell-button.component';
import {AppContext} from '../../context/app-context';
import {useLocalization} from '../../shared/hooks/use-locale.hook';
import {SupportedWalletTypes} from 'app-v2/models/wallet/types';

const useStyles = makeStyles<Theme, {isTorusWallet?: boolean}>((theme) => ({
  balance: {
    margin: '16px 0',
    display: 'flex',
    flexDirection: 'column',
  },
  column: {
    flexDirection: 'column',
    display: 'flex',
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row',
    },
  },
  walletBalance: {
    [theme.breakpoints.up('md')]: {
      width: ({isTorusWallet}) => (isTorusWallet ? '100%' : '343px'),
    },
    [theme.breakpoints.up('lg')]: {
      width: ({isTorusWallet}) => (isTorusWallet ? '411px' : '343px'),
    },
  },
  disconnectWallet: {
    width: '343px',
    marginRight: 'auto',
    display: 'flex',
    justifyContent: 'center',
    mt: '16px',
  },
  walletDetails: {
    [theme.breakpoints.up('lg')]: {
      marginLeft: '10px',
      marginTop: '-10px',
      height: '164px',
    },
  },

  title: {
    paddingTop: '0',
    fontSize: '30px',

    [theme.breakpoints.down('lg')]: {
      fontSize: '24px',
    },

    [theme.breakpoints.down('md')]: {
      fontSize: '20px',
    },
  },

  page: {
    marginTop: '80px',

    [theme.breakpoints.down('lg')]: {
      marginTop: '40px',
    },

    [theme.breakpoints.down('md')]: {
      marginTop: '16px',
    },
  },
  myNftsHeader: {
    marginTop: '44px',
    '& h1': {
      marginBottom: '0',
    },

    '& button': {
      minWidth: '130px',
      fontWeight: 600,
      fontSize: '14px',
      lineHeight: '20px',
    },

    [theme.breakpoints.up('md')]: {
      marginTop: '40px',
      '& button': {
        minWidth: '160px',
        maxHeight: '40px',
      },
    },
  },
  collection: {
    marginTop: '-8px',
    [theme.breakpoints.down('lg')]: {
      marginTop: '-16px',
    },
    [theme.breakpoints.down('md')]: {
      marginTop: '-12px',
    },
  },
}));

type Props = {
  showWalletAddress?: boolean;
};

export const UserWalletPage = ({showWalletAddress = false}: Props) => {
  const {t, locale} = useLocalization();
  const [open, setOpen] = useState(false);
  const {appConfig} = useContext(AppContext);

  const {search} = useLocation();
  const selectedWallet = useMemo(() => {
    const searchWallet = new URLSearchParams(search).get('type');

    return isSupportedWallet(searchWallet) ? searchWallet : NonCustodyWalletTypeEnum.TORUS;
  }, [search]);

  const sharedStyles = useSharedStyles();

  const {replace} = useHistory();
  const {disconnectNonCustodyWallet} = useConnectNonCustodyWallets();
  const {nfts, isLoading} = useWalletNfts(selectedWallet);

  useAuthorized();

  const getWalletNameByType = (type: SupportedWallet) => {
    switch (type) {
      case NonCustodyWalletTypeEnum.METAMASK:
        return t('Metamask');
      case NonCustodyWalletTypeEnum.WALLET_CONNECT:
        return t('Wallet Connect');
      case NonCustodyWalletTypeEnum.TORUS:
        return appConfig.cereWalletTitle || t('Cere wallet');
      default:
        return appConfig.appTitle !== 'DaVinci '
          ? t(`{{appTitle}} wallet`, {appTitle: appConfig.appTitle})
          : t('Cere wallet');
    }
  };

  const walletName = getWalletNameByType(selectedWallet || 'TORUS');
  const isTorusWallet = selectedWallet === NonCustodyWalletTypeEnum.TORUS || selectedWallet === AppWallet.DAVINCI;
  const allowDisconnectWallet = selectedWallet !== AppWallet.DAVINCI && !isTorusWallet;

  const classes = useStyles({isTorusWallet});

  const handleDisconnectWalletConfirmation = () => setOpen(true);

  const handleDisconnectWallet = async () => {
    if (!isNonCustodyWalletType(selectedWallet)) {
      return;
    }

    try {
      const isDisconnected = await disconnectNonCustodyWallet(selectedWallet);

      if (isDisconnected) {
        replace(`/${locale}/home`);
      }
    } catch {}
  };

  const onCancel = () => setOpen(false);
  const onConfirm = () => {
    onCancel();
    handleDisconnectWallet();
  };

  return (
    <SellNftContextProvider>
      <PageLayout>
        <Box className={clsx(sharedStyles.page, classes.page)}>
          <Box maxWidth="340px" display="flex" justifyContent="space-between" alignItems="center">
            <h1 className={clsx(sharedStyles.title, classes.title)}>
              {walletName !== SupportedWalletTypes.Metamask && t('My ')} {walletName}
            </h1>
          </Box>
          <div className={classes.balance}>
            <div className={classes.column}>
              <div className={classes.walletBalance}>
                <UserWalletBalanceComponent
                  selectedWallet={selectedWallet}
                  isTorusWallet={isTorusWallet}
                  walletName={walletName}
                />
              </div>
              {(showWalletAddress || isTorusWallet) && (
                <div className={clsx(classes.walletBalance, classes.walletDetails)}>
                  <UserWalletDetails wallet={selectedWallet} isWalletPage />
                </div>
              )}
            </div>
            {allowDisconnectWallet && (
              <Box className={classes.disconnectWallet}>
                <DisconnectButton onClick={handleDisconnectWalletConfirmation} />
              </Box>
            )}
          </div>
          <div className={classes.myNftsHeader}>
            <PageHeader
              title={t('My NFTs')}
              rightElement={enableMarketplaceView() && <WalletNftsSellButton nfts={nfts} />}
            />
            <div className={classes.collection}>
              <UserNftCollectionComponent nfts={nfts} isLoading={isLoading} />
            </div>
          </div>
        </Box>

        <ConfirmModal
          open={open}
          title={t('Disconnect wallet')}
          text={t('Are you sure you want to disconnect this wallet? External wallet is required for payment')}
          confirmText={t('Disconnect')}
          onCancel={onCancel}
          onConfirm={onConfirm}
        />
      </PageLayout>
    </SellNftContextProvider>
  );
};
