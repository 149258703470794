import {memo, ReactElement, useMemo} from 'react';
import {useHistory, generatePath} from 'react-router-dom';
import {Box, IconButton, makeStyles} from '@material-ui/core';
import {ArrowBack} from '@material-ui/icons';
import clsx from 'clsx';
import {useTranslation} from 'react-i18next';

import {ReactComponent as Creators} from './accets/creators.svg';
import {ReactComponent as Exhibits} from './accets/exhibits.svg';
import {ReactComponent as Home} from './accets/home.svg';
import {ReactComponent as Marketplace} from './accets/marketplace.svg';

import {ReactComponent as Logo} from '../../../assets/svg/logo.svg';
import {useThemeBreakpoints} from '../../../styles/useThemeBreakpoints';
import {ROUTES} from '../../../constants/routes';
import {NavLink} from '../../primitives/NavLink';
import {PageContainer} from '../PageContainer';
import type {HeaderNavLinkType} from '../HeaderControl/types';

const useStyles = makeStyles((theme) => ({
  rootBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center ',
    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.12), 0px 0px 0px 1px rgba(0, 0, 0, 0.05)',
  },
  rootFixed: {
    position: 'fixed',
    zIndex: 999,
    top: 0,
    left: 0,
    right: 0,
  },
  rootBackground: {
    backgroundColor: theme.palette.background.default,
  },
  rootSize: {
    height: '54px',
    [theme.breakpoints.up('lg')]: {
      height: '76px',
    },
  },
  container: {
    position: 'relative',
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  logo: {
    [theme.breakpoints.down('lg')]: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '115px',
    },
    [theme.breakpoints.up('lg')]: {
      width: '154px',
      marginRight: '50px',
    },
  },

  headerControls: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    position: 'absolute',
    right: '20px',
    height: '100%',
    [theme.breakpoints.up('md')]: {
      right: '40px',
    },
    [theme.breakpoints.up('lg')]: {
      position: 'static',
      flexGrow: 2,
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  },
  leftHeaderControls: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  rightHeaderControls: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  },

  backButtonBox: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
    [theme.breakpoints.up('md')]: {
      position: 'absolute',
      left: '20px',
      top: '50%',
      transform: 'translateY(-50%)',
    },
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  desctopNav: {
    display: 'flex',
    height: '100%',
  },
}));

type Props = {
  hideBackButton?: boolean;
  renderHeaderSearch?: () => ReactElement;
  renderHeaderNotifications?: () => ReactElement;
  renderHeaderUserControl?: (navLinks: HeaderNavLinkType[]) => ReactElement;
};

export const PageHeader = memo(
  ({hideBackButton, renderHeaderSearch, renderHeaderNotifications, renderHeaderUserControl}: Props) => {
    const {
      t,
      i18n: {language},
    } = useTranslation();
    const styles = useStyles();
    const history = useHistory();
    const {isDesktop} = useThemeBreakpoints();

    const NAV_LINKS = useMemo(
      () => [
        {title: t('Home'), link: generatePath(ROUTES.HOME, {locale: language}), icon: <Home />},
        {
          title: t('Marketplace'),
          link: generatePath(ROUTES.MARKETPLACE, {locale: language}),
          icon: <Marketplace />,
        },
        {title: t('Exhibits'), link: generatePath(ROUTES.EXHIBITS, {locale: language}), icon: <Exhibits />},
        {title: t('Creators'), link: generatePath(ROUTES.CREATORS, {locale: language}), icon: <Creators />},
      ],
      [language, t],
    );

    return (
      <Box className={styles.rootSize}>
        <Box className={clsx(styles.rootFixed, styles.rootSize, styles.rootBackground)} />

        <Box className={clsx(styles.rootFixed, styles.rootSize, styles.rootBox)}>
          <PageContainer className={styles.container}>
            <>
              {!hideBackButton && (
                <Box className={styles.backButtonBox}>
                  <IconButton size="small" color="primary" onClick={history.goBack}>
                    <ArrowBack />
                  </IconButton>
                </Box>
              )}
              <Logo className={styles.logo} />

              <Box className={styles.headerControls}>
                <Box className={styles.leftHeaderControls}>{renderHeaderSearch?.()}</Box>

                <Box className={styles.rightHeaderControls}>
                  {isDesktop && (
                    <Box className={styles.desctopNav}>
                      {NAV_LINKS.map(({title, link}) => (
                        <NavLink key={title} to={link}>
                          {title}
                        </NavLink>
                      ))}
                    </Box>
                  )}
                  {renderHeaderNotifications?.()}
                  {renderHeaderUserControl?.(NAV_LINKS)}
                </Box>
              </Box>
            </>
          </PageContainer>
        </Box>
      </Box>
    );
  },
);
