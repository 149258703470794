import useSWR from 'swr';
import {realLifeEventsApi} from '../index';
import {useState} from 'react';
import {useLocalization} from 'shared/hooks/use-locale.hook';

export const useRealLifeEvents = () => {
  const {locale} = useLocalization();
  const [loading, setLoading] = useState<boolean>(false);
  const {data: vipEvents} = useSWR(`real-life-evemts/for-user`, async () => {
    const events = await realLifeEventsApi.getRealLifeEvents(locale);
    setLoading(false);
    return events;
  });

  return {
    loading,
    vipEvents,
  };
};
