import {Avatar, Box, makeStyles, Menu, Theme, useMediaQuery} from '@material-ui/core';
import {useContext, useEffect, useRef, useState} from 'react';

import UserProfileMenuListComponent from './user-profile-menu-list.component';
import {ReactComponent as AvatarIcon} from '../../../assets/avatar.svg';
import {ReactComponent as MenuArrow} from '../../../assets/menuArrow.svg';
import {ReactComponent as MenuIcon} from '../../../assets/menu.svg';
import {UserContext} from '../../../context/user-context/user-context';

const useStyles = makeStyles<Theme, {width: number}>((theme) => ({
  avatar: {
    cursor: 'pointer',
    height: '36px',
    width: '36px',
    marginRight: '4px',
    display: 'inline',
    '& svg': {
      cursor: 'pointer',
      height: '38px',
      width: '38px',
      padding: '0',
      backgroundColor: theme.palette.common.white,
    },
  },
  landscapePaper: {
    position: 'relative',
    width: '100vw',
    minWidth: '100vw',
    maxWidth: '100vw',
    minHeight: 'calc(100vh + 24px)',
    marginTop: '-24px',
    marginLeft: '-16px',
    paddingBottom: '24px',
    backgroundColor: theme.palette.background.paper,
  },
  paperDescktop: {
    width: '343px',
    minWidth: '343px',
    maxWidth: '343px',
    marginTop: '36px',
    borderRadius: '12px',
    backgroundColor: theme.palette.background.paper,
  },
  paper: {
    position: 'relative',
    width: '100vw',
    minWidth: '100vw',
    maxWidth: '100vw',
    minHeight: 'calc(100vh + 24px)',
    marginTop: '-24px',
    marginLeft: '-16px',
    paddingBottom: '24px',
    backgroundColor: theme.palette.background.paper,

    [theme.breakpoints.up('md')]: {
      position: 'absolute',
      width: '343px',
      minWidth: '343px',
      maxWidth: '343px',
      minHeight: 'unset',
      marginTop: '-6px',
      marginLeft: 'calc(50vw - 12px)',
      borderRadius: '12px',
      backgroundColor: theme.palette.background.paper,
    },
  },
  avatarIcon: {
    [theme.breakpoints.down('lg')]: {
      display: 'none',
    },
  },
  menuIcon: {
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
}));

const UserProfileMenuComponent = ({onClose}: {onClose?: Function}) => {
  const width = window.screen.width;
  const height = window.screen.height;

  const [isLandscape, setIsLandscape] = useState(false);

  useEffect(() => {
    if (height < 430 && height < width) {
      setIsLandscape(true);
    } else {
      setIsLandscape(false);
    }
  }, [width, height]);

  const classes = useStyles({width});
  const profileMenuRef = useRef(null);
  const {isProfileMenuOpen, setIsProfileMenuOpen} = useContext(UserContext);
  const isDesktop = useMediaQuery<Theme>((theme) => `${theme.breakpoints.up('lg')}`);

  const handleClick = () => {
    setIsProfileMenuOpen(true);
  };

  const handleClose = () => {
    setIsProfileMenuOpen(false);
    onClose && onClose();
  };

  useEffect(() => {
    if (profileMenuRef.current && isProfileMenuOpen) {
      (profileMenuRef.current as any).scrollIntoView({behavior: 'auto', block: 'nearest'});
    }
  }, [isProfileMenuOpen]);

  return (
    <>
      {/*@ts-ignore*/}
      <Box ref={profileMenuRef as any} className={classes.avatarIcon} onClick={handleClick}>
        <Avatar className={classes.avatar} sizes={'medium'}>
          <AvatarIcon />
        </Avatar>
        <MenuArrow />
      </Box>
      <MenuIcon className={classes.menuIcon} onClick={handleClick} />

      <Menu
        id="simple-menu"
        anchorEl={profileMenuRef.current}
        keepMounted
        open={Boolean(profileMenuRef) && isProfileMenuOpen}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 0,
          horizontal: isDesktop ? -280 : 0,
        }}
        getContentAnchorEl={null}
        classes={{
          paper: !isLandscape ? (!isDesktop ? classes.paper : classes.paperDescktop) : classes.landscapePaper,
        }}
        disableScrollLock={true}
      >
        <UserProfileMenuListComponent onClose={handleClose} />
      </Menu>
    </>
  );
};

export default UserProfileMenuComponent;
