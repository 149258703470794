import {useCallback, useContext} from 'react';
import {Freeport} from '@cere/freeport-sdk';

import {UserContext} from './user-context/user-context';
import {getFreeport, getFreeportNonCustody} from '../shared/lib/get-freeport';
import {isAppWallet, SupportedWallet} from '../shared/types/supported-wallet';

export const useGetFreeport = (): ((walletType: SupportedWallet) => Promise<Freeport>) => {
  const {userData} = useContext(UserContext);
  return useCallback(
    async (walletType: SupportedWallet): Promise<Freeport> => {
      if (isAppWallet(walletType)) {
        return userData.token ? getFreeport(userData.token) : Promise.reject("Can't get Freeport for a guest user");
      }
      return getFreeportNonCustody(walletType);
    },
    [userData.token],
  );
};
