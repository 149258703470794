import {ethers} from 'ethers';
import {EmbedWallet, WalletEvent, WalletInitOptions} from '@cere/embed-wallet';
import jwtDecode, {JwtPayload} from 'jwt-decode';
import {IAuthApi} from '../../api/auth/IAuthApi';
import {ENV, HTTP_PROVIDER_URL, NETWORK_ID, webAppUrl} from '../../../config/common';
import {ConnectWalletOptions} from './types';

type IDTokenPayload = JwtPayload & {
  email: string;
};

export type TorusWalletOptions = WalletInitOptions & {
  biconomyApiKey?: string;
  completeUrl?: string;
};

export type TorusWalletEvent = WalletEvent;

const ID_TOKEN_LIFETIME = 160; // idToken lifetime - 2 mins
const isIdTokenExpired = (idToken: string) => {
  const {iat = 0} = jwtDecode<IDTokenPayload>(idToken);
  const tokenLifetime = Date.now() / 1000 - iat;

  return tokenLifetime > ID_TOKEN_LIFETIME;
};

export class TorusWallet {
  private idToken?: string;
  private wallet = new EmbedWallet();

  get status() {
    return this.wallet.status;
  }

  constructor(private readonly authApi: IAuthApi, private options: TorusWalletOptions) {}

  async init(options?: ConnectWalletOptions) {
    if (this.status !== 'not-ready') {
      return;
    }

    const defaultName = window.location.hostname;
    const defaultAppUrl = window.location.origin;

    const {context} = this.options;

    await this.wallet.init({
      ...this.options,
      context: {
        app: {
          url: context?.app?.url || defaultAppUrl,
          logoUrl: options?.appConfig.logoShort.url || context?.app?.logoUrl,
          name: options?.appConfig.appTitle || context?.app?.name || defaultName,
        },
      },
    });
  }

  async cleanUp() {
    await this.disconnect();
  }

  getAddress() {
    return this.getProvider().getSigner().getAddress();
  }

  async connect(options?: ConnectWalletOptions) {
    await this.init(options);

    if (this.status === 'connected') {
      return this.getAddress();
    }

    await this.authenticate();

    return this.getAddress();
  }

  async disconnect() {
    await this.wallet.disconnect();
    this.idToken = undefined;
  }

  subscribe(event: WalletEvent, handler: () => void) {
    return this.wallet.subscribe(event, handler);
  }

  switchNetwork() {
    this.wallet.showWallet('settings');
  }

  topUp() {
    this.wallet.showWallet('topup');
  }

  openWallet() {
    this.wallet.showWallet();
  }

  getProvider() {
    return new ethers.providers.Web3Provider(this.wallet.provider);
  }

  private async authenticate() {
    if (!this.idToken || isIdTokenExpired(this.idToken)) {
      await this.refreshIdToken();
    }

    const idToken = this.idToken;

    /**
     * Clear current `idToken` since it can only be used once
     */
    this.idToken = undefined;

    try {
      await this.validateAccount();
      await this.wallet.connect({idToken, mode: 'redirect', redirectUrl: this.options.completeUrl});
    } catch (error) {
      await this.refreshIdToken();

      throw error;
    }
  }

  private async validateAccount() {
    if (this.wallet.status !== 'connected') {
      return;
    }

    if (!this.idToken) {
      await this.refreshIdToken();
    }

    const {email} = jwtDecode<IDTokenPayload>(this.idToken!);
    const userInfo = await this.wallet.getUserInfo();

    if (userInfo.email !== email) {
      console.warn(`[Tor.us] Another account connected (${userInfo.email}). Disconnecting...`);

      await this.disconnect();
    }
  }

  private async refreshIdToken() {
    this.idToken = await this.authApi.getIdToken();
  }
}

export const createTorusWallet = (authApi: IAuthApi, completeUrl?: string) => {
  if (!NETWORK_ID || !HTTP_PROVIDER_URL) {
    throw new Error('Tor.us: Incorrect configuration in .env');
  }

  return new TorusWallet(authApi, {
    env: ENV as TorusWalletOptions['env'],
    completeUrl: completeUrl ? `${webAppUrl()}${completeUrl}` : undefined,
    network: {
      host: HTTP_PROVIDER_URL,
      chainId: NETWORK_ID,
    },
  });
};
