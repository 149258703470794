import {useState} from 'react';
import useSWR from 'swr';
import {NftCardInterface} from '@cere/services-types';

import {useLocalization} from 'shared/hooks/use-locale.hook';
import {nftApi} from '../index';

type ReturnData = {nft: NftCardInterface | null; loading: boolean};

export const useLoadNftById = (id?: string | null): ReturnData => {
  const [loading, setLoading] = useState(false);

  const {locale} = useLocalization();
  const {data: nft} = useSWR(`nft-id-${id}`, async () => {
    if (!id) {
      return null;
    }
    setLoading(true);
    const currentNft = await nftApi.getNftById(id, locale);
    setLoading(false);
    return currentNft;
  });
  return {nft: nft ?? null, loading};
};

export const useLoadNftsByIds = (ids: string[]) => {
  const [loading, setLoading] = useState(false);

  const {locale} = useLocalization();
  const {data: nfts} = useSWR(`nft-id-${ids}`, async () => {
    setLoading(true);
    const nfts = await nftApi.getNftsByIdsList(ids, locale);
    setLoading(false);
    return nfts;
  });
  return {nfts: nfts, loading};
};
