import {useCallback, useContext, useEffect, useState} from 'react';
import {addUserConsent, checkUserConsent} from '../../services/user-consent.service';
import {UserContext} from '../../../context/user-context/user-context';
import {singletonHook} from 'react-singleton-hook';

const checkedSet = new Map();

interface CheckUserConsentInterface {
  status: undefined | boolean;
  isModalVisible: boolean;
  readTerms: boolean;
  onReadClicked: () => void;
  isSubmitDisabled: boolean;
  onSubmit: () => void;
}

const init = {
  status: undefined,
  isModalVisible: false,
  readTerms: false,
  onReadClicked: () => {},
  isSubmitDisabled: false,
  onSubmit: () => {},
};

const useCheckUserConsent = (): CheckUserConsentInterface => {
  const [readTerms, setReadTerms] = useState<boolean>(false);
  const [isSubmitDisabled, updateSubmitButtonDisability] = useState<boolean>(true);
  const {userData} = useContext(UserContext);
  const [status, setStatus] = useState<boolean | undefined>(checkedSet.get(userData.token));

  const checkConsent = useCallback(
    async (token: string) => {
      if (!token) {
        return;
      }

      if (!checkedSet.has(token)) {
        try {
          const result = await checkUserConsent(token);
          checkedSet.set(token, result);
        } catch (e) {
          console.error(e);
        }
      }

      setStatus(checkedSet.get(userData.token));
    },
    [userData.token],
  );

  useEffect(() => {
    if (userData.token) {
      checkConsent(userData.token);
    } else {
      setStatus(undefined);
    }
  }, [userData.token, checkConsent]);

  const onReadClicked = () => {
    updateSubmitButtonDisability(readTerms);
    setReadTerms(!readTerms);
  };

  const onSubmit = async () => {
    const token = userData.token;
    checkedSet.delete(token);
    await addUserConsent(token);
    await checkConsent(token);
  };

  return {
    status: status,
    isModalVisible: status === false,
    readTerms,
    onReadClicked,
    isSubmitDisabled,
    onSubmit,
  };
};

export const useSingletonCheckUserConsent = singletonHook(init, useCheckUserConsent);
