import {ReactElement} from 'react';
import {ButtonWithIcon} from '@cere/rxb-template-ui-kit';
import clsx from 'clsx';
import {Box, makeStyles} from '@material-ui/core';

import {GoogleAnalyticsId} from 'analytics-ids';
import {ReactComponent as PlayIcon} from 'assets/icons/play.svg';
import colors from 'styles/colors';
import {useLocalization} from '../../../../../shared/hooks/use-locale.hook';

export type ExhibitEnterButtonVariant = 'primary' | 'secondary';

type Props = {
  onClick: () => unknown;
  variant: ExhibitEnterButtonVariant;
};

const useButtonStyles = makeStyles((theme) => ({
  buttonBlock: {
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'absolute',
  },
  noAccessButton: {
    top: '-20px',
    left: 0,
    right: 0,
    width: '300px',

    [theme.breakpoints.up('md')]: {
      bottom: '-88px',
      top: 'unset',
      width: '100%',
    },
  },
  enterButton: {
    bottom: '-30px',
    left: 0,
    right: 0,
    width: '174px',

    [theme.breakpoints.up('md')]: {
      bottom: '-24px',
      width: '245px',
    },
  },
  button: {
    width: 'fit-content',
    zIndex: 1,
  },
  enterIcon: {
    minWidth: '2.5rem',
    minHeight: '2.5rem',
    borderRadius: '50%',
  },
  enterIconPrimary: {
    backgroundColor: theme.palette.secondary.dark,
  },
  enterIconSecondary: {
    backgroundColor: theme.palette.secondary.main,
  },
  iconStyle: {
    width: '40px',
    height: '40px',
    background: 'rgba(140, 140, 148, 0.5)',
    backdropFilter: 'blur(8px)',
    borderRadius: '50%',
    marginBottom: '2px',
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    '& svg': {
      width: '11px',
      height: '13px',

      '& path': {
        fill: colors.light,
      },
    },
    [theme.breakpoints.up('md')]: {
      width: '48px',
      height: '48px',
      marginBottom: '16px',

      '& svg': {
        width: '13px',
        height: '16px',

        '& path': {
          fill: colors.light,
        },
      },
    },
  },
  noAccessText: {
    color: '#e0e0e7',
    textAlign: 'center',
    padding: '0 2rem',
    minHeight: '3.5rem',
    maxWidth: 'unset',
    textShadow: '0 0 3px #000',

    [theme.breakpoints.up('md')]: {
      textShadow: 'unset',
    },
  },
}));

export function ExhibitLiveEnterButton({onClick, variant = 'secondary'}: Props): ReactElement {
  const buttonStyles = useButtonStyles();
  const {t} = useLocalization();

  return (
    <Box className={clsx(buttonStyles.buttonBlock, buttonStyles.enterButton)}>
      <ButtonWithIcon
        variant="contained"
        color={variant}
        size="medium"
        fullWidth
        className={clsx(buttonStyles.button, GoogleAnalyticsId.GoToTheEventBtn)}
        textStyle={{fontSize: '1rem', whiteSpace: 'nowrap'}}
        buttonText={t('Play content')}
        iconVariant="left"
        onClick={onClick}
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          className={clsx(
            buttonStyles.enterIcon,
            variant === 'primary' ? buttonStyles.enterIconPrimary : buttonStyles.enterIconSecondary,
          )}
        >
          <PlayIcon />
        </Box>
      </ButtonWithIcon>
    </Box>
  );
}
