import {selectAppConfig} from '../../redux/modules/app-config/selectors';
import {Dispatch} from 'redux';
import {ResendOTPCommand, VerifyOtpCommand} from '../../redux/modules/auth/actions';
import {connect} from 'react-redux';
import {VerifyEmailView} from './view';

const mapStateToProps = (state: any) => {
  const {appTitle, logoShort} = selectAppConfig(state);
  return {
    appName: appTitle,
    logoUrl: logoShort.url,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  verifyCode: (code: string) => dispatch(VerifyOtpCommand.create(code)),
  resendCode: () => dispatch(ResendOTPCommand.create()),
});

export const VerifyEmailPage = connect(mapStateToProps, mapDispatchToProps)(VerifyEmailView);
