import {ReactNode} from 'react';
import {ErrorBoundary as ReactErrorBoundary} from 'react-error-boundary';
import {useLocation} from 'react-router-dom';
import {ErrorPage} from '../../../pages/ErrorPage';

export const ErrorBoundary = ({children}: {children?: ReactNode | undefined}) => {
  const {pathname} = useLocation();

  return (
    <ReactErrorBoundary FallbackComponent={ErrorPage} key={pathname}>
      {children}
    </ReactErrorBoundary>
  );
};
