import {getContractAddress} from '@cere/freeport-sdk';
import {maticNetworkUrls, MaticTestnetIdEnum} from '../../services/web3';
import {APPLICATION, ENV, NETWORK_ID} from '../../../config/common';

const CONTRACT_NAME = 'Freeport';

export const getSmartContractAddress = () =>
  getContractAddress({
    deployment: ENV || 'dev',
    contractName: CONTRACT_NAME,
    chainId: NETWORK_ID,
    application: APPLICATION(),
  });

export const getMaticNetworkUrl = () => {
  const chainId = NETWORK_ID;
  return maticNetworkUrls[chainId as MaticTestnetIdEnum];
};
