import {SyntheticEvent, useContext, useEffect, useState} from 'react';
import {Button, Typography} from '@cere/rxb-template-ui-kit';
import {Box, Grid} from '@material-ui/core';
import clsx from 'clsx';
import {ReactComponent as RePlayIcon} from '../../../../../assets/replay.svg';
import BootstrapLoader from '../../../../../shared/components/bootstrap-loader';
import {buildAbsoluteCmsUrl} from '../../../../../shared/queries/utils';
import {getMediaUrl} from '../../../../../shared/lib/media';
import {ExhibitContext} from '../../../../../context/exhibition/exhibit-context';
import {useStyles} from './styles';
import {usePlayer} from '../../../../../shared/components/Player/player.context';
import {useScrollToElement} from '../../../../../shared/hooks/scroll-to-element.hook';
import {useTranslation} from 'react-i18next';
import {CuratedRow} from 'shared/components/CuratedRow';
import {EventCardV2} from 'shared/components/EventCardBig/EventCardV2';
import {useHomePageExhibits} from 'api/hooks/use-home-page-featured-exhibits';
import {GoogleAnalyticsId} from 'analytics-ids';

type Updater = (value: boolean) => boolean;

interface NextExhibitOverlayProps {
  onClick: () => void;
  setShowMore: (updater: Updater) => unknown;
  setShowOverlay: () => void;
  showMore: boolean;
}

export const NextExhibitOverlay = ({showMore, onClick, setShowOverlay}: NextExhibitOverlayProps) => {
  const {event, loading} = useContext(ExhibitContext);
  const url = event && buildAbsoluteCmsUrl(getMediaUrl(event?.image));
  const {player} = usePlayer();
  const {refElement, scrollToElement} = useScrollToElement();
  const {t} = useTranslation();
  const width = window.screen.width;
  const height = window.screen.height;

  const [isLandscape, setIsLandscape] = useState(false);

  const classes = useStyles({url, isLandscape});

  useEffect(() => {
    if (height < 430 && height < width) {
      setIsLandscape(true);
    } else {
      setIsLandscape(false);
    }
  }, [width, height]);

  useEffect(() => {
    player?.pause();
    return () => player?.play();
  }, [player]);

  useEffect(() => {
    showMore && scrollToElement();
  }, [scrollToElement, showMore]);

  const {exhibits} = useHomePageExhibits();

  const onPreventEventClick = (e: SyntheticEvent) => {
    e.stopPropagation();
  };

  if (!event || loading) {
    return <BootstrapLoader />;
  }

  return (
    <Box className={classes.main} onClick={onPreventEventClick}>
      <Box className={classes.container}>
        <Box pt="60px" marginTop="auto" {...{ref: refElement}}>
          <Typography variant="h4" className={classes.title}>
            {event.title}
          </Typography>
        </Box>

        <Box className={classes.nextExhibitContainer}>
          <Typography className={classes.titleNextExhibit}>{t('End of exhibit video')}</Typography>

          <Button
            color="primary"
            variant="contained"
            className={clsx(classes.button, GoogleAnalyticsId.ExhibitBidBtn)}
            onClick={onClick}
          >
            {t('Purchase exclusive NFTs')}
          </Button>
          <Button className={classes.replayBlock} onClick={setShowOverlay}>
            <RePlayIcon className={classes.replayIcon} />
            <span>{t('Play video again')}</span>
          </Button>
        </Box>

        <Box className={classes.curatedRowHided}>
          <CuratedRow title={t('Discover more exhibits')} isOverlay isLandscape={isLandscape}>
            {exhibits && exhibits.map((event) => <EventCardV2 event={event} />)}
          </CuratedRow>
        </Box>

        <Box className={classes.gridHided}>
          <Typography className={classes.titleRow}>{t('Discover more exhibits')}</Typography>
          <Grid container spacing={4}>
            {exhibits &&
              exhibits.map((event) => (
                <Grid item key={event.id} xs={12} sm={6} lg={4}>
                  <EventCardV2 event={event} className={classes.card} />
                </Grid>
              ))}
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};
