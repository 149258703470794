import {ReactElement, ReactNode, useCallback, useMemo, useState} from 'react';
import {useQuery} from '@apollo/client';
import {isCmsNft, PartialExhibitionNft} from './types';
import {
  useFindRelatedExhibition,
  useFindRelatedExhibitionId,
  useFindRelatedExhibitionSlug,
  useRelatedExhibitionStatus,
} from './use-find-related-exhibition';
import {getAvailableNFTQuantity, getNFTBalance, getNFTPrice} from '../../helpers/nfts';
import {GET_NFT} from '../../queries/nfts';
import {CmsExhibitionNftRelType, CmsNft} from '../../types/graphql';
import {isAuctionHasBids} from '../../services/auction.service';
import {useUserWalletsPubKeys} from '../../../context/use-user-wallets-pub-keys';
import {useStartNftSale} from '../../../context/sell-nft-context';
import {useExchangeRate} from '../../hooks/use-exchange-rate';
import {cereToUSD, cereToUSDCents} from '../../lib/formatPrice';
import {NFTOfferTypes, useNftListing} from '../../hooks/use-nft-listing';
import {NftContext, NftContextType} from './nft-context';
import {useHistory} from 'react-router-dom';
import {useNftCtaButtonProps} from './use-nft-cta-button-props';
import {useNftAttributes} from './use-nft-attributes';
import {getNftAvailabilityByCmsNft} from '../../services/nft.service';
import {getTimelineStatus} from '../../../pages/Сms/Exhibit/utils';
import {getShareUrl} from '../Share/utils/get-share-url';
import Share from '../Share';
import {useResponsiveImage} from '../../hooks/use-responsive-image';
import analyticService, {AnalyticEventsEnum} from '../../services/analytic.service';
import {NftPurchaseParams} from '../WithPurchaseNft/v2';
import {usePurchaseById} from '../../../context/purchase-nft-context';
import {useLocalization} from '../../hooks/use-locale.hook';

type Props = {
  children: ReactNode;
  nft: PartialExhibitionNft;
  startNFTBuyOrRequestAuth: (data: NftPurchaseParams) => Promise<unknown>;
  isNftDetailsPage?: boolean;
};

export function NftInnerProvider({children, startNFTBuyOrRequestAuth, nft, isNftDetailsPage}: Props): ReactElement {
  const {t, locale} = useLocalization();
  const nftId = nft.cmsNft?.creatorNft?.nft_id;
  const exhibitionId = useFindRelatedExhibitionId(nftId);
  const exhibitionSlug = useFindRelatedExhibitionSlug(exhibitionId ? nftId : undefined);
  const exhibition = useFindRelatedExhibition(exhibitionId ? nftId : undefined);
  const exhibitionStatus = useRelatedExhibitionStatus(exhibitionId ? nftId : undefined);
  const wallets = useUserWalletsPubKeys();
  const {cmsNft} = nft;
  const {data} = useQuery<{cmsNft: CmsNft}>(GET_NFT, {
    variables: {id: cmsNft.id},
    skip: !isCmsNft(cmsNft),
  });
  const cereUnitsPerPenny = useExchangeRate();
  const startNftSell = useStartNftSale();
  const {startNFTPurchaseById} = usePurchaseById();
  const history = useHistory();

  const [showShareNftModal, setShowShareNftModal] = useState(false);

  const {
    offers,
    ownerOffer,
    primaryOffer,
    loading: nftListingLoading,
  } = useNftListing({cmsNftIncrementId: cmsNft.id, start: 0, limit: 10});

  const eventNft = useMemo(() => {
    return exhibition?.nfts.find((item) => item.cmsNft?.creatorNft?.nft_id === nftId);
  }, [exhibition?.nfts, nftId]);
  const relType = eventNft?.relType;

  const eventNftStatus: typeof exhibitionStatus = useMemo(() => {
    if (eventNft && exhibitionStatus === 'ready') {
      return 'ready';
    }

    return exhibitionStatus === 'failed' ? 'failed' : 'loading';
  }, [eventNft, exhibitionStatus]);

  const priceText = useMemo(() => {
    const hasBids = isAuctionHasBids(nft.cmsNft?.creatorNft?.creator_auctions[0]);
    return hasBids ? t('Top bid') : t('Starting price');
  }, [nft.cmsNft?.creatorNft?.creator_auctions, t]);

  const balance = useMemo(
    () => getNFTBalance(isCmsNft(cmsNft) ? cmsNft : data?.cmsNft, eventNft?.relType),
    [cmsNft, data?.cmsNft, eventNft?.relType],
  );

  const priceUnits = useMemo(() => {
    if (!isCmsNft(eventNft?.cmsNft) || !relType) {
      return 0;
    }
    return getNFTPrice(eventNft?.cmsNft, relType);
  }, [eventNft?.cmsNft, relType]);

  const owned = useMemo(() => (isCmsNft(cmsNft) ? getAvailableNFTQuantity(cmsNft, wallets) : 0), [cmsNft, wallets]);

  const auction = useMemo(
    () => ({
      priceText,
    }),
    [priceText],
  );

  const nftAvailability = getNftAvailabilityByCmsNft(eventNft).availability;
  const priceUsd = useMemo(() => cereToUSD(priceUnits, cereUnitsPerPenny!), [cereUnitsPerPenny, priceUnits]);
  const priceUsdCents = useMemo(() => cereToUSDCents(priceUnits, cereUnitsPerPenny), [cereUnitsPerPenny, priceUnits]);
  const eventUrl = useMemo(() => {
    return exhibitionSlug
      ? `/${locale}/home/exhibit/${exhibitionSlug}`
      : Object(history.location?.state).referrer ?? '';
  }, [exhibitionSlug, history.location?.state, locale]);

  const nftImage = useResponsiveImage(eventNft?.cmsNft.assets[0]?.content);

  const handleSellClick = useCallback(() => {
    if (isCmsNft(cmsNft)) {
      const nftId = nft.cmsNft?.creatorNft?.nft_id;

      if (nftId) {
        analyticService.track(AnalyticEventsEnum.MARKETPLACE_SELL, {nftId});
      }
      startNftSell({
        cmsNftIncrementId: cmsNft.id,
        nftName: cmsNft.title,
        nftId: cmsNft?.creatorNft?.nft_id ?? '',
        nftMedia: cmsNft.assets[0]?.content,
        ownedQty: owned,
        price: priceUsd,
        creatorWalletNfts: cmsNft?.creatorNft?.creator_wallet_nfts!,
        collectionAddress: cmsNft?.creatorNft?.collection_address,
      });
    }
  }, [cmsNft, nft.cmsNft?.creatorNft?.nft_id, owned, priceUsd, startNftSell]);

  const doMarketplacePurchase = useCallback(async () => {
    if (nftListingLoading || !isCmsNft(cmsNft)) {
      return;
    }
    const nftId = nft.cmsNft?.creatorNft?.nft_id;

    if (nftId) {
      analyticService.track(AnalyticEventsEnum.MARKETPLACE_BUY, {nftId});
    }

    await startNFTPurchaseById(cmsNft.id.toString(), 1);
  }, [cmsNft, nft.cmsNft?.creatorNft?.nft_id, nftListingLoading, startNFTPurchaseById]);

  const handlePurchase = useCallback(
    async (nftParams: NftPurchaseParams) => {
      await startNFTBuyOrRequestAuth(nftParams);
    },
    [startNFTBuyOrRequestAuth],
  );

  const handleBuyClick = useCallback(() => {
    if (!nft) {
      return Promise.resolve();
    }
    const creatorNft = nft.cmsNft?.creatorNft;
    const nftId = creatorNft?.nft_id;
    const auction = creatorNft?.creator_auctions?.filter((auction) => !auction.is_settled).shift();

    if (relType === CmsExhibitionNftRelType.AUCTIONED) {
      if (nftId) {
        analyticService.track(AnalyticEventsEnum.EXHIBIT_NFT_BID_CLICKED, {nftId});
      }
    }

    if (relType === CmsExhibitionNftRelType.AUCTIONED && offers.length > 0) {
      if (nftId) {
        analyticService.track(AnalyticEventsEnum.AUCTION_NFT_CARD_BUY_CLICKED, {nftId});
      }
    }

    if ((primaryOffer && relType === CmsExhibitionNftRelType.ACCESS) || auction) {
      let auctionOffer;
      if (primaryOffer?.type === NFTOfferTypes.Auction) {
        const primaryAuction = cmsNft?.creatorNft?.creator_auctions.find(
          (auction) => auction.seller === primaryOffer.seller,
        );
        if (primaryAuction) {
          auctionOffer = {
            latestBid: primaryAuction.creator_auction_bids[0]?.price ?? auction?.price,
            bids: primaryAuction.creator_auction_bids,
          };
        }
      }
      return handlePurchase({
        amount: 1,
        relType: String(nft.relType),
        creatorName: cmsNft?.cmsCreator?.name ?? '',
        minter: cmsNft?.creatorNft?.minter ?? '',
        nftAddress: cmsNft?.creatorNft?.nft_id ?? '',
        nftImage: cmsNft?.assets?.[0]?.content?.url ?? '',
        nftPrice: priceUnits,
        nftTitle: cmsNft?.title ?? '',
        nftUSDPrice: priceUsd,
        nftId: cmsNft?.id.toString() ?? '',
        eventId: exhibitionId,
        eventSlug: exhibitionSlug,
        collectionAddress: cmsNft?.creatorNft?.collection_address,
        auction: auctionOffer,
      });
    }

    return doMarketplacePurchase();
  }, [
    nft,
    cmsNft?.id,
    relType,
    offers.length,
    doMarketplacePurchase,
    handlePurchase,
    cmsNft?.creatorNft?.creator_auctions,
    cmsNft?.creatorNft?.minter,
    cmsNft?.creatorNft?.nft_id,
    cmsNft?.creatorNft?.collection_address,
    cmsNft?.cmsCreator?.name,
    cmsNft?.assets,
    cmsNft?.title,
    priceUnits,
    priceUsd,
    exhibitionId,
    exhibitionSlug,
    primaryOffer,
  ]);

  const openShare = useCallback(() => {
    setShowShareNftModal(true);
  }, []);

  const closeShare = useCallback(() => {
    setShowShareNftModal(false);
  }, []);

  const eventStatus = getTimelineStatus(exhibition);
  const attributes = useNftAttributes({
    event: exhibition,
    nft: eventNft,
    availability: nftAvailability,
    balance,
    eventStatus,
  });

  const buttonProps = useNftCtaButtonProps({
    nft: eventNft,
    event: exhibition,
    balance,
    buy: handleBuyClick,
    sell: handleSellClick,
    owned,
    share: openShare,
    attributes,
    isNftDetailsPage,
  });

  const context: NftContextType = useMemo(() => {
    return {
      relType,
      eventNftStatus,
      cmsNftId: nft.cmsNft.id,
      eventNft,
      partialNft: nft,
      attributes,
      listing: {
        offers,
        ownerOffer,
      },
      event: exhibition,
      eventUrl,
      balance,
      supply: eventNft?.cmsNft.creatorNft?.supply ?? 0,
      handlers: {
        sell: handleSellClick,
        buy: handleBuyClick,
        handlePurchase,
      },
      price: {
        units: priceUnits,
        usd: priceUsd,
        cents: priceUsdCents,
      },
      auction,
      market: {
        owned,
      },
      button: buttonProps,
      isNftDetailsPage,
    };
  }, [
    attributes,
    auction,
    balance,
    buttonProps,
    eventNft,
    eventNftStatus,
    eventUrl,
    exhibition,
    handleBuyClick,
    handleSellClick,
    handlePurchase,
    nft,
    offers,
    owned,
    ownerOffer,
    priceUnits,
    priceUsd,
    priceUsdCents,
    relType,
    isNftDetailsPage,
  ]);

  return (
    <NftContext.Provider value={context}>
      {children}
      <Share
        isOpen={showShareNftModal}
        onClose={closeShare}
        title={t('Share NFT')}
        description={eventNft?.cmsNft.title || ''}
        imgSrc={nftImage}
        url={getShareUrl(
          `${locale}/home/nft/${eventNft?.cmsNft.id}`,
          eventNft?.cmsNft.title || '',
          eventNft?.cmsNft.title || '',
          nftImage || '',
        )}
      />
    </NftContext.Provider>
  );
}
