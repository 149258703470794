import {Dispatch} from 'redux';
import {connect} from 'react-redux';
import {generatePath} from 'react-router-dom';
import {selectNftById} from '../../../redux/modules/nfts/selectors';
import {ROUTES} from '../../../constants/routes';
import {selectCurrentLocale} from '../../../redux/modules/localization/selectors';
import {selectActiveWallet, selectActiveWalletBalance} from '../../../redux/modules/wallets/selectors';
import {PurchaseNFTForCryptoCommand, PurchaseNFTForFiatCommand} from '../../../redux/modules/purchase/actions';
import {CloseActiveModalCommand} from '../../../redux/modules/modals/actions';
import {BuyFromMinterModal} from '../../shared/Modals/Purchase/BuyFromMinterModal';
import {createQuantityOptions} from '../../primitives/QuantitySelect/createQuantityOptions';

type PurchaseFromMinterModalProps = {
  nftId: string;
};

const mapStateToProps = (state: any, props: PurchaseFromMinterModalProps) => {
  const nft = selectNftById(state, props.nftId);
  const locale = selectCurrentLocale(state);
  const selectedWalletName = selectActiveWallet(state);
  const quantityOptions = createQuantityOptions(nft.balance);
  return {
    image: nft.image!,
    creatorName: nft.creatorName,
    title: nft.title,
    nftLink: generatePath(ROUTES.NFT_PAGE, {nftId: props.nftId, locale}),
    sellerWalletAddress: nft.minter,
    walletName: selectedWalletName,
    walletBalance: selectActiveWalletBalance(state),
    nftAddress: nft.address,
    quantityOptions,
    disabledQuantity: true,
    quantity: 1,
    priceUSDC: nft.price,
  };
};

const mapDispatchToProps = (dispatch: Dispatch, {nftId}: PurchaseFromMinterModalProps) => {
  return {
    onBuyClick: (sellerWalletAddress: string, price: number, qty: number) =>
      dispatch(PurchaseNFTForCryptoCommand.create(nftId, sellerWalletAddress, price, qty)),
    onClose: () => dispatch(CloseActiveModalCommand.create()),
    onPayByCard: () => dispatch(PurchaseNFTForFiatCommand.create(nftId)),
    onFundWalletClick: () => {}, // FixMe
  };
};

export const PurchaseFromMinterModal = connect(mapStateToProps, mapDispatchToProps)(BuyFromMinterModal);
