import {useContext, useMemo} from 'react';
import {Box, CardContent, Divider, makeStyles} from '@material-ui/core';
import {Typography} from '@cere/rxb-template-ui-kit';
import clsx from 'clsx';
import {Trans} from 'react-i18next';

import {ReactComponent as AccessIcon} from 'assets/ticket.svg';
import {ReactComponent as AuctionedIcon} from 'assets/crown.svg';
import {ReactComponent as LimitedIcon} from 'assets/star.svg';
import {GoogleAnalyticsId} from 'analytics-ids';
import {enableMarketplaceView} from 'config/common';

import {Card} from 'shared/components/Card';
import {Avatar} from 'shared/components/Avatar';
import {Condition, ConditionsList, Defaults} from 'shared/components/Conditions';
import {link, StyledLink} from 'shared/components/StyledLink';
import {NftContext} from 'shared/components/Nft/nft-context';
import {NftCtaButton} from 'shared/components/Nft/components/NftCtaButton';
import {useNftCardStyles} from 'shared/components/NFTDetailsCard/styles';
import {formatPrice, usdcUnitsToUSD} from 'shared/lib/formatPrice';
import {getNftRelTypeTitle} from 'shared/helpers/get-nft-rel-type-title.helper';
import {useLocalization} from 'shared/hooks/use-locale.hook';
import {CmsExhibitionNftRelType} from 'shared/types/graphql';
import colors from 'styles/colors';

export const BUTTON_CLASSES = {
  [CmsExhibitionNftRelType.ACCESS]: GoogleAnalyticsId.BuyAccessBtn,
  [CmsExhibitionNftRelType.LIMITED]: GoogleAnalyticsId.BuyDropBtn,
  [CmsExhibitionNftRelType.AUCTIONED]: GoogleAnalyticsId.BidBtn,
};

export interface NftCardProps {
  styles: ReturnType<typeof useNftCardStyles>;
}

const useStyles = makeStyles((isAccessNft: boolean) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  badgeContainer: {
    zIndex: 15,
    height: '26px',
    width: 'fit-content',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: isAccessNft ? '2px 8px' : '2px 12px',
    background: 'rgba(22, 22, 22, 0.7)',
    backdropFilter: 'blur(80px)',
    borderRadius: '13px',
    marginTop: '22px',
    marginBottom: '12px',
  },
  badgeText: {
    paddingLeft: '5px',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '22px',
    color: colors.light,
  },
  divider: {
    marginTop: '20px',
    marginBottom: '20px',
  },
  priceBlock: {
    display: 'flex',
    flexDirection: 'column',
  },
  qty: {
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '24px',
    color: colors.lightGrey,
    margin: '20px 0 13px 0',
  },
  price: {
    fontWeight: 700,
    fontSize: '20px',
    lineHeight: '26px',
  },
  ctaButtonContainer: {
    width: '122px',
    marginTop: '44px',
    left: 0,
  },
  content: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

export const NftModalCardContent = ({styles}: NftCardProps) => {
  const {t, locale} = useLocalization();
  const {balance, supply, market, relType, event, eventNft, price, auction, partialNft} = useContext(NftContext);
  const nft = eventNft?.cmsNft || partialNft.cmsNft;

  const analyticClassName = useMemo(() => {
    if (enableMarketplaceView() && market.owned > 0) {
      return GoogleAnalyticsId.SellNftBtn;
    }
    if (nft && balance) {
      return GoogleAnalyticsId.BuyNftBtn;
    }
    if (relType) {
      return BUTTON_CLASSES[relType];
    }
  }, [balance, nft, market.owned, relType]);

  const isComingSoon = Boolean(nft?.isComingSoon) || !event;

  const isAccessNftBadge = useMemo(() => relType === CmsExhibitionNftRelType.ACCESS, [relType]);
  const classes = useStyles({isAccessNftBadge});

  return (
    <Card className={classes.wrapper}>
      {nft?.assets !== undefined && <img src={nft.assets[0]?.content?.url} className={styles.image} alt="nft" />}
      <CardContent className={clsx(styles.cardContent, classes)}>
        {relType && (
          <Box className={classes.badgeContainer}>
            <ConditionsList>
              <Condition condition={isAccessNftBadge}>
                <AccessIcon />
              </Condition>
              <Condition condition={relType === CmsExhibitionNftRelType.LIMITED}>
                <LimitedIcon />
              </Condition>
              <Defaults>
                <AuctionedIcon />
              </Defaults>
            </ConditionsList>
            <span className={classes.badgeText}>{getNftRelTypeTitle(relType)}</span>
          </Box>
        )}
        <Typography className={styles.title}>{eventNft?.cmsNft?.title}</Typography>
        {nft?.cmsCreator && (
          <StyledLink
            to={`/${locale}/home/creator/${nft.cmsCreator.id}`}
            className={clsx(link, GoogleAnalyticsId.ViewCreatorBtn)}
          >
            <Avatar name={nft.cmsCreator.name} imageUrl={nft.cmsCreator.avatar.url} creatorId={nft.cmsCreator.id} />
          </StyledLink>
        )}
        <Divider />
        <Box className={classes.content}>
          <ConditionsList multiply>
            <Condition condition={!isComingSoon && market.owned === 0 && relType === CmsExhibitionNftRelType.AUCTIONED}>
              <Box className={classes.priceBlock}>
                <Typography className={classes.qty}>{t('1 Auctioned original')}</Typography>
                <Typography variant="caption1" className={clsx(classes.price)}>
                  <Typography variant="h4" component="span" className={styles.priceText}>
                    {auction.priceText}&nbsp;
                  </Typography>
                  <Typography variant="h4" component="span" className={styles.priceDescriptionText}>
                    {formatPrice(usdcUnitsToUSD(price.units))}
                  </Typography>
                </Typography>
              </Box>
            </Condition>
            <Condition condition={!isComingSoon && market.owned === 0 && relType !== CmsExhibitionNftRelType.AUCTIONED}>
              <Box className={classes.priceBlock}>
                <Typography className={classes.qty}>
                  <Trans
                    i18nKey="{{leftAmount}}/{{totalAmount}} NFTs Left"
                    values={{leftAmount: balance, totalAmount: supply}}
                    count={balance}
                  />
                </Typography>
                <Typography className={classes.price}>${price.usd}</Typography>
              </Box>
            </Condition>
            <Condition condition={!isComingSoon && Boolean(nft)}>
              <Box className={classes.ctaButtonContainer}>
                <NftCtaButton classname={analyticClassName} buttonText={t('Buy now')} />
              </Box>
            </Condition>
          </ConditionsList>
        </Box>
        <Divider className={classes.divider} />
        <Typography>{nft.cardDescription}</Typography>
      </CardContent>
    </Card>
  );
};
