import {sumBy} from 'lodash';
import {CmsExhibitionNftRelType, CmsNft, CreatorNft} from '../types/graphql';
import {CMSNFTAuction, CMSNFTOffer} from '../hooks/use-nft-listing';
import {Optional} from '../types/optional';
import {useFindNftRelType} from '../components/Nft/use-find-related-exhibition';
import {findActualAuction, findLastCreatedAuction} from '../components/Nft/utils';
import {ImageSize, getResponsiveImage} from '../hooks/use-responsive-image';

type NFT = Optional<CmsNft, 'published_at'>;

export const getNFTOfferQuantity = (offer: CMSNFTOffer): number =>
  offer.creator_nft?.creator_wallet_nfts.find((walletNft) => walletNft.wallet === offer.seller)?.quantity || 0;

export const getNFTAuctionQuantity = (auction: CMSNFTAuction): number =>
  auction.nft_id?.creator_wallet_nfts.find((walletNft) => walletNft.wallet === auction.seller)?.quantity || 0;

export const getAvailableNFTQuantity = (nft: CmsNft | undefined, userWallets: string[]): number => {
  const creatorWalletNfts = nft?.creatorNft?.creator_wallet_nfts.filter(({wallet}) => userWallets.includes(wallet));

  return sumBy(creatorWalletNfts, ({quantity}) => quantity);
};

const isValidOffer = (nft: CreatorNft | undefined, offer: {seller: string}) => {
  if (!nft) {
    return false;
  }
  const sellerWalletWithNft = nft.creator_wallet_nfts.filter(
    ({wallet, quantity}) => wallet === offer.seller && quantity > 0,
  );
  return sellerWalletWithNft.length > 0;
};

export const getNFTBalance = (nft: CmsNft | undefined, relType: CmsExhibitionNftRelType | undefined): number => {
  const minter = nft?.creatorNft?.minter;
  if (relType === CmsExhibitionNftRelType.AUCTIONED) {
    const actualAuction = findActualAuction({creatorNft: nft?.creatorNft});
    if (actualAuction && !actualAuction.is_settled) {
      return 1;
    }
  }
  const offers =
    nft?.creatorNft?.creator_make_offer
      ?.filter((offer) => offer.seller !== minter)
      ?.filter((offer) => isValidOffer(nft?.creatorNft, offer)) ?? [];
  const minterWallet = nft?.creatorNft?.creator_wallet_nfts.find(({wallet}) => wallet === minter);

  return (minterWallet?.quantity ?? 0) + offers.length;
};

export const getNFTTotalOfferedQty = (cmsNft: NFT | undefined): number => {
  if (!cmsNft) {
    return 0;
  }
  const creatorMakeOffers = cmsNft?.creatorNft?.creator_make_offer || [];
  const creatorWalletNfts = cmsNft?.creatorNft?.creator_wallet_nfts || [];
  const sellerWallets = creatorMakeOffers.map((offer) => offer.seller) || [];
  return (
    creatorWalletNfts
      .filter((walletNft) => sellerWallets.includes(walletNft.wallet))
      .reduce((sum, walletNft) => sum + walletNft.quantity, 0) || 0
  );
};

export const getNFTPrimaryOfferQty = (cmsNft: NFT): number => {
  const sellerWallet = cmsNft?.creatorNft?.minter;
  const sellerOffer = cmsNft?.creatorNft?.creator_make_offer.find((offer) => offer.seller === sellerWallet);
  if (!sellerWallet || !sellerOffer) {
    return 0;
  }
  const creatorWalletNfts = cmsNft?.creatorNft?.creator_wallet_nfts || [];
  return creatorWalletNfts.find((nft) => nft.wallet === sellerWallet)?.quantity || 0;
};

export const getValidNFTOffers = (cmsNft: NFT): Array<{price: number; seller: string}> => {
  const creatorMakeOffers = cmsNft?.creatorNft?.creator_make_offer || [];
  const creatorWalletNfts = cmsNft?.creatorNft?.creator_wallet_nfts || [];
  const walletsWithValidNftQty = creatorWalletNfts
    .filter((walletNft) => walletNft.quantity > 0)
    .map((offer) => offer.wallet);
  return creatorMakeOffers.filter((walletNft) => walletsWithValidNftQty.includes(walletNft.seller));
};

export const getNFTAuctionedPrice = (cmsNft: NFT | undefined): number => {
  if (!cmsNft) {
    return 0;
  }
  const latestOpenedAuction = findLastCreatedAuction(cmsNft);
  if (!latestOpenedAuction) {
    return 0;
  }
  if (!latestOpenedAuction?.creator_auction_bids?.length) {
    return latestOpenedAuction.price;
  }
  return latestOpenedAuction.creator_auction_bids[0].price;
};

export const getNFTSellingPrice = (cmsNft: NFT | undefined): number => {
  if (!cmsNft) {
    return 0;
  }
  const minter = cmsNft?.creatorNft?.minter;
  const primaryOfferPrice = cmsNft?.creatorNft?.creator_make_offer.find((offer) => offer.seller === minter)?.price || 0;
  const validOffers = getValidNFTOffers(cmsNft);
  if (validOffers.some((offer) => offer.seller === minter && offer.price > 0)) {
    return primaryOfferPrice;
  }
  const sortedOffersAsc = validOffers.sort((current, next) => current.price - next.price);
  return sortedOffersAsc[0]?.price || primaryOfferPrice;
};

export function getNFTPrice(nft: NFT | undefined, relType: CmsExhibitionNftRelType): number {
  if (relType === CmsExhibitionNftRelType.AUCTIONED) {
    return getNFTAuctionedPrice(nft);
  }
  return getNFTSellingPrice(nft);
}

export function useGetNFTPrice(nft?: NFT, nftType?: CmsExhibitionNftRelType) {
  const relType = useFindNftRelType(nftType ? undefined : nft?.creatorNft?.nft_id) || nftType;
  if (!nft || !relType) {
    return 0;
  }
  return getNFTPrice(nft, relType);
}

export function getNFTPriceWithoutType(nft?: NFT) {
  if (!nft) {
    return 0;
  }
  return getNFTSellingPrice(nft);
}

export const getNFTCardImage = (nft?: {cardImage?: CmsNft['cardImage']}, size: ImageSize = 'md'): string | undefined =>
  getResponsiveImage(nft?.cardImage, size);
