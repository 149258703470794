import {createContext, ReactNode, useCallback, useMemo, useState} from 'react';
import {AnalyticEventsEnum} from '../../shared/services/analytic.service';

type AuthAnalyticsTrackingContextType = {
  buySendClicked?: AnalyticEventsEnum;
  signInFocused?: AnalyticEventsEnum;
  signUpFocused?: AnalyticEventsEnum;
  emailSubmitted?: AnalyticEventsEnum;
  createdSubscription?: AnalyticEventsEnum;
};

export const AuthAnalyticsTrackingContext = createContext<
  AuthAnalyticsTrackingContextType & {
    setAnalyticsTracking: (state: Partial<AuthAnalyticsTrackingContextType>) => unknown;
  }
>({
  setAnalyticsTracking: () => null,
});

type Props = AuthAnalyticsTrackingContextType & {
  children: ReactNode;
};

export function AuthAnalyticsTrackingProvider({
  signInFocused,
  buySendClicked,
  signUpFocused,
  createdSubscription,
  emailSubmitted,
  children,
}: Props) {
  const [analyticsTracking, setAnalyticsTracking] = useState<AuthAnalyticsTrackingContextType>({
    signInFocused,
    buySendClicked,
    signUpFocused,
    createdSubscription,
    emailSubmitted,
  });

  const update = useCallback((state: Partial<AuthAnalyticsTrackingContextType>) => {
    setAnalyticsTracking((currentState) => ({...currentState, state}));
  }, []);

  const context = useMemo(
    () => ({
      ...analyticsTracking,
      setAnalyticsTracking: update,
    }),
    [analyticsTracking, update],
  );

  return <AuthAnalyticsTrackingContext.Provider value={context}>{children}</AuthAnalyticsTrackingContext.Provider>;
}
