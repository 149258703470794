import {connect} from 'react-redux';
import {selectUserEmail} from '../../../redux/modules/auth/selectors';
import {AppContainerView} from './view';

const mapStateToProps = (state: any) => ({
  isUserAuthenticated: Boolean(selectUserEmail(state)),
});

const mapDispatchToProps = () => ({});

export const AppContainer = connect(mapStateToProps, mapDispatchToProps)(AppContainerView);
