import {Box, makeStyles} from '@material-ui/core';

import {useLocalization} from 'shared/hooks/use-locale.hook';

import {Title} from '../Title';
import {Skeleton} from './Skeleton';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '24px 12px',
    backgroundColor: theme.palette.background.paper,
  },
  description: {
    width: '100%',
    height: '104px',
    borderRadius: '12px',
    marginTop: '12px',
  },
}));

export const NftDescription = () => {
  const {t} = useLocalization();
  const styles = useStyles();

  return (
    <Box className={styles.root}>
      <Title>{t('Description')}</Title>
      <Skeleton variant="rect" className={styles.description} />
    </Box>
  );
};
