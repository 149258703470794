import {useContext} from 'react';
import {Box, makeStyles} from '@material-ui/core';
import {getCardBadge} from './helpers';
import {NftCardBigBase} from './NftCardBigBase';
import {NftCardBigInfo} from './NftCardBigInfo';
import {ReactComponent as SuccessIcon} from '../../../assets/icons/success.svg';
import {NftContext} from '../Nft/nft-context';
import {NftActionButton} from '../ActionButtons';
import {useLocalization} from '../../hooks/use-locale.hook';

const useStyles = makeStyles((theme) => ({
  button: {
    width: '100%',
    '&:hover': {
      backgroundColor: theme.palette.common.white,
    },
  },
}));

export const NftCardBigFinished = () => {
  const {attributes} = useContext(NftContext);
  const {t} = useLocalization();
  const styles = useStyles();

  return (
    <NftCardBigBase
      badge={attributes.purchased ? getCardBadge(t('Your NFT'), <SuccessIcon width="14px" height="14px" />) : null}
      withOverlay={false}
      withShare={!attributes.purchased}
    >
      <NftCardBigInfo />
      <Box mt="12px">
        <NftActionButton className={styles.button} />
      </Box>
    </NftCardBigBase>
  );
};
