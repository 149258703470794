import {useContext, useEffect, useState} from 'react';
import {TorusWalletContext} from './torus-context';
import {useTorusWalletAddress} from './use-wallet-address';

/**
 * Returns the wallet status or `null` in case the wallet is not yet configured or the feature is disabled
 */
export const useTorusWalletStatus = () => {
  const wallet = useContext(TorusWalletContext);
  const {address} = useTorusWalletAddress();
  const [status, setStatus] = useState(wallet?.status);

  useEffect(() => {
    setStatus(wallet?.status);

    if (!wallet) {
      return;
    }

    const unsubscribe = wallet.subscribe('status-update', () => setStatus(wallet.status));

    return () => {
      unsubscribe();
    };
  }, [wallet]);

  return (address && status) || null;
};
