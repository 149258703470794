import {Box, Typography, Button} from '@material-ui/core';
import {useConfirmationStyles} from 'pages/ExhibitPage/routes/WebInterstitialHomePage/Exhibition-preview-container/live-styles';
import {Trans} from 'react-i18next';
import {useLocalization} from '../../hooks/use-locale.hook';
import {ReactComponent as CheckIcon} from '../../../assets/check.svg';

export interface ConfirmationModalProps {
  onClick: () => void;
}

export const ConfirmationModalContent = ({onClick}: ConfirmationModalProps) => {
  const confirmationStyles = useConfirmationStyles();
  const {t} = useLocalization();

  return (
    <Box className={confirmationStyles.confirmationContainer}>
      <CheckIcon className={confirmationStyles.checkIcon} />
      <Typography className={confirmationStyles.message}>
        <Trans i18nKey="You’re email is subscribed, stay tuned for <br /> updates!" />
      </Typography>
      <Button variant="outlined" className={confirmationStyles.button} onClick={onClick}>
        <Typography className={confirmationStyles.buttonText}>{t('Ok')}</Typography>
      </Button>
    </Box>
  );
};
