import {makeStyles, Modal} from '@material-ui/core';
import {useEffect, useState} from 'react';
import './asset-gallery.css';
import BootstrapLoader from '../bootstrap-loader';
import {ReactComponent as EmptyImage} from '../../../assets/empty-image.svg';
import {CmsNftAsset} from '@cere/services-types';
import {getMediaUrl} from '../../lib/media';

const useStyles = makeStyles(() => ({
  container: {
    width: '100vw',
    height: '100vh',
  },
  center: {
    minWidth: '100vw',
    minHeight: '100vh',
    backgroundSize: 'contain',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
  },
}));

export const AssetImageItem = ({asset}: {asset: CmsNftAsset}) => {
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [fullScreen, setFullScreen] = useState<boolean>(false);
  const [src, setSrc] = useState<string | undefined>();

  const showFullScreen = () => {
    setFullScreen(!fullScreen);
  };

  const getBlob = async (): Promise<void> => {
    setIsLoading(true);
    try {
      setSrc(getMediaUrl(asset?.content));
    } catch (error) {
      console.error(error);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    getBlob();
    // eslint-disable-next-line
  }, [asset]);

  if (isLoading) {
    return (
      <div className="loader">
        <BootstrapLoader color="text-light" />
      </div>
    );
  }

  if (!src) {
    return <EmptyImage className="main-image" />;
  }

  return (
    <>
      <img alt={asset?.content?.alternativeText} className="main-image" src={src} onClick={showFullScreen} />
      <Modal
        onClick={showFullScreen}
        BackdropProps={{
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.9)',
          },
        }}
        open={fullScreen}
      >
        <div className={classes.center} style={{backgroundImage: `url(${src})`}} />
      </Modal>
    </>
  );
};
