import {Box, makeStyles} from '@material-ui/core';

import {Skeleton} from './Skeleton';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    paddingBottom: '20px',
  },
  background: {
    height: '375px',

    [theme.breakpoints.up('md')]: {
      height: '340px',
      borderRadius: '12px',
    },

    [theme.breakpoints.up('lg')]: {
      height: '440px',
    },
  },
  iconBox: {
    position: 'absolute',
    bottom: '55px',
    left: '50%',
    transform: 'translateX(-50%)',
    width: '80px',
    height: '80px',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.background.default,
  },
  icon: {
    width: '76px',
    height: '76px',
  },
  creatorName: {
    width: '146px',
    height: '24px',
    borderRadius: '4px',
    margin: '50px auto 0 auto',
  },
}));

export const CreatorHeaderSkeleton = () => {
  const styles = useStyles();

  return (
    <Box className={styles.root}>
      <Skeleton variant="rect" className={styles.background} />
      <Box className={styles.iconBox}>
        <Skeleton className={styles.icon} variant="circle" />
      </Box>
      <Skeleton className={styles.creatorName} variant="rect" />
    </Box>
  );
};
