import {Avatar, Box, makeStyles, Theme, useMediaQuery} from '@material-ui/core';
import {Counter} from '@cere/rxb-template-ui-kit';
import {SyntheticEvent, useState} from 'react';
import {Controller, Navigation, Thumbs} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react/swiper-react';
import {Swiper as SwiperClass} from 'swiper/types';
import {ReactComponent as EmptyVideo} from '../../../assets/empty-video.svg';
import {ReactComponent as EmptyAudio} from '../../../assets/empty-audio.svg';
import {ReactComponent as FileImage} from '../../../assets/close.svg';
import ArrowLeft from '../../../assets/arrowLeft.svg';
import {AssetImageItem} from './asset-image-item';
import {getFirstAssetImage} from '../../services/asset.service';
import './asset-gallery.css';
import {AssetVideoItem} from './asset-video-Item';
import {AssetAudioItem} from './asset-audio-item';
import {parseMediaAlternativeText} from '../../lib/media';
import clsx from 'clsx';
import {DownloadContentButton} from 'pages/NftPage/download-content-button';
import {ShareIcon} from '../ShareIcon';
import Share from '../Share';
import {getShareUrl} from '../Share/utils/get-share-url';
import {useLocalization} from '../../hooks/use-locale.hook';
import {NftCardInterface, CmsNftAsset} from '@cere/services-types';

const useStyles = makeStyles<Theme, {noBorder: boolean}>((theme) => ({
  container: {
    maxWidth: '100%',
    minWidth: '100%',
    minHeight: '300px',

    [theme.breakpoints.up('lg')]: {
      width: '508px',
    },
  },
  mainSwiper: {
    width: `calc(100vw - 32px)`,
    height: 'calc(50vw - 32px)',
    '--size': '343px',
    minHeight: 'var(--size)',
    minWidth: 'var(--size)',

    border: ({noBorder}) => (noBorder ? undefined : '2px solid #e0e0e7'),
    borderRadius: ({noBorder}) => (noBorder ? undefined : '12px'),
    backgroundColor: '#010001',

    [theme.breakpoints.up('md')]: {
      '--size': '328px',
      height: '328px',
      width: '328px',
      minHeight: 'var(--size)',
      minWidth: 'var(--size)',
    },
    [theme.breakpoints.up('lg')]: {
      height: '508px',
      width: '508px',
      '--size': '508px',
    },

    '& .arrow': {
      backgroundImage: `url(${ArrowLeft})`,
      background: 'rgba(248, 248, 250, 0.2)',
      backdropFilter: 'blur(22px)',
      borderRadius: '63px',
    },
  },
  mainSwiperItem: {
    maxWidth: '100vw',
    maxHeight: '508px',
    textAlign: 'center',
    verticalAlign: 'middle',

    '& .main-image': {
      '--size': '343px',
      objectFit: 'cover',
      width: `calc(100vw - 32px)`,
      height: `calc(100vw - 32px)`,
      [theme.breakpoints.up('md')]: {
        '--size': '328px',
        width: 'var(--size)',
        height: 'var(--size)',
      },
      [theme.breakpoints.up('lg')]: {
        '--size': '508px',
      },
    },
  },
  containerClassName: {
    zIndex: 3,
    position: 'absolute',
    left: '16px',
    bottom: '16px',
    background: 'rgba(22, 22, 22, 0.5)',
    backdropFilter: 'blur(22px)',
    borderRadius: '6px',
    padding: '6px',
    '& h3': {
      fontSize: '16px',
      [theme.breakpoints.up('lg')]: {
        fontSize: '18px',
      },
    },
  },
  downloadForm: {
    position: 'absolute',
    top: 301,
    right: 12,
    zIndex: 5,

    [theme.breakpoints.up('md')]: {
      top: 286,
      right: 'unset',
      bottom: 'unset',
      left: 286,
    },
    [theme.breakpoints.up('lg')]: {
      top: 'unset',
      right: 'unset',
      bottom: 12,
      left: 312,
    },
  },
  shareIcon: {
    position: 'absolute',
    top: 12,
    right: 12,
    zIndex: 5,
  },
}));

interface AssetGalleryParams {
  assets: CmsNftAsset[];
  noBorder?: boolean;
  counterTime?: string;
  nft?: NftCardInterface;
  qtyOwned?: number;
}

const AssetGallery = ({assets = [], noBorder = false, counterTime, nft, qtyOwned}: AssetGalleryParams) => {
  const {t, locale} = useLocalization();
  const classes = useStyles({noBorder});

  const [activeIndex, setActiveIndex] = useState<number | undefined>();
  const [thumbsSwiper, setThumbsSwiper] = useState<SwiperClass | undefined>();
  const isDesktop = useMediaQuery<Theme>((theme) => `${theme.breakpoints.up('lg')}`);
  const isMobile = useMediaQuery<Theme>((theme) => `${theme.breakpoints.down('sm')}`);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const handleClickShare = (event: SyntheticEvent) => {
    event.stopPropagation();
    event.preventDefault();
    setIsModalOpen((prev) => !prev);
  };

  return (
    <div className={classes.container}>
      <Swiper
        className={classes.mainSwiper}
        modules={[Controller, Navigation, Thumbs]}
        pagination={{
          el: '.swiper-pagination',
          type: 'bullets',
          clickable: true,
        }}
        navigation={{
          nextEl: '.arrow-right',
          prevEl: '.arrow-left',
        }}
        onSlideChange={(swiper: SwiperClass) => {
          setActiveIndex(swiper.activeIndex);
        }}
        controller={{control: thumbsSwiper}}
        thumbs={{swiper: thumbsSwiper}}
      >
        <div className="arrow arrow-right" />
        <div className="arrow arrow-left" />
        {assets.map((asset, index) => {
          const {brightcoveId, mime} = parseMediaAlternativeText(asset?.content?.alternativeText);

          return (
            <SwiperSlide key={`key=${index}`} className={classes.mainSwiperItem}>
              {({isActive}) => (
                <>
                  {!counterTime ? null : (
                    <Counter
                      withSec
                      startsAt={counterTime}
                      containerClassName={classes.containerClassName}
                      cellParametersClassName={classes.cellParamClassName}
                    />
                  )}
                  {nft && !!qtyOwned && qtyOwned > 0 && (
                    <Box className={classes.downloadForm}>
                      <DownloadContentButton nft={nft} />
                    </Box>
                  )}

                  <Box className={classes.shareIcon}>
                    <ShareIcon onClick={handleClickShare} />
                  </Box>

                  <Share
                    isOpen={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                    title={t('Share NFT')}
                    description={nft?.title || ''}
                    imgSrc={nft?.image}
                    url={getShareUrl(
                      `/${locale}/home/nft/${nft?.id}`,
                      nft?.title || '',
                      nft?.title || '',
                      nft?.image || '',
                    )}
                  />

                  {brightcoveId && mime?.includes('audio') && <AssetAudioItem audioId={brightcoveId} />}
                  {asset?.content && mime?.includes('video') && (
                    <AssetVideoItem src={asset?.content?.url} mime={mime} isActive={isActive} />
                  )}
                  {!brightcoveId && <AssetImageItem asset={asset} />}
                </>
              )}
            </SwiperSlide>
          );
        })}
      </Swiper>

      <Swiper
        style={{
          visibility: assets?.length <= 1 ? 'hidden' : 'visible',
          maxHeight: assets?.length <= 1 ? '0' : undefined,
        }}
        className="thumbs-swiper"
        modules={[Controller]}
        spaceBetween={!isDesktop ? (isMobile ? 7 : -6) : -5}
        cssMode={true}
        slidesPerView={'auto'}
        touchRatio={0.2}
        slideToClickedSlide={false}
        onInit={(swiper: SwiperClass) => {
          setActiveIndex(0);
          setThumbsSwiper(swiper);
        }}
        watchSlidesProgress={true}
        onClick={(swiper: SwiperClass) => {
          setActiveIndex(swiper.clickedIndex);
          setThumbsSwiper(swiper);
        }}
        freeMode={true}
      >
        {assets.map((asset, index) => {
          const {brightcoveId, mime} = parseMediaAlternativeText(asset?.content?.alternativeText);

          return (
            <SwiperSlide key={`key-${index}`} className={clsx('thumbs-swiper__item', classes.navigationWidth)}>
              {() => {
                const src = getFirstAssetImage(asset);
                const style = {
                  width: `${!isDesktop ? (isMobile ? '56px' : '44px') : '48px'}`,
                  height: `${!isDesktop ? (isMobile ? '56px' : '44px') : '48px'}`,
                  border: '2px solid',
                  borderRadius: '4px',
                  borderColor: `rgba(250, 12, 88, ${index === activeIndex ? '1' : '0'})`,
                };

                if (brightcoveId && mime?.includes('video')) {
                  return <EmptyVideo style={style} />;
                }

                if (brightcoveId && mime?.includes('audio')) {
                  return <EmptyAudio style={style} />;
                }

                if (src) {
                  return <Avatar alt={asset?.content?.alternativeText} variant="rounded" src={src} style={style} />;
                }

                return <FileImage style={style} />;
              }}
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};

export default AssetGallery;
