import {selectNftById} from '../../../redux/modules/nfts/selectors';
import {selectCurrentLocale} from '../../../redux/modules/localization/selectors';
import {generatePath} from 'react-router-dom';
import {ROUTES} from '../../../constants/routes';
import {Dispatch} from 'redux';
import {CloseActiveModalCommand} from '../../../redux/modules/modals/actions';
import {PlaceBidCommand} from '../../../redux/modules/auctions/actions';
import {connect} from 'react-redux';
import {BidFailedModal} from '../../shared/Modals/Bid/BidFailedModal';

type PlaceBidFailedModalProps = {
  nftId: string;
  price: number;
  sellerWalletAddress: string;
  message: string;
};

const mapStateToProps = (state: any, {nftId, price, message}: PlaceBidFailedModalProps) => {
  const nft = selectNftById(state, nftId);
  const locale = selectCurrentLocale(state);

  return {
    image: nft.image!,
    creatorName: nft.creatorName,
    title: nft.title,
    subTitle: message,
    nftLink: generatePath(ROUTES.NFT_PAGE, {nftId, locale}),
    userBid: price,
  };
};

const mapDispatchToProps = (dispatch: Dispatch, {nftId, price, sellerWalletAddress}: PlaceBidFailedModalProps) => ({
  onClose: () => dispatch(CloseActiveModalCommand.create()),
  tryAgain: () => dispatch(PlaceBidCommand.create(nftId, sellerWalletAddress, price)),
});

export const PlaceBidFailedModal = connect(mapStateToProps, mapDispatchToProps)(BidFailedModal);
