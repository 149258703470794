import {useCallback, useContext, useState} from 'react';
import {Button} from '@cere/rxb-template-ui-kit';
import {Box} from '@material-ui/core';

import clsx from 'clsx';
import {CmsExhibitionNft} from '../../../../shared/types/graphql';
import {SubscribeModal} from './SubcribeModal';
import analyticService, {AnalyticEventsEnum} from '../../../../shared/services/analytic.service';
import {SubscriptionTypeEnum} from '../../../../shared/types/subscription';
import {createSubscription} from '../../../../shared/services/subscription.service';
import {useNotifications} from '../../../../shared/hooks/use-notifications';
import {ExhibitContext} from '../../../../context/exhibition/exhibit-context';
import {ctaClassName} from './styled';
import {InterstitialEventPageBuyButton} from './InterstitialEventPageBuyButton';
import {NftContext} from '../../../../shared/components/Nft/nft-context';
import {useLocalization} from '../../../../shared/hooks/use-locale.hook';
import {ReactComponent as InfoIcon} from '../../../../assets/info.svg';
import {GoogleAnalyticsId} from 'analytics-ids';
import {useStyles as useInterstitialStyles} from './styled';

interface InterstitialEventPageButtonsProps {
  setSelectedNft: (nft: CmsExhibitionNft) => unknown;
  canBePurchased: boolean;
  handlePurchase: (nftId: CmsExhibitionNft, amount: number) => void;
  onClickStayUpdatedConfirmation?: () => void;
  handleOpenTicketInfoModal?: () => void;
}

export const InterstitialEventPageButtons = ({
  setSelectedNft,
  canBePurchased,
  handlePurchase,
  onClickStayUpdatedConfirmation,
  handleOpenTicketInfoModal,
}: InterstitialEventPageButtonsProps) => {
  const {t, locale} = useLocalization();
  const {event} = useContext(ExhibitContext);
  const [showSubscribeModal, setShowSubscribeModal] = useState(false);
  const {warning} = useNotifications();
  const {eventNft} = useContext(NftContext);

  const handleClickSubscribeButton = () => {
    setShowSubscribeModal(true);
    analyticService.track(AnalyticEventsEnum.INTERSTITIAL_SUBSCRIBE_CLICKED);
  };

  const handleSubscribeToExhibit = useCallback(
    async (userEmail: string) => {
      setShowSubscribeModal(false);
      if (event?.id) {
        await createSubscription(userEmail, SubscriptionTypeEnum.EXHIBIT, Number(event?.id), locale).catch((error) => {
          warning(t("Couldn't subscribe at the moment. Please, try again later, sorry for the inconvenience."));
          throw error;
        });
        if (onClickStayUpdatedConfirmation) {
          await onClickStayUpdatedConfirmation();
        }
        analyticService.track(AnalyticEventsEnum.SUBSCRIBE_EMAIL, {
          exhibitId: event?.id,
          subscribedAt: new Date().toLocaleString('en-US'),
        });
      }
    },
    [event?.id, warning, onClickStayUpdatedConfirmation, t, locale],
  );

  const styles = useInterstitialStyles();

  if (!eventNft) {
    return null;
  }

  return (
    <>
      <Box className={styles.buttonsBox}>
        <Button
          variant="outlined"
          size="large"
          title={t('Stay updated')}
          onClick={handleClickSubscribeButton}
          fullWidth
          className={clsx(ctaClassName, styles.ctaSubscribe)}
        >
          {t('Stay updated')}
        </Button>
        <InterstitialEventPageBuyButton
          setSelectedNft={setSelectedNft}
          canBePurchased={canBePurchased}
          handlePurchase={handlePurchase}
        />

        {handleOpenTicketInfoModal && (
          <Box className={clsx(styles.whatIsTicket, styles.hideMobile)} onClick={handleOpenTicketInfoModal}>
            <Box className={GoogleAnalyticsId.WhatIsTicketBtn}>{t('What is an NFT ticket?')}</Box>
            <Box ml="10px">
              <InfoIcon />
            </Box>
          </Box>
        )}
      </Box>
      {showSubscribeModal && (
        <SubscribeModal onClose={() => setShowSubscribeModal(!showSubscribeModal)} onClick={handleSubscribeToExhibit} />
      )}
    </>
  );
};
