import {FC, ReactElement, useContext, useState} from 'react';
import {Box, makeStyles, useTheme} from '@material-ui/core';
import {Typography, Button} from '@cere/rxb-template-ui-kit';
import useCountDown from 'react-countdown-hook';
import ReactCodeInput from '@cere/react-code-input';
import clsx from 'clsx';
import {noop} from 'lodash';
import {Trans} from 'react-i18next';
import {IKeys, resendOtpCodeByEmail, resendSocialOTP} from '../../../services/auth.service';
import colors from '../../../../styles/colors';
import AuthInput from '../AuthInput';
import {MessageWithButton} from '../../../../pages/SignInPage/message-with-button';
import analyticService, {AnalyticEventsEnum} from '../../../services/analytic.service';
import {UserContext} from '../../../../context/user-context/user-context';
import {useAuth} from '../../../hooks/auth.hook';
import {useLocalization} from '../../../hooks/use-locale.hook';

const useStyles = makeStyles((theme) => ({
  hyphen: {
    position: 'absolute',
    height: '2px',
    width: '6px',
    left: 'calc(50% - 3px)',
    top: '30px',

    '@media (max-width: 375px)': {
      display: 'none',
    },
  },
  textAccent: {
    color: theme.palette.secondary.main,
  },
  withTextAccent: {
    '& strong': {
      color: theme.palette.secondary.main,
    },
  },
  textError: {
    color: colors.error,
  },
  label: {
    color: colors.lightGrey,
    fontWeight: 500,
  },
  pointer: {
    cursor: 'pointer',
  },

  codeWrapperStyles: {
    position: 'relative',
    '&>input::-webkit-outer-spin-button,input::-webkit-inner-spin-button': {
      appearance: 'none',
      margin: 0,
    },
    '&>input:first-of-type': {
      marginLeft: '0 !important',
    },
    '&>input:last-of-type': {
      marginRight: '0 !important',
    },
    '&>input:focus': {
      border: `2px solid ${theme.palette.info.main} !important`,
    },
    '@media (min-width: 376px)': {
      '&>input:nth-of-type(3)': {
        border: '1px solid red',
        marginRight: '26px !important',
      },
    },
  },
  firefoxInputStyles: {
    '& input': {
      textAlign: 'center',
    },
    '&>input[type="number"]::-webkit-outer-spin-button,input[type="number"]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '&>input[type="number"]': {
      '-moz-appearance': 'textfield',
    },
  },
}));

const DIGITS_NUMBER = 6;

const getTime = (timeLeft: number) => Math.round(timeLeft / 1000);

interface VerifyProps {
  onVerify: (value: IKeys) => void;
  onFailResend?: () => void;
  email: string;
}

export const VerifyOTP: FC<VerifyProps> = ({email, onVerify = noop, onFailResend = noop}): ReactElement => {
  const {t} = useLocalization();
  const [error, setError] = useState<string>();
  const [secureCode, setSecureCode] = useState('');
  const [timeLeft, {start}] = useCountDown(Date.now());
  const classes = useStyles();
  const {verifyOtp} = useAuth();
  const theme = useTheme();

  const baseCodeInputStyle = {
    height: '56px',
    width: '44px',
    borderRadius: '16px',
    border: `1px solid ${colors.lightGrey}`,
    fontSize: '16px',
    textAlign: '-webkit-center',
    outline: 'none',
    margin: '0 2px',
    padding: '0',

    '&:focused': {
      borderColor: theme.palette.primary.main,
    },
  };

  const inputStyleInvalid = {
    ...baseCodeInputStyle,
    borderColor: colors.error,
  };

  const {socialUserData} = useContext(UserContext);

  const handleCodeChange = (value: string) => {
    analyticService.track(AnalyticEventsEnum.SIGN_IN_OTP_VALUE_CHANGED);

    if (!value) {
      setError('');
    }
    setSecureCode(value);
  };

  const handleCodeResend = async () => {
    analyticService.track(AnalyticEventsEnum.SIGN_IN_OTP_RESEND_CLICKED);

    start(60_000);

    try {
      if (socialUserData?.type) {
        await resendSocialOTP(socialUserData.type, socialUserData.token);
      } else {
        await resendOtpCodeByEmail(email);
      }
    } catch {
      onFailResend();
    }
  };

  const handleSubmit = async (): Promise<IKeys | void> => {
    analyticService.track(AnalyticEventsEnum.SIGN_IN_OTP_SUBMITTED);

    try {
      const keys = await verifyOtp(email, secureCode);

      onVerify(keys);
    } catch (error) {
      setError(t('Invalid code'));
    }
  };

  return (
    <>
      <AuthInput value={email} disabled />
      <Box mt="24px">
        <Typography variant="body2" className={classes.label}>
          {t('Verification code')}
        </Typography>
      </Box>
      <Box display="flex" position="relative" justifyContent="center" mt="8px">
        <ReactCodeInput
          name="OTP"
          type={'url' as any}
          inputMode="url"
          fields={DIGITS_NUMBER}
          filterChars={' ' as any}
          onChange={handleCodeChange}
          inputStyle={baseCodeInputStyle as any}
          inputStyleInvalid={inputStyleInvalid as any}
          isValid={!error}
          className={clsx(classes.codeWrapperStyles, classes.firefoxInputStyles)}
        />
        <Box className={classes.hyphen} bgcolor={!error ? colors.lightGrey : colors.error} />
      </Box>
      {!!error && (
        <Typography variant="caption1" className={classes.textError}>
          {error}
        </Typography>
      )}

      <Box width="100%" textAlign="center" my="24px">
        {timeLeft ? (
          <Typography variant="button1" className={classes.withTextAccent}>
            <Trans
              i18nKey="Resend verification code in <strong>{{timeLeft}}</strong> seconds"
              values={{timeLeft: getTime(timeLeft)}}
            />
          </Typography>
        ) : (
          <Box width="100%" onClick={handleCodeResend} className={classes.pointer}>
            <MessageWithButton text={t('Did not receive  a code?')} button={t('Resend code')} />
          </Box>
        )}
      </Box>

      <Box mb="24px">
        <Button
          color="secondary"
          variant="contained"
          size="large"
          fullWidth
          disabled={secureCode.length < DIGITS_NUMBER}
          onClick={handleSubmit}
        >
          {t('Verify')}
        </Button>
      </Box>
    </>
  );
};
