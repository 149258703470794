export enum AuthPageState {
  AUTH = 'AUTH',
  VERIFY = 'Verify email',
}

export type AuthType = 'Sign in' | 'Sign up';

export enum AuthText {
  SIGN_IN = 'Sign in',
  SIGN_UP = 'Sign up',
}

export enum AuthTypes {
  SIGN_IN = 'signin',
  SIGN_UP = 'signup',
}

export enum SubmittedAuthType {
  SIGN_IN = 'SIGN_IN',
  SIGN_UP = 'SIGN_UP',
}
