import {useCallback, useMemo, useEffect, useRef} from 'react';
import {Box, makeStyles} from '@material-ui/core';
import Countdown, {CountdownRenderProps} from 'react-countdown';

import {ReactComponent as HourglassIcon} from 'assets/hourglass.svg';
import {PendingTransactionTypes, usePendingTransactionsContext} from 'context/pending-transaction-context';
import {Typography} from 'shared/components/UI';
import {useTriggerState} from 'shared/hooks/use-trigger-state';
import colors from 'styles/colors';

const useStyles = makeStyles((theme) => ({
  root: {
    '--background': 'rgba(22, 22, 22, 0.5)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.up('lg')]: {
      width: '487px',
      height: '60px',
      flexDirection: 'row',
      padding: '10px 16px',
      backgroundColor: 'var(--background)',
      borderRadius: '6px',
    },
  },
  wrapper: {
    width: '262px',
    height: '44px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '6px 16px',
    backgroundColor: 'var(--background)',
    borderRadius: '6px',
    [theme.breakpoints.up('lg')]: {
      width: '100%',
      height: '100%',
      backgroundColor: 'unset',
      padding: 0,
    },
  },
  title: {
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '16px',
    color: colors.lighter,
    [theme.breakpoints.up('lg')]: {
      fontSize: '14px',
    },
  },
  countdown: {
    minWidth: '75px',
    marginLeft: '4px',
    fontWeight: 700,
    fontSize: '12px',
    lineHeight: '16px',
    color: '#42A4FF',
  },
  iconBox: {
    '--size': '40px',
    width: 'var(--size)',
    height: 'var(--size)',
    minWidth: 'var(--size)',
    minHeight: 'var(--size)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    marginBottom: '12px',
    backgroundColor: 'rgba(140, 140, 148, 0.5)',
    [theme.breakpoints.up('lg')]: {
      margin: '0 12px 0 0',
    },
  },
  icon: {
    fill: colors.lighter,
  },
}));

const TRANSACTION_PROCESSING_TIME = 225000; // 3.45 minutes

export const PendingTransaction = () => {
  const styles = useStyles();
  const {getPendingTransactionsByType} = usePendingTransactionsContext();
  const [shouldRefresh, onRefresh] = useTriggerState();

  const pendingBuyTransactions = useMemo(
    () => getPendingTransactionsByType(PendingTransactionTypes.BUY_NFT),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [shouldRefresh, getPendingTransactionsByType],
  ); // we need refresh pendingBuyTransactions when counting will finish

  const countUntil = useMemo(() => {
    const activeCounterTransactions = pendingBuyTransactions.filter(
      (transaction) =>
        transaction?.timestamp && new Date(transaction?.timestamp).getTime() + TRANSACTION_PROCESSING_TIME < Date.now(),
    );
    if (!activeCounterTransactions.length) {
      return 0;
    }
    return new Date(activeCounterTransactions[0].timestamp!).getTime() + TRANSACTION_PROCESSING_TIME;
  }, [pendingBuyTransactions]);

  const countdownRenderer = useCallback(
    ({hours, minutes, seconds}: CountdownRenderProps) => (
      <span className={styles.countdown}>
        {hours ? `${hours} hours` : ''} {minutes ? `${minutes} min` : ''} {seconds ? `${seconds} sec` : ''}
      </span>
    ),
    [styles.countdown],
  );

  let refreshTimer = useRef<ReturnType<typeof setTimeout> | null>(null);
  useEffect(() => {
    if (10 > 0) {
      refreshTimer.current && clearTimeout(refreshTimer.current);
      refreshTimer.current = setTimeout(() => {
        console.log('onRefresh');
        onRefresh();
      }, 5000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countUntil]);

  if ((Array.isArray(pendingBuyTransactions) && !pendingBuyTransactions.length) || !countUntil) {
    return null;
  }

  return (
    <Box className={styles.root}>
      <Box className={styles.iconBox}>
        <HourglassIcon className={styles.icon} />
      </Box>
      <Box className={styles.wrapper}>
        <Typography className={styles.title}>Approximate time for NFT delivery</Typography>
        <Countdown className={styles.countdown} date={countUntil} renderer={countdownRenderer} />
      </Box>
    </Box>
  );
};
