import io from 'socket.io-client';
import * as Sentry from '@sentry/react';
import {APP_ID, WEBSOCKET_SERVICE_URL} from '../../config/common';

export const connectToSocket = (userId: string): SocketIOClient.Socket => {
  const socket = io(WEBSOCKET_SERVICE_URL!, {
    query: {app_id: APP_ID(), user_id: userId},
    transports: ['websocket'],
  });

  socket.on('error', (error: Error) => Sentry.captureException(error));
  socket.on('connect_error', (error: Error) => Sentry.captureException(error));

  return socket;
};
