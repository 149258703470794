export const getPriceBidCaption = (isAuction: boolean, hasBids: boolean): string => {
  if (!isAuction) {
    return 'Price';
  }

  if (hasBids) {
    return '';
  }

  return 'Starting price';
};
