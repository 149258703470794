import {ComponentProps, FC, useContext} from 'react';
import {PurchaseNFTContextProvider} from '../../../context/purchase-nft-context';
import {PrimaryPurchaseContextProvider} from '../../../context/primary-purchase-context';
import {AuctionContextProvider} from '../../../context/auction.context';
import {useHistory, useLocation} from 'react-router-dom';
import {useLocalization} from '../../hooks/use-locale.hook';
import {UserContext} from '../../../context/user-context/user-context';

export function withMarketplacePurchaseContext<T>(Component: FC<T>) {
  return (props: ComponentProps<typeof Component>) => (
    <PurchaseNFTContextProvider>
      <Component {...props} />
    </PurchaseNFTContextProvider>
  );
}

export function withPrimaryPurchaseContext<T>(Component: FC<T>) {
  return (props: ComponentProps<typeof Component>) => (
    <PrimaryPurchaseContextProvider>
      <Component {...props} />
    </PrimaryPurchaseContextProvider>
  );
}

export function withAuctionContext<T>(Component: FC<T>) {
  return (props: ComponentProps<typeof Component>) => (
    <AuctionContextProvider>
      <Component {...props} />
    </AuctionContextProvider>
  );
}

export function withSellContext<T>(Component: FC<T>) {
  return (props: ComponentProps<typeof Component>) => <Component {...props} />;
}

export function withCheckBeforePurchase<T>(Component: FC<T>) {
  return (props: ComponentProps<typeof Component> & {nftRelType: string; nftEventId: string; nftId: string}) => {
    const {locale} = useLocalization();
    const {userData} = useContext(UserContext);
    const history = useHistory();
    const location = useLocation();

    const checkBeforePurchase = () => {
      const nftId = props.nftId;

      if (!userData?.token && !location.pathname.includes(`/home/auth`)) {
        history.push(`/${locale}/home/auth?nftId=${nftId}`, {pathname: window.location.pathname});
      } else {
        if (userData?.userEmail && typeof userData?.userEmail === 'string') {
          return userData?.userEmail;
        }
        throw Error(`Unknown response ${userData?.userEmail}`);
      }
    };

    return <Component {...props} checkBeforePurchase={checkBeforePurchase} />;
  };
}
