import {FC, MouseEventHandler, ReactElement, useCallback, useContext} from 'react';
import {Box, LinearProgress} from '@material-ui/core';
import {isEmpty} from 'lodash';
import {makeStyles} from '@material-ui/styles';
import {Button} from '@cere/rxb-template-ui-kit';
import {ReactComponent as BackButtonPic} from '../../../../assets/arrow.svg';
import {ReactComponent as InfoFilled} from '../../../../assets/icons/info-filled.svg';
import colors from '../../../../styles/colors';
import {UserContext} from '../../../../context/user-context/user-context';
import {Condition, ConditionsList} from '../../Conditions';
import {ExhibitContext} from '../../../../context/exhibition/exhibit-context';
import analyticService, {AnalyticEventsEnum} from '../../../services/analytic.service';
import {useLocalization} from '../../../hooks/use-locale.hook';

interface OverlayNavProps {
  onClickBack: MouseEventHandler<unknown>;
  about: boolean;
  setAbout: (value: boolean) => unknown;
  selectedVideo: number;
  videoProgress: number;
  showOverlay: boolean;
}

const useStyles = makeStyles({
  nav: {
    padding: '8px 24px 8px 40px',
    position: 'fixed',
    zIndex: 3,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    background: 'rgba(22, 22, 22, 0.6)',
    backdropFilter: 'blur(22px)',

    '@media (max-width:768px)': {
      padding: '8px 16px 8px 15px',
      background: 'rgba(22, 22, 22, 0.2)',
    },
  },
  backWrapper: {
    display: 'flex',
    height: '36px',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
  },
  back: {
    background: 'none',
    stroke: colors.light,
    cursor: 'pointer',
    transform: 'rotate(90deg)',
    width: '20px',
    height: '12px',
    padding: 0,
  },
  progressWrapper: {
    position: 'absolute',
    top: '60px',
    width: '100%',
    padding: '0 14px',
    display: 'flex',
    zIndex: 10,
  },
  infoButton: {
    background: 'rgba(248, 248, 250, 0.36)',
    backdropFilter: 'blur(22px)',
    color: colors.light,
    padding: '2px 10px',
  },
});

const useProgressStyles = makeStyles({
  root: {
    background: 'rgba(255, 255, 255, 0.36)',
    borderRadius: '1px',
    height: '2px',
  },
  bar: {
    background: '#ffffff',
  },
});

const getVideoProgress = (i: number, selected: number, progress: number) => {
  if (i < selected) return 100;
  if (i > selected) return 0;
  return progress;
};

export const OverlayNav: FC<OverlayNavProps> = ({
  onClickBack,
  videoProgress,
  selectedVideo,
  setAbout,
  about,
}): ReactElement => {
  const {t} = useLocalization();
  const classes = useStyles();
  const progressClasses = useProgressStyles();
  const {event} = useContext(ExhibitContext);

  const {setIsExhibitFirstOpen} = useContext(UserContext);

  const artist = event?.cmsCreator;

  const onClickHandle = (e: any) => {
    setIsExhibitFirstOpen(false);
    onClickBack(e);
  };

  const showClick = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();
      analyticService.track(AnalyticEventsEnum.EXHIBIT_PLAYER_ABOUT_CLICKED);
      setAbout(true);
    },
    [setAbout],
  );

  if (!event || !artist) {
    return <></>;
  }

  return (
    <>
      <Box className={classes.nav}>
        <Box className={classes.backWrapper} onClick={onClickHandle}>
          <BackButtonPic className={classes.back} />
        </Box>
        <Button className={classes.infoButton} startIcon={<InfoFilled />} onClick={showClick}>
          {t('About exhibit')}
        </Button>
      </Box>
      <ConditionsList multiply>
        <Condition condition={about && !isEmpty(event.assets)}>
          <Box className={classes.progressWrapper}>
            {event.assets.map((_, i) => (
              <Box mx="2px" key={`progress-${i}`} flex="0 1 auto" width="100%">
                <LinearProgress
                  classes={progressClasses}
                  variant="determinate"
                  value={getVideoProgress(i, selectedVideo, videoProgress)}
                />
              </Box>
            ))}
          </Box>
        </Condition>
      </ConditionsList>
    </>
  );
};
