import {memo, ReactElement} from 'react';
import {Box, Typography, makeStyles} from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  titleBox: {
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
  },
  title: {
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '22px',
    color: theme.palette.grey[700],
  },
  subTitle: {
    fontSize: '14px',
    lineHeight: '22px',
    color: theme.palette.grey[700],
  },
  rightBlock: {
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
  },
  value: {
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '22px',
    color: theme.palette.text.primary,
  },
  subValue: {
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '22px',
    color: theme.palette.grey[700],
  },
}));

type Props = {
  title: string;
  subTitle?: string;
  value?: string;
  subValue?: string;
  children?: ReactElement;
  classes?: Partial<Record<'title', string>>;
};

export const NftModalValueBlock = memo(({title, subTitle, value, subValue, children, classes}: Props) => {
  const styles = useStyles();

  return (
    <Box className={styles.root}>
      <Box className={styles.titleBox}>
        <Typography className={clsx(styles.title, classes?.title)}>{title}</Typography>
        <Typography className={styles.subTitle}>{subTitle}</Typography>
      </Box>

      <Box className={styles.rightBlock}>
        {!children && value && <Typography className={styles.value}>{value}</Typography>}
        {!children && subValue && <Typography className={styles.subValue}>{subValue}</Typography>}
        {children}
      </Box>
    </Box>
  );
});
