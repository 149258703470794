import {useContext, useMemo} from 'react';

import {ExhibitContext} from 'context/exhibition/exhibit-context';
import {EventTimelineStatus} from 'shared/types/event';

import {getTimelineStatus} from '../../../Сms/Exhibit/utils';

export function useIsLiveThemeApplied(): boolean {
  const {event} = useContext(ExhibitContext);
  const timelineStatus = useMemo(() => getTimelineStatus(event), [event]);
  return timelineStatus === EventTimelineStatus.STARTED;
}
