import {useCallback, useContext, useEffect} from 'react';
import {UserContext} from '../../context/user-context/user-context';
import {dispatchError} from '../lib/error-handler';
import {
  CLOSED_BY_USER_ERROR,
  getPrivatePublicKeysBySocial,
  getSocialTokenAndTypeByProvider,
  IKeys,
  isUnverifiedEmail,
  signInWithSocial,
  SocialNetworks,
  confirmSocialEmail,
  fetchWallet,
  getPrivatePublicKeysByEmail,
  signInOrAttachEmail,
} from '../services/auth.service';
import {useHistory} from 'react-router-dom';
import analyticService, {AnalyticEventsEnum} from '../services/analytic.service';
import {useIdentifyAnalyticsWithUtmParams} from './use-identify-analytics-with-utm-params';
import {LOCAL_STORAGE_KEY_TOKEN, LOCAL_STORAGE_SELECTED_WALLET} from '../../const/storage-keys';
import {AppWallet} from '../types/supported-wallet';
import {disconnect as walletConnectDisconnect} from '../services/wallet-connect';
import {useLocalization} from './use-locale.hook';

interface UseAuthResult {
  handleEmailSignUp: (userEmail: string) => Promise<unknown>;
  handleSocialSignIn: (
    social: SocialNetworks,
    successCallback: Function,
    showOtpCallback: Function,
  ) => Promise<string | void>;
  setAuthData: (userEmail: string, publicKey: string, token: string) => void;
  clearAuthData: () => void;
  verifyOtp: (email: string, otp: string) => Promise<IKeys>;
}

export const useAuth = (): UseAuthResult => {
  const {t, locale} = useLocalization();
  const {setUserData, setSelectedWallet, setSocialUserData, userData, socialUserData} = useContext(UserContext);

  const identifyAnalyticsWithUtm = useIdentifyAnalyticsWithUtmParams(userData.token);

  const clearAuthData = useCallback(() => {
    localStorage.removeItem('userEmail');
    localStorage.removeItem('userPrivateKey');
    localStorage.removeItem('userPublicKey');
    localStorage.removeItem('selectedWallet');
    localStorage.removeItem(LOCAL_STORAGE_KEY_TOKEN);
    localStorage.removeItem('isSellExplainerNotificationOpen');

    walletConnectDisconnect();
    setUserData({});
    localStorage.setItem('isSellExplainerNotificationOpen', '');
  }, [setUserData]);

  const setAuthData = useCallback(
    (userEmail: string, publicKey: string, token: string) => {
      localStorage.setItem('userEmail', userEmail);
      localStorage.setItem('userPublicKey', String(publicKey).toLowerCase());
      localStorage.setItem(LOCAL_STORAGE_KEY_TOKEN, token);
      localStorage.setItem(LOCAL_STORAGE_SELECTED_WALLET, AppWallet.DAVINCI);
      localStorage.setItem('isSellExplainerNotificationOpen', 'true');
      analyticService.track(AnalyticEventsEnum.USER_AUTHENTICATED, {email: userEmail});

      setUserData({
        userEmail: userEmail,
        userPublicKey: localStorage.getItem('userPublicKey'),
        token: localStorage.getItem(LOCAL_STORAGE_KEY_TOKEN),
      });

      setSelectedWallet(localStorage.getItem(LOCAL_STORAGE_SELECTED_WALLET));
    },
    [setUserData, setSelectedWallet],
  );

  const handleEmailSignUp = useCallback(
    (userEmail: string) => {
      const createResult = signInOrAttachEmail(userEmail, locale);
      identifyAnalyticsWithUtm(userEmail);
      return createResult;
    },
    [identifyAnalyticsWithUtm, locale],
  );

  const handleSocialSignIn = useCallback(
    async (social: SocialNetworks, successCallback: Function, showOtpCallback: Function): Promise<string | void> => {
      try {
        const {user, credential} = await signInWithSocial(social);

        try {
          const {publicKey, token} = await getPrivatePublicKeysBySocial(credential);
          setAuthData(user.userEmail, publicKey, token);
          identifyAnalyticsWithUtm(user.userEmail);
          successCallback(user.userEmail);
        } catch (e) {
          if (isUnverifiedEmail(JSON.parse(e.message).status)) {
            const {type, token} = await getSocialTokenAndTypeByProvider(credential);

            const socialData = {
              type,
              token,
              email: user.userEmail,
            };
            setSocialUserData(socialData);
            showOtpCallback(socialData);
          } else {
            throw new Error(e);
          }
        }
      } catch (err) {
        if (!err.message.includes(CLOSED_BY_USER_ERROR)) {
          dispatchError(t('Something went wrong'));
        }
        throw err;
      }
    },
    [identifyAnalyticsWithUtm, setAuthData, setSocialUserData, t],
  );

  const verifyOtp = useCallback(
    async (email: string, otp: string): Promise<IKeys> => {
      let keys: IKeys;

      if (socialUserData?.type) {
        await confirmSocialEmail(socialUserData.type, socialUserData.token, otp);
        keys = await fetchWallet({
          type: socialUserData.type,
          token: socialUserData.token,
        });
        // do not keep user social data after the verification
        setSocialUserData(undefined);
      } else {
        keys = await getPrivatePublicKeysByEmail(email, otp);
      }

      return keys;
    },
    [socialUserData, setSocialUserData],
  );

  return {
    handleEmailSignUp,
    handleSocialSignIn,
    setAuthData,
    clearAuthData,
    verifyOtp,
  };
};

export const useAuthorized = () => {
  const history = useHistory();
  const {locale} = useLocalization();
  const {userData} = useContext(UserContext);

  useEffect(() => {
    if (!userData?.userEmail || !userData?.userPublicKey) {
      history.replace(`/${locale}/home/auth`);
    }
  }, [userData, history, locale]);
};
