import {cereToUSD} from '../lib/formatPrice';
import {
  BID_STATUSES,
  CmsExhibitionNft,
  CmsExhibitionNftRelType,
  CreatorAuction,
  CreatorAuctionBid,
  CreatorAuctionBidWithStatus,
  isCmsExhibitionNftReady,
} from '../types/graphql';
import {getStorageItem, setStorageItem} from './storage-service';
import {sortBids} from '../helpers/auction';
import {BidInterface} from '@cere/services-types/dist/types';

const BIDS_KEY = 'BIDS';

export const isAuctionHasBids = (auction: CreatorAuction | undefined) =>
  auction ? auction.creator_auction_bids.length > 0 : false;

export const getPriceForNft = (
  nftWithEventRelation: CmsExhibitionNft,
  auction: CreatorAuction,
  exchangeRate: number,
): number => {
  if (!nftWithEventRelation || !isCmsExhibitionNftReady(nftWithEventRelation)) {
    return 0;
  }

  if (nftWithEventRelation.relType === CmsExhibitionNftRelType.AUCTIONED) {
    return getNextBidAmount(auction, exchangeRate);
  }

  if (nftWithEventRelation.relType === CmsExhibitionNftRelType.LIMITED) {
    return cereToUSD(nftWithEventRelation.cmsNft?.creatorNft.creator_make_offer?.[0]?.price ?? 0, exchangeRate);
  }

  return 0;
};

export const getNextBidAmount = (auction: CreatorAuction | undefined, exchangeRate: number) => {
  if (auction) {
    return cereToUSD(auction.price, exchangeRate);
  }

  return 0;
};

export const storeProcessingBid = (hash: string, nftId: string | number, bidData: CreatorAuctionBid) => {
  const processingNfts: Record<string, Record<string, CreatorAuctionBid>> = getStorageItem(BIDS_KEY);

  const map = new Map(Object.entries(processingNfts[nftId] || {}));
  if (map.has(hash)) {
    return;
  }

  map.set(hash, bidData);
  setStorageItem(BIDS_KEY, {...processingNfts, [nftId]: Object.fromEntries(map.entries())});
};

export const removeProcessingBid = (hash: string | undefined, nftId: string | undefined) => {
  const processingNfts: Record<string, Record<string, CreatorAuctionBid>> = getStorageItem(BIDS_KEY);

  const map = new Map(Object.entries(processingNfts[nftId ?? ''] || {}));
  map.delete(hash ?? '');

  if (!map.size) {
    delete processingNfts[nftId ?? ''];
  }

  setStorageItem(BIDS_KEY, {...processingNfts});
};

export const getBidHistoryStatus = (
  nftId: string | undefined,
  bids: CreatorAuctionBid[] | BidInterface[],
): CreatorAuctionBidWithStatus[] => {
  const processingNfts: Record<string, Record<string, CreatorAuctionBid>> = getStorageItem(BIDS_KEY);
  const processingBids = processingNfts![nftId ?? ''] || {};

  return sortBids([
    ...Object.values(processingBids).map((bid) => ({...bid, bidStatus: BID_STATUSES.PENDING})),
    ...bids.map((bid) => ({...bid, bidStatus: BID_STATUSES.COMPLETED})),
  ]);
};
