import {createTheme} from '@material-ui/core/styles';
import colors from '../../styles/colors';
import {useMemo} from 'react';
import {Theme} from '@material-ui/core';
import {AppConfig} from '../types/app-config';

const values: {[key: string]: number} = {
  xs: 310,
  sm: 440,
  md: 700,
  lg: 1280,
  xl: 1920,
};

export const useCustomTheme = (appConfig: AppConfig) => {
  const themeParams = {
    primaryMainColor: appConfig.primaryMainColor || colors.primaryDark,
    primaryLightColor: colors.light,
    secondaryMainColor: appConfig.secondaryMainColor || colors.accent,
    secondaryDarkColor: appConfig.secondaryDarkColor || colors.accentDark,
    buttonContainedPrimaryFirstColor: appConfig.buttonContainedPrimaryFirstColor || colors.accent,
    buttonContainedPrimarySecondColor: appConfig.buttonContainedPrimarySecondColor || colors.buttonSecondary,
    buttonContainedSecondaryColor: appConfig.buttonContainedSecondaryColor || colors.primaryDark,
    infoMainColor: appConfig.infoMainColor || colors.cyan,
    backgroundPaperColor: appConfig.backgroundPaperColor || colors.snowWhite,
    backgroundDefaultColor: appConfig.backgroundDefaultColor || colors.white,
    grey100Color: appConfig.grey100Color || colors.footer,
    grey700Color: appConfig.grey700Color || colors.lightGrey,
    purple: colors.purple,
  };

  const theme: Theme = useMemo(
    () =>
      createTheme({
        palette: {
          primary: {
            main: themeParams.primaryMainColor,
            light: themeParams.primaryLightColor,
          },
          secondary: {
            main: themeParams.secondaryMainColor,
            dark: themeParams.secondaryDarkColor,
          },
          info: {
            main: themeParams.infoMainColor,
            contrastText: themeParams.purple,
          },
          background: {
            paper: themeParams.backgroundPaperColor,
            default: themeParams.backgroundDefaultColor,
          },
          grey: {
            700: themeParams.grey700Color,
            100: themeParams.grey100Color,
          },
        },
        overrides: {
          MuiButton: {
            contained: {
              boxShadow: 'none',
            },
            containedPrimary: {
              background: `linear-gradient(277.13deg, ${themeParams.buttonContainedPrimaryFirstColor} 11.98%, ${themeParams.buttonContainedPrimarySecondColor} 83.77%)`,
              color: colors.light,
              borderRadius: '50px',
              textTransform: 'none',
              fontWeight: 600,
              padding: '8px 16px 8px 16px',
              '&.Mui-disabled': {
                color: `${colors.light} !important`,
                background: colors.disable,
              },
            },
            containedSecondary: {
              backgroundColor: themeParams.buttonContainedSecondaryColor,
              border: `1px solid ${colors.primaryDark}`,
              color: colors.light,
              borderRadius: '50px',
              textTransform: 'none',
              fontWeight: 600,
              padding: '8px 16px 8px 16px',
              '&.Mui-disabled': {
                color: colors.light,
                background: colors.disable,
                border: `1px solid ${colors.disable}`,
              },
              '&:hover': {
                backgroundColor: `${themeParams.buttonContainedSecondaryColor} !important`,
                border: `1px solid ${colors.primaryDark}`,
                color: colors.light,
              },
            },
            root: {
              borderRadius: '50px',
              '&> *': {
                pointerEvents: 'none',
              },
            },
            sizeSmall: {
              minHeight: '36px',
            },
            sizeLarge: {
              minHeight: '48px',
              padding: '10px 16px',
            },
          },
        },
        typography: {
          fontFamily: 'Inter, Roboto, Helvetica, Arial, sans-serif',
          h1: {
            fontSize: '32px',
            fontWeight: 800,
            fontStyle: 'normal',
          },
          h2: {
            fontSize: '28px',
            fontWeight: 800,
            fontStyle: 'normal',
          },
          h3: {
            fontSize: '20px',
            fontWeight: 'bold',
            fontStyle: 'normal',
          },
          h4: {
            fontSize: '16px',
            fontWeight: 600,
            fontStyle: 'normal',
          },
          h5: {
            fontSize: '14px',
            fontWeight: 600,
            fontStyle: 'normal',
          },
          body1: {
            fontSize: '16px',
            fontWeight: 'normal',
            fontStyle: 'normal',
          },
          body2: {
            fontSize: '14px',
            fontWeight: 'normal',
            fontStyle: 'normal',
          },
          caption: {
            fontSize: '12px',
            fontWeight: 'normal',
            fontStyle: 'normal',
          },
          button: {fontSize: '14px', fontWeight: 600, fontStyle: 'normal'},
        },
        breakpoints: {
          keys: ['xs', 'sm', 'md', 'lg', 'xl'],
          down: (key) => `@media (max-width:${values[key]}px)`,
          up: (key) => `@media (min-width:${values[key] + 1}px)`,
        },
      }),
    [
      themeParams.buttonContainedPrimarySecondColor,
      themeParams.buttonContainedPrimaryFirstColor,
      themeParams.primaryMainColor,
      themeParams.primaryLightColor,
      themeParams.secondaryMainColor,
      themeParams.buttonContainedSecondaryColor,
      themeParams.secondaryDarkColor,
      themeParams.infoMainColor,
      themeParams.backgroundPaperColor,
      themeParams.backgroundDefaultColor,
      themeParams.grey700Color,
      themeParams.grey100Color,
      themeParams.purple,
    ],
  );

  return {
    theme,
  };
};
