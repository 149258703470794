import {useMediaQuery, useTheme, Box, Grid, GridSize, makeStyles} from '@material-ui/core';

import {Title} from '../Title';
import {NftCardSkeleton} from './NftCardSkeleton';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: '28px',
  },
  gridBox: {
    paddingTop: '24px',
    width: '343px',
    margin: '0 auto',

    [theme.breakpoints.up('md')]: {
      width: '688px',
    },
    [theme.breakpoints.up('lg')]: {
      width: '1264px',
    },
  },
}));

type Props = {
  title?: string;
};

export const NftsListSkeleton = ({title}: Props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isTable = useMediaQuery(theme.breakpoints.down('lg'));
  const sceletonsCount = isMobile ? 1 : isTable ? 2 : 3;

  const styles = useStyles();

  return (
    <Box className={styles.root}>
      {title && <Title>{title}</Title>}
      <Grid container spacing={2} className={styles.gridBox}>
        {Array(sceletonsCount)
          .fill(0)
          .map((_, index) => (
            <Grid item xs={(12 / sceletonsCount) as GridSize}>
              <NftCardSkeleton key={index} />
            </Grid>
          ))}
      </Grid>
    </Box>
  );
};
