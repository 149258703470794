import {useCallback, useRef} from 'react';
import {Box, Button, Divider, Typography, CardMedia, makeStyles} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import clsx from 'clsx';
import {useHistory} from 'react-router-dom';
import {PaymentTypeEnum} from '@cere/services-types';
import {ReactComponent as ArrowIcon} from '../../../assets/icons/arrow_right.svg';
import {PurchaseModal} from '../PurchaseModal';
import colors from '../../../styles/colors';
import {CopyButton} from '../CopyButton';
import AverageNftPaymentCountdown from '../average-payment-countdown/average-payment-countdown.component';
import {TOKEN_TITLE} from '../../lib/formatPrice';
import {mobileLandscapeMediaQuery} from '../../../styles/mediaQueries';
import {useLocalization} from '../../hooks/use-locale.hook';
import {ExplorerLink} from '../Explorer';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: '22px',
    [theme.breakpoints.up('md')]: {
      paddingTop: '32px',
    },
    [mobileLandscapeMediaQuery(theme)]: {
      display: 'flex',
      gap: '20px',
    },
  },
  dataBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    [mobileLandscapeMediaQuery(theme)]: {
      width: '50%',
    },
  },
  descriptionWrapper: {
    margin: '8px 0 24px 0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [mobileLandscapeMediaQuery(theme)]: {
      flexDirection: 'row',
      justifyContent: 'flex-start',
    },
  },
  description: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    textAlign: 'center',
    paddingTop: '8px',
    fontFamily: 'inherit',
    letterSpacing: 'inherit',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '16px',
    [theme.breakpoints.up('md')]: {
      paddingTop: '24px',
    },
    [mobileLandscapeMediaQuery(theme)]: {
      justifyContent: 'flex-end',
    },
  },
  button: {
    width: '311px',
    height: '36px',
    borderRadius: '50px',
    border: `1px solid ${colors.primaryDark}`,
    textTransform: 'none',
    [theme.breakpoints.up('md')]: {
      height: '44px',
    },
    [mobileLandscapeMediaQuery(theme)]: {
      height: '36px',
    },
  },
  link: {
    textDecoration: 'none',
  },
  icon: {
    '--size': '18px',
    height: 'var(--size)',
    width: 'var(--size)',
    color: theme.palette.info.main,
  },
  dataLabel: {
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '22px',
    color: colors.grey,
    marginBottom: '4px',
    fontFamily: 'inherit',
    letterSpacing: 'inherit',
  },
  dataText: {
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '22px',
    color: colors.primaryDark,
    marginBottom: '4px',
    maxWidth: '325px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontFamily: 'inherit',
    letterSpacing: 'inherit',
  },
  dataContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  copyButtonContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& button, & button svg': {
      width: '20px',
      height: '20px',
    },
  },
  dataLine: {
    marginBottom: '18px',
  },
  totalAmount: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    '& p': {
      margin: 0,
    },
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  arrowIcon: {
    marginTop: '-3px',
  },
  cardIcon: {
    width: '50px',
    height: '50px',
    borderRadius: '6px',
  },
  divider: {
    marginTop: '10px',
  },
  countdown: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',
    color: colors.primaryDark,
    fontFamily: 'inherit',
    letterSpacing: 'inherit',

    '& span': {
      color: colors.blue,
    },

    [theme.breakpoints.up('md')]: {
      fontSize: '16px',
      lineHeight: '22px',
    },

    [mobileLandscapeMediaQuery(theme)]: {
      fontSize: '14px',
      lineHeight: '20px',
    },
  },
}));

const PURCHASE_TRANSACTION_PROCESSING_TIME = 225000; // 3.45 minutes

export interface NFTPurchaseConfirmedModalProps {
  nftId: string;
  nftTitle: string;
  nftAddress: string;
  nftImage?: string;
  price: number;
  author?: string;
  quantity: number;
  open: boolean;
  onClose: () => void;
}

export const NFTPurchaseConfirmedModal = ({
  nftId,
  nftTitle,
  nftAddress,
  nftImage,
  // TODO remove if author will not used
  // author,
  price,
  quantity,
  open,
  onClose,
}: NFTPurchaseConfirmedModalProps) => {
  const {t, locale} = useLocalization();
  const styles = useStyles();
  const nftIdRef = useRef<HTMLElement | null>(null);
  const history = useHistory();
  const onViewNft = useCallback(() => {
    history.push(`/${locale}/home/nft/${nftId}`);
    onClose && onClose();
  }, [nftId, history, onClose, locale]);

  return (
    <PurchaseModal
      open={open}
      icon={<CheckCircleIcon className={styles.icon} />}
      title={t('Purchase confirmed')}
      onClose={onClose}
    >
      <Box className={styles.descriptionWrapper}>
        <Typography className={styles.description}>{t('Thank you for your purchase.')}</Typography>
        <Typography className={styles.description}>
          {t('You will be notified when your transaction is completed.')}
        </Typography>
      </Box>
      <Box className={styles.root}>
        <Box className={styles.dataBox}>
          <Box className={clsx(styles.dataContainer, styles.dataLine)}>
            <AverageNftPaymentCountdown
              className={styles.countdown}
              text={'Approximate time for NFT delivery'}
              type={PaymentTypeEnum.USD_TO_NFT}
              averagePaymentTime={PURCHASE_TRANSACTION_PROCESSING_TIME}
            />
          </Box>

          <Box className={clsx(styles.dataContainer, styles.dataLine)}>
            <Box className={styles.column}>
              <Typography className={styles.dataLabel}>{t('Pending status')}</Typography>
              <Typography className={styles.dataText}>
                {t('NFT transaction processing')} <ArrowIcon className={styles.arrowIcon} />
              </Typography>
            </Box>
          </Box>
          <Box className={clsx(styles.dataContainer, styles.dataLine)}>
            <Box className={styles.dataContainer}>
              <Box>
                <Typography className={styles.dataLabel}>NFT Name</Typography>
                <Typography className={styles.dataText}>{nftTitle}</Typography>
              </Box>
              <Box>
                <CardMedia image={nftImage} className={styles.cardIcon} />
              </Box>
            </Box>
          </Box>
          <Box className={clsx(styles.dataContainer, styles.dataLine)}>
            <Box className={styles.column}>
              <Typography className={styles.dataLabel}>{t('NFT ID')}</Typography>
              <Box className={styles.copyButtonContainer}>
                <Typography className={styles.dataText} ref={nftIdRef}>
                  {nftAddress}
                </Typography>
                <CopyButton node={nftIdRef} />
              </Box>
              <Divider className={styles.divider} />
            </Box>
          </Box>
        </Box>

        <Box className={styles.dataBox}>
          <Box className={clsx(styles.column, styles.dataLine)}>
            <Box className={styles.dataContainer}>
              <Typography className={styles.dataLabel}>{t('Quantity')}</Typography>
              <Typography className={styles.dataText}>{quantity}</Typography>
            </Box>
            <Divider className={styles.divider} />
          </Box>
          <Box className={clsx(styles.column, styles.dataLine)}>
            <Box className={styles.dataContainer}>
              <Typography className={styles.dataLabel}>{t('Price')}</Typography>
              <Typography className={styles.dataText}>{`${price} ${TOKEN_TITLE}`}</Typography>
            </Box>
            <Divider className={styles.divider} />
          </Box>
          <Box className={styles.column}>
            <Box className={styles.dataContainer}>
              <Typography className={styles.dataLabel}>{t('Total amount')}</Typography>
              <Typography className={clsx(styles.dataText, styles.totalAmount)}>
                {`${price * quantity} ${TOKEN_TITLE}`}{' '}
                <Typography className={styles.dataLabel}>
                  (~{price * quantity}
                  {' USD'})
                </Typography>
              </Typography>
            </Box>
            <Divider className={styles.divider} />
          </Box>
        </Box>
      </Box>
      <Box mt="12px">
        <ExplorerLink />
      </Box>
      <Box className={styles.buttonContainer}>
        <Button className={styles.button} onClick={onViewNft}>
          {t('View NFT')}
        </Button>
      </Box>
    </PurchaseModal>
  );
};
