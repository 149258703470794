import {QueryResult, useLazyQuery, useQuery} from '@apollo/client';
import {useCallback, useContext, useMemo} from 'react';
import {UserContext} from '../../context/user-context/user-context';
import {CmsNft} from '../types/graphql';
import {GET_NFT} from '../queries/nfts';
import {getAvailableNFTQuantity} from '../helpers/nfts';
import {cereToUSD} from '../lib/formatPrice';
import {Numberish} from '../types/numberish';
import {useExchangeRate} from './use-exchange-rate';

type NFTData = {
  nftData?: CmsNft;
  qtyOwned: number;
  price: number;
  loading: boolean;
};

type QueryData = {
  cmsNft: CmsNft;
};

type Variables = {
  id?: Numberish;
};

const getPrice = (data: QueryData | undefined, unitsPerPenny: number) => {
  if (!data) {
    return 1;
  }

  const creatorOffer = data.cmsNft?.creatorNft?.creator_make_offer ?? [];

  if (!creatorOffer[0]) {
    return 1;
  }
  return cereToUSD(creatorOffer[0].price, unitsPerPenny);
};

const createResultMapper =
  (wallets: string[], unitsPerPenny: number) =>
  ({data, loading, previousData}: QueryResult<QueryData, Variables>): NFTData => ({
    loading: loading && !previousData,
    nftData: data?.cmsNft || previousData?.cmsNft,
    qtyOwned: getAvailableNFTQuantity(data?.cmsNft!, wallets),
    price: getPrice(data, unitsPerPenny),
  });

const useWallets = () => {
  const {nonCustodyWallets, userData} = useContext(UserContext);

  return useMemo(
    () => [userData.userPublicKey!, ...nonCustodyWallets.map(({publicKey}) => publicKey)].filter(Boolean),
    [nonCustodyWallets, userData.userPublicKey],
  );
};

export const useLazyNftData = () => {
  const wallets = useWallets();
  const unitsPerPenny = useExchangeRate();
  const mapResult = useMemo(() => createResultMapper(wallets, unitsPerPenny), [unitsPerPenny, wallets]);
  const [doQuery, queryResult] = useLazyQuery<QueryData, Variables>(GET_NFT);

  const loadData = useCallback((id: Numberish) => doQuery({variables: {id}}).then(mapResult), [doQuery, mapResult]);

  return [loadData, mapResult(queryResult)] as const;
};

export const useNftData = (id: Numberish | undefined): NFTData => {
  const wallets = useWallets();
  const queryResult = useQuery<{cmsNft: CmsNft}, Variables>(GET_NFT, {variables: {id}, skip: id == null});
  const unitsPerPenny = useExchangeRate();

  return createResultMapper(wallets, unitsPerPenny)(queryResult);
};
