import {CmsCreator} from '@cere/services-types/dist/types';
import {useNftsByCreator} from 'api/hooks/use-creators-page-nfts';
import React, {createContext, useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';

export enum SortingOrder {
  ASC = 'asc',
  DESC = 'desc',
}

export type FiltersParams = Record<string, string | number | undefined>;

export interface FiltersContextType {
  filterParams: FiltersParams;
  setFilterValue: (fieldName: string, value?: string | number) => void;
  applyFilters: () => void;
  clearFilters: () => void;
  setSortingOrder: (fieldName: string, order: SortingOrder) => void;
  artistsWithAvailaiableNfts: CmsCreator[];
}

export interface FiltersContextProviderProps {
  onFiltersUpdate: (filtersParams: FiltersParams) => void;
}

export const FiltersContext = createContext<FiltersContextType>({
  filterParams: {},
  applyFilters: () => {},
  clearFilters: () => {},
  setFilterValue: () => {},
  setSortingOrder: () => {},
  artistsWithAvailaiableNfts: [],
});

export const useFilter = () => {
  return useContext(FiltersContext);
};

export const FiltersContextProvider: React.FC<FiltersContextProviderProps> = ({children, onFiltersUpdate}) => {
  const history = useHistory();
  const {search} = useLocation();
  const [filterParams, setFilterParams] = useState<FiltersParams>({});

  const updateFilterParams = useCallback(() => {
    const searchParams = Object.fromEntries(new URLSearchParams(search));
    setFilterParams(searchParams);
    onFiltersUpdate(searchParams);
  }, [onFiltersUpdate, search]);

  const updatePathQuery = useCallback(
    (filterParams: FiltersParams) => {
      const searchParams = Object.entries(filterParams)
        .filter(([_field, value]) => !!value)
        .map(([field, value]) => [field, value?.toString()]);
      history.push({
        search: '?' + new URLSearchParams(Object.fromEntries(searchParams)).toString(),
      });
    },
    [history],
  );

  // This hook should be invoked only once after component's first render
  useEffect(() => {
    updateFilterParams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setFilterValue = useCallback(
    (fieldName: string, value?: string | number) => {
      setFilterParams((params) => ({
        ...params,
        [fieldName]: value,
      }));
    },
    [setFilterParams],
  );

  const setSortingOrder = useCallback(
    (fieldName: string, sortingOrder: SortingOrder) =>
      setFilterParams((params) => {
        const filterParamsWithSorting = {...params, sorting: `${fieldName}:${sortingOrder}`};
        setFilterParams(filterParamsWithSorting);
        updatePathQuery(filterParamsWithSorting);
        onFiltersUpdate(filterParamsWithSorting);
        return filterParamsWithSorting;
      }),
    [setFilterParams, updatePathQuery, onFiltersUpdate],
  );

  const applyFilters = useCallback(() => {
    updatePathQuery(filterParams);
    onFiltersUpdate(filterParams);
  }, [filterParams, updatePathQuery, onFiltersUpdate]);

  const clearFilters = useCallback(
    () =>
      setFilterParams((params) => {
        const clearedFilters = {sorting: params.sorting};
        updatePathQuery(clearedFilters);
        onFiltersUpdate(clearedFilters);
        setFilterParams(clearedFilters);
        return clearedFilters;
      }),
    [updatePathQuery, onFiltersUpdate],
  );

  const {artistsWithAvailaiableNfts} = useNftsByCreator();

  const value = useMemo(
    () => ({
      filterParams,
      setFilterValue,
      setSortingOrder,
      applyFilters,
      clearFilters,
      artistsWithAvailaiableNfts,
    }),
    [filterParams, setFilterValue, setSortingOrder, applyFilters, clearFilters, artistsWithAvailaiableNfts],
  );

  return <FiltersContext.Provider value={value}>{children}</FiltersContext.Provider>;
};
