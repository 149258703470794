import {CmsAppConfig} from '@cere/services-types';

export enum SupportedWalletTypes {
  Torus = 'Torus',
  Metamask = 'Metamask',
  WalletConnect = 'WalletConnect',
}

export type ConnectWalletOptions = {
  appConfig: CmsAppConfig;
};
