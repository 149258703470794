import {FC, MutableRefObject, useEffect, useState, useCallback, useContext} from 'react';
import BrightcovePlayer from '@brightcove/react-player-loader';
import {getPercentage} from '../../lib/getPercentage';
import {BrightcovePlayerInterface} from './brightcove-player-interface';
import {usePlayer} from './player.context';
import {MediaPlayerProps} from './types';
import {UserContext} from 'context/user-context/user-context';
import {BRIGHTCOVE_ACCOUNT_ID, BRIGHTCOVE_PLAYER_ID} from '../../../config/common';

export const MediaPlayer: FC<MediaPlayerProps> = ({
  videoId,
  videoToken,
  autoplay = false,
  onPause,
  onPlay,
  onPlayback,
  play = false,
  className,
  onVolumeChange,
  onFullscreenChange,
  onPlayerTimeUpdate,
}) => {
  const [playerRef, setPlayerRef] = useState<MutableRefObject<BrightcovePlayerInterface> | undefined>();
  const {setPlayer} = usePlayer();
  const {isExhibitFirstOpen, setIsExhibitFirstOpen} = useContext(UserContext);
  const [pause, setPause] = useState(false);

  useEffect(() => {
    if (playerRef?.current) {
      if (play && !pause) {
        playerRef.current.play();
      } else {
        playerRef.current.pause();
      }
    }
  }, [play, pause, playerRef]);

  const loadVideo = useCallback(async () => {
    if (playerRef?.current && videoId && videoToken) {
      const video = await playerRef.current?.catalog.get({
        type: 'video',
        id: videoId,
        bcovAuthToken: videoToken,
      });

      playerRef.current?.catalog.load(video);
    }
  }, [playerRef, videoId, videoToken]);

  useEffect(() => {
    loadVideo();
  }, [loadVideo]);

  return (
    <BrightcovePlayer
      attrs={{className: className}}
      style={{position: 'static !important'}}
      accountId={BRIGHTCOVE_ACCOUNT_ID}
      playerId={BRIGHTCOVE_PLAYER_ID}
      embedOptions={{responsive: true}}
      options={{fill: true, autoplay, playsinline: autoplay, preferFullWindow: true}}
      onSuccess={(data: any) => {
        const player = data.ref.player();
        setPlayerRef({current: player});
        setPlayer(player);

        if (isExhibitFirstOpen) {
          player.muted(true);
          player.on('loadedmetadata', function () {
            player.play();
          });
          setIsExhibitFirstOpen(false);
        }

        player.on('pause', () => setPause(true));

        if (onPause) {
          player.on('pause', () => onPause());
        }
        if (onPlay) {
          player.on('play', () => onPlay());
        }

        if (onVolumeChange) {
          player.on('volumechange', onVolumeChange);
        }
        if (onFullscreenChange) {
          player.on('fullscreenchange', onFullscreenChange);
        }

        if (onPlayback) {
          player.on('timeupdate', () => {
            onPlayback(getPercentage(player.currentTime(), player.duration()));
            onPlayerTimeUpdate?.(player.currentTime());
          });
        }
      }}
    />
  );
};
