import {UserDataStore} from './types';
import {AUTH_MODULE_NAME} from './constants';
import {NoSuchModuleError} from '../../base/NoSuchModuleError';

export const selectUserEmail = (store: UserDataStore): string | null => {
  if (!(AUTH_MODULE_NAME in store)) {
    throw new NoSuchModuleError(AUTH_MODULE_NAME);
  }
  return store[AUTH_MODULE_NAME]?.email ?? null;
};
