import {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {gql, useQuery} from '@apollo/client';
import {GET_WALLET_NFTS} from '../../shared/queries/wallet';
import {
  CmsCreator,
  CmsExhibitionNft,
  CmsExhibitionWithTheme,
  CmsNft,
  CreatorAuction,
  CreatorAuctionBid,
} from '../../shared/types/graphql';
import {CreatorWalletNft, CreatorWalletNftQueryResult} from '../../shared/types/graphql/creator-wallet-nfts';
import {
  enrichNftByPaymentStatuses,
  getCreatorNftBalance,
  getPaymentStatusesByNftIds,
} from '../../shared/services/nft.service';
import {UserContext} from '../../context/user-context/user-context';
import {AuctionsContext} from '../../context/auctions-context';
import {PaymentStatusEnum} from '@cere/services-types';
import {getIsPaymentSuccess} from '../../shared/helpers/paymentStatus';
import {isExclusiveNft} from '../../shared/helpers/auction';
import {FULL_EXHIBITION} from '../../shared/queries';
import {Optional} from '../../shared/types/optional';
import {useFindRelatedExhibition} from '../../shared/components/Nft/use-find-related-exhibition';
import {omitUndefined} from '../../shared/lib/omit-undefined';
import {useLocalization} from '../../shared/hooks/use-locale.hook';

const getExhibitionById = gql`
  ${FULL_EXHIBITION}
  query ExhibitionById($id: ID!, $publicationState: PublicationState = LIVE) {
    event: cmsExhibition(id: $id, publicationState: $publicationState) {
      ...CmsFullExhibition
    }
  }
`;

const useGetWalletNfts = () => {
  const {locale} = useLocalization();
  const {userData} = useContext(UserContext);

  const {
    data: creatorWalletNftQueryResult,
    previousData,
    loading: isWalletLoading,
    refetch: refetchWalletData,
  } = useQuery<{creatorWalletNfts: CreatorWalletNftQueryResult[]}>(GET_WALLET_NFTS, {
    variables: {wallet: userData.userPublicKey || null, locale},
  });

  const walletData: undefined | {creatorWalletNfts: CreatorWalletNft[]} = creatorWalletNftQueryResult && {
    creatorWalletNfts: creatorWalletNftQueryResult.creatorWalletNfts
      .filter((creatorWalletNft) => creatorWalletNft.nft_id?.cmsNfts?.length > 0)
      .map((creatorWalletNft) => ({
        id: creatorWalletNft.id,
        wallet: creatorWalletNft.wallet,
        quantity: creatorWalletNft.quantity,
        nft_id: {
          id: creatorWalletNft.nft_id?.id,
          nft_id: creatorWalletNft.nft_id?.nft_id,
          cmsNft: creatorWalletNft.nft_id?.cmsNfts?.[0],
        },
      })),
  };

  return {
    data: walletData,
    previousData,
    loading: isWalletLoading,
    refetch: refetchWalletData,
  };
};

export const useFetchNftData = (
  cmsNftPrimaryId: string,
  setShowTokenAllocation: (show: boolean) => void,
  nft?: Optional<CmsNft, 'published_at'>,
): {
  artist: CmsCreator;
  bids: CreatorAuctionBid[];
  isBought: boolean;
  isLoading: boolean;
  refetchWalletData: () => void;
  event: CmsExhibitionWithTheme | undefined;
  balance: number;
  userHasAccessToEvent: boolean;
  isCheckingAccess: boolean;
  eventNfts: CmsExhibitionNft[] | undefined;
  nftPaymentStatus: PaymentStatusEnum;
  auction: CreatorAuction | undefined;
} => {
  const [isCheckingAccess, setIsCheckingAccess] = useState<boolean>(false);
  const [userHasAccessToEvent, setUserHasAccessToEvent] = useState(false);
  const {userData} = useContext(UserContext);
  const [balance, setBalance] = useState<number>(0);
  const [nftPaymentStatus, setNftPaymentStatus] = useState(PaymentStatusEnum.INITIAL);
  const {locale} = useLocalization();

  const {auctionNfts} = useContext(AuctionsContext);
  const auction = useMemo(() => auctionNfts[nft?.creatorNft?.nft_id ?? ''], [auctionNfts, nft]);

  const {data: walletData, previousData, loading: isWalletLoading, refetch: refetchWalletData} = useGetWalletNfts();

  const findExhibitionResult = useFindRelatedExhibition(nft?.creatorNft?.nft_id);

  const {data: eventQuery} = useQuery<{event: CmsExhibitionWithTheme}>(getExhibitionById, {
    variables: {id: findExhibitionResult?.id},
    skip: findExhibitionResult == null,
  });

  const exhibition = eventQuery?.event;

  const isBought: boolean =
    walletData?.creatorWalletNfts
      .map((item: CreatorWalletNft) => item.nft_id.cmsNft.id)
      .includes(String(cmsNftPrimaryId)) === true;

  const bids = auction?.creator_auction_bids || [];

  const checkNftPaymentStatus = async (userPublicKey: string, nonCustodyWallets: string[] = [], userLocale: string) => {
    const nfts = await enrichNftByPaymentStatuses(
      [{cmsNft: nft} as CmsExhibitionNft],
      userPublicKey,
      nonCustodyWallets,
      userLocale,
    );

    const nftPaymentStatus = nfts[0]?.paymentStatus;

    setNftPaymentStatus(() => nftPaymentStatus);
    if (nftPaymentStatus === PaymentStatusEnum.PAYMENT_SUCCESS) {
      return setShowTokenAllocation(true);
    }
    if (nftPaymentStatus === PaymentStatusEnum.TOKEN_TRANSFER_SUCCESS) {
      refetchWalletData();
    }
  };

  const checkUserAccessToEvent = useCallback(async () => {
    if (!userData.userPublicKey) {
      return;
    }

    setIsCheckingAccess(true);
    const nftIds =
      exhibition?.nfts
        ?.filter(isExclusiveNft)
        .map((nft) => nft.cmsNft?.creatorNft?.nft_id)
        .filter(omitUndefined) ?? [];
    const paymentStatuses = await getPaymentStatusesByNftIds(
      nftIds,
      userData.userPublicKey,
      userData.nonCustodyWallets?.map((wallet) => wallet.publicKey),
      [],
      locale,
    );
    if (exhibition?.allowFreeAccess) {
      setUserHasAccessToEvent(true);
    } else {
      setUserHasAccessToEvent(Object.values(paymentStatuses).some((status) => getIsPaymentSuccess(status)));
    }
    setIsCheckingAccess(false);
  }, [exhibition, userData.userPublicKey, userData.nonCustodyWallets, locale]);

  const getBalance = useCallback(async () => {
    let nftBalance;
    if (auction) {
      nftBalance = auction.is_settled ? 0 : 1;

      return setBalance(nftBalance);
    }

    nftBalance = nft?.creatorNft ? await getCreatorNftBalance(nft?.creatorNft?.nft_id) : 0;

    setBalance(nftBalance);
  }, [auction, nft]);

  useEffect(() => {
    getBalance();
  }, [getBalance, nft]);

  useEffect(() => {
    if (exhibition && userData.userPublicKey) {
      void checkUserAccessToEvent();
    }
    if (!userData.userPublicKey) {
      setUserHasAccessToEvent(false);
    }
  }, [exhibition, checkUserAccessToEvent, userData.userPublicKey]);

  useEffect(() => {
    if (!isBought && !isWalletLoading && userData.userPublicKey) {
      checkNftPaymentStatus(
        userData.userPublicKey,
        userData.nonCustodyWallets?.map((wallet) => wallet.publicKey),
        locale,
      );
    }
    // eslint-disable-next-line
  }, [walletData, isWalletLoading, userData.userPublicKey, userData.nonCustodyWallets, locale]);

  useEffect(() => {
    /* TODO remove after redirect on logout is merged */
    if (!isWalletLoading && !userData.userPublicKey) {
      refetchWalletData();
    }
  }, [isWalletLoading, userData.userPublicKey, refetchWalletData]);

  return {
    artist: nft?.cmsCreator || ({} as CmsCreator),
    bids,
    isBought,
    isLoading: isWalletLoading && !previousData,
    refetchWalletData,
    event: eventQuery?.event,
    balance,
    userHasAccessToEvent,
    isCheckingAccess,
    eventNfts: exhibition?.nfts,
    nftPaymentStatus,
    auction,
  };
};

export const useFetchNftBalance = (nftId: string | undefined): {balance: number} => {
  const [balance, setBalance] = useState<number>(0);

  const {auctionNfts} = useContext(AuctionsContext);
  const auction: CreatorAuction | undefined = useMemo(
    () => (nftId ? auctionNfts[nftId] : undefined),
    [auctionNfts, nftId],
  );
  const getBalance = useCallback(async () => {
    let nftBalance;
    if (auction) {
      nftBalance = auction.is_settled ? 0 : 1;
      return setBalance(nftBalance);
    }

    nftBalance = nftId ? await getCreatorNftBalance(nftId) : 0;

    setBalance(nftBalance);
  }, [auction, nftId]);

  useEffect(() => {
    void getBalance();
  }, [getBalance, nftId]);

  return {
    balance,
  };
};
