import {Box, makeStyles, Theme} from '@material-ui/core';
import {ReactComponent as AccessIcon} from '../../../assets/ticket.svg';
import {ReactComponent as AuctionedIcon} from '../../../assets/crown.svg';
import {ReactComponent as LimitedIcon} from '../../../assets/star.svg';
import {ReactComponent as Success} from '../../../assets/icons/success.svg';
import colors from 'styles/colors';
import clsx from 'clsx';
import {useContext, useEffect, useState} from 'react';
import {usePendingTransactionsContext} from '../../../context/pending-transaction-context';
import {WarningMessage} from '../WarningMessage';
import {UserContext} from 'context/user-context/user-context';
import {CreatorAuctionBid, CheckUserHasNftEnum, NftCardInterface, NftType} from '@cere/services-types';
import {getNftRelTypeTitle, isAccessNft, isLimitedNft} from '../../helpers/bff-helper';
import {useLocalization} from '../../hooks/use-locale.hook';
import {getStorageItem} from 'shared/services/storage-service';

interface NftTicketBadgeProps {
  nft: NftCardInterface;
  isYourNftIconIncluded?: boolean;
}

const useStyles = makeStyles<Theme, {isAccessNft: boolean}>((theme) => ({
  badgeContainer: {
    position: 'absolute',
    zIndex: 15,
    left: '12px',
    top: '12px',
    height: '26px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: ({isAccessNft}) => (isAccessNft ? '2px 8px' : '2px 12px'),
    background: 'rgba(22, 22, 22, 0.7)',
    backdropFilter: 'blur(80px)',
    borderRadius: '13px',
  },
  badgeText: {
    paddingLeft: '5px',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '22px',
    color: colors.light,
  },
  nftTypeText: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  warningText: {
    textTransform: 'none',
    color: colors.light,
  },
}));

export const NftTicketBadge2 = ({nft, isYourNftIconIncluded}: NftTicketBadgeProps) => {
  const {t, locale} = useLocalization();
  const isAccessNftBadge = nft.nftType === NftType.ACCESS;
  const classes = useStyles({isAccessNft: isAccessNftBadge});
  const {getPendingTransactionForNft} = usePendingTransactionsContext();
  const {userData} = useContext(UserContext);

  const processingNftsTemp: Record<string, Record<string, CreatorAuctionBid>> = getStorageItem('BIDS');
  const [processingNfts, setProcessingNfts] = useState(processingNftsTemp);
  useEffect(() => {
    const updateState = async () => {
      const processingNftsTemp: Record<string, Record<string, CreatorAuctionBid>> = getStorageItem('BIDS');
      setProcessingNfts(processingNftsTemp);
    };
    if (nft.auction) {
      const intervalId = setInterval(updateState, 5000);
      return () => {
        clearInterval(intervalId);
      };
    }
  }, [nft, locale]);

  if ((!!processingNfts[nft.address] || getPendingTransactionForNft(nft.address)) && userData.userPublicKey) {
    return (
      <Box className={classes.badgeContainer}>
        <WarningMessage className={classes.warningText} message={t('NFT transaction processing')} />
      </Box>
    );
  }

  if (nft.purchaseStatus === CheckUserHasNftEnum.USER_HAS_NFT && isYourNftIconIncluded) {
    return (
      <Box className={classes.badgeContainer}>
        <Success width="14px" height="14px" />
        <span className={classes.badgeText}>{t('Your NFT')}</span>
      </Box>
    );
  }

  return (
    <Box className={classes.badgeContainer}>
      <>
        {isAccessNft(nft.nftType) ? <AccessIcon /> : isLimitedNft(nft.nftType) ? <LimitedIcon /> : <AuctionedIcon />}
        <span className={clsx(classes.badgeText, classes.nftTypeText)}>{getNftRelTypeTitle(nft.nftType)}</span>
      </>
    </Box>
  );
};
