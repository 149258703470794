import {useCallback, useContext} from 'react';
import {Collection} from '@cere/freeport-sdk';

import {UserContext} from './user-context/user-context';
import {isAppWallet, SupportedWallet} from '../shared/types/supported-wallet';
import {getCollection, getCollectionNonCustody} from '../shared/lib/get-collection';

export const useGetCollection = (): ((
  walletType: SupportedWallet,
  collectionAddress: string,
) => Promise<Collection>) => {
  const {userData} = useContext(UserContext);
  return useCallback(
    async (walletType: SupportedWallet, collectionAddress): Promise<Collection> => {
      if (isAppWallet(walletType)) {
        return userData.token
          ? getCollection(userData.token, collectionAddress)
          : Promise.reject("Can't get Marketplace contract for a guest user");
      }
      return getCollectionNonCustody(walletType, collectionAddress);
    },
    [userData.token],
  );
};
