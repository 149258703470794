import dayjs from 'dayjs';
import {Dispatch} from 'redux';
import {connect} from 'react-redux';
import {SellModal as PlainSellModal} from '../../shared/Modals/Sell/SellModal';
import {selectNftById} from '../../../redux/modules/nfts/selectors';
import {selectPurchasedNft} from '../../../redux/modules/purchase-history/selectors';
import {SellNFTCommand} from '../../../redux/modules/selling/actions';
import {CreateAuctionCommand} from '../../../redux/modules/auctions/actions';
import {CloseActiveModalCommand} from '../../../redux/modules/modals/actions';

type SellModalProps = {
  nftId: string;
};

const mapStateToProps = (state: any, {nftId}: SellModalProps) => {
  const nft = selectNftById(state, nftId);
  const purchasedNft = selectPurchasedNft(state, nftId)!;
  return {
    quantity: purchasedNft.qty,
    price: nft.price,
    royalty: 0, // FixMe
    auctionStartingPrice: nft.price + nft.price * 0.1, // FixMe
  };
};

const mapDispatchToProps = (dispatch: Dispatch, {nftId}: SellModalProps) => {
  const auctionClosingTime = dayjs().add(1, 'year').unix(); // FixMe
  return {
    sell: (price: number) => dispatch(SellNFTCommand.create(nftId, price)),
    startAuction: (price: number) => dispatch(CreateAuctionCommand.create(nftId, price, auctionClosingTime)),
    onClose: () => dispatch(CloseActiveModalCommand.create()),
  };
};

export const SellModal = connect(mapStateToProps, mapDispatchToProps)(PlainSellModal);
