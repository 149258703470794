import {useContext, useMemo} from 'react';
import {getIsPaymentPending, getIsPaymentSuccess} from '../../shared/helpers/paymentStatus';
import {UserPaymentHistoryContext} from './user-payment-history.context';
import {hashToArray} from '../../shared/lib/hash-utils';

export function useIsUserOwnsSomeAccessNft(accessNftIdsHash: string) {
  const {getNftIsPurchased, getPaymentStatuses} = useContext(UserPaymentHistoryContext);

  return useMemo(() => {
    const nftIds = hashToArray(accessNftIdsHash);
    if (nftIds.length === 0) {
      return false;
    }
    return nftIds.some((nftId) => {
      const paymentStatuses = getPaymentStatuses(nftId);
      if (getIsPaymentPending(...paymentStatuses) || getIsPaymentSuccess(...paymentStatuses)) {
        return true;
      }
      return getNftIsPurchased(nftId);
    });
  }, [accessNftIdsHash, getNftIsPurchased, getPaymentStatuses]);
}
