import {AuthProvidersFactory} from './providers/AuthProvidersFactory';
import {UserDataStorage} from './UserDataStorage';
import {FirebaseAuthService} from './FirebaseAuthService';
import {authApi} from '../../api/auth';
import {enableAppV2, FIREBASE_API_KEY, FIREBASE_APP_ID, FIREBASE_AUTH_DOMAIN} from '../../../config/common';

const authProvidersFactory = new AuthProvidersFactory();
export const userDataStorage = new UserDataStorage();
// ToDo: remove this condition
export const authService = enableAppV2()
  ? new FirebaseAuthService(authApi, authProvidersFactory, userDataStorage, {
      apiKey: FIREBASE_API_KEY() ?? '',
      authDomain: FIREBASE_AUTH_DOMAIN() ?? '',
      projectId: FIREBASE_APP_ID() ?? '',
      appId: FIREBASE_APP_ID() ?? '',
    })
  : ({} as any);
