import {Optional} from '../../types/optional';
import {
  CmsCreator,
  CmsCreatorExhibition,
  CmsExhibition,
  CmsExhibitionNft,
  CmsExhibitionNftRelType,
  CmsNft,
  CreatorNft,
} from '../../types/graphql';
import {RequiredSome} from '../../types/required-some';
import {Numberish} from '../../types/numberish';
import {NftAvailability} from '../../services/nft.service';

export type PartialCmsNft = RequiredSome<Partial<CmsNft>, 'id'>;

export type PartialExhibitionNft = Optional<
  Omit<CmsExhibitionNft, 'cmsNft'> & {cmsNft: PartialCmsNft},
  'relType' | 'availableTo' | 'availableFrom' | 'videoMarkers' | 'id'
>;

export const isCmsNft = (nft: PartialCmsNft | undefined): nft is CmsNft =>
  nft != null && nft?.creatorNft != null && nft.cmsCreator != null && nft.title != null && nft.assets != null;

export const isCmsExhibitionNft = (nft: PartialExhibitionNft): nft is CmsExhibitionNft =>
  isCmsNft(nft.cmsNft) &&
  nft.relType != null &&
  nft.availableTo != null &&
  nft.availableFrom != null &&
  nft.videoMarkers != null &&
  nft.id != null;

export type Nft = Omit<CmsNft, 'creatorNft'> & {
  creatorNft?: Omit<CreatorNft, 'creator_make_offer'> & {
    creator_auctions: [
      {
        creator_auction_bids: Array<{id: Numberish}>;
      },
    ];
    creator_make_offer: Array<{
      seller: string;
      price: number;
      published_at: string;
    }>;
    creator_wallet_nfts: Array<{
      wallet: string;
      quantity: number;
    }>;
  };
  cmsCreator: {
    id: Numberish;
    name: string;
    cmsExhibitions?: CmsCreatorExhibition[];
  };
  locale?: string;
  localizations?: Array<{
    locale: string;
    id: Numberish;
  }>;
};

export type ExhibitionNft = Omit<CmsExhibitionNft, 'cmsNft' | 'relType'> & {
  id: Numberish;
  relType?: CmsExhibitionNftRelType;
  cmsNft: Nft;
};

export type Exhibition = Omit<CmsExhibition, 'nfts' | 'cmsCreator'> & {
  nfts: ExhibitionNft[];
  cmsCreator: Pick<CmsCreator, 'id' | 'name'>;
};

export type NftAttributes = {
  auctionSettled: boolean;
  availability?: NftAvailability;
  availableOnlyOnMarketplace: boolean;
  hasPendingTransaction: boolean;
  counter: string;
  offerEnded: boolean;
  pending: boolean;
  failed: boolean;
  purchased: boolean;
  soldOut: boolean;
  link: string;
  order: number;
};
