import {createContext} from 'react';

import {TransactionalNotificationsInterface} from '../../shared/services/transactional-notifications/types';

export const TransactionalNotificationsContext = createContext<TransactionalNotificationsInterface>({
  setUserCredentials: () => null,
  list: [],
  totalCount: 0,
  isFirstPageLoading: false,
  isNextPageLoading: false,
  newNotificationsCount: 0,
  subscribe: () => () => null,
  reset: () => null,
  resetList: () => null,
  loadFirstPage: () => Promise.resolve(),
  loadNextPage: () => Promise.resolve(),
  loadNewNotificationsCount: () => Promise.resolve(),
  markAsUnread: () => Promise.resolve(),
  markAsRead: () => Promise.resolve(),
  markAllAsRead: () => Promise.resolve(),
});
