import {ReactElement} from 'react';
import {Button} from '@cere/rxb-template-ui-kit';
import {useExternalAuthButton} from '../../shared/hooks/use-external-auth-button';
import {useLocalization} from '../../shared/hooks/use-locale.hook';

export const ExternalAuthButton = (): ReactElement => {
  const {onAuth} = useExternalAuthButton();
  const {t} = useLocalization();

  return (
    <>
      <Button variant="contained" color="primary" onClick={onAuth}>
        {t('Authenticate')}
      </Button>
    </>
  );
};
