import {ReactElement, useCallback, useContext, useEffect, useRef, useState} from 'react';
import {isEqual, noop} from 'lodash';
import {CheckUserHasNftEnum} from '@cere/services-types';
import {CmsExhibitionIdSlugOnly} from '../../types/graphql';
import {useAuth} from '../../hooks/auth.hook';
import {UserContext} from '../../../context/user-context/user-context';
import {IKeys} from '../../services/auth.service';
import {checkIfUserHasNft} from '../../services/payment.service';
import {BuyNftGuard} from '../BuyNftGuard';
import {WalletAuctionParams} from '../auction/Wallet';
import analyticService, {AnalyticEventsEnum} from '../../services/analytic.service';
import {useNotifications} from '../../hooks/use-notifications';
import {AuthAnalyticsTrackingProvider} from '../../../pages/SignInPage/auth-analytics-tracking.context';
import NftAllocationModal from '../../../pages/ExhibitPage/routes/WebInterstitialHomePage/NftAllocationModal';
import {UserPaymentHistoryContext} from '../../../context/payments-history/user-payment-history.context';
import {useHistory} from 'react-router-dom';
import {useSelectedWallet} from '../../../context/use-selected-wallet';
import {Verify} from '../VerifyEmailGuard/Verify';
import {useWithPurchaseLimitedNft} from '../../hooks/use-with-purchase-limited-nft.hook';
import {BuyNFTLimitedOrTicketModal} from '../BuyNFTLimitedModal';
import {NFTPurchaseConfirmedModal} from '../NFTPurchaseConfirmedModal';
import {NFTPurchaseDeniedModal} from '../NFTPurchaseDeniedModal';
import {useAuctionFactory} from '../../hooks/use-auction-factory';
import {useStartAuctionFlow} from '../../../context/auction.context';
import {useLocalization} from '../../hooks/use-locale.hook';

interface WithPurchaseNftProps {
  children: (props: WithPurchaseNftResult) => ReactElement;
}

export interface WithPurchaseNftResult {
  startNFTBuyOrRequestAuth: (data: NftPurchaseParams) => Promise<void>;
  setEvent: (event: CmsExhibitionIdSlugOnly) => void;
  showWallet: boolean;
  setVerifyCallback: (callback: Function) => void;
  setShowTokenAllocation: (show: boolean) => void;
  setShowWallet: (show: boolean) => void;
  setShowVerifyModal: (show: boolean) => void;
  setSelectedNft: (nft: NftPurchaseParams) => void;
  selectedNft: NftPurchaseParams | undefined;
}

export type NftPurchaseParams = {
  relType: string;
  nftId: string;
  nftAddress: string;
  nftTitle: string;
  nftImage: string;
  nftPrice: number;
  nftUSDPrice: number;
  creatorName: string;
  sellerWalletAddress?: string;
  minter: string;
  amount: number;
  eventId?: number;
  eventSlug?: string;
  auction?: WalletAuctionParams;
  collectionAddress?: string;
};

export type NftBidParams = Pick<
  NftPurchaseParams,
  | 'auction'
  | 'nftAddress'
  | 'sellerWalletAddress'
  | 'minter'
  | 'nftPrice'
  | 'collectionAddress'
  | 'nftId'
  | 'creatorName'
  | 'nftTitle'
  | 'nftImage'
  | 'eventSlug'
>;

const WithPurchaseNft = ({children}: WithPurchaseNftProps) => {
  const {t, locale} = useLocalization();
  const selectedWallet = useSelectedWallet();
  const auctionFactory = useAuctionFactory();
  const [selectedNft, setSelectedNft] = useState<NftPurchaseParams>();
  const [event, setEventState] = useState<CmsExhibitionIdSlugOnly | undefined>();

  const [showTokenAllocation, setShowTokenAllocation] = useState(false);
  const {getNftIsPurchased} = useContext(UserPaymentHistoryContext);

  const {userData, connectNonCustodialWallet} = useContext(UserContext);
  const {setAuthData} = useAuth();
  const {info, warning} = useNotifications();
  const startAuctionFlow = useStartAuctionFlow();

  const [purchaseData, setPurchaseData] = useState<NftPurchaseParams | null>(null);

  const resolve = useRef<(data?: unknown) => unknown>(() => null);
  const reject = useRef<(data?: unknown) => unknown>(() => null);

  const [showVerifyModal, setShowVerifyModal] = useState(false);
  const [showAccessGuard, setShowAccessGuard] = useState(false);
  const [showWallet, setShowWallet] = useState(false);

  const [verifyCallback, setVerifyCallback] = useState<Function>(noop);

  const userDataRef = useRef(userData);

  const {
    isPurchaseModalOpen,
    isPurchaseSuccessModalOpen,
    isPurchaseFailureModalOpen,
    isPurchaseLoading,
    purchaseError,
    handleBuyLimitedNft,
    handleBuyNftByCard,
    buy,
    closeLimitedModal,
  } = useWithPurchaseLimitedNft({purchaseData});

  useEffect(() => {
    userDataRef.current = userData;
  }, [userData]);

  const updatePurchaseNftQuantity = useCallback(
    (amount: number) => {
      const data: NftPurchaseParams = {...purchaseData, amount} as NftPurchaseParams;
      setPurchaseData(data);
    },
    [purchaseData],
  );

  const authenticateUserForNftOperation = useCallback((nft: NftPurchaseParams): Promise<string> => {
    return new Promise((resolveCallback, rejectCallback) => {
      setShowAccessGuard(() => true);
      setSelectedNft(() => nft);
      resolve.current = resolveCallback;
      reject.current = rejectCallback;
    }).then((data) => {
      if (typeof data === 'string') {
        return data;
      }
      throw Error(`Unknown response ${data}`);
    });
  }, []);

  const setEvent = useCallback((event: CmsExhibitionIdSlugOnly) => {
    setEventState((state) => {
      const update = {id: event.id, slug: event.slug};
      if (isEqual(state, update)) {
        return state;
      }
      return update;
    });
  }, []);

  const onAuthenticateSuccess = useCallback((email: string) => {
    setShowAccessGuard(false);
    resolve.current(email);
  }, []);

  const onAuthenticateCancel = useCallback(() => {
    setShowAccessGuard(false);
    reject.current();
  }, []);

  const handleCloseVerifyModal = useCallback(() => {
    setAuthData('', '', '');
    setShowVerifyModal(false);

    setSelectedNft(undefined);
  }, [setAuthData]);

  const handleVerify = useCallback(
    async (keys: IKeys) => {
      // todo EMAIL: getWalletByEmail if not exist then sign_up EMAIL do analytics
      setAuthData(userData.userEmail || '', keys.publicKey, keys.token);
      setShowVerifyModal(false);

      setSelectedNft(undefined);
      verifyCallback();
    },
    [setAuthData, userData.userEmail, verifyCallback],
  );

  const bid = useCallback(
    (nftData: NftBidParams | null = purchaseData) => {
      if (!nftData || !nftData.auction) {
        return;
      }
      const auction = auctionFactory.createAuctionFromNftData(
        nftData.nftAddress,
        nftData.sellerWalletAddress || nftData.minter,
        selectedWallet?.publicKey!,
        nftData.auction.latestBid ?? nftData.nftPrice,
        nftData.auction?.bids || [],
        nftData?.collectionAddress || purchaseData?.collectionAddress,
      );
      return startAuctionFlow(auction, {
        nftId: nftData.nftId,
        creatorName: nftData.creatorName,
        title: nftData.nftTitle,
        address: nftData.nftAddress,
        image: nftData.nftImage,
        eventSlug: nftData.eventSlug || purchaseData?.eventSlug,
      });
    },
    [auctionFactory, purchaseData, selectedWallet?.publicKey, startAuctionFlow],
  );

  const history = useHistory();
  const handleBuyNft = useCallback(
    async (nft: NftPurchaseParams) => {
      if (nft.auction && (nft.sellerWalletAddress || nft.minter)) {
        return bid(nft);
      }
      return buy();
    },
    [bid, buy],
  );

  const handleNftPurchase = useCallback(
    async (nft: NftPurchaseParams) => {
      const {nftId, nftAddress} = nft;
      try {
        const email = await authenticateUserForNftOperation(nft).catch(() => {
          throw Error(t('Please complete authentication to purchase NFT'));
        });
        const userHasNft: CheckUserHasNftEnum = await checkIfUserHasNft(email, nftAddress).catch(() => {
          throw Error(t('Unable to load NFT data, please try later'));
        });
        await connectNonCustodialWallet();
        const isPurchased = getNftIsPurchased(nftId);
        if (userHasNft === CheckUserHasNftEnum.USER_HAS_NFT || isPurchased) {
          history.push(`/${locale}/home/nft/${nftId}`);
        } else if (userHasNft === CheckUserHasNftEnum.USER_PAYMENT_PROCESSING) {
          info(t('NFT purchasing in progress, please wait.'));
        } else if (userHasNft === CheckUserHasNftEnum.USER_DOES_NOT_HAVE_NFT) {
          await handleBuyNft(nft).catch((err) => {
            console.log({err});
            throw Error(t('An error occurred on purchasing of the NFT. Please try again'));
          });
        }
      } catch (e) {
        warning(e.message || t('The error occurred. Please, try another time'));
      }
    },
    [
      authenticateUserForNftOperation,
      connectNonCustodialWallet,
      warning,
      handleBuyNft,
      getNftIsPurchased,
      info,
      history,
      t,
      locale,
    ],
  );

  const handleBuyNftOrRequestAuth = useCallback(
    async (nft: NftPurchaseParams) => {
      if (nft.nftAddress) {
        analyticService.track(AnalyticEventsEnum.EXHIBIT_NFT_BUY_CLICKED, {
          nftId: nft.nftAddress,
          nftTitle: nft.nftTitle,
          artistName: nft.creatorName,
          nftType: nft.relType,
        });
      }
      await handleNftPurchase(nft);
    },
    [handleNftPurchase],
  );

  const startNFTBuyOrRequestAuth = useCallback(
    async (data: NftPurchaseParams) => {
      if (!userData.userPublicKey || !userData.userEmail) {
        history.push(`/${locale}/home/auth?nftId=${data.nftId}`);
      }
      setPurchaseData(data);

      await handleBuyNftOrRequestAuth(data);
    },
    [handleBuyNftOrRequestAuth, userData.userPublicKey, userData.userEmail, history, locale],
  );

  return (
    <>
      {children({
        startNFTBuyOrRequestAuth,
        setEvent,
        showWallet,
        setVerifyCallback,
        setShowTokenAllocation,
        setShowWallet,
        setShowVerifyModal,
        setSelectedNft,
        selectedNft,
      })}
      {purchaseData && (
        <>
          <BuyNFTLimitedOrTicketModal
            nftId={purchaseData!.nftId}
            nftTitle={purchaseData!.nftTitle}
            nftAddress={purchaseData?.nftAddress || ''}
            nftImage={purchaseData?.nftImage ?? ''}
            author={purchaseData?.creatorName ?? ''}
            updateNftQuantity={updatePurchaseNftQuantity}
            price={purchaseData!.nftUSDPrice}
            open={isPurchaseModalOpen}
            isProcessing={isPurchaseLoading}
            onCancel={closeLimitedModal}
            buyNft={handleBuyLimitedNft}
            buyNftByCard={handleBuyNftByCard}
            maxCount={purchaseData.amount}
          />
          <NFTPurchaseConfirmedModal
            nftId={purchaseData!.nftId}
            author={purchaseData!.creatorName}
            nftTitle={purchaseData!.nftTitle}
            nftAddress={purchaseData?.nftAddress || ''}
            nftImage={purchaseData?.nftImage}
            quantity={purchaseData!.amount}
            price={purchaseData!.nftUSDPrice}
            open={isPurchaseSuccessModalOpen}
            onClose={closeLimitedModal}
          />
          <NFTPurchaseDeniedModal
            nftId={purchaseData!.nftId}
            author={purchaseData!.creatorName}
            nftTitle={purchaseData!.nftTitle}
            nftAddress={purchaseData?.nftAddress || ''}
            nftImage={purchaseData?.nftImage}
            quantity={purchaseData!.amount}
            eventSlug={purchaseData!.eventSlug ?? ''}
            price={purchaseData!.nftUSDPrice}
            error={{message: purchaseError}}
            open={isPurchaseFailureModalOpen}
            onClose={closeLimitedModal}
          />
        </>
      )}
      <AuthAnalyticsTrackingProvider
        emailSubmitted={AnalyticEventsEnum.SIGN_UP_INTERSTITIAL_SUBMIT_BUTTON_CLICKED}
        signUpFocused={AnalyticEventsEnum.SIGN_IN_INTERSTITIAL_INPUT_FOCUSED}
        signInFocused={AnalyticEventsEnum.SIGN_UP_INTERSTITIAL_INPUT_FOCUSED}
      >
        {purchaseData && (
          <BuyNftGuard
            nftId={purchaseData.nftId}
            eventId={event?.id}
            nftPrice={purchaseData.nftUSDPrice}
            nftAddress={purchaseData.nftAddress}
            nftTitle={purchaseData.nftTitle}
            relType={purchaseData.relType}
            nftImage={purchaseData.nftImage}
            onClose={onAuthenticateCancel}
            onSuccess={onAuthenticateSuccess}
            open={showAccessGuard}
          />
        )}
      </AuthAnalyticsTrackingProvider>
      <Verify
        open={showVerifyModal}
        onVerify={handleVerify}
        onClose={handleCloseVerifyModal}
        title={selectedNft?.nftTitle || ''}
        imgSrc={selectedNft?.nftImage || ''}
      />
      <NftAllocationModal open={showTokenAllocation} onClose={() => setShowTokenAllocation(false)} />
    </>
  );
};

export default WithPurchaseNft;
