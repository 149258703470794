import {FC} from 'react';
import clsx from 'clsx';
import {noop} from 'lodash';
import {Box, makeStyles} from '@material-ui/core';
import {Icon, Typography} from '@cere/rxb-template-ui-kit';

import {ReactComponent as Arrow} from '../../../assets/arrow.svg';

interface ICardDetailNavigationProps {
  selectedIndex: number;
  totalLength: number;
  handlePrevClick: Function;
  handleNextClick: Function;
  wrapperClassName?: string;
}

const useStyles = makeStyles(() => ({
  rotateLeft: {
    transform: 'rotate(90deg)',
  },
  rotateRight: {
    transform: 'rotate(270deg)',
  },
  arrow: {
    background: 'rgba(224, 224, 231, 0.5)',
    cursor: 'pointer',
  },
  inactiveArrow: {
    opacity: '50%',
    cursor: 'not-allowed',
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const CardDetailNavigation: FC<ICardDetailNavigationProps> = ({
  selectedIndex,
  totalLength,
  handlePrevClick = noop,
  handleNextClick = noop,
  wrapperClassName,
}) => {
  const classes = useStyles();

  return (
    <Box className={clsx(classes.wrapper, wrapperClassName)}>
      <Icon
        iconClassName={clsx(classes.arrow, !selectedIndex && classes.inactiveArrow)}
        icon={<Arrow className={classes.rotateLeft} />}
        onClick={handlePrevClick}
      />
      <Box px="8px">
        <Typography variant="caption1">{`${selectedIndex + 1}/${totalLength}`}</Typography>
      </Box>
      <Icon
        iconClassName={clsx(classes.arrow, selectedIndex + 1 === totalLength && classes.inactiveArrow)}
        icon={<Arrow className={classes.rotateRight} />}
        onClick={handleNextClick}
      />
    </Box>
  );
};
export default CardDetailNavigation;
