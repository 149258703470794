import {createContext} from 'react';
import {MessageEvent} from '../../shared/types/message-event';

export interface MessagesContextInterface {
  on<T>(event: MessageEvent, handler: (payload: T) => void): () => void;
}

export const MessagesContext = createContext<MessagesContextInterface>({
  on: () => () => null,
});
