import {selectNftById} from '../../../redux/modules/nfts/selectors';
import {selectCurrentLocale} from '../../../redux/modules/localization/selectors';
import {generatePath} from 'react-router-dom';
import {ROUTES} from '../../../constants/routes';
import {selectActiveWallet, selectActiveWalletBalance} from '../../../redux/modules/wallets/selectors';
import {Dispatch} from 'redux';
import {CloseActiveModalCommand} from '../../../redux/modules/modals/actions';
import {PurchaseNFTForCryptoCommand} from '../../../redux/modules/purchase/actions';
import {connect} from 'react-redux';
import {BuyLimitedNftModal} from '../../shared/Modals/Purchase/BuyLimitedNftModal';

type PurchaseOnMarketplaceModalProps = {
  nftId: string;
  price: number;
  qty: number;
  sellerWalletAddress: string;
};

const mapStateToProps = (state: any, {nftId, price, qty}: PurchaseOnMarketplaceModalProps) => {
  const nft = selectNftById(state, nftId);
  const locale = selectCurrentLocale(state);
  const selectedWallet = selectActiveWallet(state);

  return {
    image: nft.image!,
    creatorName: nft.creatorName,
    title: nft.title,
    nftLink: generatePath(ROUTES.NFT_PAGE, {nftId, locale}),
    walletName: selectedWallet,
    walletBalance: selectActiveWalletBalance(state),
    nftAddress: nft.address,
    priceUSDC: price,
    quantity: qty,
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch,
  {nftId, sellerWalletAddress, price}: PurchaseOnMarketplaceModalProps,
) => ({
  onClose: () => dispatch(CloseActiveModalCommand.create()),
  purchase: () => dispatch(PurchaseNFTForCryptoCommand.create(nftId, sellerWalletAddress, price)),
  fundWallet: () => {}, // FixMe
});

export const PurchaseOnMarketplaceModal = connect(mapStateToProps, mapDispatchToProps)(BuyLimitedNftModal);
