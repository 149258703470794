import {makeStyles, Box} from '@material-ui/core';

import {Skeleton} from './Skeleton';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '24px 0',
  },
  item: {
    borderRadius: '12px',
    height: '343px',
    width: '343px',

    [theme.breakpoints.up('lg')]: {
      width: '100%',
    },
  },
}));

export const VipCardSkeleton = () => {
  const styles = useStyles();

  return (
    <Box className={styles.root}>
      <Skeleton className={styles.item} variant="rect" />;
    </Box>
  );
};
