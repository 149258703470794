import {webAppUrl} from '../../../config/common';

export const getPopupPaymentUrl = (locale: string): {successUrl: string; cancelUrl: string} => {
  const link = new URL(webAppUrl());
  link.hash = `/${locale}/service/callback`;
  const cancelUrl = link.href;
  link.searchParams.append('paymentStatus', 'success');
  const successUrl = link.href;
  return {successUrl, cancelUrl};
};
