import {useCallback, useState} from 'react';
import {styled} from '@linaria/react';
import {WebInterstitialHomePage} from './routes/WebInterstitialHomePage';
import {WebInterstitialExhibitionPage} from './routes/WebInterstitialExhibitPage';
import {usePageLoaded} from 'shared/services/loading.service';
import {AuctionContextProvider} from './routes/WebInterstitialExhibitPage/context/auction-context';
import analyticService, {AnalyticEventsEnum} from '../../shared/services/analytic.service';
import WithPurchaseNft from '../../shared/components/WithPurchaseNft/v2';
import {AuctionsContextProvider} from '../../context/auctions-context';
import {SwitchNetworkDialog} from './SwitchNetworkDialog';
import {Condition, ConditionsList} from '../../shared/components/Conditions';
import {useParams} from 'react-router-dom';
import {ExhibitPageRouteParams} from './exhibit-page-route-params';
import {ExhibitionProvider} from '../../context/exhibition/exhibition-provider';
import {usePendingTicketPurchase} from './use-pending-ticket-purchase';

const Container = styled.div`
  width: 100%;
`;

enum ERouter {
  HOME = 'HOME',
  EXHIBIT = 'EXHIBIT',
}

export const ExhibitPage = () => {
  const {exhibitSlug: slug} = useParams<ExhibitPageRouteParams>();
  const [route, setRoute] = useState(ERouter.HOME);

  usePageLoaded();
  usePendingTicketPurchase();

  const goToExhibitPage = useCallback(() => {
    analyticService.track(AnalyticEventsEnum.EXHIBIT_ENTER_BUTTON_CLICKED);
    setRoute(ERouter.EXHIBIT);
  }, []);

  const goToHomePage = useCallback(() => {
    setRoute(ERouter.HOME);
  }, []);

  return (
    <Container>
      <ExhibitionProvider slug={slug}>
        <AuctionsContextProvider>
          <ConditionsList>
            <Condition condition={route === ERouter.HOME}>
              <WithPurchaseNft>
                {(props) => <WebInterstitialHomePage {...props} onPlayVideoClick={goToExhibitPage} />}
              </WithPurchaseNft>
            </Condition>
            <Condition condition={route === ERouter.EXHIBIT}>
              <WithPurchaseNft>
                {({setShowTokenAllocation}) => (
                  <AuctionContextProvider>
                    <WebInterstitialExhibitionPage
                      goBack={goToHomePage}
                      setShowTokenAllocation={setShowTokenAllocation}
                    />
                  </AuctionContextProvider>
                )}
              </WithPurchaseNft>
            </Condition>
          </ConditionsList>
        </AuctionsContextProvider>
      </ExhibitionProvider>
      <SwitchNetworkDialog />
    </Container>
  );
};
