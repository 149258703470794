import {ReactNode} from 'react';
import {useCardStyles} from './styles';
import {Box} from '@material-ui/core';
import {Link} from 'react-router-dom';
import {Condition, ConditionsList, Defaults} from '../../../shared/components/Conditions';

type Props = {
  children: ReactNode;
  link: string | null;
};

export const LinkWrapper = ({children, link}: Props) => {
  const classes = useCardStyles({comingSoon: !link});
  return (
    <Box>
      <ConditionsList>
        <Condition condition={Boolean(link)}>
          <Link to={link ?? ''} className={classes.link}>
            {children}
          </Link>
        </Condition>
        <Defaults>{children}</Defaults>
      </ConditionsList>
    </Box>
  );
};
