import useSWR from 'swr';
import {NftCardInterface} from '@cere/services-types';
import {nftApi} from '../index';
import {useLocalization} from '../../shared/hooks/use-locale.hook';

export const useFeaturedNfts = (): {featuredNfts: NftCardInterface[] | null} => {
  const {locale} = useLocalization();
  const {data: featuredNfts} = useSWR(`featured-nfts`, async () => {
    return nftApi.getFeaturedNfts(locale);
  });
  return {featuredNfts: featuredNfts ?? null};
};
