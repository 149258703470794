import {IAuctionApi, TransactionResult} from './IAuctionApi';
import {FreeportSCSimpleAuctionProvider} from './FreeportSCSimpleAuctionProvider';
import {AuctionBid} from './types';
import {checkAllowanceBalanceAuction} from '../../shared/services/payment.service';
import {NonCustodyWalletTypeEnum} from '@cere/services-types';
import {FreeportSCAuctionProvider} from './FreeportSCAuctionProvider';
import {GAS_LIMIT as gasLimit, GAS_PRICE as gasPrice} from '../../config/common';

export class FreeportSCAuctionApi implements IAuctionApi {
  constructor(
    private readonly simpleAuctionProvider: FreeportSCSimpleAuctionProvider,
    private readonly auctionProvider: FreeportSCAuctionProvider,
  ) {}

  public async getBids(sellerWalletAddress: string, nftAddress: string): Promise<AuctionBid[]> {
    const auction = await this.simpleAuctionProvider.getSimpleAuction();
    const bids = await auction.sellerNftBids(sellerWalletAddress, nftAddress);
    return bids as any[];
  }

  public async placeBid(
    userWalletAddress: string,
    sellerWalletAddress: string,
    nftAddress: string,
    price: number,
    collectionAddress?: string,
  ): Promise<TransactionResult> {
    await checkAllowanceBalanceAuction(
      localStorage.getItem('selectedWallet') as NonCustodyWalletTypeEnum,
      userWalletAddress,
      price,
      collectionAddress,
    );
    const auction = collectionAddress
      ? await this.auctionProvider.getAuction()
      : await this.simpleAuctionProvider.getSimpleAuction();
    const tx = await auction.bidOnAuction(sellerWalletAddress, nftAddress, price, {
      gasLimit,
      gasPrice,
    });
    await tx.wait();
    const {hash, timestamp} = tx;
    return {hash, timestamp: timestamp ?? 0};
  }

  public async settleAuction(
    userWalletAddress: string,
    sellerWalletAddress: string,
    nftAddress: string,
    collectionAddress?: string,
  ): Promise<TransactionResult> {
    if (userWalletAddress !== sellerWalletAddress) {
      throw new Error('Only the seller can settle the auction');
    }
    const auction = collectionAddress
      ? await this.auctionProvider.getAuction()
      : await this.simpleAuctionProvider.getSimpleAuction();
    const tx = await auction.settleAuction(sellerWalletAddress, nftAddress);
    await tx.wait();
    const {hash, timestamp} = tx;
    return {hash, timestamp: timestamp ?? 0};
  }

  public async startAuction(
    nftAddress: string,
    startingPrice: number,
    closingTime: number,
    collectionAddress?: string,
  ): Promise<TransactionResult> {
    const auction = collectionAddress
      ? await this.auctionProvider.getAuction()
      : await this.simpleAuctionProvider.getSimpleAuction();
    const tx = await auction.startAuction(nftAddress, startingPrice, closingTime);
    await tx.wait();
    const {hash, timestamp} = tx;
    return {hash, timestamp: timestamp ?? 0};
  }

  public resetProviders() {
    this.simpleAuctionProvider.resetSimpleAuctions();
    this.auctionProvider.resetAuctions();
  }
}
