import {ReactElement, useCallback, useContext, useState} from 'react';
import {Link} from 'react-router-dom';
import {Modal, Typography} from '@cere/rxb-template-ui-kit';
import {Box} from '@material-ui/core';
import {AccessNftInfoModal} from '../../../../../shared/components/AccessNftInfoModal';
import {ReactComponent as InfoIcon} from '../../../../../assets/info.svg';
import {useStyles} from './styles';
import {Condition, ConditionsList} from '../../../../../shared/components/Conditions';
import ticketImage from './ticket.svg';
import {ExhibitContext} from '../../../../../context/exhibition/exhibit-context';
import {getNFTCardImage, getNFTTotalOfferedQty} from '../../../../../shared/helpers/nfts';
import {useAccessTicket} from '../../../use-access-ticket';
import {useLocalization} from '../../../../../shared/hooks/use-locale.hook';

export function ExhibitionTicket(): ReactElement | null {
  const {t, locale} = useLocalization();
  const [showModal, setShowModal] = useState(false);
  const {event} = useContext(ExhibitContext);
  const toggleShowModal = useCallback(() => setShowModal((value) => !value), []);
  const accessNft = useAccessTicket();

  const nftImage = getNFTCardImage(accessNft?.cmsNft, 'thumb') || '';

  const styles = useStyles({nftImage});

  if (!event || !accessNft) {
    return null;
  }

  return (
    <ConditionsList>
      <Condition condition={Boolean(accessNft)}>
        <Typography variant="h3" className={styles.title}>
          {t('NFT tickets from {{name}}', {name: event.cmsCreator.name})} <InfoIcon onClick={toggleShowModal} />
        </Typography>
        <Box className={styles.ticketBlock}>
          <Link to={`/${locale}/home/nft/${accessNft?.cmsNft.id}`} className={styles.ticketLink}>
            <Box className={styles.ticketBox}>
              <Box className={styles.ticketImage} />
              <Box className={styles.ticketInfo}>
                <Box>
                  <Typography variant="h3">{accessNft?.cmsNft.title}</Typography>
                  <Typography>
                    {t('{{leftAmount}}/{{totalAmount}} left', {
                      leftAmount: getNFTTotalOfferedQty(accessNft?.cmsNft) ?? 0,
                      totalAmount: accessNft?.cmsNft?.creatorNft?.supply ?? 1,
                    })}
                  </Typography>
                </Box>
                <Box className={styles.ticketInfoBottom}>
                  <Typography>{t('Your NFT ticket')}</Typography>
                  <img alt={t('NFT ticket')} src={ticketImage} />
                </Box>
              </Box>
            </Box>
          </Link>
        </Box>
        <Modal open={showModal} onClose={toggleShowModal} title={t('NFT tickets')} maxWidth="sm">
          <AccessNftInfoModal handleButtonClick={toggleShowModal} accessTokenType />
        </Modal>
      </Condition>
    </ConditionsList>
  );
}
