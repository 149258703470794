import {useContext, useState} from 'react';
import {Button, Typography, NumberBadge, Icon} from '@cere/rxb-template-ui-kit';
import {Dialog as MaterialDialog, DialogContent, DialogTitle, Box, makeStyles} from '@material-ui/core';
import * as EmailValidator from 'email-validator';
import colors from '../../../styles/colors';
import analyticService from '../../services/analytic.service';
import {GoogleAnalyticsId} from '../../../analytics-ids';
import clsx from 'clsx';
import AuthInput from '../auth/AuthInput';
import {Checkbox} from '../Checkbox';
import {ReactComponent as CloseIcon} from '../../../assets/close.svg';
import {ReactComponent as ShareEmailIcon} from '../../../assets/share-email-modal-illustration.svg';
import {NftCardMiniature} from '../NftCardMiniature';
import {UserContext} from '../../../context/user-context/user-context';
import {AppContext} from '../../../context/app-context';
import {useLocalization} from '../../hooks/use-locale.hook';

interface ShareEmailModalProps {
  onClick: (email: string) => void;
  onClickConfirmation?: () => void;
  onClose: () => void;
  imgSrc: string;
  title: string;
  price: string;
  onFocusAnalyticsEvent: string;
  onSubmitAnalyticsEvent: string;
}

const useStyles = makeStyles((theme) => ({
  label: {
    color: colors.lightGrey,
  },
  modalContent: {
    padding: '15px',
  },
  root: {
    margin: '0px',
  },
  paper: {
    width: '100%',
    borderRadius: '13px',
    margin: '0 16px',
  },
  paperWidthXs: {
    maxWidth: '340px',
  },
  button: {
    background: theme.palette.secondary.main,
  },
}));

const useHeaderStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 1rem',
    flexDirection: 'column',
    paddingTop: '0.5rem',
  },
}));

const useContentStyles = makeStyles(() => ({
  root: {
    padding: '16px',
  },
}));

export const ShareEmailModal = ({
  onClick,
  onClickConfirmation,
  onClose,
  imgSrc,
  title,
  price,
  onFocusAnalyticsEvent,
  onSubmitAnalyticsEvent,
}: ShareEmailModalProps) => {
  const {t} = useLocalization();
  const {userData} = useContext(UserContext);
  const [email, setEmail] = useState<string>(userData.userEmail || '');
  const [error, setError] = useState<string>('');
  const [readTerms, setReadTerms] = useState<boolean>(false);
  const {appConfig} = useContext(AppContext);

  const styles = useStyles();
  const headerStyles = useHeaderStyles();
  const contentStyles = useContentStyles();

  const onChange = (e: any) => {
    setEmail(e.target.value);
  };

  const onBlur = () => {
    if (!email || !EmailValidator.validate(email)) {
      setError(t('Please enter a valid email address'));
    }
  };

  const onFocus = () => {
    analyticService.track(onFocusAnalyticsEvent);
    setError('');
  };

  const onSubmit = async () => {
    analyticService.track(onSubmitAnalyticsEvent);
    await onClick(email);
    if (onClickConfirmation) {
      await onClickConfirmation();
    }
  };

  return (
    <MaterialDialog classes={styles} scroll="paper" fullWidth open onClose={onClose} maxWidth="xs">
      <DialogTitle disableTypography classes={headerStyles}>
        <Box width="100%" display="flex" flexDirection="row-reverse" onClick={onClose}>
          <NumberBadge variant="primary" text={<Icon size="md" icon={<CloseIcon />} />} />
        </Box>
        <ShareEmailIcon />
        <Typography variant="h3">{t('Share your email')}</Typography>
      </DialogTitle>
      <DialogContent classes={contentStyles} className={clsx(styles.modalContent)}>
        <Box m="8px 0 15px">
          <NftCardMiniature imgSrc={imgSrc} title={title} content={<Typography variant="h4">{price}</Typography>} />
        </Box>

        <Typography>
          <Box width="100%" display="flex" justifyContent="center" fontWeight="bold">
            {t('Don’t miss out on the next NFT drop')}
          </Box>
        </Typography>

        <Box mt="20px">
          <AuthInput
            value={email}
            helperText={error}
            inputProps={{inputMode: 'email'}}
            onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlur}
          />

          <Box display="flex" alignItems="flex-start" mt="15px">
            <Checkbox checked={readTerms} onChange={() => setReadTerms(!readTerms)} />

            <Typography variant="caption1" className={styles.label}>
              {t('I agree to receive important announcements, feature updates and offers from {{appTitle}}', {
                appTitle: appConfig.appTitle,
              })}
            </Typography>
          </Box>
        </Box>
        <Box m="24px auto 0" width="100%" maxWidth="140px">
          <Button
            className={clsx(GoogleAnalyticsId.SubscribeBtn, styles.button)}
            disabled={error || !email || !readTerms}
            color="secondary"
            variant="contained"
            fullWidth
            onClick={onSubmit}
          >
            {t('Stay updated')}
          </Button>
        </Box>
      </DialogContent>
    </MaterialDialog>
  );
};
