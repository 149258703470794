import {Box, Link, useTheme} from '@material-ui/core';
import {Typography} from '../../../shared/components/UI';
import {TYPOGRAPHY_VARIANTS} from '../../../shared/components/UI/Typography/types';
import {ReactComponent as Wallet} from '../../../assets/icons/wallet.svg';
import {ReactComponent as Currency} from '../../../assets/icons/usdc-filled.svg';
import {ReactComponent as ShoppingCart} from '../../../assets/icons/shoppingCart.svg';
import {ReactComponent as Check} from '../../../assets/check.svg';
import {ReactComponent as Arrow} from '../../../assets/icons/back-arrow.svg';
import clsx from 'clsx';
import {useGettingStartedStyles} from './styles';
import {useHistory} from 'react-router-dom';
import {useCallback, useContext} from 'react';
import {UserContext} from '../../../context/user-context/user-context';
import {useFilteredGridStyles} from '../../../shared/components/NFTFilteredGrid/styles';
import {useLocalization} from '../../../shared/hooks/use-locale.hook';

export const GetStarted = () => {
  const {t, locale} = useLocalization();
  const theme = useTheme();
  const history = useHistory();
  const classes = useGettingStartedStyles(theme);
  const styles = useFilteredGridStyles({});
  const {userData, setIsProfileMenuOpen, setIsConnectWalletVisible} = useContext(UserContext);

  const steps = [
    {name: t('Connect your wallet'), isUrl: true},
    {name: t('Top it up with USDC on Polygon Network')},
    {name: t('Ready to place your bid or purshase exclusive NFTs!')},
    {name: t('Sell your NFTs on marketplace'), isUrl: true},
  ];

  const icons = [
    <Wallet className={classes.stepIcon} />,
    <Currency className={classes.stepIcon} />,
    <Check className={classes.stepIcon} />,
    <ShoppingCart className={classes.stepIcon} />,
  ];

  const onSellNfts = useCallback(() => {
    history.push(`/${locale}/home/user/nfts`);
  }, [history, locale]);

  const onOpenProfile = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();

      if (userData.userEmail) {
        setIsProfileMenuOpen(true);
        setIsConnectWalletVisible(true);
      } else {
        history.push(`/${locale}/home/auth/signin`);
      }
    },
    [history, setIsConnectWalletVisible, setIsProfileMenuOpen, userData.userEmail, locale],
  );

  return (
    <Box className={clsx(classes.container, styles.section)}>
      <Typography className={classes.header}>{t('Start collecting & selling NFTs')}</Typography>
      <Box className={classes.stepsWrapper}>
        {steps.map((step, index) => (
          <Box key={index} className={classes.stepWrapper}>
            <Box className={classes.stepService}>
              <Box className={classes.stepNumber}>0{index + 1}</Box>
              <Box className={classes.stepIconWrapper}>{icons[index]}</Box>
            </Box>
            <Box className={classes.infoWrapper}>
              {step.isUrl ? (
                <>
                  <Link
                    href=""
                    className={clsx(classes.stepInfo, classes.link)}
                    onClick={index === 0 ? onOpenProfile : onSellNfts}
                  >
                    {step.name}
                  </Link>
                  <Arrow className={classes.linkArrow} />
                </>
              ) : (
                <Typography variant={TYPOGRAPHY_VARIANTS.body1} className={classes.stepInfo}>
                  {step.name}
                </Typography>
              )}
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};
