import {DocumentNode, useQuery} from '@apollo/client';
import {GET_NFT_TOTAL_OFFERED_QTY_BY_CMS_ID, GET_NFT_TOTAL_OFFERED_QTY_BY_ID} from '../queries/offers';

type QueryResponse = {
  creatorMakeOffers: Array<{
    seller: string;
  }>;
  creatorWalletNfts: Array<{
    wallet: string;
    quantity: number;
  }>;
};

type RequestConfig = {cmsNftIncrementId: string} | {nftId: string | undefined};

export const useNftOfferedQty = (config: RequestConfig) => {
  let query: DocumentNode;
  let nftId: string | undefined;
  if ('cmsNftIncrementId' in config) {
    query = GET_NFT_TOTAL_OFFERED_QTY_BY_CMS_ID;
    nftId = config.cmsNftIncrementId;
  } else {
    query = GET_NFT_TOTAL_OFFERED_QTY_BY_ID;
    nftId = config.nftId;
  }

  const {data, loading} = useQuery<QueryResponse>(query, {variables: {nftId}, skip: !nftId});
  if (loading) {
    return {
      qty: 0,
      loading,
    };
  }
  const sellerWallets = data?.creatorMakeOffers.map((offer) => offer.seller) || [];
  const qty =
    data?.creatorWalletNfts
      .filter((walletNft) => sellerWallets.includes(walletNft.wallet))
      .reduce((sum, walletNft) => sum + walletNft.quantity, 0) || 0;
  return {
    qty,
    loading,
  };
};
