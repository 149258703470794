import {createSimpleAuction, getSimpleAuctionAddress, SimpleAuction} from '@cere/freeport-sdk';
import {NonCustodyWalletTypeEnum} from '@cere/services-types';
import {getDavinciSignerAndProvider} from '../../shared/services/get-signer-and-provider';
import {APPLICATION, CONTRACTS_DEPLOYMENT} from '../../config/common';
import {getSigner} from '../../shared/services/non-custody-wallet';
import {AppWallet} from '../../shared/types/supported-wallet';
import {LOCAL_STORAGE_KEY_TOKEN} from '../../const/storage-keys';
import {fetchPrivateKey} from '../../shared/services/private-key.service';

export class FreeportSCSimpleAuctionProvider {
  private custodyAuctionMap = new Map<string, SimpleAuction>();
  private nonCustodyAuctionMap = new Map<NonCustodyWalletTypeEnum, SimpleAuction>();

  public async getSimpleAuction(): Promise<SimpleAuction> {
    const userToken = localStorage.getItem(LOCAL_STORAGE_KEY_TOKEN);

    const walletType = localStorage.getItem('selectedWallet');
    if (!userToken && !walletType) {
      throw new Error('Cannot get SimpleAuction for the provided options');
    }
    if (walletType === AppWallet.DAVINCI && userToken) {
      return this.getCustodySimpleAuction(userToken);
    }
    return this.getNonCustodySimpleAuction(walletType! as NonCustodyWalletTypeEnum);
  }

  private async getCustodySimpleAuction(userToken: string): Promise<SimpleAuction> {
    if (this.custodyAuctionMap.has(userToken)) {
      return this.custodyAuctionMap.get(userToken)!;
    }
    const privateKey = await fetchPrivateKey(userToken!);

    const {signer, provider} = await getDavinciSignerAndProvider(privateKey);
    const contractAddress = await getSimpleAuctionAddress(provider, CONTRACTS_DEPLOYMENT, APPLICATION());

    const simpleAuction = createSimpleAuction({signer, contractAddress});

    this.custodyAuctionMap.set(userToken, simpleAuction);

    return simpleAuction;
  }

  private async getNonCustodySimpleAuction(type: NonCustodyWalletTypeEnum): Promise<SimpleAuction> {
    if (this.nonCustodyAuctionMap.has(type)) {
      return this.nonCustodyAuctionMap.get(type)!;
    }
    const signer = await getSigner(type);
    const contractAddress = await getSimpleAuctionAddress(signer.provider, CONTRACTS_DEPLOYMENT, APPLICATION());

    const simpleAuction = createSimpleAuction({signer, contractAddress});

    this.nonCustodyAuctionMap.set(type, simpleAuction);

    return simpleAuction;
  }

  public resetSimpleAuctions(): void {
    this.custodyAuctionMap.clear();
    this.nonCustodyAuctionMap.clear();
  }
}
