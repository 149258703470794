import {Box, CircularProgress, Divider} from '@material-ui/core';
import {useEffect, useCallback} from 'react';
import {throttle} from 'lodash';

import {PageLayout} from 'shared/components/PageLayout';
import {PageContainer} from 'shared/components/PageContainer';
import {Title} from 'shared/components/Title';
import {NotificationsEmptyList} from 'shared/components/Notifications/notifications-empty-list';
import {NotificationsControls} from 'shared/components/Notifications/notifications-controls';
import {NotificationsList} from 'shared/components/Notifications/notifications-list';
import {Condition, ConditionsList, Defaults} from 'shared/components/Conditions';
import {NotificationSkeleton} from 'shared/components/Skeletons';
import {useLocalization} from 'shared/hooks/use-locale.hook';

import {useNotifications} from './notifications.hook';
import {useStyles} from './styles';

export const NotificationsPage = () => {
  const {t} = useLocalization();
  const styles = useStyles();
  const {
    notifications,
    isFirstPageLoading,
    isNextPageLoading,
    markAsUnread,
    markAsRead,
    markAllAsRead,
    hasMore,
    loadNextPage,
  } = useNotifications();
  const canLoadMore = hasMore && !isNextPageLoading;

  const handleScroll = useCallback(() => {
    if (canLoadMore) {
      const {scrollTop, offsetHeight} = document.documentElement;
      const {innerHeight} = window;

      const bottomOfWindow = offsetHeight - (scrollTop + innerHeight);

      if (bottomOfWindow < 300) {
        loadNextPage();
      }
    }
  }, [canLoadMore, loadNextPage]);

  useEffect(() => {
    const throttledHandleScroll = throttle(handleScroll, 100);
    window.addEventListener('scroll', throttledHandleScroll);

    return () => window.removeEventListener('scroll', throttledHandleScroll);
  }, [handleScroll]);

  return (
    <PageLayout>
      <PageContainer>
        <Title>{t('Notifications')}</Title>
        <Box className={styles.controlsContainer}>
          <NotificationsControls onViewAll={markAllAsRead} loading={isFirstPageLoading} />
        </Box>
        <ConditionsList>
          <Condition condition={isFirstPageLoading}>
            {Array(10)
              .fill(0)
              .map((_, index) => (
                <Box key={index}>
                  <NotificationSkeleton />
                  {index !== 9 && <Divider />}
                </Box>
              ))}
          </Condition>
          <Condition condition={notifications.length > 0}>
            <NotificationsList notifications={notifications} onView={markAsUnread} onRead={markAsRead} />
            {isNextPageLoading && (
              <Box className={styles.nextPageLoaderWrapper}>
                <CircularProgress size={36} thickness={2} className={styles.circularProgress} />
              </Box>
            )}
          </Condition>
          <Defaults>
            <Box className={styles.emptyListContainer}>
              <NotificationsEmptyList />
            </Box>
          </Defaults>
        </ConditionsList>
      </PageContainer>
    </PageLayout>
  );
};
