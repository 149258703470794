import {Button} from '@cere/rxb-template-ui-kit';
import clsx from 'clsx';
import {useDefaultButtonStyles, useDisabledSellButtonStyles, useSellButtonStyles} from './styles';
import {useTranslation} from 'react-i18next';
import {useStartNftSale} from '../../../context/sell-nft-context';
import {withSellContext} from './hocs';
import {DisabledButton} from './DisabledButton';

export interface SellButtonProps {
  className?: string;
  text?: string;
  nftId: string;
  nftAddress: string;
  nftTitle: string;
  nftImage?: string;
  nftUsdPrice: number;
  nftCollectionAddress?: string;
  ownedQty: number;
  disabled?: boolean;
}

export const PlainSellButton = ({
  className,
  text,
  nftId,
  nftAddress,
  nftImage,
  nftTitle,
  nftUsdPrice,
  nftCollectionAddress,
  ownedQty,
  disabled,
}: SellButtonProps) => {
  const buttonStyles = useDefaultButtonStyles().default;
  const sellButtonStyles = useSellButtonStyles().default;
  const disabledStyles = useDisabledSellButtonStyles().default;

  const {t} = useTranslation();
  const startNftSell = useStartNftSale();

  const sell = () => {
    startNftSell({
      cmsNftIncrementId: nftId,
      nftId: nftAddress,
      nftName: nftTitle,
      price: nftUsdPrice,
      ownedQty: ownedQty,
      collectionAddress: nftCollectionAddress,
      nftMedia: {
        url: nftImage || '',
        alternativeText: '',
        caption: '',
      },
    });
  };

  if (disabled) {
    return <DisabledButton className={clsx(className, disabledStyles)} text={text || t('Sell')} />;
  }

  return (
    <Button
      className={clsx(className, buttonStyles, sellButtonStyles)}
      onClick={sell}
      color="primary"
      variant="contained"
    >
      {text || t('Sell')}
    </Button>
  );
};

export const SellButton = withSellContext(PlainSellButton);
