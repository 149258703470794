import {makeStyles, Table, TableBody, TableCell, TableRow} from '@material-ui/core';
import {useLocalization} from '../../hooks/use-locale.hook';
import {useNftData} from 'shared/hooks/use-nft-data';
import colors from 'styles/colors';
import {CollapsibleCard} from '../CollapsibleCard';
import {getMaticNetworkUrl, getSmartContractAddress} from './helpers';

const useStyles = makeStyles((theme) => ({
  table: {
    display: 'block',

    '& .MuiTableBody-root': {
      display: 'block',

      '& .MuiTableRow-root': {
        display: 'flex',

        '& .MuiTableCell-root': {
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          padding: '7px 5px 7px 0',
          border: 'none',

          '&:nth-child(1)': {
            overflow: 'unset',
            width: '110px',
            fontWeight: 600,
            fontSize: '14px',
            lineHeight: '20px',
          },

          '&:nth-child(2)': {
            width: '150px',
            marginLeft: 'auto',
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '21px',
            color: colors.lightGrey,
            textAlign: 'end',
          },

          [theme.breakpoints.up('sm')]: {
            fontSize: '16px',
            lineHeight: '24px',

            '&:nth-child(1)': {
              minWidth: '178px',
              fontSize: '16px',
              lineHeight: '22px',
            },

            '&:nth-child(2)': {
              width: '500px',
              marginLeft: 'unset',
              marginRight: 'unset',
              textAlign: 'unset',
            },
          },
        },
      },
    },
  },
  linkDetails: {
    color: colors.primaryDark,
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
  },
}));

interface NftCollapseDetailsProps {
  cmsNftIncrementId: string;
}

export const NftCollapseDetails = ({cmsNftIncrementId}: NftCollapseDetailsProps) => {
  const classes = useStyles();
  const {nftData} = useNftData(cmsNftIncrementId);
  const contractAddress = getSmartContractAddress();
  const networkUrl = getMaticNetworkUrl();
  const {t} = useLocalization();

  const link = `${networkUrl}token/${contractAddress}?a=${nftData?.creatorNft?.nft_id}`;
  const collectionLink = `${networkUrl}token/${nftData?.creatorNft?.collection_address}`;

  return (
    <CollapsibleCard title={t('Details')}>
      <Table className={classes.table}>
        <TableBody>
          <TableRow>
            <TableCell>{t('Contract address')}</TableCell>
            <TableCell>
              <a href={link} className={classes.linkDetails}>
                {contractAddress}
              </a>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>{t('NFT ID')}</TableCell>
            <TableCell>
              <a href={link} className={classes.linkDetails}>
                {nftData?.creatorNft?.nft_id}
              </a>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>{t('Token standard')}</TableCell>
            <TableCell>{t('ERC-1155')}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>{t('Blockchain')}</TableCell>
            <TableCell>{t('Polygon')}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>{t('Total quantity')}</TableCell>
            <TableCell>{nftData?.creatorNft?.supply}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>{t('Collection')}</TableCell>
            <TableCell>
              <a href={collectionLink} className={classes.linkDetails}>
                {nftData?.creatorNft?.collection_address}
              </a>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </CollapsibleCard>
  );
};
