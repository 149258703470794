import {ReactElement} from 'react';
import {NonCustodyWalletTypeEnum, NonCustodyWalletTitles} from '../../types/non-custody-wallet';
import {Button} from '@cere/rxb-template-ui-kit';
import {Box} from '@material-ui/core';
import {ReactComponent as Metamask} from '../../../assets/wallets/metamask.svg';
import {ReactComponent as WalletConnect} from '../../../assets/wallets/walletconnect.svg';
import {ReactComponent as Cere} from '../../../assets/coins/cere.svg';
import BootstrapLoader, {SizeEnum} from '../bootstrap-loader';
import {GoogleAnalyticsId} from '../../../analytics-ids';

interface ConnectWalletItemProps {
  type: NonCustodyWalletTypeEnum;
  onClick: () => void;
  isLoading: boolean;
}

type WalletTypeParams = {
  title: string;
  icon: ReactElement;
  googleAnalyticsId: string;
};

const buttonStyle = {
  justifyContent: 'flex-start',
  height: '48px',
  boxShadow: '0px 1px 2px rgb(0 0 0 / 12%), 0px 0px 0px 1px rgb(0 0 0 / 5%)',
  borderRadius: '12px',
  border: 'none',
};

const textStyle = {
  fontWeight: 600,
  fontSize: '14px',
  lineHeight: '20px',
  color: '#000000',
};

export const ConnectWalletItem = ({onClick, type, isLoading}: ConnectWalletItemProps) => {
  const walletTypes: {[key in NonCustodyWalletTypeEnum]: WalletTypeParams} = {
    [NonCustodyWalletTypeEnum.METAMASK]: {
      title: NonCustodyWalletTitles[NonCustodyWalletTypeEnum.METAMASK],
      icon: <Metamask />,
      googleAnalyticsId: GoogleAnalyticsId.ConnectWalletMetamaskBtn,
    },
    [NonCustodyWalletTypeEnum.WALLET_CONNECT]: {
      title: NonCustodyWalletTitles[NonCustodyWalletTypeEnum.WALLET_CONNECT],
      icon: <WalletConnect />,
      googleAnalyticsId: GoogleAnalyticsId.ConnectWalletWalletconnectBtn,
    },
    [NonCustodyWalletTypeEnum.TORUS]: {
      title: NonCustodyWalletTitles[NonCustodyWalletTypeEnum.TORUS],
      icon: <Cere />,
      googleAnalyticsId: GoogleAnalyticsId.ConnectWalletTorusBtn,
    },
  };

  return (
    <Box mb={type === NonCustodyWalletTypeEnum.METAMASK ? '15px' : '14px'}>
      <Button
        color="primary"
        variant="outlined"
        size="medium"
        fullWidth
        style={buttonStyle}
        onClick={onClick}
        disabled={isLoading}
        className={walletTypes[type].googleAnalyticsId}
      >
        <Box display="flex" justifyContent="space-between">
          <Box pr="10px">
            <Box component="span" mr="6px">
              {walletTypes[type].icon}
            </Box>
            <span style={textStyle}>{walletTypes[type].title}</span>
          </Box>
          {isLoading && <BootstrapLoader size={SizeEnum.small} />}
        </Box>
      </Button>
    </Box>
  );
};
