import {Box, Grid} from '@material-ui/core';

import {PageLayout} from 'shared/components/PageLayout';
import {PageContainer} from 'shared/components/PageContainer';
import {Condition, ConditionsList} from 'shared/components/Conditions';
import {CreatorsRowSkeleton} from 'shared/components/Skeletons';
import {Title, SubTitle} from 'shared/components/Title';
import {useOtpVerification} from 'shared/hooks/use-otp-verification.hook';
import {useArtists} from 'shared/hooks/use-artists';
import {useLocalization} from 'shared/hooks/use-locale.hook';
import {usePageLoaded} from 'shared/services/loading.service';

import {CreatorCard} from './CreatorCard';

const CreatorsPage = () => {
  usePageLoaded();
  useOtpVerification();
  const {t} = useLocalization();

  const {artists, loading} = useArtists();

  return (
    <Box overflow="hidden">
      <PageLayout hideBackButton>
        <PageContainer>
          <Title>{t('Creators')}</Title>
          <Box mt="4px" mb="20px" width="75%">
            <SubTitle>
              {t(
                'Verified creators bringing transforming their best work in unique NFT experiences which are exclusively sold on DaVinci.',
              )}
            </SubTitle>
          </Box>
          <ConditionsList>
            <Condition condition={loading || !artists}>
              <CreatorsRowSkeleton />
            </Condition>
            <Condition condition>
              <Grid container spacing={4}>
                {artists.map((artist) => (
                  <Grid item xs={12} md={6} lg={4}>
                    <CreatorCard artist={artist} />
                  </Grid>
                ))}
              </Grid>
            </Condition>
          </ConditionsList>
        </PageContainer>
      </PageLayout>
    </Box>
  );
};

export default CreatorsPage;
