import {makeStyles, alpha} from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: {
    position: 'fixed',
    bottom: '24px',
    right: '24px',
    left: '24px',
    display: 'flex',
    justifyContent: 'center',
    zIndex: 15,

    [theme.breakpoints.up('lg')]: {
      justifyContent: 'right',
    },
  },
  content: {
    padding: '16px',
    borderRadius: '12px',
    boxShadow: `0px 4px 20px ${alpha(theme.palette.common.black, 0.12)}`,
    background: theme.palette.background.default,

    width: '100%',

    [theme.breakpoints.up('md')]: {
      width: '465px',
    },
  },

  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: '17px',
    borderBottom: `1px solid ${theme.palette.divider}`,
  },

  headerTitle: {
    fontSize: '18px',
    lineHeight: '22px',
    fontWeight: 600,
    color: theme.palette.text.primary,
    display: 'flex',
  },

  title: {
    fontSize: '14px',
    lineHeight: '22px',
    marginBottom: '4px',
    textDecoration: 'none',
    color: theme.palette.text.primary,

    '& p': {
      margin: 0,
    },
  },
  close: {
    cursor: 'pointer',
    padding: '12px',
    backdropFilter: 'blur(54px)',
    borderRadius: '50%',
    background: theme.palette.grey[300],
    position: 'relative',
  },
  closeIcon: {
    borderRadius: '50%',
    position: 'absolute',
    right: '6px',
    top: '6px',
    minWidth: '12px',
    height: '12px',
  },
  nftContainer: {
    paddingTop: '16px',
    display: 'flex',
    flexDirection: 'row',
  },
  link: {
    textDecoration: 'none',
  },
  nftImageBox: {
    width: '86px',
    height: '86px',
    borderRadius: '12px',
    overflow: 'hidden',
    marginRight: '12px',
  },
  nftImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  nftDetails: {
    display: 'flex',
    flexDirection: 'column',
    width: 'calc(100% - 100px)',
    height: '86px',
    justifyContent: 'space-between',
  },
  detailsTitle: {
    fontSize: '16px',
    lineHeight: '22px',
    fontWeight: 600,
    color: theme.palette.text.primary,
  },
  creatorName: {
    fontSize: '14px',
    lineHeight: '21px',
    fontWeight: 400,
    color: theme.palette.grey[700],
    maxWidth: '90%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  nftPrize: {
    fontSize: '16px',
    lineHeight: '22px',
    fontWeight: 600,
    color: theme.palette.text.primary,
  },
  ctaButton: {
    padding: '8px 21px',
    width: 'auto',
    marginLeft: '12px',
  },
  creatorBlock: {
    display: 'flex',
    alignItems: 'center',
  },
}));
