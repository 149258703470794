import {ReactElement, ReactNode, useMemo, useState} from 'react';
import {AppContext} from './app-context';
import {css} from '@linaria/core';
import {useFetchAppConfig} from '../shared/hooks/use-app-config.hook';
import {Condition} from '../shared/components/Conditions';
import {CircularProgress, CssBaseline, Grid, MuiThemeProvider, Paper} from '@material-ui/core';
import {useStyles} from 'styles/shared-styles';
import {PageLayout} from '../shared/components/PageLayout';
import {ApolloError} from '@apollo/client/errors';
import {getRandomSha256Helper} from '../shared/helpers/get-random-sha256.helper';
import {maintenanceMode} from '../config/common';
import {UserContextProvider} from './user-context/user-context-provider';
import {BrowserRouter as Router} from 'react-router-dom';
import {MaintenancePage} from '../pages/MaintenancePage';
import {useCustomTheme} from '../shared/hooks/custom-theme.hook';

export type AppProviderType = {
  children: ReactNode;
};

const globals = css`
  :global() {
    body > iframe[style*='fixed'] {
      display: none;
    }
  }
`;

function AppConfigLoader({isLoading, error}: {isLoading: boolean; error: ApolloError | undefined}) {
  const classes = useStyles();

  return (
    <>
      <Condition condition={isLoading}>
        <PageLayout hideFooter hideHeader>
          <Grid item xs={12} className={classes.loaderWrapper}>
            <CircularProgress size={36} thickness={2} color="inherit" />
          </Grid>
        </PageLayout>
      </Condition>
      <Condition condition={!!error}>
        <PageLayout hideFooter hideHeader>
          <Grid item xs={12} className={classes.loaderWrapper}>
            <Paper className={classes.errorMessage} elevation={0}>
              {error?.message}
            </Paper>
          </Grid>
        </PageLayout>
      </Condition>
    </>
  );
}

export function AppProvider({children}: AppProviderType): ReactElement {
  const {appConfig, isLoading, error} = useFetchAppConfig();
  const [appInstanceId] = useState<string>(new Date().getTime() + ':' + getRandomSha256Helper());
  const context = useMemo(() => ({appConfig, appInstanceId}), [appConfig, appInstanceId]);
  const {theme: customTheme} = useCustomTheme(appConfig);

  if (maintenanceMode) {
    return (
      <MuiThemeProvider theme={customTheme}>
        <CssBaseline>
          <UserContextProvider>
            <Router>
              <MaintenancePage />
            </Router>
          </UserContextProvider>
        </CssBaseline>
      </MuiThemeProvider>
    );
  }

  return (
    <MuiThemeProvider theme={customTheme}>
      <AppContext.Provider value={context}>
        <AppConfigLoader isLoading={isLoading} error={error} />
        <Condition condition={process.env.NODE_ENV === 'development'}>
          <div className={globals} />
        </Condition>
        <Condition condition={!isLoading && !error}>{children}</Condition>
      </AppContext.Provider>
    </MuiThemeProvider>
  );
}
