import {IPurchaseHistoryApi} from '../../../api/purchase-history/IPurchaseHistoryApi';
import {PendingTransactionsStorage} from '../../../models/pending-transactions/PendingTransactionsStorage';
import {StoreModule} from '../../base/types';
import {PENDING_TRANSACTIONS_MODULE_NAME} from './constants';
import {pendingTransactionsReducer} from './reducers';
import {
  addPendingTransactionMiddleware,
  createdNftOfferPollingMiddleware,
  purchasedNftPollingMiddleware,
  removePendingTransactionMiddleware,
  restorePendingTransactionsMiddleware,
} from './middlewares';

export const createPendingTransactionModule = (
  purchaseHistoryApi: IPurchaseHistoryApi,
  pendingTransactionsStorage: PendingTransactionsStorage,
): StoreModule => ({
  title: PENDING_TRANSACTIONS_MODULE_NAME,
  moduleReducer: pendingTransactionsReducer,
  middlewares: [
    restorePendingTransactionsMiddleware(pendingTransactionsStorage),
    addPendingTransactionMiddleware(pendingTransactionsStorage),
    removePendingTransactionMiddleware(pendingTransactionsStorage),
    purchasedNftPollingMiddleware(purchaseHistoryApi),
    createdNftOfferPollingMiddleware(purchaseHistoryApi),
  ],
});
