import {IAuctionApi} from './IAuctionApi';
import {FreeportSCAuctionApi} from './FreeportSCAuctionApi';
import {FreeportSCSimpleAuctionProvider} from './FreeportSCSimpleAuctionProvider';
import {FreeportSCAuctionsFactory} from './FreeportSCAuctionsFactory';
import {FreeportSCAuctionProvider} from './FreeportSCAuctionProvider';

const auctionApi: IAuctionApi = new FreeportSCAuctionApi(
  new FreeportSCSimpleAuctionProvider(),
  new FreeportSCAuctionProvider(),
);
export const auctionFactory = new FreeportSCAuctionsFactory(auctionApi);
