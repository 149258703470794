import {ReactElement, useContext} from 'react';
import {Typography} from '@cere/rxb-template-ui-kit';

import {Box} from '@material-ui/core';
import clsx from 'clsx';

import {ExhibitContext} from 'context/exhibition/exhibit-context';
import {UserContext} from 'context/user-context/user-context';
import {IdleTimer} from 'shared/components/IdleTimer';
import {Calendar} from 'shared/components/Calendar';
import {Condition, ConditionsList} from 'shared/components/Conditions';
import {getButtonVariantByStatus} from 'shared/helpers/preview-button';
import {useExhibitionPreview} from 'shared/hooks/use-exhibition-preview.hook';
import {ExhibitionTeaserType} from 'shared/types/exhibition-teaser';

import {useUserHasEventAccess} from '../../../use-user-has-event-access';
import {PendingTransaction} from '../EventStatuses/PendingTransaction';
import {ExhibitTeaser} from './exhibit-teaser';
import {useStyles, useLiveThemeCalendarStyles} from './live-styles';
import {ExhibitionLiveTitle} from './exhibition-live-title';
import {ExhibitLiveEnterButton} from './exhibit-live-enter-button';
import {ExploreNftsButton} from './explore-nfts-button';
import {useAccessTicket} from '../../../use-access-ticket';

type Props = {
  localIsLoading: boolean;
  userHasJwtAccess: boolean;
  accessPending: boolean;
  onPlayVideoClick: () => void;
  onExploreNFTsClick: () => void;
};

export function ExhibitionPreviewContainerLive({
  onPlayVideoClick,
  userHasJwtAccess,
  onExploreNFTsClick,
}: Props): ReactElement {
  const {event, eventTimelineStatus} = useContext(ExhibitContext);
  const {exhibitionPreview} = useExhibitionPreview(event);
  const userHasEventAccess = useUserHasEventAccess();
  const {isExhibitFirstOpen, userData} = useContext(UserContext);

  const nftPurchasedByUser = useAccessTicket({fromAllNFTs: true});
  const isUserBoughtAnyNft = Boolean(nftPurchasedByUser);
  const styles = useStyles({exhibitionPreview});
  const calendarStyles = useLiveThemeCalendarStyles();

  const isShowVideo = userHasJwtAccess && Boolean(userData.userPublicKey) && isUserBoughtAnyNft;

  if (!event) {
    return <></>;
  }

  return (
    <>
      <Box className={clsx(styles.exhibitionInfo, styles.exhibitionInfoLiveTheme)}>
        <Box className={styles.exhibitionCalendarLiveTheme}>
          <Calendar endDate={event.endsAt} startDate={event.startsAt} classes={calendarStyles} />
        </Box>

        <Box className={styles.exhibitionInfoLeft}>
          <Box className={styles.exhibitionTitles}>
            <Typography className={styles.eventSubtitle} variant="h5">
              {event?.subtitle}
            </Typography>
            <Box className={styles.exhibitionTitle}>
              <ExhibitionLiveTitle>{`Live: ${event?.title}`}</ExhibitionLiveTitle>
            </Box>
            <PendingTransaction />
            <ExploreNftsButton eventTimelineStatus={eventTimelineStatus} onExploreNFTsClick={onExploreNFTsClick} />
          </Box>
        </Box>

        <ConditionsList>
          <Condition condition={isShowVideo}>
            <Box className={styles.exhibitionImageBlock}>
              <Box className={styles.exhibitionImagePreview}>
                <ExhibitLiveEnterButton
                  onClick={onPlayVideoClick}
                  variant={getButtonVariantByStatus(eventTimelineStatus)}
                />
              </Box>
            </Box>
          </Condition>
          <Condition condition={Boolean(event?.liveTeaser?.content)}>
            <Box className={styles.teaserBlock}>
              <Box className={styles.exhibitionTeaserContainer}>
                <ExhibitTeaser type={ExhibitionTeaserType.LIVE} />
              </Box>
            </Box>
          </Condition>
        </ConditionsList>
      </Box>

      {isExhibitFirstOpen &&
        userData.userPublicKey &&
        (userHasEventAccess || Boolean(event?.allowFreeAccess!)) &&
        userHasJwtAccess &&
        isUserBoughtAnyNft && <IdleTimer onInActive={onPlayVideoClick} />}
    </>
  );
}
