import {ButtonWithIcon} from '@cere/rxb-template-ui-kit';

import {Box, makeStyles} from '@material-ui/core';

import ArrowDownIcon from 'assets/arrow-down.svg';
import {getButtonVariantByStatus} from 'shared/helpers/preview-button';
import {EventTimelineStatus} from 'shared/types/event';
import {useLocalization} from 'shared/hooks/use-locale.hook';

const useButtonStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '300px',
    },
  },
  iconBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '40px',
  },
}));

type Props = {
  eventTimelineStatus: EventTimelineStatus;
  onExploreNFTsClick: () => void;
};

export const ExploreNftsButton = ({eventTimelineStatus, onExploreNFTsClick}: Props) => {
  const {t} = useLocalization();

  const buttonStyles = useButtonStyles();

  return (
    <ButtonWithIcon
      variant="contained"
      color={getButtonVariantByStatus(eventTimelineStatus)}
      size="large"
      textStyle={{fontSize: '1rem', whiteSpace: 'nowrap'}}
      className={buttonStyles.root}
      buttonText={t('Explore NFTs')}
      iconVariant="right"
      onClick={onExploreNFTsClick}
    >
      <Box className={buttonStyles.iconBox}>
        <img src={ArrowDownIcon} alt="Explore NFTs" />
      </Box>
    </ButtonWithIcon>
  );
};
