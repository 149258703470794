import {NftType} from '@cere/services-types';
import i18n from '../../i18n';

export const isAccessNft = (nftType: NftType): boolean => {
  return nftType === NftType.ACCESS;
};

export const isAuctionedNft = (nftType: NftType): boolean => {
  return nftType === NftType.AUCTIONED;
};

export const isLimitedNft = (nftType: NftType): boolean => {
  return nftType === NftType.LIMITED;
};

export const isExclusiveNft = (nftType: NftType): boolean => {
  return isAuctionedNft(nftType) || isLimitedNft(nftType);
};

export const getNftRelTypeTitle = (nftType: NftType | undefined): string => {
  if (nftType === NftType.ACCESS) {
    return i18n.t('TICKET');
  }
  if (nftType === NftType.AUCTIONED) {
    return i18n.t('UNIQUE');
  }

  return i18n.t('LIMITED');
};
