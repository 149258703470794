import {useHistory} from 'react-router-dom';
import {useQuery} from '@apollo/client';
import {CmsExhibitionNft, ExchangeRate} from '../../../../shared/types/graphql';
import {CERE_EXCHANGE_RATE} from '../../../../shared/queries';
import {useCallback, useContext, useEffect, useState} from 'react';
import {AuctionContext} from './context/auction-context';
import {useSingletonPollNftStatus} from '../../../../shared/hooks/nft-status-poll.hook';
import {AuctionsContext} from '../../../../context/auctions-context';
import {UserContext} from '../../../../context/user-context/user-context';
import {getIsPaymentPending} from '../../../../shared/helpers/paymentStatus';
import {isLimitedNft} from '../../../../shared/helpers/auction';
import {ExhibitContext} from '../../../../context/exhibition/exhibit-context';
import {useLocalization} from '../../../../shared/hooks/use-locale.hook';

export const useGetAuctionOverlay = ({onPlaceBid, onBuy}: {onPlaceBid: Function; onBuy: Function}) => {
  const {locale} = useLocalization();
  const history = useHistory();
  const {event} = useContext(ExhibitContext);
  const {data: exchangeRate} = useQuery<{creatorExchangeRate: ExchangeRate}>(CERE_EXCHANGE_RATE);
  const [showInfo, setShowInfo] = useState(false);
  const {selectedNftIndex, setSelectedNftIndex, nfts} = useContext(AuctionContext);
  const {auctionNfts: auctionPerNfts} = useContext(AuctionsContext);
  const {userData} = useContext(UserContext);
  const selectedNft: CmsExhibitionNft = nfts[selectedNftIndex];

  const checkHighestBid = useCallback(
    (nftId: string): boolean =>
      auctionPerNfts[nftId] && auctionPerNfts[nftId] && auctionPerNfts[nftId].buyer === userData.userPublicKey,
    [auctionPerNfts, userData.userPublicKey],
  );

  const handleOpenWallet = useCallback(
    (nft: CmsExhibitionNft, amount: number) => {
      const isLimited = isLimitedNft(nft);
      return isLimited ? onBuy(nft) : onPlaceBid(nft.cmsNft?.creatorNft?.nft_id, amount);
    },
    [onBuy, onPlaceBid],
  );

  const {setPollingEnabled, setNftIds, statusesByNftId} = useSingletonPollNftStatus();

  useEffect(() => {
    if (event) {
      setNftIds(nfts.map((nft) => nft.cmsNft?.creatorNft?.nft_id ?? ''));
      const isAnyPending = Object.values(statusesByNftId).some((status) => getIsPaymentPending(status));

      isAnyPending ? setPollingEnabled(true) : setPollingEnabled(false);
    }

    return () => setPollingEnabled(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event, nfts, statusesByNftId]);

  useEffect(() => {
    return () => {
      setNftIds([]);
      setPollingEnabled(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleViewNft = useCallback(
    (nft: CmsExhibitionNft) => {
      history.push(`${locale}/home/nft/${nft.cmsNft.id}`);
    },
    [history, locale],
  );

  return {
    exchangeRate,
    selectedNft,
    selectedNftIndex,
    setSelectedNftIndex,
    nfts,
    auctionPerNfts,
    statusesByNftId,
    showInfo,
    setShowInfo,
    handleOpenWallet,
    checkHighestBid,
    handleViewNft,
  };
};
