import {AuthTypes} from '../../pages/SignInPage/types';

export const getAuthType = () => {
  const url = new URL(window.location.href);
  let type = url.pathname.substring(url.pathname.lastIndexOf('/') + 1, url.pathname.length);
  if (type === AuthTypes.SIGN_IN) {
    return 'Sign in';
  }
  if (type === AuthTypes.SIGN_UP) {
    return 'Sign up';
  }
  return;
};
