import {AnyAction, MiddlewareAPI} from 'redux';
import {INftsApi} from '../../../api/nfts/INftsApi';
import {
  NFTPurchaseStartedEvent,
  PurchaseNFTFailedEvent,
  PurchaseNFTForCryptoCommand,
  PurchaseNFTForFiatCommand,
  PurchaseNFTSuccessEvent,
  ShowPurchaseFailedModalCommand,
  ShowPurchaseSuccessModalCommand,
  ShowStartPurchaseFromMinterModalCommand,
  ShowStartPurchaseOnMarketplaceModalCommand,
  StartPrimaryNFTPurchaseCommand,
  StartSecondaryNFTPurchaseCommand,
} from './actions';
import {selectNftById} from '../nfts/selectors';
import {NftDocument} from '../nfts/actions';
import {IPurchaseNFTApi} from '../../../models/puchase/IPurchaseNFTApi';
import {WalletConnectionService} from '../../../models/wallet/WalletConnectionService';
import {IPaymentApi} from '../../../api/payment/IPaymentApi';
import {selectUserEmail} from '../auth/selectors';
import {selectCurrentLocale} from '../localization/selectors';
import {SetPendingTransactionForNftCommand} from '../pending-transactions/actions';
import {PendingTransactionType} from '../../../models/pending-transactions/types';

export const startPurchaseFromMinterMiddleware =
  (nftApi: INftsApi) =>
  ({dispatch, getState}: MiddlewareAPI) =>
  (next: Function) =>
  async (action: AnyAction) => {
    next(action);
    if (action.type === StartPrimaryNFTPurchaseCommand.type) {
      const {nftId} = action.payload;
      const state = getState();
      const nft = selectNftById(state, nftId);
      const locale = selectCurrentLocale(state);
      if (!nft) {
        try {
          const loadedNft = await nftApi.getNftById(nftId, locale);
          dispatch(NftDocument.create(loadedNft));
        } catch (e) {
          console.error(e);
          dispatch(PurchaseNFTFailedEvent.create(nftId, e.message));
          return;
        }
      }
      dispatch(ShowStartPurchaseFromMinterModalCommand.create(nftId));
    }
  };

export const startPurchaseOnMarketplaceMiddleware =
  (nftApi: INftsApi) =>
  ({dispatch, getState}: MiddlewareAPI) =>
  (next: Function) =>
  async (action: AnyAction) => {
    next(action);
    if (action.type === StartSecondaryNFTPurchaseCommand.type) {
      const {nftId, sellerWalletAddress, price, qty} = action.payload;
      const state = getState();
      const nft = selectNftById(state, nftId);
      const locale = selectCurrentLocale(state);
      if (!nft) {
        try {
          const loadedNft = await nftApi.getNftById(nftId, locale);
          dispatch(NftDocument.create(loadedNft));
        } catch (e) {
          console.error(e);
          dispatch(PurchaseNFTFailedEvent.create(nftId, e.message));
          return;
        }
      }
      dispatch(ShowStartPurchaseOnMarketplaceModalCommand.create(nftId, sellerWalletAddress, price, qty));
    }
  };

export const purchaseNftForCryptoMiddleware =
  (purchaseApi: IPurchaseNFTApi, walletConnectionService: WalletConnectionService) =>
  ({dispatch, getState}: MiddlewareAPI) =>
  (next: Function) =>
  async (action: AnyAction) => {
    next(action);
    if (action.type === PurchaseNFTForCryptoCommand.type) {
      const {nftId, sellerWalletAddress, price, qty} = action.payload;
      const nft = selectNftById(getState(), nftId);
      if (!nft) {
        dispatch(PurchaseNFTFailedEvent.create(nftId, 'NFT data not found'));
        dispatch(ShowPurchaseFailedModalCommand.create(nftId, sellerWalletAddress, price, qty));
        return;
      }
      dispatch(NFTPurchaseStartedEvent.create());
      try {
        const userActiveWallet = await walletConnectionService.getSelectedWalletAddress();
        const timestamp = await purchaseApi.purchaseNft(
          userActiveWallet,
          sellerWalletAddress,
          nft.address,
          price,
          qty,
          nft.collectionAddress,
        );
        dispatch(PurchaseNFTSuccessEvent.create(nftId));
        dispatch(ShowPurchaseSuccessModalCommand.create(nftId, price, qty));
        dispatch(
          SetPendingTransactionForNftCommand.create(
            userActiveWallet,
            nftId,
            PendingTransactionType.BuyTransaction,
            price,
            timestamp,
          ),
        );
      } catch (e) {
        console.error(e);
        dispatch(PurchaseNFTFailedEvent.create(nftId, e.message));
        dispatch(ShowPurchaseFailedModalCommand.create(nftId, sellerWalletAddress, price, qty));
      }
    }
  };

export const purchaseNtForFiatMiddleware =
  (paymentApi: IPaymentApi) =>
  ({dispatch, getState}: MiddlewareAPI) =>
  (next: Function) =>
  async (action: AnyAction) => {
    next(action);

    if (action.type === PurchaseNFTForFiatCommand.type) {
      const state = getState();
      const userEmail = selectUserEmail(state);
      const locale = selectCurrentLocale(state);
      const {nftId, qty} = action.payload;
      if (!userEmail) {
        dispatch(PurchaseNFTFailedEvent.create(nftId, 'User email not found'));
        return;
      }
      const nft = selectNftById(getState(), nftId);
      if (!nft) {
        dispatch(PurchaseNFTFailedEvent.create(nftId, 'NFT data not found'));
        return;
      }
      if (!nft.exhibitionId) {
        dispatch(PurchaseNFTFailedEvent.create(nftId, 'NFT is not assign to an event'));
        return;
      }
      try {
        const url = await paymentApi.getFiatPaymentUrl(userEmail, nft.address, qty, Number(nft.exhibitionId), locale);
        window.open(url, 'Pay by card', 'left=100,top=100,width=800,height=600');
      } catch (e) {
        console.error(e);
        dispatch(PurchaseNFTFailedEvent.create(nftId, e.message));
        dispatch(ShowPurchaseFailedModalCommand.create(nftId, nft.minter, qty, nft.price));
      }
    }
  };
