import {useState, useContext, useEffect, useCallback} from 'react';
import useSWR from 'swr';

import {UserContext} from '../../../context/user-context/user-context';
import {fetchNewNotifications, markAsProcessed} from '../../services/target-notifications/target-notifications.service';
import {MessagesContext} from '../../../context/messages-context';
import {MessageEvent} from '../../types/message-event';
import {TargetNotification} from './types';
import {useLocalization} from '../../hooks/use-locale.hook';

const LOCAL_STORAGE_PROCESSED_TARGET_NOTIFICATIONS = 'processedTargetNotifications';

// it's necessary to store showed notification for unauthorized users
const useLocallyStoredNotifications = () => {
  const getProcessedItems = useCallback((): number[] => {
    const storedItems = localStorage.getItem(LOCAL_STORAGE_PROCESSED_TARGET_NOTIFICATIONS);

    return storedItems ? JSON.parse(storedItems) : [];
  }, []);

  const checkIfProcessed = useCallback(
    (externalId: number): boolean => {
      const storedItems = getProcessedItems();

      return storedItems.includes(externalId);
    },
    [getProcessedItems],
  );

  const storeAsProcessed = useCallback(
    (externalId: number) => {
      const storedItems = getProcessedItems();

      localStorage.setItem(
        LOCAL_STORAGE_PROCESSED_TARGET_NOTIFICATIONS,
        JSON.stringify(Array.from(new Set<number>([...storedItems, externalId]))),
      );
    },
    [getProcessedItems],
  );

  return {
    checkIfProcessed,
    storeAsProcessed,
  };
};

export const useTargetNotifications = () => {
  const [isTargetNotificationAlreadyShown, setIsTargetNotificationAlreadyShown] = useState<boolean>(false);
  const [notification, setNotification] = useState<TargetNotification | null>(null);
  const {userData} = useContext(UserContext);
  const messagesContext = useContext(MessagesContext);
  const {checkIfProcessed, storeAsProcessed} = useLocallyStoredNotifications();

  const userToken = userData?.token;
  const setActiveNotification = useCallback(
    (notification) => {
      if (isTargetNotificationAlreadyShown) {
        // show notification only once per session
        return;
      }

      setIsTargetNotificationAlreadyShown(true);
      setNotification(notification);
    },
    [setNotification, isTargetNotificationAlreadyShown],
  );
  const {locale} = useLocalization();

  const fetchNewAvailableNotification = useCallback(async () => {
    const notifications = await fetchNewNotifications(locale, userToken);

    for (const notification of notifications) {
      const isProcessed = checkIfProcessed(notification.externalId);
      if (isProcessed && userToken) {
        // if user saw on this certain device notification as unauthorized user mark it as processed to not show the same notification twice
        markAsProcessed(userToken, notification.id, locale);
      }

      if (!isProcessed) {
        setActiveNotification(notification);

        userToken && markAsProcessed(userToken, notification.id, locale);
        // to not show again if user logs out
        storeAsProcessed(notification.externalId);
        break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userToken, locale]);

  useEffect(() => {
    if (userToken) {
      fetchNewAvailableNotification();
    }
  }, [userToken, fetchNewAvailableNotification]);

  useEffect(() => {
    if (userToken) {
      return messagesContext.on(MessageEvent.TARGET_NOTIFICATION_CREATED, () => {
        fetchNewAvailableNotification();
      });
    }
  }, [userToken, messagesContext, fetchNewAvailableNotification]);

  useSWR(
    userToken || isTargetNotificationAlreadyShown ? null : 'fetchNewAvailableNotification',
    fetchNewAvailableNotification,
    {refreshInterval: 15000},
  );

  const resetNotification = useCallback(() => setNotification(null), [setNotification]);

  return {
    notification,
    resetNotification,
  };
};
