import {ChangeEventHandler, FocusEventHandler} from 'react';
import {noop} from 'lodash';
import {InputAdornment, makeStyles} from '@material-ui/core';
import {TextField, Typography} from '../../UI';
import colors from '../../../../styles/colors';
import {formatPrice, TOKEN_TITLE, usdToCere} from '../../../lib/formatPrice';
import {TYPOGRAPHY_VARIANTS} from '../../UI/Typography/types';
import {getBidTokensValue} from '../../../helpers/auction';
import {getNumberFromString} from '../../../lib/utils';
import {useLocalization} from '../../..//hooks/use-locale.hook';

export type TextVariant = 'warning' | 'error' | 'info' | '';

interface AuctionTextFieldProps {
  onFocus?: FocusEventHandler<HTMLInputElement>;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  defaultValue?: string | number;
  value?: string | number;
  variant: TextVariant;
  helperText?: Node | string;
  InputProps?: Record<string, unknown>;
  labelClassName?: Object;
  placeholder?: string;
  label?: string;
  cereUnitsPerPenny?: number;
}

const getFormControlStyles = makeStyles(() => ({
  root: {
    marginTop: 0,
    marginBottom: 0,
  },
}));

const getInputStyles = makeStyles((theme) => ({
  root: {
    color: colors.primaryDark,
    fontWeight: 700,
    fontSize: '20px',
    '& input': {
      paddingLeft: 0,
    },
  },
  underline: {
    '&:not($error):before': {
      transition: 'none',
      borderBottomWidth: '1px',
      borderBottomColor: ({variant}: {variant: TextVariant}) =>
        variant === 'warning' ? colors.darkYellow : colors.lighter,
    },
    '&:not($error):after': {
      transition: 'none',
      borderBottomWidth: '1px',
      borderBottomColor: ({variant}: {variant: TextVariant}) =>
        variant === 'warning' ? colors.darkYellow : colors.lighter,
    },
  },
  error: {
    '&:before': {
      transition: 'none',
      borderBottomWidth: '1px',
      borderBottomColor: theme.palette.secondary.main,
    },
    '&:after': {
      transition: 'none',
      borderBottomWidth: '1px',
      borderBottomColor: theme.palette.secondary.main,
    },
  },
  adornment: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',
  },
}));

const getHelperStyles = makeStyles((theme) => ({
  root: {
    color: ({variant}: {variant: TextVariant}) =>
      variant === 'error' ? theme.palette.secondary.main : variant === 'warning' ? colors.darkYellow : colors.light,
  },
}));

const getLabelStyles = makeStyles(() => ({
  root: {
    color: colors.lightGrey,
  },
  focused: {
    color: `${colors.lightGrey} !important`,
  },
  error: {
    color: `${colors.lightGrey} !important`,
  },
}));

const AuctionTextField = ({
  onFocus = noop,
  onChange = noop,
  helperText,
  variant,
  value = '',
  cereUnitsPerPenny = 0,
  InputProps = {},
  labelClassName,
  placeholder,
  label,
  ...props
}: AuctionTextFieldProps) => {
  const {t} = useLocalization();
  const formControlClasses = getFormControlStyles();
  const inputClasses = getInputStyles({variant});
  const labelClasses = getLabelStyles();
  const helperClasses = getHelperStyles({variant});

  return (
    <TextField
      classes={formControlClasses}
      label={label || t('Your bid')}
      placeholder={placeholder}
      fullWidth
      margin="normal"
      InputLabelProps={{
        shrink: true,
        classes: labelClasses,
      }}
      value={formatPrice(value as string | number, {prettify: false})}
      onChange={onChange}
      onFocus={onFocus}
      helperText={helperText}
      InputProps={{
        classes: inputClasses,
        endAdornment: (
          <InputAdornment position="end">
            <Typography variant={TYPOGRAPHY_VARIANTS.caption1} className={inputClasses.adornment}>
              {getBidTokensValue(usdToCere(getNumberFromString(value as string), cereUnitsPerPenny))} {TOKEN_TITLE}
            </Typography>
          </InputAdornment>
        ),
        //@ts-ignore
        inputmode: 'numeric',
        ...InputProps,
      }}
      FormHelperTextProps={{classes: helperClasses}}
      {...props}
      error={variant === 'error'}
      variant="standard"
    />
  );
};

export {AuctionTextField};
