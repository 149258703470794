import {useLocalization} from '../../shared/hooks/use-locale.hook';
import useSWR from 'swr';
import {exhibitApi} from '../index';
import {useState} from 'react';

export const useRandomExhibits = () => {
  const {locale} = useLocalization();
  const [loading, setLoading] = useState<boolean>(false);
  const {data: exhibits} = useSWR(`random-exhibits`, async () => {
    const exhibits = await exhibitApi.getRandomExhibits(locale);
    setLoading(false);
    return exhibits;
  });

  return {
    loading,
    exhibits,
  };
};
