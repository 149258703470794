import React, {useRef} from 'react';
import {Box, makeStyles, Theme, useMediaQuery, useTheme} from '@material-ui/core';
import {Swiper, SwiperSlide} from 'swiper/react/swiper-react';
import {Navigation} from 'swiper';
import {ReactComponent as Arrow} from '../../../assets/icons/control_chevron.svg';
import clsx from 'clsx';
import 'swiper/swiper-bundle.css';

import {useFilteredGridStyles} from '../NFTFilteredGrid/styles';
import {Title, SubTitle} from '../Title';
import colors from '../../../styles/colors';

const useStyles = makeStyles<Theme, {isLandscape?: boolean}>((theme) => ({
  wrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: 'var(--padding)',
    overflow: 'initial !important',

    [theme.breakpoints.up('md')]: {
      maxWidth: '724px',
      margin: ({isLandscape}) => (isLandscape ? 0 : 'unset'),
    },

    [theme.breakpoints.up('lg')]: {
      maxWidth: '1440px',
      padding: 'var(--padding)',
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  headerButtons: {
    display: 'none',
    flexDirection: 'row',
    width: '104px',
    justifyContent: 'space-between',
    alignItems: 'flex-end',

    [theme.breakpoints.up('lg')]: {
      display: 'flex',
    },
  },
  headerButton: {
    width: '44px',
    height: '44px',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '&.swiper-button-disabled': {
      '& path:first-child': {
        fill: colors.lighter,
      },
      '& path:last-child': {
        stroke: colors.disable,
      },
    },
  },
  headerButtonRev: {transform: 'rotate(180deg)'},
  container: {
    display: 'flex',
    width: '100%',
    padding: '24px 0',
  },

  visibleContainer: {
    padding: '16px 0 24px 0',
    [theme.breakpoints.up('lg')]: {
      padding: '8px 0 24px 0',
    },
  },

  visibleRow: {
    overflow: 'visible',
    [theme.breakpoints.up('lg')]: {
      overflow: 'visible',
    },
  },
  hiddenRow: {
    overflow: 'hidden',
  },
  hiddenSwiperRow: {
    overflow: 'initial',
  },

  slide: {
    width: 'auto',
  },
}));

export const CuratedRow: React.FC<{
  title?: string;
  subTitle?: string;
  isOverlay?: boolean;
  isLandscape?: boolean;
  paddingStyles?: string;
  titleStyles?: string;
  slidesPerView?: number;
}> = ({children, title, subTitle, isOverlay, isLandscape, paddingStyles, titleStyles, slidesPerView}) => {
  const theme = useTheme();
  const classes = useStyles({isLandscape});
  const styles = useFilteredGridStyles({});

  const nextRef = useRef(null);
  const prevRef = useRef(null);

  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const childrenArray = React.Children.toArray(children);

  return (
    <Box className={clsx(classes.wrapper, isOverlay ? classes.visibleRow : classes.hiddenRow, styles.section)}>
      <Box className={classes.header}>
        <Box className={titleStyles}>
          <Title>{title}</Title>
          <SubTitle>{subTitle}</SubTitle>
        </Box>
        <Box className={classes.headerButtons}>
          <div className={classes.headerButton} ref={prevRef}>
            <Arrow />
          </div>
          <div className={clsx(classes.headerButton, classes.headerButtonRev)} ref={nextRef}>
            <Arrow />
          </div>
        </Box>
      </Box>
      <Swiper
        className={clsx(
          classes.container,
          isOverlay ? classes.hiddenSwiperRow : classes.visibleRow,
          titleStyles && classes.visibleContainer,
        )}
        navigation={{
          nextEl: isDesktop ? nextRef.current : null,
          prevEl: isDesktop ? prevRef.current : null,
        }}
        slidesPerView={slidesPerView || 'auto'}
        spaceBetween={24}
        breakpoints={{
          [Number(theme.breakpoints.values.md)]: {
            spaceBetween: 32,
          },
          [Number(theme.breakpoints.values.lg)]: {
            allowTouchMove: false,
            noSwiping: true,
            spaceBetween: 32,
          },
        }}
        modules={[Navigation]}
      >
        {React.Children.map(childrenArray, (childComponent, index) => (
          <SwiperSlide key={`slide-${index}`} className={clsx(classes.slide, paddingStyles)}>
            {childComponent}
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
};
