import {PropsWithChildren, useContext, useEffect} from 'react';
import {TorusWalletContext} from './torus-context';
import {getWallet} from '../../shared/services/torus';
import {UserContext} from '../user-context/user-context';
import {useTorusWalletAddress} from './use-wallet-address';
import {useSingletonCheckUserConsent} from '../../shared/components/UserConsentPopup/hooks';
import {AppContext} from '../../context/app-context';

type TorusWalletProviderProps = PropsWithChildren<{}>;

const Provider = ({children}: TorusWalletProviderProps) => {
  const {appConfig} = useContext(AppContext);

  const wallet = getWallet({
    appName: appConfig.appTitle,
    logoUrl: appConfig.logoShort.url,
  });

  const {address, createWallet, loading} = useTorusWalletAddress();
  const {status: consentStatus} = useSingletonCheckUserConsent();
  const {userData} = useContext(UserContext);
  const hasToken = !!userData.token;

  useEffect(() => {
    const isConnected = wallet?.status === 'connected';

    if (hasToken && !isConnected && !loading && consentStatus === true) {
      createWallet();
    }

    if (!hasToken && isConnected) {
      wallet.disconnect();
    }
  }, [wallet, userData, address, hasToken, loading, consentStatus, createWallet]);

  return <TorusWalletContext.Provider value={wallet}>{children}</TorusWalletContext.Provider>;
};

export const TorusWalletProvider = (props: TorusWalletProviderProps) => {
  return <Provider {...props} />;
};
