import {useUserWalletsPubKeys} from '../../context/use-user-wallets-pub-keys';
import {getActiveEthereumWallet} from '../services/wallet.service';
import {useContext} from 'react';
import {UserContext} from '../../context/user-context/user-context';
import {NonCustodyWalletTypeEnum} from '../types/non-custody-wallet';
import {useLocalization} from './use-locale.hook';

export const useCheckConnectedMetamaskWallet = () => {
  const {t} = useLocalization();
  const {nonCustodyWallets, selectedWallet} = useContext(UserContext);
  const connectedWallets = useUserWalletsPubKeys();
  const userHasMetamaskWallet = nonCustodyWallets.find((wallet) => wallet.type === NonCustodyWalletTypeEnum.METAMASK);

  return async () => {
    if (userHasMetamaskWallet && selectedWallet === NonCustodyWalletTypeEnum.METAMASK) {
      const activeMetamaskWallet = await getActiveEthereumWallet();

      if (!connectedWallets.includes(activeMetamaskWallet)) {
        throw new Error(t('Incorrect wallet connected, please connect active wallet to sign this transaction.'));
      }
    }

    return true;
  };
};
