import {useCallback} from 'react';
import {Trans} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import {Box, makeStyles} from '@material-ui/core';
import {Button, Typography} from '@cere/rxb-template-ui-kit';
import {ReactComponent as BackIcon} from '../../assets/svg/back-arrow.svg';
import {CereWalletKeyInfo} from '../sign-in/components/CereWalletKeyInfo';
import {DisabledEmailField} from './components/DisabledEmailField';
import {VerifyOTPComponent} from '../../components/shared/VerifyOTPComponent';
import {AuthHeader} from '../../components/shared/AuthHeader';
import colors from '../../../styles/colors';
import {useLocalization} from '../../utils/hooks/use-localization';
import {webAppUrl} from '../../../config/common';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    height: '100vh',
  },
  backLinkBox: {
    padding: '2rem 0 0 1rem',
  },
  backLinkBoxButton: {
    minWidth: 'unset',
  },
  box: {
    margin: '0 auto',
    padding: '2rem',
    [theme.breakpoints.up('md')]: {
      maxWidth: '480px',
    },
  },
  label: {
    color: colors.lightGrey,
  },
  cardIcon: {
    width: '17px',
    height: '13px',
    margin: '0 10px 4px 0',

    '& path': {
      stroke: colors.snowWhite,
    },
  },
  button: {
    margin: '0 auto 0',
    display: 'block',
    padding: '13px 19px',
    [theme.breakpoints.up('md')]: {
      padding: '13px 37px',
    },
    [theme.breakpoints.up('lg')]: {
      margin: 'unset',
    },
  },
  payByCardLink: {
    textAlign: 'center',
    color: theme.palette.secondary.main,
    fontWeight: 600,
    padding: '15px',
    cursor: 'pointer',
  },
  textWrapper: {
    '> p': {
      fontWeight: 600,
      color: theme.palette.secondary.main,
    },
  },
  wrapper: {
    gap: '16px',
    flexWrap: 'wrap',
  },
  separator: {
    position: 'relative',
    display: 'inline',
    padding: '0 8px',
    background: '#fff',
    color: '#6E6E79',
  },
  strikethrough: {
    position: 'relative',
    textAlign: 'center',

    '&::before': {
      position: 'absolute',
      content: '""',
      left: '0',
      top: '10px',
      height: '1px',
      width: '100%',
      background: '#E0E0E7',
    },
  },
  subheader: {
    marginBottom: '18px',
    color: '#6E6E79',
    '& string': {
      color: theme.palette.primary.main,
      display: 'inline',
      fontWeight: 'normal',
    },
  },
}));

type VerifyEmailViewProps = {
  appName: string;
  logoUrl: string;
  resendCode: () => void;
  verifyCode: (code: string) => void;
};

export const VerifyEmailView = ({appName, logoUrl, resendCode, verifyCode}: VerifyEmailViewProps) => {
  const styles = useStyles();
  const history = useHistory();
  const {t} = useLocalization();

  const goBack = useCallback(() => {
    history.goBack();
  }, [history]);

  const goHome = useCallback(() => {
    history.push('/');
  }, [history]);

  return (
    <Box className={styles.root}>
      <Box className={styles.backLinkBox}>
        <Button onClick={goBack} className={styles.backLinkBoxButton}>
          <BackIcon />
        </Button>
      </Box>
      <Box className={styles.box}>
        <AuthHeader logoUrl={logoUrl} title={t('Verify email')} onLogoClick={goHome} />
        <Typography className={styles.subheader}>
          <Trans
            i18nKey="Sign in to {{appName}} with a <strong>registered account</strong> and the verification code sent to your email"
            values={{appName}}
          />
        </Typography>
        <DisabledEmailField label={t('Email address')} email={history.location.state?.email} />
        <VerifyOTPComponent verifyOtp={verifyCode} resendOtpCode={resendCode} numberOfDigits={6} />
        <CereWalletKeyInfo appUrl={webAppUrl()} />
      </Box>
    </Box>
  );
};
