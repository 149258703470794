import {MouseEvent, useMemo, useState} from 'react';
import {makeStyles, Card, CardMedia, Box, Theme} from '@material-ui/core';
import clsx from 'clsx';
import colors from '../../../styles/colors';
import {ShareIcon} from '../ShareIcon';
import dayjs from 'dayjs';
import {getBadgeText, getEventCardBadge, getEventCardDatesV2, getEventCardStatus} from './helpers';
import {useResponsiveImage} from 'shared/hooks/use-responsive-image';
import {ExhibitionBFF} from 'pages/HomePage/types';
import {Avatar} from '../Avatar';
import {getShareUrl} from '../Share/utils/get-share-url';
import Share from '../Share';
import {buildAbsoluteCmsUrl} from 'shared/queries/utils';
import {getMediaUrl} from 'shared/lib/media';
import {GoogleAnalyticsId} from 'analytics-ids';
import {link, StyledLink} from '../StyledLink';
import {useLocalization} from '../../hooks/use-locale.hook';
import {useExhibitNftsBySlug} from 'api/hooks/use-exhibit-nfts-by-slug';
import {CheckUserHasNftEnum, NftType} from '@cere/services-types';

const useStyles = makeStyles<Theme, {isCuttedCard?: boolean}>((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '12px',
    position: 'relative',
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.12)',
    maxWidth: '400px',
    margin: '0 auto',
    height: ({isCuttedCard}) => (isCuttedCard ? '320px' : '343px'),
    width: ({isCuttedCard}) => (isCuttedCard ? '320px' : '343px'),

    [theme.breakpoints.up('md')]: {
      height: ({isCuttedCard}) => (isCuttedCard ? '320px' : '328px'),
      width: ({isCuttedCard}) => (isCuttedCard ? '320px' : '328px'),
    },

    [theme.breakpoints.up('lg')]: {
      height: ({isCuttedCard}) => (isCuttedCard ? '320px' : '400px'),
      width: ({isCuttedCard}) => (isCuttedCard ? '320px' : '400px'),
    },
  },
  mediaWrapper: {
    overflow: 'hidden',
    borderRadius: '12px 12px 0 0',
  },
  mediaWrapperCentred: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  badge: {
    position: 'absolute',
    zIndex: 2,
    top: '12px',
    left: '12px',
  },
  share: {
    position: 'absolute',
    top: '12px',
    right: '12px',
    zIndex: 2,
  },
  overlay: {
    position: 'relative',
    filter: 'blur(15px)',
    '&:after': {
      content: '""',
      position: 'absolute',
      width: '100%',
      height: '100%',
      bottom: 0,
      zIndex: 1,
      background: '#505050',
      opacity: 0.5,
    },
  },
  pointer: {
    cursor: 'pointer',
  },
  overlayText: {
    position: 'absolute',
    fontSize: '14px',
    color: colors.light,
    fontWeight: 'bold',
  },
  overlayTextDate: {
    fontSize: '20px',
    color: colors.light,
    fontWeight: 'bold',
  },
  badgeContainer: {
    position: 'absolute',
    zIndex: 15,
    left: '12px',
    top: '12px',
    height: '26px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '2px 14px 2px 12px',
    background: 'rgba(22, 22, 22, 0.7)',
    backdropFilter: 'blur(80px)',
    borderRadius: '100px',
  },
  badgeText: {
    paddingLeft: '5px',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '22px',
    color: colors.light,
  },
  shoppingCartIcon: {
    width: '17px',
    height: '16px',
    '& path': {
      fill: '#3CBCAC',
    },
  },
  cardContent: {
    position: 'absolute',
    bottom: '12px',
    left: '12px',
  },
  avatar: {
    '& p': {
      color: colors.lighter,
    },
  },
  wrapper: {
    position: 'absolute',
    background: 'linear-gradient(360deg, #161616 0%, rgba(22, 22, 22, 0) 100%)',
    width: '100%',
    height: '100%',
  },
  title: {
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',
    color: colors.light,
    margin: '6px 0 12px 0',
  },
  date: {
    '& p': {
      fontWeight: 600,
      fontSize: '12px',
      lineHeight: '20px',
      color: `${colors.light} !important`,
    },
  },
}));

const useMediaStyles = makeStyles((theme) => ({
  root: {
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    height: '343px',
    width: '343px',

    [theme.breakpoints.up('md')]: {
      height: '328px',
      width: '328px',
    },

    [theme.breakpoints.up('lg')]: {
      height: '400px',
      width: '400px',
    },
  },
}));

interface Props {
  event: ExhibitionBFF;
  className?: string;
  withOverlay?: boolean;
  noAvatar?: boolean;
  isCuttedCard?: boolean;
}

export const EventCardV2 = ({event, className, withOverlay, noAvatar, isCuttedCard}: Props) => {
  const eventCardStatus = getEventCardStatus(event as any);
  const eventCardDatesComponent = getEventCardDatesV2(eventCardStatus, event as any);
  const {nfts} = useExhibitNftsBySlug(event?.slug);

  const isUserHasAccess = useMemo(
    () =>
      Boolean(
        nfts?.find((nft) => nft.nftType === NftType.ACCESS && nft.purchaseStatus === CheckUserHasNftEnum.USER_HAS_NFT),
      ),
    [nfts],
  );

  const badgeText = getBadgeText(nfts || [], event.allowFreeAccess);
  const badge = getEventCardBadge(eventCardStatus, badgeText, isUserHasAccess);
  const imgSrc = useResponsiveImage(event.image);
  const {t, locale} = useLocalization();
  const classes = useStyles({isCuttedCard});
  const mediaClasses = useMediaStyles({});

  const [showShareModal, setShowShareModal] = useState(false);

  const [shareEventSlug, setShareEventSlug] = useState<string>();
  const [shareEventTitle, setShareEventTitle] = useState<string>();
  const [shareEventDescription, setShareEventDescription] = useState<string>();
  const [shareEventImageUrl, setShareEventImageUrl] = useState<string>();
  const toggleShareEvent = (exhibitionSlug: string, title: string, description: string, imageUrl: string): void => {
    setShowShareModal((val) => !val);
    setShareEventSlug(exhibitionSlug);
    setShareEventTitle(title);
    setShareEventDescription(description);
    setShareEventImageUrl(imageUrl);
  };

  const handleShareClick = (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    toggleShareEvent(event?.slug, event?.title, event?.description, buildAbsoluteCmsUrl(getMediaUrl(event?.image)));
  };

  const comingSoonDate: string | null = useMemo(
    () =>
      event.startsAt && dayjs(event.startsAt).isAfter(dayjs()) ? dayjs(event.startsAt).format('MM.DD.YYYY') : null,
    [event.startsAt],
  );

  return (
    <>
      <StyledLink to={`/${locale}/home/exhibit/${event.slug}`} className={clsx(link, GoogleAnalyticsId.ViewExhibitBtn)}>
        <Card className={clsx(classes.root, className)}>
          <Box className={clsx(classes.mediaWrapper, withOverlay && classes.mediaWrapperCentred)}>
            <CardMedia classes={mediaClasses} className={withOverlay ? classes.overlay : ''} image={imgSrc} />
            {withOverlay && (
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                className={classes.overlayText}
              >
                {t('Coming soon')}
                <Box className={classes.overlayTextDate}>{comingSoonDate}</Box>
              </Box>
            )}
          </Box>
          {badge && <Box className={classes.badge}>{badge}</Box>}
          <ShareIcon onClick={handleShareClick} className={classes.share} />
          <Box className={classes.wrapper}>
            <Box className={classes.cardContent}>
              <Box className={classes.date}>{eventCardDatesComponent}</Box>
              <Box className={classes.title}>{event.title}</Box>
              {!noAvatar && (
                <Box className={classes.avatar}>
                  <Avatar
                    name={event.creator?.name}
                    imageUrl={event.creator?.avatar?.url}
                    creatorId={event.creator?.id}
                    isCutTitle
                    isExhibitCard
                    isCuttedCard={isCuttedCard}
                  />
                </Box>
              )}
            </Box>
          </Box>
        </Card>
      </StyledLink>
      <Share
        isOpen={showShareModal}
        onClose={() => {
          toggleShareEvent(
            shareEventSlug || '',
            shareEventTitle || '',
            shareEventDescription || '',
            shareEventImageUrl || '',
          );
        }}
        title={t('Share exhibit')}
        description={shareEventTitle || ''}
        imgSrc={shareEventImageUrl || ''}
        url={getShareUrl(
          `/${locale}/home/exhibit/${shareEventSlug}`,
          shareEventTitle || '',
          shareEventDescription || '',
          shareEventImageUrl || '',
        )}
        entity="exhibit"
      />
    </>
  );
};
