import {Box, makeStyles} from '@material-ui/core';
import {Typography} from '@cere/rxb-template-ui-kit';
import colors from '../../../styles/colors';
import {ReactComponent as CopyIcon} from '../../../assets/icons/copy.svg';
import {ReactComponent as CheckIcon} from '../../../assets/check.svg';
import {useContext, useRef, useState} from 'react';
import {POPUP_TIMEOUT_BEFORE_CLOSE} from '../../../config/common';
import {ReactComponent as CereIcon} from '../../../assets/coins/cere.svg';
import {copyToClipboard} from '../../lib/utils';
import {UserContext} from '../../../context/user-context/user-context';
import {NonCustodyWalletTypeEnum} from '../../types/non-custody-wallet';

const useStyles = makeStyles(() => ({
  root: {
    alignSelf: 'flex-end',
    maxWidth: '220px',
    margin: '16px 16px 0 16px',
    padding: '6px',
    backgroundColor: colors.light,
    borderRadius: '100px',
    display: 'flex',
    flexDirection: 'row',
    '& svg:nth-child(1)': {
      width: '24px',
      height: '24px',
      backgroundColor: colors.light,
    },
  },
  address: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    width: 'calc(100% - 40px)',
    height: '1.2em',
    whiteSpace: 'nowrap',
  },
}));

interface WalletAddressChipParams {
  walletType: NonCustodyWalletTypeEnum;
}

export const WalletAddressChip = ({walletType}: WalletAddressChipParams) => {
  const classes = useStyles();
  const timeout = useRef<number>();
  const {nonCustodyWallets} = useContext(UserContext);
  const address = nonCustodyWallets.find((wallet) => wallet.type === walletType)?.publicKey;
  const [showSnackbar, setShowSnackbar] = useState(false);

  const handleCopyClick = (e: React.MouseEvent<SVGSVGElement>) => {
    address && copyToClipboard(e, address, onSnackBarShow);
  };

  const onSnackBarShow = () => {
    setShowSnackbar(true);

    timeout.current = window.setTimeout(() => {
      setShowSnackbar(false);
    }, POPUP_TIMEOUT_BEFORE_CLOSE);
  };

  if (!address) {
    return null;
  }

  return (
    <Box className={classes.root}>
      <CereIcon />
      <Typography className={classes.address}>{address}</Typography>
      {showSnackbar ? <CheckIcon /> : <CopyIcon onClick={handleCopyClick} />}
    </Box>
  );
};
