import {gql, useQuery} from '@apollo/client';
import {useUserWalletsPubKeys} from '../use-user-wallets-pub-keys';
import {PendingTransactionTypes, usePendingTransactionsContext} from './index';
import {useEffect, useState} from 'react';

const query = gql`
  query purchasedNfts($wallets: [String], $nftsIds: [String]) {
    creatorWalletNfts(where: {wallet_in: $wallets, nft_id: {nft_id_in: $nftsIds}}) {
      nft_id {
        nft_id
      }
    }
  }
`;

type PurchasedNft = {
  nft_id: {nft_id: string};
};

const POLLING_INTERVAL = 5000; // 5 seconds

export const usePurchasedNftsPolling = () => {
  const [nftsIds, setNftsIds] = useState<string[]>([]);
  const wallets = useUserWalletsPubKeys();
  const {getPendingTransactionsByType, clearPendingTransactionForNft} = usePendingTransactionsContext();
  const {data, startPolling, stopPolling} = useQuery<{creatorWalletNfts: PurchasedNft[]}>(query, {
    variables: {wallets, nftsIds},
    skip: !wallets.length || !nftsIds.length,
  });

  useEffect(() => {
    const pendingPurchaseTransactions = getPendingTransactionsByType(PendingTransactionTypes.BUY_NFT);
    setNftsIds(pendingPurchaseTransactions.map((transaction) => transaction.nftId));
  }, [getPendingTransactionsByType, setNftsIds]);

  useEffect(() => {
    if (!nftsIds.length) {
      stopPolling();
      return;
    }
    startPolling(POLLING_INTERVAL);
    return stopPolling;
  }, [nftsIds, stopPolling, startPolling]);

  useEffect(() => {
    if (!data) {
      return;
    }
    const purchasedNfts = data.creatorWalletNfts;
    purchasedNfts.forEach((nft) => {
      if (nftsIds.includes(nft.nft_id.nft_id)) {
        clearPendingTransactionForNft(nft.nft_id.nft_id);
      }
    });
  }, [data, nftsIds, clearPendingTransactionForNft]);

  return nftsIds;
};
