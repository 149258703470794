import {makeStyles} from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  mainBox: {
    overflowY: 'auto',
    padding: '0',
  },
  teaserContentMb: {
    marginBottom: '16px',

    [theme.breakpoints.up('md')]: {
      marginBottom: '74px',
    },
    [theme.breakpoints.up('lg')]: {
      marginBottom: '88px',
    },
  },
  teaserContentMt: {
    marginTop: '84px',

    [theme.breakpoints.up('md')]: {
      marginTop: '132px',
    },
    [theme.breakpoints.up('lg')]: {
      marginTop: '140px',
    },
  },
  topCardsContainer: {
    width: '100%',
    padding: '32px 0 0 0',

    [theme.breakpoints.up('md')]: {
      padding: '44px 6px 24px 6px',
    },
    [theme.breakpoints.up('lg')]: {
      padding: '80px 6px 44px 6px',
    },
  },
  topCardsContainerLive: {
    width: '100%',
    padding: '32px 8px',

    [theme.breakpoints.up('md')]: {
      padding: '44px 0',
    },
  },
  exhibitPageBottomCards: {
    padding: '0 6px 0 6px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '100%',
    marginTop: '25px',

    [theme.breakpoints.up('md')]: {
      padding: '0 0 35px 0',
    },
  },
  title: {
    fontSize: '20px',
    fontWeight: 'bold',
    margin: '0 0 16px 0',
    [theme.breakpoints.up('md')]: {
      fontSize: '28px',
      fontWeight: 800,
      margin: '0 0 24px 0',
    },
  },
}));
