import {EventTimelineStatus} from '../types/event';
import {ExhibitEnterButtonVariant} from '../../pages/ExhibitPage/routes/WebInterstitialHomePage/Exhibition-preview-container/exhibit-live-enter-button';

export const getButtonVariantByStatus = (eventTimelineStatus: EventTimelineStatus): ExhibitEnterButtonVariant => {
  if (eventTimelineStatus === EventTimelineStatus.FINISHED) {
    return 'secondary';
  }

  return 'primary';
};
