import {alpha, makeStyles, Theme} from '@material-ui/core';

export const useStyles = makeStyles<Theme, {comingSoon: boolean}>((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative',
    padding: theme.spacing(1.5),
    borderRadius: '12px',
    boxShadow: `0px 4px 20px ${alpha(theme.palette.common.black, 0.12)}`,
  },
  imageBox: {
    position: 'relative',
    width: '100px',
    minWidth: '100px',
    height: '100px',
    borderRadius: '12px',
    overflow: 'hidden',
  },
  image: {
    width: '100%',
    height: '100%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'auto',
    objectFit: 'cover',
  },
  comingSoonImage: {
    filter: 'blur(1.3rem)',
  },
  comingSoonText: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontSize: '10px',
    fontWeight: 600,
    color: theme.palette.primary.light,
    textAlign: 'center',
  },
  infoBox: {
    display: 'flex',
  },
  textBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    paddingLeft: theme.spacing(1.5),
  },
  buttonBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    '& button': {
      maxHeight: '36px',
    },
  },
  link: {
    textDecoration: 'none',
  },
  title: {
    fontWeight: 600,
    fontSize: theme.typography.fontSize,
    color: theme.palette.text.primary,
  },
  subTitle: {
    fontSize: 12,
    color: theme.palette.grey[700],
  },
  price: {
    fontWeight: 600,
    fontSize: 16,
  },
}));

export const useAdditionsBlockStyles = makeStyles((theme) => ({
  additionsBox: {
    [theme.breakpoints.up('lg')]: {
      display: 'grid',
      gridTemplateColumns: 'repeat(3, 1fr)',
      gridColumnGap: theme.spacing(2),
      gridRowGap: theme.spacing(2),
    },
  },
  additionsItem: {
    [theme.breakpoints.down('lg')]: {
      marginTop: theme.spacing(2),
    },
  },
  title: {
    fontWeight: 700,
    fontSize: '20px',
    lineHeight: '26px',
    marginTop: '40px',
    marginBottom: '16px',

    [theme.breakpoints.up('md')]: {
      fontWeight: 800,
      fontSize: '28px',
      lineHeight: '36px',
      marginBottom: '24px',
    },
    [theme.breakpoints.up('lg')]: {
      marginTop: '80px',
    },
  },
}));
