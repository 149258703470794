import {BrowserRouter as Router, Redirect, Route, Switch} from 'react-router-dom';
import {NftPageWrapper as NftPage} from './pages/NftPage';
import {InterstitialEventPage} from './pages/InterstitialEventPage';
import {UserNftsPage} from './pages/UserPages/user-nfts-page';
import {UserWalletPage} from './pages/UserPages/user-wallet-page';

import {ExhibitPage} from 'pages/ExhibitPage';
import CreatorPage from 'pages/CreatorPage';
import CreatorsPage from 'pages/CreatorsPage';
import {CreatorsPage as CreatorsPageV2} from './app-v2/pages/creators';
import {HomePage} from 'pages/HomePage';
import {HomePage as HomePageV2} from './app-v2/pages/home';
import ExhibitsPage from 'pages/ExhibitsPage';
import {SignInPage} from 'pages/SignInPage';
import NotFoundPage from 'pages/NotFoundPage';
import UserDepositFromP2pPage from './pages/UserPages/user-deposit-from-p2p-page';
import {UserDepositFromFiatPage} from './pages/UserPages/user-deposit-from-fiat-page';
import {ServiceCallback} from './pages/ServiceCallback/service-callback';
import {TransferNftsPage} from './pages/TransferNfts/transfer-nfts-page';
import {ErrorBoundary} from './shared/components/ErrorBoundary';
import {enableAppV2, enableFiatDepositPage, enableMarketplaceView, enableModernHomepage} from './config/common';
import {PurchaseNFTContextProvider} from './context/purchase-nft-context';
import {SellNftContextProvider} from './context/sell-nft-context';
import {Marketplace} from './pages/Marketplace';
import {MarketplacePage} from './app-v2/pages/marketplace';
import {NotificationsPage} from './pages/NotificationsPage';
import {ExternalAuthPage} from './pages/ExternalAuthPage';
import {TargetNotificationsPopup} from './shared/components/TargetNotification';
import {WithSimpleLocalizedRedirection} from 'shared/components/WithSimpleLocalizedRedirection';
import {SignInCompletePage} from './pages/SignInCompletePage';
import {AuctionContextProvider} from './context/auction.context';
import {PrimaryPurchaseContextProvider} from './context/primary-purchase-context';
import {ExternalAuthCallback} from './pages/ExternalAuthCallback';
import FeedPage from 'pages/FeedPage';
import {AppContainer} from './app-v2/components/connected/AppContainer';
import {SignInPage as SignInPageV2} from './app-v2/pages/sign-in';
import {HistoryProvider} from './app-v2/components/connected/HistoryProvider';
import {VerifyEmailPage} from './app-v2/pages/verify-email';
import {ApplicationModalContainer} from './app-v2/components/connected/ApplicationModalContainer';

export const AppRouter = () => {
  return (
    <Router>
      <HistoryProvider />
      <ApplicationModalContainer />
      <AuctionContextProvider>
        <PurchaseNFTContextProvider>
          <PrimaryPurchaseContextProvider>
            <SellNftContextProvider>
              <ErrorBoundary>
                <Switch>
                  <Route
                    exact
                    path="/"
                    render={({location}) => {
                      if (window.location.href.includes('#')) {
                        return <Redirect to={location.hash.replace('#', '')} />; // For backward compatibility
                      }

                      return <InterstitialEventPage />;
                    }}
                  />
                  <Route path="/:locale/home/auth/verify">
                    <WithSimpleLocalizedRedirection>
                      <VerifyEmailPage />
                    </WithSimpleLocalizedRedirection>
                  </Route>

                  <Route path="/:locale/home/auth">
                    <WithSimpleLocalizedRedirection>
                      {enableAppV2() ? <SignInPageV2 /> : <SignInPage />}
                    </WithSimpleLocalizedRedirection>
                  </Route>
                  <Route path="/:locale/auth/complete/:nftId">
                    <WithSimpleLocalizedRedirection>
                      <SignInCompletePage />
                    </WithSimpleLocalizedRedirection>
                  </Route>
                  <Route path="/:locale/auth/complete">
                    <WithSimpleLocalizedRedirection>
                      <SignInCompletePage />
                    </WithSimpleLocalizedRedirection>
                  </Route>
                  <Route path="/:locale/home/external-auth">
                    <WithSimpleLocalizedRedirection>
                      <ExternalAuthPage />
                    </WithSimpleLocalizedRedirection>
                  </Route>
                  <Route path="/:locale/external-auth-callback">
                    <WithSimpleLocalizedRedirection>
                      <ExternalAuthCallback />
                    </WithSimpleLocalizedRedirection>
                  </Route>
                  <Route path="/home/external-auth">
                    <WithSimpleLocalizedRedirection>
                      <ExternalAuthPage />
                    </WithSimpleLocalizedRedirection>
                  </Route>
                  {/*TODO it here for backward compatibility, remove after change successURL in interstitial page */}
                  <Route path="/:locale/home/artist/:artistId/exhibit/:exhibitSlug">
                    <ExhibitPage />
                  </Route>
                  <Route path="/:locale/home/nft/:cmsNftIncrementId">
                    <NftPage />
                  </Route>
                  <Route path="/:locale/home/exhibit/:exhibitSlug">
                    <ExhibitPage />
                  </Route>
                  <Route path="/:locale/home/creator/:artistId">
                    <CreatorPage />
                  </Route>
                  {enableFiatDepositPage() && (
                    <Route path="/:locale/home/user/wallet/deposit-fiat">
                      <WithSimpleLocalizedRedirection>
                        <UserDepositFromFiatPage />
                      </WithSimpleLocalizedRedirection>
                    </Route>
                  )}
                  <Route path="/:locale/home/user/wallet/deposit">
                    <WithSimpleLocalizedRedirection>
                      <UserDepositFromP2pPage />
                    </WithSimpleLocalizedRedirection>
                  </Route>
                  <Route path="/:locale/home/user/wallet/transfer">
                    <WithSimpleLocalizedRedirection>
                      <TransferNftsPage />
                    </WithSimpleLocalizedRedirection>
                  </Route>
                  <Route path="/:locale/home/user/wallet">
                    <WithSimpleLocalizedRedirection>
                      <UserWalletPage />
                    </WithSimpleLocalizedRedirection>
                  </Route>
                  <Route path="/:locale/home/user/nfts">
                    <WithSimpleLocalizedRedirection>
                      <UserNftsPage />
                    </WithSimpleLocalizedRedirection>
                  </Route>
                  <Route exact path="/:locale/creators">
                    <WithSimpleLocalizedRedirection>
                      {enableAppV2() ? (
                        <AppContainer>
                          <CreatorsPageV2 locale="en" />
                        </AppContainer>
                      ) : (
                        <CreatorsPage />
                      )}
                    </WithSimpleLocalizedRedirection>
                  </Route>
                  <Route exact path="/:locale/feed">
                    <WithSimpleLocalizedRedirection>
                      <FeedPage />
                    </WithSimpleLocalizedRedirection>
                  </Route>
                  {enableModernHomepage() && (
                    <Route exact path="/:locale/home">
                      <WithSimpleLocalizedRedirection>
                        {enableAppV2() ? (
                          <AppContainer>
                            <HomePageV2 />
                          </AppContainer>
                        ) : (
                          <HomePage />
                        )}
                      </WithSimpleLocalizedRedirection>
                    </Route>
                  )}
                  {enableModernHomepage() && (
                    <Route exact path="/:locale/exhibits">
                      <WithSimpleLocalizedRedirection>
                        <ExhibitsPage />
                      </WithSimpleLocalizedRedirection>
                    </Route>
                  )}
                  {!enableModernHomepage() && (
                    <Route exact path="/:locale/home">
                      <WithSimpleLocalizedRedirection>
                        <ExhibitsPage />
                      </WithSimpleLocalizedRedirection>
                    </Route>
                  )}
                  {enableMarketplaceView() && (
                    <Route path="/:locale/marketplace">
                      {enableAppV2() ? (
                        <MarketplacePage />
                      ) : (
                        <WithSimpleLocalizedRedirection>
                          <Marketplace />
                        </WithSimpleLocalizedRedirection>
                      )}
                    </Route>
                  )}
                  <Route path="/:locale/service/callback">
                    <WithSimpleLocalizedRedirection>
                      <ServiceCallback />
                    </WithSimpleLocalizedRedirection>
                  </Route>
                  <Route path="/:locale/notifications">
                    <WithSimpleLocalizedRedirection>
                      <NotificationsPage />
                    </WithSimpleLocalizedRedirection>
                  </Route>
                  <Route path="/:locale/not-found">
                    <WithSimpleLocalizedRedirection>
                      <NotFoundPage />
                    </WithSimpleLocalizedRedirection>
                  </Route>
                  <Route path="/:locale/">
                    <InterstitialEventPage />
                  </Route>
                  <Route>
                    <NotFoundPage />
                  </Route>
                </Switch>
                <TargetNotificationsPopup />
              </ErrorBoundary>
            </SellNftContextProvider>
          </PrimaryPurchaseContextProvider>
        </PurchaseNFTContextProvider>
      </AuctionContextProvider>
    </Router>
  );
};

export default Router;
