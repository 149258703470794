import {ReactElement, ReactNode, useContext, useMemo} from 'react';
import {Link, useHistory} from 'react-router-dom';
import {makeStyles} from '@material-ui/core';
import {NftContext} from '../Nft/nft-context-v2';
import clsx from 'clsx';
import {Condition, ConditionsList, Defaults} from '../Conditions';
import {AvailabilityStatus} from '@cere/services-types';

const useStyles = makeStyles({
  link: {
    textDecoration: 'none',
    color: 'currentColor',
  },
  block: {
    display: 'block',
  },
});

type Props = {
  children: ReactNode;
  block?: boolean;
  className?: string;
};

export function NftLink({children, block, className}: Props): ReactElement {
  const styles = useStyles();
  const {attributes, nft, event} = useContext(NftContext);
  const history = useHistory();

  const link = useMemo(
    () => ({
      pathname: attributes.link,
      state: {
        referrer: history.location.pathname,
        event: event,
        nft: nft,
      },
    }),
    [attributes.link, event, nft, history.location.pathname],
  );

  return (
    <ConditionsList>
      <Condition condition={nft.availability === AvailabilityStatus.COMING_SOON}>{children}</Condition>
      <Defaults>
        <Link to={link} className={clsx(styles.link, className, block && styles.block)}>
          {children}
        </Link>
      </Defaults>
    </ConditionsList>
  );
}
