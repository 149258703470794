import {CircularProgress, Grid, makeStyles} from '@material-ui/core';
import {useCallback, useEffect} from 'react';
import {useTorusWalletAddress, useTorusWalletStatus} from '../../context/torus-context';
import {useHistory, useParams} from 'react-router-dom';
import {secondaryOfferPrice, useStartNFTPurchase} from '../../context/purchase-nft-context';
import {useLocalization} from '../../shared/hooks/use-locale.hook';
import {useLocalStorage} from '../../shared/hooks/use-local-storage.hook';
import {useStartPrimaryPurchase} from '../../context/primary-purchase-context';
import {nftApi} from '../../api';
import {NftCardInterface, NftSellingType} from '@cere/services-types';

interface SignInCompleteParams {
  nftId: string;
}

const useStyles = makeStyles((theme) => ({
  backLinkBox: {
    padding: '2rem 0 0 1rem',
  },
  backLinkBoxButton: {
    minWidth: 'unset',
  },
  box: {
    margin: '0 auto',
    padding: '2rem',
    [theme.breakpoints.up('md')]: {
      maxWidth: '480px',
    },
  },
  button: {
    margin: '0 auto 0',
    display: 'block',
    padding: '13px 19px',
    [theme.breakpoints.up('md')]: {
      padding: '13px 37px',
    },
    [theme.breakpoints.up('lg')]: {
      margin: 'unset',
    },
  },
  payByCardLink: {
    textAlign: 'center',
    color: theme.palette.secondary.main,
    fontWeight: 600,
    padding: '15px',
    cursor: 'pointer',
  },
  loaderWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
    height: '100vh',
  },
}));

export const SignInCompletePage = () => {
  const {locale} = useLocalization();
  const {nftId} = useParams<SignInCompleteParams>();
  const styles = useStyles();
  const {address, loading, error} = useTorusWalletAddress();
  const walletStatus = useTorusWalletStatus();
  const history = useHistory<{pathname: string | undefined}>();
  const startPrimaryPurchase = useStartPrimaryPurchase();
  const startSecondaryPurchase = useStartNFTPurchase();
  const {value: nexPageValue} = useLocalStorage(`userNextPage`);

  const getNextPage = useCallback(() => {
    return nexPageValue || history?.location?.state?.pathname || `${locale}/home`;
  }, [history?.location?.state?.pathname, locale, nexPageValue]);

  const redirect = useCallback(async () => {
    if (nftId) {
      const nftData: NftCardInterface | null = await nftApi.getNftById(nftId, locale);
      if (!nftData) {
        return;
      }
      const isPrimary = nftData?.sellingType !== NftSellingType.IS_SECONDARY;
      if (isPrimary && !secondaryOfferPrice) {
        startPrimaryPurchase(
          {
            nftId: nftData.id,
            nftAddress: nftData.address,
            nftMinterAddress: nftData.minter,
            nftTitle: nftData.title,
            nftImage: nftData.image,
            nftCreatorName: nftData.creatorName,
            nftUsdPrice: nftData.priceUsd,
            nftAmount: 1,
            nftEventSlug: nftData.exhibitionSlug || '',
            nftCollectionAddress: nftData.collectionAddress,
          },
          true,
        );
      } else {
        startSecondaryPurchase({...nftData, amount: 1}, true);
      }
    }
    history.push(getNextPage());
  }, [locale, nftId, startPrimaryPurchase, getNextPage, startSecondaryPurchase, history]);

  useEffect(() => {
    if (!loading && address && walletStatus === 'connected') {
      redirect();
    }
    if (error) {
      console.error(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, loading, address, nftId, walletStatus]);

  return (
    <Grid item xs={12} className={styles.loaderWrapper}>
      <CircularProgress size={36} thickness={2} color="inherit" />
    </Grid>
  );
};
