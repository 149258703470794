import {Field} from 'react-final-form';
import {Box, FormControl, Paper, TextField, Typography} from '@material-ui/core';
import {useListingPriceStyles} from './styles';
import {AnyObject} from 'final-form';
import {useCallback} from 'react';
import ErrorIcon from '@material-ui/icons/Error';
import {BID_RAISE_PERCENT} from '../../../model/auction/Auction';

const INPUT_LABEL_PROPS = {
  shrink: true,
};

export interface ListingBlockProps {
  styles: ReturnType<typeof useListingPriceStyles>;
  nftPrice: number;
  isProcessing: boolean;
  hasValidationErrors: boolean;
  errors?: AnyObject;
}

export const AuctionBlock = ({styles, nftPrice, hasValidationErrors, errors, isProcessing}: ListingBlockProps) => {
  const valueValidator = useCallback(
    (value: number) => {
      const minBid = nftPrice + (nftPrice * BID_RAISE_PERCENT) / 100;
      if (!value || value < minBid) {
        return `Minimal acceptable bid has to be more than ${minBid}$`;
      }

      return null;
    },
    [nftPrice],
  );

  return (
    <>
      <Box className={styles.fieldsContainer}>
        <Field name="quantity">
          {({input}) => (
            <FormControl variant="outlined" disabled={isProcessing}>
              <TextField
                className={styles.input}
                type="number"
                variant="outlined"
                label="Quantity"
                {...input}
                disabled
                InputLabelProps={INPUT_LABEL_PROPS}
              />
            </FormControl>
          )}
        </Field>
        <Field name="currency">
          {({input}) => (
            <TextField
              className={styles.input}
              variant="outlined"
              label="Currency"
              {...input}
              disabled
              InputLabelProps={INPUT_LABEL_PROPS}
            />
          )}
        </Field>
        <Field name="auctionStartingPrice" validate={valueValidator}>
          {({input}) => (
            <FormControl variant="outlined" disabled={isProcessing}>
              <TextField
                className={styles.input}
                type="number"
                variant="outlined"
                label="Minimal acceptable bid"
                {...input}
                disabled={isProcessing}
                InputLabelProps={INPUT_LABEL_PROPS}
              />
            </FormControl>
          )}
        </Field>
      </Box>
      {hasValidationErrors && (
        <Paper className={styles.errorMessage} elevation={0}>
          <ErrorIcon fontSize="small" />
          <Typography className={styles.errorText}>{errors?.auctionStartingPrice}</Typography>
        </Paper>
      )}
    </>
  );
};
