import {memo} from 'react';
import {Box, Typography, makeStyles} from '@material-ui/core';

import {ImageSquare} from '../ImageSquare';
import {ReactComponent as VerifiedCreator} from './verifiedCreator.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '82px',
    padding: '4px',
    display: 'flex',
    borderRadius: '12px',
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.12)',
    backgroundColor: theme.palette.background.paper,
  },
  imageBox: {
    minWidth: '74px',
    width: '74px',
    borderRadius: '12px',
    overflow: 'hidden',
  },

  infoBox: {
    paddingLeft: '16px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: '2px',
    maxWidth: 'calc(100% - 78px)',
  },

  creator: {
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
    fontSize: '12px',
  },
  creatorName: {
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '20px',
    color: theme.palette.grey[700],
    maxWidth: '95%',
    whiteSpace: 'nowrap',
    overflowX: 'hidden',
    textOverflow: 'ellipsis',
  },
  title: {
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '20px',
    color: theme.palette.text.primary,
    whiteSpace: 'nowrap',
    overflowX: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

type Props = {
  image: string;
  title: string;
  creator: string;
};

export const NftModalHighlightCard = memo(({image, title, creator}: Props) => {
  const styles = useStyles();

  return (
    <Box className={styles.root}>
      <Box className={styles.imageBox}>
        <ImageSquare image={image} title={title} />
      </Box>
      <Box className={styles.infoBox}>
        <Box className={styles.creator}>
          <Typography className={styles.creatorName}>{creator}</Typography>
          <VerifiedCreator />
        </Box>

        <Typography className={styles.title}>{title}</Typography>
      </Box>
    </Box>
  );
});
