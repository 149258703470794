import {useQuery} from '@apollo/client';
import {createContext, ReactElement, ReactNode, useContext, useEffect, useMemo, useState} from 'react';
import {GET_NFTS_AUCTIONS} from '../shared/queries';
import {CreatorAuction} from '../shared/types/graphql';
import {AUCTION_UPDATE_DELAY_TIMEOUT} from '../config/common';
import {isAuctionedNft} from '../shared/helpers/auction';
import {omitUndefined} from '../shared/lib/omit-undefined';
import {ExhibitContext} from './exhibition/exhibit-context';
import {removeProcessingBid} from '../shared/services/auction.service';
import {isNumberish, Numberish} from '../shared/types/numberish';

type Props = {
  children: ReactNode;
  nftIds?: Numberish[];
};

export type AuctionsContextType = {
  auctionNfts: Record<string, CreatorAuction>;
  setAuctionNftIncrementIds: Function;
  refetch: Function;
};

export const AuctionsContext = createContext<AuctionsContextType>({
  auctionNfts: {},
  setAuctionNftIncrementIds: () => {},
  refetch: () => {},
});

export function AuctionsContextProvider({children, nftIds = []}: Props): ReactElement {
  const {exclusiveNfts} = useContext(ExhibitContext);
  const auctionNftIds = useMemo(
    () =>
      exclusiveNfts
        .filter(isAuctionedNft)
        .map((nft) => nft.cmsNft?.creatorNft?.id)
        .filter(omitUndefined)
        .filter(isNumberish)
        .concat(nftIds),
    [exclusiveNfts, nftIds],
  );
  const [auctionNftIncrementIds, setAuctionNftIncrementIds] = useState(auctionNftIds);

  const {data, refetch} = useQuery<
    {creatorNfts: Array<{creator_auctions: CreatorAuction[]; nft_id: string; id: string}>},
    {ids: Numberish[]}
  >(GET_NFTS_AUCTIONS, {
    variables: {ids: auctionNftIncrementIds.length ? [...auctionNftIncrementIds] : []},
  });

  const auctionNfts = useMemo(() => {
    return (
      data?.creatorNfts?.reduce((acc, creatorAuction) => {
        creatorAuction.creator_auctions[0]?.creator_auction_bids.forEach((bid) => {
          removeProcessingBid(bid.txHash!, creatorAuction.nft_id);
        });

        return {
          ...acc,
          ...{[creatorAuction.nft_id]: creatorAuction.creator_auctions[0]}, // Consider only 1 auction per NFT
        };
      }, {}) || {}
    );
  }, [data?.creatorNfts]);

  useEffect(() => {
    let auctionsInterval: number | undefined;
    if (auctionNftIncrementIds.length) {
      auctionsInterval = window.setInterval(() => {
        void refetch();
      }, AUCTION_UPDATE_DELAY_TIMEOUT);
    } else {
      clearInterval(auctionsInterval);
    }

    return () => {
      clearInterval(auctionsInterval);
    };
  }, [auctionNftIncrementIds, refetch]);

  const context = useMemo(() => ({auctionNfts, setAuctionNftIncrementIds, refetch}), [auctionNfts, refetch]);

  return <AuctionsContext.Provider value={context}>{children}</AuctionsContext.Provider>;
}
