import {FC, ReactElement, useContext, useMemo} from 'react';
import {Box, makeStyles} from '@material-ui/core';
import {checkNftMayBePurchasedV2, getNftCardBadge, isNftCardBlured} from './helpers';
import {NftCardBigBase} from './NftCardBigBase';
import {NftCardBigInfo} from './NftCardBigInfo';
import {NftContext} from '../Nft/nft-context-v2';
import {useUserHasEventAccess} from '../../../pages/ExhibitPage/use-user-has-event-access';
import {ExhibitContext} from '../../../context/exhibition/exhibit-context';
import {AvailabilityStatus} from '@cere/services-types';
import {NftActionButton} from '../ActionButtons';

interface NftCardBigNotStartedProps {
  withUnlockableContent: boolean;
  buttonText?: string;
  badge?: ReactElement | null;
}

const useStyles = makeStyles((theme) => ({
  button: {
    width: '100%',
    '&:hover': {
      backgroundColor: theme.palette.common.white,
    },
  },
}));

export const NftCardBigNotStarted: FC<NftCardBigNotStartedProps> = ({withUnlockableContent = false, badge}) => {
  const styles = useStyles();
  const {attributes, nft, creator, balance} = useContext(NftContext);
  const {eventTimelineStatus} = useContext(ExhibitContext);
  const hasEventAccess = useUserHasEventAccess();
  const cardBadge = useMemo(
    () =>
      badge ||
      getNftCardBadge(hasEventAccess && attributes.purchased, withUnlockableContent, attributes.hasPendingTransaction),
    [attributes.purchased, badge, hasEventAccess, withUnlockableContent, attributes.hasPendingTransaction],
  );

  const hasProperCreatorNft = useMemo(() => creator?.id, [creator?.id]);
  const isSharable = nft.availability !== AvailabilityStatus.COMING_SOON;
  const withOverlay =
    isNftCardBlured(
      attributes.purchased,
      checkNftMayBePurchasedV2(eventTimelineStatus, nft.nftType, attributes.availability, balance ?? 0),
      attributes.availability,
    ) || !hasProperCreatorNft;

  return (
    <NftCardBigBase badge={cardBadge} withShare={isSharable} withOverlay={withOverlay}>
      <NftCardBigInfo />
      <Box mt="auto">
        <Box mt="12px">
          <NftActionButton className={styles.button} />
        </Box>
      </Box>
    </NftCardBigBase>
  );
};
