import {Box, Button, makeStyles} from '@material-ui/core';
import {useFilter} from '../../../context/filters-context';
import {ReactComponent as PlaceholderImage} from './assets/filter_placeholder.svg';
import {Typography} from '../UI';
import colors from '../../../styles/colors';
import {useLocalization} from '../../hooks/use-locale.hook';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  image: {
    width: '158px',
    height: '141px',

    [theme.breakpoints.up('lg')]: {
      width: '185px',
      height: '165px',
    },
  },
  header: {
    marginTop: '24px',
    fontWeight: 700,
    fontSize: '20px',
    lineHeight: '26px',
    color: colors.lightGrey,
  },
  subHeader: {
    marginTop: '6px',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '21px',
    color: colors.lightGrey,
  },
  button: {
    marginTop: '12px',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '22px',
    color: theme.palette.secondary.main,
    textTransform: 'none',
  },
}));

export const Placeholder = () => {
  const {t} = useLocalization();
  const {clearFilters} = useFilter();
  const styles = useStyles();
  return (
    <Box className={styles.container}>
      <PlaceholderImage className={styles.image} />
      <Typography className={styles.header}>{t('No results match')}</Typography>
      <Typography className={styles.subHeader}>{t('The active filters are hiding all NFT collectibles')}</Typography>
      <Button className={styles.button} onClick={clearFilters}>
        {t('Clear filters')}
      </Button>
    </Box>
  );
};
