import {makeStyles} from '@material-ui/core';
import colors from '../../../styles/colors';
import {mobileLandscapeMediaQuery} from '../../../styles/mediaQueries';

export const useListingPriceStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '24px',
  },
  description: {
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
    marginBottom: '24px',
    [mobileLandscapeMediaQuery(theme)]: {
      textAlign: 'center',
    },
  },
  typeSelector: {
    marginBottom: '24px',
    [mobileLandscapeMediaQuery(theme)]: {
      maxWidth: '438px',
      margin: '0 auto 18px auto',
    },
  },
  fieldsContainer: {
    width: '100%',
    display: 'grid',
    gridRowGap: '16px',
    gridColumnGap: '16px',
    paddingBottom: '16px',
    [mobileLandscapeMediaQuery(theme)]: {
      gridTemplateColumns: 'repeat(3, 1fr)',
    },
  },
  label: {
    padding: '0 5px',
    marginLeft: '-5px',
    background: colors.light,
  },
  input: {
    height: '56px',
    borderRadius: '12px',
    '& .MuiOutlinedInput-root': {
      borderRadius: '12px',
      background: colors.light,
      border: 'none',
    },
    '& fieldset': {
      border: `1px solid ${colors.lighter}`,
    },
  },
  errorMessage: {
    display: 'flex',
    padding: '12px',
    background: 'rgba(255, 81, 81, 0.1)',
    borderRadius: '12px',
    marginBottom: '16px',
    color: colors.error,
  },
  errorText: {
    fontWeight: 500,
    fontSize: '13px',
    lineHeight: '22px',
    color: colors.error,
    marginLeft: '10px',
  },
  royaltiesContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'start',
    paddingBottom: '24px',
    '& svg, & path': {
      color: theme.palette.info.main,
      fill: theme.palette.info.main,
    },
    [mobileLandscapeMediaQuery(theme)]: {
      paddingBottom: '16px',
      justifyContent: 'start',
      alignItems: 'center',
    },
  },
  royaltiesContent: {
    display: 'flex',
    alignItems: 'start',
    '& p': {
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '24px',
      margin: '0 8px',
    },
  },
  dot: {
    marginTop: '6px',
    width: '12px',
    height: '12px',
  },
  tooltip: {
    width: '16px',
    height: '16px',
    marginTop: '4px',
    '& svg': {
      width: '16px',
      height: '16px',
    },
    [`@media (orientation: landscape)`]: {
      margin: 0,
    },
  },
  button: {
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '20px',
    borderRadius: '50px',
    border: `1px solid ${colors.primaryDark}`,
    textTransform: 'none',
  },
  buttonContainer: {
    height: '36px',
    flexBasis: '50%',
    '& button': {
      width: '100%',
      height: '100%',
    },
    '&:not(:last-child)': {
      marginRight: '13px',
    },
    [mobileLandscapeMediaQuery(theme)]: {
      maxWidth: '170px',
    },
  },
  controlsContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    [mobileLandscapeMediaQuery(theme)]: {
      justifyContent: 'end',
    },
  },
}));

export const useModalStyles = makeStyles((theme) => ({
  header: {
    [mobileLandscapeMediaQuery(theme)]: {
      flexDirection: 'column',
      justifyContent: 'center',
    },
  },
}));
