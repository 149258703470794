import {Box, Container, Grid, makeStyles, Chip} from '@material-ui/core';
import {Typography} from '@cere/rxb-template-ui-kit';
import {ExhibitionStatus} from '@cere/services-types';
import {useQuery} from '@apollo/client';
import clsx from 'clsx';
import {useCallback, useState} from 'react';

import {useAllExhibits} from '../../api/hooks/use-all-exhibit';
import {usePageLoaded} from '../../shared/services/loading.service';
import {useOtpVerification} from '../../shared/hooks/use-otp-verification.hook';
import {ExhibitionsList} from './exhibitions-list';
import {PageLayout} from '../../shared/components/PageLayout';
import {CMS_HOMES} from 'shared/queries';
import {CmsHome} from '../HomePage/types';
import {AppMetaTags} from '../../shared/components/SeoHeaders/seo-headers.component';
import {useLocalization} from '../../shared/hooks/use-locale.hook';
import {AVAILABLE_LANGUAGES} from '../../config/common';

const useStyles = makeStyles((theme) => ({
  grey: {
    color: theme.palette.grey[700],
  },
  container: {
    [theme.breakpoints.up('md')]: {
      maxWidth: '688px',
      minWidth: '688px',
      margin: '0 auto',
      padding: '0',
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: '1280px',
      minWidth: '1280px',
    },
  },
  filtersBox: {
    display: 'flex',
    gap: theme.spacing(1.5),

    margin: '32px 0 32px 0',
    [theme.breakpoints.up('md')]: {
      margin: '40px 0 32px 0',
    },
    [theme.breakpoints.up('lg')]: {
      margin: '48px 0 32px 0',
    },
  },
  filterItem: {
    height: '30px',
    padding: '4px 12px',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '22px',

    [theme.breakpoints.up('md')]: {
      height: '34px',
      padding: '6px 16px',
      fontSize: '16px',
    },

    '&.MuiChip-clickable:focus': {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.common.white,
    },

    '&.active': {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.common.white,
    },
  },
}));

function ExhibitsPage() {
  usePageLoaded();
  useOtpVerification();
  const classes = useStyles();
  const {t} = useLocalization();

  const [timelineStatus, setTimelineStatus] = useState<ExhibitionStatus>();

  const {exhibits, loading, error} = useAllExhibits({timelineStatus});

  const onChange = useCallback(
    (value: ExhibitionStatus) => setTimelineStatus(value === timelineStatus ? undefined : value),
    [timelineStatus],
  );

  // TODO - move to BFF
  const {data: homeData} = useQuery<{homes: CmsHome[]}, {limit: number}>(CMS_HOMES);

  if (error) {
    throw error;
  }

  const filterOptions = [
    {value: ExhibitionStatus.STARTED, label: t('Live now')},
    {value: ExhibitionStatus.NOT_STARTED, label: t('Upcoming')},
    {value: ExhibitionStatus.FINISHED, label: t('Closed')},
  ];

  const localizations = AVAILABLE_LANGUAGES().map((language) => ({
    locale: language,
    link: `/${language}/exhibits`,
  }));

  return (
    <PageLayout hideBackButton>
      <AppMetaTags
        title={homeData?.homes[0]?.seoTitle || t('Davinci - NFT Experience & Marketplace Platform')}
        description={
          homeData?.homes[0]?.seoDescription ||
          t(
            'Innovative NFT Experience & Marketplace Platform - World-class artists, hyper-personalized NFT experiences, and decentralized ownership',
          )
        }
        canonical={homeData?.homes[0]?.seoCanonical}
        image={homeData?.homes[0]?.seoImage?.url}
        localizations={localizations}
      />
      <Container maxWidth="lg" classes={{root: classes.container}}>
        <Box mt="32px" mb="28px">
          <Typography variant="h3">{t('Exhibits')}</Typography>

          <Box mt="4px" mb="20px">
            <Typography variant="body2" className={classes.grey}>
              {t('Hand-curated and unique artist experiences. View, buy, and auction for valuable NFT collectibles.')}
            </Typography>
          </Box>

          <Box className={classes.filtersBox}>
            {filterOptions.map(({value, label}) => (
              <Chip
                key={value}
                className={clsx(value === timelineStatus && 'active')}
                classes={{root: classes.filterItem}}
                label={label}
                onClick={onChange.bind(null, value)}
                size="small"
              />
            ))}
          </Box>

          <Grid container spacing={4}>
            <ExhibitionsList exhibitions={exhibits} loading={loading} isSorted />
          </Grid>
        </Box>
      </Container>
    </PageLayout>
  );
}

export default ExhibitsPage;
