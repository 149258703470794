import {FC, MouseEvent, ReactElement} from 'react';
import {Box, makeStyles, Typography} from '@material-ui/core';
import {Icon} from '@cere/rxb-template-ui-kit';
import clsx from 'clsx';
import {noop} from 'lodash';
import {ReactComponent as Share} from '../../../assets/icons/share-light.svg';
import colors from '../../../styles/colors';
import {useLocalization} from '../../hooks/use-locale.hook';

const useStyles = makeStyles(() => ({
  root: {
    background: `${colors.primaryDark}BF`,
    cursor: 'pointer',
  },
  desktopWrapper: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: '43px',
    padding: '3px 29px 3px 18px',
  },
  text: {
    color: colors.white,
    paddingTop: '2px',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px',
  },
}));

interface ShareIconProps {
  className?: string;
  onClick?: (e: MouseEvent) => void;
  isShort?: boolean;
}

export const ShareIcon: FC<ShareIconProps> = ({onClick = noop, className, isShort = false}): ReactElement => {
  const {t} = useLocalization();
  const classes = useStyles();

  return isShort ? (
    <Box className={clsx(classes.root, classes.desktopWrapper)}>
      <Icon size="md" onClick={onClick} icon={<Share />} iconClassName={className} />
      <Typography className={classes.text}>{t('Share')}</Typography>
    </Box>
  ) : (
    <Icon size="md" onClick={onClick} icon={<Share />} iconClassName={clsx(classes.root, className)} />
  );
};
