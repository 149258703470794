import {useContext} from 'react';
import {Box, makeStyles} from '@material-ui/core';
import {Typography} from '@cere/rxb-template-ui-kit';
import clsx from 'clsx';
import colors from '../../../styles/colors';
import {formatNumber} from '../../lib/formatNumber';
import {NftCardBigDescription} from './NftCardBigDescription';
import {Condition, ConditionsList, Defaults} from '../Conditions';
import {NftContext} from '../Nft/nft-context-v2';
import {formatPrice, usdcUnitsToUSD} from '../../lib/formatPrice';
import {NftLink} from './nft-link';
import {Trans} from 'react-i18next';
import {AvailabilityStatus, NftType, CheckUserHasNftEnum} from '@cere/services-types';

const useStyles = makeStyles(() => ({
  titleBlock: {
    marginBottom: '6px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  priceColor: {
    color: colors.primaryDark,
  },
  grey: {
    color: colors.lightGrey,

    '& strong': {
      fontSize: '16px',
    },
  },
  accentInactive: {
    opacity: '0.6',
  },
}));

export const NftCardBigInfo = () => {
  const classes = useStyles();
  const {nft, attributes, auction, price} = useContext(NftContext);
  const inactivePrice = nft.purchaseStatus === CheckUserHasNftEnum.USER_HAS_NFT;
  const isAuction = nft.nftType === NftType.AUCTIONED;
  const isComingSoon = nft.availability === AvailabilityStatus.COMING_SOON;
  const isOfferEnded = nft.availability === AvailabilityStatus.OFFER_ENDED;
  const isSoldOut = nft.availability === AvailabilityStatus.SOLD_OUT;

  return (
    <ConditionsList>
      <Condition condition={isComingSoon}>
        <Box mb="40px">
          <NftLink>
            <Typography variant="h4">{nft.title}</Typography>
          </NftLink>
        </Box>
      </Condition>
      <Defaults>
        <Box className={classes.titleBlock}>
          <NftLink>
            <Typography variant="h4">{nft.title}</Typography>
          </NftLink>
        </Box>

        <NftCardBigDescription description={nft.description} />

        {isComingSoon && <Box my={2} />}

        <ConditionsList>
          <Condition condition={isAuction && !attributes.availableOnlyOnMarketplace}>
            <Box display="flex" alignItems="center" mb="4px" justifyContent="space-between">
              <ConditionsList>
                <Condition condition={isSoldOut}>
                  <Box height="20px" width="100%" />
                </Condition>
                <Defaults>
                  <Typography variant="caption1" className={classes.grey}>
                    <Trans i18nKey="<strong>1</strong> Auctioned original" />
                  </Typography>

                  <Typography
                    variant="caption1"
                    className={clsx(classes.priceColor, inactivePrice && classes.accentInactive)}
                  >
                    {auction.priceText}
                    &nbsp;
                    <Typography variant="h4" component="span">
                      {formatPrice(usdcUnitsToUSD(price.units))}
                    </Typography>
                  </Typography>
                </Defaults>
              </ConditionsList>
            </Box>
          </Condition>
          <Defaults>
            <Box display="flex" alignItems="center" justifyContent="space-between">
              <ConditionsList>
                <Condition condition={(isOfferEnded || isSoldOut) && !attributes.availableOnlyOnMarketplace}>
                  <Box height="20px" width="100%" />
                </Condition>
                <Defaults>
                  <Typography variant="caption1" className={classes.grey}>
                    <Trans
                      i18nKey="<strong>{{leftAmount}}</strong>/{{totalAmount}} NFTs left"
                      count={isAuction ? 1 : nft.supply ?? 0}
                      values={{
                        leftAmount: formatNumber(nft.balance ?? 0),
                        totalAmount: formatNumber(isAuction ? 1 : nft.supply ?? 0),
                      }}
                    />
                  </Typography>

                  <Typography
                    variant="h4"
                    className={clsx(classes.priceColor, inactivePrice && classes.accentInactive)}
                  >
                    {formatPrice(usdcUnitsToUSD(price.units))}
                  </Typography>
                </Defaults>
              </ConditionsList>
            </Box>
          </Defaults>
        </ConditionsList>
      </Defaults>
    </ConditionsList>
  );
};
