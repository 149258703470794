import {Box, makeStyles} from '@material-ui/core';
import ArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import {Title} from '../Title';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '16px 12px',
    borderRadius: '12px',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,

    [theme.breakpoints.up('md')]: {
      padding: '16px',
    },
    [theme.breakpoints.up('lg')]: {
      padding: '16px 24px',
    },
  },
}));

type Props = {
  title?: string;
};

export const AccordionSkeleton = ({title}: Props) => {
  const styles = useStyles();

  return (
    <Box className={styles.root}>
      <Title>{title}</Title>
      <ArrowDownIcon />
    </Box>
  );
};
