import {ReactElement, ReactNode, useMemo} from 'react';
import {useIsPreviewMode} from '../../shared/hooks/use-preview-mode-hooks';
import {useFetchFullEventData} from '../../shared/hooks/event-data/event-data.hook';
import {isAccessNft, isExclusiveNft} from '../../shared/helpers/auction';
import {getTimelineStatus} from '../../pages/Сms/Exhibit/utils';
import {ExhibitContext} from './exhibit-context';
import {Exhibition} from '../../shared/components/Nft/types';
import {useTranslation} from 'react-i18next';

type Props = {
  children: ReactNode;
  slug: string | undefined;
  exhibition?: Exhibition;
};

export function ExhibitionProvider({slug, children, exhibition}: Props): ReactElement {
  const isPreviewMode = useIsPreviewMode();
  const eventData = {
    ...exhibition,
    slug,
  };
  const {event, isLoading, error, startPolling, stopPolling, refetch} = useFetchFullEventData(eventData, isPreviewMode);

  const eventNfts = useMemo(() => event?.nfts ?? [], [event?.nfts]);

  const exclusiveNfts = useMemo(() => eventNfts.filter(isExclusiveNft), [eventNfts]);
  const accessNfts = useMemo(() => eventNfts.filter(isAccessNft), [eventNfts]);
  const eventTimelineStatus = useMemo(() => getTimelineStatus(event), [event]);
  const {t} = useTranslation();

  const exhibitContext = useMemo(
    () => ({
      startPolling,
      stopPolling,
      refetch,
      event,
      error,
      eventTimelineStatus,
      accessNfts,
      exclusiveNfts,
      loading: isLoading,
      symbol: Symbol('ExhibitPage'),
    }),
    [accessNfts, error, event, eventTimelineStatus, exclusiveNfts, isLoading, refetch, startPolling, stopPolling],
  );

  if (!isLoading && (event == null || error)) {
    throw new Error(t('Exhibit not found'));
  }

  return <ExhibitContext.Provider value={exhibitContext}>{children}</ExhibitContext.Provider>;
}
