import React from 'react';
import clsx from 'clsx';
import {Box, Dialog, Typography, makeStyles} from '@material-ui/core';
import colors from '../../../styles/colors';
import {CloseButton} from '../CloseButton';

export interface NFTModalProps {
  open: boolean;
  title: string;
  subTitle?: string;
  message?: string;
  icon?: React.ReactElement;
  classes?: Partial<Record<'title' | 'message' | 'modalContainer', string>>;
  onClose: (event?: any) => void;
}

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiDialog-paper': {
      padding: '24px 1rem',
      background: colors.light,
      borderRadius: '12px',
      width: '343px',
      minWidth: '343px',
      [`${theme.breakpoints.down('md')} and (orientation: landscape)`]: {
        width: '100%',
        maxWidth: '740px',
        padding: '1rem 1rem 24px 1rem',
      },
      [theme.breakpoints.up('md')]: {
        minWidth: '0',
        width: '448px',
        padding: '24px 1rem',
      },
    },
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  closeButtonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'end',
  },
  closeButton: {
    marginLeft: 'auto',
  },
  closeButtonBox: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    fontWeight: 700,
    fontSize: '20px',
    lineHeight: '26px',
    textAlign: 'center',
  },
  subTitle: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    textAlign: 'center',
    marginTop: '8px',
  },
  message: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    textAlign: 'center',
    marginTop: '8px',
  },
  iconContainer: {
    marginBottom: '8px',
  },
}));

export const NFTModal: React.FC<NFTModalProps> = ({
  children,
  open,
  title,
  subTitle,
  message,
  icon,
  classes,
  onClose,
}) => {
  const styles = useStyles();
  return (
    <Dialog className={clsx(styles.root, classes && classes.modalContainer)} open={open} onClose={onClose}>
      <Box className={clsx(styles.header)}>
        <Box className={styles.closeButtonContainer}>
          <CloseButton onClick={onClose} />
        </Box>
        {icon && <Box className={styles.iconContainer}>{icon}</Box>}
        <Typography className={clsx(styles.title, classes?.title)} variant="h3">
          {title}
        </Typography>
        {subTitle && (
          <Typography className={styles.subTitle} variant="h5">
            {subTitle}
          </Typography>
        )}
        {message && (
          <Typography className={clsx(styles.message, classes?.message)} variant="caption">
            {message}
          </Typography>
        )}
      </Box>
      {children}
    </Dialog>
  );
};
