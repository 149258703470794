import {AnyAction, applyMiddleware, combineReducers, compose, createStore, Middleware, Reducer, Store} from 'redux';
import {composeWithDevTools} from 'redux-devtools-extension';
import {StoreModule} from './base/types';
import {InitAppCommand} from './base/actions';

type ReducersConfig = {
  [key: string]: Reducer;
};

export class AppStore {
  public readonly store: Store;
  private reducersConfig: ReducersConfig = {};
  private middlewares: Middleware[] = [];

  constructor(isDevMode: boolean) {
    const reducer = combineReducers(this.reducersConfig);
    let middleware = applyMiddleware(this.applyDynamicMiddleware.bind(this));
    if (isDevMode) {
      middleware = composeWithDevTools(middleware);
    }
    this.store = createStore(reducer, middleware);
  }

  init() {
    this.store.dispatch(InitAppCommand.create());
  }

  addModule(module: StoreModule): void {
    if (module.moduleReducer) {
      this.reducersConfig = {
        ...this.reducersConfig,
        [module.title]: module.moduleReducer,
      };
      this.store.replaceReducer(combineReducers(this.reducersConfig));
    }
    if (module.middlewares && module.middlewares.length) {
      this.middlewares.push(...module.middlewares);
    }
  }

  private applyDynamicMiddleware() {
    return (next: any) => (action: AnyAction) => {
      // @ts-ignore
      compose(...this.middlewares.map((m) => m(this.store)))(next)(action);
    };
  }
}
