import React, {ReactNode} from 'react';
import {Box, Divider} from '@material-ui/core';
import {Typography} from '@cere/rxb-template-ui-kit';
import {makeStyles} from '@material-ui/core/styles';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import clsx from 'clsx';
import {Card} from '../Card';
import colors from '../../../styles/colors';
import {useCollapsed} from './hooks';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    width: '100%',
    height: '100%',
    flexDirection: 'column',
  },
  collapsible: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    cursor: 'pointer',
    padding: '18px 25px',
  },
  block: {
    width: '100%',
    display: 'flex',
    padding: '10px 24px',
  },
  cardTitle: {
    fontWeight: 700,
    fontSize: '20px',
    lineHeight: '26px',
    color: colors.primaryDark,
  },
});

export interface CollapsibleCardProps {
  title?: string | ReactNode;
  className?: string;
  collapsibleclassName?: string;
  noDivider?: boolean;
  initiallyOpened?: boolean;
  stayOpened?: boolean;
}

export const CollapsibleCard: React.FC<CollapsibleCardProps> = ({
  children,
  title,
  className,
  collapsibleclassName,
  initiallyOpened,
  noDivider,
  stayOpened,
}) => {
  const {opened, toggleCollapsed} = useCollapsed(initiallyOpened);
  const styles = useStyles();

  const handleClick = () => {
    if (stayOpened) {
      return;
    }
    toggleCollapsed();
  };

  const renderArrows = () => {
    if (stayOpened) {
      return null;
    }
    if (opened) {
      return <KeyboardArrowUpIcon />;
    }
    return <KeyboardArrowDownIcon />;
  };

  return (
    <Card className={clsx({opened}, 'collapsible-container')}>
      <Box className={clsx(styles.root, className)}>
        <Box className={clsx(styles.collapsible, collapsibleclassName)} onClick={handleClick}>
          <Typography className={styles.cardTitle}>{title}</Typography>
          {renderArrows()}
        </Box>
        {(stayOpened || opened) && (
          <>
            {!noDivider && <Divider />}
            <Box className={clsx(styles.block, collapsibleclassName)}>{children}</Box>
          </>
        )}
      </Box>
    </Card>
  );
};
