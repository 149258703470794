import {AnyAction, MiddlewareAPI} from 'redux';
import {InitAppCommand} from '../../base/actions';
import {
  ClearUserDataCommand,
  ResendOTPCommand,
  ResendOTPFailedEvent,
  RestoreUserDataCommand,
  SignInWithEmailCommand,
  SignInWithSocialCommand,
  UserDataDocument,
  UserDataRestorationFailedEvent,
  UserDataRestoredEvent,
  UserSignedInWithEmailEvent,
  UserSignedInWithSocialEvent,
  UserSignInFailedEvent,
  VerifyEmailOTPCommand,
  VerifyOtpCommand,
  VerifyOTPSuccessEvent,
  VerifySocialOTPCommand,
} from './actions';
import {UserDataStorage} from '../../../models/auth/UserDataStorage';
import {IAuthService} from '../../../models/auth/IAuthService';
import {IAuthApi} from '../../../api/auth/IAuthApi';
import {selectCurrentLocale} from '../localization/selectors';
import {RedirectCommand} from '../navigation/actions';

export const initUserDataRestorationMiddleware =
  ({dispatch}: MiddlewareAPI) =>
  (next: Function) =>
  (action: AnyAction) => {
    next(action);
    if (action.type === InitAppCommand.type) {
      dispatch(RestoreUserDataCommand.create());
    }
  };

export const restoreUserDataMiddleware =
  (userDataStorage: UserDataStorage) =>
  ({dispatch}: MiddlewareAPI) =>
  (next: Function) =>
  (action: AnyAction) => {
    next(action);
    if (action.type === RestoreUserDataCommand.type) {
      try {
        const userData = userDataStorage.getUserData();
        dispatch(UserDataDocument.create(userData));
        dispatch(UserDataRestoredEvent.create());
      } catch (e) {
        dispatch(UserDataRestorationFailedEvent.create(e.message));
        dispatch(ClearUserDataCommand.create());
      }
    }
  };

export const signInByEmailMiddleware =
  (authService: IAuthService, userDataStorage: UserDataStorage) =>
  ({dispatch, getState}: MiddlewareAPI) =>
  (next: Function) =>
  async (action: AnyAction) => {
    next(action);
    if (action.type === SignInWithEmailCommand.type) {
      try {
        const email = action.payload;
        const locale = selectCurrentLocale(getState());
        await authService.signInOrAttachEmail(email, locale);
        userDataStorage.setUserEmail(email);
        dispatch(UserSignedInWithEmailEvent.create(email));
        dispatch(RedirectCommand.create(`/${locale}/home/auth/verify`, {email}));
      } catch (e) {
        dispatch(UserSignInFailedEvent.create(e.message));
        dispatch(ClearUserDataCommand.create());
      }
    }
  };

export const verifyOTPMiddleware =
  (userDataStorage: UserDataStorage) =>
  ({dispatch}: MiddlewareAPI) =>
  (next: Function) =>
  (action: AnyAction) => {
    next(action);
    if (action.type === VerifyOtpCommand.type) {
      const code = action.payload;
      const oauthType = userDataStorage.getUserOauthType();
      if (oauthType) {
        dispatch(VerifySocialOTPCommand.create(code));
        return;
      }
      dispatch(VerifyEmailOTPCommand.create(code));
    }
  };

export const resendOTPCodeMiddleware =
  (authApi: IAuthApi, userDataStorage: UserDataStorage) =>
  ({dispatch}: MiddlewareAPI) =>
  (next: Function) =>
  async (action: AnyAction) => {
    next(action);
    if (action.type === ResendOTPCommand.type) {
      const oauthType = userDataStorage.getUserOauthType();
      const token = userDataStorage.getUserToken();
      try {
        if (oauthType && token) {
          await authApi.sendOTPCodeBySocial(oauthType, token);
          return;
        }
        const email = userDataStorage.getUserEmail();
        await authApi.sendOTPCodeByEmail(email);
      } catch (e) {
        dispatch(ResendOTPFailedEvent.create(e.message));
      }
    }
  };

export const verifyEmailOTPMiddleware =
  (authApi: IAuthApi, userDataStorage: UserDataStorage) =>
  ({dispatch, getState}: MiddlewareAPI) =>
  (next: Function) =>
  async (action: AnyAction) => {
    next(action);
    if (action.type === VerifyEmailOTPCommand.type) {
      const locale = selectCurrentLocale(getState());
      try {
        const otpCode = action.payload;
        const email = userDataStorage.getUserEmail();
        const {publicKey, token} = await authApi.validateEmailOTP(email, otpCode);
        userDataStorage.setUserData(email, publicKey, token);
        dispatch(VerifyOTPSuccessEvent.create({email, publicKey, token}));
        dispatch(UserDataDocument.create({email, publicKey, token}));
        dispatch(RedirectCommand.create(`/${locale}/home`));
      } catch (e) {
        dispatch(UserSignInFailedEvent.create(e.message));
        dispatch(ClearUserDataCommand.create());
      }
    }
  };

export const signInBySocialMiddleware =
  (authService: IAuthService, userDataStorage: UserDataStorage) =>
  ({dispatch, getState}: MiddlewareAPI) =>
  (next: Function) =>
  async (action: AnyAction) => {
    next(action);
    if (action.type === SignInWithSocialCommand.type) {
      const locale = selectCurrentLocale(getState());
      try {
        const provider = action.payload;
        const {oauthType, ...userData} = await authService.signInWithSocial(provider);
        userDataStorage.setUserOauthType(oauthType);
        userDataStorage.setUserData(userData.email, userData.publicKey, userData.token);
        dispatch(UserSignedInWithSocialEvent.create(userData));
        dispatch(UserDataDocument.create(userData));
        dispatch(RedirectCommand.create(`/${locale}/home`));
      } catch (e) {
        dispatch(UserSignInFailedEvent.create(e.message));
        dispatch(ClearUserDataCommand.create());
      }
    }
  };

export const verifySocialOTPMiddleware =
  (authApi: IAuthApi, userDataStorage: UserDataStorage) =>
  ({dispatch, getState}: MiddlewareAPI) =>
  (next: Function) =>
  async (action: AnyAction) => {
    next(action);
    if (action.type === VerifySocialOTPCommand.type) {
      const locale = selectCurrentLocale(getState());
      try {
        const otpCode = action.payload;
        const authType = userDataStorage.getUserOauthType();
        const {email, publicKey, token} = userDataStorage.getUserData();
        await authApi.confirmSocialEmail(authType!, token!, otpCode);
        dispatch(VerifyOTPSuccessEvent.create({email, publicKey, token}));
        dispatch(UserDataDocument.create({email, publicKey, token}));
        dispatch(RedirectCommand.create(`/${locale}/home`));
      } catch (e) {
        dispatch(UserSignInFailedEvent.create(e.message));
        dispatch(ClearUserDataCommand.create());
      }
    }
  };

export const logOutMiddleware =
  (userDataStorage: UserDataStorage) =>
  ({dispatch}: MiddlewareAPI) =>
  (next: Function) =>
  async (action: AnyAction) => {
    next(action);
    if (action.type === ClearUserDataCommand.type) {
      userDataStorage.clearUserData();
      dispatch(UserDataDocument.create(null));
    }
  };
