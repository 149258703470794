import {FC, useCallback, useContext, useMemo} from 'react';
import {Box, CircularProgress, makeStyles} from '@material-ui/core';
import {Button} from '@cere/rxb-template-ui-kit';
import clsx from 'clsx';
import {getNftAvailabilityByCmsNft, NftAvailability} from '../../services/nft.service';
import colors from '../../../styles/colors';
import {checkNftMayBePurchased, getNftCardButtonText, getSegmentClassname} from './helpers';
import {CmsExhibitionNftRelType} from '../../types/graphql';
import {UserContext} from '../../../context/user-context/user-context';
import {getIsPaymentPending} from '../../helpers/paymentStatus';
import {getTimelineStatus} from '../../../pages/Сms/Exhibit/utils';
import {useFindExhibitionWithNft} from '../../hooks/use-find-exhibition-with-nft';
import {useFindCreatorNftWithAuctionsAndOffer} from '../Nft/use-find-creator-nft-with-auctions-and-offer';
import {UserPaymentHistoryContext} from '../../../context/payments-history/user-payment-history.context';
import {useIsUserOwnsSomeAccessNft} from '../../../context/payments-history/use-is-user-owns-some-access-nft';
import {isString} from '../../types/is-string';
import {useHistory} from 'react-router-dom';
import {useNftBalance} from '../../hooks/use-nft-balance';
import {enableMarketplaceView} from '../../../config/common';
import {useNftOfferedQty} from '../../hooks/use-nft-offered-qty';
import {usePendingTransactionsContext} from '../../../context/pending-transaction-context';
import {useNftListing} from '../../hooks/use-nft-listing';
import {GoogleAnalyticsId} from '../../../analytics-ids';
import {EventTimelineStatus} from '../../types/event';
import {ExhibitContext, ExhibitionEmptyContext} from '../../../context/exhibition/exhibit-context';
import {arrayToHash} from '../../lib/hash-utils';
import {useLocalization} from '../../hooks/use-locale.hook';

const useStyles = makeStyles(() => ({
  buttonLight: {
    background: `${colors.lighter} !important`,
    color: `${colors.disable} !important`,
    cursor: 'default',
  },
}));

interface NftCardBigButtonProps {
  onClickView?: Function;
  onClick: Function;
  buttonText?: string;
  id?: string;
  classname?: string | undefined;
  nftId: string;
  qtyOfferedByUser?: number;
  hideIfPurchased?: boolean;
}

export const NftCardBigButton: FC<NftCardBigButtonProps> = ({
  onClick,
  id,
  classname,
  buttonText,
  nftId,
  qtyOfferedByUser = 0,
  hideIfPurchased = false,
}) => {
  const {t, locale} = useLocalization();
  const classes = useStyles();
  const {setIsExhibitFirstOpen} = useContext(UserContext);
  const {data: balance, loading: balanceLoading} = useNftBalance(nftId);
  const {qty: nftTotalOfferedQty, loading: offeredQtyLoading} = useNftOfferedQty({nftId});
  const {symbol} = useContext(ExhibitContext);
  const {data: exhibition, loading: exhibitionLoading} = useFindExhibitionWithNft(nftId);
  const {getPaymentStatuses, getNftIsPurchased} = useContext(UserPaymentHistoryContext);
  const accessNftIds: string[] = useMemo(() => {
    return exhibition ? exhibition.accessNfts.map((nft) => nft.cmsNft?.creatorNft?.nft_id).filter(isString) : [];
  }, [exhibition]);
  const userHasAccess = useIsUserOwnsSomeAccessNft(arrayToHash(accessNftIds));
  const eventStatus = getTimelineStatus(exhibition);
  const nftAvailability = getNftAvailabilityByCmsNft(exhibition?.nft).availability;
  const {getPendingTransactionForNft} = usePendingTransactionsContext();

  const nftType = exhibition?.nft?.relType;
  const isExhibitionPage = symbol !== ExhibitionEmptyContext;

  const {data: creatorNftData, loading: creatorNftLoading} = useFindCreatorNftWithAuctionsAndOffer(nftId);
  const isSettled = creatorNftData?.creator_auctions[0]?.is_settled;

  const isLoading = creatorNftLoading || exhibitionLoading || balanceLoading || offeredQtyLoading || !exhibition;

  const purchased = useMemo(() => getNftIsPurchased(nftId), [getNftIsPurchased, nftId]);
  const pending = useMemo(() => getIsPaymentPending(...getPaymentStatuses(nftId)), [getPaymentStatuses, nftId]);
  const segmentClass = getSegmentClassname(classname, purchased, pending);
  const history = useHistory();

  const gotoNftPage = useCallback(() => {
    const nft = exhibition?.nft;
    if (nft) {
      history.push(`/${locale}/home/nft/${nft.cmsNft.id}`);
    }
  }, [exhibition?.nft, history, locale]);

  const isSoldOut = !balance || balance === 0 || (isSettled && nftType === CmsExhibitionNftRelType.AUCTIONED);
  const cmsNftIncrementId = exhibition?.nft?.cmsNft.id.toString()!;
  const {offers, ownerOffer, primaryOffer} = useNftListing({cmsNftIncrementId, start: 0});

  const isOfferEnded = useMemo(() => {
    if (eventStatus === EventTimelineStatus.FINISHED || nftAvailability === NftAvailability.PASSED) {
      return true;
    }

    if (nftType === CmsExhibitionNftRelType.AUCTIONED && balance > 0 && !isSettled) {
      return false;
    }

    return !primaryOffer && offers.length === 0;
  }, [eventStatus, nftAvailability, offers, primaryOffer, nftType, balance, isSettled]);

  const isAvailableOnlyOnMarketplace =
    !!offers.length && !ownerOffer && (isOfferEnded || isSoldOut) && isExhibitionPage;

  const defaultText = nftType === CmsExhibitionNftRelType.AUCTIONED ? t('Bid') : t('Buy');
  const text = useMemo(() => {
    if (isLoading) {
      return t('Loading...');
    }
    return creatorNftData
      ? getNftCardButtonText(
          buttonText || defaultText,
          purchased,
          balance || nftTotalOfferedQty || 0,
          pending,
          eventStatus,
          nftAvailability,
          nftType,
          isSettled,
          isAvailableOnlyOnMarketplace,
        )
      : t('Coming soon');
  }, [
    balance,
    buttonText,
    creatorNftData,
    defaultText,
    eventStatus,
    isSettled,
    isLoading,
    nftAvailability,
    nftTotalOfferedQty,
    nftType,
    pending,
    purchased,
    isAvailableOnlyOnMarketplace,
    t,
  ]);

  const isAuctionedOrLimited =
    exhibition?.nft?.relType === CmsExhibitionNftRelType.AUCTIONED ||
    exhibition?.nft?.relType === CmsExhibitionNftRelType.LIMITED;

  const disabled = useMemo(() => {
    const pendingTransaction = getPendingTransactionForNft(nftId);
    if (isLoading) {
      return true;
    }
    if (pendingTransaction) {
      return true;
    }
    if (purchased) {
      return qtyOfferedByUser > 0;
    }
    if (pending) {
      return true;
    }
    if (offers.length > 0) {
      return false;
    }
    if (isAvailableOnlyOnMarketplace) {
      return false;
    }
    if (isOfferEnded) {
      return true;
    }
    if (nftAvailability === NftAvailability.COMING_SOON || nftAvailability === NftAvailability.PASSED) {
      return true;
    }
    if (!exhibition) {
      return true;
    }
    if (isSettled && exhibition.nft?.relType === CmsExhibitionNftRelType.AUCTIONED) {
      return nftTotalOfferedQty < 1;
    }
    if (isAuctionedOrLimited && exhibition.allowFreeAccess) {
      return false;
    }
    if (isAuctionedOrLimited && !exhibition.allowFreeAccess && !isLoading && !pendingTransaction && purchased) {
      return !userHasAccess;
    }
    if (balance >= 0) {
      return !balance;
    }
    if (nftTotalOfferedQty >= 0) {
      return !nftTotalOfferedQty;
    }
  }, [
    balance,
    nftTotalOfferedQty,
    exhibition,
    nftAvailability,
    pending,
    purchased,
    userHasAccess,
    isSettled,
    qtyOfferedByUser,
    isLoading,
    getPendingTransactionForNft,
    isAvailableOnlyOnMarketplace,
    isAuctionedOrLimited,
    offers,
    isOfferEnded,
    nftId,
  ]);

  const handleClick = useCallback(
    (event: MouseEvent) => {
      event.stopPropagation();
      event.preventDefault();

      setIsExhibitFirstOpen(false);

      if (isAvailableOnlyOnMarketplace && isExhibitionPage) {
        return onClick();
      }

      if (isOfferEnded && isExhibitionPage) {
        return gotoNftPage();
      }

      if (purchased && isExhibitionPage) {
        return onClick();
      }

      return isExhibitionPage && purchased && enableMarketplaceView() ? gotoNftPage() : onClick();
    },
    [
      gotoNftPage,
      isExhibitionPage,
      onClick,
      purchased,
      setIsExhibitFirstOpen,
      isAvailableOnlyOnMarketplace,
      isOfferEnded,
    ],
  );

  const isLightButton =
    (isOfferEnded && !isAvailableOnlyOnMarketplace) ||
    (!purchased &&
      !primaryOffer &&
      !offers.length &&
      !isAvailableOnlyOnMarketplace &&
      !checkNftMayBePurchased(
        eventStatus,
        nftType,
        nftAvailability,
        balance || nftTotalOfferedQty || 0,
        isSettled ?? false,
      ));

  const checkIfPurchasedAndMarketplaceDisabled = () => {
    return hideIfPurchased && purchased && !enableMarketplaceView();
  };

  const checkIfOfferEndedAndMarketplaceDisabled = () => {
    return hideIfPurchased && !enableMarketplaceView() && isOfferEnded;
  };

  if (checkIfPurchasedAndMarketplaceDisabled() || checkIfOfferEndedAndMarketplaceDisabled()) {
    return null;
  }

  return (
    <Button
      fullWidth
      color="secondary"
      variant={isExhibitionPage && purchased ? 'outlined' : 'contained'}
      size="large"
      onClick={handleClick}
      className={clsx(isLightButton && classes.buttonLight, segmentClass, purchased && GoogleAnalyticsId.ViewNftBtn)}
      disabled={disabled}
      disableRipple={isLightButton}
      id={id}
    >
      {isLoading ? (
        <Box display="flex">
          <CircularProgress size={22} thickness={2} color="inherit" />
        </Box>
      ) : (
        text
      )}
    </Button>
  );
};
