import {ApolloError, gql, useQuery} from '@apollo/client';
import {useUserWalletsPubKeys} from '../../../context/use-user-wallets-pub-keys';
import {Numberish} from '../../types/numberish';

const GET_CREATOR_NFT_WITH_AUCTIONS = gql`
  query GetCreatorNftWithAuctions($nftId: String!, $wallets: [String]!) {
    creatorNfts(where: {nft_id: $nftId}) {
      id
      supply
      creator_make_offer {
        price
      }
      creator_auctions {
        ends_at
        price
        is_settled
      }
      creator_wallet_nfts(where: {wallet_in: $wallets, quantity_gt: 0}) {
        id
        wallet
        quantity
      }
    }
  }
`;

type CreatorNftResult = {
  id: Numberish;
  supply: number;
  creator_make_offer:
    | [
        {
          price: number;
        },
      ]
    | null;
  creator_auctions:
    | [
        {
          ends_at: string;
          price: number;
          is_settled: false;
        },
      ]
    | [];
  creator_wallet_nfts:
    | Array<{
        id: Numberish;
        wallet: string;
        quantity: number;
      }>
    | [];
};

type Result = {
  data: CreatorNftResult | undefined;
  loading: boolean;
  error: ApolloError | undefined;
};

export function useFindCreatorNftWithAuctionsAndOffer(nftId: string | undefined): Result {
  const wallets = useUserWalletsPubKeys();
  const {data, loading, error} = useQuery<{creatorNfts: [CreatorNftResult] | []}>(GET_CREATOR_NFT_WITH_AUCTIONS, {
    variables: {nftId, wallets},
    skip: nftId == null,
  });
  return {data: data?.creatorNfts?.[0], loading, error};
}
