import {AppStore} from './AppStore';

import {
  creatorsApi,
  purchaseHistoryApi,
  nftsApi,
  auctionsApi,
  appConfigApi,
  homePageApi,
  eventsApi,
  marketplacePageApi,
} from '../api';
import {authApi} from '../api/auth';
import {paymentApi} from '../api/payment';

import {walletConnectionService} from '../models/wallet';
import {authService, userDataStorage} from '../models/auth';
import {purchaseNftApi} from '../models/puchase';
import {sellNftApi} from '../models/selling';
import {auctionSCApi} from '../models/auction';
import {AuctionService} from '../models/auction/AuctionService';
import {localizationService} from '../models/localization';

import {createCreatorsModule} from './modules/creators';
import {createAuthModule} from './modules/auth';
import {createUserWalletsModule} from './modules/wallets';
import {createPurchaseModule} from './modules/purchase';
import {createNftsModule} from './modules/nfts';
import {createPurchaseHistoryModule} from './modules/purchase-history';
import {createSellingModule} from './modules/selling';
import {createAuctionsModule} from './modules/auctions';
import {createAppConfigModule} from './modules/app-config';
import {createModalsModule} from './modules/modals';
import {createLocalizationModule} from './modules/localization';
import {createNavigationModule} from './modules/navigation';
import {createHomePageModule} from './modules/home-page';
import {walletsBalanceService} from '../models/balance';
import {createPendingTransactionModule} from './modules/pending-transactions';
import {PendingTransactionsStorage} from '../models/pending-transactions/PendingTransactionsStorage';
import {createMarketplacePageModule} from './modules/marketplace-page';

export const appStore = new AppStore(process.env.NODE_ENV === 'development');

const appConfigNodule = createAppConfigModule(appConfigApi);
const authModule = createAuthModule(authApi, authService, userDataStorage);
const navigationModule = createNavigationModule();
const walletsModule = createUserWalletsModule(walletConnectionService, walletsBalanceService);
const nftsModule = createNftsModule();
const purchaseHistoryModule = createPurchaseHistoryModule(purchaseHistoryApi, walletConnectionService);
const purchaseModule = createPurchaseModule(nftsApi, purchaseNftApi, paymentApi, walletConnectionService);
const sellingModule = createSellingModule(nftsApi, sellNftApi, walletConnectionService);
const auctionModule = createAuctionsModule(auctionsApi, auctionSCApi, new AuctionService(), walletConnectionService);
const modalsModule = createModalsModule();
const localizationModule = createLocalizationModule(localizationService);
const pendingTransactionsModule = createPendingTransactionModule(purchaseHistoryApi, new PendingTransactionsStorage());
const creatorsModule = createCreatorsModule(creatorsApi);

const homePageModule = createHomePageModule(homePageApi, creatorsApi, eventsApi);
const marketplacePageModule = createMarketplacePageModule(marketplacePageApi, creatorsApi);

appStore.addModule(appConfigNodule);
appStore.addModule(localizationModule);
appStore.addModule(navigationModule);
appStore.addModule(modalsModule);
appStore.addModule(authModule);
appStore.addModule(walletsModule);
appStore.addModule(nftsModule);
appStore.addModule(purchaseHistoryModule);
appStore.addModule(pendingTransactionsModule);
appStore.addModule(purchaseModule);
appStore.addModule(sellingModule);
appStore.addModule(auctionModule);
appStore.addModule(creatorsModule);
appStore.addModule(homePageModule);
appStore.addModule(marketplacePageModule);

appStore.init();
