import {useLocation} from 'react-router-dom';
import {PendingTransactionTypes, usePendingTransactionsContext} from '../../context/pending-transaction-context';
import {useEffect} from 'react';
import {useSelectedWallet} from '../../context/use-selected-wallet';

export const usePendingTicketPurchase = () => {
  const {search} = useLocation();
  const {setPendingTransactionForNft} = usePendingTransactionsContext();
  const selectedWallet = useSelectedWallet();

  useEffect(() => {
    const query = new URLSearchParams(search);
    const paymentStatus = query.get('paymentStatus');
    const nftId = query.get('nftId');
    if (!selectedWallet.publicKey) {
      return;
    }
    if (paymentStatus?.toLowerCase() !== 'success' || !nftId) {
      return;
    }
    void setPendingTransactionForNft({
      nftId,
      type: PendingTransactionTypes.BUY_NFT,
      wallet: selectedWallet.publicKey || '',
    });
  }, [search, selectedWallet.publicKey, setPendingTransactionForNft]);
};
