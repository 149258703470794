import {PendingTransaction, PendingTransactionType} from './types';

const PENDING_TRANSACTIONS_KEY = 'user-pending-transactions';

export class PendingTransactionsStorage {
  setPendingTransaction(pendingTransaction: PendingTransaction): void {
    const storedTransactions = this.getStoredTransactions();
    storedTransactions[pendingTransaction.nftId] = pendingTransaction;
    localStorage.setItem(PENDING_TRANSACTIONS_KEY, JSON.stringify(storedTransactions));
  }

  getPendingTransactionByNftId(nftId: string): PendingTransaction | null {
    const storedTransactions = this.getStoredTransactions();
    return storedTransactions[nftId] ?? null;
  }

  getPendingTransactionsByType(type: PendingTransactionType): PendingTransaction[] {
    const storedTransactions = this.getStoredTransactions();
    return Object.values(storedTransactions).filter((transaction) => transaction.type === type);
  }

  removePendingTransactionByNftId(nftId: string): void {
    const storedTransactions = this.getStoredTransactions();
    delete storedTransactions[nftId];
    localStorage.setItem(PENDING_TRANSACTIONS_KEY, JSON.stringify(storedTransactions));
  }

  getStoredTransactions(): {[key: string]: PendingTransaction} {
    const storedTransactionsString = localStorage.getItem(PENDING_TRANSACTIONS_KEY);
    return storedTransactionsString ? JSON.parse(storedTransactionsString) : {};
  }
}
