import {AnyAction, MiddlewareAPI} from 'redux';
import {
  ActiveWalletTypeDocument,
  ConnectedWalletsTypesDocument,
  ConnectWalletCommand,
  DisconnectWalletCommand,
  RestoreWalletsConnectionCommand,
  SelectWalletTypeCommand,
  StartWalletsBalancePollingCommand,
  WalletConnectedEvent,
  WalletConnectionFailedEvent,
  WalletDisconnectedEvent,
  WalletDisconnectionFailedEvent,
  WalletsBalanceDocument,
  WalletsConnectionRestorationFailedEvent,
  WalletsConnectionRestoredEvent,
} from './actions';
import {WalletConnectionService} from '../../../models/wallet/WalletConnectionService';
import {SupportedWalletTypes} from '../../../models/wallet/types';
import {WalletsBalanceService} from '../../../models/balance/WalletsBalanceService';
import {selectAppConfig} from '../app-config/selectors';
import {LoadAppConfigSuccessEvent} from '../app-config/actions';
import {selectUserEmail} from '../auth/selectors';

export const walletsInitMiddleware =
  ({dispatch}: MiddlewareAPI) =>
  (next: Function) =>
  (action: AnyAction) => {
    next(action);
    if (action.type === LoadAppConfigSuccessEvent.type) {
      dispatch(RestoreWalletsConnectionCommand.create());
      dispatch(StartWalletsBalancePollingCommand.create());
    }
  };

export const walletsConnectionRestoreMiddleware =
  (walletConnectionService: WalletConnectionService) =>
  ({dispatch, getState}: MiddlewareAPI) =>
  (next: Function) =>
  async (action: AnyAction) => {
    next(action);
    if (action.type === RestoreWalletsConnectionCommand.type) {
      const appConfig = selectAppConfig(getState());

      try {
        await walletConnectionService.restoreAllWalletsConnection({appConfig});
        const connectedWalletsTypes = walletConnectionService.getAllConnectedWalletsTypes();
        const activeWallet = walletConnectionService.getActiveWalletType();
        dispatch(WalletsConnectionRestoredEvent.create());
        dispatch(ConnectedWalletsTypesDocument.create(connectedWalletsTypes));
        dispatch(ActiveWalletTypeDocument.create(activeWallet));
      } catch (e) {
        dispatch(WalletsConnectionRestorationFailedEvent.create(e.message));
        dispatch(ConnectWalletCommand.create(SupportedWalletTypes.Torus));
      }
    }
  };

export const connectWalletMiddleware =
  (walletConnectionService: WalletConnectionService) =>
  ({dispatch, getState}: MiddlewareAPI) =>
  (next: Function) =>
  async (action: AnyAction) => {
    next(action);
    if (action.type === ConnectWalletCommand.type) {
      const appConfig = selectAppConfig(getState());
      const isAuth = Boolean(selectUserEmail(getState()));
      const walletType = action.payload;

      if (!isAuth) {
        dispatch(WalletConnectionFailedEvent.create('The user is not authenticated'));
        return;
      }

      try {
        await walletConnectionService.connectWalletByType(walletType, {appConfig});

        const connectedWalletsTypes = walletConnectionService.getAllConnectedWalletsTypes();
        dispatch(WalletConnectedEvent.create(walletType));
        dispatch(ConnectedWalletsTypesDocument.create(connectedWalletsTypes));
        dispatch(SelectWalletTypeCommand.create(walletType));
      } catch (e) {
        dispatch(WalletConnectionFailedEvent.create(e.message));
      }
    }
  };

export const selectWalletMiddleware =
  (walletConnectionService: WalletConnectionService) =>
  ({dispatch}: MiddlewareAPI) =>
  (next: Function) =>
  async (action: AnyAction) => {
    next(action);
    if (action.type === SelectWalletTypeCommand.type) {
      const walletType = action.payload;
      walletConnectionService.setActiveWalletType(walletType);
      dispatch(ActiveWalletTypeDocument.create(walletType));
    }
  };

export const disconnectWalletMiddleware =
  (walletConnectionService: WalletConnectionService) =>
  ({dispatch}: MiddlewareAPI) =>
  (next: Function) =>
  async (action: AnyAction) => {
    next(action);
    if (action.type === DisconnectWalletCommand.type) {
      const walletType = action.payload;
      let connectedWalletsTypesBefore: SupportedWalletTypes[];
      try {
        connectedWalletsTypesBefore = walletConnectionService.getAllConnectedWalletsTypes();
      } catch (e) {
        dispatch(WalletDisconnectionFailedEvent.create(e.message));
        return;
      }
      const selectedWalletType = walletConnectionService.getActiveWalletType();
      if (walletType === selectedWalletType) {
        dispatch(SelectWalletTypeCommand.create(SupportedWalletTypes.Torus));
      }
      await walletConnectionService.disconnectWalletByType(walletType);
      try {
        const connectedWalletsTypesAfter = walletConnectionService.getAllConnectedWalletsTypes();
        if (connectedWalletsTypesBefore.length === connectedWalletsTypesAfter.length) {
          dispatch(WalletDisconnectionFailedEvent.create('Wallet was not disconnected'));
          return;
        }
        dispatch(WalletDisconnectedEvent.create(walletType));
        dispatch(ConnectedWalletsTypesDocument.create(connectedWalletsTypesAfter));
      } catch (e) {
        dispatch(WalletDisconnectionFailedEvent.create(e.message));
      }
    }
  };

export const walletsBalancePollingMiddleware =
  (walletsBalanceService: WalletsBalanceService, pollingInterwal: number) =>
  ({dispatch}: MiddlewareAPI) =>
  (next: Function) =>
  (action: AnyAction) => {
    next(action);

    if (action.type === StartWalletsBalancePollingCommand.type) {
      setInterval(async () => {
        const walletsBalance = await walletsBalanceService.getWalletsBalance();
        dispatch(WalletsBalanceDocument.create(walletsBalance));
      }, pollingInterwal);
    }
  };
