import {alpha, Box, Card, CardMedia, Grid, makeStyles, Theme, useMediaQuery} from '@material-ui/core';
import {MouseEventHandler, SyntheticEvent, useCallback, useContext, useEffect, useMemo, useState} from 'react';
import clsx from 'clsx';
import useResizeObserver from 'use-resize-observer';

import {ReactComponent as InfoIcon} from 'assets//icons/infoIcon.svg';
import {ReactComponent as AccessIcon} from 'assets/ticket.svg';
import {ReactComponent as AuctionedIcon} from 'assets/crown.svg';
import {ReactComponent as LimitedIcon} from 'assets/star.svg';
import {GoogleAnalyticsId} from 'analytics-ids';
import {useStartNftSale} from 'context/sell-nft-context';
import {useSelectedWallet} from 'context/use-selected-wallet';
import {UserPaymentHistoryContext} from 'context/payments-history/user-payment-history.context';
import {usePurchaseById} from 'context/purchase-nft-context';
import {Typography} from 'shared/components/UI';
import {TYPOGRAPHY_VARIANTS} from 'shared/components/UI/Typography/types';
import {NftCardBigButton} from 'shared/components/NftCardBig/NftCardBigButton';
import {link, StyledLink} from 'shared/components/StyledLink';
import {Avatar} from 'shared/components/Avatar';
import {useNftPriceAndBalance} from 'shared/components/NFTDetailsCard/hooks';
import {NFTModalSimple} from 'shared/components/NFTModalSimple';
import {NftProvider} from 'shared/components/Nft/nft-provider';
import {makePartialExhibitionNft} from 'shared/components/Nft/utils';
import {useNftCardStyles} from 'shared/components/NFTDetailsCard/styles';
import {Condition, ConditionsList, Defaults} from 'shared/components/Conditions';
import {NftPurchaseParams} from 'shared/components/WithPurchaseNft/v2';
import {Nft} from 'shared/components/Nft/types';
import {
  useFindNftRelType,
  useFindRelatedExhibitionId,
  useFindRelatedExhibitionSlug,
} from 'shared/components/Nft/use-find-related-exhibition';
import {getAvailableNFTQuantity, getNFTCardImage, useGetNFTPrice} from 'shared/helpers/nfts';
import {getNftRelTypeTitle} from 'shared/helpers/get-nft-rel-type-title.helper';
import {useNotifications} from 'shared/hooks/use-notifications';
import {useHover} from 'shared/hooks/use-hover.hook copy';
import {useLocalization} from 'shared/hooks/use-locale.hook';
import {formatPrice} from 'shared/lib/formatPrice';
import {unwrap} from 'shared/lib/unwrap';
import {CmsExhibitionIdSlugOnly, CmsExhibitionNftRelType} from 'shared/types/graphql';
import colors from 'styles/colors';

import {BannerImagesBlock} from '../imagesBlock';
import {NftModalCardContent} from './nftModalContent';
interface NftBannerProps {
  nft: Nft;
  image: string;
  onClick: MouseEventHandler;
  startNFTBuyOrRequestAuth: (data: NftPurchaseParams) => Promise<unknown>;
  setEvent: (event: CmsExhibitionIdSlugOnly) => void;
}

const useStyles = makeStyles<Theme, {isHovered?: boolean}>((theme) => ({
  wrapper: {
    backgroundImage: ({isHovered}) =>
      isHovered
        ? `linear-gradient(to top, ${colors.primaryDark} 0%, ${alpha(colors.primaryDark, 0.7)} 100%)`
        : `linear-gradient(to top, ${colors.primaryDark} 0%, ${alpha(colors.primaryDark, 0)} 100%)`,
    position: 'relative',
    height: '100%',
    width: '100%',
  },
  info: {
    display: 'flex',
    flexDirection: 'column-reverse',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'column',
    },
  },
  title: {
    color: colors.light,
    fontWeight: 'bold',
  },
}));

const useBaseStyles = makeStyles<Theme, {isHover: boolean}>((theme) => ({
  card: {
    height: '440px',
    borderRadius: 0,

    [theme.breakpoints.down('md')]: {
      height: '375px',
    },
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    paddingBottom: '64px',

    paddingLeft: '40px',
    paddingRight: '40px',

    [theme.breakpoints.down('md')]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'flex-end',

      paddingLeft: '16px',
      paddingRight: '16px',
      paddingBottom: '40px',
    },
  },
  infoIcon: {
    position: 'absolute',
    zIndex: 2,
    top: '22px',
    right: '22px',

    [theme.breakpoints.up('md')]: {
      top: '38px',
      right: '38px',
    },
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  title: {
    color: colors.light,
    fontWeight: 'bold',
    fontSize: '48px',
    order: 2,

    [theme.breakpoints.down('md')]: {
      fontSize: '20px',
      paddingBottom: '4px',
      order: 1,
    },
  },
  authorWrapper: {
    display: 'flex',
    '& p': {
      color: colors.light,
      lineHeight: '21px',
    },
  },
  avatar: {
    color: colors.light,
    paddingLeft: '4px',
  },
  bannerControls: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'flex-end',
    },
    '& button': {
      minHeight: '36px',
      maxHeight: '36px',
      minWidth: '116px',
    },
  },
  price: {
    color: colors.snowWhite,
    paddingRight: '20px',
    fontSize: '20px',
    fontWeight: 700,

    [theme.breakpoints.down('md')]: {
      paddingRight: 0,
      paddingBottom: '8px',
      fontSize: '16px',
    },
  },
  button: {
    minWidth: '116px',
    minHeight: '36px',
    padding: '4px 22px',
  },
  descriptionContainer: {
    marginTop: '24px',
    marginBottom: '-24px',
    display: ({isHover}) => (!isHover ? 'none' : 'block'),
    [theme.breakpoints.down('lg')]: {
      display: 'none',
    },
  },
  descriptionText: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '21px',
    color: colors.white,
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: 5,
    WebkitBoxOrient: 'vertical',
  },
  modalContainer: {
    '& .MuiDialog-paper': {
      background: colors.snowWhite,
    },
    [theme.breakpoints.up('md')]: {
      '& .MuiDialog-paper': {
        padding: '24px',
        background: colors.light,
        borderRadius: '12px',
        minWidth: '686px',
        maxWidth: '686px',
      },
    },
  },
}));

const useMediaStyles = makeStyles((isAccessNft: boolean) => ({
  root: {
    display: 'flex',
    height: '100%',
    width: '100%',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  badgeContainer: {
    zIndex: 15,
    height: '26px',
    width: 'fit-content',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: isAccessNft ? '2px 8px' : '2px 12px',
    background: 'rgba(22, 22, 22, 0.7)',
    backdropFilter: 'blur(80px)',
    borderRadius: '13px',

    position: 'absolute',
    top: '22px',
    left: '12px',
  },
  badgeText: {
    paddingLeft: '5px',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '22px',
    color: colors.light,
  },
}));

export const NftBanner = ({nft, image, onClick, setEvent, startNFTBuyOrRequestAuth}: NftBannerProps) => {
  const {t, locale} = useLocalization();
  const selectedWallet = useSelectedWallet();
  const {error} = useNotifications();
  const {ref, width} = useResizeObserver();
  const isDesktop = useMediaQuery<Theme>((theme) => `${theme.breakpoints.up('lg')}`);
  const [hoverRef, isHovered] = useHover();
  const [isHover, setIsHover] = useState(false);
  useEffect(() => {
    if (isDesktop) {
      setIsHover(isHovered);
    }
  }, [isHovered, isDesktop]);
  const classes = useStyles({isHovered});
  const baseClasses = useBaseStyles({isHover});

  const {price} = useNftPriceAndBalance(nft);
  const priceUnits = useGetNFTPrice(nft);
  const startNftSell = useStartNftSale();
  const {getNftIsPurchased} = useContext(UserPaymentHistoryContext);
  const purchased = useMemo(
    () => getNftIsPurchased(nft?.creatorNft?.nft_id),
    [getNftIsPurchased, nft?.creatorNft?.nft_id],
  );

  const eventId = useFindRelatedExhibitionId(nft?.creatorNft?.nft_id);
  const eventSlug = useFindRelatedExhibitionSlug(nft?.creatorNft?.nft_id);

  const qtyOwned = useMemo(
    () => getAvailableNFTQuantity(nft, [selectedWallet.publicKey ? selectedWallet.publicKey : '']),
    [selectedWallet.publicKey, nft],
  );

  useEffect(() => {
    if (eventId && eventSlug) {
      setEvent({
        id: eventId,
        slug: eventSlug,
      });
    }
  }, [eventId, eventSlug, setEvent]);

  const handleSellClick = () => {
    startNftSell({
      cmsNftIncrementId: nft.id,
      nftId: nft?.creatorNft?.nft_id!,
      nftName: nft.title,
      nftMedia: nft.assets[0]?.content,
      ownedQty: qtyOwned,
      price,
      creatorWalletNfts: nft?.creatorNft?.creator_wallet_nfts!,
      collectionAddress: nft?.creatorNft?.collection_address,
    });
  };

  const nftType = useFindNftRelType(nft?.creatorNft?.nft_id);
  const {startNFTPurchaseById} = usePurchaseById();

  const handleBuyClick = useCallback(async () => {
    try {
      if (nftType === CmsExhibitionNftRelType.AUCTIONED) {
        await startNFTBuyOrRequestAuth({
          amount: 1,
          relType: unwrap(nftType),
          auction: {
            latestBid: nft?.creatorNft?.creator_auctions?.[0]?.price ?? 0,
            bids: nft?.creatorNft?.creator_auctions?.[0]?.creator_auction_bids,
          },
          creatorName: nft.cmsCreator.name,
          minter: nft?.creatorNft?.minter ?? '',
          nftAddress: nft?.creatorNft?.nft_id ?? '',
          nftImage: getNFTCardImage(nft) ?? '',
          nftPrice: priceUnits,
          nftTitle: nft.title,
          nftUSDPrice: price,
          nftId: nft?.creatorNft?.nft_id ?? '',
          eventId,
          eventSlug,
          collectionAddress: nft?.creatorNft?.collection_address,
        });
      } else {
        await startNFTPurchaseById(nft.id.toString(), 1);
      }
    } catch (e) {
      error(e.message);
    }
  }, [nft, nftType, startNFTBuyOrRequestAuth, priceUnits, price, eventId, eventSlug, startNFTPurchaseById, error]);

  const handleButtonClick = () => {
    if (purchased) {
      handleSellClick();
      return;
    }
    void handleBuyClick();
  };

  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = (event: SyntheticEvent) => {
    event.stopPropagation();
    setOpenModal(!openModal);
  };

  const isAccessNftBadge = useMemo(() => nftType === CmsExhibitionNftRelType.ACCESS, [nftType]);
  const styles = useNftCardStyles();
  const mediaClasses = useMediaStyles({width, isAccessNftBadge});

  return (
    <div ref={hoverRef}>
      <Card ref={ref} className={clsx(baseClasses.card)} onClick={onClick}>
        <CardMedia classes={mediaClasses} image={image}>
          <Grid item className={clsx(baseClasses.wrapper, classes.wrapper)}>
            <Box className={classes.info}>
              <StyledLink
                to={`/${locale}/home/creator/${nft?.cmsCreator?.id}`}
                onClick={(event) => event.stopPropagation()}
                className={clsx(link, baseClasses.authorWrapper, GoogleAnalyticsId.ViewCreatorBtn)}
              >
                <Avatar
                  name={nft.cmsCreator?.name}
                  imageUrl={nft.cmsCreator?.avatar?.url}
                  creatorId={nft.cmsCreator?.id}
                />
              </StyledLink>
              <Typography className={baseClasses.title}>{nft.title}</Typography>
            </Box>
            <Box className={baseClasses.bannerControls}>
              <Typography className={baseClasses.price} variant={TYPOGRAPHY_VARIANTS.button1}>
                {formatPrice(price)}
              </Typography>
              <NftCardBigButton
                classname={clsx(baseClasses.button, GoogleAnalyticsId.NftBannerBtn)}
                nftId={nft?.creatorNft!.nft_id}
                onClick={handleButtonClick}
              />
            </Box>
            <Box className={baseClasses.descriptionContainer}>
              <Typography className={baseClasses.descriptionText}>{nft.cardDescription}</Typography>
            </Box>
            {nftType && (
              <Box className={mediaClasses.badgeContainer}>
                <ConditionsList>
                  <Condition condition={isAccessNftBadge}>
                    <AccessIcon />
                  </Condition>
                  <Condition condition={nftType === CmsExhibitionNftRelType.LIMITED}>
                    <LimitedIcon />
                  </Condition>
                  <Defaults>
                    <AuctionedIcon />
                  </Defaults>
                </ConditionsList>
                <span className={mediaClasses.badgeText}>{getNftRelTypeTitle(nftType)}</span>
              </Box>
            )}
          </Grid>
          {isHover && (
            <BannerImagesBlock
              isHovered={isHovered}
              url1={nft?.assets[0]?.content?.url}
              url2={nft?.cmsCreator?.avatar.url}
              url3={nft?.assets[1]?.content?.url}
            />
          )}
        </CardMedia>
        <Box className={baseClasses.infoIcon} onClick={handleOpenModal}>
          <InfoIcon />
        </Box>
      </Card>

      <NFTModalSimple
        open={openModal}
        classes={baseClasses}
        title={t('The story behind this NFT')}
        onClose={handleOpenModal}
      >
        <Box>
          <NftProvider nft={makePartialExhibitionNft(nft.id, nft)}>
            <NftModalCardContent styles={styles} />
          </NftProvider>
        </Box>
      </NFTModalSimple>
    </div>
  );
};
