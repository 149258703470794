import {Button} from '@material-ui/core';
import clsx from 'clsx';
import {useDefaultButtonStyles, useDisabledButtonStyles} from './styles';

export interface DisabledButtonProps {
  className?: string;
  text: string;
}

export const DisabledButton = ({className, text}: DisabledButtonProps) => {
  const buttonStyles = useDefaultButtonStyles().default;
  const disabledButtonStyles = useDisabledButtonStyles({}).default;

  return <Button className={clsx(buttonStyles, disabledButtonStyles, className)}>{text}</Button>;
};
