import {useContext, useState} from 'react';
import {Button, Typography, Modal} from '@cere/rxb-template-ui-kit';
import {Box, makeStyles} from '@material-ui/core';
import * as EmailValidator from 'email-validator';
import AuthInput from '../../../../../shared/components/auth/AuthInput';
import {Checkbox} from '../../../../../shared/components/Checkbox';
import colors from '../../../../../styles/colors';
import analyticService, {AnalyticEventsEnum} from '../../../../../shared/services/analytic.service';
import {GoogleAnalyticsId} from '../../../../../analytics-ids';
import clsx from 'clsx';
import {AppContext} from '../../../../../context/app-context';
import {useLocalization} from '../../../../../shared/hooks/use-locale.hook';

interface SubscribeModalProps {
  onClick: Function;
  onClose: Function;
  onClickStayUpdatedConfirmation?: Function;
}

const useStyles = makeStyles(() => ({
  label: {
    color: colors.lightGrey,
  },
  modalContent: {
    padding: '15px',
  },
}));

export const SubscribeModal = ({onClick, onClose, onClickStayUpdatedConfirmation}: SubscribeModalProps) => {
  const {t} = useLocalization();
  const [email, setEmail] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [readTerms, setReadTerms] = useState<boolean>(false);
  const {appConfig} = useContext(AppContext);

  const classes = useStyles();

  const onChange = (e: any) => {
    setEmail(e.target.value);
  };

  const onBlur = () => {
    if (!email || !EmailValidator.validate(email)) {
      setError(t('Please enter a valid email address'));
    }
  };

  const onFocus = () => {
    analyticService.track(AnalyticEventsEnum.INTERSTITIAL_SUBSCRIBE_EMAIL_FOCUSED);
    setError('');
  };

  const onSubmit = async () => {
    analyticService.track(AnalyticEventsEnum.INTERSTITIAL_SUBSCRIBE_SUBMIT);
    await onClick(email);
    if (onClickStayUpdatedConfirmation) {
      await onClickStayUpdatedConfirmation();
    }
  };

  return (
    <Modal
      open
      onClose={onClose}
      maxWidth="xs"
      contentClassName={classes.modalContent}
      headerContent={<Typography variant="h4">{t('Subscribe to exhibit updates')}</Typography>}
    >
      <Typography>
        {t('Provide your email to get the latest news and updates for this exhibit on {{appTitle}}.', {
          appTitle: appConfig.appTitle,
        })}
      </Typography>

      <Box mt="20px">
        <AuthInput
          value={email}
          helperText={error}
          inputProps={{inputMode: 'email'}}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
        />

        <Box display="flex" alignItems="flex-start" mt="15px">
          <Checkbox checked={readTerms} onChange={() => setReadTerms(!readTerms)} />

          <Typography variant="caption1" className={classes.label}>
            {t('I agree to receive important announcements, feature updates and offers from {{appTitle}}', {
              appTitle: appConfig.appTitle,
            })}
          </Typography>
        </Box>
      </Box>
      <Box m="24px auto 0" width="100%" maxWidth="140px">
        <Button
          className={clsx(GoogleAnalyticsId.SubscribeBtn)}
          disabled={error || !email || !readTerms}
          color="primary"
          variant="contained"
          fullWidth
          onClick={onSubmit}
        >
          {t('Get updated')}
        </Button>
      </Box>
    </Modal>
  );
};
