import {useMemo} from 'react';
import {getMediaUrl} from '../lib/media';
import {buildAbsoluteCmsUrl} from '../queries/utils';
import {CmsExhibitionWithTheme} from '../types/graphql';

export const useExhibitionPreview = (event?: CmsExhibitionWithTheme, isLiveTheme = true) => {
  const liveThemePreviewImage = useMemo(
    () => (event?.live_theme?.background ? buildAbsoluteCmsUrl(getMediaUrl(event?.live_theme?.background)) : undefined),
    [event?.live_theme?.background],
  );

  const basePreviewImage = useMemo(() => {
    return event?.image ? buildAbsoluteCmsUrl(getMediaUrl(event.image)) : undefined;
  }, [event?.image]);

  const videoPreviewFile = useMemo(() => event?.videoPreview ?? event?.image, [event?.image, event?.videoPreview]);

  const exhibitionPreview = useMemo(() => {
    return videoPreviewFile ? buildAbsoluteCmsUrl(getMediaUrl(videoPreviewFile)) : undefined;
  }, [videoPreviewFile]);

  return {
    previewImage: isLiveTheme ? liveThemePreviewImage : basePreviewImage,
    exhibitionPreview,
  };
};
