import {Button} from '@material-ui/core';
import clsx from 'clsx';
import {useBuyButtonStyles, useDefaultButtonStyles} from './styles';
import {useTranslation} from 'react-i18next';
import {useStartNFTPurchase} from '../../../context/purchase-nft-context';
import {withCheckBeforePurchase, withMarketplacePurchaseContext} from './hocs';
import {DisabledButton} from './DisabledButton';
import {NftType, AuctionStatus, NftPriceType, AvailabilityStatus} from '@cere/services-types';

export interface BuyOnMarketplaceButtonProps {
  type?: 'default' | 'green';
  className?: string;
  text?: string;
  nftId: string;
  sellerWallet: string;
  nftPrice: number;
  nftUsdPrice: number;
  nftAmount: number;
  nftRelType: NftType;
  nftEventId?: string;
  nftEventSlug?: string;
  nftTitle: string;
  nftDescription: string;
  nftCreatorName: string;
  nftCreatorId: string;
  nftAddress: string;
  nftCollectionAddress?: string;
  nftImage?: string;
  nftMinter: string;
  disabled?: boolean;
  checkBeforePurchase?: () => void;
  selectedOfferPrice?: string;
}

const PlainBuyOnMarketplaceButton = ({
  type = 'default',
  className,
  text,
  nftId,
  sellerWallet,
  nftPrice,
  nftUsdPrice,
  nftAmount,
  nftRelType,
  nftEventId,
  nftEventSlug,
  nftTitle,
  nftDescription,
  nftCreatorName,
  nftCreatorId,
  nftAddress,
  nftCollectionAddress,
  nftImage,
  nftMinter,
  disabled,
  checkBeforePurchase,
  selectedOfferPrice,
}: BuyOnMarketplaceButtonProps) => {
  const buttonStyles = useDefaultButtonStyles().default;
  const buyButtonStyles = useBuyButtonStyles()[type];
  const {t} = useTranslation();
  const startNftPurchase = useStartNFTPurchase();

  const buyOnMarketplace = async () => {
    window.sessionStorage.setItem('selectedOfferPrice', `${selectedOfferPrice}`);
    checkBeforePurchase?.();
    startNftPurchase(
      {
        id: nftId,
        sellerWalletAddress: sellerWallet,
        price: nftPrice,
        priceUsd: nftUsdPrice,
        amount: nftAmount,
        nftType: nftRelType,
        exhibitionId: nftEventId,
        exhibitionSlug: nftEventSlug,
        title: nftTitle,
        description: nftDescription,
        creatorName: nftCreatorName,
        address: nftAddress,
        collectionAddress: nftCollectionAddress,
        image: nftImage,
        creatorId: nftCreatorId,
        minter: nftMinter,
        auctionStatus: AuctionStatus.NOT_AUCTION,
        priceType: NftPriceType.DEFAULT_PRICE,
        availability: AvailabilityStatus.ONGOING,
      },
      false,
      selectedOfferPrice,
    );
  };

  if (disabled) {
    return <DisabledButton text={text || t('Buy')} />;
  }

  return (
    <Button
      className={clsx(className, buttonStyles, buyButtonStyles)}
      onClick={buyOnMarketplace}
      variant="contained"
      color="primary"
    >
      {text || t('Buy')}
    </Button>
  );
};

export const BuyOnMarketplaceButton = withMarketplacePurchaseContext(
  withCheckBeforePurchase(PlainBuyOnMarketplaceButton),
);
