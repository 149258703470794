import {
  ApplicationEnum,
  Deployment,
  Freeport,
  Marketplace,
  Auction,
  SimpleAuction,
  TestERC20 as ERC20,
  getMarketplaceAddress,
  createMarketplace,
  getERC20Address,
  createERC20,
  getFreeportAddress,
  createFreeport,
  createAuction,
  createSimpleAuction,
  getAuctionAddress,
  getSimpleAuctionAddress,
} from '@cere/freeport-sdk';
import {WalletConnectionService} from '../wallet/WalletConnectionService';

export class ContractsProvider {
  constructor(
    private readonly walletConnectionService: WalletConnectionService,
    private readonly application: ApplicationEnum,
    private readonly contractsDeployment: Deployment,
  ) {}

  async getERC20Contract(): Promise<ERC20> {
    const signer = await this.walletConnectionService.getSigner();
    const contractAddress = await getERC20Address(signer.provider, this.contractsDeployment, this.application);
    return createERC20({signer, contractAddress});
  }

  async getMarketplaceContract(): Promise<Marketplace> {
    const signer = await this.walletConnectionService.getSigner();
    const contractAddress = await getMarketplaceAddress(signer.provider, this.contractsDeployment, this.application);
    return createMarketplace({signer, contractAddress});
  }

  async getFreeportContract(): Promise<Freeport> {
    const signer = await this.walletConnectionService.getSigner();
    const contractAddress = await getFreeportAddress(signer.provider, this.contractsDeployment, this.application);
    return createFreeport({signer, contractAddress});
  }

  async getAuctionContract(): Promise<Auction> {
    const signer = await this.walletConnectionService.getSigner();
    const contractAddress = await getAuctionAddress(signer.provider, this.contractsDeployment, this.application);
    return createAuction({signer, contractAddress});
  }

  async getSimpleAuctionContract(): Promise<SimpleAuction> {
    const signer = await this.walletConnectionService.getSigner();
    const contractAddress = await getSimpleAuctionAddress(signer.provider, this.contractsDeployment, this.application);
    return createSimpleAuction({signer, contractAddress});
  }
}
