import {makeStyles, Card, CardMedia, Box, Theme, Button, Typography} from '@material-ui/core';
import clsx from 'clsx';
import dayjs from 'dayjs';

import {ReactComponent as CalendarIcon} from 'assets/calendar.svg';
import {ReactComponent as PinIcon} from 'assets/icons/pin.svg';
import {ReactComponent as StarIcon} from 'assets/icons/vipStar.svg';
import {ReactComponent as Line} from 'assets/icons/vipLine.svg';
import {ReactComponent as LeftLine} from 'assets/icons/vipLineLeft.svg';
import {useLocalization} from '../../hooks/use-locale.hook';
import {GoogleAnalyticsId} from 'analytics-ids';
import {useCallback} from 'react';
import {addVipEventToCalendar} from 'shared/services/event.service';
import {VipEventType} from 'shared/types/vipEvent';

const useStyles = makeStyles<Theme, {isCuttedCard?: boolean}>((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '12px',
    position: 'relative',
    background: 'rgba(22, 22, 22, 0.3)',
    maxWidth: '400px',
    margin: '0 auto',
    height: '343px',
    width: '343px',

    [theme.breakpoints.up('lg')]: {
      height: '268px',
      width: '1264px',
      maxWidth: '1264px',
    },
  },
  mediaWrapper: {
    overflow: 'hidden',
    borderRadius: '12px 12px 0 0',
  },
  mediaWrapperCentred: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  badge: {
    position: 'absolute',
    zIndex: 2,
    top: '12px',
    left: '12px',
  },
  overlay: {
    position: 'relative',
    filter: 'blur(15px)',
    '&:after': {
      content: '""',
      position: 'absolute',
      width: '100%',
      height: '100%',
      bottom: 0,
      zIndex: 1,
      background: '#505050',
      opacity: 0.5,
    },
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  wrapper: {
    position: 'absolute',
    background: 'linear-gradient(360deg, #161616 0%, rgba(22, 22, 22, 0) 100%)',
    width: '100%',
    height: '100%',
  },
  title: {
    fontWeight: 800,
    fontSize: '24px',
    lineHeight: '29px',
    color: theme.palette.common.white,
    margin: '16px 0 22px 0',

    [theme.breakpoints.up('lg')]: {
      fontSize: '52px',
      lineHeight: '63px',
    },
  },
  date: {
    '& p': {
      fontWeight: 600,
      fontSize: '12px',
      lineHeight: '20px',
      color: theme.palette.grey[200],
    },
  },
  calendarIcon: {
    '& path ': {
      stroke: theme.palette.common.black,
    },
  },
  addToCalendarText: {
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '22px',
    height: '40px',
    textTransform: 'unset',
    padding: '0 24px',
  },
  dateAndTime: {
    paddingLeft: '14px',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '22px',
    color: theme.palette.common.white,
    '& strong': {
      color: theme.palette.grey[400],
      fontWeight: 600,
    },
  },
  addressBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  addressText: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px',
    marginLeft: '11px',
    color: theme.palette.common.white,
  },
  authorName: {
    fontWeight: 600,
    fontSize: '10px',
    lineHeight: '12px',
    letterSpacing: '0.9em',
    textTransform: 'uppercase',
    color: theme.palette.common.white,
    margin: '10px 0 8px 6px',
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  bottomBox: {
    position: 'absolute',
    bottom: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '10px 0 16px',
    gap: '12px',
    width: '100%',
    height: '142px',
    background: 'rgba(22, 22, 22, 0.7)',
    backdropFilter: 'blur(10px)',
    borderRadius: '12px',
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row',
      padding: '0 12px 0 36px',
      gap: '36px',
      height: '60px',
      borderRadius: '300px',
      width: 'fit-content',
      maxWidth: '1260px',
      margin: '0 auto 34px auto',
    },
  },
  bottomLine: {
    width: '228px',
    height: '0px',
    border: '1px solid #454545',
    backgroundColor: '#454545',
    [theme.breakpoints.up('lg')]: {
      width: '40px',
      transform: 'rotate(90deg)',
    },
  },
  linesBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  starIcon: {
    margin: '28px auto 0 auto',
    [theme.breakpoints.up('lg')]: {
      margin: '52px auto 17px auto',
      width: '20px',
      height: '19px',
    },
  },
}));

const useMediaStyles = makeStyles((theme) => ({
  root: {
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    height: '343px',
    width: '343px',

    [theme.breakpoints.up('lg')]: {
      width: '100%',
    },
  },
  textDivider: {
    color: `${theme.palette.grey[100]} !important`,
  },
}));

interface Props {
  event: VipEventType;
  className?: string;
  withOverlay?: boolean;
  noAvatar?: boolean;
  isCuttedCard?: boolean;
}

export const VipCard = ({event, className, withOverlay, isCuttedCard}: Props) => {
  const {t} = useLocalization();
  const classes = useStyles({isCuttedCard});
  const mediaClasses = useMediaStyles({});

  const addToCalendar = useCallback(async () => addVipEventToCalendar(event), [event]);

  return (
    <Card className={clsx(classes.root, className)}>
      <Box className={clsx(classes.mediaWrapper, withOverlay && classes.mediaWrapperCentred)}>
        <CardMedia
          classes={mediaClasses}
          className={withOverlay ? classes.overlay : ''}
          image={event?.coverImage[0]?.url || ''}
        />
      </Box>

      <Box className={classes.wrapper}>
        <Box className={classes.cardContent}>
          <StarIcon className={classes.starIcon} />

          <Typography className={classes.authorName}>{event.authorName}</Typography>

          <Box className={classes.linesBox}>
            <LeftLine />
            <Line />
          </Box>
          <Box className={classes.title}>{event.title}</Box>

          <Box className={classes.bottomBox}>
            <Box className={classes?.dateAndTime}>
              <span> {dayjs(event.date).format('MM.DD.YYYY')}</span>
              <strong className={classes.textDivider}>{t(' at')}</strong>
              <span> {dayjs(event.date).format('hh:mma')}</span>
            </Box>

            <Box className={classes.bottomLine} />

            <Box className={classes.addressBox}>
              <PinIcon />
              <Typography className={classes.addressText}>{event.address}</Typography>
            </Box>

            <Button
              className={clsx(GoogleAnalyticsId.AddToCalendarBtn, classes.addToCalendarText)}
              variant="contained"
              startIcon={<CalendarIcon className={classes.calendarIcon} />}
              onClick={addToCalendar}
            >
              <span> {t('Add to Calendar')} </span>
            </Button>
          </Box>
        </Box>
      </Box>
    </Card>
  );
};
