import {Box, Typography} from '@material-ui/core';
import {Link} from 'react-router-dom';
import {Button} from '@cere/rxb-template-ui-kit';
import {useContext} from 'react';

import {useLoadNftById} from 'api/hooks/use-load-nft-by-id';
import {ReactComponent as CloseIcon} from 'assets/close.svg';
import {useLocalization} from 'shared/hooks/use-locale.hook';
import {formatPrice} from '../../lib/formatPrice';
import {NftContext} from '../Nft/nft-context-v2';
import {NftProvider2} from '../Nft/nft-provider-v2';
import {VerifiedAuthorBadge} from '../VerifiedAuthorIcon';

import {useStyles} from './styles';
import {useTargetNotifications} from './hooks';
import {TargetNotification} from './types';

type Props = {
  notification: TargetNotification;
  onClose: () => void;
};

const NotificationsPopupContent = ({notification, onClose}: Props) => {
  const classes = useStyles();
  const {price, button} = useContext(NftContext);
  const {locale} = useLocalization();

  const nftLink = `/${locale}/home/nft/${notification.nftId}`;

  return (
    <Box className={classes.container}>
      <Box className={classes.content}>
        <Box className={classes.header}>
          <Typography className={classes.headerTitle}>{notification.title}</Typography>
          <Box className={classes.close} onClick={onClose}>
            <CloseIcon className={classes.closeIcon} />
          </Box>
        </Box>
        <Box className={classes.nftContainer}>
          <Link to={nftLink}>
            <Box className={classes.nftImageBox}>
              <img src={notification.nftImageUrl} alt={notification.title} className={classes.nftImage} />
            </Box>
          </Link>
          <Box className={classes.nftDetails}>
            <Box>
              <Link to={nftLink} className={classes.link}>
                <Typography className={classes.detailsTitle}>{notification.nftTitle}</Typography>
              </Link>
              <Box className={classes.creatorBlock}>
                <Typography className={classes.creatorName}>{notification.creatorName}</Typography>
                <VerifiedAuthorBadge />
              </Box>
            </Box>
            <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
              <Typography className={classes.nftPrize}>
                {price?.usd ? formatPrice(price.usd + price.cents) : ''}
              </Typography>
              <Button
                color="secondary"
                variant="contained"
                onClick={button.onClick}
                className={classes.ctaButton}
                disabled={button.disabled}
              >
                {notification.buttonText}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export const TargetNotificationsPopup = () => {
  const {notification, resetNotification} = useTargetNotifications();

  const {nft} = useLoadNftById(notification ? `${notification?.nftId}` : '');

  if (!notification || !nft) {
    return null;
  }

  return (
    <NftProvider2 nft={nft}>
      <NotificationsPopupContent notification={notification} onClose={resetNotification} />
    </NftProvider2>
  );
};
