import {PurchasedNft} from '../../../api/purchase-history/types';
import {AnyAction} from 'redux';
import {PurchasedNFTDocument, PurchasedNFTsDocument, ReplacedPurchasedNFTsDocument} from './actions';

type PurchasedNftsState = {[id: string]: PurchasedNft};

export const purchasedNftsReducer = (state: PurchasedNftsState = {}, action: AnyAction): PurchasedNftsState => {
  switch (action.type) {
    case PurchasedNFTsDocument.type: {
      const purchasedNfts: PurchasedNft[] = action.payload;
      return purchasedNfts.reduce((acc, nft) => {
        acc[nft.id] = nft;
        return acc;
      }, state);
    }
    case ReplacedPurchasedNFTsDocument.type: {
      const purchasedNfts: PurchasedNft[] = action.payload;
      return purchasedNfts.reduce((acc, nft) => {
        acc[nft.id] = nft;
        return acc;
      }, {} as PurchasedNftsState);
    }
    case PurchasedNFTDocument.type: {
      const purchasedNft: PurchasedNft = action.payload;
      return {
        ...state,
        [purchasedNft.id]: purchasedNft,
      };
    }
    default:
      return state;
  }
};
