import {useCallback, useContext, useState} from 'react';
import {UserContext} from '../../context/user-context/user-context';
import {nftApi} from '../../api';
import {getFreeport} from '../lib/get-freeport';
import {NonCustodyWalletTypeEnum} from '../types/non-custody-wallet';
import {safeTransferFrom} from '../services/token-transfer.service';
import {getCollection} from '../lib/get-collection';
import {singletonHook} from 'react-singleton-hook';

const init = {startDavinciNftsTransfer: () => Promise.resolve(), isActive: true};

const useTransferDavinciNftsHook = () => {
  const [isActive, setIsActive] = useState<boolean>(false);

  const {userData, nonCustodyWallets} = useContext(UserContext);

  const startDavinciNftsTransfer = useCallback(
    async (nftIds: string[]) => {
      if (isActive || !userData.token || !userData.userPublicKey) {
        return;
      }

      const nfts = await nftApi.getNftsByAddressList(nftIds);
      if (!userData.token || !userData.userPublicKey) {
        return;
      }
      const userPublicKey: string = userData.userPublicKey;
      const cereWallet = nonCustodyWallets?.find((wallet) => wallet.type === NonCustodyWalletTypeEnum.TORUS);
      if (!cereWallet?.publicKey) {
        return;
      }

      setIsActive(true);
      console.warn(`Start nfts moving to CERE wallet for ${nftIds}`);
      const contracts = await Promise.all(
        nfts.map(async (nft) =>
          nft.collectionAddress
            ? await getCollection(userData.token, nft.collectionAddress!)
            : await getFreeport(userData.token),
        ),
      );

      await Promise.all(
        nfts.map(async (nft, index) => {
          try {
            await safeTransferFrom(contracts[index], {
              from: userPublicKey,
              toAccount: cereWallet.publicKey,
              tokenId: nft.address!,
              amount: 1,
            });
            console.warn(`Nft ${nft.address} was transferred from ${userPublicKey} to ${cereWallet.publicKey}`);
          } catch (e) {
            console.error(e);
          }
        }),
      );
      setIsActive(false);
    },
    [userData, isActive, nonCustodyWallets],
  );

  return {startDavinciNftsTransfer, isActive};
};

export const useSingletonTransferDavinciNftsHook = singletonHook(init, useTransferDavinciNftsHook);
