import {AnyAction, MiddlewareAPI} from 'redux';
import {InitAppCommand} from '../../base/actions';
import {AppConfigDocument, LoadAppConfigCommand, LoadAppConfigFailedEvent, LoadAppConfigSuccessEvent} from './actions';
import {IAppConfigApi} from '../../../api/app-config/IAppConfigApi';
import {selectCurrentLocale} from '../localization/selectors';

export const startAppConfigLoadingMiddleware =
  ({dispatch}: MiddlewareAPI) =>
  (next: Function) =>
  (action: AnyAction) => {
    next(action);
    if (action.type === InitAppCommand.type) {
      dispatch(LoadAppConfigCommand.create());
    }
  };

export const loadAppConfigMiddleware =
  (configApi: IAppConfigApi) =>
  ({dispatch, getState}: MiddlewareAPI) =>
  (next: Function) =>
  async (action: AnyAction) => {
    next(action);
    if (action.type === LoadAppConfigCommand.type) {
      try {
        const locale = selectCurrentLocale(getState());
        const config = await configApi.getAppConfig(locale);
        dispatch(AppConfigDocument.create(config));
        dispatch(LoadAppConfigSuccessEvent.create());
      } catch (e) {
        dispatch(LoadAppConfigFailedEvent.create(e.message));
      }
    }
  };
