import {ReactElement} from 'react';
import {Badge} from '@cere/rxb-template-ui-kit';
import {css} from '@linaria/core';
import {ReactComponent as LockIcon} from '../../../assets/lock.svg';
import {ReactComponent as TicketIcon} from '../../../assets/ticket.svg';
import {ReactComponent as EventLiveIcon} from '../../../assets/event-is-live.svg';
import {ReactComponent as SuccessIcon} from '../../../assets/icons/successIcon.svg';
import {getTimelineStatus} from '../../../pages/Сms/Exhibit/utils';
import {CmsExhibition} from '../../types/graphql';
import {EventCardBadgeStatus, EventTimelineStatus} from '../../types/event';
import colors from '../../../styles/colors';
import {getNftAvailabilityByCmsNft, NftAvailability} from '../../services/nft.service';
import dayjs from 'dayjs';
import {Typography} from '@material-ui/core';
import i18n from '../../../i18n';
import {AvailabilityStatus, NftCardInterface, NftType} from '@cere/services-types';

const lockClassName = css`
  width: 14px;
  height: 14px;
  fill: ${colors.light};
`;

const getCardBadge = (text: string, icon: unknown = null) => (
  <Badge variant="inverted" type="rounded" text={text} icon={icon} />
);

export const getEventCardBadge = (
  eventCardBadgeStatus: EventCardBadgeStatus,
  badgeText?: string,
  isUserHasAccess?: boolean,
): ReactElement | null => {
  if (isUserHasAccess) {
    return getCardBadge(i18n.t('Ticket bought'), <SuccessIcon />);
  }
  if (
    eventCardBadgeStatus === EventCardBadgeStatus.EXHIBIT_NOT_STARTED ||
    eventCardBadgeStatus === EventCardBadgeStatus.TICKET_ON_SALE
  ) {
    return getCardBadge(badgeText || i18n.t('Tickets on sale'), <TicketIcon className={lockClassName} />);
  }
  if (eventCardBadgeStatus === EventCardBadgeStatus.EXHIBIT_CLOSED) {
    return getCardBadge(i18n.t('Exhibit closed'), <LockIcon className={lockClassName} />);
  }

  if (eventCardBadgeStatus === EventCardBadgeStatus.AUCTION_LIVE) {
    return getCardBadge(i18n.t('Auction live'), <EventLiveIcon className={lockClassName} />);
  }

  return null;
};

export const getEventCardDatesV2 = (
  eventCardBadgeStatus: EventCardBadgeStatus,
  event: CmsExhibition,
): ReactElement | null => {
  const eventStartDate = dayjs(event?.startsAt).format('MM.DD.YYYY');
  const eventEndDate = dayjs(event?.endsAt).format('MM.DD.YYYY');

  if (event.isComingSoon && !event.startsAt) {
    return <Typography>{i18n.t('Starts: to be announced')}</Typography>;
  }
  if (eventCardBadgeStatus === EventCardBadgeStatus.EXHIBIT_CLOSED) {
    return (
      <Typography>
        {i18n.t('Ended ')}
        {eventEndDate}
      </Typography>
    );
  }
  if (
    eventCardBadgeStatus === EventCardBadgeStatus.TICKET_ON_SALE ||
    eventCardBadgeStatus === EventCardBadgeStatus.EXHIBIT_NOT_STARTED
  ) {
    return (
      <Typography>
        {i18n.t('Starts ')}
        {eventStartDate}
      </Typography>
    );
  }

  if (eventCardBadgeStatus === EventCardBadgeStatus.AUCTION_LIVE) {
    return (
      <Typography>
        {i18n.t('Ends ')}
        {eventEndDate}
      </Typography>
    );
  }
  return null;
};

export const getBadgeText = (nfts: NftCardInterface[], isFreeAccessExhibit?: boolean) => {
  let text = i18n.t('Tickets unavailable');
  if (!nfts || nfts?.length === 0) return text;

  for (let i = 0; i < nfts.length; i++) {
    const {balance, availability, nftType} = nfts[i];

    if (isFreeAccessExhibit) {
      if (
        (balance !== undefined &&
          balance > 0 &&
          nftType !== NftType.AUCTIONED &&
          availability !== AvailabilityStatus.OFFER_ENDED) ||
        nftType === NftType.AUCTIONED
      ) {
        text = i18n.t('Tickets on sale');
        break;
      }
    }

    if (!isFreeAccessExhibit) {
      if (
        nftType === NftType.ACCESS &&
        balance !== undefined &&
        balance > 0 &&
        availability !== AvailabilityStatus.OFFER_ENDED
      ) {
        text = i18n.t('Tickets on sale');
        break;
      }
    }

    if (
      balance === 0 &&
      (availability === AvailabilityStatus.SOLD_OUT || availability === AvailabilityStatus.OFFER_ENDED)
    ) {
      text = i18n.t('Tickets sold out');
    }
  }

  return text;
};

export const getEventCardStatus = (event: Omit<CmsExhibition, 'cmsCreator'>): EventCardBadgeStatus => {
  const timelineStatus = getTimelineStatus(event);

  const isSomeNftOngoing = event?.nfts?.some((nft) => {
    const nftAvailability = getNftAvailabilityByCmsNft(nft).availability;
    return nftAvailability === NftAvailability.ONGOING;
  });

  if (event.isComingSoon) {
    return EventCardBadgeStatus.TICKET_ON_SALE;
  }
  if (timelineStatus === EventTimelineStatus.FINISHED) {
    return EventCardBadgeStatus.EXHIBIT_CLOSED;
  }
  if (timelineStatus === EventTimelineStatus.NOT_STARTED && isSomeNftOngoing) {
    return EventCardBadgeStatus.TICKET_ON_SALE;
  }
  if (timelineStatus === EventTimelineStatus.NOT_STARTED) {
    return EventCardBadgeStatus.EXHIBIT_NOT_STARTED;
  }
  if (timelineStatus === EventTimelineStatus.STARTED) {
    return EventCardBadgeStatus.AUCTION_LIVE;
  }

  return EventCardBadgeStatus.NONE;
};
