import {AxiosInstance} from 'axios';

export const createTranslationsApi = (api: AxiosInstance) => {
  const getTranslationsByLocale = async (locale: string): Promise<Record<string, string>> => {
    const {data: response} = await api.get(`/translations?locale=${locale}`);
    const nft = response.data;
    return nft ?? null;
  };

  return {
    getTranslationsByLocale,
  };
};
