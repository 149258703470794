import {useState} from 'react';
import {Box, Theme, useMediaQuery} from '@material-ui/core';
import {Counter, Typography} from '@cere/rxb-template-ui-kit';
import {PaymentTypeEnum} from '@cere/services-types';
import clsx from 'clsx';

import {ReactComponent as LockHourglass} from 'assets/hourglass.svg';
import {isEventStarted} from 'shared/lib/CompareDates';
import {useLocalization} from 'shared/hooks/use-locale.hook';
import AverageNftPaymentCountdown from 'shared/components/average-payment-countdown/average-payment-countdown.component';
import {CmsExhibition} from 'shared/types/graphql';
import {ExhibitionTeaserType} from 'shared/types/exhibition-teaser';

import {ExhibitTeaser} from '../Exhibition-preview-container/exhibit-teaser';
import {useStyles} from '../Exhibition-preview-container/live-styles';

type Props = {
  event: CmsExhibition;
};

export const ActiveAllocationPendingEvent = ({event}: Props) => {
  const shouldShowTeaser = Boolean(event?.teaser?.content);
  const styles = useStyles({});
  const isDesktop = useMediaQuery<Theme>((theme) => `${theme.breakpoints.up('lg')}`);
  const [activeCountdown, setActiveCountdown] = useState(true);
  const {t} = useLocalization();

  return (
    <Box className={styles.pendingAllocationPage}>
      <Box className={styles.exhibitionInfo}>
        <Box className={styles.exhibitionInfoLeft}>
          <Box className={styles.exhibitionCounter}>
            <Typography variant="caption1">
              {isEventStarted(event.endsAt) ? t('Exhibit ends in') : t('Exhibit starts in')}
            </Typography>
            <Counter
              withSec
              startsAt={isEventStarted(event.endsAt) ? new Date(event.endsAt) : new Date(event.startsAt)}
              withCellParameters={true}
              cellParametersClassName={styles.cellParamClassName}
              cellClassName={styles.cellClassName}
              delimiterClassName={styles.cellClassName}
              containerClassName={styles.counterContainer}
            />
          </Box>
          <Box className={styles.exhibitionTitles}>
            <Typography className={styles.eventSubtitle} variant="h5">
              {event?.subtitle}
            </Typography>
            <Box className={styles.exhibitionTitleWithoutTheme}>{event?.title}</Box>
          </Box>

          <Box className={styles.lockContentInfo}>
            {!isDesktop && (
              <Box className={styles.lockHourglassStyle}>
                <LockHourglass />
              </Box>
            )}
            <Box className={styles.countdownContainer}>
              {isDesktop && activeCountdown && (
                <Box className={styles.lockHourglassStyle}>
                  <LockHourglass />
                </Box>
              )}
              {activeCountdown && (
                <AverageNftPaymentCountdown
                  text={t('Approximate time for NFT delivery')}
                  className={styles.countdown}
                  type={PaymentTypeEnum.USD_TO_NFT}
                  onComplete={() => setActiveCountdown(false)}
                />
              )}
            </Box>
          </Box>
        </Box>
        <Box className={styles.lockExhibitionImageBlock}>
          <Box className={styles.exhibitImagePreview}>
            <Box className={clsx(styles.enterLockExhibitButtonBlock, styles.enterButtonBlock)}>
              {shouldShowTeaser && (
                <Box className={styles.exhibitionTeaserContainer}>
                  <ExhibitTeaser type={ExhibitionTeaserType.REGULAR} />
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
