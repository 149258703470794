import {FC, MouseEventHandler, useCallback, useContext} from 'react';
import {Box} from '@material-ui/core';
import {Button} from '@cere/rxb-template-ui-kit';
import {CmsExhibitionNft} from '../../types/graphql';
import {NftTicketCardSelect} from './NftTicketSelect';
import {NftTicketCardBase} from './NftTicketCardBase';
import {NftContext} from '../Nft/nft-context';
import {useLocalization} from '../../hooks/use-locale.hook';

interface NftTicketCardProps {
  analyticClassname?: string;
  canBePurchased: boolean;
  handleClickLearn: MouseEventHandler<unknown>;
  nfts: CmsExhibitionNft[];
  setSelectedNft: Function;
  withCtaAction?: () => unknown;
}

export const NftTicketCardWithSelect: FC<NftTicketCardProps> = ({
  handleClickLearn,
  analyticClassname,
  canBePurchased,
  nfts,
  setSelectedNft,
  withCtaAction,
}) => {
  const {t} = useLocalization();
  const {button} = useContext(NftContext);
  const handler = useCallback(() => {
    withCtaAction?.();
    button.onClick();
  }, [button, withCtaAction]);
  return (
    <NftTicketCardBase
      handleClickLearn={handleClickLearn}
      buttonContent={
        <Box mt="12px">
          <Button
            className={analyticClassname}
            fullWidth
            color="secondary"
            variant="contained"
            size="large"
            onClick={handler}
            disabled={!canBePurchased}
          >
            {button.label || t('Buy')}
          </Button>
        </Box>
      }
      selectContent={
        <Box mt="4px">
          <NftTicketCardSelect nfts={nfts} onSelect={setSelectedNft} />
        </Box>
      }
      justifyContentInfo="flex-end"
    />
  );
};
