import {ReactElement, useCallback, useContext, useMemo} from 'react';
import {Field, Form, FormSpy} from 'react-final-form';
import {Chip} from '@material-ui/core';

import {ALL_WALLET, AllWallets} from './types';
import {AppWallet, SupportedWallet} from '../../shared/types/supported-wallet';
import {UserContext} from '../../context/user-context/user-context';
import {useSharedStyles} from './shared-styles';
import clsx from 'clsx';
import {NonCustodyWalletTitles} from '../../shared/types/non-custody-wallet';
import {useLoadWalletNfts} from './use-load-wallet-nfts';
import {useUserWalletsPubKeys} from '../../context/use-user-wallets-pub-keys';
import {AppContext} from '../../context/app-context';
import {useLocalization} from '../../shared/hooks/use-locale.hook';

type Props = {
  setActiveWallet: (wallet: AllWallets) => unknown;
};

type FormValues = {
  wallet: AllWallets;
};

export function WalletSelector({setActiveWallet}: Props): ReactElement {
  const {t} = useLocalization();
  const {nonCustodyWallets} = useContext(UserContext);
  const sharedStyles = useSharedStyles();
  const initialValue = useMemo(() => ({wallet: ALL_WALLET}), []);
  const nftQueryParam = useUserWalletsPubKeys();
  const {nfts} = useLoadWalletNfts(nftQueryParam);
  const {appConfig} = useContext(AppContext);

  const titles: Record<SupportedWallet, string> = useMemo(
    () => ({
      ...{[AppWallet.DAVINCI]: appConfig.appTitle},
      ...NonCustodyWalletTitles,
    }),
    [appConfig],
  );

  const walletOptions: {value: SupportedWallet | typeof ALL_WALLET; label: string | SupportedWallet}[] = useMemo(
    () => [
      {value: ALL_WALLET, label: t('All')},
      ...nonCustodyWallets.map(({type}) => ({label: t('{{title}} wallet', {title: titles[type]}), value: type})),
    ],
    [nonCustodyWallets, titles, t],
  );

  const submit = useCallback(
    ({wallet}: FormValues) => {
      setActiveWallet(wallet);
    },
    [setActiveWallet],
  );
  const onChange = useCallback(
    ({values}: {dirty: boolean; values: FormValues}) => {
      submit(values);
    },
    [submit],
  );

  if (nfts.length === 0) {
    return <></>;
  }

  return (
    <Form initialValues={initialValue} onSubmit={submit}>
      {({handleSubmit}) => (
        <form onSubmit={handleSubmit}>
          <div className={sharedStyles.walletSelector}>
            <FormSpy subscription={{dirty: true, values: true}} onChange={onChange} />
            <Field name="wallet">
              {({input}) => (
                <>
                  {walletOptions.map(({value, label}) => (
                    <Chip
                      key={value}
                      className={clsx(value === input.value && 'active')}
                      classes={{root: sharedStyles.walletItem}}
                      label={label}
                      onClick={value === input.value ? undefined : () => input.onChange(value)}
                      size="small"
                    />
                  ))}
                </>
              )}
            </Field>
          </div>
        </form>
      )}
    </Form>
  );
}
