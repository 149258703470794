import {makeStyles} from '@material-ui/core';
import {styled} from '@linaria/react';
import {css} from '@linaria/core';
import colors from '../../../../styles/colors';

export const PreviewContainer = styled.div<{previewImageSrc: string}>`
  position: relative;
  height: auto;
  background: ${(props) =>
    `linear-gradient(180deg, rgba(22, 22, 22, 0) 0%, #000 94.27%),url("${props.previewImageSrc}")`};
  background-size: cover;
  border-bottom: 1px solid #484848;
  background-position: center;
`;

export const PageContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
  background: #f5f5f7;
  background-size: cover;
  padding: 35px 20px 15px 20px;
  background-position: center;
  align-items: center;
`;

export const ctaClassName = css`
  max-width: 160px;
  white-space: nowrap;
`;

export const ctaSubscribeClassName = css`
  border-color: ${colors.light} !important;
  color: ${colors.light} !important;
  margin-left: 10px !important;
`;

export const useStyles = makeStyles((theme) => ({
  root: {
    overflowY: 'auto',
    padding: '0',
    [theme.breakpoints.down('md')]: {
      marginTop: '-24px',
    },
  },
  bannerBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '48px 16px',

    [theme.breakpoints.up('md')]: {
      padding: '60px 100px 44px',
      height: '1070px',
    },
    [theme.breakpoints.up('lg')]: {
      padding: '80px 80px 75px',
      height: '755px',
      flexDirection: 'row',
      justifyContent: 'center',
    },
  },
  bannerTextBox: {
    [theme.breakpoints.up('lg')]: {
      marginRight: '90px',
    },
  },
  bannerText: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1,
    [theme.breakpoints.up('lg')]: {
      alignItems: 'start',
    },
  },
  whatIsTicket: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: '2rem',
    cursor: 'pointer',
    color: theme.palette.common.white,
    textDecoration: 'underline',
    fontWeight: 'bold',
    fontSize: 16,
    [theme.breakpoints.up('lg')]: {
      paddingTop: '0',
    },
  },
  teaserBox: {
    width: '90vw',
    height: '53vw',
    borderRadius: '12px',
    maxWidth: '343px',
    maxHeight: '201px',
    margin: '0 auto',
    [theme.breakpoints.up('md')]: {
      borderRadius: '14px',
      maxWidth: '686px',
      maxHeight: '400px',
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: '1264px',
      maxHeight: '747px',
    },
  },
  gridContainer: {
    marginTop: '8px',
  },
  divider: {
    margin: '24px 0 48px 0',
    [theme.breakpoints.up('md')]: {
      margin: '24px 0 60px 0',
    },
    [theme.breakpoints.up('lg')]: {
      margin: '24px 0 80px 0',
    },
  },
  hideDesktop: {
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  hideMobile: {
    [theme.breakpoints.down('lg')]: {
      display: 'none',
    },
  },
  buttonsBox: {
    marginTop: '24px',
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.up('lg')]: {
      width: '586px',
      justifyContent: 'space-between',
    },
  },
  ctaSubscribe: {
    borderColor: `${colors.light} !important`,
    color: `${colors.light} !important`,
    marginRight: '10px !important',
    [theme.breakpoints.up('lg')]: {
      marginRight: '0 !important',
    },
  },
}));
