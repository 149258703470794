import clsx from 'clsx';
import {Dispatch} from 'redux';
import {connect} from 'react-redux';
import {makeStyles} from '@material-ui/core';
import colors from '../../../../styles/colors';
import {Button} from '../../primitives/Button';
import {useLocalization} from '../../../utils/hooks/use-localization';
import {StartBidFlowCommand} from '../../../redux/modules/auctions/actions';

const useStyles = makeStyles((theme) => ({
  default: {
    background: `linear-gradient(277.13deg, ${colors.buttonSecondary} 11.98%, ${theme.palette.secondary.main} 83.77%)`,
    color: colors.light,
  },
  green: {
    color: colors.green,
    background: colors.snowWhite,
    border: `1px solid ${colors.green}`,
    fontSize: '12px',
    lineHeight: '20px',
  },
}));

export interface BidButtonProps {
  type?: 'default' | 'green';
  className?: string;
  text?: string;
  disabled?: boolean;
  onClick: () => void;
}

const PlainBidButton = ({type = 'default', className, text, disabled, onClick}: BidButtonProps) => {
  const styles = useStyles()[type];
  const {t} = useLocalization();

  return (
    <Button className={clsx(className, styles)} disabled={disabled} onClick={onClick}>
      {text || t('Bid')}
    </Button>
  );
};

const mapDispatchToProps = (
  dispatch: Dispatch,
  {nftId, sellerWalletAddress}: {nftId: string; sellerWalletAddress: string},
) => ({
  onClick: () => dispatch(StartBidFlowCommand.create(nftId, sellerWalletAddress)),
});

export const BidButton = connect(() => ({}), mapDispatchToProps)(PlainBidButton);
