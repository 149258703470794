import {Box, makeStyles} from '@material-ui/core';

import {useLocalization} from '../../hooks/use-locale.hook';
import {MainTitle} from '../Title';
import {Skeleton} from './Skeleton';

const useStyles = makeStyles((theme) => ({
  mainTitle: {
    paddingTop: '40px',
    paddingBottom: '16px',

    [theme.breakpoints.up('md')]: {
      paddingBottom: '24px',
    },
    [theme.breakpoints.up('lg')]: {
      paddingTop: '80px',
    },
  },
  root: {
    display: 'grid',
    gridTemplateRows: 'repeat(3, 1fr)',
    gridRowGap: '12px',

    [theme.breakpoints.up('lg')]: {
      gridTemplateRows: 'unset',
      gridRowGap: '0',
      gridTemplateColumns: 'repeat(3, 1fr)',
      gridColumnGap: '12px',
    },
  },
  card: {
    display: 'flex',
    padding: '12px',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '12px',
    overflow: 'hidden',
  },
  image: {
    width: '100px',
    height: '100px',
    borderRadius: '12px',
  },
  cardInfo: {
    position: 'relative',
    marginLeft: '12px',
    flexGrow: 2,
  },
  title: {
    position: 'absolute',
    top: '0',
    left: '0',
    width: '140px',
    height: '45px',
    borderRadius: '12px',
  },
  price: {
    position: 'absolute',
    bottom: '0',
    left: '0',
    width: '74px',
    height: '22px',
    borderRadius: '4px',
  },
  button: {
    position: 'absolute',
    bottom: '0',
    right: '0',
    width: '90px',
    height: '34px',
    borderRadius: '17px',
  },
}));

export const NftAdditionsSkeleton = () => {
  const {t} = useLocalization();
  const styles = useStyles();

  return (
    <>
      <Box className={styles.mainTitle}>
        <MainTitle>{t('More collectibles')}</MainTitle>
      </Box>
      <Box className={styles.root}>
        {Array(3)
          .fill(0)
          .map((_, index) => (
            <Box className={styles.card} key={index}>
              <Skeleton variant="rect" className={styles.image} />
              <Box className={styles.cardInfo}>
                <Skeleton variant="rect" className={styles.title} />
                <Skeleton variant="rect" className={styles.price} />
                <Skeleton variant="rect" className={styles.button} />
              </Box>
            </Box>
          ))}
      </Box>
    </>
  );
};
