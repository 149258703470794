import {createAuction, getAuctionAddress, Auction} from '@cere/freeport-sdk';
import {NonCustodyWalletTypeEnum} from '@cere/services-types';
import {getDavinciSignerAndProvider} from '../../shared/services/get-signer-and-provider';
import {APPLICATION, CONTRACTS_DEPLOYMENT} from '../../config/common';
import {getSigner} from '../../shared/services/non-custody-wallet';
import {AppWallet} from '../../shared/types/supported-wallet';
import {LOCAL_STORAGE_KEY_TOKEN} from '../../const/storage-keys';

export class FreeportSCAuctionProvider {
  private custodyAuctionMap = new Map<string, Auction>();
  private nonCustodyAuctionMap = new Map<NonCustodyWalletTypeEnum, Auction>();

  public async getAuction(): Promise<Auction> {
    const userToken = localStorage.getItem(LOCAL_STORAGE_KEY_TOKEN);
    const walletType = localStorage.getItem('selectedWallet');

    if (!userToken && !walletType) {
      throw new Error('Cannot get Auction for the provided options');
    }
    if (walletType === AppWallet.DAVINCI && userToken) {
      return this.getCustodyAuction(userToken);
    }
    return this.getNonCustodyAuction(walletType! as NonCustodyWalletTypeEnum);
  }

  private async getCustodyAuction(userToken: string): Promise<Auction> {
    if (this.custodyAuctionMap.has(userToken)) {
      return this.custodyAuctionMap.get(userToken)!;
    }
    const {signer, provider} = await getDavinciSignerAndProvider(userToken);
    const contractAddress = await getAuctionAddress(provider, CONTRACTS_DEPLOYMENT, APPLICATION());

    const simpleAuction = createAuction({signer, contractAddress});

    this.custodyAuctionMap.set(userToken, simpleAuction);

    return simpleAuction;
  }

  private async getNonCustodyAuction(type: NonCustodyWalletTypeEnum): Promise<Auction> {
    if (this.nonCustodyAuctionMap.has(type)) {
      return this.nonCustodyAuctionMap.get(type)!;
    }
    const signer = await getSigner(type);
    const contractAddress = await getAuctionAddress(signer.provider, CONTRACTS_DEPLOYMENT, APPLICATION());

    const simpleAuction = createAuction({signer, contractAddress});

    this.nonCustodyAuctionMap.set(type, simpleAuction);

    return simpleAuction;
  }

  public resetAuctions(): void {
    this.custodyAuctionMap.clear();
    this.nonCustodyAuctionMap.clear();
  }
}
