import {alpha, Box, makeStyles, Typography} from '@material-ui/core';
import {noop} from 'lodash';
import {Notification} from '@cere/rxb-template-ui-kit';
import clsx from 'clsx';

import colors from '../../../styles/colors';
import Bid from '../../../assets/check.svg';
import {AuctionCardLargeBase} from './auctionCardLargeBase';
import {MouseEventHandler, ReactNode} from 'react';
import {useLocalization} from '../../hooks/use-locale.hook';
import {NftActionButton} from '../ActionButtons';

const useStyles = makeStyles((theme) => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',

    [theme.breakpoints.up('md')]: {
      width: '200px',
      minWidth: '200px',
      maxWidth: '200px',
    },
  },
  bid: {
    color: colors.grey,
    marginLeft: '5px',
  },
  button: {
    background: theme.palette.info.main,
    color: theme.palette.common.white,
    border: 'none',
    maxHeight: '36px',
    width: '100%',

    '&:hover': {
      background: alpha(theme.palette.info.main, 0.6),
    },
    '&.Mui-disabled': {
      background: alpha(theme.palette.info.main, 0.3),
    },
  },
  caption: {
    fontSize: '12px',
  },
  price: {
    fontSize: '20px',
    fontWeight: 'bold',
    lineHeight: 1.167,
  },
}));

type AuctionCardLargePriceProps = {
  isFixedPrice: boolean;
  offersCount: string;
  price: string | number;
  hidePrice: boolean;
};

type AuctionCardLargeProps = {
  title: string;
  isFixedPrice: boolean;
  subtitle: string;
  offersCount: string;
  price: string | number;
  imgSrc: string | undefined;
  onCardClick: MouseEventHandler;
  onClick: MouseEventHandler;
  disabled: boolean;
  buttonText: string;
  isBadge?: boolean;
  className?: string;
  contentClassName?: string;
  children?: ReactNode;
  mediaContent: ReactNode;
  description: string;
  descriptionClassName?: string;
  hidePrice: boolean;
};

const AuctionCardLargePrice = ({isFixedPrice, offersCount, price, hidePrice}: AuctionCardLargePriceProps) => {
  const classes = useStyles();
  const {t} = useLocalization();

  if (hidePrice) {
    return null;
  }

  return (
    <>
      {isFixedPrice ? (
        <Typography className={classes.caption} display="block">
          {t('Price')}
        </Typography>
      ) : (
        <Typography className={classes.caption} display="block">
          {offersCount ? t('Highest bid') : t('Starting price')}
          <Typography component="span" className={clsx(classes.bid, classes.caption)}>
            {offersCount}
          </Typography>
        </Typography>
      )}
      <Typography className={classes.price} display="block">
        {price}
      </Typography>
    </>
  );
};

const AuctionCardLarge = ({
  title,
  isFixedPrice,
  subtitle,
  price,
  offersCount,
  imgSrc,
  onCardClick = noop,
  isBadge,
  className,
  mediaContent,
  description = '',
  descriptionClassName = '',
  hidePrice = false,
}: AuctionCardLargeProps) => {
  const classes = useStyles();
  return (
    <AuctionCardLargeBase
      title={title}
      description={description}
      subtitle={subtitle}
      imgSrc={imgSrc}
      onCardClick={onCardClick}
      className={className}
      contentClassName={classes.content}
      mediaContent={mediaContent}
      descriptionClassName={descriptionClassName}
    >
      <Box>
        <AuctionCardLargePrice
          isFixedPrice={isFixedPrice}
          offersCount={offersCount}
          price={price}
          hidePrice={hidePrice}
        />
        <Box mt="13px" width="100%">
          {!isBadge ? (
            <NftActionButton className={classes.button} />
          ) : (
            <Notification
              icon={<img src={Bid} alt="Not found" />}
              text="You have the highest bid!"
              textVariant="caption1"
              variant="secondary"
              size="sm"
            />
          )}
        </Box>
      </Box>
    </AuctionCardLargeBase>
  );
};

export {AuctionCardLarge};
