import {IPurchaseHistoryApi} from '../../../api/purchase-history/IPurchaseHistoryApi';
import {WalletConnectionService} from '../../../models/wallet/WalletConnectionService';
import {StoreModule} from '../../base/types';
import {PURCHASE_HISTORY_MODULE_NAME} from './constants';
import {purchasedNftsReducer} from './reducers';
import {
  initPurchaseHistoryLoadingMiddleware,
  loadPurchasedNftsMiddleware,
  reloadPurchasedNftsMiddleware,
} from './middlewares';

export const createPurchaseHistoryModule = (
  purchaseHistoryApi: IPurchaseHistoryApi,
  walletConnectionService: WalletConnectionService,
): StoreModule => ({
  title: PURCHASE_HISTORY_MODULE_NAME,
  moduleReducer: purchasedNftsReducer,
  middlewares: [
    initPurchaseHistoryLoadingMiddleware,
    loadPurchasedNftsMiddleware(purchaseHistoryApi, walletConnectionService),
    reloadPurchasedNftsMiddleware(purchaseHistoryApi, walletConnectionService),
  ],
});
