import {Box, Divider, makeStyles} from '@material-ui/core';

import {Skeleton} from './Skeleton';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '12px 12px 0px 0px',
    overflow: 'hidden',

    [theme.breakpoints.down('md')]: {
      width: '343px',
      marginLeft: 'auto',
      marginRight: 'auto',
    },

    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  nftImage: {
    width: '343px',
    height: '343px',

    [theme.breakpoints.up('md')]: {
      width: '328px',
      height: '328px',
    },

    [theme.breakpoints.up('lg')]: {
      width: '508px',
      height: '508px',
    },
  },
  nftInfoBox: {
    position: 'relative',
    flexGrow: 2,
    padding: '12px 12px 24px',

    [theme.breakpoints.up('md')]: {
      padding: '24px',
    },

    [theme.breakpoints.up('lg')]: {
      padding: '32px',
    },
  },
  title: {
    width: '100%',
    height: '26px',
    borderRadius: '4px',
    marginBottom: '12px',

    [theme.breakpoints.up('md')]: {
      borderRadius: '8px',
    },

    [theme.breakpoints.up('lg')]: {
      height: '40px',

      borderRadius: '12px',
    },
  },
  subTitle: {
    width: '80px',
    height: '22px',
    borderRadius: '11px',
    marginBottom: '12px',
  },
  creatorBox: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '12px',
    [theme.breakpoints.up('lg')]: {
      marginBottom: '24px',
    },
  },
  creatorLogo: {
    width: '24px',
    height: '24px',
    marginRight: '12px',
    [theme.breakpoints.up('md')]: {
      marginRight: '6px',
    },
  },
  creatorName: {
    width: '150px',
    height: '24px',
    borderRadius: '4px',

    [theme.breakpoints.up('md')]: {
      width: '127px',
      borderRadius: '8px',
    },
    [theme.breakpoints.up('lg')]: {
      width: '167px',
    },
  },
  desktopDivider: {
    marginBottom: '24px',
    [theme.breakpoints.down('lg')]: {
      display: 'none',
    },
  },
  totalAmount: {
    width: '194px',
    height: '22px',
    borderRadius: '8px',
    marginBottom: '12px',
    [theme.breakpoints.down('lg')]: {
      display: 'none',
    },
  },
  price: {
    width: '100%',
    height: '26px',
    borderRadius: '4px',
    marginBottom: '8px',
    [theme.breakpoints.up('md')]: {
      height: '36px',
      borderRadius: '8px',
      marginBottom: '12px',
    },
    [theme.breakpoints.up('lg')]: {
      width: '136px',
      height: '40px',
      borderRadius: '8px',
      marginBottom: '24px',
    },
  },
  button: {
    width: '100%',
    height: '48px',
    borderRadius: '24px',
    [theme.breakpoints.up('lg')]: {
      width: '292px',
    },
  },
  relatedExhibits: {
    width: '100%',
    padding: '12px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.up('lg')]: {
      position: 'absolute',
      bottom: '0',
      left: '0',
      padding: '18px',
      borderTop: `1px solid ${theme.palette.divider}`,
    },
  },
  relatedExhibitsLink: {
    width: '182px',
    height: '24px',
    borderRadius: '4px',
  },
}));

export const NftDetailBlock = () => {
  const styles = useStyles();

  return (
    <Box className={styles.root}>
      <Skeleton variant="rect" className={styles.nftImage} />
      <Box className={styles.nftInfoBox}>
        <Skeleton variant="rect" className={styles.title} />
        <Skeleton variant="rect" className={styles.subTitle} />
        <Box className={styles.creatorBox}>
          <Skeleton variant="circle" className={styles.creatorLogo} />
          <Skeleton variant="rect" className={styles.creatorName} />
        </Box>
        <Divider className={styles.desktopDivider} />
        <Skeleton variant="rect" className={styles.totalAmount} />
        <Skeleton variant="rect" className={styles.price} />
        <Skeleton variant="rect" className={styles.button} />
        <Box className={styles.relatedExhibits}>
          <Skeleton variant="rect" className={styles.relatedExhibitsLink} />
        </Box>
      </Box>
    </Box>
  );
};
