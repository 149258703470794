import {Box, makeStyles, useMediaQuery, useTheme, Theme} from '@material-ui/core';

import {Skeleton} from './Skeleton';

const useStyles = makeStyles<
  Theme,
  {
    isNftCollectiblesCard?: boolean;
  }
>((theme) => ({
  root: {
    borderRadius: '12px',
    overflow: 'hidden',

    [theme.breakpoints.down('md')]: {
      maxWidth: '328px',
      width: ({isNftCollectiblesCard}) => (isNftCollectiblesCard ? '167px' : '328px'),
    },

    [theme.breakpoints.up('md')]: {
      width: '328px',
    },

    [theme.breakpoints.up('lg')]: {
      width: ({isNftCollectiblesCard}) => (isNftCollectiblesCard ? '300px' : '400px'),
    },
  },
  image: {
    width: '336px',
    height: '336px',

    [theme.breakpoints.down('md')]: {
      height: ({isNftCollectiblesCard}) => (isNftCollectiblesCard ? '167px' : '336px'),
    },

    [theme.breakpoints.up('lg')]: {
      width: ({isNftCollectiblesCard}) => (isNftCollectiblesCard ? '300px' : '400px'),
      height: ({isNftCollectiblesCard}) => (isNftCollectiblesCard ? '298px' : '336px'),
    },
  },
  nftInfo: {
    backgroundColor: theme.palette.background.default,
    padding: '1rem',
  },
  creator: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: '0.5rem',
  },
  creatorLogo: {
    width: '24px',
    height: '24px',
    marginRight: '0.5rem',

    [theme.breakpoints.down('md')]: {
      width: ({isNftCollectiblesCard}) => (isNftCollectiblesCard ? '20px' : '24px'),
      height: ({isNftCollectiblesCard}) => (isNftCollectiblesCard ? '20px' : '24px'),
    },
  },
  creatorTitle: {
    width: '114px',

    [theme.breakpoints.down('md')]: {
      width: ({isNftCollectiblesCard}) => (isNftCollectiblesCard ? '77px' : '114px'),
    },
  },
  ticketInfo: {
    borderRadius: '1rem',
    paddingBottom: '0.5rem',
  },
  buyBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  button: {
    width: '122px',
    height: '44px',
    borderRadius: '22px',

    [theme.breakpoints.down('md')]: {
      width: ({isNftCollectiblesCard}) => (isNftCollectiblesCard ? '82px' : '122px'),
      height: ({isNftCollectiblesCard}) => (isNftCollectiblesCard ? '36px' : '44px'),
      borderRadius: ({isNftCollectiblesCard}) => (isNftCollectiblesCard ? '18px' : '22px'),
    },
  },
  nftText: {
    width: ({isNftCollectiblesCard}) => (isNftCollectiblesCard ? '137px' : '274px'),
    marginBottom: '1rem',

    [theme.breakpoints.up('md')]: {
      width: '274px',
    },

    [theme.breakpoints.up('lg')]: {
      width: ({isNftCollectiblesCard}) => (isNftCollectiblesCard ? '228px' : '274px'),
    },
  },
}));

type Props = {
  isNftCollectiblesCard?: boolean;
};

export const NftCardSkeleton = ({isNftCollectiblesCard}: Props) => {
  const styles = useStyles({isNftCollectiblesCard});
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box className={styles.root}>
      <Skeleton variant="rect" className={styles.image} />

      <Box className={styles.nftInfo}>
        <Box className={styles.creator}>
          <Skeleton className={styles.creatorLogo} variant="circle" />
          <Skeleton className={styles.creatorTitle} variant="text" />
        </Box>
        <Skeleton className={styles.nftText} variant="text" />
        {!isMobile && <Skeleton variant="text" width={127} />}
        <Box className={styles.buyBox}>
          {!isMobile && <Skeleton variant="text" width={75} />}
          <Skeleton className={styles.button} variant="rect" />
        </Box>
      </Box>
    </Box>
  );
};
