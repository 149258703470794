import colors from '../../../../../styles/colors';
import {Box, CircularProgress} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles({
  wrapper: {
    marginTop: '33%',
    color: colors.light,
  },
  circularProgress: {
    color: 'inherit',
  },
});

export const PreviewLoader = () => {
  const classes = useStyles();

  return (
    <Box className={classes.wrapper}>
      <CircularProgress size={36} thickness={2} className={classes.circularProgress} />
    </Box>
  );
};
