import {useCallback} from 'react';
import {Box, Button, Card, CardContent, CardMedia, CircularProgress, makeStyles, Theme} from '@material-ui/core';
import {useHistory} from 'react-router-dom';
import clsx from 'clsx';

import {ReactComponent as EmptyCart} from '../../../assets/empty-cart.svg';
import {CreatorWalletNft} from '../../../shared/types/graphql/creator-wallet-nfts';
import colors from '../../../styles/colors';
import {GoogleAnalyticsId} from '../../../analytics-ids';
import {NftInfoCollection} from './nftInfoCollection';
import {useLocalization} from '../../../shared/hooks/use-locale.hook';
import {Trans} from 'react-i18next';
import {getNFTCardImage} from 'shared/helpers/nfts';

const useStyles = makeStyles((theme: Theme) => ({
  loaderPage: {
    backgroundColor: colors.light,
    color: colors.primaryDark,
    minHeight: '300px',
  },
  loader: {
    margin: 'auto',
  },

  list: {
    '--size': '164px',
    padding: '0.5rem 0 1rem',

    display: 'grid',
    gap: '16px 16px',
    justifyContent: 'center',
    gridTemplateColumns: 'repeat(auto-fill, var(--size))',

    [theme.breakpoints.up('sm')]: {
      gap: '24px 32px',
      justifyContent: 'start',
      '--size': '208px',
    },
    [theme.breakpoints.up('lg')]: {
      '--size': '184px',
    },
  },

  selectorNftRoot: {
    position: 'relative',
    overflow: 'unset',
    boxShadow: '0px 4px 20px rgba(0, 0, 0, .12)',
    borderRadius: '12px',
  },

  cardMedia: {
    borderRadius: '12px 12px 0 0',
    width: 'var(--size)',
    height: 'var(--size)',
  },

  cardTitle: {
    margin: '10px 0 10px 0',
    fontWeight: 600,
    fontSize: '0.9rem',
  },
  emptyNftList: {
    display: 'flex',
    minHeight: '300px',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  emptyNftImage: {
    display: 'block',
    margin: 'auto',
  },
  emptyNftText: {
    color: colors.grey,
    fontSize: '14px',
    fontWeight: 400,
    margin: '20px 10px',

    [theme.breakpoints.up('md')]: {
      width: '378px',
    },

    [theme.breakpoints.up('lg')]: {
      width: '516px',
    },
  },
  button: {
    backgroundColor: colors.primaryDark,
    color: colors.white,
    fontWeight: 600,
    textTransform: 'none',
    borderRadius: '50px',
    padding: '6px 20px 6px 20px',
    '&:hover': {
      backgroundColor: colors.primaryDark,
    },
  },
  selectorNftContent: {
    padding: '0.5rem',

    '&:last-child': {
      paddingBottom: '0.5rem',
    },
  },
}));

type Props = {
  nfts: CreatorWalletNft[];
  isLoading?: boolean;
};

export const UserNftCollectionComponent = ({nfts, isLoading = false}: Props) => {
  const {t, locale} = useLocalization();
  const classes = useStyles();
  const history = useHistory();

  const handleViewNft = useCallback(
    (nft: CreatorWalletNft) => history.push(`/${locale}/home/nft/${nft.nft_id.cmsNft.id}`),
    [history, locale],
  );

  if (isLoading) {
    return (
      <Box className={classes.loaderPage} display="flex">
        <CircularProgress size={36} thickness={2} color="inherit" className={classes.loader} />
      </Box>
    );
  }

  if (!nfts.length) {
    return (
      <Box className={classes.emptyNftList}>
        <div>
          <EmptyCart className={classes.emptyNftImage} />
          <p className={classes.emptyNftText}>
            <Trans i18nKey="You do not yet own any NFTs.<br /> Discover the ongoing exhibits and buy an access NFT to gain access to more exclusive NFTs." />
          </p>

          <Button
            className={clsx(classes.button, GoogleAnalyticsId.ExploreNftsBtn)}
            variant="text"
            onClick={() => history.push(`/${locale}/home`)}
          >
            {t('Browse NFTs')}
          </Button>
        </div>
      </Box>
    );
  }
  return (
    <Box className={classes.list}>
      {nfts.map((nft: CreatorWalletNft) => {
        return (
          <Card key={nft.nft_id.nft_id} classes={{root: classes.selectorNftRoot}} onClick={() => handleViewNft(nft)}>
            <CardMedia className={classes.cardMedia} image={getNFTCardImage(nft.nft_id.cmsNft)} />
            <CardContent classes={{root: classes.selectorNftContent}}>
              <NftInfoCollection
                title={nft.nft_id.cmsNft.title}
                id={nft.nft_id.id}
                cmsNft={nft.nft_id.cmsNft}
                limit={nft.nft_id.cmsNft?.creatorNft?.supply ?? 0}
                qtyOwned={nft.quantity}
              />
            </CardContent>
          </Card>
        );
      })}
    </Box>
  );
};
