import {useCallback, useMemo} from 'react';
import {MaterialUiPickersDate} from '@material-ui/pickers/typings/date';
import {MuiPickersUtilsProvider, DatePicker} from '@material-ui/pickers';
import DateUtils from '@date-io/dayjs';
import clsx from 'clsx';
import {useFiltersStyles} from './styles';
import {CollapsibleCard} from '../CollapsibleCard';
import {useFilter} from '../../../context/filters-context';
import {ReactComponent as CalendarIcon} from '../../../assets/icons/calendar.svg';
import {InputAdornment, Theme, useMediaQuery} from '@material-ui/core';
import {useLocalization} from '../../hooks/use-locale.hook';

const DATE = 'date';
const MAX_TIMESTAMP_LENGTH = (10e13).toString().length;
const TIMESTAMP_RATIO = 1000;

export const DateFilter = () => {
  const styles = useFiltersStyles();
  const {filterParams, setFilterValue} = useFilter();
  const isDesktop = useMediaQuery<Theme>((theme) => `${theme.breakpoints.up('lg')}`);
  const date = Number(filterParams[DATE]);
  const {t} = useLocalization();

  const onChange = useCallback(
    (value: MaterialUiPickersDate) => {
      const ms = value ? value.unix() * TIMESTAMP_RATIO : undefined;
      setFilterValue(DATE, ms);
    },
    [setFilterValue],
  );

  const value = useMemo(() => {
    if (!date) {
      return null;
    }
    if (date.toString().length > MAX_TIMESTAMP_LENGTH) {
      return date / TIMESTAMP_RATIO;
    }
    return date;
  }, [date]);

  return (
    <CollapsibleCard
      className={styles.collapsible}
      title={t('Date')}
      initiallyOpened={isDesktop}
      stayOpened={isDesktop}
      noDivider
    >
      <MuiPickersUtilsProvider utils={DateUtils}>
        <DatePicker
          className={clsx(styles.input, styles.datePicker)}
          inputVariant="outlined"
          emptyLabel={t('Select date')}
          format="MM/DD/YYYY"
          value={value}
          onChange={onChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <CalendarIcon />
              </InputAdornment>
            ),
          }}
        />
      </MuiPickersUtilsProvider>
    </CollapsibleCard>
  );
};
