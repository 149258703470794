import {ReactElement} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Box} from '@material-ui/core';
import {Typography} from '@cere/rxb-template-ui-kit';

import {ReactComponent as HourglassIcon} from '../../../assets/hourglass.svg';
import {PaymentTypeEnum} from '@cere/services-types';
import colors from '../../../styles/colors';
import AverageNftPaymentCountdown from '../average-payment-countdown/average-payment-countdown.component';
import {CloseButton} from '../CloseButton';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    borderRadius: '12px',
    background: colors.light,
    padding: '20px 70px 16px 20px',
    width: '343px',
    maxWidth: '343px',
    bottom: '8px',
    position: 'fixed',
    margin: '0 auto',
    left: 0,
    right: 0,
    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.12)',
    lineHeight: '16px',
    zIndex: 100,
    [theme.breakpoints.up('md')]: {
      width: '687px',
      maxWidth: '687px',
    },
    [theme.breakpoints.up('lg')]: {
      width: '832px',
      maxWidth: '832px',
    },
  },
  icon: {
    flex: '0 0 auto',
    fill: theme.palette.primary.main,
    marginRight: '16px',

    '& svg': {
      width: '28px',
      height: '28px',
    },
  },
  closeContainerIconStyle: {
    position: 'absolute',
    top: '18px',
    right: '16px',

    '& button': {
      '--buttonSize': '24px',
      width: 'var(--buttonSize)',
      maxWidth: 'var(--buttonSize)',
      height: 'var(--buttonSize)',

      '& svg': {
        '--iconSize': '14px',
        width: 'var(--iconSize)',
        maxWidth: 'var(--buttonSize)',
        height: 'var(--iconSize)',
      },
    },
  },
  countdown: {
    color: colors.primaryDark,
    '& span': {
      color: colors['navbar-text'],
    },
  },
  textColor: {
    color: colors.primaryDark,
  },
  hourglassIconStyle: {
    marginTop: '-3px',
    marginRight: '8px',
    '& svg': {
      width: '28px',
      height: '28px',
      '& path': {
        fill: theme.palette.primary.main,
      },
    },
  },
}));

interface TransactionProcessingModalProps {
  open: boolean;
  headerText: string;
  message: string;
  countdownTitle: string;
  expectedProcessingTime?: number;
  onClose: () => void;
}

export const TransactionProcessingModal = ({
  open,
  headerText,
  message,
  countdownTitle,
  expectedProcessingTime,
  onClose,
}: TransactionProcessingModalProps): ReactElement | null => {
  const classes = useStyles();

  if (!open) return null;

  return (
    <Box className={classes.root}>
      <Box className={classes.hourglassIconStyle}>
        <HourglassIcon />
      </Box>
      <Box className={classes.closeContainerIconStyle}>
        <CloseButton onClick={onClose} />
      </Box>
      <Box>
        <Box mb="11px">
          <Typography variant="h4" className={classes.textColor}>
            {headerText}
          </Typography>
        </Box>
        <Typography mb="16px" variant="caption1" className={classes.textColor}>
          {message}
        </Typography>
        <Box mt="16px">
          <AverageNftPaymentCountdown
            text={countdownTitle}
            className={classes.countdown}
            type={PaymentTypeEnum.USD_TO_NFT}
            averagePaymentTime={expectedProcessingTime}
            onComplete={onClose}
          />
        </Box>
      </Box>
    </Box>
  );
};
