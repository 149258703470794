import {ReactElement, useContext, useMemo} from 'react';
import {Box, makeStyles, Theme, useMediaQuery} from '@material-ui/core';
import {Typography} from '@cere/rxb-template-ui-kit';
import clsx from 'clsx';
import discordIcon from './discord-icon.svg';
import {styled} from '@linaria/react';
import {getTimelineStatus} from '../../../../Сms/Exhibit/utils';
import {EventTimelineStatus} from '../../../../../shared/types/event';
import colors from '../../../../../styles/colors';
import {isUrlToImage} from '../../../../../shared/lib/isUrrlToImage';
import {UserPaymentHistoryContext} from '../../../../../context/payments-history/user-payment-history.context';
import {getIsPaymentSuccess} from '../../../../../shared/helpers/paymentStatus';
import {ExhibitContext} from '../../../../../context/exhibition/exhibit-context';
import {useLocalization} from '../../../../../shared/hooks/use-locale.hook';

const Preview = styled.div<{url: string}>`
  background: transparent center center;
  background-size: cover;
  background-image: ${({url}) => `url("${url}")`};
`;

const useStyles = makeStyles((theme) => ({
  artistBlock: {
    background: theme.palette.grey['200'],
    overflow: 'hidden',
    minWidth: '270px',
  },
  artistImage: {
    aspectRatio: '16/9',
    position: 'relative',

    [theme.breakpoints.up('lg')]: {
      aspectRatio: '20/1',
    },
  },
  title: {
    margin: '1rem 0 0.7rem',
    maxWidth: '406px',
    color: colors.primaryDark,
    [theme.breakpoints.up('lg')]: {
      paddingRight: '120px',
      fontWeight: 800,
      fontSize: '28px',
      lineHeight: '36px',
      borderRight: '2px solid #3cbcac',
    },
  },
  text: {
    margin: '0.3rem 0',
    padding: '.5rem 0 .5rem 1rem',
    borderLeft: '2px solid #3cbcac',
    [theme.breakpoints.up('lg')]: {
      fontWeight: 'normal',
      fontSize: '16px',
      lineHeight: '24px',
      color: '#000000',
      display: 'flex',
      alignSelf: 'center',
      borderLeft: 'none',
    },
  },
  discordIcon: {
    position: 'absolute',
    width: '46px',
    height: '46px',
    borderRadius: '100%',
    left: 0,
    right: 0,
    bottom: '-23px',
    margin: '0 auto',
    background: '#5865f2',
    boxSizing: 'content-box',
    border: `6px solid ${theme.palette.grey['200']}`,

    '& img': {
      width: '100%',
      height: '100%',
    },

    [theme.breakpoints.up('lg')]: {
      width: '60px',
      height: '60px',
      bottom: '-37px',
    },
  },
  discordButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '1rem',

    '& > a': {
      display: 'inline-flex',
      fontSize: '0.9rem',
      padding: '0rem 1rem 0rem 0.5rem',
      alignItems: 'center',
      borderRadius: '2rem',
      textDecoration: 'none',
      fontWeight: 600,
      lineHeight: '20px',
      color: '#fff',
      background: '#000',
    },
  },
  tablet: {
    width: '50%',
    marginRight: '32px',
  },
}));

export function ExhibitionStayUpdated(): ReactElement {
  const {t} = useLocalization();
  const styles = useStyles();
  const {event, accessNfts} = useContext(ExhibitContext);
  const {getPaymentStatuses, getNftIsPurchased} = useContext(UserPaymentHistoryContext);
  const someOfAccessNFTsIsPurchased = useMemo(
    () =>
      accessNfts.some(
        (nft) =>
          getNftIsPurchased(nft.cmsNft?.creatorNft?.nft_id) ||
          getIsPaymentSuccess(...getPaymentStatuses(nft.cmsNft?.creatorNft?.nft_id)),
      ),
    [accessNfts, getNftIsPurchased, getPaymentStatuses],
  );
  const isMomentBeforeExhibitionStarted = getTimelineStatus(event) === EventTimelineStatus.NOT_STARTED;

  const isMobile = useMediaQuery<Theme>((theme) => `${theme.breakpoints.down('sm')}`);
  const isDesktop = useMediaQuery<Theme>((theme) => `${theme.breakpoints.up('lg')}`);

  if (!event?.artistQuote || !event?.artistQuoteVisual) {
    return <></>;
  }

  if (!someOfAccessNFTsIsPurchased || !isMomentBeforeExhibitionStarted) {
    return <></>;
  }

  return (
    <Box
      boxShadow={1}
      mt={!isDesktop && !isMobile ? '24px' : 4}
      mb={isDesktop && '48px'}
      className={isDesktop || isMobile ? styles.artistBlock : clsx(styles.artistBlock, styles.tablet)}
      borderRadius="1rem"
    >
      <Preview
        url={isUrlToImage(event.artistQuoteVisual?.url) ? event.artistQuoteVisual?.url : event.image.url || ''}
        className={styles.artistImage}
      >
        {event.cmsCreator?.discordLink && (
          <div className={styles.discordIcon}>
            <img alt="discord-icon" width={30} height={30} src={discordIcon} />
          </div>
        )}
      </Preview>
      <Box p="24px">
        <Box px={2} display={isDesktop ? 'flex' : 'block'}>
          <Typography className={styles.title} variant={isDesktop ? 'h2' : 'h3'}>
            {t('Stay updated by {{name}}', {name: event.cmsCreator.name})}
          </Typography>
          <Typography className={styles.text} variant="body2">
            {event.artistQuote}
          </Typography>
        </Box>
        {event.cmsCreator?.discordLink && (
          <Box className={styles.discordButton}>
            <a
              target="_blank"
              rel="noopener noreferrer"
              title={t('Discord Channel')}
              href={event.cmsCreator?.discordLink}
            >
              <img alt="discord-icon" width={36} height={36} src={discordIcon} />
              {t('Discord channel')}
            </a>
          </Box>
        )}
      </Box>
    </Box>
  );
}
