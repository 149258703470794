import analyticService, {AnalyticEventsEnum} from '../../services/analytic.service';
import {OverlayDetailCard} from '../auction/OverlayDetailCard';
import {useCallback, useContext} from 'react';
import {NftContext} from '../Nft/nft-context-v2';
import {AuctionContext} from '../../../pages/ExhibitPage/routes/WebInterstitialExhibitPage/context/auction-context';
import {useLocalization} from '../../hooks/use-locale.hook';
import {AvailabilityStatus, NftCardInterface, NftType} from '@cere/services-types';

export const DetailedCard = ({
  isShowInfo,
  setIsShowInfo,
  eventNfts,
}: {
  isShowInfo: boolean;
  setIsShowInfo: Function;
  eventNfts: NftCardInterface[];
}) => {
  const {t} = useLocalization();
  const {nft, event, balance, button} = useContext(NftContext);
  const {selectedNftIndex, setSelectedNftIndex} = useContext(AuctionContext);
  const nfts = (eventNfts || []).filter((nft) => nft.nftType !== NftType.ACCESS);

  const handleArrowClick = useCallback(
    (index: number) => {
      analyticService.track(AnalyticEventsEnum.AUCTION_CARD_NAVIGATION_ARROW_CLICKED);
      setSelectedNftIndex(index);
    },
    [setSelectedNftIndex],
  );

  return (
    <OverlayDetailCard
      imgSrc={nft.image || ''}
      title={nft.title || ''}
      relType={nft.nftType}
      description={nft.nftType === NftType.AUCTIONED ? t('1 Auctioned original') : ''}
      description1={nft.description || ''}
      subtitle={
        nft.nftType === NftType.AUCTIONED || nft.isComingSoon
          ? ''
          : t('{{leftAmount}}/{{totalAmount}} left', {
              leftAmount: balance,
              totalAmount: nft.supply ?? 1,
            })
      }
      selectedIndex={selectedNftIndex}
      totalLength={nfts.length}
      handleArrowClick={handleArrowClick}
      onClick={() => {
        setIsShowInfo(true);
        button.onClick();
      }}
      isAuction={nft.nftType === NftType.AUCTIONED}
      isOpen={isShowInfo}
      endsAt={event ? new Date(event.endsAt || '') : new Date()}
      disabled={button.disabled}
      buttonText={button.label}
      nft={nft}
      hideNavigation={nfts.length <= 1}
      hidePrice={nft.availability === AvailabilityStatus.COMING_SOON}
    />
  );
};
