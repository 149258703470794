import {AnyAction} from 'redux';
import {NormalizedPendingTransactions} from './types';
import {AllPendingTransactionsDocument, PendingTransactionDocument} from './actions';

export const pendingTransactionsReducer = (
  state: NormalizedPendingTransactions = {},
  action: AnyAction,
): NormalizedPendingTransactions => {
  switch (action.type) {
    case AllPendingTransactionsDocument.type:
      return action.payload;
    case PendingTransactionDocument.type:
      return {
        ...state,
        [action.payload.nftId]: action.payload,
      };
    default:
      return state;
  }
};
