import {createContext, useContext} from 'react';
import {useNftCtaButtonProps} from './use-nft-cta-button-props-v2';
import {Numberish} from '../../types/numberish';
import {useRelatedExhibitionStatus} from './use-find-related-exhibition';
import {
  FullCreatorInterface,
  NftCardInterface,
  AvailabilityStatus,
  ExhibitCardInterface,
  NftType,
} from '@cere/services-types';
import {useNftAttributesV2} from './use-nft-attributes-v2';

export type NftContextTypeV2 = {
  eventUrl: string;
  cmsNftId: Numberish;
  event?: ExhibitCardInterface;
  eventNftStatus: ReturnType<typeof useRelatedExhibitionStatus>;
  nft: NftCardInterface;
  creator?: FullCreatorInterface;
  relType?: NftType;
  attributes: ReturnType<typeof useNftAttributesV2>;
  supply: number;
  balance: number;
  price: {
    units: number;
    usd: number;
    cents: number;
  };
  market: {
    owned: number;
  };
  auction: {
    priceText: string;
  };
  button: ReturnType<typeof useNftCtaButtonProps>;
  isNftDetailsPage?: boolean;
  quantity?: number;
  setQuantity?: (quantity: number) => void;
  hasUserOffer?: boolean;
};

export const NftContext = createContext<NftContextTypeV2>({
  relType: undefined,
  eventNftStatus: 'loading',
  cmsNftId: -100,
  event: undefined,
  nft: {} as any,
  creator: undefined,
  eventUrl: '',
  balance: 0,
  supply: 0,
  attributes: {
    auctionSettled: false,
    availability: AvailabilityStatus.OFFER_ENDED,
    availableOnlyOnMarketplace: false,
    hasPendingTransaction: false,
    counter: '',
    failed: false,
    link: '',
    offerEnded: false,
    order: 0,
    pending: false,
    purchased: false,
    soldOut: false,
  },
  price: {
    units: 0,
    usd: 0,
    cents: 0,
  },
  market: {
    owned: 0,
  },
  auction: {
    priceText: 'Starting price',
  },
  button: {
    label: '',
    disabled: false,
    loading: false,
    isLightButton: false,
    onClick: () => null,
    onShare: () => null,
  },
  isNftDetailsPage: false,
  quantity: 1,
  setQuantity: (quantity) => quantity,
});

export const useNftContextV2 = () => {
  return useContext(NftContext);
};
