import {TextField} from '@cere/rxb-template-ui-kit';
import {makeStyles} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    borderRadius: '16px',

    '&:hover $notchedOutline': {
      borderColor: '#E0E0E7',
    },
  },
  notchedOutline: {
    borderColor: '#E0E0E7',
  },
  focused: {
    '& $notchedOutline': {
      borderColor: '#3CBCAC !important',
    },
  },
  disabled: {
    '& $notchedOutline': {
      borderColor: '#E0E0E7',
    },
  },
  error: {
    '&:hover $notchedOutline': {
      borderColor: '#f44336',
    },
  },
}));

const useLabelStyles = makeStyles(() => ({
  focused: {
    color: '#3CBCAC !important',
  },
}));

type DisabledEmailFieldProps = {
  email: string;
  label: string;
};

export const DisabledEmailField = ({email, label}: DisabledEmailFieldProps) => {
  const inputClasses = useStyles();
  const labelClasses = useLabelStyles();
  return (
    <TextField
      variant="outlined"
      label={label}
      fullWidth
      value={email}
      disabled={true}
      InputProps={{classes: inputClasses}}
      InputLabelProps={{classes: labelClasses}}
    />
  );
};
