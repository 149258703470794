import {Box, Button, makeStyles} from '@material-ui/core';
import {ReactComponent as WarningIcon} from '../../../assets/icons/warning.svg';
import {NFTCTAButton} from '../NFTCTAButton';
import {NFTModal} from '../NFTModal';
import colors from '../../../styles/colors';
import {useLocalization} from '../../hooks/use-locale.hook';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '24px -8px 0 -8px',
    [theme.breakpoints.up('lg')]: {
      margin: '24px -8px 0 -8px',
    },
    [theme.breakpoints.up('md')]: {
      margin: '24px 0 0 0',
    },
  },
  button: {
    borderRadius: '50px',
    border: `1px solid ${colors.primaryDark}`,
  },
  buttonContainer: {
    height: '36px',
    width: '150px',
    '& button': {
      width: '100%',
      height: '36px',
    },
    [theme.breakpoints.up('md')]: {
      width: '200px',
    },
  },
  controlsContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    [theme.breakpoints.up('md')]: {
      width: '412px',
    },
  },
}));

export interface CancelNFTListingModalProps {
  open: boolean;
  isProcessing: boolean;
  onCancel: () => void;
  cancelNftListing: () => Promise<void>;
}

export const CancelNFTListingModal = ({open, isProcessing, onCancel, cancelNftListing}: CancelNFTListingModalProps) => {
  const styles = useStyles();
  const {t} = useLocalization();

  return (
    <NFTModal
      open={open}
      icon={<WarningIcon />}
      title={t('Confirm cancel listing')}
      subTitle={t('Please confirm you want to cancel your listing from marketplace')}
      onClose={onCancel}
    >
      <Box className={styles.root}>
        <Box className={styles.controlsContainer}>
          <Box className={styles.buttonContainer}>
            <Button className={styles.button} variant="outlined" onClick={onCancel} disabled={isProcessing}>
              {t('Cancel')}
            </Button>
          </Box>
          <Box className={styles.buttonContainer}>
            <NFTCTAButton text={t('Confirm')} onClick={cancelNftListing} loading={isProcessing} />
          </Box>
        </Box>
      </Box>
    </NFTModal>
  );
};
