import {useEffect, useCallback} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {useQuery} from '@apollo/client';
import {Box} from '@material-ui/core';

import {useLoadNftById} from 'api/hooks/use-load-nft-by-id';
import {NftProvider2} from 'shared/components/Nft/nft-provider-v2';
import {AppMetaTags} from 'shared/components/SeoHeaders/seo-headers.component';
import {PageLayout} from 'shared/components/PageLayout';
import {PageContainer} from 'shared/components/PageContainer';
import {Condition, ConditionsList} from 'shared/components/Conditions';
import {NftDetailBlock, NftDescription, NftCreator, AccordionSkeleton} from 'shared/components/Skeletons';
import {NFTAdditions} from 'shared/components/NftAdditions';
import {useLocaleUpdated} from 'shared/hooks/use-locale-updated.hook';
import {useScrollToElement} from 'shared/hooks/scroll-to-element.hook';
import {useLocalization} from '../../shared/hooks/use-locale.hook';
import {GET_NFT_SEO_DATA} from 'shared/queries/nfts';
import {CmsNft} from 'shared/types/graphql';
import {PublicationState} from 'shared/types/cms-exhibit';

import {NftPage} from './nft-page';
interface NftPageRouteParams {
  cmsNftIncrementId: string;
}

type Variables = {
  id: string | number;
  publicationState?: PublicationState;
};

export const NftPageWrapper = () => {
  const {t} = useLocalization();
  const {refElement, scrollToElement} = useScrollToElement();

  const {cmsNftIncrementId} = useParams<NftPageRouteParams>();
  const {nft, loading: isNftLoading} = useLoadNftById(cmsNftIncrementId);
  const history = useHistory();
  const {data, previousData} = useQuery<{cmsNft: CmsNft}, Variables>(GET_NFT_SEO_DATA, {
    variables: {id: cmsNftIncrementId},
  });

  const nftData = data || previousData;
  const isInitialLoading = !nft && isNftLoading;

  useEffect(() => {
    scrollToElement();
  }, [scrollToElement]);

  useLocaleUpdated(
    useCallback(
      (newLocale: string) => {
        const nftLocalization = nftData?.cmsNft?.localizations?.find(({locale}) => locale === newLocale);
        const location = nftLocalization
          ? `/${nftLocalization.locale}/home/nft/${nftLocalization.id}`
          : `/${newLocale}/home`;

        history.push(location);
      },
      [history, nftData],
    ),
  );

  const localizations = !nftData
    ? []
    : [
        {
          locale: nftData?.cmsNft.locale || '',
          link: `/${nftData?.cmsNft.locale}/home/exhibit/${nftData?.cmsNft.id}`,
        },
        ...(nftData?.cmsNft?.localizations || []).map((localizedNft) => ({
          locale: localizedNft.locale,
          link: `/${localizedNft.locale}/home/nft/${localizedNft.id}`,
        })),
      ];

  return (
    <PageLayout>
      <div ref={refElement}>
        <PageContainer>
          <ConditionsList>
            <Condition condition={isInitialLoading}>
              <NftDetailBlock />
              <NftDescription />
              <NftCreator />
              <Box pt="16px">
                <AccordionSkeleton title={t('Bid history')} />
              </Box>
              <Box pt="16px">
                <AccordionSkeleton title={t('Listings')} />
              </Box>
              <Box pt="16px">
                <AccordionSkeleton title={t('Details')} />
              </Box>
            </Condition>
            <Condition condition={!!nft}>
              <NftProvider2 nft={nft!}>
                {nftData && (
                  <AppMetaTags
                    title={nftData?.cmsNft.seoTitle || nftData?.cmsNft.title!}
                    description={nftData?.cmsNft.seoDescription || nftData?.cmsNft.description!}
                    canonical={nftData?.cmsNft.seoCanonical}
                    image={nftData?.cmsNft?.seoImage?.url || nftData?.cmsNft?.assets[0]?.content?.url}
                    localizations={localizations}
                  />
                )}
                <NftPage />
              </NftProvider2>
            </Condition>
          </ConditionsList>
          <NFTAdditions />
        </PageContainer>
      </div>
    </PageLayout>
  );
};
