import {useMemo} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {checkPreviewKey} from '../helpers/preview';
import {ExhibitPageRouteParams} from '../../pages/ExhibitPage/exhibit-page-route-params';

export const useIsPreviewMode = () => {
  const {exhibitSlug: slug} = useParams<ExhibitPageRouteParams>();
  const history = useHistory();
  return useMemo(() => {
    const query = new URLSearchParams(history.location.search);
    const eventSlug = (slug || query.get('event')) ?? undefined;
    const previewKey = query.get('preview_key') ?? undefined;
    return checkPreviewKey(eventSlug!, previewKey!);
  }, [history.location.search, slug]);
};

export const useExhibitionPreviewKey = (): string | undefined => {
  const history = useHistory();
  const isPreviewMode = useIsPreviewMode();
  return isPreviewMode ? new URLSearchParams(history.location.search).get('preview_key') ?? undefined : undefined;
};
