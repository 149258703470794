import {makeStyles} from '@material-ui/core';

import './asset-gallery.css';
import {ReactComponent as EmptyVideo} from '../../../assets/empty-video.svg';
import {useEffect, useRef} from 'react';

export const useStyles = makeStyles(() => ({
  video: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    inset: '0px',
  },
}));

export const AssetVideoItem = ({src, mime, isActive}: {src: string; mime: string; isActive: boolean}) => {
  const classes = useStyles();
  const videoRef = useRef<HTMLVideoElement | null>(null);

  useEffect(() => {
    if (isActive) {
      videoRef.current?.play();
    } else {
      videoRef.current?.pause();
    }
  }, [isActive, videoRef]);

  if (!src) {
    return <EmptyVideo className="main-image" />;
  }

  const videoSourceUrl = `${src}#t=0.001`; // Force iOS Safari to render video preview image

  return (
    <>
      <video controls muted className={classes.video} ref={videoRef}>
        <source src={videoSourceUrl} type={mime} />
      </video>
    </>
  );
};
