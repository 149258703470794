import {useCallback, useContext, useState} from 'react';
import {Box, Button, TextField, TextFieldProps, MenuItem, makeStyles} from '@material-ui/core';

import colors from '../../../styles/colors';
import {ReactComponent as Icon} from '../../../assets/icons/transfer.svg';
import {NonCustodyWallet, NonCustodyWalletTitles} from '../../../shared/types/non-custody-wallet';

import {NFTCTAButton} from '../NFTCTAButton';
import {NFTModal} from '../NFTModal';
import {AppContext} from '../../../context/app-context';
import {useLocalization} from '../../hooks/use-locale.hook';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '24px -8px 0 -8px',
    [theme.breakpoints.up('lg')]: {
      margin: '24px -8px 0 -8px',
    },
    [theme.breakpoints.up('md')]: {
      margin: '24px 0 0 0',
    },
  },

  button: {
    borderRadius: '50px',
    border: `1px solid ${colors.primaryDark}`,
    textTransform: 'none',
    fontWeight: 600,
    fontSize: '1rem',
  },

  buttonContainer: {
    width: '150px',

    '& button': {
      width: '100%',
      height: '44px',
    },

    [theme.breakpoints.up('md')]: {
      width: '200px',
    },
  },

  controlsContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    [theme.breakpoints.up('md')]: {
      width: '412px',
    },
  },

  walletSelect: {
    margin: '24px 0 8px 0',

    '& .MuiInputBase-root': {
      borderRadius: '16px',
    },

    '& .MuiSelect-root': {
      backgroundColor: 'transparent',
    },
  },

  modlaContent: {
    paddingLeft: '24px',
    paddingRight: '24px',
  },

  modlaTitle: {
    fontSize: '28px',
    fontWeight: 800,
    marginBottom: '8px',
  },

  modlaMessage: {
    fontSize: '16px',
    marginLeft: '20px',
    marginRight: '20px',
  },
}));

export interface CancelNFTListingModalProps {
  open: boolean;
  isProcessing: boolean;
  wallets: NonCustodyWallet[];
  onCancel: () => void;
  onApprove: (wallet: NonCustodyWallet) => void;
}

export const TransferNftToSellModal = ({
  open,
  isProcessing,
  wallets,
  onCancel,
  onApprove,
}: CancelNFTListingModalProps) => {
  const {t} = useLocalization();
  const styles = useStyles();
  const [selectedWallet, setSelectedWallet] = useState<NonCustodyWallet>();
  const currentWallet = selectedWallet || wallets[0];
  const {appConfig} = useContext(AppContext);

  const handleApprove = useCallback(() => onApprove(currentWallet), [currentWallet, onApprove]);
  const handleWalletChange: NonNullable<TextFieldProps['onChange']> = useCallback(
    (event) => setSelectedWallet(wallets.find((wallet) => wallet.publicKey === event.target.value)),
    [wallets],
  );

  const MODAL_TITLE = t('Transfer NFT to sell');
  const WALLET_SELECT_LABEL = t('Receiving wallet');
  const MODAL_TEXT = t(
    'To sell your NFT it must be transferred from {{appTitle}} wallet to external connected wallet. Please approve the transfer. It may take a few minutes to process the transfer',
    {appTitle: appConfig.appTitle},
  );

  return (
    <NFTModal
      open={open}
      icon={<Icon />}
      title={MODAL_TITLE}
      message={MODAL_TEXT}
      onClose={onCancel}
      classes={{
        title: styles.modlaTitle,
        message: styles.modlaMessage,
      }}
    >
      {wallets.length > 1 && (
        <TextField
          select
          variant="outlined"
          className={styles.walletSelect}
          label={WALLET_SELECT_LABEL}
          value={currentWallet.publicKey}
          onChange={handleWalletChange}
        >
          {wallets.map(({publicKey, type}) => (
            <MenuItem value={publicKey}>{NonCustodyWalletTitles[type]}</MenuItem>
          ))}
        </TextField>
      )}

      <Box className={styles.root}>
        <Box className={styles.controlsContainer}>
          <Box className={styles.buttonContainer}>
            <Button className={styles.button} variant="outlined" onClick={onCancel} disabled={isProcessing}>
              {t('Cancel')}
            </Button>
          </Box>
          <Box className={styles.buttonContainer}>
            <NFTCTAButton text="Approve" type="submit" onClick={handleApprove} loading={isProcessing} />
          </Box>
        </Box>
      </Box>
    </NFTModal>
  );
};
