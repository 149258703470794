import {Avatar, Divider, MenuItem, ListItemSecondaryAction, Box, Button, Collapse} from '@material-ui/core';
import clsx from 'clsx';
import {useQuery} from '@apollo/client';
import {Typography} from '@cere/rxb-template-ui-kit';
import {useHistory} from 'react-router-dom';
import {ReactComponent as HomeIcon} from '../../../assets/menuIcons/homeIcon.svg';
import {ReactComponent as UserAccountIcon} from '../../../assets/menuIcons/profileIcon.svg';
import {ReactComponent as PicturesIcon} from '../../../assets/menuIcons/collectionIcon.svg';
import {ReactComponent as SignOutIcon} from '../../../assets/menuIcons/signOutIcon.svg';
import {ReactComponent as ExhibitsIcon} from '../../../assets/menuIcons/exhibitsIcon.svg';
import {ReactComponent as ShoppingCartIcon} from '../../../assets/menuIcons/cartIcon.svg';
import {ReactComponent as CloseIcon} from '../../../assets/close.svg';
import {ReactComponent as AvatarIcon} from '../../../assets/avatar.svg';
import {ReactComponent as SellNFTsIcon} from '../../../assets/icons/sellNftsLabel.svg';
import {ReactComponent as CreatorsIcon} from '../../../assets/menuIcons/creatorsIcon.svg';
import {useAuth} from '../../hooks/auth.hook';
import {noop} from '../../types/noop';
import {ForwardedRef, forwardRef, useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import {UserContext} from '../../../context/user-context/user-context';
import {ReactComponent as Arrow} from '../../../assets/icons/back-arrow.svg';
import {ReactComponent as HelpIcon} from '../../../assets/menuIcons/faqIcon.svg';
import {ReactComponent as BackIcon} from '../../../assets/icons/back-arrow.svg';
import {UserWallets} from './UserWallets';
import {arrowStyle} from './UserWallets/styles';
import {resetFreeportClients} from '../../lib/get-freeport';
import {resetErc20Clients} from '../../lib/get-erc20';
import {resetSignerAndProvider} from '../../services/get-signer-and-provider';
import {useUserWalletsPubKeys} from 'context/use-user-wallets-pub-keys';
import analyticService, {AnalyticEventsEnum} from 'shared/services/analytic.service';
import {enableModernHomepage} from '../../../config/common';
import {creatorWalletNfts} from './queries';
import {useStyles} from './styles';
import {UserAccount} from './Account';
import {GoogleAnalyticsId} from 'analytics-ids';
import {useAuctionFactory} from '../../hooks/use-auction-factory';
import {AppContext} from '../../../context/app-context';
import {resetCollectionClients} from '../../lib/get-collection';
import {useLocalization} from '../../hooks/use-locale.hook';

type Props = {
  onClose: noop;
  forwardedRef?: ForwardedRef<HTMLLIElement>;
};
const UserProfileMenuListComponent = ({onClose, forwardedRef}: Props) => {
  const {t, locale} = useLocalization();
  const refElement = useRef<HTMLDivElement>();
  const auctionFactory = useAuctionFactory();
  const {appConfig} = useContext(AppContext);

  const [height, setHeight] = useState<number>(0);
  useEffect(() => {
    if (!!refElement.current) {
      setHeight(refElement?.current.scrollHeight);
    }
  }, [refElement?.current?.scrollHeight]);
  const classes = useStyles({height});

  const history = useHistory();
  const {userData, setIsConnectWalletVisible, setIsProfileMenuOpen} = useContext(UserContext);
  const {clearAuthData} = useAuth();
  const [isAccountOpen, setIsAccountOpen] = useState<boolean>(false);
  const userWallets = useUserWalletsPubKeys();
  const {data, startPolling, stopPolling} = useQuery<{wallets: Array<{wallet: string}>}>(creatorWalletNfts, {
    variables: {wallets: userWallets, locale},
  });

  const nfts = data?.wallets ?? [];

  const navigate = (path: string) => {
    if (onClose) {
      onClose();
    }
    setIsProfileMenuOpen(false);
    history.push(path, {pathname: window.location.pathname});
  };

  const onSignInClick = useCallback(() => {
    analyticService.track(AnalyticEventsEnum.SIGN_IN_HEADER_CLICK);
    history.push(`/${locale}/home/auth/signin`, {pathname: window.location.pathname});
  }, [history, locale]);

  const onSignUpClick = useCallback(() => {
    analyticService.track(AnalyticEventsEnum.SIGN_IN_HEADER_CLICK);
    history.push(`/${locale}/home/auth/signup`, {pathname: window.location.pathname});
  }, [history, locale]);

  const onSignedOut = () => {
    resetFreeportClients();
    resetErc20Clients();
    auctionFactory.resetProviders();
    resetSignerAndProvider();
    resetCollectionClients();
  };

  const signOut = () => {
    if (onClose) {
      onClose();
    }
    clearAuthData();
    onSignedOut();
  };

  useEffect(() => {
    startPolling(30_000);
    return () => stopPolling();
  }, [startPolling, stopPolling]);

  const handleConnectWallet = () => {
    analyticService.track(AnalyticEventsEnum.CONNECT_WALLET_CLICKED);
    setIsConnectWalletVisible(true);
  };

  const {pathname} = useMemo(() => new URL(window.location.href), []);

  return (
    <Box {...{ref: refElement}} className={classes.menu}>
      <Box className={clsx(classes.mobileHeader, classes.menuItemRoot)}>
        <Box onClick={onClose} display="flex" alignItems="center">
          <BackIcon />
          <span className={classes.backArrow}>{t('Back')}</span>
        </Box>
        <Box onClick={onClose} className={classes.mobileClose}>
          <CloseIcon className={classes.closeIcon} />
        </Box>
      </Box>

      <MenuItem
        ref={forwardedRef}
        classes={{root: classes.menuItemRoot}}
        className={userData.userPublicKey && classes.userInfoBlock}
      >
        {userData.userPublicKey ? (
          <Avatar className={classes.avatar}>
            <AvatarIcon />
          </Avatar>
        ) : (
          <>
            <Typography className={classes.defaultMenu}>{t('Menu')}</Typography>
            <Box onClick={onClose} className={clsx(classes.close, classes.menuMobile)}>
              <CloseIcon className={classes.closeIcon} />
            </Box>
          </>
        )}

        {userData.userPublicKey && (
          <div>
            <Typography className={classes.userName}>{userData.userEmail || t('Empty email')}</Typography>
            <Box onClick={onClose} className={clsx(classes.close, classes.closeIconContainer, classes.menuMobile)}>
              <CloseIcon className={classes.closeIcon} />
            </Box>
          </div>
        )}
      </MenuItem>

      <Divider variant="middle" component="li" className={!userData.userPublicKey ? classes.guest : classes.divider} />

      {userData.userPublicKey && (
        <>
          <MenuItem
            classes={{root: classes.menuItemRoot}}
            className={classes.menuItem}
            onClick={() => setIsAccountOpen(!isAccountOpen)}
          >
            <Box py="6px">
              <Typography variant="h5" className={clsx(classes.menuItemText, classes.inActiveItem)}>
                <UserAccountIcon />
                {t('My account')}
              </Typography>
              <ListItemSecondaryAction>
                <Arrow className={clsx(classes.arrow, isAccountOpen && classes.arrowOpen)} style={arrowStyle} />
              </ListItemSecondaryAction>
            </Box>
          </MenuItem>
          <Collapse in={isAccountOpen}>
            <UserAccount pathname={pathname} onClose={onClose} navigate={navigate} />
          </Collapse>

          <MenuItem
            classes={{root: classes.menuItemRoot}}
            className={clsx(
              classes.menuItem,
              pathname === `/${locale}/home/user/nfts` ? classes.activeItem : classes.inActiveItem,
            )}
            onClick={navigate.bind(null, `/${locale}/home/user/nfts`)}
          >
            <PicturesIcon />
            {t('My collection')}
            <Box className={classes.comingSoonLabel}>{nfts.length}</Box>
            {enableModernHomepage() && <SellNFTsIcon className={classes.sellNftsLabel} />}
          </MenuItem>

          <Divider variant="middle" component="li" className={classes.divider} />
        </>
      )}

      <Box className={classes.menuDesktop}>
        <MenuItem
          className={clsx(classes.menuItem, pathname === `/${locale}/home` ? classes.activeItem : classes.inActiveItem)}
          onClick={navigate.bind(null, `/${locale}/home`)}
        >
          <HomeIcon />
          {t('Home')}
        </MenuItem>
        {enableModernHomepage() && (
          <>
            <MenuItem
              className={clsx(
                classes.menuItem,
                pathname === `/${locale}/marketplace` ? classes.activeItem : classes.inActiveItem,
              )}
              onClick={navigate.bind(null, `/${locale}/marketplace`)}
            >
              <ShoppingCartIcon />
              {t('Marketplace')}
            </MenuItem>
            <MenuItem
              className={clsx(
                classes.menuItem,
                pathname === `/${locale}/exhibits` ? classes.activeItem : classes.inActiveItem,
              )}
              onClick={navigate.bind(null, `/${locale}/exhibits`)}
            >
              <ExhibitsIcon />
              {t('Exhibits')}
            </MenuItem>
            <MenuItem
              className={clsx(
                classes.menuItem,
                pathname === `/${locale}/creators` ? classes.activeItem : classes.inActiveItem,
              )}
              onClick={navigate.bind(null, `/${locale}/creators`)}
            >
              <CreatorsIcon />
              {t('Creators')}
            </MenuItem>
            <Divider
              variant="middle"
              component="li"
              className={!userData.userPublicKey ? classes.guest : classes.divider}
            />
          </>
        )}
      </Box>
      {userData.userPublicKey && (
        <>
          <Box className={classes.walletsBlock}>
            <Typography variant="h4">{t('Wallets')}</Typography>
            <Box className={clsx(classes.text, GoogleAnalyticsId.ConnectWalletBtn)} onClick={handleConnectWallet}>
              {t('Add wallet')}
            </Box>
          </Box>
          <Box className={classes.userWalletsBlock}>
            <UserWallets onClose={onClose} />
          </Box>
        </>
      )}
      <Divider
        variant="middle"
        component="li"
        className={!userData.userPublicKey ? classes.guestDivider : classes.userDivider}
      />
      {!userData.userPublicKey && (
        <>
          <MenuItem className={clsx(classes.menuItem, classes.signIcon)}>
            <Button variant="contained" color="primary" className={classes.activeSignInButton} onClick={onSignUpClick}>
              {t('Sign up')}
            </Button>
          </MenuItem>
          <MenuItem className={classes.menuItem}>
            <Button variant="outlined" color="primary" className={classes.defaultSignInButton} onClick={onSignInClick}>
              <Typography className={classes.signInText}>{t('Sign in')}</Typography>
            </Button>
          </MenuItem>
          <Divider variant="middle" component="li" className={classes.guestDivider} />
        </>
      )}

      <a className={classes.link} href={appConfig.faqUrl}>
        <MenuItem classes={{root: classes.menuItemRoot}} className={classes.menuItem}>
          <HelpIcon />
          {t('Help & Feedback')}
        </MenuItem>
      </a>

      {userData.userPublicKey && (
        <>
          <Divider variant="middle" component="li" className={classes.guestDivider} />
          <MenuItem
            classes={{root: classes.menuItemRoot}}
            className={clsx(classes.menuItem, classes.signIcon)}
            onClick={signOut}
          >
            <SignOutIcon />
            {t('Sign out')}
          </MenuItem>
        </>
      )}
    </Box>
  );
};

export default forwardRef((props: Omit<Props, 'forwardedRef'>, ref: ForwardedRef<HTMLLIElement>) => (
  <UserProfileMenuListComponent {...props} forwardedRef={ref} />
));
