import React from 'react';
import {Dialog} from '@cere/rxb-template-ui-kit';
import {ReactComponent as CloseIcon} from '../assets/close.svg';

type ProviderContext = readonly [(option: DialogOption) => void, () => void];

const EMPTY_FUNC = () => {};
const DialogContext = React.createContext<ProviderContext>([EMPTY_FUNC, EMPTY_FUNC]);
export const useDialog = () => React.useContext(DialogContext);

type DialogParams = {
  title: string;
  children: React.ReactNode;
  open: boolean;
  onClose?: Function;
  onExited?: Function;
};
type DialogOption = Omit<DialogParams, 'open'>;
type DialogContainerProps = DialogParams & {
  onClose: () => void;
  onKill: () => void;
};

function DialogContainer(props: DialogContainerProps) {
  const {title, children, open, onClose, onKill} = props;

  return (
    <Dialog open={open} onClose={onClose} onExited={onKill} closeIcon={<CloseIcon />} title={title}>
      {children}
    </Dialog>
  );
}

// @ts-ignore
export default function DialogProvider({children}) {
  const [dialogs, setDialogs] = React.useState<DialogParams[]>([]);
  const createDialog = (option: DialogOption) => {
    const dialog = {...option, open: true};
    setDialogs((dialogs) => [...dialogs, dialog]);
  };
  const closeDialog = () => {
    setDialogs((dialogs) => {
      const latestDialog = dialogs.pop();
      if (!latestDialog) return dialogs;
      if (latestDialog.onClose) latestDialog.onClose();
      return [...dialogs].concat({...latestDialog, open: false});
    });
  };
  const contextValue = React.useRef([createDialog, closeDialog] as const);

  return (
    <DialogContext.Provider value={contextValue.current}>
      {children}
      {dialogs.map((dialog, i) => {
        const {onClose, ...dialogParams} = dialog;
        const handleKill = () => {
          if (dialog.onExited) dialog.onExited();
          setDialogs((dialogs) => dialogs.slice(0, dialogs.length - 1));
        };

        return <DialogContainer key={i} onClose={closeDialog} onKill={handleKill} {...dialogParams} />;
      })}
    </DialogContext.Provider>
  );
}
