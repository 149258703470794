import {BaseAction} from '../../base/BaseAction';
import {PurchaseFromMinterModal} from '../../../components/connected/PurchaseModals/PurchaseFromMinterModal';
import {PurchaseSuccessfulModal} from '../../../components/connected/PurchaseModals/PurchaseSuccessfulModal';
import {PurchaseFailedModal} from '../../../components/connected/PurchaseModals/PurchaseFailedModal';
import {PurchaseOnMarketplaceModal} from '../../../components/connected/PurchaseModals/PurchaseOnMarketplaceModal';

const NFT = '[NFT]';

export class StartPrimaryNFTPurchaseCommand {
  static type = `${NFT} Start NFT purchase from minter`;
  static create(nftId: string, qty: number = 1) {
    return {
      type: this.type,
      payload: {
        nftId,
        qty,
      },
    };
  }
}

export class StartSecondaryNFTPurchaseCommand {
  static type = `${NFT} Start NFT purchase on marketplace`;
  static create(nftId: string, sellerWalletAddress: string, price: number, qty: number = 1) {
    return {
      type: this.type,
      payload: {
        nftId,
        sellerWalletAddress,
        price,
        qty,
      },
    };
  }
}

export class PurchaseNFTForCryptoCommand {
  static type = `${NFT} Purchase NFT for crypto`;
  static create(nftId: string, sellerWalletAddress: string, price: number, qty: number = 1) {
    return {
      type: this.type,
      payload: {
        nftId,
        sellerWalletAddress,
        price,
        qty,
      },
    };
  }
}

export class PurchaseNFTForFiatCommand {
  static type = `${NFT} Purchase NFT for fiat`;
  static create(nftId: string, qty: number = 1) {
    return {
      type: this.type,
      payload: {
        nftId,
        qty,
      },
    };
  }
}

export class NFTPurchaseStartedEvent extends BaseAction {
  static type = `${NFT} Purchase started`;
}

export class PurchaseNFTSuccessEvent {
  static type = `${NFT} Purchase success`;
  static create(id: string) {
    return {
      type: this.type,
      payload: id,
    };
  }
}

export class PurchaseNFTFailedEvent {
  static type = `${NFT} Purchase failed`;
  static create(nftId: string, message: string) {
    return {
      type: this.type,
      payload: {nftId, message},
    };
  }
}

// ToDo: Update the actions below after purchase modals are migrated
export class ShowStartPurchaseFromMinterModalCommand {
  static type = `[MODAL] Show start purchase from minter modal`;
  static create(nftId: string) {
    return {
      type: this.type,
      payload: {nftId},
      component: PurchaseFromMinterModal,
    };
  }
}

export class ShowStartPurchaseOnMarketplaceModalCommand {
  static type = `[MODAL] Show start purchase on marketplace modal`;
  static create(nftId: string, sellerWalletAddress: string, price: number, qty: number) {
    return {
      type: this.type,
      component: PurchaseOnMarketplaceModal,
      payload: {
        nftId,
        sellerWalletAddress,
        price,
        qty,
      },
    };
  }
}

export class ShowContinuePurchaseModalCommand {
  static type = `[MODAL] Show continue purchase modal`;
  static create(nftId: string, sellerWalletAddress: string, price: number, qty: number) {
    return {
      type: this.type,
      payload: {
        nftId,
        sellerWalletAddress,
        price,
        qty,
      },
    };
  }
}

export class ShowPurchaseSuccessModalCommand {
  static type = `[MODAL] Show purchase success modal`;
  static create(nftId: string, price: number, qty: number) {
    return {
      type: this.type,
      payload: {nftId, price, qty},
      component: PurchaseSuccessfulModal,
    };
  }
}

export class ShowPurchaseFailedModalCommand {
  static type = `[MODAL] Show purchase failed modal`;
  static create(nftId: string, sellerWalletAddress: string, price: number, qty: number) {
    return {
      type: this.type,
      payload: {nftId, sellerWalletAddress, price, qty},
      component: PurchaseFailedModal,
    };
  }
}
