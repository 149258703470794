import {FC, useRef, useState, useEffect, useCallback, useMemo, useContext} from 'react';
import {Box, makeStyles} from '@material-ui/core';
import {Typography} from '@cere/rxb-template-ui-kit';
import colors from '../../../styles/colors';
import {Markdown} from '../Markdown';
import {Condition, ConditionsList, Defaults} from '../Conditions';
import clsx from 'clsx';
import {NftContext} from '../Nft/nft-context';
import {useLocalization} from '../../hooks/use-locale.hook';

const useStyles = makeStyles((theme) => ({
  descriptionBox: {
    width: '100%',
  },
  description: {
    color: colors.lightGrey,
  },
  collapsedDescription: {
    maxHeight: '20px',
    marginBottom: '1rem',
  },
  invisibleDescription: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',

    '& > div': {
      width: '88%',
      boxSizing: 'border-box',
      paddingRight: 'calc(1rem + 1ch)',
      position: 'relative',
    },
  },
  visibleDescription: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '1rem',
  },
  coreDescription: {
    display: 'flex',
    flexGrow: 1,
  },
  moreButton: {
    color: theme.palette.primary.main,
    margin: '.3rem 0',
    width: '12%',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
  },
}));

export const NftCardBigDescription: FC<{description?: string}> = ({description}) => {
  const classes = useStyles();
  const {eventNft} = useContext(NftContext);
  const {t} = useLocalization();

  const [isDescriptionExpanded, setIsDescriptionExpanded] = useState<boolean>(false);
  const [isDescriptionBig, setIsDescriptionBig] = useState<boolean>(true);
  const refElement = useRef<HTMLDivElement>(null);

  const isMultiline = useMemo(
    () =>
      Boolean(
        description &&
          (description
            ?.split('\n')
            .map((line) => line.trim())
            .filter(Boolean).length > 1 ||
            description?.length > 40),
      ),
    [description],
  );

  const textDescription = useMemo(() => {
    return isDescriptionExpanded
      ? description
      : description
          ?.split('\n')
          .map((line) => line.trim())
          .filter(Boolean)
          .slice(0, 1)
          .join('');
  }, [description, isDescriptionExpanded]);

  useEffect(
    () =>
      setIsDescriptionBig(() => {
        if (!refElement.current) {
          return false;
        }
        return (
          textDescription !== description ||
          refElement.current?.scrollHeight > refElement.current.clientHeight ||
          refElement.current?.scrollWidth > refElement.current.clientWidth ||
          isMultiline
        );
      }),
    [description, textDescription, isMultiline],
  );

  const toggleDescription = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();
      setIsDescriptionExpanded(!isDescriptionExpanded);
    },
    [isDescriptionExpanded],
  );

  if (eventNft?.cmsNft?.isComingSoon) {
    return <></>;
  }

  if (!description) {
    return <Box pt="10px" width="100%" className={classes.coreDescription} />;
  }

  return (
    <Box
      pt="10px"
      width="100%"
      className={clsx(
        classes.coreDescription,
        !isDescriptionExpanded ? classes.invisibleDescription : classes.visibleDescription,
      )}
    >
      <Typography component="div" variant="body2" className={classes.descriptionBox}>
        <Box className={classes.description}>
          <ConditionsList>
            <Condition condition={isMultiline && !isDescriptionExpanded}>
              <div ref={refElement} className={classes.collapsedDescription}>
                <Markdown collapsed={!isDescriptionExpanded && isDescriptionBig}>{textDescription ?? ''}</Markdown>
              </div>
            </Condition>
            <Defaults>
              <Markdown>{textDescription ?? ''}</Markdown>
            </Defaults>
          </ConditionsList>
        </Box>
      </Typography>
      {(isDescriptionBig || isDescriptionExpanded) && (
        <Typography variant="body2" className={classes.moreButton} onClick={toggleDescription}>
          {isDescriptionExpanded ? t('less') : t('more')}
        </Typography>
      )}
    </Box>
  );
};
