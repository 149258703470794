import {FC, useContext} from 'react';
import clsx from 'clsx';
import {Typography} from '@cere/rxb-template-ui-kit';
import {makeStyles, MenuItem, Select} from '@material-ui/core';
import {CmsExhibitionNft} from '../../types/graphql';
import colors from '../../../styles/colors';
import {formatNumber} from '../../lib/formatNumber';
import {ReactComponent as BackIcon} from '../../../assets/icons/back-arrow.svg';
import {getNftAvailabilityByCmsNft, isNftAvailabilityComing, isNftAvailabilityPassed} from '../../services/nft.service';
import {getNFTTotalOfferedQty} from '../../helpers/nfts';
import {NftContext} from '../Nft/nft-context';

interface NftTicketCardSelectProps {
  nfts: CmsExhibitionNft[];
  onSelect: Function;
}

const useStyles = makeStyles(() => ({
  grey: {
    color: colors.lightGrey,
  },
  pr: {
    paddingRight: '16px',
  },
  unavaliable: {
    textDecoration: 'line-through',
  },
  option: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  wrapper: {
    width: '100%',
    borderRadius: '12px',
  },
  icon: {
    transform: 'rotate(-90deg)',
    width: '10px',
    marginRight: '14px',

    '& path': {
      fill: colors.lightGrey,
    },
  },
}));

const useStylesSelect = makeStyles(() => ({
  root: {
    borderColor: colors.lighter,
    padding: '10px 14px',
  },
  select: {
    '&:focus': {
      backgroundColor: colors.light,
    },
  },
}));

export const NftTicketCardSelect: FC<NftTicketCardSelectProps> = ({nfts, onSelect}) => {
  const classes = useStyles();
  const classesSelect = useStylesSelect();
  const {eventNft} = useContext(NftContext);

  return (
    <Select
      value={eventNft?.cmsNft.id}
      onChange={(e) => onSelect(nfts.find((n) => n.cmsNft.id === e.target.value))}
      variant="outlined"
      renderValue={() => <Typography variant="body2">{eventNft?.cmsNft.title}</Typography>}
      classes={classesSelect}
      className={classes.wrapper}
      IconComponent={() => <BackIcon className={classes.icon} />}
    >
      {nfts.map((nft) => {
        const nftAvailability = getNftAvailabilityByCmsNft(nft).availability;
        const isComingSoon = isNftAvailabilityComing(nftAvailability);

        const isDisabled = isNftAvailabilityPassed(nftAvailability, getNFTTotalOfferedQty(nft.cmsNft)) || isComingSoon;

        return (
          <MenuItem value={nft.cmsNft.id} key={nft.cmsNft.id} disabled={isDisabled} className={classes.option}>
            <Typography variant="body2" className={clsx(classes.pr, isDisabled && classes.unavaliable)}>
              {nft.cmsNft.title}
            </Typography>

            {!isComingSoon &&
              nft?.cmsNft?.creatorNft?.supply !== undefined &&
              getNFTTotalOfferedQty(nft.cmsNft) !== undefined && (
                <Typography variant="caption1" className={classes.grey}>
                  <Typography variant="h4" component="span">
                    {formatNumber(getNFTTotalOfferedQty(nft.cmsNft))}
                  </Typography>
                  /{formatNumber(nft?.cmsNft?.creatorNft?.supply)}
                </Typography>
              )}
          </MenuItem>
        );
      })}
    </Select>
  );
};
