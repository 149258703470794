import {
  CmsExhibitionNft,
  CmsExhibitionNftAccess,
  CmsExhibitionNftAuctioned,
  CmsExhibitionNftExclusive,
  CmsExhibitionNftLimited,
  CmsExhibitionNftRelType,
  CmsNft,
  CreatorAuctionBid,
  CreatorAuctionBidWithStatus,
} from '../types/graphql';
import {NftCardInterface, NftType} from '@cere/services-types';
import {formatNumber} from '../lib/formatNumber';
import abbreviate from '@pqt/abbreviate';
import {compareKeys} from '../lib/compare-keys';
import {NonCustodyWallet} from '../types/non-custody-wallet';
import {RequiredSome} from '../types/required-some';
import {DeepPartial} from '../types/deep-partial';
import {getNFTTotalOfferedQty} from './nfts';
import {Optional} from '../types/optional';

type CmsExhibitionNftWeak = RequiredSome<DeepPartial<CmsExhibitionNft>, 'cmsNft'>;

export const isAuctionedNft = (nft: Optional<CmsExhibitionNft, 'relType'>): nft is CmsExhibitionNftAuctioned => {
  return nft.relType === CmsExhibitionNftRelType.AUCTIONED;
};

export const isLimitedNft = (nft: CmsExhibitionNft): nft is CmsExhibitionNftLimited => {
  return nft.relType === CmsExhibitionNftRelType.LIMITED;
};

export const isExclusiveNft = (nft: CmsExhibitionNft): nft is CmsExhibitionNftExclusive => {
  return isAuctionedNft(nft) || isLimitedNft(nft);
};

export const isAccessNft = (nft: CmsExhibitionNftWeak): nft is CmsExhibitionNftAccess => {
  return nft.relType === CmsExhibitionNftRelType.ACCESS;
};

export const isAccessNftType = (nft?: NftCardInterface): boolean => {
  return nft?.nftType === NftType.ACCESS;
};

export const isLimitedNftType = (nft?: NftCardInterface): boolean => {
  return nft?.nftType === NftType.AUCTIONED;
};

export const isAuctionedNftType = (nft?: NftCardInterface): boolean => {
  return nft?.nftType === NftType.LIMITED;
};

export const isExclusiveNftType = (nft?: NftCardInterface): boolean => {
  return isLimitedNftType(nft) || isAuctionedNftType(nft);
};

export const getNftCountLabel = (nft: CmsNft, isAuction: boolean): string =>
  isAuction
    ? '1 Auctioned original'
    : `${formatNumber(getNFTTotalOfferedQty(nft))}/${nft?.creatorNft?.supply || 0} left`;

export const getBidTokensValue = (amount: number) => {
  if (amount < 1_000_000) {
    return amount;
  }

  return abbreviate(amount, 1).replace('.', ',');
};

export const getIsUserBid = (
  bid: Pick<CreatorAuctionBid, 'buyer'>,
  userPublicKey: string,
  externalWallets: NonCustodyWallet[],
) => {
  return (
    compareKeys(userPublicKey || '', bid.buyer) ||
    !!externalWallets.find((wallet) => compareKeys(wallet.publicKey, bid.buyer))
  );
};

export const sortBids = (bids: CreatorAuctionBidWithStatus[]) => {
  const tempBidsHistory = [...bids];
  return tempBidsHistory.sort((prev, next) => next.price - prev.price);
};
