import {SupportedWalletTypes} from './types';

const LAST_ACTIVE_WALLET_TYPE = 'last_active_wallet_type';
const CONNECTED_WALLETS = 'connected_wallets';

export class WalletConnectionStorage {
  setLastActiveWallet(type: SupportedWalletTypes): void {
    window.localStorage.setItem(LAST_ACTIVE_WALLET_TYPE, type);
  }

  setConnectedWalletsTypes(types: SupportedWalletTypes[]) {
    window.localStorage.setItem(CONNECTED_WALLETS, types.join(','));
  }

  getLastActiveWalletType(): SupportedWalletTypes | null {
    const lastConnectedWalletType = window.localStorage.getItem(LAST_ACTIVE_WALLET_TYPE);
    if (!lastConnectedWalletType) {
      return null;
    }
    return lastConnectedWalletType as SupportedWalletTypes;
  }

  getConnectedWalletsTypes(): SupportedWalletTypes[] {
    const typesString = window.localStorage.getItem(CONNECTED_WALLETS);
    if (!typesString) {
      return [];
    }
    return typesString.split(',') as SupportedWalletTypes[];
  }

  clearAll(): void {
    window.localStorage.removeItem(LAST_ACTIVE_WALLET_TYPE);
    window.localStorage.removeItem(CONNECTED_WALLETS);
  }
}
