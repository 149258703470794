import 'swiper/swiper-bundle.css';

import {useCallback} from 'react';
import analyticService, {AnalyticEventsEnum} from '../../shared/services/analytic.service';
import {GoogleAnalyticsId} from '../../analytics-ids';
import {CuratedRow} from '../../shared/components/CuratedRow';
import {Numberish} from '../../shared/types/numberish';
import {NftProvider2} from '../../shared/components/Nft/nft-provider-v2';
import {NftCardV3} from './NftCard/nft-card-v3';
import {NftCardInterface} from '@cere/services-types';
import {useLocalization} from '../../shared/hooks/use-locale.hook';

interface HomePageCuratedRowProps {
  featuredNfts: NftCardInterface[] | null;
}

export const HomePageCuratedRow = ({featuredNfts}: HomePageCuratedRowProps) => {
  const trackNftClick = useCallback(
    (nftId: Numberish) => analyticService.track(AnalyticEventsEnum.FEATURED_NFT_LISTING_CLICKED, {nftId}),
    [],
  );
  const {t} = useLocalization();

  return (
    <CuratedRow title={t('Featured listings')}>
      {(featuredNfts || [])?.map((nft: NftCardInterface) => {
        return (
          <NftProvider2 key={nft.id} nft={nft}>
            <NftCardV3
              nftId={nft.id}
              imageUrl={nft.image}
              nftType={nft.nftType}
              availableFrom={nft.availableFrom?.toString()}
              availableTo={nft.availableTo?.toString()}
              availability={nft.availability}
              nftAddress={nft.address}
              nftTitle={nft.title}
              nftDescription={nft.description}
              balance={nft.balance}
              supply={nft.supply}
              usdPrice={nft.priceUsd}
              onClick={trackNftClick}
              buttonClassName={GoogleAnalyticsId.BuyFeaturedBtn}
              isFeaturedListingsCard
              isCutTitle
            />
          </NftProvider2>
        );
      })}
    </CuratedRow>
  );
};
