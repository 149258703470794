import {Box, Divider, makeStyles, Theme, Typography} from '@material-ui/core';
import clsx from 'clsx';
import {Markdown} from '../Markdown';
import {Numberish} from 'shared/types/numberish';
import colors from 'styles/colors';
import {useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import {useLocalization} from '../../hooks/use-locale.hook';
import {NftContext} from '../Nft/nft-context-v2';
import {Avatar} from '../Avatar';
import {link, StyledLink} from '../StyledLink';
import {GoogleAnalyticsId} from 'analytics-ids';
import {SocialNetworksInfo} from '../SocialNetworksInfo';

export interface NFTDescriptionsProps {
  cmsNftIncrementId: Numberish;
  isNftWithAsset?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  block: {
    width: '100%',
    padding: '32px 24px',

    [theme.breakpoints.up('md')]: {
      marginTop: (isNftWithAsset) => (isNftWithAsset ? '42px' : '0'),
    },
    [theme.breakpoints.up('lg')]: {
      marginTop: (isNftWithAsset) => (isNftWithAsset ? '56px' : '0'),
    },
  },
  cardTitle: {
    fontWeight: 700,
    fontSize: '20px',
    lineHeight: '26px',
    marginBottom: '12px',
    color: colors.primaryDark,
  },
  creatorTitle: {
    [theme.breakpoints.up('md')]: {
      marginTop: '16px',
    },
  },
  divider: {
    margin: '24px 0',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  link: {
    color: '#3CBCAC',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',
  },
  collapsibleWrapper: {
    '&:first-child': {
      padding: '0 !important',
    },
    '&:last-child': {
      padding: '0 !important',
    },
  },
  avatar: {
    borderRadius: '100px',
    height: '91px',
    width: '91px',

    [theme.breakpoints.down('md')]: {
      height: '73px',
      width: '73px',
    },
  },
  artistInfo: {
    marginTop: '25px',
    [theme.breakpoints.down('md')]: {
      marginTop: '28px',
    },
  },
  description: {
    display: 'flex',
  },
  info: {
    marginLeft: '32px',
    [theme.breakpoints.down('md')]: {
      marginLeft: '28px',
    },
  },
  artistAbout: {
    [theme.breakpoints.up('md')]: {
      marginTop: '24px',
    },
  },
  moreButton: {
    color: theme.palette.primary.main,
    margin: '.3rem 0',
    width: '12%',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
  },
}));

export const FullNftDescriptions = ({isNftWithAsset}: NFTDescriptionsProps) => {
  const {t, locale} = useLocalization();
  const {nft, creator} = useContext(NftContext);

  const styles = useStyles(isNftWithAsset);
  const [isDescriptionExpanded, setIsDescriptionExpanded] = useState<boolean>(false);
  const [isDescriptionBig, setIsDescriptionBig] = useState<boolean>(true);
  const isMultiline = useMemo(
    () =>
      Boolean(
        nft?.description &&
          (nft.description
            ?.split('\n')
            .map((line) => line.trim())
            .filter(Boolean).length > 1 ||
            nft.description?.length > 40),
      ),
    [nft],
  );
  useEffect(
    () =>
      setIsDescriptionBig(() => {
        if (!refElement.current) {
          return false;
        }
        return (
          refElement.current?.scrollHeight > refElement.current.clientHeight ||
          refElement.current?.scrollWidth > refElement.current.clientWidth ||
          isMultiline
        );
      }),
    [nft, isMultiline],
  );

  const toggleDescription = useCallback(() => {
    setIsDescriptionExpanded(!isDescriptionExpanded);
  }, [isDescriptionExpanded]);

  const refElement = useRef<HTMLDivElement>();

  if (!nft) {
    return null;
  }

  return (
    <Box className={styles.block}>
      <Typography className={styles.cardTitle}>{t('Description')}</Typography>
      {nft && <Markdown isShort={!isDescriptionBig || !isDescriptionExpanded}>{nft.description}</Markdown>}
      <Divider className={styles.divider} />

      {creator && (
        <>
          <Typography className={clsx(styles.cardTitle, styles.creatorTitle)}>{t('Creator')}</Typography>
          {creator && (
            <Box {...{ref: refElement}}>
              <Markdown isShort={!isDescriptionBig || !isDescriptionExpanded}>{creator.about}</Markdown>
            </Box>
          )}
          {isDescriptionBig && (
            <Box onClick={toggleDescription}>
              <Typography className={styles.moreButton}>{isDescriptionExpanded ? t('less') : t('more')}</Typography>
            </Box>
          )}

          <Box className={styles.artistAbout}>
            <StyledLink
              to={`/${locale}/home/creator/${creator?.id}`}
              className={clsx(link, GoogleAnalyticsId.ViewCreatorBtn)}
            >
              <Box display="flex" alignItems="center">
                <Avatar name={creator.name} imageUrl={creator.avatar?.url} creatorId={creator.id} />
              </Box>
            </StyledLink>
            <SocialNetworksInfo artist={creator} isShort={false} iconSize="15px" noTitle />
          </Box>
        </>
      )}
    </Box>
  );
};
