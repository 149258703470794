import {makeStyles, Typography} from '@material-ui/core';
import {ReactComponent as LinkIcon} from '../../../assets/icons/linkIcon.svg';
import colors from '../../../styles/colors';
import {getMaticNetworkUrl, getSmartContractAddress} from '../NftDetailsInfo/helpers';
import {t} from 'i18next';

export const useStyles = makeStyles(() => ({
  linkText: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px',
    color: colors.accent,
    marginRight: '12px',
    fontFamily: 'inherit',
    letterSpacing: 'inherit',
  },
  linkBlock: {
    textDecoration: 'none',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

export const ExplorerLink = () => {
  const styles = useStyles();
  const contractAddress = getSmartContractAddress();
  const networkUrl = getMaticNetworkUrl();
  let link = `${networkUrl}/address/${contractAddress}`;

  return (
    <a className={styles.linkBlock} href={link}>
      <Typography className={styles.linkText}>{t('View transaction details in Explorer')}</Typography>
      <LinkIcon />
    </a>
  );
};
