import {Box, Grid, makeStyles} from '@material-ui/core';

import {Skeleton} from '../../primitives/Skeleton';
import {useThemeBreakpoints} from '../../../styles/useThemeBreakpoints';

const useStyles = makeStyles(() => ({
  imageBox: {
    position: 'relative',
    width: '100%',
    paddingBottom: '100%',
    overflow: 'hidden',
  },
  image: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    borderRadius: '12px',
  },
}));

export const ExhibitsRowSkeleton = () => {
  const {isMobile, isTablet} = useThemeBreakpoints();
  const sceletonsCount = isMobile ? 1 : isTablet ? 2 : 3;

  const styles = useStyles();

  return (
    <Grid container spacing={2}>
      {Array(sceletonsCount)
        .fill(0)
        .map((_, index) => (
          <Grid key={`key=${index}`} item xs={12} md={6} lg={4}>
            <Box className={styles.imageBox}>
              <Skeleton className={styles.image} variant="rect" key={index} />
            </Box>
          </Grid>
        ))}
    </Grid>
  );
};
