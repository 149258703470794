import {createSelector} from 'reselect';
// import {NftCardInterface} from '@cere/services-types';
import {NFTS_MODULE_NAME} from './constants';
import {NoSuchModuleError} from '../../base/NoSuchModuleError';
import {NftsStore} from './types';

export const selectNfts = (store: NftsStore) => {
  if (!store[NFTS_MODULE_NAME]) {
    throw new NoSuchModuleError(NFTS_MODULE_NAME);
  }
  return store[NFTS_MODULE_NAME].nfts;
};

export const selectNftById = (store: NftsStore, id: string) => selectNfts(store)[id];

export const createSelectNftById = () => createSelector([selectNfts, (_, id: string) => id], (nfts, id) => nfts[id]);
