import {useCallback, useMemo, useRef, useState} from 'react';
import {Field, Form} from 'react-final-form';
import ErrorIcon from '@material-ui/icons/Error';
import {Box, Button, Divider, makeStyles, Paper} from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import clsx from 'clsx';
import colors from '../../../styles/colors';
import {CopyButton} from '../CopyButton';
import {cereToUSD, formatBidValue, TOKEN_DECIMALS, TOKEN_TITLE} from '../../lib/formatPrice';
import {NFTCTAButton} from '../NFTCTAButton';
import {useConnectNonCustodyWallet} from '../../hooks/use-connect-wallet';
import {useSelectedWallet} from '../../../context/use-selected-wallet';
import {AppWallet} from '../../types/supported-wallet';
import {NonCustodyWalletTitles, NonCustodyWalletTypeEnum} from '../../types/non-custody-wallet';
import {useSingletonFetchUserWalletBalance} from '../../hooks/user-balance.hook';
import {WalletBalance} from '../WalletBalance';
import {TorusWalletFundButton} from '../TorusWalletFundButton';
import {ReactComponent as BidWalletIcon} from '../../../assets/icons/currencies.svg';
import {useExchangeRate} from '../../hooks/use-exchange-rate';
import {AuctionTextField, TextVariant} from '../auction/Wallet/AuctionTextField';
import {Typography} from '../UI';
import analyticService, {AnalyticEventsEnum} from '../../services/analytic.service';
import NftModalHighlightCard from '../NftModalHighlightCard';
import {Trans} from 'react-i18next';
import {PurchaseModal} from '../PurchaseModal';
import {useLocalization} from '../../hooks/use-locale.hook';
import {mobileLandscapeMediaQuery} from '../../../styles/mediaQueries';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: '20px',

    [mobileLandscapeMediaQuery(theme)]: {
      display: 'flex',
      gap: '20px',
    },
  },

  dataBox: {
    [mobileLandscapeMediaQuery(theme)]: {
      width: '50%',
    },
  },

  button: {
    width: '208px',
    height: '36px',
    borderRadius: '50px',
    background: theme.palette.primary.main,
    '&:hover': {
      background: theme.palette.primary.main,
    },
    [theme.breakpoints.up('lg')]: {
      height: '44px',
    },
  },
  fundButton: {
    width: '208px',
    height: '36px',
    borderRadius: '50px',
    background: theme.palette.secondary.main,
    marginTop: '16px',

    [mobileLandscapeMediaQuery(theme)]: {
      order: 1,
      marginTop: 0,
      marginLeft: '16px',
    },
    [theme.breakpoints.up('lg')]: {
      height: '44px',
    },
  },
  label: {
    root: {
      color: colors.lightGrey,
    },
    focused: {
      color: colors.lightGrey,
    },
  },
  minPrice: {
    fontWeight: 600,
    color: colors.lightGrey,
    paddingLeft: '3px',
  },
  icon: {
    '--size': '18px',
    height: 'var(--size)',
    width: 'var(--size)',
    color: theme.palette.primary.main,
  },
  dataLabelWrapper: {
    display: 'flex',
    fontSize: '14px',
    marginBottom: '4px',
    color: colors.grey,
  },
  dataLabel: {
    color: colors.grey,
    fontWeight: 600,
    lineHeight: '22px',
  },
  dataSubLabel: {
    fontWeight: 'normal',
    marginLeft: '1ch',
  },
  dataText: {
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '22px',
    color: colors.primaryDark,
    marginBottom: '4px',
    maxWidth: '325px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  quantity: {
    width: '196px',
    margin: '0',
    padding: '0',
    '& .MuiOutlinedInput-root': {
      padding: '0 0 0 10px',
      margin: '0',
      border: `1px solid ${colors.lighter}`,
      borderRadius: `12px`,
      backgroundColor: colors.light,
    },
  },
  dataContainer: {
    marginBottom: '8px',
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  copyButtonContainer: {
    marginBottom: '16px',
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& button, & button svg': {
      width: '20px',
      height: '20px',
    },
  },
  dataLine: {
    marginBottom: '16px',
  },
  netSales: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    '& p': {
      margin: 0,
    },
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  mediaDescriptionContainer: {
    maxWidth: 'calc(100% - var(--media-size))',
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [mobileLandscapeMediaQuery(theme)]: {
      flexDirection: 'row',
      justifyContent: 'flex-end',
    },
  },
  connectWalletContainer: {
    margin: '20px 0 16px 0',
    [theme.breakpoints.up('md')]: {
      margin: '10px 0 20px 0',
    },
    [theme.breakpoints.up('lg')]: {
      margin: '24px 0 16px 0',
    },
  },
  connectWalletButton: {
    padding: 0,
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '20px',
    textTransform: 'none',
    color: theme.palette.primary.main,
  },
  errorMessage: {
    display: 'flex',
    width: '100%',
    padding: '12px',
    marginBottom: '16px',
    background: 'rgba(255, 81, 81, 0.1)',
    borderRadius: '12px',
    color: colors.error,
  },
  warningMessage: {
    display: 'flex',
    width: '100%',
    padding: '12px',
    marginBottom: '16px',
    background: colors.warningYellow,
    borderRadius: '12px',
    color: colors.darkYellow,
  },
  errorText: {
    fontWeight: 500,
    fontSize: '13px',
    lineHeight: '22px',
    color: colors.error,
    marginLeft: '10px',
  },
  warningText: {
    fontWeight: 500,
    fontSize: '13px',
    lineHeight: '22px',
    color: colors.darkYellow,
    marginLeft: '10px',

    '& strong': {
      color: colors.primaryDark,
      fontWeight: 500,
    },
  },
  wallet: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
}));

export interface BidNFTAuctionModalProps {
  nftId: string;
  author: string;
  nftTitle: string;
  nftAddress: string;
  nftImage?: string;
  bidsQty: number;
  nextMinBid: number;
  latestBidPrice: number;
  isProcessing: boolean;
  open: boolean;
  onClose: () => void;
  validateBidPrice: (price: number) => void;
  makeBid: (price: number) => Promise<void>;
  initAuctionPrice: number;
}

enum AMOUNT_FIELD_ERRORS {
  INSUFFICIENT = 'INSUFFICIENT',
  LOWERMINIMAL = 'LOWERMINIMAL',
}

const AMOUNT_FIELD_NAME = 'bidAmount';

export const BidNFTAuctionModal = ({
  nftId,
  nftTitle,
  nftAddress,
  nftImage,
  author,
  isProcessing,
  bidsQty,
  nextMinBid,
  latestBidPrice,
  open,
  onClose,
  makeBid,
  validateBidPrice,
  initAuctionPrice,
}: BidNFTAuctionModalProps) => {
  const {t} = useLocalization();
  const styles = useStyles();
  const nftIdRef = useRef<HTMLElement | null>(null);
  const selectedWallet = useSelectedWallet();
  const cereUnitsPerPenny = useExchangeRate();

  const nextMinUSDBid = useMemo(() => cereToUSD(nextMinBid, cereUnitsPerPenny), [cereUnitsPerPenny, nextMinBid]);
  const latestBidUSDPrice = useMemo(
    () => cereToUSD(latestBidPrice, cereUnitsPerPenny),
    [cereUnitsPerPenny, latestBidPrice],
  );

  const [validationType, setValidationType] = useState<TextVariant>('');
  const {showConnectWalletModal} = useConnectNonCustodyWallet();
  const {isLoading, walletBalance} = useSingletonFetchUserWalletBalance();

  const isNonCustodyWalletConnected = selectedWallet.wallet !== AppWallet.DAVINCI;
  const isEnoughFunds = walletBalance?.usdc ? walletBalance.usdc >= latestBidUSDPrice : false;
  const isButtonDisabled = isLoading || !isEnoughFunds;

  const validateBid = useCallback(
    (values) => {
      const bidAmount = Number(formatBidValue(values[AMOUNT_FIELD_NAME]));

      if (!isEnoughFunds || bidAmount > walletBalance.usdc) {
        setValidationType('error');
        return {[AMOUNT_FIELD_NAME]: AMOUNT_FIELD_ERRORS.INSUFFICIENT};
      }

      try {
        validateBidPrice(bidAmount * TOKEN_DECIMALS);
      } catch (_) {
        setValidationType('warning');
        return {[AMOUNT_FIELD_NAME]: AMOUNT_FIELD_ERRORS.LOWERMINIMAL};
      }
      setValidationType('');
      return {};
    },
    [isEnoughFunds, validateBidPrice, walletBalance.usdc],
  );

  const parseError = (error: AMOUNT_FIELD_ERRORS): string | undefined => {
    switch (error) {
      case AMOUNT_FIELD_ERRORS.LOWERMINIMAL:
        // eslint-disable-next-line no-template-curly-in-string
        return t('Bid should be minimal [${{price}}]', {price: nextMinUSDBid});
      case AMOUNT_FIELD_ERRORS.INSUFFICIENT:
        return t('Insufficient balance');
      default:
        return undefined;
    }
  };

  const handleClick = useCallback(
    (values) => {
      analyticService.track(AnalyticEventsEnum.WALLET_PLACE_BID_CLICKED);

      const bidAmount = Number(formatBidValue(values[AMOUNT_FIELD_NAME]));
      void makeBid(Math.round(bidAmount * TOKEN_DECIMALS));
    },
    [makeBid],
  );

  const initialValues = useMemo(
    () => ({
      [AMOUNT_FIELD_NAME]: initAuctionPrice
        ? cereToUSD(initAuctionPrice, cereUnitsPerPenny).toString()
        : nextMinUSDBid.toString(),
    }),
    [initAuctionPrice, cereUnitsPerPenny, nextMinUSDBid],
  );

  return (
    <PurchaseModal open={open} icon={<BidWalletIcon />} title={t('Place bid')} onClose={onClose}>
      <Form onSubmit={handleClick} initialValues={initialValues} validate={validateBid}>
        {({handleSubmit, hasValidationErrors, errors, dirty, values}) => (
          <>
            {!isNonCustodyWalletConnected && (
              <Box className={clsx(styles.dataContainer, styles.connectWalletContainer)}>
                <Button className={styles.connectWalletButton} variant="text" onClick={showConnectWalletModal}>
                  {t('Connect wallet')} <ChevronRightIcon fontSize="small" />
                </Button>
              </Box>
            )}
            <Box className={clsx(styles.root, {'require-connect': !isNonCustodyWalletConnected})}>
              <Box className={styles.dataBox}>
                <Box className={clsx(styles.dataContainer, styles.dataLine)}>
                  <Box className={styles.column}>
                    <NftModalHighlightCard
                      nftId={nftId}
                      title={author}
                      nftTitle={nftTitle}
                      nftImage={nftImage}
                      onClick={onClose}
                    />
                  </Box>
                </Box>
                <Box className={clsx(styles.dataContainer, styles.dataLine)}>
                  <Box className={styles.column}>
                    <WalletBalance
                      balance={walletBalance?.usdc}
                      caption={t('My {{title}} wallet balance', {
                        title: NonCustodyWalletTitles[selectedWallet.wallet as NonCustodyWalletTypeEnum],
                      })}
                    />
                  </Box>
                </Box>
              </Box>
              <Box className={styles.dataBox}>
                <Box className={clsx(styles.dataContainer, styles.dataLine)}>
                  <Box className={styles.column}>
                    <Typography className={styles.dataLabel}>{t('NFT ID')}</Typography>
                    <Box className={styles.copyButtonContainer}>
                      <Typography className={styles.dataText} ref={nftIdRef}>
                        {nftAddress}
                      </Typography>
                      <CopyButton node={nftIdRef} />
                    </Box>
                    <Divider />
                  </Box>
                </Box>
                <Box className={clsx(styles.column, styles.dataLine)}>
                  <Box className={styles.dataContainer}>
                    <Typography className={styles.dataLabel}>{t('Quantity')}</Typography>
                    <Typography className={styles.dataText}>1</Typography>
                  </Box>
                  <Divider />
                </Box>
                <Box className={clsx(styles.column, styles.dataLine)}>
                  <Box className={styles.dataContainer}>
                    <Box className={styles.dataLabelWrapper}>
                      <Typography className={styles.dataLabel}>
                        {!bidsQty ? t('Starting price') : t('Highest bid')}
                      </Typography>
                      <Typography className={styles.dataSubLabel}>
                        <Trans i18nKey="({{count}} bids)" values={{count: bidsQty}} count={bidsQty} />
                      </Typography>
                    </Box>
                    <Typography className={styles.dataText}>{`${latestBidUSDPrice} ${TOKEN_TITLE}`}</Typography>
                  </Box>
                  <Divider />
                </Box>
                <Box className={clsx(styles.column, styles.dataLine)}>
                  <Box className={styles.dataContainer}>
                    <Field name={AMOUNT_FIELD_NAME}>
                      {({input}) => {
                        const onFocus = (e: any) => {
                          analyticService.track(AnalyticEventsEnum.WALLET_INPUT_FOCUSED);
                          input.onFocus(e);
                        };

                        return (
                          <AuctionTextField
                            onFocus={onFocus}
                            onChange={input.onChange}
                            value={formatBidValue(input.value)}
                            helperText={dirty ? parseError((errors || {})[AMOUNT_FIELD_NAME]) : ''}
                            variant={dirty ? validationType : ''}
                            labelClassName={styles.label}
                            cereUnitsPerPenny={cereUnitsPerPenny}
                          />
                        );
                      }}
                    </Field>
                  </Box>
                </Box>
              </Box>
            </Box>
            {!isLoading && (
              <>
                {dirty &&
                  hasValidationErrors &&
                  (errors || {})[AMOUNT_FIELD_NAME] === AMOUNT_FIELD_ERRORS.INSUFFICIENT && (
                    <>
                      <Paper className={styles.errorMessage} elevation={0}>
                        <ErrorIcon fontSize="small" />
                        <Typography className={styles.errorText}>
                          {t('Insufficient balance, fund your wallet')}
                        </Typography>
                      </Paper>
                    </>
                  )}
                {dirty &&
                  hasValidationErrors &&
                  (errors || {})[AMOUNT_FIELD_NAME] === AMOUNT_FIELD_ERRORS.LOWERMINIMAL && (
                    <>
                      <Paper className={styles.warningMessage} elevation={0}>
                        <ErrorIcon fontSize="small" />
                        <Typography className={styles.warningText}>
                          <Trans
                            // eslint-disable-next-line no-template-curly-in-string
                            i18nKey="Bid cannot be lower than starting price: <strong>${{price}}</strong>"
                            values={{price: latestBidUSDPrice}}
                          />
                        </Typography>
                      </Paper>
                    </>
                  )}
              </>
            )}
            <Box className={styles.buttonContainer}>
              <NFTCTAButton
                className={styles.button}
                text={t('Place bid')}
                type="submit"
                onClick={handleSubmit}
                loading={isProcessing}
                disabled={(dirty && hasValidationErrors) || (isNonCustodyWalletConnected && isButtonDisabled)}
              />
              {dirty && hasValidationErrors && (errors || {})[AMOUNT_FIELD_NAME] === AMOUNT_FIELD_ERRORS.INSUFFICIENT && (
                <TorusWalletFundButton
                  className={styles.fundButton}
                  contextInfo={{
                    price: Number(formatBidValue(values[AMOUNT_FIELD_NAME])),
                    title: nftTitle,
                    description: t('You’re about to place bid on:'),
                    imageUrl: nftImage!,
                  }}
                />
              )}
            </Box>
          </>
        )}
      </Form>
    </PurchaseModal>
  );
};
