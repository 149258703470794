import {Form, Field} from 'react-final-form';
import * as EmailValidator from 'email-validator';
import AuthInput from './AuthInput';
import {useCallback} from 'react';
import {Button} from '@cere/rxb-template-ui-kit';
import {GoogleAnalyticsId} from '../../../../analytics-ids';
import {Box} from '@material-ui/core';
import {useLocalization} from '../../../utils/hooks/use-localization';

type UserFormProps = {
  onSubmit: (formData: {email: string}) => void;
};

export const UserForm = ({onSubmit}: UserFormProps) => {
  const {t} = useLocalization();

  const emailValidator = useCallback(
    (value: string) => {
      if (EmailValidator.validate(value?.trim() ?? '')) {
        return null;
      }
      return t('Please enter a valid email');
    },
    [t],
  );

  return (
    <Form onSubmit={onSubmit} initialValues={{email: ''}}>
      {({handleSubmit, hasValidationErrors, pristine, submitting}) => (
        <>
          <Field validate={emailValidator} name="email">
            {({input, meta}) => (
              <AuthInput
                value={input.value}
                helperText={meta.touched ? meta.error : null}
                onChange={input.onChange}
                onBlur={input.onBlur}
                onFocus={input.onFocus}
                inputProps={{inputMode: 'email'}}
              />
            )}
          </Field>
          <Box mt="24px" mb="12px">
            <Button
              className={GoogleAnalyticsId.SignInBtn}
              color="secondary"
              size="large"
              variant="contained"
              fullWidth
              type="submit"
              disabled={hasValidationErrors || pristine || submitting}
              onClick={handleSubmit}
            >
              {t('Sign in')}
            </Button>
          </Box>
        </>
      )}
    </Form>
  );
};
