import {ReactElement} from 'react';
import {Container, Theme} from '@material-ui/core';
import {makeStyles} from '@material-ui/core';

const useStyles = makeStyles<Theme, {isHomePage?: boolean}>((theme) => ({
  container: {
    margin: ({isHomePage}) => (isHomePage ? '0 auto 28px auto' : '32px auto 28px auto'),
    overflow: 'hidden',
    paddingLeft: '16px',
    paddingRight: '16px',

    [theme.breakpoints.up('md')]: {
      maxWidth: '688px',
      minWidth: '688px',
      paddingLeft: '0',
      paddingRight: '0',
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: '1280px',
      minWidth: '1280px',
    },
  },
}));

type Props = {
  children: ReactElement | ReactElement[];
  isHomePage?: boolean;
};

export const PageContainer = ({children, isHomePage}: Props) => {
  const styles = useStyles({isHomePage});
  return <Container className={styles.container}>{children}</Container>;
};
