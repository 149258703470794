import React from 'react';
import {Card as MUICard} from '@material-ui/core';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/core/styles';
import colors from '../../../styles/colors';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: '100%',
    position: 'relative',
    borderRadius: '12px',
    backgroundColor: colors.snowWhite,
    flexDirection: 'column',
    marginTop: '16px',
    boxShadow: 'none',

    [theme.breakpoints.up('md')]: {
      margin: '16x 40px 0 40px',
    },

    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row',
      margin: 'unset',
    },
  },
}));

export const Card: React.FC<{className?: string}> = ({children, className}) => {
  const styles = useStyles();
  return <MUICard className={clsx(styles.root, className)}>{children}</MUICard>;
};
