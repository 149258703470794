import {ReactElement, ReactNode, useContext} from 'react';
import {Link} from 'react-router-dom';
import {makeStyles} from '@material-ui/core';
import {AvailabilityStatus} from '@cere/services-types';
import clsx from 'clsx';
import {useNFTCardStyles} from './styles';
import {NftContext} from '../../../shared/components/Nft/nft-context-v2';
import {useLocalization} from '../../../shared/hooks/use-locale.hook';

type Props = {
  children: ReactNode;
};

const useStyles = makeStyles(() => ({
  link: {
    color: 'currentColor',
    textDecoration: 'none',
  },
}));

export function NftLink({children}: Props): ReactElement {
  const {locale} = useLocalization();
  const {nft} = useContext(NftContext);
  const styles = useStyles();
  const isComingSoon = nft.availability === AvailabilityStatus.COMING_SOON;
  const classes = useNFTCardStyles({
    comingSoon: isComingSoon,
  });
  const link = `/${locale}/home/nft/${nft.id}`;
  return (
    <Link to={link} className={clsx(classes.link, styles.link, isComingSoon && classes.inactiveLink)}>
      {children}
    </Link>
  );
}
