import {useCallback, useState} from 'react';
import {Box, makeStyles} from '@material-ui/core';
import {Button} from '@cere/rxb-template-ui-kit';
import {ReactComponent as BackIcon} from '../../assets/icons/back-arrow.svg';
import analyticService, {AnalyticEventsEnum} from '../../shared/services/analytic.service';
import {AuthForm} from './auth-form';
import {AuthAnalyticsTrackingProvider} from './auth-analytics-tracking.context';
import {noop} from 'lodash';
import {AuthContext} from '../../shared/components/BuyNftGuard';
import {AuthPageState} from './types';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    height: '100vh',
  },
  backLinkBox: {
    padding: '2rem 0 0 1rem',
  },
  backLinkBoxButton: {
    minWidth: 'unset',
  },
  box: {
    margin: '0 auto',
    padding: '2rem',
    [theme.breakpoints.up('md')]: {
      maxWidth: '480px',
    },
  },
}));

export const SignInPage = () => {
  const [onBackClickState, setOnBackClickState] = useState(false);
  const styles = useStyles();
  const [variant, setVariant] = useState(AuthPageState.AUTH);
  const value = {variant, setVariant};

  const onBackClick = useCallback(() => {
    analyticService.track(AnalyticEventsEnum.SIGN_IN_BACK_CLICKED);
    setOnBackClickState(true);
  }, []);

  return (
    <Box className={styles.root}>
      <Box className={styles.backLinkBox}>
        <Button onClick={onBackClick} className={styles.backLinkBoxButton}>
          <BackIcon />
        </Button>
      </Box>
      <Box className={styles.box}>
        <AuthAnalyticsTrackingProvider
          signUpFocused={AnalyticEventsEnum.SIGN_UP_INPUT_FOCUSED}
          signInFocused={AnalyticEventsEnum.SIGN_IN_INPUT_FOCUSED}
        >
          <AuthContext.Provider value={value}>
            <AuthForm
              goBackState={onBackClickState}
              changeGoBackState={onBackClickState ? setOnBackClickState : noop}
            />
          </AuthContext.Provider>
        </AuthAnalyticsTrackingProvider>
      </Box>
    </Box>
  );
};
