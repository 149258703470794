import {ReactElement, useRef} from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from '@material-ui/core';
import {Error} from '@material-ui/icons';

import {CreatorWalletNft} from '../../shared/types/graphql/creator-wallet-nfts';
import {useConfirmStyles} from './confirm-styles';
import clsx from 'clsx';
import {PromiseResolve, PromiseReject} from '../../shared/lib/async-result';
import {Transition} from '../../shared/components/transition';
import {CopyButton} from '../../shared/components/CopyButton';
import {useLocalization} from '../../shared/hooks/use-locale.hook';
import {getNFTCardImage} from 'shared/helpers/nfts';
import {useNftRoyalty} from 'context/use-nft-royalties';
import {useLoadNftById} from 'api/hooks/use-load-nft-by-id';

type Props = {
  nft: CreatorWalletNft;
  address: string;
  network: string;
  open: boolean;
  onConfirm: PromiseResolve;
  onCancel: PromiseReject;
};

export function ConfirmTransfer({open, address, network, onConfirm, onCancel, nft}: Props): ReactElement {
  const {t} = useLocalization();
  const classes = useConfirmStyles();
  const addressRef = useRef<HTMLElement | null>(null);
  const nftRef = useRef<HTMLElement | null>(null);
  const cmsNft = nft.nft_id.cmsNft;
  const nftRoyalty = useNftRoyalty(nft.nft_id.cmsNft.id.toString());
  const {nft: nftCard} = useLoadNftById(nft.nft_id.cmsNft.id.toString());

  return (
    <Dialog
      classes={{root: classes.root, paperWidthSm: classes.rootWidth}}
      onClose={onCancel}
      open={open}
      fullWidth
      maxWidth="sm"
      TransitionComponent={Transition}
    >
      <DialogTitle classes={{root: classes.title}}>
        <div className="icon">
          <Error />
        </div>
        {t('Confirm Transfer')}
        <Typography classes={{root: classes.titleCaption}} variant="body1">
          {t('Please confirm you are sending to the correct address & network. Transfer is irrevocable.')}
        </Typography>
      </DialogTitle>
      <DialogContent classes={{root: classes.content}}>
        <div className={classes.line}>
          <div>
            <Typography classes={{root: classes.lineGrey}} variant="body1">
              {t('NFT name')}
            </Typography>
            <Typography classes={{root: classes.lineMain}} variant="body1">
              {cmsNft.title}
            </Typography>
          </div>
          <img className={classes.lineImage} alt={cmsNft.title} src={getNFTCardImage(cmsNft)} />
        </div>
        <div className={classes.line}>
          <div>
            <Typography classes={{root: classes.lineGrey}} variant="body1">
              {t('NFT ID')}
            </Typography>
            <Typography ref={nftRef} classes={{root: clsx(classes.lineMain, classes.lineMainNoWrap)}} variant="body1">
              {nft.nft_id.nft_id}
            </Typography>
          </div>
          <CopyButton node={nftRef} className={classes.lineCopyButton} />
        </div>
        <div className={classes.line}>
          <div>
            <Typography classes={{root: classes.lineGrey}} variant="body1">
              {t('Network')}
            </Typography>
            <Typography classes={{root: clsx(classes.lineMain, classes.lineMainNoWrap)}} variant="body1">
              {network}
            </Typography>
          </div>
        </div>
        <div className={classes.line}>
          <div>
            <Typography classes={{root: classes.lineGrey}} variant="body1">
              {t('Receiving address')}
            </Typography>
            <Typography
              ref={addressRef}
              classes={{root: clsx(classes.lineMain, classes.lineMainNoWrap)}}
              variant="body1"
            >
              {address}
            </Typography>
          </div>
          <CopyButton node={addressRef} className={classes.lineCopyButton} />
        </div>
        <div className={classes.line}>
          <div>
            <Typography classes={{root: classes.lineGrey}} variant="body1">
              {t('Royalties')}
            </Typography>
            <Typography classes={{root: clsx(classes.lineMain, classes.lineMainNoWrap)}} variant="body1">
              {nftCard?.priceUsd ? nftCard?.priceUsd * (nftRoyalty || 0.01) : 0} {t('USDC')}
            </Typography>
          </div>
        </div>
      </DialogContent>
      <DialogActions classes={{root: classes.actions}}>
        <Button
          classes={{root: classes.button, outlined: classes.buttonOutlined}}
          onClick={onCancel}
          variant="outlined"
        >
          {t('Cancel')}
        </Button>
        <Button
          disableElevation
          classes={{root: classes.button, contained: classes.buttonContained}}
          onClick={() => onConfirm(undefined)}
          variant="contained"
        >
          {t('Transfer')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
