import {Children} from 'react';
import {Box, Button, CircularProgress, Typography} from '@material-ui/core';
import {FiltersContextProvider, FiltersParams} from '../../../context/filters-context';
import {useNFTFilterState} from '../NFTFilter/hooks';
import {NFTFilterModal} from '../NFTFilterModal';
import {NFTFilter} from '../NFTFilter';
import {useFilteredGridStyles} from './styles';
import clsx from 'clsx';
import {Condition, ConditionsList} from '../Conditions';
import {Placeholder} from './Placeholder';
import {useLocalization} from '../../hooks/use-locale.hook';

export interface NFTFilteredGridProps {
  children: JSX.Element | JSX.Element[];
  title: string;
  subTitle?: string;
  hasMore?: boolean;
  loading?: boolean;
  onFiltersUpdate: (params: FiltersParams) => void;
  onLoadMore: () => void;
  isNftCollectiblesCard?: boolean;
}

export const NFTFilteredGrid = ({
  children,
  title,
  subTitle,
  hasMore,
  onFiltersUpdate,
  loading,
  onLoadMore,
  isNftCollectiblesCard,
}: NFTFilteredGridProps) => {
  const {t} = useLocalization();
  const styles = useFilteredGridStyles({isNftCollectiblesCard});
  const {opened, open, close} = useNFTFilterState();

  return (
    <FiltersContextProvider onFiltersUpdate={onFiltersUpdate}>
      <Box className={clsx(styles.root, styles.section)}>
        <Box className={styles.header}>
          <Box className={styles.headerContentWrapper}>
            <Box className={styles.textContent}>
              <Typography className={styles.title}>{title}</Typography>
              <Typography className={styles.subTitle}>{subTitle}</Typography>
            </Box>
            <Box className={styles.filtersContent}>
              <NFTFilter openFilterModal={open} />
            </Box>
          </Box>
          <Box className={styles.desktopFiltersWrapper}>
            <NFTFilterModal opened={opened} onClose={close} />
          </Box>
        </Box>
        <ConditionsList>
          <Condition condition={!!loading}>
            <CircularProgress color="inherit" size={40} />
          </Condition>
          <Condition condition={!loading && !Children.toArray(children).length}>
            <Placeholder />
          </Condition>
          <Condition condition={!loading}>
            <Box className={styles.contentGrid}>{children}</Box>
            {hasMore && (
              <Box className={styles.controlsContainer}>
                <Button className={styles.loadMoreButton} onClick={onLoadMore}>
                  {t('Show more NFTs')}
                </Button>
              </Box>
            )}
          </Condition>
        </ConditionsList>
      </Box>
    </FiltersContextProvider>
  );
};
