import {ReactElement} from 'react';
import {makeStyles} from '@material-ui/core';
import {Button} from '@cere/rxb-template-ui-kit';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  socialButton: {
    border: '1px solid #E0E0E7',
    display: 'flex',
    alignItems: 'center',
    flex: '0 0 65px',
    borderRadius: '12px',

    '& *': {
      display: 'flex',
    },
  },
  disabled: {
    background: 'transparent !important',
    filter: 'grayscale(100%)',
  },
}));

type SocialButtonProps = {
  icon: ReactElement;
  disabled?: boolean;
  onClick: () => void;
};

export const SocialButton = ({icon, disabled, onClick}: SocialButtonProps) => {
  const styles = useStyles();

  return (
    <Button
      color="primary"
      size="large"
      className={clsx(styles.socialButton, {[styles.disabled]: disabled})}
      disabled={disabled}
      onClick={onClick}
    >
      {icon}
    </Button>
  );
};
