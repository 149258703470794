import {Box, makeStyles} from '@material-ui/core';

import {useHomePageBannerStyles} from 'pages/HomePage/HomePageBanner';

import {Skeleton} from './Skeleton';

const useStyles = makeStyles((theme) => ({
  svipersBox: {
    position: 'absolute',
    bottom: '20px',
    left: '5%',
    width: '90%',
    display: 'grid',
    gridTemplateColumns: 'repeat(5, 1fr)',
    gridColumnGap: '5px',
  },
  sviper: {
    height: '2px',
    borderRadius: '1px',
    backgroundColor: theme.palette.grey[400],
  },
  button: {
    position: 'absolute',
    bottom: '54px',
    left: '5%',
    height: '44px',
    width: '168px',
    borderRadius: '22px',
    backgroundColor: theme.palette.grey[400],
  },
}));

export const HomePageBannerSkeleton = () => {
  const containerStyles = useHomePageBannerStyles();
  const styles = useStyles();

  return (
    <Box className={containerStyles.root}>
      <Skeleton variant="rect" height={500} />
      <Box className={styles.svipersBox}>
        {Array(5)
          .fill(0)
          .map((_, index) => (
            <Box key={index} className={styles.sviper} />
          ))}
      </Box>
      <Box className={styles.button} />
    </Box>
  );
};
