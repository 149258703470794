import {gql} from '@apollo/client';
import {UPLOAD_FILE_FRAGMENT} from './fragments';
import {ARTIST_PROFILE_FRAGMENT} from './artists';

export const NFT_FRAGMENT = gql`
  ${UPLOAD_FILE_FRAGMENT}
  ${ARTIST_PROFILE_FRAGMENT}
  fragment nftFragment on CmsNft {
    id
    title

    creatorNft {
      id
      minter
      nft_id
      collection_address
      supply
      creator_take_offer(sort: "published_at:desc") {
        amount
        price
        buyer
      }
      creator_auctions(sort: "published_at:desc") {
        seller
        price
        is_settled
        published_at
        creator_auction_bids(sort: "timestamp:desc") {
          id
          buyer
          price
          timestamp
          txHash
        }
      }
      creator_wallet_nfts(where: {quantity_gt: 0}) {
        wallet
        quantity
      }
      creator_make_offer(sort: "price:asc", where: {price_gt: 0}) {
        seller
        price
        published_at
      }
    }
    cmsCreator {
      ...ArtistProfile
    }
    isComingSoon
    title
    description
    cardDescription
    assets {
      content {
        ...Media
      }
    }
    cardImage {
      ...Media
    }
    seoTitle
    seoDescription
    seoCanonical
    seoImage {
      ...Media
    }
    locale
    localizations {
      locale
      id
    }
  }
`;

export const GET_NFT = gql`
  ${NFT_FRAGMENT}
  query CmsNft($id: ID!) {
    cmsNft(id: $id) {
      ...nftFragment
    }
  }
`;

export const GET_NFT_SEO_DATA = gql`
  query NftSeoData($id: ID!) {
    cmsNft(id: $id) {
      title
      description
      seoTitle
      seoDescription
      seoCanonical
      seoImage {
        url
      }
      assets {
        content {
          url
        }
      }
    }
  }
`;
