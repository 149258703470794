export enum BUTTON_VARIANTS {
  contained = 'contained',
  outlined = 'outlined',
  text = 'text',
}

export enum BUTTON_COLORS {
  primary = 'primary',
  secondary = 'secondary',
}

export enum BUTTON_SIZES {
  large = 'large',
  small = 'small',
}
