import {SupportedWalletTypes} from '../../../models/wallet/types';
import {AnyAction, combineReducers} from 'redux';
import {ActiveWalletTypeDocument, ConnectedWalletsTypesDocument, WalletsBalanceDocument} from './actions';

type WalletsBalanceStore = {
  [key: string]: number;
};

export const selectedWalletReducer = (
  selectedWallet: SupportedWalletTypes = SupportedWalletTypes.Torus,
  action: AnyAction,
) => {
  if (action.type === ActiveWalletTypeDocument.type) {
    return action.payload;
  }
  return selectedWallet;
};

export const connectedWalletsReducer = (wallets: SupportedWalletTypes[] = [], action: AnyAction) => {
  if (action.type === ConnectedWalletsTypesDocument.type) {
    return action.payload;
  }
  return wallets;
};

export const walletsBalanceReducer = (store: WalletsBalanceStore = {}, action: AnyAction) => {
  if (action.type === WalletsBalanceDocument.type) {
    return action.payload;
  }
  return store;
};

export const userWalletsModuleReducer = combineReducers({
  selectedWallet: selectedWalletReducer,
  connectedWallets: connectedWalletsReducer,
  walletsBalance: walletsBalanceReducer,
});
