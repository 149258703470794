import {LocalizationContext} from '../../context/localization-context';
import {useContext} from 'react';
import {useTranslation, TFunction} from 'react-i18next';

export const useLocalization = (): {
  locale: string;
  updateLocale: (locale: string) => void;
  t: TFunction<'translation', undefined>;
} => {
  const {locale, updateLocale} = useContext(LocalizationContext);
  const {t} = useTranslation();

  return {
    locale,
    updateLocale,
    t,
  };
};
