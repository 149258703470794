import {memo, useState, useCallback, useMemo, useRef, ReactNode} from 'react';
import {Box, Typography, makeStyles} from '@material-ui/core';
import {SpeedDial, SpeedDialIcon, SpeedDialAction} from '@material-ui/lab';
import clsx from 'clsx';

import {FavoriteBorder, MoreVert, Close} from '@material-ui/icons';
import {ReactComponent as ShareIcon} from '../../../assets/svg/share.svg';

import {useThemeBreakpoints} from '../../../styles/useThemeBreakpoints';
import {IconButton} from '../../primitives/IconButton';
import {AvatarWithName} from '../../primitives/AvatarWithName';
import {CardImage} from '../../primitives/CardImage';
import {StyledLink} from '../../primitives/StyledLink';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    borderRadius: '12px',
    overflow: 'hidden',
    minWidth: '100px',
    filter: 'drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.12))',
  },
  badgeBox: {
    position: 'absolute',
    top: '12px',
    left: '12px',
    maxWidth: 'calc(100% - 54px)',
  },
  actionButtonsBox: {
    position: 'absolute',
    top: '12px',
    right: '12px',
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
  },
  infoBox: {
    flexGrow: 2,
    padding: '12px',
    backgroundColor: theme.palette.primary.light,
  },
  title: {
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '22px',
    paddingTop: '5px',
  },
  bottomBox: {
    paddingTop: '16px',
  },
  subTitle: {
    fontWeight: 500,
    fontSize: '13px',
    lineHeight: '21px',
    color: theme.palette.text.secondary,
  },
  actionBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    paddingTop: '2px',
  },
  leftBottomInfo: {
    fontSize: '16px',
    lineHeight: '22px',
  },
  overflowText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  iconButton: {
    width: '30px',
    height: '30px',
    minHeight: '30px',
    backgroundColor: theme.palette.grey[900],
    color: theme.palette.common.white,
  },
  speedDialAction: {
    margin: '4px',
    '& .MuiSpeedDialAction-tooltipPlacementLeft': {
      display: 'none !important',
    },
  },
  speedDialIcon: {
    width: '27px',
    height: '27px',
  },
  moreButtonActions: {
    paddingTop: '36px !important',
  },
  hidden: {
    display: 'none !important',
  },
}));

const SMALL_CARD_WIDTH = 200;

export type CardProps = {
  title: string;
  image?: string;
  cardLink: string;
  subTitle?: NonNullable<ReactNode>;
  creatorImage?: string;
  creatorName?: string;
  creatorLink?: string;
  cardBadge?: NonNullable<ReactNode>;
  onShareClick?: () => void;
  onLikeClick?: () => void;
  bottomInfoDescription?: NonNullable<ReactNode>;
  bottomInfo?: NonNullable<ReactNode>;
  leftBottomInfo?: NonNullable<ReactNode>;
  rightBottomElement?: NonNullable<ReactNode>;
  imageBackdrop?: NonNullable<ReactNode>;
  classes?: Partial<Record<'bottomBox' | 'infoBox', string>>;
};

export const Card = memo(
  ({
    title,
    image,
    cardLink,
    subTitle,
    creatorImage,
    creatorName,
    creatorLink,
    cardBadge,
    onShareClick,
    onLikeClick,
    bottomInfoDescription,
    bottomInfo,
    leftBottomInfo,
    rightBottomElement,
    imageBackdrop,
    classes,
  }: CardProps) => {
    const styles = useStyles();
    const ref = useRef<HTMLDivElement | null>(null);
    const {isMobile, isTablet, isDesktop} = useThemeBreakpoints();

    const [openShowMore, setOpenShowMore] = useState(false);
    const handleCloseShowMore = useCallback(() => setOpenShowMore(false), []);
    const handleOpenShowMore = useCallback(() => setOpenShowMore(true), []);

    const smallCard = useMemo(
      () => {
        const width = ref.current?.getBoundingClientRect().width;
        return width ? width <= SMALL_CARD_WIDTH : false;
      },
      // we need to recalculate this value after breakpoints change
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [isMobile, isTablet, isDesktop],
    );

    return (
      <div className={styles.root} ref={ref}>
        <CardImage title={title} image={image!} cardLink={cardLink} imageBackdrop={imageBackdrop}>
          <Box className={styles.actionButtonsBox}>
            {(!!onShareClick || !!onLikeClick) &&
              (smallCard ? (
                <SpeedDial
                  ariaLabel="more-icon-button"
                  direction="down"
                  open={openShowMore}
                  onClose={handleCloseShowMore}
                  onOpen={handleOpenShowMore}
                  icon={<SpeedDialIcon className={styles.speedDialIcon} icon={<MoreVert />} openIcon={<Close />} />}
                  classes={{fab: styles.iconButton, actions: styles.moreButtonActions}}
                >
                  {onShareClick && (
                    <SpeedDialAction
                      icon={<ShareIcon />}
                      onClick={onShareClick}
                      className={clsx(styles.iconButton, styles.speedDialAction)}
                      TooltipClasses={{popper: styles.hidden}}
                    />
                  )}
                  {onLikeClick && (
                    <SpeedDialAction
                      icon={<FavoriteBorder />}
                      onClick={onLikeClick}
                      className={clsx(styles.iconButton, styles.speedDialAction)}
                      TooltipClasses={{popper: styles.hidden}}
                    />
                  )}
                </SpeedDial>
              ) : (
                <>
                  {onShareClick && (
                    <IconButton onClick={onShareClick}>
                      <ShareIcon />
                    </IconButton>
                  )}
                  {onLikeClick && (
                    <IconButton onClick={onLikeClick}>
                      <FavoriteBorder />
                    </IconButton>
                  )}
                </>
              ))}
          </Box>
          <Box className={styles.badgeBox}>{cardBadge}</Box>
        </CardImage>

        <Box className={clsx(styles.infoBox, classes?.infoBox)}>
          {creatorName && creatorLink && (
            <StyledLink to={creatorLink}>
              <AvatarWithName name={creatorName} image={creatorImage} isVerified />
            </StyledLink>
          )}
          <Typography className={clsx(styles.title, styles.overflowText)}>{title}</Typography>

          <Box className={clsx(styles.bottomBox, classes?.bottomBox)}>
            <Typography className={clsx(styles.subTitle, styles.overflowText)}>{subTitle}</Typography>
            {bottomInfoDescription}
            {bottomInfo || (
              <Box className={styles.actionBox}>
                <Typography className={clsx(styles.leftBottomInfo, styles.overflowText)}>{leftBottomInfo}</Typography>
                {!smallCard && rightBottomElement}
              </Box>
            )}
          </Box>
        </Box>
      </div>
    );
  },
);
