import {ReactElement, useCallback, useContext, useRef, useState} from 'react';
import {Box, IconButton, makeStyles, Theme} from '@material-ui/core';
import clsx from 'clsx';

import {GoogleAnalyticsId} from 'analytics-ids';
import {ReactComponent as PlayIcon} from 'assets/icons/play.svg';
import {ExhibitContext} from 'context/exhibition/exhibit-context';
import {Condition, ConditionsList} from 'shared/components/Conditions';
import {ExhibitionTeaserType} from 'shared/types/exhibition-teaser';
import {useResponsiveImage} from 'shared/hooks/use-responsive-image';
import colors from 'styles/colors';

const useStyles = makeStyles<Theme, {isLive: boolean}>(() => ({
  block: {
    zIndex: 1,
    color: colors.white,
    width: '100%',
    height: '100%',
    borderRadius: 'var(--radius)',
    overflow: 'hidden',
    position: 'relative',
    '--radius': '12px',
    '--stroke': '2px',

    '@supports not (-webkit-mask-composite: xor)': {
      border: ({isLive}) =>
        isLive ? 'var(--stroke) solid rgba(243, 102, 130, 0.8)' : 'var(--stroke) solid rgba(100, 100, 100, .1))',
    },

    '@supports (-webkit-mask-composite: xor)': {
      '&::before': {
        content: "''",
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        padding: 'var(--stroke)',
        borderRadius: 'var(--radius)',
        background: ({isLive}) =>
          isLive
            ? 'linear-gradient(226.34deg, rgba(244, 104, 129, 0.8) 15.52%, rgba(243, 102, 130, 0.8) 16.27%, rgba(231, 69, 150, 0.8) 29.76%, rgba(224, 49, 162, 0.8) 41.02%, rgba(221, 42, 166, 0.8) 48.62%, rgba(82, 16, 226, 0.8) 77.78%)'
            : 'linear-gradient(180deg, rgba(100, 100, 100, .6), rgba(100, 100, 100, .1))',
        mask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
        '-webkit-mask-composite': 'xor',
      },
    },

    '& video': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
  },
  teaserEnterButton: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  teaserButtonBlock: {
    margin: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100px',
    height: '100px',
    position: 'absolute',
    opacity: '.8',
    zIndex: 10,

    '& svg': {
      width: '23px',
      height: 'auto',
    },
  },
  hidden: {
    display: 'none',
  },
  iconButtonRoot: {
    opacity: '.8',

    '&:hover': {
      opacity: 1,
    },
  },
}));

export function ExhibitTeaser({type}: {type: ExhibitionTeaserType}): ReactElement {
  const {event} = useContext(ExhibitContext);
  const [playing, setPlaying] = useState(false);
  const styles = useStyles({isLive: type === ExhibitionTeaserType.LIVE});
  const videoRef = useRef<HTMLVideoElement | null>(null);

  const handlePlay = useCallback(() => setPlaying(true), []);
  const handlePause = useCallback(() => setPlaying(false), []);
  const playPauseVideo = useCallback(() => {
    if (videoRef.current?.muted) {
      videoRef.current.muted = false;
    }

    if (videoRef.current?.paused) {
      videoRef.current?.play();
    } else {
      videoRef.current?.pause();
    }
  }, []);

  const teaser = type === ExhibitionTeaserType.LIVE ? event?.liveTeaser : event?.teaser;
  const url = teaser?.content?.url;
  const teaserPosterUrl = useResponsiveImage(teaser?.poster);

  const videoSourceUrl = teaserPosterUrl ? url : url && `${url}#t=0.001`; // Force iOS Safari to render video preview image

  return (
    <ConditionsList>
      <Condition condition={typeof url === 'string' && url !== ''}>
        <Box className={styles.block}>
          <Box className={clsx(styles.teaserButtonBlock, styles.teaserEnterButton, playing && styles.hidden)}>
            <IconButton
              classes={{root: styles.iconButtonRoot}}
              className={GoogleAnalyticsId.GoToTheEventBtn}
              onClick={playPauseVideo}
            >
              <PlayIcon />
            </IconButton>
          </Box>
          <video
            ref={videoRef}
            onClick={playPauseVideo}
            poster={teaserPosterUrl}
            onPause={handlePause}
            onPlay={handlePlay}
          >
            <source src={videoSourceUrl} type="video/mp4" />
          </video>
        </Box>
      </Condition>
    </ConditionsList>
  );
}
