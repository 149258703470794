import {makeStyles} from '@material-ui/core';

import colors from '../../styles/colors';

export const useConfirmStyles = makeStyles((theme) => ({
  root: {
    '--padding': '1rem 1.5rem',
    padding: '1rem',
  },
  rootWidth: {
    borderRadius: '12px',
    [theme.breakpoints.up('md')]: {
      maxWidth: '480px',
    },
  },
  title: {
    '& > h2': {
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      gap: '.5rem 0',
      alignItems: 'center',
      fontWeight: 600,
    },
    '& .icon': {
      '--size': '18px',
      width: 'var(--size)',
      height: 'var(--size)',
      borderRadius: 'var(--size)',
      background: colors.primaryDark,
      color: colors.darkYellow,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  titleCaption: {
    margin: '0 auto',
    width: '80%',
    color: theme.palette.grey['800'],
    fontSize: '14px',
    fontWeight: 600,
  },
  content: {
    padding: 'var(--padding)',
  },
  line: {
    display: 'flex',
    margin: '1rem 0',
    alignItems: 'center',
  },
  lineMain: {
    fontSize: '14px',
    fontWeight: 600,
  },
  lineMainNoWrap: {
    maxWidth: '200px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',

    [theme.breakpoints.up('md')]: {
      maxWidth: '300px',
    },
  },
  lineGrey: {
    color: theme.palette.grey['500'],
    fontSize: '13px',
    fontWeight: 600,
  },
  lineCopyButton: {
    marginLeft: 'auto',
    position: 'relative',
    top: '0.3rem',
  },
  lineImage: {
    '--size': '45px',
    width: 'var(--size)',
    height: 'var(--size)',
    borderRadius: '.2rem',
    objectFit: 'cover',
    marginLeft: 'auto',
  },
  lineButton: {},
  actions: {
    padding: 'var(--padding)',
    justifyContent: 'space-between',
  },
  button: {
    borderRadius: '2rem',
    textTransform: 'none',
    fontWeight: 600,
    width: '120px',

    [theme.breakpoints.up('md')]: {
      width: '200px',
    },
  },
  buttonOutlined: {
    border: `1px solid ${colors.primaryDark}`,
  },
  buttonContained: {
    color: colors.white,
    background: colors.primaryDark,

    '&:hover': {
      color: colors.white,
      background: colors.primaryDark,
      opacity: 0.8,
    },
  },
}));
