import {ReactNode, memo} from 'react';
import {Container, makeStyles} from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '430px',
    paddingLeft: '16px',
    paddingRight: '16px',

    [theme.breakpoints.up('md')]: {
      // 700px
      maxWidth: '700px',
      minWidth: '700px',
      paddingLeft: '24px',
      paddingRight: '24px',
    },
    [theme.breakpoints.up('lg')]: {
      // 1280px
      maxWidth: '1280px',
      minWidth: '1280px',
      paddingLeft: '40px',
      paddingRight: '40px',
    },
  },
}));

type Props = {
  children: NonNullable<ReactNode>;
  className?: string;
};

export const PageContainer = memo(({children, className}: Props) => {
  const styles = useStyles();
  return <Container className={clsx(styles.container, className)}>{children}</Container>;
});
