import {FC, ReactElement} from 'react';
import {Icon, Typography} from '@cere/rxb-template-ui-kit';
import {Box, makeStyles, Theme, useMediaQuery} from '@material-ui/core';

import {ReactComponent as FacebookIcon} from '../../../assets/facebook.svg';
import {ReactComponent as InstagramIcon} from '../../../assets/instagram.svg';
import {ReactComponent as TwitterIcon} from '../../../assets/twitter.svg';
import {ReactComponent as YoutubeIcon} from '../../../assets/youtube.svg';
import {ReactComponent as TiktokIcon} from '../../../assets/tiktok.svg';
import {CmsCreator} from '../../types/graphql';
import {formatSocialCounterCount} from 'shared/lib/formatNumber';
import colors from '../../../styles/colors';
import {useLocalization} from '../../hooks/use-locale.hook';
import {FullCreatorInterface} from '@cere/services-types/dist/types';

interface SocialNetworksInfoProps {
  artist: CmsCreator | FullCreatorInterface;
  isShort?: boolean;
  withoutBackground?: boolean;
  iconSize?: string;
  centered?: boolean;
  noTitle?: boolean;
}

type ArtistSocialNetworkNumberFieldName =
  | 'instagramFollowerNumber'
  | 'facebookFollowerNumber'
  | 'twitterFollowerNumber'
  | 'youtubeFollowerNumber'
  | 'tiktokFollowerNumber';

const SOCIAL_NETWORKS: {
  fieldName: ArtistSocialNetworkNumberFieldName;
  icon: ReactElement;
}[] = [
  {
    icon: <InstagramIcon />,
    fieldName: 'instagramFollowerNumber',
  },
  {
    icon: <FacebookIcon />,
    fieldName: 'facebookFollowerNumber',
  },
  {
    icon: <TwitterIcon />,
    fieldName: 'twitterFollowerNumber',
  },
  {
    icon: <YoutubeIcon />,
    fieldName: 'youtubeFollowerNumber',
  },
  {
    icon: <TiktokIcon />,
    fieldName: 'tiktokFollowerNumber',
  },
];

interface SocialNetworksInfoStyles {
  isShort?: boolean;
  withoutBackground: boolean;
  iconSize: string;
  centered: boolean;
  isDesktop: boolean;
  isMobile: boolean;
}

const useStyles = makeStyles<Theme, SocialNetworksInfoStyles>((theme) => ({
  container: {
    width: '100%',
    paddingTop: ({isMobile, isShort}) => (isMobile || isShort ? '12px' : '16px'),
  },
  list: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: ({centered}) => (centered ? 'center' : 'space-between'),
    flexWrap: 'wrap',
    gap: '25px',
    opacity: '0.8',

    [theme.breakpoints.up('sm')]: {
      gap: '35px',
      justifyContent: ({centered}) => (centered ? 'center' : 'inherit'),
    },
  },
  icon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: ({iconSize}) => (iconSize ? iconSize : '25px'),
    height: ({iconSize}) => (iconSize ? iconSize : '25px'),
    borderRadius: '25px',
    backgroundColor: ({withoutBackground}) => (withoutBackground ? 'initial' : colors.snowWhite),

    '& svg': {
      width: ({iconSize}) => (iconSize ? iconSize : '25px'),
      height: ({iconSize}) => (iconSize ? iconSize : '25px'),
    },
  },
  iconText: {
    paddingLeft: '8px',
    paddingRight: '20px',
  },
  networkFieldBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  networkCount: {
    fontWeight: 500,
    fontSize: '14px',
    lineWeight: '22px',
    padding: '8px 0 0 0',
    color: colors.primaryDark,
  },
  networkCountDesktop: {
    fontWeight: 600,
    fontSize: '16px',
    lineWeight: '22px',
    padding: '0 0 0 8px',
    color: colors.primaryDark,
    width: '64px',
  },
  followersText: {
    margin: '10px 0 16px 0',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '22px',
  },
}));

export const SocialNetworksInfo: FC<SocialNetworksInfoProps> = ({
  artist,
  isShort = false,
  withoutBackground = false,
  iconSize = '25px',
  centered = false,
  noTitle = false,
}): ReactElement => {
  const {t} = useLocalization();
  const isDesktop = useMediaQuery<Theme>((theme) => `${theme.breakpoints.up('lg')}`);
  const isMobile = useMediaQuery<Theme>((theme) => `${theme.breakpoints.down('sm')}`);
  const classes = useStyles({isShort, isDesktop, isMobile, withoutBackground, iconSize, centered});
  const socialNetworksToShow = SOCIAL_NETWORKS.filter(({fieldName}) => artist?.[fieldName]);

  if (socialNetworksToShow.length === 0) {
    return <></>;
  }

  return (
    <Box className={classes.container}>
      <Box mr={isDesktop && !isShort && !centered ? '92px' : undefined}>
        {!isMobile && !isShort && !noTitle && (
          <Typography className={classes.followersText}>{t('Followers')}</Typography>
        )}
        <Box className={classes.list}>
          {socialNetworksToShow.map((network) => (
            <Box key={network.fieldName} className={classes.networkFieldBox}>
              <Icon icon={<Box className={classes.icon}>{network.icon}</Box>} />
              <Box>
                <Typography className={classes.networkCount}>
                  {formatSocialCounterCount(artist[network.fieldName])}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};
