import {Link} from 'react-router-dom';
import {Typography} from '@cere/rxb-template-ui-kit';
import {Box} from '@material-ui/core';
import {makeStyles, Theme} from '@material-ui/core';
import clsx from 'clsx';

import {ReactComponent as FacebookIcon} from '../../../assets/social/ic-facebook-filled.svg';
import {ReactComponent as InstagramIcon} from '../../../assets/social/ic-instagram-filled.svg';
import {ReactComponent as TwitterIcon} from '../../../assets/social/ic-twitter-filled.svg';
import {ReactComponent as DiscordIcon} from '../../../assets/social/ic-discord-filled.svg';
import {ReactComponent as HelpIcon} from '../../../assets/help.svg';
import {useContext} from 'react';
import {AppContext} from '../../../context/app-context';
import {LanguageSelect} from '../LanguageSelect';
import {useLocalization} from '../../hooks/use-locale.hook';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    background: theme.palette.grey[100],
    color: theme.palette.grey[700],
    padding: '40px 0',

    [theme.breakpoints.up('md')]: {
      padding: '49px 0',
    },
    [theme.breakpoints.up('lg')]: {
      padding: '65px 0 49px',
    },
  },
  content: {
    padding: '0 16px',

    [theme.breakpoints.up('md')]: {
      padding: '0 40px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    [theme.breakpoints.up('lg')]: {
      padding: '0 88px',
    },
  },
  socialIcon: {
    marginRight: '24px',
  },
  contentTop: {
    paddingBottom: '24px',

    [theme.breakpoints.up('md')]: {
      paddingBottom: '32px',
    },
  },
  contentBottom: {
    paddingTop: '24px',
    borderTop: `1px solid ${theme.palette.background.default}`,

    [theme.breakpoints.up('md')]: {
      paddingTop: '32px',
    },
  },
  firstLine: {
    [theme.breakpoints.down('md')]: {
      marginBottom: '16px',
    },
    [theme.breakpoints.up('md')]: {
      paddingRight: '40px',
    },
  },
  logo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  logoImg: {
    height: '30px',
  },
  helpFeedback: {
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',
    paddingLeft: '9px',
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.primary.main,
    margin: 'auto 0',
  },
  helpBlock: {
    margin: '24px 0 8px 0',

    [theme.breakpoints.up('md')]: {
      margin: 'unset',
      paddingRight: '40px',
    },
  },
  languageSelect: {
    margin: '24px 0 8px 0',

    [theme.breakpoints.up('md')]: {
      margin: 'unset',
      paddingRight: '40px',
    },
  },
  experiencePlatform: {
    order: 2,
  },
  footerInfo: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      alignItems: 'center',
    },
  },
}));

export const Footer = () => {
  const classes = useStyles();
  const {appConfig} = useContext(AppContext);
  const {t, locale} = useLocalization();

  return (
    <Box className={classes.root}>
      <Box className={clsx(classes.content, classes.contentTop)}>
        <Box
          component={Link}
          {...{to: `/${locale}/home`}}
          display="flex"
          alignItems="center"
          className={classes.firstLine}
        >
          <Box className={classes.logo}>
            <img src={appConfig.logoFull?.url || ''} alt="logo" className={classes.logoImg} />
          </Box>
        </Box>
        <Box className={classes.footerInfo}>
          <Typography variant="h4" className={classes.experiencePlatform}>
            {t('The NFT Experience Platform')}
          </Typography>
          <Box className={classes.languageSelect}>
            <LanguageSelect />
          </Box>
          <Box className={classes.helpBlock}>
            <a className={classes.link} href={appConfig.faqUrl}>
              <HelpIcon />
              <span className={classes.helpFeedback}>{t('Help & Feedback')}</span>
            </a>
          </Box>
        </Box>
      </Box>
      <Box className={clsx(classes.content, classes.contentBottom)}>
        <Box className={classes.firstLine}>
          <Typography>{`©${new Date().getFullYear()} ${appConfig.appTitle}. All rights reserved`}</Typography>
        </Box>

        <Box>
          <Typography component="a" href={appConfig.instagramUrl} target="_blank" className={classes.socialIcon}>
            <InstagramIcon />
          </Typography>
          <Typography component="a" href={appConfig.facebookUrl} target="_blank" className={classes.socialIcon}>
            <FacebookIcon />
          </Typography>
          <Typography component="a" href={appConfig.twitterUrl} target="_blank" className={classes.socialIcon}>
            <TwitterIcon />
          </Typography>
          <Typography component="a" href={appConfig.discordUrl} target="_blank">
            <DiscordIcon />
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
