import {memo, useCallback} from 'react';
import {Box, Button, Typography, Divider, makeStyles} from '@material-ui/core';
import {useTranslation} from 'react-i18next';

import {NftModalValueBlock} from '../../../primitives/NftModalValueBlock';
import {CopyBox} from '../../../primitives/CopyBox';
import {StyledLink} from '../../../primitives/StyledLink';
import {ImageSquare} from '../../../primitives/ImageSquare';
import {mobileLandscapeMediaQuery} from '../../../../styles/mediaQueries';

import {ReactComponent as SuccessIcon} from '../assets/success.svg';
import {PurchaseModalBox} from '../PurchaseModalBox';
import {PurchaseModalBoxInner} from '../PurchaseModalBoxInner';
import {transformTokensToPrice} from '../../../../utils/helpers/price';

const useStyles = makeStyles((theme) => ({
  nftBox: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: '8px',
  },
  nftNameTitle: {
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '22px',
    color: theme.palette.grey[700],
    paddingBottom: '4px',
  },
  nftName: {
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '22px',
  },
  nftImage: {
    minWidth: '50px',
    maxWidth: '50px',
    borderRadius: '6px',
    overflow: 'hidden',
  },
  button: {
    width: '311px',
    textTransform: 'none',
    borderColor: theme.palette.text.primary,
    [theme.breakpoints.up('md')]: {
      height: '44px',
    },
  },
  hideOnMobileLandscape: {
    [mobileLandscapeMediaQuery(theme)]: {
      display: 'none',
    },
  },
  netSalesTitle: {
    color: theme.palette.text.primary,
  },
}));

type Props = {
  image: string;
  title: string;
  nftLink: string;
  nftAddress: string;
  priceUSDC: number;
  royalties: number;
  onClose: () => void;
};

export const SellSuccessModal = memo(({image, title, nftLink, nftAddress, priceUSDC, royalties, onClose}: Props) => {
  const {t} = useTranslation();
  const styles = useStyles();

  const netSales = priceUSDC - priceUSDC * royalties;

  const renderNftCardAndUserWallet = useCallback(
    () => (
      <>
        <StyledLink to={nftLink}>
          <Box className={styles.nftBox}>
            <Box>
              <Typography className={styles.nftNameTitle}>{t('NFT name')}</Typography>
              <Typography className={styles.nftName}>{title}</Typography>
            </Box>
            <Box className={styles.nftImage}>
              <ImageSquare image={image} />
            </Box>
          </Box>
        </StyledLink>
        <Box>
          <CopyBox title={t('NFT ID')} copiedText={nftAddress} />
        </Box>
      </>
    ),
    [title, image, nftAddress, nftLink, styles, t],
  );

  const renderNftPriceInfo = useCallback(
    () => (
      <>
        <Divider className={styles.hideOnMobileLandscape} />
        <Box pb="8px" pt="16px">
          <NftModalValueBlock title={t('Quantity')} value="1" />
        </Box>
        <Divider />
        <Box pb="8px" pt="16px">
          <NftModalValueBlock
            title={t('Listing price')}
            value={`${transformTokensToPrice(priceUSDC).toFixed(2)} USDC`}
          />
        </Box>

        <Divider />
        <Box pb="8px" pt="16px">
          <NftModalValueBlock title={t('Royalties')} value={`${royalties.toFixed(2)} USDC`} />
        </Box>

        <Divider />
        <Box pb="8px" pt="16px">
          <NftModalValueBlock
            title={t('Net sales')}
            value={`${transformTokensToPrice(netSales).toFixed(2)} USDC `}
            subValue={`(~${transformTokensToPrice(netSales).toFixed(2)} USD)`}
            classes={{title: styles.netSalesTitle}}
          />
        </Box>
      </>
    ),
    [priceUSDC, royalties, netSales, styles, t],
  );

  const renderButtons = useCallback(
    () => (
      <Button variant="outlined" className={styles.button} onClick={onClose}>
        {t('Ok')}
      </Button>
    ),
    [onClose, styles, t],
  );

  return (
    <PurchaseModalBox
      icon={<SuccessIcon />}
      title={t('Listing confirmed')}
      subTitle={t('Your NFT is now listed on the marketplace')}
      onClose={onClose}
    >
      <PurchaseModalBoxInner
        renderNftCardAndUserWallet={renderNftCardAndUserWallet}
        renderNftPriceInfo={renderNftPriceInfo}
        renderButtons={renderButtons}
      />
    </PurchaseModalBox>
  );
});
