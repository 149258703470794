import {ReactElement, memo} from 'react';
import {Box, makeStyles} from '@material-ui/core';

import {PageHeader} from '../PageHeader';
import {PageFooter} from '../PageFooter';
import type {HeaderNavLinkType} from '../HeaderControl/types';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.default,
  },
  pageInner: {
    flexGrow: 2,
  },
  searchInputBox: {
    position: 'relative',
    width: 'fit-content',
  },
  searchInputBoxHidden: {
    opacity: 0,
  },
}));

type Props = {
  children: ReactElement | ReactElement[];
  hideBackButton?: boolean;
  renderHeaderSearch?: () => ReactElement;
  renderHeaderNotifications?: () => ReactElement;
  renderHeaderUserControl?: (navLinks: HeaderNavLinkType[]) => ReactElement;
  renderFooterSelect?: () => ReactElement;
};

export const PageLayout = memo(
  ({
    children,
    hideBackButton,
    renderHeaderSearch,
    renderHeaderNotifications,
    renderHeaderUserControl,
    renderFooterSelect,
  }: Props) => {
    const styles = useStyles();

    return (
      <>
        <Box className={styles.root}>
          <PageHeader
            hideBackButton={hideBackButton}
            renderHeaderSearch={renderHeaderSearch}
            renderHeaderNotifications={renderHeaderNotifications}
            renderHeaderUserControl={renderHeaderUserControl}
          />

          <Box className={styles.pageInner}>{children}</Box>

          <PageFooter renderFooterSelect={renderFooterSelect} />
        </Box>
      </>
    );
  },
);
