import {useContext} from 'react';
import {EventTimelineStatus} from '../../../../../shared/types/event';
import {isAuctionedNft} from '../../../../../shared/helpers/auction';
import {
  getAuctionedNftsByStatus,
  getSeparatedAccessOrLimitedNftsByAvailability,
  NftSectionType,
} from '../../../../../shared/services/nft.service';
import {ExhibitContext} from '../../../../../context/exhibition/exhibit-context';
import {useUserHasEventAccess} from '../../../use-user-has-event-access';
import {UserPaymentHistoryContext} from '../../../../../context/payments-history/user-payment-history.context';
import {AuctionsContext} from '../../../../../context/auctions-context';
import {getIsPaymentPending, getIsPaymentSuccess} from '../../../../../shared/helpers/paymentStatus';
import {usePendingTransactionsContext} from '../../../../../context/pending-transaction-context';
import {CmsExhibitionNftAccess, CmsExhibitionNftAuctioned} from '../../../../../shared/types/graphql';

export function useGetTopCardsSorted(isListMode: boolean) {
  const {eventTimelineStatus, exclusiveNfts, accessNfts} = useContext(ExhibitContext);
  const hasEventAccess = useUserHasEventAccess();
  const {getNftIsPurchased, getPaymentStatuses} = useContext(UserPaymentHistoryContext);
  const {auctionNfts: auctions} = useContext(AuctionsContext);
  const {getPendingTransactionForNft} = usePendingTransactionsContext();

  const purchasedAccessNfts = accessNfts.filter(
    (nft) =>
      getNftIsPurchased(nft.cmsNft?.creatorNft?.nft_id) ||
      getIsPaymentSuccess(...getPaymentStatuses(nft.cmsNft?.creatorNft?.nft_id)),
  );
  const notPurchasedAccessNfts = accessNfts.filter(
    (nft) =>
      !getNftIsPurchased(nft.cmsNft?.creatorNft?.nft_id) &&
      !getIsPaymentSuccess(...getPaymentStatuses(nft.cmsNft?.creatorNft?.nft_id)) &&
      !getIsPaymentPending(...getPaymentStatuses(nft.cmsNft?.creatorNft?.nft_id)),
  );

  if (
    eventTimelineStatus === EventTimelineStatus.FINISHED ||
    (eventTimelineStatus === EventTimelineStatus.STARTED && hasEventAccess)
  ) {
    const auctionedNfts = exclusiveNfts.filter(isAuctionedNft);
    const purchasedAuctionNfts = auctionedNfts.filter((nft) => getNftIsPurchased(nft.cmsNft?.creatorNft?.nft_id));
    const notPurchasedAuctionNfts = auctionedNfts.filter((nft) => !getNftIsPurchased(nft.cmsNft?.creatorNft?.nft_id));
    const topCardsNfts: Array<CmsExhibitionNftAuctioned | CmsExhibitionNftAccess> = [
      ...purchasedAuctionNfts,
      ...getAuctionedNftsByStatus(notPurchasedAuctionNfts, auctions),
    ];
    const pendingPurchasedAccessNft = purchasedAccessNfts.find((nft) =>
      Boolean(getPendingTransactionForNft(nft.cmsNft?.creatorNft?.nft_id)),
    );

    if (pendingPurchasedAccessNft) {
      topCardsNfts.unshift(pendingPurchasedAccessNft);
    }

    return topCardsNfts;
  }
  const sepatatedNfts = getSeparatedAccessOrLimitedNftsByAvailability(notPurchasedAccessNfts);

  if (eventTimelineStatus === EventTimelineStatus.NOT_STARTED) {
    return getSeparatedAccessOrLimitedNftsByAvailability(notPurchasedAccessNfts)[NftSectionType.ONGOING];
  }

  if (!isListMode) {
    return [
      ...purchasedAccessNfts,
      ...sepatatedNfts[NftSectionType.ONGOING],
      ...sepatatedNfts[NftSectionType.COMING_SOON],
      ...sepatatedNfts[NftSectionType.SOLDOUT],
      ...sepatatedNfts[NftSectionType.PASSED],
    ];
  }

  return [
    ...purchasedAccessNfts,
    ...getSeparatedAccessOrLimitedNftsByAvailability(notPurchasedAccessNfts)[NftSectionType.ONGOING],
  ];
}
