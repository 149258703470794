import {useCallback, useContext, useEffect, useState} from 'react';
import analyticService, {AnalyticEventsEnum} from '../services/analytic.service';
import {PendingTransactionTypes, usePendingTransactionsContext} from 'context/pending-transaction-context';
import {getPopupUrlByEmailAndNftId} from '../services/payment.service';
import {UserContext} from '../../context/user-context/user-context';
import {useSelectedWallet} from 'context/use-selected-wallet';
import {usePurchaseWithNonCustody} from './purchase.hook';
import {useSingletonPollNftStatus} from './nft-status-poll.hook';
import {TOKEN_DECIMALS} from '../lib/formatPrice';
import {PaymentStatusEnum} from '@cere/services-types';
import {NftPurchaseParams} from '../components/WithPurchaseNft/v2';
import {getIsPaymentFailed, getIsPaymentSuccess} from '../helpers/paymentStatus';
import {useLocalization} from './use-locale.hook';

interface WithPurchaseNftProps {
  purchaseData: NftPurchaseParams | null;
}

export enum PurchaseNFTModalTypes {
  LIMITED_TICKET_PURCHASE_MODAL = 'LIMITED_TICKET_PURCHASE_MODAL',
  PURCHASE_CONFIRMED_MODAL = 'PURCHASE_CONFIRMED_MODAL',
  PURCHASE_FAIL_MODAL = 'PURCHASE_FAIL_MODAL',
}

export const useWithPurchaseLimitedNft = ({purchaseData}: WithPurchaseNftProps) => {
  const {t, locale} = useLocalization();
  const {setPendingTransactionForNft, clearPendingTransactionForNft} = usePendingTransactionsContext();

  const {userData} = useContext(UserContext);

  const selectedWallet = useSelectedWallet();
  const {setPollingEnabled, statusesByNftId, sessionParamsById} = useSingletonPollNftStatus();
  const {checkAndShowSwitchNetworkDialog, handleNonCustodyPurchase, purchaseSuccess, purchaseError} =
    usePurchaseWithNonCustody();

  const [isPurchaseLoading, setIsPurchaseLoading] = useState<boolean>(false);
  const [purchaseCatchError, setPurchaseCatchError] = useState<string>('');
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [modalType, setModalType] = useState<PurchaseNFTModalTypes>(
    PurchaseNFTModalTypes.LIMITED_TICKET_PURCHASE_MODAL,
  );

  const openPurchaseLimitedModal = () => {
    setModalType(PurchaseNFTModalTypes.LIMITED_TICKET_PURCHASE_MODAL);
  };
  const openPurchaseSuccessModal = () => {
    setModalType(PurchaseNFTModalTypes.PURCHASE_CONFIRMED_MODAL);
  };
  const openPurchaseFailureModal = () => {
    setModalType(PurchaseNFTModalTypes.PURCHASE_FAIL_MODAL);
  };

  const closeModal = () => {
    analyticService.track(AnalyticEventsEnum.EXHIBIT_WALLET_CLOSE_BUTTON_CLICKED);

    setIsModalOpen(false);
  };

  const buy = useCallback(() => {
    openPurchaseLimitedModal();
    setIsModalOpen(true);
  }, []);

  const finishPurchase = useCallback(() => {
    setIsPurchaseLoading(false);
    clearPendingTransactionForNft(purchaseData!.nftId);
  }, [clearPendingTransactionForNft, purchaseData]);

  const handleBuyLimitedNft = useCallback(async () => {
    try {
      setIsPurchaseLoading(true);
      setPollingEnabled(true);
      const priceInTokens = purchaseData!.nftUSDPrice * TOKEN_DECIMALS;

      const isCorrectNetworkSelected = await checkAndShowSwitchNetworkDialog();
      if (!isCorrectNetworkSelected) {
        setPurchaseCatchError(t('Wrong network selected!'));
        closeModal();
        return;
      }

      await handleNonCustodyPurchase(
        purchaseData!.minter,
        purchaseData!.nftAddress,
        priceInTokens,
        1,
        purchaseData!.collectionAddress,
      );
      await setPendingTransactionForNft({
        wallet: selectedWallet.publicKey || '',
        type: PendingTransactionTypes.BUY_NFT,
        nftId: purchaseData!.nftAddress ?? '',
      });
      openPurchaseSuccessModal();
    } catch (error) {
      setPurchaseCatchError(error.message);
      console.error(error);
      openPurchaseFailureModal();
    } finally {
      setPollingEnabled(false);
      finishPurchase();
    }
  }, [
    checkAndShowSwitchNetworkDialog,
    handleNonCustodyPurchase,
    setPendingTransactionForNft,
    setPollingEnabled,
    finishPurchase,
    purchaseData,
    selectedWallet?.publicKey,
    t,
  ]);

  useEffect(() => {
    const nftId = purchaseData?.nftAddress ?? '';
    if (statusesByNftId && statusesByNftId[nftId]) {
      const status = statusesByNftId[nftId];
      if (getIsPaymentFailed(status)) {
        setIsPurchaseLoading(false);
        setPollingEnabled(false);
        clearPendingTransactionForNft(nftId);
        openPurchaseFailureModal();
      } else if (getIsPaymentSuccess(status)) {
        closeModal();
        setPollingEnabled(false);
        setIsPurchaseLoading(false);
        clearPendingTransactionForNft(nftId);
      }
    }
  }, [
    clearPendingTransactionForNft,
    setPollingEnabled,
    setIsPurchaseLoading,
    purchaseData?.nftAddress,
    statusesByNftId,
  ]);

  const handleBuyNftByCard = useCallback(async () => {
    let win;

    try {
      setIsPurchaseLoading(true);
      setPollingEnabled(true);
      const nftTitle = purchaseData?.nftTitle || '';
      const artistName = purchaseData?.creatorName || '';
      const nftId = purchaseData?.nftAddress ?? '';
      win = window.open('', t('Pay by card'), 'left=100,top=100,width=800,height=600');

      let url;
      const status = statusesByNftId[nftId];
      if (status === PaymentStatusEnum.FIAT_PAYMENT_PENDING) {
        url = sessionParamsById[nftId].paymentUrl;
      } else {
        url = await getPopupUrlByEmailAndNftId({
          buyerEmail: userData.userEmail ?? '',
          nftId: purchaseData!.nftAddress,
          quantity: 1,
          locale,
        });
      }

      if (url) {
        analyticService.track(AnalyticEventsEnum.CHECKOUT_STARTED, {
          nftId,
          nftTitle,
          artistName,
        });
        await win?.location.replace(url);

        await setPendingTransactionForNft({
          wallet: selectedWallet.publicKey || '',
          type: PendingTransactionTypes.BUY_NFT,
          nftId: purchaseData!.nftAddress ?? '',
        });
      }
    } catch (error) {
      win?.close();
      setPurchaseCatchError(error.message);
      openPurchaseFailureModal();
      setIsPurchaseLoading(false);
      console.error(error);
    }
  }, [
    t,
    purchaseData,
    sessionParamsById,
    statusesByNftId,
    userData.userEmail,
    selectedWallet.publicKey,
    setPollingEnabled,
    setPendingTransactionForNft,
    locale,
  ]);

  return {
    buy,
    isPurchaseLoading,
    isPurchaseModalOpen: isModalOpen && modalType === PurchaseNFTModalTypes.LIMITED_TICKET_PURCHASE_MODAL,
    isPurchaseSuccessModalOpen:
      isModalOpen && purchaseSuccess && modalType === PurchaseNFTModalTypes.PURCHASE_CONFIRMED_MODAL,
    isPurchaseFailureModalOpen:
      isModalOpen && Boolean(purchaseError) && modalType === PurchaseNFTModalTypes.PURCHASE_FAIL_MODAL,
    purchaseError: purchaseError || purchaseCatchError,
    closeLimitedModal: closeModal,
    handleBuyLimitedNft,
    handleBuyNftByCard,
  };
};
