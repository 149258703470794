import {TextField} from '../../UI';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {useState} from 'react';
import {Box, makeStyles, Popper} from '@material-ui/core';
import {useLocalization} from '../../../../shared/hooks/use-locale.hook';
import {ReactComponent as WarningIcon} from '../../../../assets/icons/warn.svg';
import {BUY_ONLY_ONE_NFT_AT_TIME} from '../../../../config/common';

interface QuantityFieldProps {
  label?: string;
  maxValue: number;
  onValueChange: (value: number) => void;
  variant?: 'outlined' | 'standard' | 'filled';
  className?: string;
  quantity?: number;
  isModal?: boolean;
}

export const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiOutlinedInput-root': {
      border: 'unset',
      backgroundColor: theme.palette.common.white,
      width: '214px',
      [theme.breakpoints.up('lg')]: {
        width: '278px',
      },
    },
  },
  notification: {
    marginTop: '-8px',
    zIndex: 1000,
    background: theme.palette.grey[100],
    border: `1px solid ${theme.palette.grey[200]}`,
    borderRadius: '0px 0px 12px 12px',
    padding: '15px 14px',
    '& span': {
      fontWeight: 400,
      fontSize: '12px',
      linHheight: '16px',
    },
  },
  listingsText: {
    color: theme.palette.secondary.main,
  },
}));

const generate = (val: number, maxValue: number): number[] => {
  const items: number[] = Array.from(Array(5)).map((_, i) => val + i - 2);
  const additional = 10 ** val.toString().length;
  let ret = [val, ...items, additional, additional * 5, additional * 10, maxValue];
  ret = Array.from(new Set(ret)).sort((a, b) => a - b);
  ret = ret.filter((v: number) => v > 0 && v < maxValue);
  return ret;
};

export const QuantityField = ({
  label,
  maxValue,
  onValueChange,
  variant = 'standard',
  className,
  quantity,
  isModal = true,
}: QuantityFieldProps) => {
  const {t} = useLocalization();
  const [options, setOptions] = useState<number[]>(generate(1, maxValue));
  const [inputValue, setInputValue] = useState<string | undefined>('1');
  const [value, setValue] = useState<number | undefined>(quantity || 1);
  const disabled = BUY_ONLY_ONE_NFT_AT_TIME || maxValue === 1;
  const classes = useStyles();

  const changeInputHandler = (val: number | string | undefined) => {
    const re = /^\d{0,10}$/;
    if (!re.test(`${val}`)) {
      return;
    }
    if (val) {
      setOptions(generate(+val, maxValue));
      setInputValue(String(val));
      setValue(+val);
      onValueChange(+val);
    } else {
      setInputValue(undefined);
      setValue(undefined);
    }
  };

  return (
    <Autocomplete
      options={options}
      getOptionLabel={(option) => String(option)}
      filterOptions={(opts) => opts}
      value={value}
      inputValue={inputValue}
      multiple={false}
      disabled={disabled}
      disableClearable={true}
      onChange={(_, v) => changeInputHandler(+v)}
      onInputChange={(_, v) => changeInputHandler(v)}
      renderInput={(params) => (
        <TextField className={className} variant={variant} {...params} label={label} inputMode={'numeric'} />
      )}
      classes={!isModal ? {root: classes.root} : undefined}
      PopperComponent={(props) => (
        <Popper {...props}>
          {props.children}
          {!isModal ? (
            <Box className={classes.notification}>
              <WarningIcon />
              <span> {t('Want to buy more copies? Go to ')} </span>
              <span className={classes.listingsText}> {t('listings')} </span>
            </Box>
          ) : null}
        </Popper>
      )}
    />
  );
};
