import {MouseEventHandler, ReactNode, useContext, useMemo} from 'react';
import {Box, Card, makeStyles, CardMedia, Typography, Theme} from '@material-ui/core';
import {noop} from 'lodash';
import clsx from 'clsx';
import dayjs from 'dayjs';

import colors from '../../../styles/colors';
import {NftContext} from '../Nft/nft-context-v2';
import {AvailabilityStatus} from '@cere/services-types';

const useStyles = makeStyles<
  Theme,
  {
    isComingSoon: boolean;
    image?: string;
  }
>(() => ({
  root: {
    borderRadius: '12px',
    boxShadow: 'none',
    padding: '16px',
    background: 'rgba(22, 22, 22, 0.6)',
    backdropFilter: 'blur(66px)',
    display: 'flex',
    color: colors.light,
    flexWrap: 'wrap',
  },
  media: {
    height: '210px',
    width: '180px',
    marginRight: '16px',
    borderRadius: '12px',
    flex: '0 0 180px',
    position: 'relative',
    overflow: 'hidden',
    backgroundImage: 'unset !important',

    '&:after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      width: '100%',
      height: '100%',
      bottom: 0,
      borderRadius: '12px',
      backgroundImage: ({image}) => `url(${image})`,
      backgroundSize: 'cover',
      filter: ({isComingSoon}) => (isComingSoon ? 'blur(1.3rem)' : 'unset'),
      zIndex: 10,
    },

    '@media (max-width:576px)': {
      width: '168px',
      height: '195px',
      marginRight: '12px',
      flexBasis: '168px',
    },
  },
  content: {
    flex: '1 1 100%',
  },
  subtitle: {
    display: 'block',
    marginTop: '5px',
  },
  bottomWrapper: {
    flex: '0 0 100%',
  },
  description: {
    color: colors.grey,

    '& .MuiTypography-root': {
      color: 'inherit',
    },
  },
  caption: {
    fontSize: '12px',
  },
  title: {
    wordBreak: 'break-all',
    fontSize: '20px',
    fontWeight: 'bold',
    lineHeight: 1.167,
  },
  comingSoon: {
    overflow: 'hidden',
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#fff',
    fontWeight: 700,
    zIndex: 100,

    '& span': {
      fontSize: '1.2rem',
    },
  },
}));

type AuctionCardLargeBaseProps = {
  title: string;
  subtitle?: string;
  imgSrc: string | undefined;
  onCardClick?: MouseEventHandler;
  className?: string;
  contentClassName: string;
  children?: ReactNode;
  contentBottom?: ReactNode;
  mediaContent: ReactNode;
  description?: string;
  descriptionClassName?: string;
  onVisualClick?: MouseEventHandler;
};

const AuctionCardLargeBase = ({
  title,
  subtitle,
  imgSrc,
  onCardClick = noop,
  className,
  contentClassName,
  children,
  contentBottom,
  mediaContent,
  description,
  descriptionClassName,
  onVisualClick = noop,
}: AuctionCardLargeBaseProps) => {
  const {nft} = useContext(NftContext);

  const isComingSoon = useMemo(() => {
    if (nft.availability === AvailabilityStatus.COMING_SOON || nft.isComingSoon) {
      return true;
    }
    return nft.isComingSoon ?? false;
  }, [nft]);

  const isComingSoonCaption = useMemo(() => {
    if (nft.availability === AvailabilityStatus.COMING_SOON || nft.isComingSoon) {
      return dayjs(nft.availableFrom).format('MM.DD.YYYY');
    }
  }, [nft]);

  const isFullLoaded = nft.nftType != null;

  const classes = useStyles({
    isComingSoon: Boolean(nft.isComingSoon),
    image: nft.image,
  });

  return (
    <Card className={clsx(classes.root, className)} onClick={onCardClick}>
      <Box display="flex" width="100%">
        <CardMedia className={classes.media} image={imgSrc} onClick={onVisualClick}>
          {!!mediaContent && mediaContent}
          {isComingSoon && isFullLoaded && (
            <div className={classes.comingSoon}>
              Coming soon
              {isComingSoonCaption && <span>{isComingSoonCaption}</span>}
            </div>
          )}
        </CardMedia>
        <Box className={clsx(classes.content, contentClassName)}>
          <Box>
            <Typography className={classes.title}>{title}</Typography>
            {!isComingSoon && <Typography className={clsx(classes.subtitle, classes.caption)}>{subtitle}</Typography>}
          </Box>
          {description && (
            <Box className={classes.description}>
              <Typography className={clsx(descriptionClassName, classes.caption)}>{description}</Typography>
            </Box>
          )}
          {children}
        </Box>
      </Box>
      {!!contentBottom && <Box className={classes.bottomWrapper}>{contentBottom}</Box>}
    </Card>
  );
};

export {AuctionCardLargeBase};
