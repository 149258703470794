import React from 'react';
import clsx from 'clsx';
import {Box, Dialog, Typography, makeStyles} from '@material-ui/core';
import colors from '../../../styles/colors';
import {CloseButton} from '../CloseButton';
import {mobileLandscapeMediaQuery} from '../../../styles/mediaQueries';

export interface NFTModalProps {
  open: boolean;
  title: string;
  icon?: React.ReactElement;
  classes?: Partial<Record<'title' | 'header' | 'modalContainer' | 'iconContainer', string>>;
  onClose: (event?: any) => void;
}

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiDialog-paper': {
      padding: '24px 1rem',
      backgroundColor: colors.light,
      [`${theme.breakpoints.up('md')}`]: {
        borderRadius: '12px',
        width: '448px',
      },
      [theme.breakpoints.down('sm')]: {
        minWidth: '100%',
        minHeight: '100%',
      },
      [mobileLandscapeMediaQuery(theme)]: {
        minWidth: '100%',
        minHeight: '100%',
        borderRadius: '0',
        width: 'unset',
      },
    },
  },
  header: {
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    [mobileLandscapeMediaQuery(theme)]: {
      flexDirection: 'row',
      justifyContent: 'flex-start',
    },
  },
  closeButtonContainer: {
    display: 'flex',
    position: 'absolute',
    right: 4,
    top: 0,
  },
  title: {
    fontWeight: 700,
    fontSize: '20px',
    lineHeight: '26px',
    textAlign: 'center',
    fontFamily: 'inherit',
    letterSpacing: 'inherit',
  },
  iconContainer: {
    marginTop: '36px',
    marginBottom: '8px',
    marginRight: 0,
    [mobileLandscapeMediaQuery(theme)]: {
      margin: '0 12px 0 0 ',
    },
  },
}));

export const PurchaseModal: React.FC<NFTModalProps> = ({children, open, title, icon, classes, onClose}) => {
  const styles = useStyles();
  return (
    <Dialog className={clsx(styles.root, classes?.modalContainer)} open={open} onClose={onClose}>
      <Box className={clsx(styles.header, classes?.header)}>
        <Box className={styles.closeButtonContainer}>
          <CloseButton onClick={onClose} />
        </Box>
        {icon && <Box className={clsx(styles.iconContainer, classes?.iconContainer)}>{icon}</Box>}
        <Typography className={clsx(styles.title, classes?.title)} variant="h3">
          {title}
        </Typography>
      </Box>
      {children}
    </Dialog>
  );
};
