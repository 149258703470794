const colors = {
  'text-primary': '#0065f2',
  header: '#0065f2',
  'block-background': '#e4f1fd',
  'button-background-active': '#0065f2',
  'button-background-inactive': '#e4f1fd',
  'border-color': '#0065f2',
  'input-background': '#F3F7FE',
  'navbar-background': '#F3F7FE',
  'navbar-text': '#007AFF',
  blue: '#42A4FF',
  white: '#F5F5F7',
  accent: '#FA0C58',
  accentDark: '#cb1451',
  buttonSecondary: '#B01C63',
  light: '#F8F8FA',
  lighter: '#E0E0E7',
  green: '#1ACC97',
  cyan: '#3CBCAC',
  primaryDark: '#161616',
  secondaryDark: '#454545',
  grey: '#717178',
  'background-grey': '#E8E8ED',
  lightGrey: '#6E6E79',
  disable: '#8C8C94',
  lightBackground: '#EAEAEF',
  error: '#FF5151',
  footer: '#F0F0F7',
  scrolledHeader: '#F5F5F7F0',
  red: '#FF0000',
  snowWhite: '#ffffff',
  black: '#000000',
  darkYellow: '#E6C05D',
  warningYellow: '#E9A8011A',
  purple: '#7F38F3',
};

export default colors;
