import {makeStyles, Theme} from '@material-ui/core';

export const useStyles = makeStyles<Theme, {nftImage?: string}>((theme) => ({
  title: {
    whiteSpace: 'break-spaces',
    fontSize: '20px',
    marginBottom: '.3em',

    [theme.breakpoints.up('md')]: {
      fontSize: '30px',
    },

    '& svg': {
      cursor: 'pointer',
      width: '17px',
      height: '17px',

      '& path': {
        fill: theme.palette.info.main,
      },
    },
  },
  ticketBlock: {
    marginTop: '1.2rem',
    width: '328px',
  },
  ticketLink: {
    textDecoration: 'none',
    color: 'inherit',
  },
  ticketBox: {
    '--height': '130px',
    '--width': '328px',
    width: '328px',
    height: 'var(--height)',
    display: 'grid',
    alignItems: 'stretch',
    gridTemplateColumns: 'var(--height) 1fr',
    borderRadius: '.75rem',
    boxShadow: '0 4px 20px rgba(0, 0, 0, .12)',
    overflow: 'hidden',
  },
  ticketImage: {
    width: 'var(--height)',
    height: 'var(--height)',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    backgroundImage: ({nftImage}) => nftImage && `url(${nftImage})`,
  },
  ticketInfo: {
    display: 'grid',
    gridTemplateRows: '1fr max-content',

    '& h3, & p': {
      paddingLeft: '.5rem',
      marginTop: '0.5rem',
      fontSize: '0.8rem',
    },
  },
  ticketInfoBottom: {
    display: 'flex',
    alignItems: 'baseline',
    gap: '0.5rem',
    paddingBottom: '0.3rem',
    background: theme.palette.info.main,
    color: '#fff',

    '& img': {
      '--size': '17px',
      position: 'relative',
      top: '.15rem',
      width: 'var(--size)',
      height: 'var(--size)',
      objectFit: 'cover',
    },
  },
}));
