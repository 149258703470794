import {useRef} from 'react';
import ErrorIcon from '@material-ui/icons/Error';
import {Box, Button, CardMedia, Divider, makeStyles, Paper, Typography} from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import clsx from 'clsx';
import {PurchaseModal} from '../PurchaseModal';
import {ReactComponent as WalletIcon} from '../../../assets/icons/wallet_colored.svg';
import colors from '../../../styles/colors';
import {CopyButton} from '../CopyButton';
import {TOKEN_TITLE} from '../../lib/formatPrice';
import {NFTCTAButton} from '../NFTCTAButton';
import {useConnectNonCustodyWallet} from '../../hooks/use-connect-wallet';
import {useSelectedWallet} from '../../../context/use-selected-wallet';
import {NonCustodyWalletTypeEnum, NonCustodyWalletTitles, isNonCustodyWalletType} from '../../types/non-custody-wallet';
import {useSingletonFetchUserWalletBalance} from '../../hooks/user-balance.hook';
import {WalletBalance} from '../WalletBalance';
import {TorusWalletFundButton} from '../TorusWalletFundButton';
import {useLocalization} from '../../hooks/use-locale.hook';
import {mobileLandscapeMediaQuery} from '../../../styles/mediaQueries';

const useStyles = makeStyles((theme) => ({
  root: {
    '--media-size': '50px',
    display: 'flex',
    flexDirection: 'column',
    marginTop: '32px',
    '&.require-connect': {
      margin: 0,
    },

    [mobileLandscapeMediaQuery(theme)]: {
      flexDirection: 'row',
      gap: '20px',
    },
    [theme.breakpoints.up('md')]: {
      flexDirection: 'column',
      justifyContent: 'start',
    },
  },
  dataBox: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'end',
    [mobileLandscapeMediaQuery(theme)]: {
      width: '50%',
    },
    [theme.breakpoints.up('md')]: {
      width: '100%',
      maxWidth: 'none',
    },
  },
  button: {
    width: '208px',
    height: '36px',
    borderRadius: '50px',
    [theme.breakpoints.up('lg')]: {
      width: '200px',
      height: '44px',
    },
  },
  icon: {
    '--size': '18px',
    height: 'var(--size)',
    width: 'var(--size)',
    color: theme.palette.info.main,
  },
  dataLabel: {
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '22px',
    color: colors.grey,
    marginBottom: '4px',
    fontFamily: 'inherit',
    letterSpacing: 'inherit',
  },
  dataText: {
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '22px',
    color: colors.primaryDark,
    marginBottom: '4px',
    maxWidth: '325px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontFamily: 'inherit',
    letterSpacing: 'inherit',
  },
  dataContainer: {
    marginBottom: '8px',
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  copyButtonContainer: {
    marginBottom: '16px',
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& button, & button svg': {
      width: '20px',
      height: '20px',
    },
  },
  dataLine: {
    marginBottom: '16px',
  },
  netSales: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    '& p': {
      margin: 0,
    },
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  mediaDescriptionContainer: {
    maxWidth: 'calc(100% - var(--media-size))',
  },
  media: {
    width: 'var(--media-size)',
    height: 'var(--media-size)',
    objectFit: 'fill',
    borderRadius: '6px',
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '16px auto 0 auto',
    [`@media (orientation: landscape)`]: {
      justifyContent: 'end',
    },
    [theme.breakpoints.up('md')]: {
      justifyContent: 'center',
    },
  },
  connectWalletContainer: {
    margin: '20px 0 16px 0',
    [theme.breakpoints.up('md')]: {
      margin: '10px 0 20px 0',
    },
    [theme.breakpoints.up('lg')]: {
      margin: '24px 0 16px 0',
    },
  },
  connectWalletButton: {
    padding: 0,
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '20px',
    textTransform: 'none',
    color: theme.palette.primary.main,
    fontFamily: 'inherit',
    letterSpacing: 'inherit',
  },
  errorMessage: {
    display: 'flex',
    width: '100%',
    padding: '12px',
    background: 'rgba(255, 81, 81, 0.1)',
    borderRadius: '12px',
    marginTop: '16px',
    color: colors.error,
    fontFamily: 'inherit',
    letterSpacing: 'inherit',

    [theme.breakpoints.up('lg')]: {
      marginTop: '16px',
    },
  },
  errorText: {
    fontWeight: 500,
    fontSize: '13px',
    lineHeight: '22px',
    color: colors.error,
    marginLeft: '10px',
    fontFamily: 'inherit',
    letterSpacing: 'inherit',
  },
}));

export interface BuyNFTModalProps {
  nftTitle: string;
  nftAddress: string;
  nftImage?: string;
  price: number;
  open: boolean;
  isProcessing: boolean;
  onCancel: () => void;
  buyNft: () => Promise<void>;
}

export const BuyNFTModal = ({
  nftTitle,
  nftAddress,
  nftImage,
  price,
  open,
  isProcessing,
  onCancel,
  buyNft,
}: BuyNFTModalProps) => {
  const {t} = useLocalization();
  const styles = useStyles();
  const nftIdRef = useRef<HTMLElement | null>(null);
  const selectedWallet = useSelectedWallet();
  const {showConnectWalletModal} = useConnectNonCustodyWallet();
  const {isLoading, walletBalance} = useSingletonFetchUserWalletBalance();

  const isNonCustodyWalletConnected = isNonCustodyWalletType(selectedWallet.wallet);
  const isTorusWalletSelected = selectedWallet.wallet === NonCustodyWalletTypeEnum.TORUS;

  const isEnoughFunds = walletBalance?.usdc ? walletBalance.usdc >= price : false;
  const isButtonDisabled = isLoading || !isNonCustodyWalletConnected || !isEnoughFunds;
  const hasError = !isNonCustodyWalletConnected || !isEnoughFunds;

  return (
    <PurchaseModal open={open} icon={<WalletIcon />} title={t('Buy this NFT')} onClose={onCancel}>
      {!isNonCustodyWalletConnected && (
        <Box className={clsx(styles.dataContainer, styles.connectWalletContainer)}>
          <Button className={styles.connectWalletButton} variant="text" onClick={showConnectWalletModal}>
            {t('Connect wallet')} <ChevronRightIcon fontSize="small" />
          </Button>
        </Box>
      )}
      <Box className={clsx(styles.root, {'require-connect': !isNonCustodyWalletConnected})}>
        <Box className={styles.dataBox}>
          <Box className={clsx(styles.dataContainer, styles.dataLine)}>
            <Box className={clsx(styles.column, styles.mediaDescriptionContainer)}>
              <Typography className={styles.dataLabel}>{t('NFT name')}</Typography>
              <Typography className={styles.dataText}>{nftTitle}</Typography>
            </Box>
            <CardMedia className={styles.media} image={nftImage} />
          </Box>
          <Box className={clsx(styles.dataContainer, styles.dataLine)}>
            <Box className={styles.column}>
              <Typography className={styles.dataLabel}>{t('NFT ID')}</Typography>
              <Box className={styles.copyButtonContainer}>
                <Typography className={styles.dataText} ref={nftIdRef}>
                  {nftAddress}
                </Typography>
                <CopyButton node={nftIdRef} />
              </Box>
              <Divider />
            </Box>
          </Box>
        </Box>
        <Box className={styles.dataBox}>
          <Box className={clsx(styles.column, styles.dataLine)}>
            <Box className={styles.dataContainer}>
              <Typography className={styles.dataLabel}>{t('Quantity')}</Typography>
              <Typography className={styles.dataText}>1</Typography>
            </Box>
            <Divider />
          </Box>
          <Box className={clsx(styles.column, styles.dataLine)}>
            <Box className={styles.dataContainer}>
              <Typography className={styles.dataLabel}>{t('Price')}</Typography>
              <Typography className={styles.dataText}>{`${price} ${TOKEN_TITLE}`}</Typography>
            </Box>
            <Divider />
          </Box>
          <Box className={styles.column}>
            <Box className={styles.dataContainer}>
              <Typography className={styles.dataLabel}>{t('Total amount')}</Typography>
              <Typography className={clsx(styles.dataText, styles.netSales)}>
                {`${price} ${TOKEN_TITLE}`}{' '}
                <Typography className={styles.dataLabel}>
                  (~{price}
                  {' USD'})
                </Typography>
              </Typography>
            </Box>
            <Divider />
          </Box>
        </Box>
      </Box>

      {isNonCustodyWalletType(selectedWallet.wallet) && (
        <Box marginTop="16px">
          <WalletBalance
            balance={walletBalance?.usdc}
            caption={t('My {{title}} wallet balance', {title: NonCustodyWalletTitles[selectedWallet.wallet]})}
          />
        </Box>
      )}

      {!isLoading && hasError && (
        <>
          <Paper className={styles.errorMessage} elevation={0}>
            <ErrorIcon fontSize="small" />
            <Typography className={styles.errorText}>
              {isTorusWalletSelected
                ? t('Insufficient balance, fund your wallet')
                : t('Connect wallet with sufficient balance')}
            </Typography>
          </Paper>

          {isTorusWalletSelected && (
            <Box display="flex" justifyContent="center" marginTop="16px">
              <TorusWalletFundButton
                nftAddress={nftAddress}
                className={styles.button}
                contextInfo={{
                  price,
                  title: nftTitle,
                  description: t('You’re about to purchase:'),
                  imageUrl: nftImage!,
                }}
              />
            </Box>
          )}
        </>
      )}

      <Box className={styles.buttonContainer}>
        <NFTCTAButton
          className={styles.button}
          text={t('Buy')}
          type="submit"
          onClick={buyNft}
          loading={isProcessing}
          disabled={isButtonDisabled}
        />
      </Box>
    </PurchaseModal>
  );
};
