import {Theme, useMediaQuery} from '@material-ui/core';
import {noop} from 'lodash';
import {OverlayDetailCardPortrait} from './overlay-detail-card-portrait';
import {OverlayDetailCardLandscape} from './overlay-detail-card-landscape';
import {getBidHistoryStatus} from '../../../services/auction.service';
import analyticService, {AnalyticEventsEnum} from '../../../services/analytic.service';
import {useUserWalletsPubKeys} from '../../../../context/use-user-wallets-pub-keys';
import dayjs from 'dayjs';

import {NftBidParams} from '../../WithPurchaseNft/v2';
import {usdcUnitsToUSD} from '../../../lib/formatPrice';
import {NftCardInterface, NftType} from '@cere/services-types';

interface OverlayDetailCardProps {
  title: string;
  subtitle: string;
  description: string;
  description1: string;
  disabled?: boolean;
  onClick: (purchaseParams: NftBidParams) => void;
  handleArrowClick: Function;
  totalLength: number;
  isAuction: boolean;
  imgSrc: string;
  selectedIndex: number;
  isOpen: boolean;
  endsAt: Date;
  nft: NftCardInterface;
  buttonText: string;
  hideNavigation?: boolean;
  relType: NftType | undefined;
  hidePrice?: boolean;
}

export const OverlayDetailCard = ({
  title,
  subtitle,
  description,
  description1,
  imgSrc,
  onClick,
  disabled = false,
  isAuction = true,
  selectedIndex,
  totalLength,
  handleArrowClick = noop,
  isOpen,
  endsAt,
  nft,
  buttonText,
  relType,
  hideNavigation = true,
  hidePrice = false,
}: OverlayDetailCardProps) => {
  const handlePrevClick = () => selectedIndex && handleArrowClick(selectedIndex - 1);
  const handleNextClick = () => selectedIndex + 1 < totalLength && handleArrowClick(selectedIndex + 1);

  const userWallets = useUserWalletsPubKeys();

  const isBadge = userWallets.includes(nft?.auction?.buyer ?? '');

  const endDate: Date = isAuction ? dayjs(nft?.auction?.endsAt).toDate() : endsAt;

  const isMobile = useMediaQuery<Theme>((theme) => `${theme.breakpoints.down('sm')}`);
  const onVisualClick = () => {
    analyticService.track(AnalyticEventsEnum.AUCTION_CARD_VISUAL_CLICKED);
  };

  const bidsWithStatuses = getBidHistoryStatus(nft.id || '', nft?.auction?.bids || []);

  const onBidClick = () => {
    if (!nft) {
      return;
    }
    onClick({
      nftId: nft.id.toString(),
      nftPrice: nft.priceUsd,
      nftAddress: nft.id!,
      minter: nft.minter!,
      nftTitle: nft.title,
      nftImage: nft.image ?? '',
      creatorName: nft.creatorName,
      auction: nft?.auction
        ? {
            bids: nft?.auction?.bids,
            latestBid: nft?.auction?.price,
          }
        : undefined,
    });
  };

  return isMobile ? (
    <OverlayDetailCardPortrait
      hideNavigation={hideNavigation}
      title={title}
      subtitle={subtitle}
      description={description}
      description1={description1}
      price={usdcUnitsToUSD(nft.price)}
      imgSrc={imgSrc}
      onClick={onBidClick}
      disabled={disabled}
      isBadge={isBadge}
      selectedIndex={selectedIndex}
      totalLength={totalLength}
      handleArrowClick={handleArrowClick}
      bids={bidsWithStatuses}
      isAuction={isAuction}
      isOpen={isOpen}
      endsAt={endDate}
      handlePrevClick={handlePrevClick}
      handleNextClick={handleNextClick}
      onVisualClick={onVisualClick}
      buttonText={buttonText}
      relType={relType?.toString() ?? ''}
      hidePrice={hidePrice}
    />
  ) : (
    <OverlayDetailCardLandscape
      hideNavigation={hideNavigation}
      title={title}
      subtitle={subtitle}
      description={description}
      description1={description1}
      price={usdcUnitsToUSD(nft.price)}
      imgSrc={imgSrc}
      onClick={onBidClick}
      disabled={disabled}
      isBadge={isBadge}
      selectedIndex={selectedIndex}
      totalLength={totalLength}
      handleArrowClick={handleArrowClick}
      bids={bidsWithStatuses}
      isAuction={isAuction}
      isOpen={isOpen}
      endsAt={endDate}
      handlePrevClick={handlePrevClick}
      handleNextClick={handleNextClick}
      onVisualClick={onVisualClick}
      buttonText={buttonText}
      relType={relType?.toString() ?? ''}
      hidePrice={hidePrice}
    />
  );
};
