import {ComponentType, useCallback, useState} from 'react';
import {ClickAwayListener, Tooltip, TooltipProps} from '@material-ui/core';

export interface ClickableTooltipProps {
  text: string;
  placement?: TooltipProps['placement'];
  triggerComponent: ComponentType<any>;
}

export const ClickableTooltip = ({
  text,
  placement = 'top',
  triggerComponent: TriggerComponent,
}: ClickableTooltipProps) => {
  const [isOpened, setOpened] = useState<boolean>(false);

  const toggleTooltip = useCallback(() => setOpened(!isOpened), [isOpened, setOpened]);
  const closeTooltip = useCallback(() => setOpened(false), [setOpened]);

  return (
    <ClickAwayListener onClickAway={closeTooltip}>
      <Tooltip
        PopperProps={{
          disablePortal: true,
        }}
        onClose={closeTooltip}
        open={isOpened}
        placement={placement}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        title={text}
      >
        <TriggerComponent onClick={toggleTooltip} />
      </Tooltip>
    </ClickAwayListener>
  );
};
