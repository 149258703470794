import {gql} from '@apollo/client';

export const USER_WALLETS_NFTS = gql`
  query userWalletsNfts($userWallets: [String!], $locale: String) {
    walletNfts: creatorWalletNfts(
      where: {wallet_in: $userWallets, quantity_gt: 0, nft_id: {cmsNfts_null: false, cmsNfts: {locale: $locale}}}
    ) {
      wallet
      quantity
      nft_id {
        cmsNfts {
          id
        }
      }
    }
  }
`;

export const PURCHASED_NFT = gql`
  query purchasedNfts($userWalletAddress: [String!], $nftId: String) {
    purchasedNfts: creatorWalletNfts(
      where: {wallet: $userWalletAddress, nft_id: {cmsNfts_null: false, cmsNfts: {id: $nftId}}}
    ) {
      quantity
    }
  }
`;

export const USER_AUCTIONS = gql`
  query userAuctions($userWallets: [String!], $locale: String) {
    auctions: creatorAuctions(
      where: {seller_in: $userWallets, is_settled: false, nft_id: {cmsNfts_null: false, cmsNfts: {locale: $locale}}}
    ) {
      seller
      nft_id {
        cmsNfts {
          id
        }
      }
    }
  }
`;

export const USER_NFT_OFFER = gql`
  query userNftOffers($userWalletAddress: [String!], $nftId: String) {
    offers: creatorMakeOffers(where: {seller: $userWalletAddress, creator_nft: {cmsNfts: {id: $nftId}}}) {
      seller
      price
      creator_nft {
        cmsNfts {
          id
        }
      }
    }
  }
`;
