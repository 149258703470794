import {ReactElement, ReactNode, useMemo, useState, createContext} from 'react';
import {noop} from 'lodash';
import {NonCustodyWalletTypeEnum} from '../shared/types/non-custody-wallet';
interface Provider {
  children: ReactNode;
}

export type SwitchNetworkContextType = {
  isSwitchNetworkDialogVisible: boolean;
  showNetworkDialog: () => void;
  hideNetworkDialog: () => void;
  switchNetworkWalletType?: NonCustodyWalletTypeEnum;
  setSwitchNetworkWalletType: (walletType: NonCustodyWalletTypeEnum) => void;
  resetSwitchNetworkWalletType: Function;
};

export const SwitchNetworkContext = createContext<SwitchNetworkContextType>({
  isSwitchNetworkDialogVisible: false,
  showNetworkDialog: noop,
  hideNetworkDialog: noop,
  switchNetworkWalletType: undefined,
  setSwitchNetworkWalletType: noop,
  resetSwitchNetworkWalletType: noop,
});

export function SwitchNetworkContextProvider({children}: Provider): ReactElement {
  const [state, setState] = useState<{
    isSwitchNetworkDialogVisible: boolean;
    switchNetworkWalletType?: NonCustodyWalletTypeEnum;
  }>({
    isSwitchNetworkDialogVisible: false,
    switchNetworkWalletType: undefined,
  });

  const context = useMemo(
    () => ({
      isSwitchNetworkDialogVisible: state.isSwitchNetworkDialogVisible,
      switchNetworkWalletType: state.switchNetworkWalletType,
      showNetworkDialog: () => setState((prevState) => ({...prevState, isSwitchNetworkDialogVisible: true})),
      hideNetworkDialog: () =>
        setState((prevState) => ({
          ...prevState,
          isSwitchNetworkDialogVisible: false,
          switchNetworkWalletType: undefined,
        })),
      setSwitchNetworkWalletType: (walletType: NonCustodyWalletTypeEnum) =>
        setState((prevState) => ({...prevState, switchNetworkWalletType: walletType})),
      resetSwitchNetworkWalletType: () => setState((prevState) => ({...prevState, switchNetworkWalletType: undefined})),
    }),
    [setState, state],
  );

  return <SwitchNetworkContext.Provider value={context}>{children}</SwitchNetworkContext.Provider>;
}
