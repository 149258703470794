import {useMediaQuery, useTheme, Grid, GridSize, makeStyles} from '@material-ui/core';

import {Skeleton} from './Skeleton';

const useStyles = makeStyles((theme) => ({
  item: {
    borderRadius: '12px',
    width: '320px',
    minHeight: '320px',

    [theme.breakpoints.up('md')]: {
      width: '328px',
      minHeight: '328px',
    },

    [theme.breakpoints.up('lg')]: {
      width: '400px',
      minHeight: '400px',
    },
  },
}));

export const ExhibitsRowSkeleton = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isTable = useMediaQuery(theme.breakpoints.down('lg'));
  const sceletonsCount = isMobile ? 1 : isTable ? 2 : 3;

  const styles = useStyles();

  return (
    <Grid container spacing={2}>
      {Array(sceletonsCount)
        .fill(0)
        .map((_, index) => (
          <Grid item xs={(12 / sceletonsCount) as GridSize}>
            <Skeleton className={styles.item} variant="rect" key={index} />
          </Grid>
        ))}
    </Grid>
  );
};
