import {useCallback} from 'react';
import {Box, makeStyles} from '@material-ui/core';
import {Button, Typography} from '@cere/rxb-template-ui-kit';
import {useHistory} from 'react-router-dom';

import {ReactComponent as BackIcon} from '../../assets/svg/back-arrow.svg';
import colors from '../../../styles/colors';
import {ReactComponent as Google} from './assets/ic-google.svg';
import {ReactComponent as Facebook} from './assets/ic-facebook.svg';
import {SupportedAuthProviders} from '../../models/auth/types';
import {SocialButton} from './components/SocialButton';
import {CereWalletKeyInfo} from './components/CereWalletKeyInfo';
import {AuthHeader} from '../../components/shared/AuthHeader';
import {UserForm} from './components/UserForm';
import {useLocalization} from '../../utils/hooks/use-localization';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    height: '100vh',
  },
  authHeader: {
    marginBottom: '18px',
  },
  backLinkBox: {
    padding: '2rem 0 0 1rem',
  },
  backLinkBoxButton: {
    minWidth: 'unset',
  },
  box: {
    margin: '0 auto',
    padding: '2rem',
    [theme.breakpoints.up('md')]: {
      maxWidth: '480px',
    },
  },
  label: {
    color: colors.lightGrey,
  },
  cardIcon: {
    width: '17px',
    height: '13px',
    margin: '0 10px 4px 0',

    '& path': {
      stroke: colors.snowWhite,
    },
  },
  button: {
    margin: '0 auto 0',
    display: 'block',
    padding: '13px 19px',
    [theme.breakpoints.up('md')]: {
      padding: '13px 37px',
    },
    [theme.breakpoints.up('lg')]: {
      margin: 'unset',
    },
  },
  payByCardLink: {
    textAlign: 'center',
    color: theme.palette.secondary.main,
    fontWeight: 600,
    padding: '15px',
    cursor: 'pointer',
  },
  textWrapper: {
    '> p': {
      fontWeight: 600,
      color: theme.palette.secondary.main,
    },
  },
  wrapper: {
    gap: '16px',
    flexWrap: 'wrap',
  },
  separator: {
    position: 'relative',
    display: 'inline',
    padding: '0 8px',
    background: '#fff',
    color: '#6E6E79',
  },
  strikethrough: {
    position: 'relative',
    textAlign: 'center',

    '&::before': {
      position: 'absolute',
      content: '""',
      left: '0',
      top: '10px',
      height: '1px',
      width: '100%',
      background: '#E0E0E7',
    },
  },
}));

type SignInPageViewProps = {
  logoUrl: string;
  onSignInWithEmail: (email: string) => void;
  onSignInWithSocialProvider: (provider: SupportedAuthProviders) => void;
};

export const SignInPageView = ({logoUrl, onSignInWithEmail, onSignInWithSocialProvider}: SignInPageViewProps) => {
  const styles = useStyles();
  const {t} = useLocalization();
  const history = useHistory();

  const goBack = useCallback(() => {
    history.goBack();
  }, [history]);

  const goHome = useCallback(() => {
    history.push('/');
  }, [history]);

  const signInWithEmail = useCallback(
    ({email}: {email: string}) => {
      onSignInWithEmail(email);
    },
    [onSignInWithEmail],
  );

  const onAuthWithGoogle = useCallback(() => {
    onSignInWithSocialProvider(SupportedAuthProviders.Google);
  }, [onSignInWithSocialProvider]);

  const onAuthWithFacebook = useCallback(() => {
    onSignInWithSocialProvider(SupportedAuthProviders.Facebook);
  }, [onSignInWithSocialProvider]);

  return (
    <Box className={styles.root}>
      <Box className={styles.backLinkBox}>
        <Button onClick={goBack} className={styles.backLinkBoxButton}>
          <BackIcon />
        </Button>
      </Box>
      <Box className={styles.box}>
        <AuthHeader
          className={styles.authHeader}
          logoUrl={logoUrl}
          title={t('Sign in')}
          subtitle={t('Access the exclusive DaVinci NFT experience')}
          onLogoClick={goHome}
        />
        <UserForm onSubmit={signInWithEmail} />
        <Box className={styles.strikethrough} m="24px 0">
          <Typography variant="body2" className={styles.separator}>
            {t('Or')}
          </Typography>
        </Box>
        <Box display="flex" justifyContent="center" alignItems="center" className={styles.wrapper} mb="32px">
          <SocialButton icon={<Google />} onClick={onAuthWithGoogle} />
          <SocialButton icon={<Facebook />} onClick={onAuthWithFacebook} />
        </Box>
        <CereWalletKeyInfo appUrl={process.env.REACT_APP_DAVINCI_WEB_APP_URL ?? ''} />
      </Box>
    </Box>
  );
};
