import {useState} from 'react';

export const useCollapsed = (initialState: boolean = false) => {
  const [opened, setOpened] = useState<boolean>(initialState);

  const toggleCollapsed = () => setOpened((state) => !state);

  const show = () => setOpened(true);

  const hide = () => setOpened(false);

  return {
    opened,
    toggleCollapsed,
    show,
    hide,
  };
};
