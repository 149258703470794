import {AuctionBid} from './types';
import {IAuctionApi} from './IAuctionApi';
import {BidTooLowError} from './BidTooLowError';
import {storeProcessingBid} from '../../shared/services/auction.service';
import {ROUND_DIVIDER} from '../../shared/lib/formatPrice';

export const BID_RAISE_PERCENT = 10;

export class Auction {
  constructor(
    private readonly api: IAuctionApi,
    private readonly nftAddress: string,
    private readonly userWalletAddress: string,
    private readonly sellerWalletAddress: string,
    private bids: AuctionBid[] = [],
    private readonly startingPrice: number = 0,
    private readonly collectionAddress?: string,
    public readonly bidRisePercent: number = BID_RAISE_PERCENT,
  ) {}

  hasBids(): boolean {
    return this.bids.length > 0;
  }

  async start(closingTime: number): Promise<void> {
    await this.api.startAuction(this.nftAddress, this.startingPrice, closingTime, this.collectionAddress);
  }

  async settle(): Promise<void> {
    await this.api.settleAuction(
      this.userWalletAddress,
      this.sellerWalletAddress,
      this.nftAddress,
      this.collectionAddress,
    );
  }

  async makeBid(price: number): Promise<void> {
    await this.validateBid(price);
    const {hash} = await this.api.placeBid(
      this.userWalletAddress,
      this.sellerWalletAddress,
      this.nftAddress,
      price,
      this.collectionAddress,
    );
    const bid = {
      id: '-',
      txHash: hash,
      timestamp: new Date().toISOString(),
      price,
      buyer: this.userWalletAddress,
    };
    storeProcessingBid(hash, this.nftAddress, bid);
    this.bids = [bid, ...this.bids];
  }

  getBids(): AuctionBid[] {
    return this.bids;
  }

  getLatestBidPrice(): number {
    const bids = this.getBids();
    if (!bids.length) {
      return this.startingPrice;
    }
    const [latestBid] = bids;
    return latestBid.price;
  }

  getNextMinBid(): number {
    const latestBidPrice = this.getLatestBidPrice();
    return Math.ceil((latestBidPrice * (1 + this.bidRisePercent / 100)) / ROUND_DIVIDER) * ROUND_DIVIDER;
  }

  validateBid(bidPrice: number): void {
    const minBidPrice = this.getNextMinBid();
    if (bidPrice < minBidPrice) {
      throw new BidTooLowError();
    }
  }
}
