import {useContext, useState, useEffect} from 'react';
import {Box, Button, makeStyles, Paper, Typography} from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import clsx from 'clsx';
import {ReactComponent as CheckIcon} from '../../../assets/icons/checkGreen.svg';
import {PurchaseModal} from '../PurchaseModal';
import {NFTCTAButton} from '../NFTCTAButton';
import {useConnectNonCustodyWallet} from '../../hooks/use-connect-wallet';
import {useSelectedWallet} from '../../../context/use-selected-wallet';
import {NonCustodyWalletTitles, NonCustodyWalletTypeEnum, isNonCustodyWalletType} from '../../types/non-custody-wallet';
import {useSingletonFetchUserWalletBalance} from '../../hooks/user-balance.hook';
import {WalletBalance} from '../WalletBalance';
import {TorusWalletFundButton} from '../TorusWalletFundButton';
import {ReactComponent as Card} from '../../../assets/icons/card.svg';
import NftModalHighlightCard from '../NftModalHighlightCard';
import {mobileLandscapeMediaQuery} from '../../../styles/mediaQueries';
import {useLocalization} from '../../hooks/use-locale.hook';
import {AppContext} from '../../../context/app-context';
import {FundWalletInfoStepper} from '../FundWalletInfoStepper';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: '22px',

    [theme.breakpoints.up('md')]: {
      paddingTop: '32px',
    },

    [mobileLandscapeMediaQuery(theme)]: {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 1fr)',
      gridColumnGap: '20px',
    },
  },

  registrationMsg: {
    position: 'absolute',
    top: '12px',
    left: '50%',
    transform: 'translateX(-50%)',
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    padding: '16px',
    borderRadius: '12px',
    border: '1px solid #E7E8EB',
    boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.1)',
    backgroundColor: '#fff',
  },

  button: {
    width: '100%',
    height: '36px',
    borderRadius: '50px',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',
    [mobileLandscapeMediaQuery(theme)]: {
      width: '200px',
    },
  },
  icon: {
    '--size': '18px',
    height: 'var(--size)',
    width: 'var(--size)',
    color: theme.palette.primary.main,
  },
  dataContainer: {
    marginBottom: '8px',
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  column: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '16px',
    margin: '16px auto 0 auto',

    [mobileLandscapeMediaQuery(theme)]: {
      flexDirection: 'row-reverse',
      justifyContent: 'flex-start',
    },
  },

  connectWalletContainer: {
    margin: '20px 0 16px 0',
    [theme.breakpoints.up('md')]: {
      margin: '10px 0 20px 0',
    },
    [theme.breakpoints.up('lg')]: {
      margin: '24px 0 16px 0',
    },
  },
  connectWalletButton: {
    padding: 0,
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '20px',
    textTransform: 'none',
    color: theme.palette.primary.main,
  },
  walletRoot: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    border: 'none',
    backgroundColor: theme.palette.background.paper,
  },
  errorMessage: {
    display: 'flex',
    width: '100%',
    padding: '12px',
    background: 'rgba(255, 81, 81, 0.1)',
    borderRadius: '0 0 12px 12px',
    color: theme.palette.error.main,
  },
  errorText: {
    fontWeight: 500,
    fontSize: '13px',
    lineHeight: '22px',
    color: theme.palette.error.main,
    marginLeft: '10px',
  },
  payByCardWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  payByCardIcon: {
    '& path': {
      stroke: theme.palette.secondary.main,
      fill: theme.palette.secondary.main,
    },
    marginRight: '8px',
  },
  payByCardIconLoading: {
    '& path': {
      stroke: theme.palette.text.disabled,
      fill: theme.palette.text.disabled,
    },
  },
  payByCardLink: {
    color: theme.palette.secondary.main,
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',
  },
  payByCardLinkLoading: {
    color: theme.palette.text.disabled,
  },
}));

const useModalStyles = makeStyles(() => ({
  header: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    paddingTop: '2.4rem',
    paddingBottom: '0.5rem',
  },
}));

export interface BuyNFTLimitedModalProps {
  nftId: string;
  nftTitle: string;
  nftImage?: string;
  price: number;
  open: boolean;
  isProcessing: boolean;
  onCancel: () => void;
  buyNft: () => Promise<void>;
  buyNftByCard: () => Promise<void>;
  onlyCrypto?: boolean;
}

export const BuyNFTLimitedOrTicketModalContinue = ({
  nftId,
  nftImage,
  price,
  nftTitle,
  open,
  isProcessing,
  onCancel,
  buyNft,
  buyNftByCard,
  onlyCrypto = false,
}: BuyNFTLimitedModalProps) => {
  const {t} = useLocalization();
  const styles = useStyles();
  const modalStyles = useModalStyles();

  const selectedWallet = useSelectedWallet();
  const {showConnectWalletModal} = useConnectNonCustodyWallet();
  const {isLoading, walletBalance} = useSingletonFetchUserWalletBalance();

  const isNonCustodyWalletConnected = isNonCustodyWalletType(selectedWallet.wallet);
  const isTorusWalletSelected = selectedWallet.wallet === NonCustodyWalletTypeEnum.TORUS;

  const isEnoughFunds = walletBalance?.usdc ? walletBalance.usdc >= price : false;
  const isButtonDisabled = isLoading || !isNonCustodyWalletConnected || !isEnoughFunds;
  const isShowWalletError = isTorusWalletSelected && !isLoading && !isEnoughFunds;
  const {appConfig} = useContext(AppContext);

  const [isShowRegistrationMsg, setIsShowRegistrationMsg] = useState(true);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsShowRegistrationMsg(false);
    }, 3000);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <PurchaseModal open={open} title={t('Continue your purchase')} onClose={onCancel} classes={modalStyles}>
      {isShowRegistrationMsg && (
        <Box className={styles.registrationMsg}>
          <CheckIcon />
          {t('Successful registration')}
        </Box>
      )}
      <Typography variant="body2">{t('Your account and wallet are successfully created.')}</Typography>
      <Typography variant="body2">{t("You're all set for any marketplace transaction.")}</Typography>
      {!isNonCustodyWalletConnected && (
        <Box className={clsx(styles.dataContainer, styles.connectWalletContainer)}>
          <Button className={styles.connectWalletButton} variant="text" onClick={showConnectWalletModal}>
            {t('Connect wallet')} <ChevronRightIcon fontSize="small" />
          </Button>
        </Box>
      )}
      <Box className={styles.root}>
        <Box>
          <Box className={clsx(styles.dataContainer)}>
            <Box className={styles.column}>
              <NftModalHighlightCard
                nftId={nftId}
                title={nftTitle}
                nftTitle={`$${price}`}
                onClick={onCancel}
                nftImage={nftImage}
                withoutVerifiedAuthorIcon
              />
            </Box>
          </Box>
        </Box>
        <Box>
          <Box className={clsx(styles.dataContainer)}>
            <Box className={styles.column}>
              {isNonCustodyWalletType(selectedWallet.wallet) && (
                <WalletBalance
                  balance={walletBalance?.usdc}
                  caption={t('My {{title}} wallet balance', {title: NonCustodyWalletTitles[selectedWallet.wallet]})}
                  classes={{root: styles.walletRoot}}
                />
              )}
              {isShowWalletError && (
                <Paper className={styles.errorMessage} elevation={0}>
                  <ErrorIcon fontSize="small" />
                  <Typography className={styles.errorText}>{t('Insufficient balance, fund your wallet')}</Typography>
                </Paper>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
      <Box className={styles.buttonContainer}>
        {isShowWalletError && (
          <TorusWalletFundButton
            text={t('Add funds')}
            className={styles.button}
            contextInfo={{
              price,
              title: nftTitle,
              description: t('You’re about to purchase:'),
              imageUrl: nftImage!,
            }}
          />
        )}
        {!isShowWalletError && (
          <NFTCTAButton
            className={styles.button}
            text={t('Buy')}
            type="submit"
            onClick={buyNft}
            loading={isProcessing}
            disabled={isButtonDisabled}
          />
        )}
        {!appConfig.isFiatPaymentDisabled && !onlyCrypto && (
          <Box className={clsx(styles.button, styles.payByCardWrapper)}>
            <Card className={clsx(styles.payByCardIcon, isProcessing && styles.payByCardIconLoading)} />
            <Typography
              className={clsx(styles.payByCardLink, isProcessing && styles.payByCardLinkLoading)}
              onClick={buyNftByCard}
            >
              {t('Pay by card')}
            </Typography>
          </Box>
        )}
      </Box>
      <FundWalletInfoStepper />
    </PurchaseModal>
  );
};
