import {StoreModule} from '../../base/types';
import {PURCHASE_MODULE_NAME} from './constants';
import {INftsApi} from '../../../api/nfts/INftsApi';
import {IPurchaseNFTApi} from '../../../models/puchase/IPurchaseNFTApi';
import {WalletConnectionService} from '../../../models/wallet/WalletConnectionService';
import {IPaymentApi} from '../../../api/payment/IPaymentApi';
import {
  purchaseNftForCryptoMiddleware,
  purchaseNtForFiatMiddleware,
  startPurchaseFromMinterMiddleware,
  startPurchaseOnMarketplaceMiddleware,
} from './middlewares';

export const createPurchaseModule = (
  nftApi: INftsApi,
  purchaseApi: IPurchaseNFTApi,
  paymentApi: IPaymentApi,
  walletConnectionService: WalletConnectionService,
): StoreModule => ({
  title: PURCHASE_MODULE_NAME,
  middlewares: [
    startPurchaseFromMinterMiddleware(nftApi),
    startPurchaseOnMarketplaceMiddleware(nftApi),
    purchaseNftForCryptoMiddleware(purchaseApi, walletConnectionService),
    purchaseNtForFiatMiddleware(paymentApi),
  ],
});
