import {css} from '@linaria/core';
import colors from '../../../../styles/colors';

export const typoColor = {color: '#F8F8FA'};

export const subtitletext = {color: '#F8F8FA', marginBottom: '4px'};

export const tabletTitleText = css`
  font-size: 32px;
`;

export const desktopTitleText = css`
  font-size: 40px;
`;

export const inVisibleAboutTextStyle = css`
  min-height: 40px;
  overflow-y: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  color: ${String(colors.lightGrey)};
`;

export const inVisibleAboutTextDesktopStyle = css`
  min-height: 40px;
  overflow-y: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: ${String(colors.lightGrey)};
`;

export const visibleAboutTextStyle = css`
  color: ${String(colors.lightGrey)};
`;

export const aboutTextButtonStyle = css`
  padding-top: 5px;
  padding-left: 0 !important;
`;

export const aboutTextButtonTitleStyle = css`
  color: #fa0c58;
`;

export const titleDesktop = {color: '#F8F8FA', fontSize: '48px'};

export const subTitleDesktop = {color: '#F8F8FA', fontSize: '16px', paddingBottom: '4px'};
