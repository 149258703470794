import {useContext} from 'react';
import dayjs from 'dayjs';
import {NftAttributes} from './types';
import {
  NftCardInterface,
  ExhibitCardInterface,
  AuctionStatus,
  NftSellingType,
  AvailabilityStatus,
  NftType,
  CheckUserHasNftEnum,
} from '@cere/services-types';
import {usePendingTransactionsContext} from '../../../context/pending-transaction-context';
import {getIsPaymentFailed, getIsPaymentPending} from '../../helpers/paymentStatus';
import {UserPaymentHistoryContext} from '../../../context/payments-history/user-payment-history.context';
import {useLocalization} from '../../hooks/use-locale.hook';

export const useNftAttributesV2 = (
  nft: NftCardInterface,
  exhibit: ExhibitCardInterface,
): Omit<NftAttributes, 'availability'> & {availability: AvailabilityStatus} => {
  const {locale} = useLocalization();
  const {getPaymentStatuses} = useContext(UserPaymentHistoryContext);
  const {getPendingTransactionForNft} = usePendingTransactionsContext();
  const isEventStarted = exhibit ? dayjs(exhibit.startsAt).isBefore(new Date()) : null;
  const counter =
    nft.availability !== AvailabilityStatus.SOLD_OUT &&
    nft.availability !== AvailabilityStatus.OFFER_ENDED &&
    nft.nftType === NftType.ACCESS &&
    isEventStarted
      ? exhibit.endsAt ?? ''
      : '';
  return {
    auctionSettled: nft.auctionStatus === AuctionStatus.SETTLED,
    availability: nft.availability,
    availableOnlyOnMarketplace: nft.sellingType === NftSellingType.IS_SECONDARY,
    hasPendingTransaction: Boolean(getPendingTransactionForNft(nft.address)),
    counter,
    offerEnded: nft.availability !== AvailabilityStatus.OFFER_ENDED,
    pending: getIsPaymentPending(...getPaymentStatuses(nft.address)),
    failed: getIsPaymentFailed(...getPaymentStatuses(nft.address)),
    purchased: nft.purchaseStatus === CheckUserHasNftEnum.USER_HAS_NFT,
    soldOut: nft.availability !== AvailabilityStatus.SOLD_OUT,
    link: `/${locale}/home/nft/${nft.id}`,
    order: 1,
  };
};
