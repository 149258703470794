import {makeStyles, Theme} from '@material-ui/core';
import colors from '../../../styles/colors';

export const useFilteredGridStyles = makeStyles<Theme, {isNftCollectiblesCard?: boolean}>((theme) => ({
  rootVars: {
    '--padding': '0 1rem',
    '--filters-width': '100%',

    [theme.breakpoints.up('md')]: {
      '--padding': 0,
      '--filters-width': '349px',
    },

    [theme.breakpoints.up('lg')]: {
      maxWidth: '1280px',
      minWidth: '1280px',
      margin: '0 auto',
      '--filters-width': '397px',
    },
  },
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '2rem 0',
  },
  section: {
    [theme.breakpoints.up('md')]: {
      margin: 'auto',
      maxWidth: '712px',
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: '1440px',
    },
  },
  header: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
  headerContentWrapper: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'column',
    padding: 'var(--padding)',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      alignItems: 'end',
      padding: 0,
      marginBottom: '24px',
    },
    [theme.breakpoints.up('lg')]: {
      padding: 'var(--padding)',
      marginBottom: '28px',
    },
  },
  desktopFiltersWrapper: {
    [theme.breakpoints.up('md')]: {
      width: '100%',
      padding: 'var(--padding)',
      marginBottom: '36px',
    },
  },
  textContent: {
    [theme.breakpoints.up('md')]: {
      width: 'calc(100% - var(--filters-width))',
    },
    [theme.breakpoints.up('lg')]: {
      width: 'calc(100% - var(--filters-width))',
    },
  },
  filtersContent: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '24px 0',
    [theme.breakpoints.up('md')]: {
      width: '349px',
      padding: 0,
    },
    [theme.breakpoints.up('lg')]: {
      width: '397px',
    },
  },
  title: {
    fontWeight: 700,
    fontSize: '20px',
    lineHeight: '26px',
    color: colors.primaryDark,
    marginBottom: '12px',
    [theme.breakpoints.up('lg')]: {
      fontWeight: 800,
      fontSize: '36px',
      lineHeight: '43.5px',
    },
  },
  subTitle: {
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
    color: colors.lightGrey,

    [theme.breakpoints.up('md')]: {
      fontSize: '14px',
    },

    [theme.breakpoints.up('lg')]: {
      fontSize: '16px',
    },
  },
  contentGrid: {
    width: '100%',
    display: 'grid',

    gap: '32px',
    padding: 0,
    gridTemplateColumns: 'repeat(auto-fit, minmax(328px, 2fr))',

    [theme.breakpoints.down('md')]: {
      gap: '8px',
      gridTemplateColumns: ({isNftCollectiblesCard}) =>
        `repeat(auto-fit, minmax(${isNftCollectiblesCard ? '167px' : '343px'}, 1fr))`,
      justifyItems: 'center',
      padding: 'var(--padding)',
    },
    [theme.breakpoints.up('lg')]: {
      padding: 'var(--padding)',
      gap: ({isNftCollectiblesCard}) => (isNftCollectiblesCard ? '24px' : '32px'),
      gridTemplateColumns: ({isNftCollectiblesCard}) =>
        `repeat(auto-fit, minmax(${isNftCollectiblesCard ? '298px, 4fr' : '343px, 1fr'}))`,
    },
  },
  controlsContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '24px 0 0 0',
  },
  loadMoreButton: {
    width: '184px',
    height: '36px',
    borderRadius: '100px',
    padding: '8px 16px',
    backgroundColor: colors.lighter,
    textTransform: 'none',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',
  },
}));
