import {useMemo, useContext} from 'react';
import {UserContext} from '../user-context/user-context';
import {NonCustodyWalletTypeEnum} from '../../shared/types/non-custody-wallet';
import {useConnectNonCustodyWallets} from '../../shared/hooks/non-custody-wallet.hook';

export const useTorusWalletAddress = () => {
  const {nonCustodyWallets} = useContext(UserContext);
  const {isTorusLoading, connectTorus, error} = useConnectNonCustodyWallets();
  const wallet = useMemo(
    () => nonCustodyWallets.find(({type}) => type === NonCustodyWalletTypeEnum.TORUS),
    [nonCustodyWallets],
  );

  return {
    error,
    loading: isTorusLoading,
    address: wallet?.publicKey,
    createWallet: connectTorus,
  };
};
