import {Box, Button, CardMedia, Divider, Typography, makeStyles} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import clsx from 'clsx';
import {PurchaseModal} from '../PurchaseModal';
import colors from '../../../styles/colors';
import {CopyButton} from '../CopyButton';
import {useRef} from 'react';
import {useNftRoyalty} from '../../../context/use-nft-royalties';
import {SellingNftDetails} from '../../../context/sell-nft-context';
import {TOKEN_TITLE} from '../../lib/formatPrice';
import {useLocalization} from '../../hooks/use-locale.hook';
import {mobileLandscapeMediaQuery} from '../../../styles/mediaQueries';
import {ExplorerLink} from '../Explorer';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: '22px',
    [theme.breakpoints.up('md')]: {
      paddingTop: '32px',
    },
    [mobileLandscapeMediaQuery(theme)]: {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 1fr)',
      gridColumnGap: '20px',
    },
  },
  description: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    paddingTop: '8px',
    textAlign: 'center',
  },
  buttonBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: '24px',
    [mobileLandscapeMediaQuery(theme)]: {
      justifyContent: 'flex-end',
    },
  },
  button: {
    width: '100%',
    height: '36px',
    borderRadius: '50px',
    border: `1px solid ${colors.primaryDark}`,
    [mobileLandscapeMediaQuery(theme)]: {
      width: '200px',
    },
  },
  icon: {
    '--size': '18px',
    height: 'var(--size)',
    width: 'var(--size)',
    color: theme.palette.primary.main,
  },
  dark: {
    color: colors.primaryDark,
  },
  grey: {
    color: colors.grey,
  },
  labelText: {
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '22px',
    marginBottom: '4px',
  },
  dataLabel: {
    width: '95px',
  },
  dataText: {
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '22px',
    marginBottom: '4px',
    maxWidth: '325px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  dataContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  dataBox: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  copyButtonContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& button, & button svg': {
      width: '20px',
      height: '20px',
    },
  },
  dataLine: {
    marginBottom: '18px',
  },
  netSales: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'end',
    '& p': {
      margin: 0,
    },
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  media: {
    width: '50px',
    height: '50px',
    minWidth: '50px',
    minHeight: '50px',
    borderRadius: '6px',
  },
}));

const useModalStyles = makeStyles((theme) => ({
  header: {
    [mobileLandscapeMediaQuery(theme)]: {
      flexDirection: 'column',
      justifyContent: 'center',
    },
  },
}));

export interface NFTListingConfirmedModalProps {
  nftDetails: SellingNftDetails;
  open: boolean;
  onClose: () => void;
}

export const NFTListingConfirmedModal = ({open, onClose, nftDetails}: NFTListingConfirmedModalProps) => {
  const {t} = useLocalization();
  const styles = useStyles();
  const modalStyles = useModalStyles();
  const nftRoyalty = useNftRoyalty(nftDetails.nftId);
  const nftIdRef = useRef<HTMLElement | null>(null);

  const price = nftDetails.auctionStartingPrice || nftDetails.price;
  const royalties = price * nftRoyalty;
  const priceText = nftDetails.auctionStartingPrice ? t('Starting price') : t('Listing price');

  return (
    <PurchaseModal
      open={open}
      icon={<CheckCircleIcon className={styles.icon} />}
      title={t('Listing confirmed')}
      onClose={onClose}
      classes={modalStyles}
    >
      <Typography className={styles.description}>
        {t('Your NFT is listed on the marketplace. You will be notified once the transaction is processed.')}
      </Typography>
      <Box className={styles.root}>
        <Box className={styles.dataBox}>
          <Box className={clsx(styles.dataContainer, styles.dataLine)}>
            <Box className={styles.column}>
              <Typography className={clsx(styles.labelText, styles.dataLabel, styles.grey)}>{t('NFT name')}</Typography>
              <Typography className={styles.dataText}>{nftDetails.nftName}</Typography>
            </Box>
            {nftDetails.nftMedia && <CardMedia className={styles.media} image={nftDetails.nftMedia.url} />}
          </Box>
          <Box className={clsx(styles.dataContainer, styles.dataLine)}>
            <Box className={styles.column}>
              <Typography className={clsx(styles.labelText, styles.dataLabel, styles.grey)}>{t('NFT ID')}</Typography>
              <Box className={styles.copyButtonContainer}>
                <Typography className={styles.dataText} ref={nftIdRef}>
                  {nftDetails.nftId}
                </Typography>
                <CopyButton node={nftIdRef} />
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className={styles.dataBox}>
          <Box className={clsx(styles.column, styles.dataLine)}>
            <Box className={styles.dataContainer}>
              <Typography className={clsx(styles.labelText, styles.dataLabel, styles.grey)}>{t('Quantity')}</Typography>
              <Typography className={styles.dataText}>1</Typography>
            </Box>
            <Divider />
          </Box>
          <Box className={clsx(styles.column, styles.dataLine)}>
            <Box className={styles.dataContainer}>
              <Typography className={clsx(styles.labelText, styles.dataLabel, styles.grey)}>{priceText}</Typography>
              <Typography className={styles.dataText}>{`${price} ${TOKEN_TITLE}`}</Typography>
            </Box>
            <Divider />
          </Box>
          <Box className={clsx(styles.column, styles.dataLine)}>
            <Box className={styles.dataContainer}>
              <Typography className={clsx(styles.labelText, styles.dataLabel, styles.grey)}>
                {t('Royalties')}
              </Typography>
              <Typography className={styles.dataText}>{`${nftRoyalty * nftDetails.price} ${TOKEN_TITLE}`}</Typography>
            </Box>
            <Divider />
          </Box>
          {!nftDetails.auctionStartingPrice && (
            <Box className={styles.column}>
              <Box className={styles.dataContainer}>
                <Typography className={clsx(styles.labelText, styles.dataLabel, styles.dark)}>
                  {t('Net sales')}
                </Typography>
                <Typography className={clsx(styles.dataText, styles.netSales)}>
                  {`${price - royalties} ${TOKEN_TITLE}`}{' '}
                  <Typography className={clsx(styles.labelText, styles.grey)}>(~${price - royalties} USD)</Typography>
                </Typography>
              </Box>
              <Divider />
            </Box>
          )}
        </Box>
      </Box>
      <Box mt="12px">
        <ExplorerLink />
      </Box>
      <Box className={styles.buttonBox}>
        <Button className={styles.button} onClick={onClose}>
          {t('Ok')}
        </Button>
      </Box>
    </PurchaseModal>
  );
};
