import {useEffect, useMemo} from 'react';
import {useQuery} from '@apollo/client';
import {Box, FormControl, InputAdornment, makeStyles, OutlinedInput} from '@material-ui/core';
import {useState} from 'react';
import {regex} from '../../../config/regex';
import {cereToUSD, formatPrice, usdToCere, TOKEN_TITLE, TOKEN_DECIMALS} from '../../../shared/lib/formatPrice';
import {CERE_EXCHANGE_RATE} from '../../../shared/queries';
import {ExchangeRate} from '../../../shared/types/graphql';
import colors from '../../../styles/colors';
import BootstrapLoader from '../../../shared/components/bootstrap-loader';
import {useHistory} from 'react-router-dom';
import {getQuerySearchParam} from '../../../shared/lib/utils';

const useStyles = makeStyles((theme) => ({
  input: {
    borderRadius: '16px',
    color: colors.grey,
    margin: '4px 0 4px 0',
  },
  rate: {
    margin: '4px 0 4px 0',
    color: theme.palette.primary.main,
    fontWeight: 600,
  },
}));

interface CereUsdConverterComponentParams {
  onValidChanged: (isValidValud: boolean) => void;
  onAmountChanged?: (usdAmount: string, cereAmount: string) => void;
}

const CereUsdConverterComponent = ({onValidChanged, onAmountChanged}: CereUsdConverterComponentParams) => {
  const classes = useStyles();
  const history = useHistory();
  const initialUsd = getQuerySearchParam(history.location.search, 'usd');
  const [usdPlaceholder, setUsdPlaceholder] = useState<string>('0');
  const [cerePlaceholder, setCerePlaceholder] = useState<string>('0');
  const [usd, setUsd] = useState<string>(initialUsd || '100');
  const [cere, setCere] = useState<string>('');

  const isUsdFieldValid = useMemo(() => regex.currencyUsd.test(usd) && Number(usd) >= 5, [usd]);
  const isCereFieldValid = useMemo(() => regex.currencyCere.test(cere), [cere]);

  useEffect(() => {
    onValidChanged(isUsdFieldValid && isCereFieldValid);
  }, [isUsdFieldValid, isCereFieldValid, onValidChanged]);

  useEffect(() => {
    onAmountChanged && onAmountChanged(usd, cere);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usd, cere]);

  const {data: exchangeRate} = useQuery<{creatorExchangeRate: ExchangeRate}>(CERE_EXCHANGE_RATE);

  const changeUsdValue = (e: any) => {
    const value: string = e.target.value || '';
    if (regex.currencyUsd.test(value)) {
      setCere(usdToCere(Number(value), exchangeRate?.creatorExchangeRate.cere_units_per_penny || 0).toString());
    }
    setUsd(value);
  };

  const changeCereValue = (e: any) => {
    const value: string = e.target.value || '';
    if (regex.currencyCere.test(value)) {
      setUsd(
        cereToUSD(
          Number(value) * TOKEN_DECIMALS,
          exchangeRate?.creatorExchangeRate.cere_units_per_penny || 0,
        ).toString(),
      );
    }
    setCere(value);
  };

  useEffect(() => {
    if (exchangeRate?.creatorExchangeRate.cere_units_per_penny) {
      setCere(usdToCere(Number(usd), exchangeRate?.creatorExchangeRate.cere_units_per_penny || 0).toString());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exchangeRate?.creatorExchangeRate.cere_units_per_penny]);

  if (!exchangeRate?.creatorExchangeRate.cere_units_per_penny) {
    return <BootstrapLoader />;
  }

  return (
    <Box component="form">
      <FormControl fullWidth variant="outlined">
        <OutlinedInput
          className={classes.input}
          error={!isUsdFieldValid}
          endAdornment={<InputAdornment position="end">USD</InputAdornment>}
          onChange={changeUsdValue}
          placeholder={usdPlaceholder}
          value={usd}
          onFocus={() => setUsdPlaceholder('')}
          onBlur={() => setUsdPlaceholder('0')}
        />
      </FormControl>
      <FormControl fullWidth variant="outlined">
        <OutlinedInput
          className={classes.input}
          error={!isCereFieldValid}
          id="outlined-error-helper-text"
          endAdornment={<InputAdornment position="end">{TOKEN_TITLE}</InputAdornment>}
          onChange={changeCereValue}
          placeholder={cerePlaceholder}
          value={cere}
          onFocus={() => setCerePlaceholder('')}
          onBlur={() => setCerePlaceholder('0')}
        />
      </FormControl>
      <span className={classes.rate}>
        {formatPrice(1)} ~ {usdToCere(1, exchangeRate?.creatorExchangeRate.cere_units_per_penny || 0)} {TOKEN_TITLE}
      </span>
    </Box>
  );
};

export default CereUsdConverterComponent;
