import {useState} from 'react';
import './asset-gallery.css';

import {ReactComponent as EmptyAudio} from '../../../assets/empty-audio.svg';
import BootstrapLoader from '../bootstrap-loader';
import {MediaPlayerWithRestrictedAccess} from '../Player/MediaPlayerWithRestrictedAccess';

export const AssetAudioItem = ({audioId}: {audioId: string}) => {
  const [jwtLoaded, setJwtLoaded] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const onJwtLoaded = (isSuccess: boolean) => {
    setIsLoading(false);
    setJwtLoaded(isSuccess);
  };

  if (!audioId || !jwtLoaded) {
    return <EmptyAudio className="main-image" />;
  }

  return (
    <>
      {isLoading && (
        <div className="loader">
          <BootstrapLoader color="text-light" />
        </div>
      )}
      <div className="main-audio" style={{display: isLoading ? 'none' : 'block'}}>
        <MediaPlayerWithRestrictedAccess
          className="reset-position"
          videoId={audioId}
          onJwtLoaded={onJwtLoaded}
          autoplay={false}
          onPause={() => {}}
          onPlay={() => {}}
          play={false}
        />
      </div>
    </>
  );
};
