import {get, patch} from '../../lib/request';
import {isRecord} from '../../types/is-record';
import {isNumberish, Numberish} from '../../types/numberish';
import {NotificationItem, NftNotificationParameters, ExhibitNotificationParameters} from './types';
import {TransactionalNotificationStatus} from './enums';
import {apolloClient} from '../../../apollo-client';
import {GET_NFTS_NOTIFICATIONS_DATA, GET_EXHIBITIONS_NOTIFICATIONS_DATA} from '../../queries/notifications';
import {NOTIFICATION_SERVICE_API_URL as base, tenantId} from '../../../config/common';

const isNotificationsResponse = (
  val: unknown,
): val is Promise<{
  data: NotificationItem[];
  total: number;
}> => isRecord(val) && isNumberish(val.total) && Array.isArray(val.data);

export const fetchNotifications = async (
  userId: number,
  token: string,
  page: number,
  limit: number,
  statuses: TransactionalNotificationStatus[] = [],
): Promise<{
  data: NotificationItem[];
  total: number;
}> => {
  const query = new URLSearchParams();
  query.append('page', page.toString());
  query.append('limit', limit.toString());

  if (statuses.length > 0) {
    query.append('statuses', statuses.join(','));
  }

  const response = await get<'json'>(`/users/${userId}/notifications?${query.toString()}`, {
    base,
    headers: {Authorization: `Bearer ${token}`, 'X-Tenant-Id': tenantId()},
  });

  if (isRecord(response) && isNotificationsResponse(response.data)) {
    return response.data;
  }

  return {
    data: [],
    total: 0,
  };
};

export const patchNotification = async (
  userId: number,
  token: string,
  notificationId: Numberish,
  status: TransactionalNotificationStatus,
): Promise<void> => {
  await patch<'json'>(
    `/users/${userId}/notifications/${notificationId}`,
    {status},
    {
      base,
      headers: {Authorization: `Bearer ${token}`, 'X-Tenant-Id': tenantId()},
    },
  );
};
export const patchNotifications = async (
  userId: number,
  token: string,
  status: TransactionalNotificationStatus,
): Promise<void> => {
  await patch<'json'>(
    `/users/${userId}/notifications`,
    {status},
    {
      base,
      headers: {Authorization: `Bearer ${token}`, 'X-Tenant-Id': tenantId()},
    },
  );
};

export const getExhibitionNotificationsData = async (
  data: NotificationItem[],
): Promise<ExhibitNotificationParameters[]> => {
  const uniqueIds = new Set(data.map(({exhibitId}) => exhibitId).filter(Boolean));

  if (uniqueIds.size === 0) {
    return [];
  }

  const cmsData = await apolloClient.query<{cmsExhibitions: ExhibitNotificationParameters[]}>({
    query: GET_EXHIBITIONS_NOTIFICATIONS_DATA,
    variables: {
      ids: Array.from(uniqueIds),
    },
  });

  return cmsData.data?.cmsExhibitions;
};

export const getNftNotificationsData = async (data: NotificationItem[]): Promise<NftNotificationParameters[]> => {
  const uniqueIds = new Set(data.map(({cmsNftId}) => cmsNftId).filter(Boolean));

  if (uniqueIds.size === 0) {
    return [];
  }

  const cmsData = await apolloClient.query<{cmsNfts: NftNotificationParameters[]}>({
    query: GET_NFTS_NOTIFICATIONS_DATA,
    variables: {
      ids: Array.from(uniqueIds),
    },
  });

  return cmsData.data?.cmsNfts;
};
