import {useMemo} from 'react';
import {EventTimelineStatus} from '../../types/event';
import {getNftAvailabilityByCmsNft, NftAvailability} from '../../services/nft.service';
import {CmsExhibitionNftRelType} from '../../types/graphql';
import {useNftListing} from '../../hooks/use-nft-listing';
import {ExhibitionNft} from './types';

type Params = {
  nft: ExhibitionNft | undefined;
  balance: number;
  eventStatus: EventTimelineStatus;
};

export function useNftIsOfferEnded({nft, balance, eventStatus}: Params): undefined | boolean {
  const cmsNftId = nft?.cmsNft.id;
  const relType = nft?.relType;
  const auctionSettled = nft?.cmsNft?.creatorNft?.creator_auctions[0]?.is_settled;
  const nftAvailability = getNftAvailabilityByCmsNft(nft).availability;
  const {
    offers,
    primaryOffer,
    loading: nftListingLoading,
  } = useNftListing({cmsNftIncrementId: cmsNftId, start: 0, limit: 10});

  return useMemo(() => {
    if (nftListingLoading) {
      return undefined;
    }
    if (eventStatus === EventTimelineStatus.FINISHED || nftAvailability === NftAvailability.PASSED) {
      return true;
    }

    if (relType === CmsExhibitionNftRelType.AUCTIONED && balance > 0 && !auctionSettled) {
      return false;
    }

    return !primaryOffer && offers.length === 0;
  }, [nftListingLoading, eventStatus, nftAvailability, relType, balance, auctionSettled, primaryOffer, offers.length]);
}
