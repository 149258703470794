import {ReactElement, ReactNode, useCallback, useContext, useEffect, useMemo, useState} from 'react';
import * as Sentry from '@sentry/react';
import {Condition, ConditionsList, Defaults} from '../../shared/components/Conditions';
import {AuthPageState, AuthText, AuthType, SubmittedAuthType} from './types';
import {Field, Form, FormSpy} from 'react-final-form';
import {SignInHeader} from './sign-in-header';
import {OnChange} from 'react-final-form-listeners';
import {emailValidator} from './email-validator';
import AuthInput from '../../shared/components/auth/AuthInput';
import analyticService, {AnalyticEventsEnum} from '../../shared/services/analytic.service';
import {useHistory, useLocation} from 'react-router-dom';
import {TermsAndConditions} from '../../shared/components/TermsAndConditions';
import {Box, Button, Typography, makeStyles} from '@material-ui/core';
import {Checkbox} from '../../shared/components/Checkbox';
import clsx from 'clsx';
import {FORM_ERROR} from 'final-form';
import {GoogleAnalyticsId} from '../../analytics-ids';
import SocialButtons from '../../shared/components/auth/SocialButtons';
import {MessageWithButton} from './message-with-button';
import {VerifyOTP} from '../../shared/components/auth/VerifyOTP';
import {useAuth} from '../../shared/hooks/auth.hook';
import {useNotifications} from '../../shared/hooks/use-notifications';
import {IKeys, resendSocialOTP, signInOrAttachEmail} from '../../shared/services/auth.service';
import {createSubscription} from '../../shared/services/subscription.service';
import {SubscriptionTypeEnum} from '../../shared/types/subscription';
import {useGoBack} from './use-go-back';
import {AuthAnalyticsTrackingContext} from './auth-analytics-tracking.context';
import {SocialData} from '../../shared/components/auth/SocialButtons/types';
import {getAuthType} from '../../shared/helpers/nftType';
import {AppContext} from '../../context/app-context';
import {AuthContext} from '../../shared/components/BuyNftGuard';
import {usePurchaseById} from '../../context/purchase-nft-context';
import {Markdown} from '../../shared/components/Markdown';
import {humanReadableError} from '../../shared/lib/error-handler';
import {useLocalization} from '../../shared/hooks/use-locale.hook';
import {SignInKeyConstructInfo} from './sign-in-key-construct-info';
import {ExternalAuthButton} from './external-auth-button';
import {isExternalAuthEnabled} from '../../config/common';

type Props = {
  onComplete?: (email: string, social?: boolean) => unknown;
  withHeader?: boolean;
  noVerify?: boolean;
  buttonText?: (authType: AuthType) => NonNullable<ReactNode>;
  goBackState?: boolean;
  changeGoBackState?: (value: boolean) => void;
};

type FormValues = {
  email: string;
  confirmed: boolean;
  subscribed: boolean;
  authType: AuthType;
};

const useStyles = makeStyles((theme) => ({
  label: {
    color: theme.palette.grey[700],
  },
  cardIcon: {
    width: '17px',
    height: '13px',
    margin: '0 10px 4px 0',

    '& path': {
      stroke: theme.palette.common.white,
    },
  },
  button: {
    margin: '0 auto 0',
    display: 'block',
    padding: '13px 19px',
    [theme.breakpoints.up('md')]: {
      padding: '13px 37px',
    },
    [theme.breakpoints.up('lg')]: {
      margin: 'unset',
    },
  },
  payByCardLink: {
    textAlign: 'center',
    color: theme.palette.secondary.main,
    fontWeight: 600,
    padding: '15px',
    cursor: 'pointer',
  },
  textWrapper: {
    '> p': {
      fontWeight: 600,
      color: theme.palette.secondary.main,
    },
  },
}));

export function AuthForm({
  onComplete,
  withHeader = true,
  noVerify,
  buttonText,
  goBackState,
  changeGoBackState,
}: Props): ReactElement {
  const {t, locale} = useLocalization();
  const styles = useStyles();
  const purchasedNftId = new URLSearchParams(useLocation().search).get('nftId');
  const {setPurchaseDataById, loadingNftId} = usePurchaseById();
  const {variant, setVariant} = useContext(AuthContext);
  const [submittedEmail, setSubmittedEmail] = useState<string>('');
  const [submittedAuthType, setSubmittedAuthType] = useState<SubmittedAuthType | undefined>(undefined);
  const {appConfig} = useContext(AppContext);
  const history = useHistory<{pathname: string | undefined}>();
  const {setAuthData, handleEmailSignUp} = useAuth();
  const {error: errorNotification} = useNotifications();
  const {signUpFocused, signInFocused, emailSubmitted} = useContext(AuthAnalyticsTrackingContext);
  const authType = getAuthType();

  const setNextPage = useCallback(() => {
    let link: string = history?.location?.state?.pathname || `/${locale}/home`;
    localStorage.setItem(`userNextPage`, link);
  }, [history?.location?.state?.pathname, locale]);

  useEffect(() => {
    setNextPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getNexPage = useCallback(() => {
    return purchasedNftId ? `/${locale}/auth/complete/${purchasedNftId}` : `/${locale}/auth/complete`;
  }, [purchasedNftId, locale]);

  const initialValues: FormValues = useMemo(
    () => ({
      email: '',
      confirmed: false,
      subscribed: false,
      authType: authType ? authType : AuthText.SIGN_UP,
    }),
    [authType],
  );

  const goBack = useGoBack();

  useEffect(() => {
    if (purchasedNftId) {
      setPurchaseDataById(purchasedNftId);
    }
  }, [purchasedNftId, setPurchaseDataById]);

  useEffect(() => {
    if (goBackState && changeGoBackState) {
      if (variant === AuthPageState.VERIFY) {
        setVariant(AuthPageState.AUTH);
        changeGoBackState(!goBackState);
      }

      if (variant === AuthPageState.AUTH) {
        goBack();
      }
    }
  }, [goBackState, variant, setVariant, goBack, changeGoBackState]);

  const handleNoVerifyOrVerify = useCallback(
    (email: string) => {
      if (noVerify) {
        if (onComplete) {
          onComplete(email);
        } else {
          goBack();
        }
      } else {
        setVariant(AuthPageState.VERIFY);
      }
    },
    [noVerify, onComplete, setVariant, goBack],
  );

  const submit = useCallback(
    async (values: FormValues) => {
      const {email, authType, subscribed} = values;
      const trimedEmail = email.trim();
      analyticService.track(emailSubmitted);
      let result: undefined | string = undefined;
      if (authType === AuthText.SIGN_IN) {
        await signInOrAttachEmail(trimedEmail, locale).catch((error) => {
          Sentry.captureException(error);
          result = t('Sign in failed. {{errorDetails}}', {errorDetails: humanReadableError(error)});
        });
      } else if (authType === AuthText.SIGN_UP) {
        try {
          await handleEmailSignUp(trimedEmail);

          const payload = {
            email: trimedEmail,
            createdAt: new Date().toUTCString(),
          };
          analyticService.gtmTrack(AnalyticEventsEnum.ACCOUNT_CREATED, payload);
          if (subscribed) {
            await createSubscription(trimedEmail, SubscriptionTypeEnum.MARKETING).catch(() => {
              result = t('The subscription attempt failed. Please, try to subscribe later.');
            });
          }
        } catch (error) {
          const errorDetails = humanReadableError(error);
          Sentry.captureException(error);
          result = t('Sign up failed. {{errorDetails}}', {errorDetails});
        }
      } else {
        result = t('Unknown error occurred.');
      }
      if (result) {
        errorNotification(result);
        return {[FORM_ERROR]: result};
      } else {
        handleNoVerifyOrVerify(trimedEmail);
      }
    },
    [emailSubmitted, handleEmailSignUp, errorNotification, handleNoVerifyOrVerify, t, locale],
  );

  const handleVerify = useCallback(
    async (keys: IKeys) => {
      if (submittedAuthType === SubmittedAuthType.SIGN_UP) {
        const now = new Date();
        const payload = {
          email: submittedEmail,
          createdAt: now.toUTCString(),
        };
        analyticService.gtmTrack(AnalyticEventsEnum.ACCOUNT_CREATED, payload);
      }
      history.push(getNexPage());
      setAuthData(submittedEmail, keys.publicKey, keys.token);
    },
    [setAuthData, submittedAuthType, submittedEmail, history, getNexPage],
  );

  const changeListener = useCallback(({values, pristine}: {values: FormValues; pristine: boolean}) => {
    if (pristine) {
      return;
    }
    setSubmittedEmail(values.email);
    if (values.authType === AuthText.SIGN_IN) {
      setSubmittedAuthType(SubmittedAuthType.SIGN_IN);
    }
    if (values.authType === AuthText.SIGN_UP) {
      setSubmittedAuthType(SubmittedAuthType.SIGN_UP);
    }
  }, []);

  const onSocialSuccess = useCallback(
    async (email: string, subscribed: boolean) => {
      if (subscribed) {
        await createSubscription(email, SubscriptionTypeEnum.MARKETING).catch(() => {
          errorNotification(t('The subscription attempt failed. Please, try to subscribe later.'));
        });
      }

      if (onComplete) {
        onComplete(email, true);
      }

      history.push(getNexPage());
    },
    [errorNotification, onComplete, t, history, getNexPage],
  );

  const onSocialRequestAuth = useCallback(
    async (socialData: SocialData) => {
      setSubmittedEmail(socialData.email);
      setSubmittedAuthType(SubmittedAuthType.SIGN_IN);
      await resendSocialOTP(socialData.type, socialData.token).catch((error) => {
        errorNotification(t('Sign in failed. Please try again later'));
        Sentry.captureException(error);
      });
      handleNoVerifyOrVerify(socialData.email);
    },
    [errorNotification, handleNoVerifyOrVerify, t],
  );

  return (
    <ConditionsList>
      <Condition condition={variant === AuthPageState.AUTH}>
        <Form onSubmit={submit} initialValues={initialValues}>
          {({values, form, hasValidationErrors, pristine, submitting, handleSubmit}) => (
            <>
              {withHeader && loadingNftId === null && (
                <SignInHeader page={variant} authType={values.authType} nftId={purchasedNftId} />
              )}
              <FormSpy onChange={changeListener} subscription={{values: true, pristine: true}} />
              <OnChange name="authType">
                {(value: AuthType) => {
                  if (value !== AuthText.SIGN_IN) {
                    form.change('subscribed', false);
                    form.change('confirmed', false);
                  }
                }}
              </OnChange>
              <ConditionsList>
                <Condition condition={!!appConfig.signUpCta}>
                  <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" pb="15px">
                    <Markdown>{appConfig.signUpCta}</Markdown>
                    <Condition condition={isExternalAuthEnabled()}>
                      <ExternalAuthButton />
                    </Condition>
                  </Box>
                </Condition>
                <Defaults>
                  <Field validate={emailValidator} name="email">
                    {({input, meta}) => (
                      <AuthInput
                        value={input.value}
                        helperText={meta.touched ? meta.error : null}
                        onChange={input.onChange}
                        onBlur={input.onBlur}
                        onFocus={() => {
                          if (values.authType === AuthText.SIGN_IN) {
                            analyticService.track(signInFocused);
                          }
                          if (values.authType === AuthText.SIGN_UP) {
                            analyticService.track(signUpFocused);
                          }
                          input.onFocus();
                        }}
                        inputProps={{inputMode: 'email'}}
                      />
                    )}
                  </Field>
                  <ConditionsList>
                    <Condition condition={values.authType === AuthText.SIGN_UP}>
                      <Field validate={(value) => (value ? null : t('Please agree with to continue'))} name="confirmed">
                        {({input}) => <TermsAndConditions onClick={input.onChange} />}
                      </Field>
                      <Field type="checkbox" name="subscribed">
                        {({input}) => (
                          <Box display="flex" alignItems="flex-start" mt="16px">
                            <Checkbox checked={input.value} onChange={input.onChange} />
                            <Typography variant="caption" className={styles.label}>
                              {t(
                                'I agree to receive important announcements, feature updates and offers from {{appTitle}}',
                                {appTitle: appConfig.appTitle},
                              )}
                            </Typography>
                          </Box>
                        )}
                      </Field>
                    </Condition>
                  </ConditionsList>
                  <Box mt={values.authType === AuthText.SIGN_UP ? '15px' : '24px'} mb="12px">
                    <Button
                      className={clsx(values.authType === AuthText.SIGN_IN && GoogleAnalyticsId.SignInBtn)}
                      color="secondary"
                      size="large"
                      variant="contained"
                      fullWidth
                      type="submit"
                      disabled={hasValidationErrors || pristine || submitting}
                      onClick={handleSubmit}
                    >
                      {buttonText ? <Box>{buttonText(values.authType)}</Box> : values.authType}
                    </Button>
                  </Box>
                </Defaults>
              </ConditionsList>
              <SocialButtons
                disabled={values.authType === AuthText.SIGN_UP && !values.confirmed}
                subscribed={values.subscribed}
                onSuccess={onSocialSuccess}
                authType={values.authType === AuthText.SIGN_IN ? SubmittedAuthType.SIGN_IN : SubmittedAuthType.SIGN_UP}
                onRequestOtp={onSocialRequestAuth}
              />
              <SignInKeyConstructInfo />
              <Field name="authType">
                {({input}) => (
                  <ConditionsList>
                    <Condition condition={input.value === AuthText.SIGN_UP}>
                      <MessageWithButton
                        text={t('Already have an account? ')}
                        button={AuthText.SIGN_IN}
                        onClick={() => {
                          const search = purchasedNftId ? `nftId=${purchasedNftId}` : '';
                          analyticService.track(AnalyticEventsEnum.SIGN_IN_TOGGLE_AUTH_CLICKED);
                          input.onChange(AuthText.SIGN_IN);
                          !!changeGoBackState && history.push({pathname: `/${locale}/home/auth/signin`, search});
                        }}
                      />
                    </Condition>
                    <Condition condition={input.value === AuthText.SIGN_IN}>
                      <MessageWithButton
                        text={t('No account yet? ')}
                        button={AuthText.SIGN_UP}
                        onClick={() => {
                          const search = purchasedNftId ? `nftId=${purchasedNftId}` : '';
                          analyticService.track(AnalyticEventsEnum.SIGN_IN_TOGGLE_AUTH_CLICKED);
                          input.onChange(AuthText.SIGN_UP);
                          !!changeGoBackState && history.push({pathname: `/${locale}/home/auth/signup`, search});
                        }}
                      />
                    </Condition>
                  </ConditionsList>
                )}
              </Field>
            </>
          )}
        </Form>
      </Condition>
      <Condition condition={variant === AuthPageState.VERIFY}>
        {withHeader && <SignInHeader page={variant} authType="Sign in" />}
        <VerifyOTP email={submittedEmail} onVerify={handleVerify} onFailResend={() => setVariant(AuthPageState.AUTH)} />
        <SignInKeyConstructInfo />
      </Condition>
    </ConditionsList>
  );
}
