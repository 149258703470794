import {ReactComponentElement, useCallback, useContext, useMemo, useState} from 'react';
import {Redirect, useHistory} from 'react-router-dom';
import {Modal} from '@cere/rxb-template-ui-kit';
import {FullCreatorInterface, NftType} from '@cere/services-types';
import {Box, Button, Divider, Grid, Typography} from '@material-ui/core';
import clsx from 'clsx';

import {useLoadNftById} from 'api/hooks/use-load-nft-by-id';
import {GoogleAnalyticsId} from 'analytics-ids';
import {ReactComponent as InfoIcon} from 'assets/info.svg';
import {ReactComponent as CheckIcon} from 'assets/check.svg';
import {isInterstitialPageList} from 'config/common';
import {ExhibitContext} from 'context/exhibition/exhibit-context';
import {PurchaseNFTContextProvider, usePurchaseById} from 'context/purchase-nft-context';

import Share from 'shared/components/Share';
import {getShareUrl} from 'shared/components/Share/utils/get-share-url';
import {AccessNftInfoModal} from 'shared/components/AccessNftInfoModal';
import {NftTicketCardWithSelect} from 'shared/components/NftTicketCard/NftTicketCardWithSelect';
import {NftTicketCard} from 'shared/components/NftTicketCard';
import {ShareEmailModal} from 'shared/components/ShareEmailModal';
import {TicketInfoModal} from 'shared/components/TicketInfoModal';
import {NftExhibitionCard} from 'shared/components/NftCardBig/nft-exhibition-card';
import {NftProvider} from 'shared/components/Nft/nft-provider';
import {Condition, ConditionsList, Defaults} from 'shared/components/Conditions';
import {AboutUpcomingAuctionInfo} from 'shared/components/auction/AboutAuctionTickets';
import {NFTModalSimple} from 'shared/components/NFTModalSimple';
import {ConfirmationModalContent} from 'shared/components/ConfirmationModal';
import {NftProvider2} from 'shared/components/Nft/nft-provider-v2';
import {Title} from 'shared/components/Title';
import {
  InterstitialEventPageBannerSkeleton,
  InterstitialEventTeaserSkeleton,
  ExhibitAboutInfo,
  InterstitialNftTicketsListSkeleton,
  AboutUpcomingAuctionInfoSkeleton,
} from 'shared/components/Skeletons';
import {checkNftMayBePurchased} from 'shared/components/NftCardBig/helpers';

import {hashFromObjectHelper} from 'shared/helpers/hash-from-object.helper';
import {getNFTBalance, getNFTPrice, getNFTTotalOfferedQty} from 'shared/helpers/nfts';
import {useExchangeRate} from 'shared/hooks/use-exchange-rate';
import {useLocalization} from 'shared/hooks/use-locale.hook';
import {cereToUSD, formatPrice} from 'shared/lib/formatPrice';
import {getMediaUrl} from 'shared/lib/media';
import {omitUndefined} from 'shared/lib/omit-undefined';
import {getFirstInAssetsImage} from 'shared/services/asset.service';
import analyticService, {AnalyticEventsEnum} from 'shared/services/analytic.service';
import {createSubscription} from 'shared/services/subscription.service';
import {getNftAvailabilityByCmsNft, NftAvailability} from 'shared/services/nft.service';
import {CmsExhibitionNft, CmsExhibitionNftAccess, CmsExhibitionNftRelType} from 'shared/types/graphql';
import {SubscriptionTypeEnum} from 'shared/types/subscription';
import {ExhibitionTeaserType} from 'shared/types/exhibition-teaser';
import {Numberish} from 'shared/types/numberish';
import {EventTimelineStatus} from 'shared/types/event';

import {useConfirmationStyles} from '../../../ExhibitPage/routes/WebInterstitialHomePage/Exhibition-preview-container/live-styles';
import {ExhibitTeaser} from '../../../ExhibitPage/routes/WebInterstitialHomePage/Exhibition-preview-container/exhibit-teaser';
import {AccessNftSectionTitle} from '../../../ExhibitPage/routes/WebInterstitialHomePage/ExhibitPageTopCards/AccessNftSectionTitle';
import {getTimelineStatus} from '../../../Сms/Exhibit/utils';
import {AuthAnalyticsTrackingProvider} from '../../../SignInPage/auth-analytics-tracking.context';
import {InterstitialEventPageBanner} from './InterstitialEventPageBanner';
import {InterstitialEventPageButtons} from './InterstitialEventPageButtons';
import {InterstitialEventPageBuyButton} from './InterstitialEventPageBuyButton';
import {PreviewContainer, useStyles as useInterstitialStyles} from './styled';
import {PageLayout} from 'shared/components/PageLayout';
import {PageContainer} from 'shared/components/PageContainer';
import {InterstitialInfo} from './InterstitialInfo';

const PREVIEW_KEY = 'preview_key';

// TODO: remove this component when this page is moved to use BFF
const NftExhibitionTicket = ({nft}: {nft: CmsExhibitionNftAccess}) => {
  const {nft: nftCard} = useLoadNftById(nft?.cmsNft?.id.toString());
  return (
    <>
      {nftCard && (
        <NftProvider2 nft={nftCard}>
          <NftExhibitionCard nft={{nftType: NftType.ACCESS}} />
        </NftProvider2>
      )}
    </>
  );
};

export const InterstitialEventPage = () => {
  const {t, locale} = useLocalization();
  const history = useHistory();

  const {event, error, loading: isLoading, accessNfts} = useContext(ExhibitContext);
  const [showShareModal, setShowShareModal] = useState(false);
  const {startNFTPurchaseById} = usePurchaseById();

  const query = new URLSearchParams(history.location.search);
  const eventSlug = query.get('event') ?? undefined;

  const isEventFinished = useMemo(
    () => (event ? getTimelineStatus(event) === EventTimelineStatus.FINISHED : false),
    [event],
  );

  const [shareNftId, setShareNftId] = useState<Numberish>();
  const [shareNftTitle, setShareNftTitle] = useState<string>();
  const [shareNftDescription, setShareNftDescription] = useState<string>();
  const [shareNftImageUrl, setShareNftImageUrl] = useState<string>();
  const toggleShareNft = (nftId: Numberish, title: string, description: string, imageUrl: string) => {
    setShowShareModal((val) => !val);
    setShareNftId(nftId);
    setShareNftTitle(title);
    setShareNftDescription(description);
    setShareNftImageUrl(imageUrl);
  };

  const [showModal, setShowModal] = useState(false);
  const [showModalConfirmation, setShowModalConfirmation] = useState(false);
  const [showStayUpdatedModalConfirmation, setShowStayUpdatedModalConfirmation] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('');
  const [dialogContent, setDialogContent] = useState<ReactComponentElement<any> | null>(null);

  const [showShareEmailModal, setShareEmailModal] = useState(false);
  const [showTicketInfoModal, setShowTicketInfoModal] = useState(false);

  const orderedAccessNfts = useMemo(() => {
    const eventStatus = getTimelineStatus(event);
    const accessNftsWithOfferedQtyAndAvailability = accessNfts.map((nft) => {
      const nftAvailability = getNftAvailabilityByCmsNft(nft).availability;
      return {
        ...nft,
        balance: getNFTBalance(nft?.cmsNft, nft.relType),
        isOfferEnded: eventStatus === EventTimelineStatus.FINISHED || nftAvailability === NftAvailability.PASSED,
      };
    });
    const availableNftIndex = accessNftsWithOfferedQtyAndAvailability.findIndex(
      (nft) => nft.balance > 0 && !nft.isOfferEnded,
    );
    if (availableNftIndex < 0) {
      return accessNftsWithOfferedQtyAndAvailability;
    }
    const [availableNft] = accessNftsWithOfferedQtyAndAvailability.splice(availableNftIndex, 1);
    return [availableNft, ...accessNftsWithOfferedQtyAndAvailability];
  }, [accessNfts, event]);

  const [mainAccessNft, ...noDuplicatedNfts] = orderedAccessNfts;

  const accessNftDialogData = useMemo(
    () => ({
      title: mainAccessNft?.cmsNft.title,
      content: ({handleButtonClick}: {handleButtonClick: Function}) => (
        <AccessNftInfoModal handleButtonClick={handleButtonClick} />
      ),
    }),
    [mainAccessNft?.cmsNft.title],
  );

  const [selectedNft, setSelectedNft] = useState<CmsExhibitionNft>(mainAccessNft);

  const eventTimelineStatus = useMemo<EventTimelineStatus>(() => getTimelineStatus(event), [event]);

  const handleCloseDialog = () => {
    if (dialogTitle === accessNftDialogData.title) {
      analyticService.track(AnalyticEventsEnum.INTERSTITIAL_ACCESS_NFT_CLOSE_CLICKED);
    }

    setShowModal(false);
  };

  const handleCloseShareEmailModal = () => {
    setShareEmailModal(false);
  };

  const handleOpenTicketInfoModal = () => {
    analyticService.track(AnalyticEventsEnum.INTERSTITIAL_WHAT_IS_TICKET_CLICKED);
    setShowTicketInfoModal(true);
  };

  const handleCloseTicketInfoModal = () => {
    setShowTicketInfoModal(false);
  };

  const handleOpenModalConfirmation = () => {
    setShowModalConfirmation(true);
  };

  const handleCloseModalConfirmation = () => {
    setShowModalConfirmation(false);
  };

  const handleOpenStayUpdatedModalConfirmation = () => {
    setShowStayUpdatedModalConfirmation(true);
  };

  const handleCloseStayUpdatedModalConfirmation = () => {
    setShowStayUpdatedModalConfirmation(false);
  };

  const handleSubscribeToExhibit = async (userEmail: string) => {
    setShareEmailModal(false);
    await createSubscription(userEmail, SubscriptionTypeEnum.EXHIBIT, Number(event?.id ?? 0), locale);
    analyticService.track(AnalyticEventsEnum.SUBSCRIBE_EMAIL_ON_EXIT, {
      exhibitId: event?.id ?? '',
      email: userEmail,
      subscribedAt: new Date().toLocaleString('en-US'),
    });
  };

  const handleGotButtonClick = () => {
    analyticService.track(AnalyticEventsEnum.INTERSTITIAL_ACCESS_NFT_GOT_IT_CLICKED);

    setShowModal(false);
  };

  const handleClickLearnMoreButton = () => {
    analyticService.track(AnalyticEventsEnum.INTERSTITIAL_ACCESS_NFT_CLICKED);

    setDialogTitle(t('NFT tickets'));
    setDialogContent(accessNftDialogData.content({handleButtonClick: handleGotButtonClick}));

    setShowModal(true);
  };

  const handlePurchase = useCallback(
    async (nft: CmsExhibitionNft, amount: number) => {
      if (nft?.cmsNft.id) {
        await startNFTPurchaseById(nft?.cmsNft.id.toString(), amount);
      }
    },
    [startNFTPurchaseById],
  );

  const getAvailabilityParams = (nft?: CmsExhibitionNft) => getNftAvailabilityByCmsNft(nft || undefined);

  const shouldShowEventTeaser = useMemo(() => {
    if (!event?.teaser?.content) {
      return false;
    }

    return [EventTimelineStatus.NOT_STARTED, EventTimelineStatus.STARTED].includes(eventTimelineStatus);
  }, [event, eventTimelineStatus]);

  const styles = useInterstitialStyles();
  const confirmationStyles = useConfirmationStyles();

  const unitsPerPenny = useExchangeRate();
  const selectedPrice = selectedNft ? getNFTPrice(selectedNft?.cmsNft, selectedNft.relType) : 0;
  const nftPrice = useMemo(() => formatPrice(cereToUSD(selectedPrice, unitsPerPenny)), [selectedPrice, unitsPerPenny]);

  const canBePurchased = useCallback(
    (nft?: CmsExhibitionNft) =>
      checkNftMayBePurchased(
        eventTimelineStatus,
        CmsExhibitionNftRelType.ACCESS,
        getAvailabilityParams(nft).availability,
        getNFTTotalOfferedQty(nft?.cmsNft),
      ),
    [eventTimelineStatus],
  );

  const nftTitle =
    accessNfts.find((nft) => nft?.cmsNft?.creatorNft?.nft_id === selectedNft?.cmsNft?.creatorNft?.nft_id)?.cmsNft
      ?.title || '';

  const nftImgSrc =
    getFirstInAssetsImage(
      accessNfts.find((nft) => nft?.cmsNft?.creatorNft?.nft_id === selectedNft?.cmsNft?.creatorNft?.nft_id)?.cmsNft
        ?.assets || [],
    ) || '';

  const ticketModalTitle = event?.ticketModalTitle || '';
  const ticketModalBody = event?.ticketModalBody || '';

  const getSearchQuery = useCallback(() => {
    const searchParams = new URLSearchParams(history.location.search);
    const previewKey = searchParams.get(PREVIEW_KEY);
    if (!previewKey) {
      return '';
    }
    return new URLSearchParams({[PREVIEW_KEY]: previewKey}).toString();
  }, [history.location.search]);

  if (error || (!isLoading && event === undefined)) {
    throw error;
  }

  if (isEventFinished) {
    return <Redirect to={{pathname: `/${locale}/home/exhibit/${eventSlug}`, search: getSearchQuery()}} />;
  }

  return (
    <PageLayout hideBackButton>
      <Box className={styles.root}>
        <PurchaseNFTContextProvider>
          <ConditionsList>
            <Condition condition={!event}>
              <InterstitialEventPageBannerSkeleton />
            </Condition>
            <Defaults>
              <PreviewContainer previewImageSrc={getMediaUrl(event?.image)}>
                <Box className={styles.bannerBox}>
                  <Box className={styles.bannerTextBox}>
                    <Box className={styles.bannerText}>
                      <ConditionsList>
                        <Condition condition={Boolean(mainAccessNft)}>
                          <NftProvider nft={mainAccessNft}>
                            <InterstitialEventPageBanner />
                            <InterstitialEventPageButtons
                              setSelectedNft={setSelectedNft}
                              canBePurchased={canBePurchased(mainAccessNft)}
                              handlePurchase={handlePurchase}
                              onClickStayUpdatedConfirmation={handleOpenStayUpdatedModalConfirmation}
                              handleOpenTicketInfoModal={handleOpenTicketInfoModal}
                            />
                          </NftProvider>
                        </Condition>
                        <Defaults>
                          <InterstitialEventPageBanner />
                          <InterstitialEventPageButtons
                            setSelectedNft={setSelectedNft}
                            canBePurchased={canBePurchased(mainAccessNft)}
                            handlePurchase={handlePurchase}
                            onClickStayUpdatedConfirmation={handleOpenStayUpdatedModalConfirmation}
                          />
                        </Defaults>
                      </ConditionsList>
                    </Box>

                    <ConditionsList>
                      <Condition condition={Boolean(mainAccessNft)}>
                        <NftProvider nft={mainAccessNft}>
                          <Box
                            className={clsx(styles.whatIsTicket, styles.hideDesktop)}
                            onClick={handleOpenTicketInfoModal}
                          >
                            <Box className={clsx(GoogleAnalyticsId.WhatIsTicketBtn)}>{t('What is an NFT ticket?')}</Box>
                            <Box ml="10px">
                              <InfoIcon />
                            </Box>
                          </Box>
                        </NftProvider>
                      </Condition>
                    </ConditionsList>
                  </Box>
                  <Box mt="2rem" maxWidth="340px" width="100%">
                    {!!accessNfts.length && mainAccessNft && (
                      <ConditionsList>
                        <Condition condition={isInterstitialPageList()}>
                          <NftProvider nft={mainAccessNft}>
                            <NftTicketCard
                              analyticClassname={GoogleAnalyticsId.BuyAccessBtn}
                              handleClickLearn={handleClickLearnMoreButton}
                              withCtaAction={() => {
                                analyticService.track(AnalyticEventsEnum.INTERSTITIAL_SECONDARY_ACCESS_CLICKED);
                                setSelectedNft(mainAccessNft);
                              }}
                              canBePurchased={canBePurchased(mainAccessNft)}
                              withInfoIcon={false}
                            />
                            {showTicketInfoModal && (
                              <TicketInfoModal
                                onClose={handleCloseTicketInfoModal}
                                onClick={handleSubscribeToExhibit}
                                title={ticketModalTitle}
                                body={ticketModalBody}
                                onFocusAnalyticsEvent={AnalyticEventsEnum.INTERSTITIAL_SUBSCRIBE_EMAIL_ON_EXIT_FOCUSED}
                                onSubmitAnalyticsEvent={AnalyticEventsEnum.INTERSTITIAL_SUBSCRIBE_ON_EXIT_SUBMIT}
                                ctaButton={
                                  <InterstitialEventPageBuyButton
                                    setSelectedNft={setSelectedNft}
                                    canBePurchased={canBePurchased(mainAccessNft)}
                                    handlePurchase={handlePurchase}
                                  />
                                }
                              />
                            )}
                          </NftProvider>
                        </Condition>
                        <Defaults>
                          <NftProvider nft={selectedNft}>
                            <NftTicketCardWithSelect
                              analyticClassname={GoogleAnalyticsId.BuyAccessBtn}
                              nfts={accessNfts}
                              handleClickLearn={handleClickLearnMoreButton}
                              withCtaAction={() => {
                                analyticService.track(AnalyticEventsEnum.INTERSTITIAL_NFT_CARD_BUY_CLICKED, {
                                  nftId: selectedNft?.cmsNft?.creatorNft?.nft_id ?? '',
                                  nftTitle: selectedNft?.cmsNft?.title,
                                  artistName: selectedNft?.cmsNft.cmsCreator?.name,
                                  eventName: event?.title!,
                                  nftType: selectedNft.relType,
                                });
                              }}
                              canBePurchased={canBePurchased(selectedNft)}
                              setSelectedNft={setSelectedNft}
                            />
                          </NftProvider>
                        </Defaults>
                      </ConditionsList>
                    )}
                  </Box>
                </Box>

                <Modal open={showModal} onClose={handleCloseDialog} title={dialogTitle} maxWidth="sm">
                  {dialogContent}
                </Modal>
                <AuthAnalyticsTrackingProvider
                  buySendClicked={AnalyticEventsEnum.INTERSTITIAL_BUY_SEND_CLICKED}
                  emailSubmitted={AnalyticEventsEnum.SIGN_UP_INTERSTITIAL_SUBMIT_BUTTON_CLICKED}
                  signUpFocused={AnalyticEventsEnum.INTERSTITIAL_BUY_EMAIL_FOCUSED}
                  signInFocused={AnalyticEventsEnum.INTERSTITIAL_BUY_EMAIL_FOCUSED}
                >
                  {showShareEmailModal && (
                    <ShareEmailModal
                      onClose={handleCloseShareEmailModal}
                      onClick={handleSubscribeToExhibit}
                      onClickConfirmation={handleOpenModalConfirmation}
                      title={nftTitle}
                      price={nftPrice}
                      imgSrc={nftImgSrc}
                      onFocusAnalyticsEvent={AnalyticEventsEnum.INTERSTITIAL_SUBSCRIBE_EMAIL_ON_EXIT_FOCUSED}
                      onSubmitAnalyticsEvent={AnalyticEventsEnum.INTERSTITIAL_SUBSCRIBE_ON_EXIT_SUBMIT}
                    />
                  )}
                </AuthAnalyticsTrackingProvider>

                {showModalConfirmation && (
                  <NFTModalSimple open={true} onClose={handleCloseModalConfirmation}>
                    <Box className={confirmationStyles.confirmationContainer}>
                      <CheckIcon className={confirmationStyles.checkIcon} />
                      <Typography className={confirmationStyles.title}>{t('You’re email is subscribed')}</Typography>
                      <Typography className={confirmationStyles.message}>{t('Stay tuned for updates!')}</Typography>
                      <Button
                        variant="outlined"
                        className={confirmationStyles.button}
                        onClick={handleCloseModalConfirmation}
                      >
                        <Typography className={confirmationStyles.buttonText}>{t('Ok')}</Typography>
                      </Button>
                    </Box>
                  </NFTModalSimple>
                )}
                {showStayUpdatedModalConfirmation && (
                  <NFTModalSimple
                    open={true}
                    onClose={handleCloseStayUpdatedModalConfirmation}
                    title={t('Share your email to buy this NFT')}
                  >
                    <ConfirmationModalContent onClick={handleCloseStayUpdatedModalConfirmation} />
                  </NFTModalSimple>
                )}
              </PreviewContainer>
            </Defaults>
          </ConditionsList>

          <PageContainer>
            <ConditionsList>
              <Condition condition={!event}>
                <InterstitialEventTeaserSkeleton />
              </Condition>
              <Condition condition={shouldShowEventTeaser}>
                <Box width="100%" display="flex" justifyContent="center">
                  <Box className={styles.teaserBox}>
                    <ExhibitTeaser type={ExhibitionTeaserType.REGULAR} />
                  </Box>
                </Box>
              </Condition>
            </ConditionsList>

            <ConditionsList>
              <Condition condition={!event}>
                <Box pt={6} width="100%">
                  <Title>{t('About the drop')}</Title>as
                  <Box pt={1} />
                  <ExhibitAboutInfo />
                </Box>
              </Condition>
              <Defaults>
                <InterstitialInfo creator={event?.cmsCreator! as FullCreatorInterface} />
                <Box>
                  <Divider className={styles.divider} />
                </Box>
              </Defaults>
            </ConditionsList>

            <ConditionsList>
              <Condition condition={!event}>
                <InterstitialNftTicketsListSkeleton title={t('NFT tickets')} />
              </Condition>
              <Condition condition={isInterstitialPageList() && noDuplicatedNfts.length > 0}>
                <Box width="100%" mt="2rem">
                  <AccessNftSectionTitle
                    toggleShowModal={handleClickLearnMoreButton}
                    infoText={`NFT tickets from ${event?.cmsCreator.name!}`}
                  />
                  <Grid container spacing={4} className={styles.gridContainer}>
                    {noDuplicatedNfts.filter(omitUndefined).map((nft) => (
                      <Grid item xs={12} md={6} lg={4}>
                        <Box key={hashFromObjectHelper(nft)}>
                          <NftExhibitionTicket nft={nft} />
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </Condition>
            </ConditionsList>

            <ConditionsList>
              <Condition condition={!event}>
                <AboutUpcomingAuctionInfoSkeleton />
              </Condition>
              <Defaults>
                <AboutUpcomingAuctionInfo isInterstitialPage previewImageUrl={event?.NFT_visual_exhibitupload?.url!} />
              </Defaults>
            </ConditionsList>
          </PageContainer>

          <Share
            isOpen={showShareModal}
            onClose={() => {
              toggleShareNft(shareNftId || '', shareNftTitle || '', shareNftDescription || '', shareNftImageUrl || '');
            }}
            title={t('Share NFT')}
            description={shareNftTitle || ''}
            imgSrc={shareNftImageUrl || ''}
            url={getShareUrl(
              `/${locale}/home/nft/${shareNftId}`,
              shareNftTitle || '',
              shareNftDescription || '',
              shareNftImageUrl || '',
            )}
          />
        </PurchaseNFTContextProvider>
      </Box>
    </PageLayout>
  );
};
