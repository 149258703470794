import {gql} from '@apollo/client';

export const creatorWalletNfts = gql`
  query creatorWalletNfts($wallets: [String], $locale: String) {
    wallets: creatorWalletNfts(
      where: {wallet_in: $wallets, quantity_gt: 0, nft_id: {cmsNfts_null: false, cmsNfts: {locale: $locale}}}
    ) {
      wallet
    }
  }
`;
