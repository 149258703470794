import React, {createContext, useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {Numberish} from '../../shared/types/numberish';

export enum PendingTransactionTypes {
  BUY_NFT = 'BUY_NFT',
  SELL_NFT = 'SELL_NFT',
  CANCEL_NFT_LISTING = 'CANCEL_NFT_LISTING',
}

export type PendingTransaction = {
  type: PendingTransactionTypes;
  wallet: string;
  nftId: string;
  price?: number;
  qty?: number;
  timestamp?: string;
};

export interface PendingTransactionContextType {
  setPendingTransactionForNft(transaction: PendingTransaction): Promise<void>;
  clearPendingTransactionForNft(nftId: Numberish | string | undefined): void;
  getPendingTransactionForNft(nftId: Numberish | string | undefined): PendingTransaction | null;
  getPendingTransactionsByType(type: PendingTransactionTypes): PendingTransaction[];
}

export const PendingTransactionContext = createContext<PendingTransactionContextType>({
  setPendingTransactionForNft: () => Promise.resolve(),
  clearPendingTransactionForNft: () => null,
  getPendingTransactionForNft: () => null,
  getPendingTransactionsByType: () => [],
});

const PENDING_TRANSACTIONS_KEY = 'PENDING_TRANSACTIONS';

export const usePendingTransactionsContext = () => {
  return useContext(PendingTransactionContext);
};

export const PendingTransactionContextProvider: React.FC = ({children}) => {
  const [pendingTransactions, setPendingTransactions] = useState<{[key: string]: PendingTransaction}>({});

  const clearPendingTransactionForNft = useCallback(
    async (nftId: Numberish | string) => {
      setPendingTransactions((prevTransactions) => {
        const transactions = {
          ...prevTransactions,
        };
        delete transactions[nftId];
        localStorage.setItem(PENDING_TRANSACTIONS_KEY, JSON.stringify(transactions));
        return transactions;
      });
    },
    [setPendingTransactions],
  );

  useEffect(() => {
    const transactionsJson = localStorage.getItem(PENDING_TRANSACTIONS_KEY);
    const transactions = transactionsJson ? JSON.parse(transactionsJson) : {};
    setPendingTransactions(transactions);
  }, [setPendingTransactions]);

  const setPendingTransactionForNft = useCallback(
    async (transaction: PendingTransaction) => {
      const {nftId} = transaction;
      if (!nftId) {
        return;
      }
      setPendingTransactions((prevTransactions) => {
        const transactions = {
          ...prevTransactions,
          [nftId]: transaction,
        };
        localStorage.setItem(PENDING_TRANSACTIONS_KEY, JSON.stringify(transactions));
        return transactions;
      });
    },
    [setPendingTransactions],
  );

  const getPendingTransactionForNft = useCallback(
    (nftId: Numberish | string | undefined): PendingTransaction | null => {
      if (!nftId) {
        return null;
      }
      return pendingTransactions[nftId] || null;
    },
    [pendingTransactions],
  );

  const getPendingTransactionsByType = useCallback(
    (type: PendingTransactionTypes) =>
      Object.values(pendingTransactions).filter((transaction) => transaction.type === type),
    [pendingTransactions],
  );

  const values = useMemo(
    () => ({
      getPendingTransactionsByType,
      clearPendingTransactionForNft,
      setPendingTransactionForNft,
      getPendingTransactionForNft,
    }),
    [
      getPendingTransactionsByType,
      setPendingTransactionForNft,
      getPendingTransactionForNft,
      clearPendingTransactionForNft,
    ],
  );

  return <PendingTransactionContext.Provider value={values}>{children}</PendingTransactionContext.Provider>;
};
