import {Box} from '@material-ui/core';
import {Typography} from '@cere/rxb-template-ui-kit';
import {useQuery} from '@apollo/client';
import {useFetchNftData} from 'pages/NftPage/nft-page.service';
import {CmsExhibitionNftRelType, CmsNft, CmsNftWithWallets} from 'shared/types/graphql';
import {GET_NFT} from 'shared/queries/nfts';
import {useFindNftRelType} from '../../../shared/components/Nft/use-find-related-exhibition';
import {NftInfo} from './nft-info';
import {useStyles} from './styles';

type CollectionsProps = {
  title: string;
  id: string;
  cmsNft: CmsNft;
  limit: number;
  qtyOwned: number;
};

type Variables = {
  id: string | number;
};

export const NftInfoCollection = ({title, id, cmsNft, limit, qtyOwned}: CollectionsProps) => {
  const {balance} = useFetchNftData(id, () => {}, cmsNft);

  const classes = useStyles();
  const {data: nftData} = useQuery<{cmsNft: CmsNftWithWallets}, Variables>(GET_NFT, {
    variables: {id: cmsNft.id},
  });
  const nftType: CmsExhibitionNftRelType | undefined = useFindNftRelType(nftData?.cmsNft?.creatorNft?.nft_id);

  return (
    <Box>
      <Box>
        <Typography variant="h4" className={classes.title}>
          {title}
        </Typography>
      </Box>
      <NftInfo nft={cmsNft} nftType={nftType} limit={limit} balance={balance} qtyOwned={qtyOwned} />
    </Box>
  );
};
