import {makeStyles, Theme} from '@material-ui/core';
import colors from '../../../styles/colors';

export const useDefaultButtonStyles = makeStyles((theme) => ({
  default: {
    height: '48px',
    padding: '4px 16px',
    borderRadius: '100px',
    textTransform: 'none',
    textDecoration: 'none',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',

    [theme.breakpoints.up('md')]: {
      height: '44px',
    },
  },
}));

export const useBuyButtonStyles = makeStyles(() => ({
  default: {},
  green: {
    color: colors.green,
    background: colors.snowWhite,
    border: `1px solid ${colors.green}`,
    fontSize: '12px',
    lineHeight: '20px',
  },
}));

export const useBidButtonStyles = useBuyButtonStyles;

export const useSellButtonStyles = makeStyles((theme) => ({
  default: {
    border: `1px solid ${theme.palette.secondary.main}`,
    background: colors.snowWhite,
    color: theme.palette.secondary.main,
  },
}));

export const useDisabledButtonStyles = makeStyles<Theme, {isDarkTheme?: boolean}>(() => ({
  default: {
    background: ({isDarkTheme}) => (isDarkTheme ? `${colors.disable}` : `${colors.lighter} !important`),
    color: ({isDarkTheme}) => (isDarkTheme ? `${colors.disable}` : `${colors.disable} !important`),
    cursor: 'default',
    fontSize: '14px',
    lineHeight: '20px',
  },
}));

export const useDisabledSellButtonStyles = makeStyles(() => ({
  default: {
    background: `${colors.disable} !important `,
    color: `${colors.light} !important`,
  },
}));
