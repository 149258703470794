import {ReactElement, ReactNode, useContext} from 'react';
import {PartialExhibitionNft} from './types';
import {NftInnerProvider} from './nft-inner-provider';
import {NftContext} from './nft-context';
import WithPurchaseNft from '../WithPurchaseNft/v2';

type Props = {
  children: ReactNode;
  nft: PartialExhibitionNft;
  isNftDetailsPage?: boolean;
};

export function NftProvider({nft, children, isNftDetailsPage}: Props): ReactElement {
  const {cmsNftId} = useContext(NftContext);

  if (cmsNftId === nft.cmsNft.id) {
    return <>{children}</>;
  }

  return (
    <WithPurchaseNft>
      {({startNFTBuyOrRequestAuth}) => (
        <NftInnerProvider
          nft={nft}
          startNFTBuyOrRequestAuth={startNFTBuyOrRequestAuth}
          isNftDetailsPage={isNftDetailsPage}
        >
          {children}
        </NftInnerProvider>
      )}
    </WithPurchaseNft>
  );
}
