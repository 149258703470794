import {ChangeEvent, useCallback} from 'react';
import {Box, TextField, Theme, useMediaQuery} from '@material-ui/core';
import clsx from 'clsx';
import {useFiltersStyles} from './styles';
import {CollapsibleCard} from '../CollapsibleCard';
import {useFilter} from '../../../context/filters-context';
import {useLocalization} from '../../hooks/use-locale.hook';

const FROM_FILTER_VALUE = 'minPrice';
const TO_FILTER_VALUE = 'maxPrice';

export const PriceFilter = () => {
  const styles = useFiltersStyles();
  const {t} = useLocalization();
  const {filterParams, setFilterValue} = useFilter();
  const isDesktop = useMediaQuery<Theme>((theme) => `${theme.breakpoints.up('lg')}`);

  const setValue = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const {name, value} = event.currentTarget;
      setFilterValue(name, value);
    },
    [setFilterValue],
  );

  return (
    <CollapsibleCard
      className={styles.collapsible}
      title={t('Price')}
      initiallyOpened={isDesktop}
      stayOpened={isDesktop}
      noDivider
    >
      <Box className={styles.filterContent}>
        <TextField
          className={clsx(styles.input, styles.priceInput)}
          placeholder={t('From')}
          variant="outlined"
          name={FROM_FILTER_VALUE}
          value={filterParams[FROM_FILTER_VALUE]}
          onChange={setValue}
        />
        <TextField
          className={clsx(styles.input, styles.priceInput)}
          placeholder={t('To')}
          variant="outlined"
          value={filterParams[TO_FILTER_VALUE]}
          name={TO_FILTER_VALUE}
          onChange={setValue}
        />
      </Box>
    </CollapsibleCard>
  );
};
