import {useContext, useMemo, useRef, useState} from 'react';
import {Box, Button, Divider, makeStyles, Paper, Typography} from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import clsx from 'clsx';
import {PurchaseModal} from '../PurchaseModal';
import colors from '../../../styles/colors';
import {CopyButton} from '../CopyButton';
import {TOKEN_TITLE} from '../../lib/formatPrice';
import {NFTCTAButton} from '../NFTCTAButton';
import {useConnectNonCustodyWallet} from '../../hooks/use-connect-wallet';
import {useSelectedWallet} from '../../../context/use-selected-wallet';
import {NonCustodyWalletTitles, NonCustodyWalletTypeEnum, isNonCustodyWalletType} from '../../types/non-custody-wallet';
import {useSingletonFetchUserWalletBalance} from '../../hooks/user-balance.hook';
import {WalletBalance} from '../WalletBalance';
import {TorusWalletFundButton} from '../TorusWalletFundButton';
import {ReactComponent as WalletIcon} from '../../../assets/icons/wallet_colored.svg';
import {ReactComponent as Card} from '../../../assets/icons/card.svg';
import {QuantityField} from '../fields/quantity/quantity.field';
import NftModalHighlightCard from '../NftModalHighlightCard';
import {mobileLandscapeMediaQuery} from '../../../styles/mediaQueries';
import {useLocalization} from '../../hooks/use-locale.hook';
import {Condition} from '../Conditions';
import {AppContext} from '../../../context/app-context';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: '22px',

    [theme.breakpoints.up('md')]: {
      paddingTop: '32px',
    },

    [mobileLandscapeMediaQuery(theme)]: {
      display: 'flex',
      gap: '20px',
    },
  },
  subBox: {
    [mobileLandscapeMediaQuery(theme)]: {
      width: '50%',
    },
  },

  button: {
    width: '208px',
    height: '36px',
    borderRadius: '50px',
    [theme.breakpoints.up('lg')]: {
      width: '200px',
      height: '44px',
    },
  },
  icon: {
    '--size': '18px',
    height: 'var(--size)',
    width: 'var(--size)',
    color: theme.palette.info.main,
  },
  dataLabel: {
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '22px',
    color: colors.grey,
    marginBottom: '4px',
    fontFamily: 'inherit',
    letterSpacing: 'inherit',
  },
  dataText: {
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '22px',
    color: colors.primaryDark,
    marginBottom: '4px',
    maxWidth: '325px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontFamily: 'inherit',
    letterSpacing: 'inherit',
  },
  quantity: {
    width: '196px',
    margin: '0',
    padding: '0',
    '& .MuiOutlinedInput-root': {
      padding: '0 0 0 10px',
      margin: '0',
      border: `1px solid ${colors.lighter}`,
      borderRadius: `12px`,
      backgroundColor: colors.light,
    },
  },
  dataContainer: {
    marginBottom: '8px',
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  copyButtonContainer: {
    marginBottom: '16px',
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& button, & button svg': {
      width: '20px',
      height: '20px',
    },
  },
  dataLine: {
    marginBottom: '16px',
  },
  netSales: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    '& p': {
      margin: 0,
    },
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '16px auto 0 auto',

    [mobileLandscapeMediaQuery(theme)]: {
      flexDirection: 'row-reverse',
      justifyContent: 'flex-start',
    },
  },
  buttonContainerInnerSpaccing: {
    marginBottom: '16px',

    [mobileLandscapeMediaQuery(theme)]: {
      marginBottom: '0',
      marginLeft: '16px',
    },
  },
  connectWalletContainer: {
    margin: '20px 0 16px 0',
    [theme.breakpoints.up('md')]: {
      margin: '10px 0 20px 0',
    },
    [theme.breakpoints.up('lg')]: {
      margin: '24px 0 16px 0',
    },
  },
  connectWalletButton: {
    padding: 0,
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '20px',
    textTransform: 'none',
    color: theme.palette.primary.main,
    fontFamily: 'inherit',
    letterSpacing: 'inherit',
  },
  errorMessage: {
    display: 'flex',
    width: '100%',
    padding: '12px',
    background: 'rgba(255, 81, 81, 0.1)',
    borderRadius: '12px',
    marginTop: '16px',
    color: colors.error,

    [theme.breakpoints.up('lg')]: {
      marginTop: '16px',
    },
  },
  errorText: {
    fontWeight: 500,
    fontSize: '13px',
    lineHeight: '22px',
    color: colors.error,
    marginLeft: '10px',
    fontFamily: 'inherit',
    letterSpacing: 'inherit',
  },
  payByCardWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  payByCardIcon: {
    '& path': {
      stroke: theme.palette.secondary.main,
      fill: theme.palette.secondary.main,
    },
    marginRight: '8px',
  },
  payByCardIconLoading: {
    '& path': {
      stroke: colors.disable,
      fill: colors.disable,
    },
  },
  payByCardLink: {
    color: theme.palette.secondary.main,
    fontWeight: 600,
    fontFamily: 'inherit',
    letterSpacing: 'inherit',
  },
  payByCardLinkLoading: {
    color: colors.disable,
  },
}));

export interface BuyNFTLimitedModalProps {
  nftId: string;
  nftTitle: string;
  nftAddress: string;
  nftImage?: string;
  price: number;
  author: string;
  open: boolean;
  isProcessing: boolean;
  onCancel: () => void;
  updateNftQuantity: (val: number) => void;
  buyNft: () => Promise<void>;
  buyNftByCard: () => Promise<void>;
  onlyCrypto?: boolean;
  maxCount: number;
  quantity?: number;
}

export const BuyNFTLimitedOrTicketModal = ({
  nftId,
  nftTitle,
  nftAddress,
  nftImage,
  price,
  author,
  open,
  isProcessing,
  onCancel,
  updateNftQuantity,
  buyNft,
  buyNftByCard,
  onlyCrypto = false,
  maxCount,
  quantity,
}: BuyNFTLimitedModalProps) => {
  const {t} = useLocalization();
  const styles = useStyles();

  const nftIdRef = useRef<HTMLElement | null>(null);
  const selectedWallet = useSelectedWallet();
  const [total, setTotal] = useState<number>(price);
  const {showConnectWalletModal} = useConnectNonCustodyWallet();
  const {isLoading, walletBalance} = useSingletonFetchUserWalletBalance();

  const isNonCustodyWalletConnected = isNonCustodyWalletType(selectedWallet.wallet);
  const isTorusWalletSelected = selectedWallet.wallet === NonCustodyWalletTypeEnum.TORUS;

  const isEnoughFunds = walletBalance?.usdc ? walletBalance.usdc >= price : false;
  const isButtonDisabled = isLoading || !isNonCustodyWalletConnected || !isEnoughFunds;
  const isShowWalletError = isTorusWalletSelected && !isLoading && !isEnoughFunds;
  const {appConfig} = useContext(AppContext);

  const updateNftQuantityHandler = useMemo(
    () => (quantity: number) => {
      updateNftQuantity(quantity);
      setTotal(price * quantity);
    },
    [price, updateNftQuantity],
  );

  return (
    <PurchaseModal open={open} icon={<WalletIcon />} title={t('Buy this NFT')} onClose={onCancel}>
      {!isNonCustodyWalletConnected && (
        <Box className={clsx(styles.dataContainer, styles.connectWalletContainer)}>
          <Button className={styles.connectWalletButton} variant="text" onClick={showConnectWalletModal}>
            {t('Connect wallet')} <ChevronRightIcon fontSize="small" />
          </Button>
        </Box>
      )}
      <Box className={styles.root}>
        <Box className={styles.subBox}>
          <Box className={clsx(styles.dataContainer, styles.dataLine)}>
            <Box className={styles.column}>
              <NftModalHighlightCard
                nftId={nftId}
                title={author}
                nftTitle={nftTitle}
                onClick={onCancel}
                nftImage={nftImage}
              />
            </Box>
          </Box>
          <Box className={clsx(styles.dataContainer, styles.dataLine)}>
            <Box className={styles.column}>
              {isNonCustodyWalletType(selectedWallet.wallet) && (
                <WalletBalance
                  balance={walletBalance?.usdc}
                  caption={t('My {{title}} wallet balance', {title: NonCustodyWalletTitles[selectedWallet.wallet]})}
                />
              )}
            </Box>
          </Box>
        </Box>

        <Box className={styles.subBox}>
          <Box className={clsx(styles.column, styles.dataLine)}>
            <Typography className={styles.dataLabel}>{t('NFT ID')}</Typography>
            <Box className={styles.copyButtonContainer}>
              <Typography className={styles.dataText} ref={nftIdRef}>
                {nftAddress}
              </Typography>
              <CopyButton node={nftIdRef} />
            </Box>
            <Divider />
          </Box>
          <Box className={clsx(styles.column, styles.dataLine)}>
            <Box className={styles.dataContainer}>
              <Typography className={styles.dataLabel}>{t('Quantity')}</Typography>
              <QuantityField
                className={styles.quantity}
                variant={'outlined'}
                maxValue={maxCount}
                onValueChange={updateNftQuantityHandler}
                quantity={quantity}
              />
            </Box>
            <Divider />
          </Box>
          {maxCount > 1 && (
            <Box className={clsx(styles.column, styles.dataLine)}>
              <Box className={styles.dataContainer}>
                <Typography className={styles.dataLabel}>{t('Price')}</Typography>
                <Typography className={styles.dataText}>{`${price} ${TOKEN_TITLE}`}</Typography>
              </Box>
              <Divider />
            </Box>
          )}
          <Box className={styles.column}>
            <Box className={styles.dataContainer}>
              <Typography className={styles.dataLabel}>{t('Total amount')}</Typography>
              <Typography className={clsx(styles.dataText, styles.netSales)}>
                {`${total} ${TOKEN_TITLE}`}{' '}
                <Typography className={styles.dataLabel}>
                  (~{total}
                  {' USD'})
                </Typography>
              </Typography>
            </Box>
            <Divider />
          </Box>
        </Box>
      </Box>

      {isShowWalletError && (
        <Paper className={styles.errorMessage} elevation={0}>
          <ErrorIcon fontSize="small" />
          <Typography className={styles.errorText}>{t('Insufficient balance, fund your wallet')}</Typography>
        </Paper>
      )}

      <Box className={styles.buttonContainer}>
        {isShowWalletError && (
          <TorusWalletFundButton
            className={clsx(styles.button, styles.buttonContainerInnerSpaccing)}
            contextInfo={{
              price,
              title: nftTitle,
              description: t('You’re about to purchase:'),
              imageUrl: nftImage!,
            }}
          />
        )}
        <NFTCTAButton
          className={clsx(styles.button, styles.buttonContainerInnerSpaccing)}
          text={t('Buy')}
          type="submit"
          onClick={buyNft}
          loading={isProcessing}
          disabled={isButtonDisabled}
        />
        <Condition condition={!appConfig.isFiatPaymentDisabled && !onlyCrypto}>
          <Box className={styles.payByCardWrapper}>
            <Card className={clsx(styles.payByCardIcon, isProcessing && styles.payByCardIconLoading)} />
            <Typography
              className={clsx(styles.payByCardLink, isProcessing && styles.payByCardLinkLoading)}
              onClick={buyNftByCard}
            >
              {t('Pay by card')}
            </Typography>
          </Box>
        </Condition>
      </Box>
    </PurchaseModal>
  );
};
