import {useContext, useEffect, useMemo, useState} from 'react';
import {Box, CardContent, Chip, Divider} from '@material-ui/core';
import {Link} from 'react-router-dom';
import {Typography} from '@cere/rxb-template-ui-kit';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import clsx from 'clsx';

import {useNftCardStyles} from './styles';
import {Card} from '../Card';
import {Avatar} from '../Avatar';
import {Condition, ConditionsList} from '../Conditions';
import {GoogleAnalyticsId} from 'analytics-ids';
import {link, StyledLink} from '../StyledLink';
import {getTimelineStatusV2} from 'pages/Сms/Exhibit/utils';
import {EventTimelineStatus} from 'shared/types/event';
import {WarningMessage} from '../WarningMessage';
import {formatPrice} from '../../lib/formatPrice';
import {NftContext} from '../Nft/nft-context-v2';
import AssetGallery from '../AssetGallery/asset-gallery';
import {getNftEndsDateTime} from '../../helpers/timer-availability';
import {FullNftDescriptions} from '../NftDescriptions/fullNftDescription';
import {getNftRelTypeTitle} from '../../helpers/get-nft-rel-type-title.helper';
import {AvailabilityStatus, CheckUserHasNftEnum, CreatorAuctionBid, NftType} from '@cere/services-types';
import {useNftAssets} from './hooks';
import {NftActionButton, NftSellButton} from '../ActionButtons';
import {DisabledButton} from '../ActionButtons/DisabledButton';
import {useLocalization} from '../../hooks/use-locale.hook';
import {ALL_WALLET} from 'pages/UserPages/types';
import {useWalletNfts} from 'pages/UserPages/use-wallet-nfts';
import {getStorageItem} from 'shared/services/storage-service';
import {QuantityField} from '../fields/quantity/quantity.field';
import {PendingTransactionTypes, usePendingTransactionsContext} from 'context/pending-transaction-context';

export const NFTDetailsCard = () => {
  const {
    eventUrl,
    balance,
    supply,
    market,
    relType,
    event,
    nft,
    price,
    auction,
    attributes,
    creator,
    quantity,
    setQuantity,
    hasUserOffer,
  } = useContext(NftContext);
  const {t, locale} = useLocalization();
  const assets = useNftAssets(nft.id);
  const {getPendingTransactionForNft} = usePendingTransactionsContext();

  const isPendingTransactionSellNft = useMemo(
    () => getPendingTransactionForNft(nft.address)?.type === PendingTransactionTypes.SELL_NFT,
    [nft.address, getPendingTransactionForNft],
  );
  const isComingSoon = nft.availability === AvailabilityStatus.COMING_SOON || !event;
  const isPurchased = useMemo(
    () =>
      nft.purchaseStatus === CheckUserHasNftEnum.USER_HAS_NFT ||
      (attributes.pending && !attributes.hasPendingTransaction),
    [nft, attributes],
  );

  const processingNftsTemp: Record<string, Record<string, CreatorAuctionBid>> = getStorageItem('BIDS');
  const eventStatus = getTimelineStatusV2(nft, event);

  const isNftWithAsset = useMemo(() => Boolean(assets && assets.length > 1), [assets]);

  const eventTime = eventStatus === EventTimelineStatus.STARTED ? event?.endsAt : '';
  const counterTime = attributes.counter
    ? getNftEndsDateTime(eventTime, relType, eventStatus, nft.availability, nft.purchaseStatus)
    : '';

  const styles = useNftCardStyles();
  const {nfts} = useWalletNfts(ALL_WALLET);
  const nftsWithQuantity = useMemo(
    () => nfts.filter((nftWithQuantity) => nftWithQuantity.nft_id.cmsNft.id === nft.id),
    [nfts, nft],
  );

  const [processingNfts, setProcessingNfts] = useState(processingNftsTemp);
  useEffect(() => {
    const updateState = async () => {
      const processingNftsTemp: Record<string, Record<string, CreatorAuctionBid>> = getStorageItem('BIDS');
      setProcessingNfts(processingNftsTemp);
    };
    if (nft.auction) {
      const intervalId = setInterval(updateState, 5000);
      return () => {
        clearInterval(intervalId);
      };
    }
  }, [nft, locale]);

  const updateNftQuantityHandler = useMemo(
    () => (nftQuantity: number) => {
      setQuantity && setQuantity(nftQuantity);
    },
    [setQuantity],
  );

  return (
    <Card>
      <Box display="flex" flexDirection="column" width="100%">
        <Box className={styles.wrapper}>
          <Box className={clsx(styles.media, !(assets?.length ?? 0 > 1) && styles.mediaNotOwner)}>
            <AssetGallery assets={assets ?? []} nft={nft} qtyOwned={market.owned} counterTime={counterTime} noBorder />
          </Box>
          <CardContent className={styles.cardContent}>
            <Box className={clsx(styles.content, isNftWithAsset && styles.assetSontent)}>
              {(!!processingNfts[nft.address] || attributes.hasPendingTransaction) && (
                <WarningMessage className={styles.warning} message={t('NFT transaction processing')} />
              )}

              <Typography className={styles.title}>{nft.title}</Typography>
              {relType && market.owned < 1 && (
                <Typography className={styles.nftType}>{getNftRelTypeTitle(relType)}</Typography>
              )}
              {creator && (
                <StyledLink
                  to={`/${locale}/home/creator/${creator.id}`}
                  className={clsx(link, GoogleAnalyticsId.ViewCreatorBtn)}
                >
                  <Avatar name={creator.name} imageUrl={creator.avatar.url} creatorId={creator.id} />
                </StyledLink>
              )}

              <ConditionsList multiply>
                <Condition condition={!isComingSoon && !market.owned && nft.nftType === NftType.AUCTIONED}>
                  <Box className={styles.priceBlock}>
                    <Typography className={styles.qty}>{t('1 Auctioned original')}</Typography>
                    <Typography variant="caption1" className={clsx(styles.price)}>
                      <Typography variant="h4" component="span" className={styles.priceText}>
                        {auction.priceText}&nbsp;
                      </Typography>
                      <Typography variant="h4" component="span" className={styles.priceDescriptionText}>
                        {formatPrice(price.usd + price.cents)}
                      </Typography>
                    </Typography>
                  </Box>
                </Condition>
                <Condition condition={!isComingSoon && !market.owned && nft.nftType !== NftType.AUCTIONED}>
                  <Box className={styles.priceBlock}>
                    <Typography className={styles.qty}>
                      {t('{{leftAmount}}/{{totalAmount}} left', {leftAmount: balance, totalAmount: supply})}
                    </Typography>
                    <Typography className={styles.price}>${price.usd}</Typography>
                  </Box>
                </Condition>
                <Condition condition={market.owned > 0}>
                  <Chip
                    className={styles.chip}
                    label={t('{{count}} owned by you', {count: nftsWithQuantity[0]?.quantity || market.owned})}
                  />
                </Condition>

                <Condition condition={!isComingSoon}>
                  <Box className={styles.quantityBlock}>
                    <Typography variant="h4">{t('Quantity')}</Typography>
                    <QuantityField
                      className={styles.quantity}
                      variant={'outlined'}
                      maxValue={nft.balance || 1}
                      onValueChange={updateNftQuantityHandler}
                      quantity={quantity}
                      isModal={false}
                    />
                  </Box>
                </Condition>
              </ConditionsList>

              <ConditionsList>
                <Condition condition={isPurchased && !isComingSoon}>
                  <Box className={styles.controlsContainer}>
                    <NftSellButton className={styles.button} nft={nft} />
                    <NftActionButton className={styles.button} excludeSellButton />
                  </Box>
                </Condition>
                <Condition condition={!!hasUserOffer || isPendingTransactionSellNft}>
                  <Box className={styles.controlsContainer}>
                    <DisabledButton className={styles.button} text={t('Sell')} />
                    <NftActionButton className={styles.button} excludeSellButton />
                  </Box>
                </Condition>
                <Condition condition={!isPurchased && !isComingSoon}>
                  <Box className={clsx(styles.ctaButtonContainer, styles.buyButtonContainer)}>
                    <NftActionButton className={styles.button} excludeSellButton />
                  </Box>
                </Condition>
              </ConditionsList>
            </Box>
            {event && eventUrl && !(eventStatus === EventTimelineStatus.FINISHED && market.owned < 1) && (
              <Box>
                <Divider className={styles.divider} />
                <Link to={eventUrl} className={styles.exhibitLink}>
                  <Typography className={styles.exhibitLinkDescription}>{t('View related exhibit')}</Typography>
                  <ChevronRightIcon />
                </Link>
                <Divider className={styles.divider} />
              </Box>
            )}
          </CardContent>
        </Box>
        <Divider className={styles.descriptionDivider} />
        <FullNftDescriptions cmsNftIncrementId={nft.id} isNftWithAsset={isNftWithAsset} />
      </Box>
    </Card>
  );
};
