import {selectAppConfig} from '../../redux/modules/app-config/selectors';
import {Dispatch} from 'redux';
import {SignInWithEmailCommand, SignInWithSocialCommand} from '../../redux/modules/auth/actions';
import {SupportedAuthProviders} from '../../models/auth/types';
import {connect} from 'react-redux';
import {SignInPageView} from './view';

const mapStateToProps = (state: any) => ({
  logoUrl: selectAppConfig(state).logoShort.url,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onSignInWithEmail: (email: string) => dispatch(SignInWithEmailCommand.create(email)),
  onSignInWithSocialProvider: (provider: SupportedAuthProviders) => dispatch(SignInWithSocialCommand.create(provider)),
});

export const SignInPage = connect(mapStateToProps, mapDispatchToProps)(SignInPageView);
