import {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {Trans} from 'react-i18next';
import {useHistory, useLocation} from 'react-router-dom';
import {Button, makeStyles} from '@material-ui/core';

import PageHeader from '../../shared/components/PageHeader';
import {UserWalletBalanceComponent} from './components/user-wallet-balance.component';
import {useAuthorized} from '../../shared/hooks/auth.hook';
import colors from '../../styles/colors';
import {ReactComponent as LoaderIcon} from '../../assets/icons/loader.svg';
import {deposit, getERC20balance} from '../../shared/services/wallet.service';
import {TOKEN_TITLE} from '../../shared/lib/formatPrice';
import {UserWalletDetails} from './components/user-wallet-details.component';
import {dispatchError} from '../../shared/lib/error-handler';
import {UserContext} from '../../context/user-context/user-context';
import {AppContext} from '../../context/app-context';
import {useLocalization} from '../../shared/hooks/use-locale.hook';
import {fetchPrivateKey} from '../../shared/services/private-key.service';

const useStyles = makeStyles((theme) => ({
  page: {
    padding: '0 16px 0 16px',
  },
  pendingBalanceArea: {
    background:
      'linear-gradient(226.34deg, rgba(244, 104, 129, 0.12) 15.52%, rgba(243, 102, 130, 0.12) 16.27%, rgba(231, 69, 150, 0.12) 29.76%, rgba(224, 49, 162, 0.12) 41.02%, rgba(221, 42, 166, 0.12) 48.62%, rgba(82, 16, 226, 0.12) 77.78%)',
    borderRadius: '12px',
    padding: '16px',
    fontSize: '14px',
  },
  depositButton: {
    float: 'right',
    padding: '10px 25px 10px 25px',
  },
  depositButtonLoader: {
    float: 'right',
    padding: '10px 35px',
  },
  pendingBalance: {
    fontSize: '16px',
    fontWeight: 600,
    display: 'block',
  },
  walletAddress: {
    backgroundColor: colors.lighter,
    borderRadius: '12px',
    padding: '16px',
  },
  copy: {
    fill: theme.palette.primary.main,
  },
  balance: {
    paddingBottom: '16px',
  },
  title: {
    display: 'block',
    fontSize: '20px',
    fontWeight: 'bold',
    padding: '24px 0 0 0',
    margin: '0 0 4px 0',
  },
  cutText: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    width: 'calc(100% - 40px)',
    height: '1.2em',
    whiteSpace: 'nowrap',
  },
  copyButton: {
    position: 'absolute',
    right: '22px',
    paddingTop: '15px',
  },
  howTo: {
    fontSize: '16px',
    paddingLeft: '23px',
    // fontWeight: 'bold',
    // '& span': {
    //   fontWeight: 'normal',
    // },
    fontWeight: 'normal',
    '& strong': {
      fontWeight: 'bold',
    },
  },
  button: {
    width: '100%',
    margin: '22px 0 22px 0',
  },
}));

const UserDepositFromFiatPage = () => {
  const {t, locale} = useLocalization();
  const history = useHistory();
  const classes = useStyles();
  const {userData} = useContext(UserContext);
  const location = useLocation<{from: string}>();
  const {appConfig} = useContext(AppContext);

  const [depositProcess, setDepositProcess] = useState(false);
  const userWallet = useMemo(() => userData.userPublicKey, [userData.userPublicKey]);
  const [erc20balance, setErc20balance] = useState<number>(0);
  const [showBackNftButton, setShowBackNftButton] = useState<boolean>(false);

  useAuthorized();

  const handleBackToNft = async (): Promise<void> => {
    history.goBack();
  };

  useEffect(() => {
    setShowBackNftButton(location?.state?.from !== `/${locale}/home/user/wallet`);
  }, [location, locale]);

  const handleDeposit = useCallback(async (): Promise<void> => {
    setDepositProcess(true);
    const privateKey = await fetchPrivateKey(userData.token);

    try {
      await deposit(erc20balance, privateKey);
      setErc20balance(0);
    } catch (err) {
      if (err.message.indexOf('insufficient funds') > -1) {
        dispatchError(t('Insufficient funds for intrinsic transaction cost'));
      } else {
        console.error(err);
      }
    }
    setDepositProcess(false);
  }, [erc20balance, userData, t]);

  const updateERC20Balance = useCallback(async () => {
    const {userPublicKey, token} = userData;

    if (!userPublicKey || !token) {
      throw new Error(t('Incorrect public and private keys!'));
    }

    const balance = await getERC20balance(userPublicKey, token);
    setErc20balance(balance);
  }, [userData, t]);

  useEffect(() => {
    updateERC20Balance();
  }, [updateERC20Balance, userData, userWallet]);

  return (
    <>
      {/*TODO Move to top level component*/}
      <PageHeader />

      <div className={classes.page}>
        {erc20balance > 0 && (
          <div className={classes.pendingBalanceArea}>
            {!depositProcess && (
              <Button color="primary" variant="contained" onClick={handleDeposit} className={classes.depositButton}>
                {t('Deposit')}
              </Button>
            )}
            {depositProcess && (
              <div className={classes.depositButtonLoader}>
                <LoaderIcon />
              </div>
            )}
            <span className={classes.pendingBalance}>
              {erc20balance} {TOKEN_TITLE}
            </span>
            {t('Pending balance')}
          </div>
        )}
        <h1 className={classes.title}>{t('Deposit')}</h1>
        <div className={classes.balance}>
          <UserWalletBalanceComponent showUsdBalance={true} />
        </div>

        <UserWalletDetails />

        <h1 className={classes.title}>{t('How to fund your wallet')}</h1>
        <ol className={classes.howTo}>
          <li>
            <Trans i18nKey="<strong>Copy</strong> your wallet address above" />
          </li>
          <li>
            <Trans i18nKey="<strong>Send</strong> USDC <strong>ONLY</strong> via <strong>Polygon</strong> to the copied address" />
          </li>
          <li>
            <Trans
              i18nKey="<strong>Confirm</strong> pending balance in {{appTitle}}"
              values={{appTitle: appConfig.appTitle}}
            />
          </li>
          <li>
            <Trans i18nKey="<strong>Ready</strong> to use your balance" />
          </li>
        </ol>
        {showBackNftButton && (
          <Button color="secondary" variant="contained" className={classes.button} onClick={handleBackToNft}>
            {t('Back to NFT')}
          </Button>
        )}
      </div>
    </>
  );
};

export default UserDepositFromFiatPage;
