import {PurchaseModal} from '../PurchaseModal';
import {Box, Typography, Button} from '@material-ui/core';
import {Form} from 'react-final-form';
import {ReactComponent as CurrenciesIcon} from '../../../assets/icons/currencies.svg';
import {ReactComponent as InfoIcon} from '../../../assets/info.svg';
import DotIcon from '@material-ui/icons/FiberManualRecord';
import {NFTCTAButton} from '../NFTCTAButton';
import {useCallback, useContext, useMemo, useState} from 'react';
import {TOKEN_TITLE} from '../../lib/formatPrice';
import {useNftRoyalty} from '../../../context/use-nft-royalties';
import {ClickableTooltip} from '../ClickableTooltip';
import {AppContext} from '../../../context/app-context';
import {FixedPriceBlock} from './FixedPriceBlock';
import {useListingPriceStyles, useModalStyles} from './styles';
import {TabsContainer} from '../Tabs/TabsContainer';
import {Tab} from '../Tabs/Tab';
import {Condition, ConditionsList} from '../Conditions';
import {AuctionBlock} from './AuctionBlock';
import {BID_RAISE_PERCENT} from '../../../model/auction/Auction';
import {useLocalization} from '../../hooks/use-locale.hook';
import {MARKETPLACE_AUCTIONS_ENABLED} from '../../../config/common';

type ListingPriceFormData = {
  quantity: number;
  currency: string;
  price: number;
  auctionStartingPrice: number;
};

export interface SetNFTListingPriceModalProps {
  nftId: string;
  open: boolean;
  ownedQty: number;
  price: number;
  isProcessing: boolean;
  onCancel: () => void;
  sellNft: (details: ListingPriceFormData, isAuction: boolean) => Promise<void>;
}

enum TabsVariants {
  FixedPrice = 'FIXED_PRICE',
  Auction = 'AUCTION',
}

export const SetNFTListingPriceModal = ({
  open,
  onCancel,
  sellNft,
  nftId,
  ownedQty,
  price,
  isProcessing,
}: SetNFTListingPriceModalProps) => {
  const {t} = useLocalization();
  const [activeTab, setActiveTab] = useState<TabsVariants>(TabsVariants.FixedPrice);
  const styles = useListingPriceStyles();
  const modalStyles = useModalStyles();
  const nftRoyalty = useNftRoyalty(nftId);
  const {appConfig} = useContext(AppContext);

  const initialValues = useMemo<ListingPriceFormData>(
    () => ({
      quantity: ownedQty,
      currency: TOKEN_TITLE,
      price: price,
      auctionStartingPrice: price + (price * BID_RAISE_PERCENT) / 100,
    }),
    [ownedQty, price],
  );

  const selectFixedPrice = useCallback(() => setActiveTab(TabsVariants.FixedPrice), [setActiveTab]);
  const selectAuction = useCallback(() => setActiveTab(TabsVariants.Auction), [setActiveTab]);

  const handleSubmit = async (formValues: ListingPriceFormData) => {
    await sellNft(formValues, MARKETPLACE_AUCTIONS_ENABLED() && activeTab === TabsVariants.Auction);
  };

  return (
    <PurchaseModal
      open={open}
      icon={<CurrenciesIcon />}
      title={t('Select sell method')}
      onClose={onCancel}
      classes={modalStyles}
    >
      <Box className={styles.root}>
        <Typography className={styles.description}>
          {t('Please select your sell method and set price for your NFT on the marketplace.')}
        </Typography>
        {MARKETPLACE_AUCTIONS_ENABLED() && (
          <TabsContainer className={styles.typeSelector}>
            <Tab
              onClick={!isProcessing ? selectFixedPrice : () => {}}
              selected={activeTab === TabsVariants.FixedPrice}
              isLoading={isProcessing}
            >
              {t('Fixed price')}
            </Tab>
            <Tab
              onClick={!isProcessing ? selectAuction : () => {}}
              selected={activeTab === TabsVariants.Auction}
              isLoading={isProcessing}
            >
              {t('Auction')}
            </Tab>
          </TabsContainer>
        )}
        <Form initialValues={initialValues} onSubmit={handleSubmit}>
          {({handleSubmit, errors, hasValidationErrors}) => (
            <>
              <ConditionsList>
                <Condition condition={activeTab === TabsVariants.FixedPrice}>
                  <FixedPriceBlock
                    styles={styles}
                    isProcessing={isProcessing}
                    hasValidationErrors={hasValidationErrors}
                    errors={errors}
                  />
                </Condition>
                <Condition condition={MARKETPLACE_AUCTIONS_ENABLED() && activeTab === TabsVariants.Auction}>
                  <AuctionBlock
                    styles={styles}
                    nftPrice={price}
                    isProcessing={isProcessing}
                    hasValidationErrors={hasValidationErrors}
                    errors={errors}
                  />
                </Condition>
              </ConditionsList>
              <Box className={styles.royaltiesContainer}>
                <Box className={styles.royaltiesContent}>
                  <DotIcon className={styles.dot} fontSize="small" />
                  <Typography>
                    {t('{{royaltyPercent}}% royalties deducted from listing price', {royaltyPercent: nftRoyalty * 100})}
                  </Typography>
                </Box>
                <ClickableTooltip
                  text={t('Royalties are split between the creator, {{appTitle}} and Cere DDC.', {
                    appTitle: appConfig.appTitle,
                  })}
                  triggerComponent={InfoIcon}
                />
              </Box>
              <Box className={styles.controlsContainer}>
                <Box className={styles.buttonContainer}>
                  <Button className={styles.button} variant="outlined" onClick={onCancel} disabled={isProcessing}>
                    {t('Cancel')}
                  </Button>
                </Box>
                <Box className={styles.buttonContainer}>
                  <NFTCTAButton text={t('Sell')} type="submit" onClick={handleSubmit} loading={isProcessing} />
                </Box>
              </Box>
            </>
          )}
        </Form>
      </Box>
    </PurchaseModal>
  );
};
