import {SyntheticEvent, useCallback, useContext, useMemo} from 'react';
import {Typography} from '@cere/rxb-template-ui-kit';
import {Box, makeStyles, Theme} from '@material-ui/core';
import {AuctionOverlayProps} from '../types';
import BootstrapLoader from '../../../../../shared/components/bootstrap-loader';
import {useGetAuctionOverlay} from '../overlay.hook';
import {Condition, ConditionsList, Defaults} from '../../../../../shared/components/Conditions';
import {ExhibitContext} from '../../../../../context/exhibition/exhibit-context';
import {BigExclusiveNftCard, DetailedCard, ExclusiveNftCard} from '../../../../../shared/components/Overlay';
import {useLocalization} from '../../../../../shared/hooks/use-locale.hook';
import {NftProvider2} from 'shared/components/Nft/nft-provider-v2';
import {useExhibitNftsBySlug} from 'api/hooks/use-exhibit-nfts-by-slug';
import {NftCardInterface, NftType} from '@cere/services-types';

const useStyles = makeStyles<Theme, {showMore: boolean}>((theme) => ({
  scrollable: {
    overflowY: 'auto',
    maxWidth: '100%',
    display: 'flex',
    flex: '0 0 auto',
    flexDirection: 'column',
    gap: '1rem 1rem',

    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },

    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  item: {
    [theme.breakpoints.up('md')]: {
      flex: ({showMore}) => (showMore ? '0 0 360px' : '0 0 405px'),
    },
  },
  notification: {
    margin: '16px 0',
  },
  selectedNFT: {
    border: '1px solid rgba(60, 188, 172, 0.5)',
  },
}));

export const OverlayLandscape = ({onPlaceBid, onBuy, showMore, setShowMore}: AuctionOverlayProps) => {
  const {exchangeRate, selectedNftIndex, setSelectedNftIndex, showInfo, setShowInfo, checkHighestBid} =
    useGetAuctionOverlay({onPlaceBid, onBuy});
  const {event} = useContext(ExhibitContext);
  const {nfts, loading} = useExhibitNftsBySlug(event?.slug || '');

  const exhibitNfts = useMemo(() => nfts?.filter((nft) => nft.nftType !== NftType.ACCESS), [nfts]);

  const {t} = useLocalization();

  const classes = useStyles({showMore});
  const selectedNft: NftCardInterface | undefined = exhibitNfts?.[selectedNftIndex];

  const handleOpenDetails = useCallback(
    (index: number) => {
      setShowMore(() => true);
      setSelectedNftIndex(index);
    },
    [setSelectedNftIndex, setShowMore],
  );

  if (!event || loading || !exchangeRate) {
    return <BootstrapLoader />;
  }

  if (!selectedNft) {
    return (
      <Box height="100%" display="flex" alignItems="center" justifyContent="center">
        {t('Exclusive NFT drop coming soon')}
      </Box>
    );
  }

  const stopPropagationFunction = (event: SyntheticEvent) => event.stopPropagation();

  return (
    <ConditionsList>
      <Condition condition={showMore}>
        <ConditionsList>
          <Condition condition={Boolean(selectedNft)}>
            <Box onClick={stopPropagationFunction}>
              <NftProvider2 nft={selectedNft}>
                <DetailedCard eventNfts={exhibitNfts || []} isShowInfo={showInfo} setIsShowInfo={setShowInfo} />
              </NftProvider2>
            </Box>
          </Condition>
        </ConditionsList>
        <Box my="23px">
          <Typography variant="h4">{t('Exclusive NFTs')}</Typography>
        </Box>
        <Box className={classes.scrollable} onClick={stopPropagationFunction}>
          {exhibitNfts?.map((nft, i) => {
            const openDetails = () => handleOpenDetails(i);
            return (
              <Box className={classes.item} key={nft.id} onClick={stopPropagationFunction}>
                <NftProvider2 nft={nft}>
                  <ExclusiveNftCard
                    selectedNftId={selectedNft.id}
                    onCardClick={openDetails}
                    showMore={showMore}
                    nftId={nft.id}
                  />
                </NftProvider2>
              </Box>
            );
          })}
        </Box>
      </Condition>
      <Defaults>
        <Box className={classes.scrollable} onClick={stopPropagationFunction}>
          {exhibitNfts?.map((nft: NftCardInterface, i) => {
            const onCardClick = () => handleOpenDetails(i);
            return (
              <Box className={classes.item} key={nft.id} onClick={stopPropagationFunction}>
                <NftProvider2 nft={nft}>
                  <BigExclusiveNftCard
                    onCardClick={onCardClick}
                    showMore={showMore}
                    checkHighestBid={checkHighestBid}
                  />
                </NftProvider2>
              </Box>
            );
          })}
        </Box>
      </Defaults>
    </ConditionsList>
  );
};
