import {ReactElement, useContext} from 'react';
import {Condition, ConditionsList, Defaults} from '../Conditions';
import {EventTimelineStatus} from '../../types/event';
import {NftCardBigFinished} from './NftCardBigFinished';
import {NftCardBigNotStarted} from './NftCardBigNotStarted';
import {NftAllocationPendingBadge} from './NftCardBigBadge';
import {ExhibitContext} from '../../../context/exhibition/exhibit-context';
import {NftContext} from '../Nft/nft-context-v2';
import {NftCardInterface, NftType} from '@cere/services-types';
import {useLocalization} from '../../hooks/use-locale.hook';

type Props = {
  nft: Pick<NftCardInterface, 'nftType'>;
};

export function NftExhibitionCard({nft}: Props): ReactElement {
  const {eventTimelineStatus} = useContext(ExhibitContext);
  const {attributes} = useContext(NftContext);
  const {t} = useLocalization();

  return (
    <ConditionsList>
      <Condition condition={eventTimelineStatus === EventTimelineStatus.FINISHED}>
        <NftCardBigFinished />
      </Condition>
      <Defaults>
        <NftCardBigNotStarted
          buttonText={nft.nftType === NftType.AUCTIONED ? t('Bid') : t('Buy')}
          withUnlockableContent={false}
          badge={attributes.pending ? <NftAllocationPendingBadge /> : null}
        />
      </Defaults>
    </ConditionsList>
  );
}
